import { IHeader } from "../types/TTimeScaleTypes";

interface IProps<T> {
  header: IHeader<T>[];
  data: T[];
  showNumber?: boolean;
}
const TimeScaleTable = <T extends object>({ header, data, showNumber = false }: IProps<T>) => {
  console.log(header);
  return (
    <table className="table table-card">
      <thead>
        <tr>
          {showNumber && (
            <th scope="col" className="">
              SNo.
            </th>
          )}
          {header?.map((column) => (
            <th key={String(column.key)}>{column?.label}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row, rowIndex) => (
          <tr key={rowIndex}>
            {showNumber && <td>{rowIndex + 1}</td>}
            {header?.map((column) => (
              <td key={String(column?.key)}>
                {column.render
                  ? column.render(row[column.key], row, rowIndex)
                  : String(row[column?.key])}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TimeScaleTable;
