import Loader from "Common/Components/Loader";
import { IconTrash } from "Common/Icons";
import { useAppContext } from "contexts/AppContext";
import { deleteSuperAdmin, getAllUsers, setSuperAdminsParams } from "pages/Dashboard/action";
import { handleSort } from "pages/EmptyReturn/Common/utils";
import { useState } from "react";
import { tableHeaders } from "./constant";
import { IsuperAdminTypes } from "./constants";

const SuperAdminTable = () => {
  const { state, myDispatch } = useAppContext();
  const { userData, uLoading, searchParams, superAdminCriteria } = state.dashboardStates;
  const { page, limit } = searchParams;
  const [headers, setTableHeader] = useState<any | null>(tableHeaders);

  const onDeleteSuperAdmin = async (email: string) => {
    const deletedUser = await deleteSuperAdmin(email);
    myDispatch(deletedUser);
  };

  // Sorting
  const onSort = (obj: any) => {
    const searchParamsD = handleSort(obj, headers, searchParams);
    myDispatch(setSuperAdminsParams(superAdminCriteria, searchParamsD));
    myDispatch(getAllUsers(superAdminCriteria, searchParamsD));
  };

  return (
    <div className="table-responsive mt-10" style={{ height: "calc(100vh - 474px)" }}>
      {uLoading && <Loader />}
      <table className="table table-card">
        <thead>
          <tr>
            <th scope="col" className="">
              Sno
            </th>
            {headers.map((item: any, i: number) => {
              let sortIcon = "table-sort table-sort--default";
              if (item.sort === "-" && item.active) {
                sortIcon = "table-sort table-sort--ascending";
              }
              if (item.sort === "+" && item.active) {
                sortIcon = "table-sort table-sort--descending";
              }
              return (
                <th key={i} onClick={() => onSort(item)} className={item.sort ? sortIcon : ""}>
                  {item.label}
                </th>
              );
            })}
            <th></th>
          </tr>
        </thead>
        <tbody>
          {userData && userData.length ? (
            userData.map((superAdmin: IsuperAdminTypes, index: number) => {
              return (
                <tr key={index}>
                  <td className="text-center">{(page - 1) * limit + index + 1}</td>
                  <td>{superAdmin.fullName}</td>
                  <td>{superAdmin.email}</td>
                  <td>
                    <div className="badge badge-success">{superAdmin.role}</div>
                  </td>
                  <td>{superAdmin.subscriptionType}</td>
                  <td>{superAdmin.subSubscriptionType}</td>
                  <td>{superAdmin.companyType}</td>
                  <td>{superAdmin.companyName}</td>
                  <td>{superAdmin.companyAddress}</td>
                  <td className="w-10px">
                    <button
                      className="btn btn-link"
                      onClick={() => onDeleteSuperAdmin(superAdmin.email)}
                    >
                      <IconTrash />
                    </button>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={100}>No Data Found</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default SuperAdminTable;
