import { useAppContext } from "contexts/AppContext";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { getReportContainers, setReportContainersParams } from "../action";
const tabItems = [
  {
    id: 0,
    title: "All",
    path: "",
  },
  {
    id: 1,
    title: "containerSize",
    path: "",
  },
  {
    id: 2,
    title: "containerOwner",
    path: "",
  },
  {
    id: 3,
    title: "containerType",
    path: "",
  },
  {
    id: 4,
    title: "portId",
    path: "",
  },
  {
    id: 5,
    title: "dischargeDate",
    path: "",
  },
  {
    id: 6,
    title: "lastFreeDay",
    path: "",
  },
  {
    id: 7,
    title: "eta",
    path: "",
  },
  {
    id: 8,
    title: "vesselName",
    path: "",
  },
  {
    id: 9,
    title: "voyageName",
    path: "",
  },
  {
    id: 10,
    title: "outGateDate",
    path: "",
  },
  {
    id: 11,
    title: "terminatedDate",
    path: "",
  },
];

const ReportTabs = (activeTab: any) => {
  const { state, myDispatch } = useAppContext();
  const { searchParams, searchPost } = state.reportContainerStates;

  const [isTabActive, setIsTabActive] = useState(tabItems[0].title);

  const handleTabFilter = async (title: string) => {
    let payload = {};
    if (title === "ALL") {
      payload = {};
    } else {
      payload = {
        portFilter: searchPost.portFilter,
        [title]: true,
      };
    }

    setIsTabActive(title);
    searchParams.page = 1;
    const response = await setReportContainersParams(payload, searchParams);
    myDispatch(response);
  };

  return (
    <div className="d-flex flex-row align-items-center justify-content-between w-100">
      <ul className="nav nav-compact border-0 p-0" role="tablist">
        {tabItems.map((tabItem) => (
          <li className="nav-item  mb-1" key={tabItem.id}>
            <Link
              to={tabItem.path}
              className={`nav-link ml-1 ${
                isTabActive === tabItem.title ? " active" : "rounded-3 bg-light"
              }`}
              onClick={() => handleTabFilter(tabItem.title)}
            >
              {tabItem.title}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ReportTabs;
