import React, { useState } from "react";
import {
  IconAngleArrowRight,
  IconAngleArrowTop,
  IconCheck,
  IconDual,
  IconTimes,
  IconWarning,
} from "Common/Components/Icons/Icons";
import { LaPropsI, EmptySlotTable } from "../../location";
import sslFile from "../../nysslFile.json";
import { useAppContext } from "contexts/AppContext";
import { NoAppointMentPorts } from "pages/EmptyReturn/constant";

// import {getShiftActive} from ""

const EmptySlotAvailabilityTable = (props: EmptySlotTable) => {
  const { data, emptyData, portsArray } = props;

  // ---------------------------------------------------- REDUX STATE ---------------------------------------------------//
  const { state } = useAppContext();
  const { filteredEmptiesSlot } = state.reportDRStates;

  // ---------------------------------------------------- STATE MANAGEMENT---------------------------------------------------//
  const [emptyReturnsInfo, setEmptyReturnsInfo] = useState<string[]>(sslFile.shipping_lines);
  // const [shifts, setShifts] = useState<string[]>(
  //   terminaTab === "NY" ? ["shift1"] : ["shift1", "shift2", "shift3"]
  // );

  // ---------------------------------------------------- HANDLE FUNCTIONS ---------------------------------------------------//
  const showEmptyReturnsInfo = (ssl: string) => {
    if (emptyReturnsInfo.includes(ssl)) {
      const temp = emptyReturnsInfo.filter((fil) => fil !== ssl);
      setEmptyReturnsInfo([...temp]);
    } else {
      const temp = emptyReturnsInfo;
      temp.push(ssl);
      setEmptyReturnsInfo([...temp]);
    }
  };

  return (
    <>
      <tbody>
        <tr>
          <td colSpan={100}>
            {/* <div className="d-flex align-items-center">
              <span className="font-14 text-dark font-weight-500">{data}</span>
              <button className="btn btn-link" onClick={() => showEmptyReturnsInfo(data)}>
                {emptyReturnsInfo.includes(data) ? <IconAngleArrowTop /> : <IconAngleArrowRight />}
              </button>
            </div> */}
          </td>
        </tr>
        {filteredEmptiesSlot &&
          filteredEmptiesSlot.map((slot: any, i: number) => {
            return (
              <tr key={i}>
                <React.Fragment>
                  <td width="100">
                    <span className="font-14 font-weight-100">
                      {slot.date}
                      <br />
                      <br />
                      {`Shift ${slot.shift}`}
                    </span>
                  </td>
                  {portsArray.map((pp: any, pIndex: number) => {
                    let label = pp?.label?.replace("_", " ");
                    return (
                      slot[label] && (
                        <td key={pIndex} className="text-center">
                          <div className="d-flex flex-column align-items-center justify-content-center">
                            {slot[label].isOpen ? (
                              <>
                                <IconCheck className="text-primary wh-20px" />
                                {!NoAppointMentPorts.includes(label) && (
                                  <span>
                                    {slot[label].slotsAvailable ? (
                                      <span className="text-center">
                                        {slot[label].availableCount && (
                                          <span>{slot[label].availableCount}</span>
                                        )}{" "}
                                        Slots Available
                                      </span>
                                    ) : (
                                      <span className="text-center">No Slots Available</span>
                                    )}
                                  </span>
                                )}
                              </>
                            ) : (
                              <IconTimes className="text-error-500 wh-20px" />
                            )}
                          </div>
                        </td>
                      )
                    );
                    // ) : (
                    //   <td key={pIndex} className="text-center"></td>
                    // );
                  })}
                </React.Fragment>
              </tr>
            );
          })}
      </tbody>
    </>
  );
};

export default EmptySlotAvailabilityTable;
