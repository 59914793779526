import { api } from "config";
import { objToQueryParams, removeNullEmpty } from "utils/commonFunctions";

export const getSuccessfulContainers = (data: any, params?: any) => {
  delete params.reset;
  let postData = removeNullEmpty(data);
  let filterParams = removeNullEmpty(params);
  let url = "api/dashboard/sucessfullDataReport";
  url = filterParams ? url + "?" + objToQueryParams(filterParams) : url;
  return {
    type: "SUCCESSFULCONTAINERS",
    payload: api.post(url, postData),
  };
};

export const setSuccessfulContainersParams = (searchPost?: any, searchParams?: any) => {
  delete searchParams.reset;
  return {
    type: "SET_SUCCESSFULCONTAINERS_PARAMS",
    payload: { searchPost, searchParams },
  };
};

export const resetSuccessfulContainersParams = (searchPost?: any, searchParams?: any) => {
  return {
    type: "RESET_SUCCESSFULCONTAINERS_PARAMS",
  };
};
