import { SelectFieldWithoutControl } from "Common/Components/Inputs/CustomSelectField";
import { useAppContext } from "contexts/AppContext";
import _, { filter } from "lodash";
import { getRequiredCookiesByPort, setContainerCredentialsParams } from "../action";

import { Col, Form, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
const PORTS = [
  "LBCT",
  "ITS TERMINAL",
  "GCT NY",
  "EVERPORT_TERMINAL",
  "VIGNIT",
  "CN",
  "TRAPAC",
  "HLT",
  "EVERPORT_OAK",
  "EMODAL LA",
  "EMODAL NY",
];
const PORT_CHOICES = PORTS.map((val: string) => ({
  label: val,
  value: val,
}));

const CookieFilterRow = (props: any) => {
  const { state, myDispatch } = useAppContext();
  const { searchParams, searchPost } = state.supportStates;
  const [filters, setFilters] = useState<any>({ ports: [PORT_CHOICES[0]] });

  useEffect(() => {
    getContainerCrendentialForCookies();
  }, [filters]);

  const getContainerCrendentialForCookies = async () => {
    const ports: any = filters.ports.map((p: { value: string }) => p.value);
    const response: any = getRequiredCookiesByPort({
      ports: ports,
    });
    myDispatch(response);

    const searchPostD = {
      ...searchPost,
      ports: ports,
    };
    myDispatch(setContainerCredentialsParams(searchPostD, searchParams));
  };

  const handlePortFilter = (ports: any) => {
    setFilters({ ...filters, ports });
  };

  return (
    <div className="filter-row d-flex align-items-center">
      {/* <span className="ml-2 px-10 font-12">Filter By</span> */}
      {/* <SelectFieldWithoutControl
        className="min-200 mr-2"
        classNamePrefix="select"
        isLoading={false}
        isClearable={true}
        isSearchable={true}
        isMulti={true}
        name="port"
        placeholder="Ports"
        onChange={handlePortFilter}
        options={PORT_CHOICES}
        size="small"
        value={filters.ports}
      /> */}
      <Row>
        <Col xs="auto">
          <Form.Group as={Row} className="mb-1">
            <Form.Label column sm="8">
              Only Cookies? :
            </Form.Label>
            <Col sm="4">
              <Form.Check
                inline
                name="paid"
                label=""
                onChange={(event) => props.setIsCookies(event?.target?.checked)}
                type="switch"
                defaultChecked
              />
            </Col>
          </Form.Group>
        </Col>
      </Row>
    </div>
  );
};

export default CookieFilterRow;
