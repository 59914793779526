import {
  IconArchive,
  IconEmpty,
  IconFileCheck,
  IconPhone,
  IconSettings,
  IconUploadAlt,
} from "Common/Components/Icons/Icons";
import { getPermissionArrays } from "Common/utils";
import _ from "lodash";
import {
  IconAngleArrowLeft,
  IconCancelCircle,
  IconCheckCircleDash,
  IconClock,
  IconDeliveryClockWise,
  IconDocument,
  IconCalendar,
  IconCheckCircle,
  IconClock9,
  IconFile,
  IconMonitor,
  IconWarning,
  IconWarningCircle,
  IconHeartRate,
  IconUserAlt,
} from "Common/Icons";
export const sidebarLists = (user: any) => {
  if (!user.terminal) {
    return [];
  }
  const emptyTerminalLink = () => {
    if (user?.terminal?.[0]?.code === 1) {
      return "/current-empties/LA-LB";
    } else if (user?.terminal?.[0]?.code === 2) {
      return "/current-empties/NJ-NY";
    } else if (user?.terminal?.[0]?.code > 2) {
      return `/current-empties/${user?.terminal?.[0].code}`;
    }
  };

  let sidebarLinks = [
    {
      link: "/dashboard/containers",
      title: "Dashboard",
      icon: <IconMonitor />,
      permissions: ["SuperAdmin"],
    },
    {
      link: "/import-availability",
      title: "Import Availability",
      icon: <IconFileCheck />,
      permissions: [],
    },
    {
      link: emptyTerminalLink(),
      title: "Empty Return",
      icon: <IconEmpty />,
      permissions: [],
    },
    {
      link: "/gate-hour",
      title: "Gate Hours",
      icon: <IconCalendar />,
      permissions: [],
    },
    {
      link: "/vessel-schedule",
      title: "Vessel Schedule",
      icon: <IconClock9 />,
      permissions: [],
    },
    {
      link: "/time-scale-reports",
      title: "Time Scale Reports",
      icon: <IconFile />,
      permissions: [],
    },
    // {
    //   link: "/missing-report-container",
    //   title: "Missing Containers",
    //   icon: <IconDocument />,
    //   permissions: ["SuperAdmin"],
    // },
    // {
    //   link: "/wrong-data-reports",
    //   title: "Wrong Data Reports",
    //   icon: <IconCancelCircle />,
    //   permissions: ["SuperAdmin"],
    // },
    // {
    //   link: "/vessel-report",
    //   title: "Vessel Container Report",
    //   icon: <IconAngleArrowLeft />,
    //   permissions: ["SuperAdmin"],
    // },

    // {
    //   link: "/admin-correction",
    //   title: "Empty Correction",
    //   icon: <IconCheckCircle />,
    //   permissions: ["SuperAdmin"],
    // },
    // {
    //   link: `${isNyTerminal() ? "/new-empty-correction/NJ-NY" : "/new-empty-correction/LA-LB"}`,
    //   title: "Empty New Correction",
    //   icon: <IconCheckCircle />,
    //   permissions: [],
    // },
    {
      link: "/settings/select-terminal",
      title: "Settings",
      icon: <IconSettings />,
      permissions: [],
    },

    //Reports
    {
      title: "Reports",
      icon: <IconHeartRate />,
      permissions: [],
      subLinks: [
        {
          link: "/reports/not-updated-containers",
          title: "Not Updated Containers",
          icon: <IconWarningCircle />,
          permissions: ["SuperAdmin"],
        },
        {
          link: "/reports/report-container",
          title: "Report Container",
          icon: <IconFile />,
          permissions: ["SuperAdmin"],
        },
        {
          link: "/reports/problem-containers",
          title: "Problem Containers",
          icon: <IconWarning />,
          permissions: ["SuperAdmin"],
        },
        {
          link: "/reports/successful-data-reports",
          title: "Successful Data Reports",
          icon: <IconCheckCircleDash />,
          permissions: ["SuperAdmin"],
        },
        {
          link: "/reports/archive-container",
          title: "Archive Containers",
          icon: <IconArchive />,

          permissions: [],
        },
        {
          link: "/reports/drayos-data-reports",
          title: "Missing Report Containers",
          icon: <IconFile />,
          permissions: ["SuperAdmin"],
        },
        {
          link: "/reports/successful-matrics-container-report",
          title: "Metric Report",
          icon: <IconCheckCircleDash />,
          permissions: ["SuperAdmin"],
        },
        {
          link: "/reports/empties-slot-report",
          title: "Empties Slot Report",
          icon: <IconCheckCircleDash />,
          permissions: ["SuperAdmin"],
        },
        {
          link: "/reports/appointment-report",
          title: "Appointment Report",
          icon: <IconCheckCircleDash />,
          permissions: ["SuperAdmin"],
        },
        {
          link: "/reports/appointment-slot-report",
          title: "Appointment Slot Report",
          icon: <IconCheckCircleDash />,
          permissions: ["SuperAdmin"],
        },
        {
          link: "/reports/drayos-standalone-report",
          title: "Drayos Standalone Report",
          icon: <IconCheckCircleDash />,
          permissions: ["SuperAdmin"],
        },
        {
          link: "/reports/drayos-baseline-snapshot-report",
          title: "Drayos standalone Report Snapshot",
          icon: <IconCheckCircleDash />,
          permissions: ["SuperAdmin"],
        },
      ],
    },
    {
      title: "Support",
      icon: <IconUserAlt />,
      permissions: ["SuperAdmin"],
      subLinks: [
        {
          link: "/support/sessions",
          title: "Sessions",
          icon: <IconPhone />,
          permissions: [],
        },
        {
          link: "/support/tokens",
          title: "Tokens",
          icon: <IconSettings />,
          permissions: ["SuperAdmin"],
        },
        {
          link: "/support/tti-blankindex-updater",
          title: "TTI Blank Index Updater",
          icon: <IconPhone />,
          permissions: ["SuperAdmin"],
        },
        {
          link: "/support/upload-folder",
          title: "Uploader",
          icon: <IconUploadAlt />,
          permissions: ["SuperAdmin"],
        },
        {
          link: "/support/cookies",
          title: "Cookies",
          icon: <IconUploadAlt />,
          permissions: ["SuperAdmin"],
        },
        {
          link: "/support/upload-html",
          title: "HTML Upload",
          icon: <IconUploadAlt />,
          permissions: ["SuperAdmin"],
        },
        {
          link: "/support/site-tracker",
          title: "Site Tracker",
          icon: <IconUploadAlt />,
          permissions: ["SuperAdmin"],
        },
      ],
    },
  ];

  if (![1, 4, 6, 11, 9, 17, 19].includes(user?.terminal[0]?.code)) {
    sidebarLinks = sidebarLinks.filter((bar: any) => bar.title !== "Gate Hours");
  }
  if (user.role) {
    const filterLinks: any = getPermissionArrays(sidebarLinks, user.role);
    return filterLinks;
  }
  return sidebarLinks;
};
