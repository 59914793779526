import { SelectFieldWithoutControl } from "Common/Components/Inputs/CustomSelectField";
import { useAppContext } from "contexts/AppContext";
import _, { filter } from "lodash";
import { setContainerCredentialsParams } from "../action";
import datapoint from "../dataPoints.json";
import { useState } from "react";
const SearchFilter = () => {
  const { state, myDispatch } = useAppContext();
  const { searchParams, searchPost } = state.supportStates;
  const [filters, setFilters] = useState({ ports: datapoint.ports, time: datapoint.time[0] });
  const [postParams, setPostParams] = useState(searchPost);

  const handlePortFilter = (ports: any) => {
    const searchPostD = {
      ...postParams,
      ports: ports.map((port: any) => port.value),
    };
    setFilters({ ...filters, ports });
    setPostParams(searchPostD);
  };
  const handleTimeFilter = (time: any) => {
    const searchPostD = {
      ...searchPost,
      time: time.value,
    };
    setPostParams(searchPostD);
    setFilters({ ...filters, time });
  };

  let timeopts = [...datapoint.time];
  if (searchPost?.ports?.length == 1) {
    timeopts = [...datapoint.time];
    timeopts.push({
      label: "all",
      value: "",
    });
  } else {
    timeopts = [...datapoint.time];
  }

  const onSubmit = () => {
    searchParams.page = 1;
    if (postParams.time == "") delete postParams.time;
    myDispatch(setContainerCredentialsParams(postParams, searchParams));
  };

  return (
    <div className="filter-row d-flex align-items-center">
      <span className="ml-2 px-10 font-12">Filter By</span>
      <SelectFieldWithoutControl
        className="min-200 mr-2"
        classNamePrefix="select"
        isLoading={false}
        isClearable={true}
        isSearchable={true}
        isMulti={true}
        name="port"
        placeholder="Ports"
        onChange={handlePortFilter}
        options={datapoint.ports}
        size="small"
        value={filters.ports}
      />
      <SelectFieldWithoutControl
        className="w-200 mr-2"
        classNamePrefix="select"
        isLoading={false}
        isSearchable={true}
        name="time"
        placeholder="Last Updated"
        onChange={handleTimeFilter}
        options={timeopts}
        size="small"
        value={filters.time}
      />
      <button onClick={() => onSubmit()} className={`btn float-right mb-15 btn-success`}>
        Search
      </button>
    </div>
  );
};

export default SearchFilter;
