import React, { useState, useEffect, Fragment } from "react";

import { useAppContext } from "contexts/AppContext";
import {
  getContainerLists,
  toggleAddContainer,
  setAvailableParams,
  onAvailabilityReset,
  getcontainerCountStatus,
  getArchiveContainer,
} from "./action";

import SubHeaderTab from "Common/Components/SubHeaderTab";
import {
  IconAngleArrowLeft,
  IconAngleArrowRight,
  IconCalendar,
  IconCaretDown,
  IconDownload,
  IconPlus,
  IconSendEmail,
  IconUpload,
} from "Common/Icons";
import ContainerTable from "./component/ContainerTable";
import CardView from "./component/CardView";
import Pagination from "Common/Components/Pagination";
import ContainerAdd from "./component/ContainerAdd";

import FilterRow from "./component/FilterRow";
import ContainerAddForm from "./component/ContainerAddForm";
import CountCards from "./component/CountCards";
import { CustomIconSpreadSheet } from "Common/Components/Icons/CustomIcons";
import TopCard from "pages/StatusCard/TopCard";
import ContainerEditHeader from "./component/edit/containerEditHeader";
import ContainerEdit from "./component/edit/index";
import { ITerminalTypes } from "pages/OnboardingModal/onboarding";
import Toastr from "Common/Components/Toastr";
import DownloadCsv from "pages/ReportContainer/Components/DownloadCsv";
import { IconArchive } from "Common/Components/Icons/Icons";

const Importavailability = (): React.ReactElement => {
  const { state, myDispatch } = useAppContext();
  const {
    containerCount,
    showAddContainerModal,
    searchParams,
    searchPost,
    cLoading,
    status,
    cAddErrorMessage,
    cAddSuccessMessage,
  } = state.containerStates;
  const { loggedUser } = state.appStates;

  const [selectedTerminal, setSelectedTerminal] = useState<ITerminalTypes>();
  const [showEditModal, setShowEditModal] = useState<any | null>(false);
  const [checkedContainer, setCheckedContainer] = useState<any>([]);

  useEffect(() => {
    return () => {
      // On Unmount reset the state
      myDispatch(onAvailabilityReset());
    };
  }, []);

  // Initilized
  useEffect(() => {
    getContainers();
  }, [searchParams, searchPost]);

  // get Containers Value
  const getContainers = () => {
    myDispatch(getcontainerCountStatus(searchPost));
    myDispatch(getContainerLists(searchPost, searchParams));
  };

  // On Paginate
  const onPaginate = (val: any) => {
    let searchParamsD = { ...searchParams, page: val };
    myDispatch(setAvailableParams(null, searchParamsD));
  };
  const addContainerModal = () => {
    myDispatch(toggleAddContainer(true));
  };

  const onCardClick = (terminal: ITerminalTypes) => {
    setSelectedTerminal(terminal);
    let searchPostD = { ...searchPost, terminalId: terminal._id };

    myDispatch(setAvailableParams(searchPostD, searchParams));
  };

  const archivebutton = (containerid: string, e: any) => {
    if (e) {
      setCheckedContainer([...checkedContainer, containerid]);
    } else {
      // remove from list
      setCheckedContainer(
        checkedContainer.filter(
          (cc: any) =>
            // console.log(cc._id !== container._id)
            !cc.includes(containerid)
        )
      );
    }
  };

  const ArchiveContainer = async () => {
    const searchPostD = {
      containerIds: checkedContainer,
    };
    const response = await getArchiveContainer(searchPostD);
    myDispatch(response);
    response.payload.then(async (res) => {
      setCheckedContainer([]);
      getContainers();
    });
  };

  return (
    <div className="page-wrapper">
      <div className="position-relative subheader-wrapper">
        {showEditModal ? (
          <ContainerEditHeader hide={() => setShowEditModal(false)} />
        ) : (
          <SubHeaderTab
            val="importAvailabilityItems"
            buttons={
              <Fragment>
                <DownloadCsv
                  page="importAvailability"
                  searchParams={searchParams}
                  searchPost={searchPost}
                />
                {/* <button className="btn btn-outline-light mr-2">
                  <CustomIconSpreadSheet />
                </button>
                <button className="btn btn-outline-light mr-2">
                  <IconDownload />
                </button>
                <button className="btn btn-outline-light mr-2">
                  <IconSendEmail />
                </button> */}
                <button className="btn btn-success ml-2" onClick={() => ArchiveContainer()}>
                  <IconArchive />
                </button>
              </Fragment>
            }
          />
        )}
        {!showEditModal ? (
          <div className="page-fluid">
            {cAddSuccessMessage && (
              <Toastr show={true} status="success" message={"Container Added Successfully!"} />
            )}
            <div className="top-page">
              <div className="pb-15 d-flex align-items-center">
                <h4 className="mb-0">Containers</h4>
                <div className="ml-auto">
                  <Pagination
                    className={"ml-auto"}
                    pageRangeDisplayed={5}
                    totalItemsCount={containerCount}
                    itemsCountPerPage={searchParams.limit}
                    onChange={onPaginate}
                    activePage={searchParams.page}
                  />
                </div>
              </div>

              <div className={`form-row`}>
                {loggedUser?.terminal?.length > 1 &&
                  loggedUser?.terminal.map((terminal: any, i: number) => (
                    <CardView
                      key={i}
                      terminal={terminal}
                      l={loggedUser?.terminal?.length}
                      onCardClick={onCardClick}
                      selectedTerminal={selectedTerminal}
                    />
                  ))}
              </div>
              <CountCards />
              <div className="form-row">
                <div className="col-12">
                  {loggedUser?.terminal?.length > 0 ? (
                    <ContainerAddForm />
                  ) : (
                    <div className="alert alert-danger" role="alert">
                      Please Add Terminal to start tracking containers.
                    </div>
                  )}
                </div>
              </div>
              {/* Search and Filter */}
              <FilterRow />
            </div>
            <ContainerTable setShowEditModal={setShowEditModal} selectContainer={archivebutton} />
          </div>
        ) : (
          <ContainerEdit />
        )}
      </div>
    </div>
  );
};

export default Importavailability;
