import { api } from "config";
import axios from "axios";
import { headers } from "config/api";
import _ from "lodash";
import { objToQueryParams } from "utils/commonFunctions";

// const api = axios.create({
//   baseURL: `https://trackos-devapi.axle.network/`,
//   headers,
// })

export const getVesselLists = (data?: any, params?: any) => {
  let url = `api/vessels`;
  url = params ? url + "?" + objToQueryParams(params) : url;
  return {
    type: "VESSELLISTS",
    payload: api.post(url, data),
  };
};

export const setVesselParams = (searchPost?: any, searchParams?: any) => {
  return {
    type: "SETVESSELPARAM",
    payload: { searchPost, searchParams },
  };
};
