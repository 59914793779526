import { IconDownload } from "Common/Icons";
import { useAppContext } from "contexts/AppContext";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { getDetailedValue } from "utils/commonFunctions";
import moment from "moment";
// import { read, writeFileXLSX } from "xlsx";
import * as XLSX from "xlsx";

const EXCEL_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const EXCEL_EXTENSION = ".xlsx";

interface IProps {
  page: string;
  searchPost?: any;
  searchParams?: any;
}

const DownloadMatrixCsv = (props: IProps) => {
  const { state, myDispatch } = useAppContext();
  const { successfulMatricContainers } = state.SucessfulMatricsContainerStates;

  const [downloading, setDownloading] = useState<any | null>(null);

  const onDownload = async () => {
    setDownloading(true);
    convertToCSV(successfulMatricContainers, props.page);
    setDownloading(false);
  };
  return (
    <button disabled={downloading} className="btn btn-success" onClick={() => onDownload()}>
      {downloading && (
        <span
          className="spinner-border spinner-border-sm mr-2"
          role="status"
          aria-hidden="true"
        ></span>
      )}

      <IconDownload />
    </button>
  );
};

export default DownloadMatrixCsv;

const convertToCSV = (values: any[], page: string) => {
  let data = [];
  data = values.map((value: any, key: number) => {
    console.log(value, "values of the data is ");

    let csvVal: any = {};
    csvVal["S no"] = key + 1;

    csvVal["Status "] = value.status;
    csvVal["Container "] = value.containerNo;
    csvVal["Load "] = value.drayosRef;
    csvVal["Port "] = value.portId;
    csvVal["User Id "] = value.userId;
    csvVal[" Duration "] = value.Duration;

    return csvVal;
  });
  let fdata: Blob;
  const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
  const workbook: XLSX.WorkBook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
  const excelBuffer: any = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  fdata = new Blob([excelBuffer], {
    type: EXCEL_TYPE,
  });

  let csvURL = null;

  csvURL = window.URL.createObjectURL(fdata);
  let tempLink = document.createElement("a");
  tempLink.href = csvURL;
  tempLink.setAttribute("download", `${page + new Date().getTime() + EXCEL_EXTENSION}`);
  tempLink.click();
};
