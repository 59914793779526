export const initialSucessfulMatricsContainerValues = {
  successfulMatricsContainers: [],
  sLoading: false,
  successfulMatricContainerCount: 0,
  totalSuccessfulMatricContainerCount: 0,

  searchParams: {
    limit: 50,
    page: 1,
  },
};

export const sucessfulMatricsContainerReducer = (
  state: any,
  action: {
    type: string;
    payload: any;
  }
) => {
  switch (action.type) {
    case "SUCCESSFULMATRICSCONTAINERS_INPROGRESS": {
      return {
        ...state,
        sLoading: true,
      };
    }
    case "SUCCESSFULMATRICSCONTAINERS_SUCCESS": {
      let successfulMatricContainers = action.payload.data.data;
      return {
        ...state,
        sLoading: false,
        successfulMatricContainers: successfulMatricContainers,
        successfulMatricContainerCount: action.payload.data.successContainersCount,
        totalSuccessfulMatricContainerCount: action.payload.data.total,
      };
    }
    case "SUCCESSFULMATRICSCONTAINERS_REJECTED": {
      return {
        ...state,
        sLoading: false,
        successfulMatricContainers: [],
        successfulMatricContainerCount: 0,
        totalSuccessfulMatricContainerCount: 0,
      };
    }
    case "SET_SUCCESSFULMATRICSCONTAINERS_PARAMS": {
      let param = action.payload.searchParams ? action.payload.searchParams : state.searchParams;
      console.log(param, "param test");
      let post = action.payload.searchPost ? action.payload.searchPost : state.searchPost;
      console.log("post as payload", post);
      return {
        ...state,
        searchParams: param,
        searchPost: { ...post },
        sLoading: true,
      };
    }

    // case "RESET_SUCCESSFULMATRICSCONTAINERS_PARAMS": {
    //   return {
    //     ...state,
    //     searchParams: {
    //       limit: 50,
    //       page: 1,
    //       reset: true,
    //     },
    //     searchPost: { ...state.searchPost, portId: "", containerOwner: "", reset: true },
    //   };
    // }

    default:
      return state;
  }
};
