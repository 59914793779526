import Loader from "Common/Components/Loader";
import { caseStatus } from "Common/Components/StatusBadge";
import { useAppContext } from "contexts/AppContext";
import moment from "moment";
import ContainerDetails from "pages/Importavailability/component/ContainerDetails";
import ContainerIngate from "pages/Importavailability/component/ContainerIngate";
import { Fragment, useState } from "react";
import { getDetailedValue } from "utils/commonFunctions";
import { getArchiveContainers, setArchiveContainersParams } from "../action";
import { tableHeaders } from "../constant";

const ArchiveContainerTable = () => {
  const { state, myDispatch } = useAppContext();
  const { archiveContainers, aLoading, searchParams, searchPost } = state.archiveContainerStates;
  const { loggedUser } = state.appStates;
  const { containerSizes, containerOwners, containerTypes, ports } = state.configStates;

  const [headers, setTableHeader] = useState<any | null>(tableHeaders);
  const [viewDetails, setViewDetails] = useState<any | null>();
  const [viewIngate, setViewIngate] = useState<any | null>();

  //HANDLE FUNCTION

  // get size and type
  const getSizeType = (containerSize: any, containerType: any) => {
    let sizeVal = getDetailedValue(containerSize, containerSizes);

    let typeVal = getDetailedValue(containerType, containerTypes);
    return `${sizeVal} ${sizeVal && typeVal && "-"} ${typeVal}`;
  };
  // get port
  const getPortTerminalName = (val: any, vtype: "port" | "terminal") => {
    if (typeof val === "string") {
      return getDetailedValue(val, vtype === "port" ? ports : loggedUser?.terminal);
    } else {
      return val?.name;
    }
  };

  // get Empty Return
  const getEmptyReturn = (vals: any) => {
    let emptyReturn: string[] = [];
    vals.length > 0 &&
      vals.map((val: any) => {
        if (typeof val === "object") {
          emptyReturn.push(val.port);
        } else {
          let portname = getPortTerminalName(val, "port");
          if (portname) emptyReturn.push(portname);
        }
      });
    return emptyReturn;
  };

  // Sorting
  const onSort = (obj: any) => {
    let sortString = ``;
    headers.map((D: any) => {
      if (D.value === obj.value) {
        D.sort = D.sort === "-" || D.sort === "" ? "+" : "-";
        sortString = `${D.sort === "+" ? "" : D.sort}${D.value}`;
        D.active = true;
      } else {
        D.active = false;
      }
      return D;
    });
    if (sortString !== "") {
      const searchParamsD = {
        ...searchParams,
        sort: sortString,
      };
      myDispatch(setArchiveContainersParams(searchPost, searchParamsD));
    }
  };

  return (
    <Fragment>
      <div className="table-responsive" style={{ height: "calc(100vh - 249px)" }}>
        {aLoading && <Loader />}
        <table className="table table-card">
          <thead>
            <tr>
              <th scope="col" className="">
                Sno
              </th>
              {headers.map((item: any, i: number) => {
                let sortIcon = "table-sort table-sort--default";
                if (item.sort === "-" && item.active) {
                  sortIcon = "table-sort table-sort--ascending";
                }
                if (item.sort === "+" && item.active) {
                  sortIcon = "table-sort table-sort--descending";
                }
                return (
                  <th key={i} onClick={() => onSort(item)} className={item.sort ? sortIcon : ""}>
                    {item.label}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {archiveContainers.length ? (
              archiveContainers.map((archiveContainer: any, i: number) => {
                return (
                  <tr key={i}>
                    <td className="text-center">{i + 1}</td>
                    <td>
                      <div
                        className="pointer text-primary"
                        onClick={() => {
                          setViewDetails(archiveContainer);
                          // if (archiveContainer.containerType || archiveContainer.containerSize) {

                          // }
                        }}
                      >
                        {archiveContainer.containerNo}
                      </div>
                    </td>
                    <td>{caseStatus(archiveContainer.status)}</td>
                    <td>
                      {archiveContainer.custom && <p>{"Custom: " + archiveContainer.custom}</p>}
                      {archiveContainer.freight && <p>{"Freight: " + archiveContainer.freight}</p>}
                      {archiveContainer.brokerHold && (
                        <p>{"Broker: " + archiveContainer.brokerHold}</p>
                      )}
                    </td>
                    <td>{getDetailedValue(archiveContainer.containerOwner, containerOwners)}</td>
                    <td>
                      {getSizeType(archiveContainer.containerSize, archiveContainer.containerType)}
                    </td>
                    <td>{getPortTerminalName(archiveContainer.portId, "port")}</td>
                    <td>
                      {archiveContainer.eta
                        ? moment(archiveContainer.eta).format("MM/DD")
                        : archiveContainer.eta
                        ? moment(archiveContainer.sslEta).format("MM/DD")
                        : ""}
                    </td>
                    <td>
                      {archiveContainer.status !== "On Vessel" &&
                        archiveContainer.dischargeDate &&
                        moment(archiveContainer.dischargeDate).format("MM/DD")}
                    </td>
                    <td>
                      {archiveContainer.status !== "On Vessel" &&
                        archiveContainer.lastFreeDay &&
                        moment(archiveContainer.lastFreeDay).format("MM/DD")}
                    </td>

                    <td>
                      {archiveContainer.status !== "On Vessel" &&
                        archiveContainer.outGateDate &&
                        moment(archiveContainer.outGateDate).format("MM/DD")}
                    </td>
                    <td className="">
                      {getEmptyReturn(archiveContainer.emptyReturnLocation)
                        .toString()
                        .replaceAll("_", " ")}
                    </td>
                    <td>
                      {archiveContainer.status !== "On Vessel" && archiveContainer.terminatedDate
                        ? moment(archiveContainer.terminatedDate).format("MM/DD")
                        : archiveContainer.inGate?.date
                        ? moment(archiveContainer.inGate?.date).format("MM/DD")
                        : ""}
                    </td>
                    <td>{archiveContainer.vesselName}</td>
                    <td>{archiveContainer.voyageName}</td>
                    <td>{archiveContainer.billOfLading}</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={100}>Data Not Found</td>
              </tr>
            )}
          </tbody>
        </table>
        {viewIngate && viewIngate.containerNo && (
          <ContainerIngate values={viewIngate} onCloseModal={() => setViewIngate(null)} />
        )}
        {/* Container Details Modal */}
        {viewDetails && viewDetails.containerNo && (
          <ContainerDetails values={viewDetails} onCancel={() => setViewDetails(null)} />
        )}
      </div>
    </Fragment>
  );
};

export default ArchiveContainerTable;
