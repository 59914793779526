export const vesselTabItems: any = {
  vesselSchedulePort: [
    {
      id: 1,
      title: "APM",
      path: "/vessel-schedule/APM",
      label: "APM",
    },
    {
      id: 2,
      title: "MAHER_TERMINAL",
      path: "/vessel-schedule/MAHER",
      label: "MAHER_TERMINAL",
    },
    {
      id: 3,
      title: "GCT_BAYONNE",
      path: "/vessel-schedule/GCTBAYONNE",
      label: "GCT_BAYONNE",
    },
    {
      id: 4,
      title: "GCT_NY",
      path: "/vessel-schedule/GCTNY",
      label: "GCT_NY",
    },
    {
      id: 5,
      title: "PNCT",
      path: "/vessel-schedule/PNCT",
      label: "PNCT",
    },
  ],
};

export const portOpts = ["APM", "MAHER_TERMINAL", "GCT_BAYONNE", "GCT_NY", "PNCT"];

export const tableHeaders = [
  {
    label: "Vessel Name",
    value: "vesselName",
    sort: "-",
  },
  {
    label: "Voyage Name",
    value: "voyageName",
    sort: "-",
  },
  {
    label: "Vessel Code",
    value: "vesselCode",
    sort: "-",
  },
  {
    label: "ETA",
    value: "etaOnSite",
    sort: "-",
  },
  {
    label: "ERD",
    value: "earliestReturnDate",
    sort: "-",
  },
  {
    label: "ERDRF",
    value: "earliestReturnDateRf",
    sort: "-",
  },
  {
    label: "CutOff Date",
    value: "cutOffDate",
    sort: "-",
  },
  {
    label: "CutOff Date RF",
    value: "cutOffDateRf",
    sort: "-",
  },
  {
    label: "Hazardous CutOff",
    value: "hazardousCutOffDate",
    sort: "-",
  },
  {
    label: "Extra Tracer Data",
    value: "vesselExtraTracerData",
  },
];
