import React, { useEffect, useState } from "react";
import SubHeaderTab from "Common/Components/SubHeaderTab";
import SearchFilter from "../Common/SearchFilter";
import { useAppContext } from "contexts/AppContext";
import TerminalTabs from "../Common/TerminalTabs";
import Loader from "Common/Components/Loader";
import moment from "moment";
import { getTimeZone } from "../Common/utils";
import { ScreentshotPropsI } from "../location";

const TerminalScreengrabs = (props: ScreentshotPropsI) => {
  const { tab } = props;
  const { state } = useAppContext();
  const { empties, eLoading } = state.emptyStates;
  const { userSelectedTerminals } = state.appStates;

  // ---------------------------------------------------- STATE MANAGEMENT---------------------------------------------------//
  const [s3Url, setS3Url] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [updateTime, setUpdateTime] = useState<string>("");
  const [terminal, setTerminal] = useState("");

  // ---------------------------------------------------- HOOKS MANAGEMENT ---------------------------------------------------//
  useEffect(() => {
    let datas = empties?.data;
    if (datas?.length && eLoading) {
      setLoading(true);
      const date = new Date(empties.updatedTime);
      const userTerminal = terminal
        ? terminal
        : userSelectedTerminals[0]?.name === "New Jersey/NY"
        ? "NY"
        : "LA";
      const timeZone = getTimeZone(userTerminal);
      setUpdateTime(moment(date).format("LLLL"));

      //for la and Ny s3url and for new Market s3Url
      const s3UrlData = datas[0]?.data?.s3url || datas[0]?.data?.s3Url;
      if (Array.isArray(s3UrlData)) {
        setS3Url(s3UrlData?.reverse());
      } else {
        setS3Url(s3UrlData);
      }
    } else {
      setS3Url("");
      setLoading(false);
    }
  }, [empties, eLoading]);

  const onTerminalChange = (value: string) => {
    setTerminal(value);
  };

  return (
    <div className="page-wrapper">
      {!eLoading && <Loader />}
      <div className="position-relative subheader-wrapper">
        <SubHeaderTab val="emptyReturnItems" />
        <div className="page-fluid">
          <div className="top-page">
            <TerminalTabs terminal={tab} value="historyEmpties" />
            <SearchFilter
              tab="Terminal Screengrabs"
              terminal={tab}
              page="screenshot"
              onTerminalChange={onTerminalChange}
              imageUrl={s3Url}
            />
            <div className="img-wrapper p-30 bg-white rounded-6 mt-15 position-relative ">
              {loading && <div className="img-animation">Loading...</div>}
              {s3Url ? (
                <>
                  <p className="d-flex justify-content-center align-items-center font-14 mb-2 font-weight-500">
                    <span className="ml-1">{updateTime}</span>
                  </p>
                  {!Array.isArray(s3Url) ? (
                    <embed
                      onLoad={() => setLoading(false)}
                      onError={() => setLoading(false)}
                      width="100%"
                      className=""
                      height="570px"
                      // style={{ Heigth: "560px" }}
                      type="text/html"
                      src={s3Url}
                    />
                  ) : (
                    s3Url.map((url: string, i: number) => (
                      <embed
                        key={i}
                        onLoad={() => setLoading(false)}
                        onError={() => setLoading(false)}
                        width="100%"
                        type="text/html"
                        height="570px"
                        className=""
                        src={url}
                      />
                    ))
                  )}
                </>
              ) : // <span>No Image Found</span>
              null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TerminalScreengrabs;
