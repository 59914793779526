import axios from "axios";

export let headers = {
  "Access-Control-Allow-Origin": "*",
  "Cache-Control": "no-cache",
  Pragma: "no-cache",
  Expires: "0",
  Authorization: localStorage.getItem("accessToken"),
} as any;

// console.log("api", `${process.env.REACT_APP_API_URL}`);

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  headers,
});

api.interceptors.request.use(function (config: any) {
  const accessToken = localStorage.getItem("accessToken");
  const refreshToken = localStorage.getItem("refreshToken");
  config.headers.Authorization = `${accessToken ? accessToken : refreshToken}`;
  config.headers.xapikey = process.env.REACT_APP_XAPI;
  return config;
});

api.interceptors.response.use(
  (response) =>
    new Promise(async (resolve, reject) => {
      resolve(response);
    }),
  (error) => {
    if (!error.response) {
      return new Promise((resolve, reject) => reject(error));
    }
    if (error.response.status === 401) {
      // localStorage.clear();
      // window.location.href = "/login";
    }
    if (error.response && error.response.data) {
      return new Promise(async (resolve, reject) => reject(error.response.data));
    }
  }
);

export default api;
