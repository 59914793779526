import { api } from "config";
import { objToQueryParams, removeNullEmpty } from "utils/commonFunctions";

export const getWrongDataReports = (params?: any, data?: any) => {
  let postData = removeNullEmpty(data);
  let url = "api/dashboard/wrongDataReport";
  url = params ? url + "?" + objToQueryParams(params) : url;
  return {
    type: "WRONGDATAREPORT",
    payload: api.post(url, postData),
  };
};

export const setWrongDataReportParams = (searchParams?: any, searchPost?: any) => {
  return {
    type: "SET_WRONGDATAREPORT_PARAMS",
    payload: { searchParams, searchPost },
  };
};

export const resetWrongDataReportParams = () => {
  return {
    type: "RESET_WRONGDATAREPORT_PARAMS",
  };
};
