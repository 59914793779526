import { yupResolver } from "@hookform/resolvers/yup";
import CustomInputField from "Common/Components/Inputs/CustomInputField";
import CustomSelectField from "Common/Components/Inputs/CustomSelectField";
import CustomTextAreaField from "Common/Components/Inputs/CustomTextAreaField";
import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { trackSchema } from "./Components/TrackValidation";
import { TrackFormValue } from "./tracking";

const colourOptions = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

const Tracking = () => {
  // REACT HOOK FORM
  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<TrackFormValue>({
    mode: "onBlur",
    resolver: yupResolver(trackSchema),
  });

  return (
    <div className="page-wrapper">
      <div className="position-relative subheader-wrapper">
        <div className="page-fluid">
          <div className="card">
            <div className="card-body">
              <h2>Tracking page....</h2>
              <p>Under Construction</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    // <form onSubmit={handleSubmit(onSubmit)}>
    //   <div className="container">
    //     <div className="form-column justify-content-center">
    //       <div className="col-md-4 py-2">
    //         <CustomTextAreaField
    //           name="containerNo"
    //           placeholder="Enter Container No.(s)"
    //           register={register}
    //           helper={errors.containerNo && errors.containerNo.message}
    //           error={Boolean(errors.containerNo)}
    //         />
    //       </div>
    //       <div className="col-md-4 py-2">
    //         <CustomSelectField
    //           control={control}
    //           className="basic-single"
    //           classNamePrefix="select"
    //           defaultValue={colourOptions[0]}
    //           isDisabled={false}
    //           isLoading={false}
    //           isClearable={true}
    //           isSearchable={true}
    //           name="color"
    //           options={colourOptions}
    //         />
    //       </div>
    //       <div className="col-md-4 py-2">
    //         <CustomInputField
    //           name="username"
    //           placeholder="Enter Username"
    //           type="text"
    //           register={register}
    //           helper={errors.username && errors.username.message}
    //           error={Boolean(errors.username)}
    //         />
    //       </div>
    //       <div className="col-md-4 py-2">
    //         <CustomInputField
    //           name="password"
    //           placeholder="Enter Password"
    //           type="password"
    //           register={register}
    //           helper={errors.password && errors.password.message}
    //           error={Boolean(errors.password)}
    //         />
    //       </div>
    //       <div className="col-md-4 py-2">
    //         <CustomInputField
    //           name="carrierId"
    //           placeholder="Enter Carrier Id"
    //           type="text"
    //           register={register}
    //           helper={errors.carrierId && errors.carrierId.message}
    //           error={Boolean(errors.carrierId)}
    //         />
    //       </div>
    //     </div>
    //   </div>
    // </form>
  );
};

export default Tracking;
