import React, { useState } from "react";
import { CustomIconSpreadSheet } from "../../Common/Components/Icons/CustomIcons";
import { IconDownload, IconPlus, IconSendEmail } from "../../Common/Components/Icons/Icons";
import SubHeaderTab from "../../Common/Components/SubHeaderTab";
import TopCard from "./TopCard";

const StatusCard = () => {
  return (
    <div className="page-wrapper">
      <div className="position-relative subheader-wrapper">
        <SubHeaderTab
          val="importAvailabilityItems"
          buttons={
            <div className="">
              <button className="btn btn-outline-light btn--sm mr-2">
                <CustomIconSpreadSheet />
              </button>
              <button className="btn btn-outline-light btn--sm mr-2">
                <IconDownload />
              </button>
              <button className="btn btn-outline-light btn--sm mr-2">
                <IconSendEmail />
              </button>
              <button className="btn btn-success">
                <IconPlus className="mr-2" />
                Add New Container
              </button>
            </div>
          }
        />
        <div className="page-fluid">
          <div className="top-page">
            {/*Cards */}
            <TopCard />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatusCard;
