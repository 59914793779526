import React, { Fragment, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import { useAppContext } from "contexts/AppContext";
import Loader from "Common/Components/Loader";

interface IProps {
  values: any;
  onCloseModal: () => void;
}
interface IEProps {
  url: string | "";
}

const ContainerIngate = (props: IProps): React.ReactElement => {
  const { values, onCloseModal } = props;
  const { state, myDispatch } = useAppContext();
  const { ports, mLoading } = state.configStates;
  const [loading, setLoading] = useState(true);
  const [showImg, setShow] = useState<string | null>(null);
  const [docUrl, setDocUrl] = useState<string | "">("");

  useEffect(() => {
    if (values.outGate?.s3Url) {
      setDocUrl(values.outGate?.s3Url);
      setShow("Ingate");
    } else if (values.etaS3url) {
      setDocUrl(values.etaS3url);
      setShow("Eta");
    }
  }, []);

  useEffect(() => {
    if (showImg === "InGate") {
      setDocUrl(values.inGate?.s3Url);
    }
    if (showImg === "OutGate") {
      setDocUrl(values.outGate?.s3Url);
    }
    if (showImg === "Eta") {
      setDocUrl(values.etaS3url);
    }
  }, [showImg]);

  return (
    <Fragment>
      <Modal show={values && values.containerNo} size="xl">
        <Modal.Header>
          <Modal.Title>
            {values && values.containerNo}'s {showImg}
          </Modal.Title>
        </Modal.Header>
        {values && values.containerNo && (
          <Modal.Body>
            <div className="btn-group" role="group" aria-label="Basic example">
              {mLoading && <Loader />}
              {(values.containerNo === "MRSU6052461" || values.ingate?.imagePath?.s3Url) && (
                <button
                  type="button"
                  className={
                    showImg === "Ingate" ? `btn btn-secondary` : `btn btn-outline-secondary`
                  }
                  onClick={() => setShow("Ingate")}
                >
                  Ingate Outgate
                </button>
              )}
              {values.etaS3url && (
                <button
                  type="button"
                  className={showImg === "Eta" ? `btn btn-secondary` : `btn btn-outline-secondary`}
                  onClick={() => setShow("Eta")}
                >
                  ETA Image
                </button>
              )}
              {values.inGate?.s3Url && (
                <button
                  type="button"
                  className={
                    showImg === "InGate" ? `btn btn-secondary` : `btn btn-outline-secondary`
                  }
                  onClick={() => setShow("InGate")}
                >
                  In gate Image
                </button>
              )}

              {values.outGate?.s3Url && (
                <button
                  type="button"
                  className={
                    showImg === "OutGate" ? `btn btn-secondary` : `btn btn-outline-secondary`
                  }
                  onClick={() => setShow("OutGate")}
                >
                  Out Gate Image
                </button>
              )}
            </div>
            <hr />
            <div style={{ height: "500px", overflow: "auto" }}>
              {docUrl && <embed type="text/html" src={docUrl} width="100%" height={"100%"}></embed>}

              {!values.etaS3url && !values.inGate?.s3Url && !values.outGate?.s3Url && (
                <div>
                  <p>No Image Screenshots Available</p>
                  <button className="btn btn-close" onClick={() => onCloseModal()}>
                    Cancel
                  </button>
                </div>
              )}
            </div>
          </Modal.Body>
        )}

        <Modal.Footer>
          <button className="btn btn-close" onClick={() => onCloseModal()}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};
export default ContainerIngate;
