import React, { useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { ForgetFormValue } from "../login";
import CustomInputField from "Common/Components/Inputs/CustomInputField";
import { forgetSchema } from "./AuthValidation";
import { useAppContext } from "contexts/AppContext";
import { forgotPassword, onAuthReset } from "reducers/action/appAction";
import Toastr from "Common/Components/Toastr";
// import { isLoggedIn as isAUth } from "utils";

const ForgetForm = (props: any) => {
  const { handleEmail } = props;
  let navigate = useNavigate();
  const { state, myDispatch } = useAppContext();
  const { errorMessage, isLoggedIn, loading, isResetSuccess } = state.appStates;
  useEffect(() => {
    return () => {
      // On Unmount reset the state
      myDispatch(onAuthReset());
    };
  }, []);

  // REACT HOOK FORM
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<ForgetFormValue>({
    mode: "onBlur",
    resolver: yupResolver(forgetSchema),
  });

  // FORM SUBMIT
  const onFormSubmit: SubmitHandler<ForgetFormValue> = async (data) => {
    let res = await forgotPassword(data);
    myDispatch(res);
  };
  // console.log("rest", isResetSuccess);

  return (
    <div className="signup-wrapper--right bg-animation h-100 p-20">
      {isResetSuccess && (
        <Toastr show={true} status="success" message="Reset Link sent successfully" />
      )}
      <div className="d-flex align-items-center justify-content-end">
        <div className="text-muted mr-10">Already have an account?</div>
        <Link to="/login">
          <button className="btn btn btn-outline-light">Sign in</button>
        </Link>
      </div>
      <div className="d-flex align-items-center justify-content-center h-100">
        <div className="form-wrapper w-max p-50 bg-white rounded-10 shadow-5">
          <h3 className="text-center mb-30">Start Improving Your Shipping!</h3>
          <form className="form-sm" onSubmit={handleSubmit(onFormSubmit)}>
            {!isResetSuccess && errorMessage && (
              <div className="alert alert-warning" role="alert">
                {errorMessage}
              </div>
            )}
            {!isResetSuccess && (
              <CustomInputField
                autoComplete="off"
                placeholder="Email*"
                name="email"
                type="text"
                required
                register={register}
                error={Boolean(errors.email)}
                helper={errors.email ? errors.email.message : ""}
                label={
                  <span>
                    <span className="text-danger">*</span> Email
                  </span>
                }
                className="form-control"
              />
            )}
            <div className="boxes mt-4">{/* <input type="checkbox" id="box-1" /> */}</div>
            <div className="text-center">
              {!isResetSuccess && (
                <button type="submit" className="btn btn-primary btn-lg btn-block mt-40">
                  {loading && (
                    <span
                      className="spinner-border spinner-border-sm mr-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                  Submit Email
                </button>
              )}
              {isResetSuccess && (
                <button type="button" className="btn btn-success btn-lg btn-block mt-40">
                  Please Check Your Email for Password Reset
                </button>
              )}
            </div>

            <p className="pt-3 text-center">
              Don't have an account?
              <Link to="/signup" className="ml-2">
                Sign Up
              </Link>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgetForm;
