import { useEffect, useMemo, useState } from "react";
import SearchFilter from "./components/SearchFilter";
import Card from "./components/Card";
import { Col, Row } from "react-bootstrap";
import { useAppContext } from "contexts/AppContext";
import Loader from "Common/Components/Loader";
import { ICronData, ICronTableData, IHeader, IStatus } from "./types/TTimeScaleTypes";
import TimeScaleTable from "./components/TimeScaleTable";
import { terminalTypes } from "./expressions";
import { IconEye } from "Common/Icons";
import TableLoader from "./components/TableLoader";

const defaultTimeScaleHeader: IHeader<ICronTableData>[] = [
  { key: "cronId", label: "Cron ID" },
  { key: "containerNo", label: "Container No" },
  { key: "reference_number", label: "Load No" },
  { key: "carrierId", label: "Customer" },
  { key: "eta", label: "ETA" },
  { key: "freight", label: "Freight Released/Hold" },
  { key: "custom", label: "Customs Released/Hold" },
  { key: "lastFreeDay", label: "Last Free Day" },
  { key: "status", label: "Status" },
  { key: "portNote", label: "Port Note" },
  {
    key: "errorMessage",
    label: "Problem Container Remarks",
    // render: (value, row) => {
    //     return(
    //     <span style={{ cursor: "pointer", textAlign: "center" }}>
    //         {value ? <IconEye /> : 'null'}
    //     </span>
    // )}
  },
];

const TimeScaleReport = () => {
  const { state } = useAppContext();
  const { drayosUsers } = state?.dashboardStates;

  const {
    loading,
    eta,
    status,
    freight,
    lastFreeDay,
    showCard,
    tableLoading,
    tableData,
    showTable,
  }: {
    showTable: boolean;
    showCard: boolean;
    loading: boolean;
    eta: any[];
    status: any[];
    freight: any[];
    lastFreeDay: any[];
    tableLoading: boolean;
    tableData: ICronData[];
  } = state.timescaleReportStates;
  console.log({ tableData });
  const fields: IStatus["type"][] = ["status", "eta", "freight", "lastFreeDay"];
  const cardData = {
    eta,
    status,
    freight,
    lastFreeDay,
  };

  const initialParams = {
    carrierId: "",
    startDate: null,
    endDate: null,
    portCode: "",
    hasNo: "",
    fields: [],
    terminalTypes: "",
    userChanged: [],
  };
  const [params, setParams] = useState<any>(initialParams);
  const [isProblemContainer, setIsProblemContainer] = useState(false);
  const defaultTimeScaleTableData: ICronTableData[] = useMemo(() => {
    return (
      tableData[0]?.loadInfo
        ?.filter((re) => re)
        .map((re) => {
          const entry: ICronTableData = {
            cronId: tableData[0]?.cronId,
            reference_number: re?.reference_number,
            containerNo: re?.containerNo,
            carrierId:
              drayosUsers?.find((opt: any) => opt?._id === re?.carrierId)?.email || re?.carrierId,
            eta: re?.eta,
            freight: re?.freight,
            custom: re?.custom,
            lastFreeDay: re?.lastFreeDay,
            status: re?.status,
            portNote: re?.portNote,
            errorMessage: re?.errorMessage,
          };
          return entry;
        }) || []
    );
  }, [tableData]);

  const filterBasedTableData = useMemo(() => {
    if (isProblemContainer && params.userChanged?.length > 0 && params.fields?.length > 0) {
      const combinedData: ICronData["loadInfo"] = [];
      const addMergedEntries = (sourceArray: ICronData["loadInfo"]) => {
        sourceArray?.forEach((re) => {
          const existing = combinedData.find(
            (entry) => entry?.reference_number === re?.reference_number
          );
          if (existing) {
            Object.assign(existing, re);
          } else {
            combinedData.push(re);
          }
        });
      };

      addMergedEntries(tableData[0]?.loadInfo?.filter((re) => re));
      addMergedEntries(tableData[0]?.userChangedInfo?.filter((re) => re));
      addMergedEntries(tableData[0]?.problemContainerInfo?.filter((re) => re));

      return (
        combinedData?.map((re) => {
          const entry: ICronTableData = {
            cronId: tableData[0]?.cronId,
            reference_number: re?.reference_number,
            containerNo: re?.containerNo,
            carrierId:
              drayosUsers?.find((opt: any) => opt?._id === re?.carrierId)?.email || re?.carrierId,
            eta: re?.eta,
            freight: re?.freight,
            custom: re?.custom,
            lastFreeDay: re?.lastFreeDay,
            status: re?.status,
            portNote: re?.portNote,
            errorMessage: re?.errorMessage,
          };
          return entry;
        }) || []
      );
    }
    if (params.fields?.length > 0 && params.userChanged?.length > 0) {
      const getCombinedData = tableData[0]?.loadInfo
        ?.filter((re) => re)
        .map((re) => {
          const userChangedInfo = tableData[0]?.userChangedInfo?.find(
            (el) => el?.reference_number === re?.reference_number
          );
          return userChangedInfo ? { ...re, ...userChangedInfo } : re;
        });
      tableData[0]?.userChangedInfo
        ?.filter((re) => re)
        .forEach((re) => {
          if (!getCombinedData.find((el) => el?.reference_number === re?.reference_number)) {
            getCombinedData.push(re);
          }
        });
      return (
        getCombinedData?.map((re) => {
          const entry: ICronTableData = {
            cronId: tableData[0]?.cronId,
            reference_number: re?.reference_number,
            containerNo: re?.containerNo,
            carrierId:
              drayosUsers?.find((opt: any) => opt?._id === re?.carrierId)?.email || re?.carrierId,
            eta: re?.eta,
            freight: re?.freight,
            custom: re?.custom,
            lastFreeDay: re?.lastFreeDay,
            status: re?.status,
            portNote: re?.portNote,
            errorMessage: re?.errorMessage,
          };
          return entry;
        }) || []
      );
    }

    if (params.fields?.length > 0 && isProblemContainer) {
      const getCombinedData = tableData[0]?.loadInfo
        ?.filter((re) => re)
        .map((re) => {
          const problemInfo = tableData[0]?.problemContainerInfo?.find(
            (el) => el?.reference_number === re?.reference_number
          );
          return problemInfo ? { ...re, ...problemInfo } : re;
        });

      tableData[0]?.problemContainerInfo
        ?.filter((re) => re)
        .forEach((re) => {
          if (!getCombinedData.find((el) => el?.reference_number === re?.reference_number)) {
            getCombinedData.push(re);
          }
        });
      return getCombinedData?.map((re) => {
        const entry: ICronTableData = {
          cronId: tableData[0]?.cronId,
          reference_number: re?.reference_number,
          containerNo: re?.containerNo,
          carrierId:
            drayosUsers?.find((opt: any) => opt?._id === re?.carrierId)?.email || re?.carrierId,
          eta: re?.eta,
          freight: re?.freight,
          custom: re?.custom,
          lastFreeDay: re?.lastFreeDay,
          status: re?.status,
          portNote: re?.portNote,
          errorMessage: re?.errorMessage,
        };
        return entry;
      });
    }
    if (isProblemContainer && params.userChanged?.length > 0) {
      const getCombinedData = tableData[0]?.userChangedInfo
        ?.filter((re) => re)
        .map((re) => {
          const problemInfo = tableData[0]?.problemContainerInfo?.find(
            (el) => el?.reference_number === re?.reference_number
          );
          return problemInfo ? { ...re, ...problemInfo } : re;
        });

      tableData[0]?.problemContainerInfo
        ?.filter((re) => re)
        .forEach((re) => {
          if (!getCombinedData.find((el) => el?.reference_number === re?.reference_number)) {
            getCombinedData.push(re);
          }
        });

      return (
        getCombinedData?.map((re) => {
          const entry: ICronTableData = {
            cronId: tableData[0]?.cronId,
            reference_number: re?.reference_number,
            containerNo: re?.containerNo,
            carrierId:
              drayosUsers?.find((opt: any) => opt?._id === re?.carrierId)?.email || re?.carrierId,
            eta: re?.eta,
            freight: re?.freight,
            custom: re?.custom,
            lastFreeDay: re?.lastFreeDay,
            status: re?.status,
            portNote: re?.portNote,
            errorMessage: re?.errorMessage,
          };
          return entry;
        }) || []
      );
    }
    if (params.userChanged?.length > 0) {
      return (
        tableData[0]?.userChangedInfo
          ?.filter((re) => re)
          .map((re) => {
            const entry: ICronTableData = {
              cronId: tableData[0]?.cronId,
              reference_number: re?.reference_number,
              containerNo: re?.containerNo,
              carrierId:
                drayosUsers?.find((opt: any) => opt?._id === re?.carrierId)?.email || re?.carrierId,
              eta: re?.eta,
              freight: re?.freight,
              custom: re?.custom,
              lastFreeDay: re?.lastFreeDay,
              status: re?.status,
              portNote: re?.portNote,
              errorMessage: re?.errorMessage,
            };
            return entry;
          }) || []
      );
    }

    if (isProblemContainer) {
      return (
        tableData[0]?.problemContainerInfo
          ?.filter((re) => re)
          .map((re) => {
            const entry: ICronTableData = {
              cronId: tableData[0]?.cronId,
              reference_number: re?.reference_number,
              containerNo: re?.containerNo,
              carrierId:
                drayosUsers?.find((opt: any) => opt?._id === re?.carrierId)?.email || re?.carrierId,
              eta: re?.eta,
              freight: re?.freight,
              custom: re?.custom,
              lastFreeDay: re?.lastFreeDay,
              status: re?.status,
              portNote: re?.portNote,
              errorMessage: re?.errorMessage,
            };
            return entry;
          }) || []
      );
    }

    return defaultTimeScaleTableData;
  }, [defaultTimeScaleTableData, tableLoading]);

  return (
    <div className="page-wrapper">
      <div className="position-relative subheader-wrapper">
        <div className="page-fluid">
          <div className="top-page">
            <div className="pb-15 d-flex align-items-center">
              <h4 className="mb-0">Timescale Report</h4>
              <div className="ml-auto"></div>
            </div>
            <SearchFilter
              isProblemContainer={isProblemContainer}
              setIsProblemContainer={setIsProblemContainer}
              params={params}
              setParams={setParams}
            />
          </div>

          {loading ? (
            <Loader />
          ) : (
            <div className="mt-4">
              <div className="mt-1 p-4 bg-white">
                <Row>
                  {!showCard ? (
                    <p>Select start date & end date to see report</p>
                  ) : (
                    fields.map((f) => (
                      <Col key={f}>
                        <Card
                          untracked={
                            (Number(cardData[f][0]?.unTrackedRatio || 0) * 100).toFixed(2) + "%"
                          }
                          heading={f}
                          tracked={
                            (Number(cardData[f][0]?.trackedRatio || 0) * 100).toFixed(2) + "%"
                          }
                        />
                      </Col>
                    ))
                  )}
                </Row>
              </div>

              <div className="mt-5">
                {tableLoading ? (
                  <TableLoader />
                ) : !showTable ? (
                  ""
                ) : filterBasedTableData?.length <= 0 ? (
                  "No Table Data found"
                ) : (
                  <TimeScaleTable data={filterBasedTableData} header={defaultTimeScaleHeader} />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TimeScaleReport;
