function isPromise(obj: any) {
  return (
    !!obj &&
    (typeof obj === "object" || typeof obj === "function") &&
    typeof obj.then === "function"
  );
}
// Dispatch Middleware to have inprogress, success and failure actions
export function wrapperDispatch(dispatch: any) {
  return function (action: any) {
    if (isPromise(action.payload)) {
      dispatch({ ...action, type: action.type + "_INPROGRESS" });
      action.payload
        .then((res: any) => {
          dispatch({ ...action, type: action.type + "_SUCCESS", payload: res });
        })
        .catch((err: any) =>
          dispatch({ ...action, type: action.type + "_REJECTED", payload: err })
        );
    } else {
      dispatch(action);
    }
  };
}
