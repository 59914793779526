import ConfirmationAlertModal from "Common/Components/confirmationAlert/ConfirmationAlertModal";
import { CustomIconAlert } from "Common/Components/Icons/CustomIcons";
import { IconCheckCircleOutline, IconTimesCircleOutline } from "Common/Components/Icons/Icons";
import { useAppContext } from "contexts/AppContext";
import { getActivated } from "pages/OnboardingModal/action";
import React, { useEffect } from "react";
import { useLocation, useParams, Navigate, useNavigate, Link } from "react-router-dom";
import { useState } from "react";

const UserActivateForm = () => {
  const { state, myDispatch } = useAppContext();
  // const search = useLocation().search;
  // const userId = new URLSearchParams(search).get("userId");
  // const activationToken = new URLSearchParams(search).get("activationToken");
  const navigate = useNavigate();
  const { userId } = useParams();

  useEffect(() => {
    if (userId) {
      const token = userId.split("activationToken=");
      const activated = getActivated(token[1]);
      myDispatch(activated);
    }
  }, [userId]);

  return (
    <div className="">
      <div className="signup-wrapper--right bg-animation h-100 p-20">
        <div className="d-flex align-items-center justify-content-end">
          <div className="text-muted mr-10">Already have an account?</div>
          <Link to="/login">
            <button className="btn btn btn-outline-light">Sign in</button>
          </Link>
        </div>
      </div>
      <ConfirmationAlertModal
        icon={
          state.appStates.message ? (
            <IconCheckCircleOutline className="text-success w-30 h-30" />
          ) : (
            <IconTimesCircleOutline className="text-danger w-30 h-30" />
          )
        }
        showModal={Boolean(state.appStates.message || state.appStates.errorMessage)}
        title={
          <h4 className="font-20">{state.appStates.message || state.appStates.errorMessage}</h4>
        }
      />
    </div>
  );
};

export default UserActivateForm;
