const PortImages: any = {
  PortLogo: require("assets/images/ports/ic.svg").default,
  APM_TERMINAL: require("assets/images/ports/APM.svg").default,
  BOLUDA: require("assets/images/ports/BOLUDA.png"),
  COSCO: require("assets/images/ports/COSCO.png"),
  EVERGREEN: require("assets/images/ports/EVERGREEN.png"),
  EVERPORT_TERMINAL: require("assets/images/ports/EVERPORT.png"),
  FENIX: require("assets/images/ports/FENIX.svg").default,
  GCT: require("assets/images/ports/GCT.png"),
  TRAPAC: require("assets/images/ports/TRAPAC.svg").default,
  WBCT: require("assets/images/ports/WBCT.png"),
  LBCT: require("assets/images/ports/LBCT.png"),
  ITS_TERMINAL: require("assets/images/ports/ITS.gif"),
  YTI: require("assets/images/ports/YTI.png"),
  PCT: require("assets/images/ports/PCT.png"),
  PIER_A: require("assets/images/ports/PIER_A.png"),
  APM: require("assets/images/ports/APM.svg").default,
  PNCT: require("assets/images/ports/PNCT.png"),
  GCT_NY: require("assets/images/ports/GCTNY.png"),
  GCT_BAYONNE: require("assets/images/ports/GCTBAYONNE.png"),
  MAHER_TERMINAL: require("assets/images/ports/MAHER.png"),
  TTI: require("assets/images/ports/TTI.png"),
  "C&C_MAINTENANCE": require("assets/images/ports/C&C.png"),
  COLUMBIA_CONTAINER_SERVICES_1100: require("assets/images/ports/CCS.png"),
  COLUMBIA_CONTAINER_SERVICES_1155: require("assets/images/ports/CCS.png"),
  IRONBOUND_HYATT: require("assets/images/ports/IRONBOUND.png"),
  IRONBOUND_DELANCY: require("assets/images/ports/IRONBOUND.png"),
};

export default PortImages;
