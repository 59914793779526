import React, { SetStateAction, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { assignPortCred, loginMaherUser } from "reducers/action/appAction";
import { useAppContext } from "contexts/AppContext";
import FieldArray from "./FieldArray";
import { useForm } from "react-hook-form";
export interface ModalPropsI {
  showModal: any;
  hideModal: any;
  showNextModal: any;
}
const LoginCredential = (props: any) => {
  const { state, myDispatch } = useAppContext();
  const { maherError, maherResponse, assignLoading } = state.configStates;
  const { activeStep, showModal, ports, handlePorts, setShowModal, handlePrevious, handleActive } =
    props;

  // get selected ports array and setting default Values
  let newPortValues: any[] = [];
  if (ports?.length) {
    ports.map((pp: any) => {
      return newPortValues.push({
        ...pp,
        isCredAdded: pp.portPassword && pp.portUsername,
        status: "Missed",
        portId: pp.portId ? pp.portId : pp._id,
      });
    });
  }
  let DefaultValues = { ports: newPortValues };
  // get selected ports array
  const [isSaving, setIsSaving] = useState<boolean>(false);
  // setting default values
  let [defaultValues, setDefaultValues] = useState<any>(DefaultValues);

  // React hook form
  const {
    register,
    control,
    handleSubmit,
    getValues,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    defaultValues,
  });

  const onSubmit = (data: any) => {
    setIsSaving(true);
    let allSave: any[] = [];

    data.ports.map((sp: any) => {
      if (sp.code.includes("MAHER TERMINAL")) {
        let postMaherCred = {
          user: {
            username: sp.portUsername ? sp.portUsername : "",
            password: sp.portPassword ? sp.portPassword : "",
          },
          requestData: [],
        };
        myDispatch(loginMaherUser(postMaherCred));
      }
      if (Object.keys(maherResponse).length !== 0) {
        let postdata: any = {
          portId: sp.portId,
          portUsername: sp.portUsername ? sp.portUsername : "",
          portPassword: sp.portPassword ? sp.portPassword : "",
        };

        if (sp._id) {
          postdata._id = sp._id;
        }
        allSave.push(myDispatch(assignPortCred(postdata)));
        setShowModal(false);
        return sp;
      }
    });

    Promise.allSettled(allSave).then((res) => {
      setIsSaving(false);
    });
  };
  return (
    <Modal show={showModal && activeStep === 3} animation={false} centered size="xl">
      {defaultValues && defaultValues.ports && defaultValues.ports.length > 0 && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header className="justify-content-center p-40">
            <Modal.Title>Enter your Port Login Credentials</Modal.Title>
          </Modal.Header>
          <Modal.Body className="pt-0">
            <div className="">
              <FieldArray {...{ register, control, defaultValues, getValues, setValue, errors }} />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className=" btn btn-close mr-20 px-15 py-10"
              onClick={() => handlePrevious(activeStep)}
            >
              Back
            </button>
            {/* <button
              className="btn btn-outline-primary px-15 py-10  mr-10"
              onClick={() => setShowModal(false)}
            >
              Do it Later
            </button> */}
            <button type="submit" className="btn btn-primary px-15 py-10">
              {assignLoading && (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
              Complete!
            </button>
          </Modal.Footer>
        </form>
      )}
    </Modal>
  );
};
export default LoginCredential;
