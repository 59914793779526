import { IconAngleArrowLeft, IconAngleArrowRight, IconDownload } from "Common/Icons";
import { useAppContext } from "contexts/AppContext";

interface IProps {
  hide: any;
}
const ContainerEditHeader = (props: IProps) => {
  const { state, myDispatch } = useAppContext();
  const { selectedContainer, cLoading } = state.containerStates;

  const { hide } = props;
  return (
    <div className="subheader">
      <div className="subheader__inner d-flex">
        <div className="d-flex flex-row align-items-center">
          <button className="btn btn-outline-light" onClick={hide}>
            <IconAngleArrowLeft />
          </button>
          <div className="d-flex ml-3 align-items-center">
            <h5 className="mb-0">{selectedContainer.containerNo}</h5>
            <span className="badge badge-info ml-3">{selectedContainer.status}</span>
          </div>
        </div>
        <div className="ml-auto position-relative d-flex">
          <button className="btn btn-outline-light mr-10">
            <IconDownload />
          </button>
          <button className="btn btn-outline-light mr-10" onClick={hide}>
            Cancel
          </button>
          <div className="position-relative mr-10">
            <button type="submit" form="hook-form" className="btn btn-success">
              Save Changes
            </button>
          </div>
          <button className="btn btn-outline-light mr-2">
            <IconAngleArrowLeft />
          </button>
          <button className="btn btn-outline-light">
            <IconAngleArrowRight />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ContainerEditHeader;
