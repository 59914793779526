import Loader from "Common/Components/Loader";
import { useAppContext } from "contexts/AppContext";
import React, { useState } from "react";
import _ from "lodash";
import { useEffect, useRef } from "react";
import { DayTypeI, GateHourI } from "../location";
import { getRemarks, StatusWrapper } from "../Common/CustomTableCell";
import { STATIC_DAYS } from "./Constant";
import { getCurrentWeek } from "../Common/utils";
import { getWeekData } from "../utils";

const GateTable = (props: any) => {
  const { state } = useAppContext();
  const { todayDate } = props;
  const { gatehour, gLoading } = state.emptyStates;
  const [shift3day, setShift3Day] = React.useState<Set<any>>(new Set());
  const [portIndex, setPortIndex] = useState(0);
  const [weekArr, setWeekArr] = useState(getWeekData(todayDate));

  useEffect(() => {
    checkDay();
    checkData();
    setWeekArr(getWeekData(todayDate));
  }, [gatehour]);

  let indexArr: string[] = [];

  const checkDay = () => {
    STATIC_DAYS?.forEach((day) => {
      gatehour?.forEach((ar: GateHourI) => {
        const findValue = ar?.data?.gateHours?.find((fd: DayTypeI) => {
          return fd?.day?.toUpperCase()?.includes(day);
        });
        if (findValue?.hasOwnProperty("shift3")) {
          indexArr.push(findValue?.day?.toUpperCase());
        }
      });
    });
    setShift3Day(new Set(indexArr));
  };

  const checkData = () => {
    let remIndex = 0;
    gatehour?.some((entry: any, index: any) => {
      if (entry?.data?.gateHours?.length) {
        remIndex = index;
        return true;
      }
    });
    setPortIndex(remIndex);
  };

  return (
    <div
      className="table-responsive  mt-15"
      id="export-table"
      style={{ height: "calc(100vh - 268px)" }}
    >
      {gLoading && <Loader />}
      <table className="table table-card gate-table table--card table--tooltip ">
        <thead className="table-background ">
          <tr>
            <th scope="col" rowSpan={2}>
              Sno
            </th>
            <th rowSpan={2}>Terminal</th>
            {weekArr?.length
              ? weekArr?.map((gate: any, i: any) => {
                  return (
                    <th
                      key={i}
                      colSpan={shift3day?.has(gate.day.toUpperCase()) ? 3 : 2}
                      className="text-center"
                    >
                      {gate.day}
                      <div className="mt-1"> {gate.date}</div>
                    </th>
                  );
                })
              : ""}
          </tr>
          <tr>
            {gatehour?.length
              ? gatehour[portIndex]?.data?.gateHours?.map((sft: any, idx: number) => {
                  return (
                    <React.Fragment key={idx}>
                      <th>Shift 1</th>
                      <th>Shift 2</th>
                      {shift3day?.has(sft.day.toUpperCase()) ? <th>Shift 3</th> : null}
                    </React.Fragment>
                  );
                })
              : ""}
          </tr>
        </thead>
        <tbody>
          {gatehour && gatehour?.length ? (
            _.compact(gatehour)?.map((gh: any, i: number) => {
              return (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{gh?.key.replaceAll(/_/g, " ")}</td>
                  {STATIC_DAYS?.map((day, idx) => {
                    let dayValue: any = gh?.data?.gateHours?.find((ff: any) =>
                      ff.day?.toUpperCase()?.includes(day)
                    );
                    if (dayValue) {
                      dayValue = {
                        ...dayValue,
                        status1: dayValue?.status1?.toUpperCase(),
                        status2: dayValue?.status2?.toUpperCase(),
                      };
                      return (
                        <React.Fragment key={idx}>
                          <StatusWrapper dayValue={dayValue} value="status1">
                            {getRemarks(dayValue?.remarks1)}
                          </StatusWrapper>

                          <StatusWrapper dayValue={dayValue} value="status2">
                            {getRemarks(dayValue?.remarks2)}
                          </StatusWrapper>

                          {shift3day?.has(day.toUpperCase()) ? (
                            <StatusWrapper dayValue={dayValue} value="status3">
                              {getRemarks(dayValue?.remarks3)}
                            </StatusWrapper>
                          ) : null}
                        </React.Fragment>
                      );
                    } else {
                      return <td className="text-center"></td>;
                    }
                  })}
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={100}>No data found</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default GateTable;
