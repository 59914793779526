import { IconDownload, IconPlus, IconSendEmail } from "Common/Components/Icons/Icons";
import SubHeaderTab from "Common/Components/SubHeaderTab";
import BookingFilterRow from "pages/Bookings/Components/FilterRow";
import { useState, useEffect, Fragment } from "react";
// import TokenFilterRow from "./TokenFilterRow";
// import TokenList from "./TokenList";
// import CookieFilterRow from "./CookieFilterRow";
import CookiesList from "./CookieList";
import {
  getRequiredCookiesByPort,
  setContainerCredentialsParams,
  updateLBCTCookies,
} from "../action";
import { useAppContext } from "contexts/AppContext";
import CookieFilterRow from "./CookieFilterRow";
import { Button } from "react-bootstrap";
import { IconReturn } from "Common/Icons";
import Toastr from "Common/Components/Toastr";

interface INewToken {
  hitCount: number;
  tokenValue: string;
  portCode: string;
  userName: string;
  password: string;
  expiresAt: string;
  isNew: boolean;
  isDisable: boolean;
}

const Cookie = () => {
  const { state, myDispatch } = useAppContext();
  const { searchParams, searchPost } = state.supportStates;
  const [isCookies, setIsCookies] = useState<any>(true);
  useEffect(() => {
    getContainerCrendentialForCookies();
  }, []);

  const getContainerCrendentialForCookies = async () => {
    const response: any = getRequiredCookiesByPort({
      ports: "LBCT",
    });
    myDispatch(response);

    const searchPostD = {
      ...searchPost,
      ports: "LBCT",
    };
    myDispatch(setContainerCredentialsParams(searchPostD, searchParams));
  };

  const runCron = () => {
    const payload = {
      port: "LBCT",
    };
    const response = updateLBCTCookies(payload);
    myDispatch(response);
  };

  return (
    <div className="page-wrapper">
      <div className="position-relative subheader-wrapper">
        <SubHeaderTab val="supports" buttons={<RunCron runCron={runCron} />} />
        <div className="page-fluid">
          <div className="top-page">
            <div className="pb-15 d-flex align-items-center">
              <h4 className="mb-0">Cookies for LBCT</h4>
            </div>
            <CookieFilterRow setIsCookies={setIsCookies} />
          </div>
          <CookiesList isCookies={isCookies} />
        </div>
      </div>
    </div>
  );
};

export default Cookie;

const RunCron = (props: any) => {
  const [running, setRunning] = useState<boolean>(false);
  const [disable, setDisable] = useState<boolean>(false);
  const onRun = () => {
    props.runCron();
    setRunning(true);
    setDisable(true);
    setTimeout(() => {
      setRunning(false);
    }, 10000);
  };
  return (
    <Fragment>
      <button disabled={disable} className="btn btn-success" onClick={() => onRun()}>
        {running && (
          <span
            className="spinner-border spinner-border-sm mr-2"
            role="status"
            aria-hidden="true"
          ></span>
        )}

        <IconReturn />
      </button>
      {running && (
        <Toastr
          show={true}
          status="success"
          title="LBCT Cookies Checker is running Successfully."
          message="Please, don't click on the button now!"
        />
      )}
    </Fragment>
  );
};
