import SubHeaderTab from "Common/Components/SubHeaderTab";
import React from "react";
import { DashboardPropsI } from "../dashboard";
import DashboardCard from "../DashboardCard";
import UserInfoTable from "./Common/UserInfoTable";
import SearchFilter from "./Common/SearchFilter";
import { useAppContext } from "contexts/AppContext";
import Pagination from "Common/Components/Pagination";
import { getAllUsers, setUserInfoAvailableParams } from "../action";

const UserInfo = (props: DashboardPropsI) => {
  //API Initialize
  const { state, myDispatch } = useAppContext();
  const { userCount, userSearchParams, userSearchPost } = state.dashboardStates;

  //API CALL
  const getUserList = async (postparams: any, params: any) => {
    myDispatch(getAllUsers(postparams, params));
  };

  // HANDLING FUNCTION
  const onPaginate = (val: any) => {
    let searchParamsD = { ...userSearchParams, page: val };
    myDispatch(setUserInfoAvailableParams(userSearchPost, searchParamsD));
    getUserList(userSearchPost, searchParamsD);
  };

  return (
    <div className="page-wrapper">
      <div className="position-relative subheader-wrapper">
        <SubHeaderTab val="dashboard" />
        <div className="page-fluid">
          <div className="top-page">
            <div className="pb-15 d-flex align-items-center">
              <h4 className="mb-0">Containers</h4>
              <div className="ml-auto">
                <Pagination
                  className={"ml-auto"}
                  pageRangeDisplayed={5}
                  totalItemsCount={userCount}
                  itemsCountPerPage={userSearchParams.limit}
                  onChange={onPaginate}
                  activePage={userSearchParams.page}
                />
              </div>
            </div>
            <DashboardCard />
            <SearchFilter />
            <UserInfoTable />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserInfo;
