import React, { useEffect, useState } from "react";
import { InputPropsI } from "../components";
import { IconEyeClosed, IconEye } from "Common/Icons";
import { IconAmericanExpress, IconCardCheck, IconMasterCard, IconVisa } from "../Icons/Icons";

const CustomInputField = (props: InputPropsI) => {
  const {
    error,
    placeholder,
    required,
    label,
    name,
    type,
    register,
    id,
    rules,
    autoComplete,
    onChange,
    value,
    keyPress,
    helper,
    defaultValue,
    dense,
    inputFieldOnly,
    format,
    formatType,
    ...rest
  } = props;

  const [viewpassword, setViewPassword] = useState(type !== "password");
  const [inputType, setInputType] = useState(type || "text");
  useEffect(() => {
    if (type === "password") {
      setInputType(viewpassword ? "text" : "password");
    }
  }, [viewpassword]);

  return (
    <React.Fragment>
      {inputFieldOnly ? (
        <React.Fragment>
          <div className="position-relative">
            <input
              autoComplete={autoComplete || "new-password"}
              type={inputType}
              placeholder={placeholder}
              autoCorrect="off"
              onKeyPress={keyPress}
              id={id}
              defaultValue={defaultValue}
              value={value}
              onChange={
                onChange
                  ? (event: React.ChangeEvent<HTMLInputElement>) => {
                      return onChange(event.target.value);
                    }
                  : null
              }
              {...(register && {
                ...register(
                  name,
                  rules
                    ? {
                        ...rules,
                      }
                    : {
                        required,
                      }
                ),
              })}
              {...rest}
            />

            {error && helper && !format && (
              <div className="mt-2 error" style={{ color: "red" }}>
                {helper}
              </div>
            )}

            {format === "visa" && (
              <span className="input-icon">
                <IconVisa />
                <IconMasterCard />
                <IconAmericanExpress />
                {/* <IconJCB /> */}
              </span>
            )}
            {formatType === "credit" && (
              <span className="input-icon">
                <IconCardCheck />
              </span>
            )}
          </div>
        </React.Fragment>
      ) : (
        <div className={`${!dense ? "form-group mb-20" : "mb-0"} ${error && "error"}`}>
          <label className={`${label ? "mb-10" : "d-none"}`}>{label}</label>
          <div className="input-wrapper">
            <input
              autoComplete={autoComplete || "new-password"}
              type={inputType}
              placeholder={placeholder}
              autoCorrect="off"
              onKeyPress={keyPress}
              id={id}
              defaultValue={defaultValue}
              value={value}
              onChange={
                onChange
                  ? (event: React.ChangeEvent<HTMLInputElement>) => {
                      return onChange(event.target.value);
                    }
                  : null
              }
              {...(register && {
                ...register(
                  name,
                  rules
                    ? {
                        ...rules,
                      }
                    : {
                        required,
                      }
                ),
              })}
              {...rest}
            />
            {type === "password" && (
              <div className="input-icon" onClick={() => setViewPassword(!viewpassword)}>
                {viewpassword ? <IconEye /> : <IconEyeClosed />}
              </div>
            )}
          </div>
          {error && helper && (
            <div className="mt-2 error" style={{ color: "red" }}>
              {helper}
            </div>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default CustomInputField;
