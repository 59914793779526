import React, { useEffect, useRef, useState } from "react";
import TableHeader from "./Components/TableHeader";
import SubHeaderTab from "Common/Components/SubHeaderTab";
import SearchFilter from "../Common/SearchFilter";
import { useAppContext } from "contexts/AppContext";
import { setEmptiesData, getEmptyData, fetchGateHours } from "../action";
import TerminalTabs from "../Common/TerminalTabs";
import { EmptiesTypes, LocationPropsI, SmallTypesI } from "../location";
import Loader from "Common/Components/Loader";
import moment from "moment";
import LAEmptyTable from "../Common/LAEmptyTable";
import NYEmptyTable from "../Common/NYEmptyTable";
import TopEmptyCard from "../Common/TopEmptyCard";
import { getCurrentWeek, getShiftActive, getTimeZone, handlePrevShift } from "../Common/utils";
import "moment-timezone";
import WeeklyEmptyCard from "../Common/WeeklyEmptyCard";
import { getInitialFilters } from "pages/EmptyCorrection/constant";

const NewEmptyCorrection = (props: LocationPropsI) => {
  const { terminalTab, tab } = props;
  const { state, myDispatch } = useAppContext();
  const { empties, eLoading, isTomorrow, shiftCount } = state.emptyStates;

  const todayDate = moment().format("LLLL");
  const currentShift = getShiftActive(shiftCount);

  // ---------------------------------------------------- STATE MANAGEMENT---------------------------------------------------//
  const [empytData, setEmptyData] = useState<EmptiesTypes[]>([]);
  const [scroll, setScroll] = useState(0);
  const [shift, setShift] = useState<string>("shift1");
  const [LAshift, setLAShift] = useState<string>(currentShift);
  const [sslArray, setSslArray] = useState<SmallTypesI[]>([]);
  const [portsArray, setPortsArray] = useState<SmallTypesI[]>([]);
  const [containerTypes, setContainerTypes] = useState<SmallTypesI[]>([]);
  const [updateTime, setUpdateTime] = useState("");
  const [isToday, setIsToday] = useState(true);
  const [weekDateValue, setWeekDateValue] = useState(
    terminalTab === "LA" ? moment(getCurrentWeek()[0], "MM-DD-YYYY").format("LLLL") : todayDate
  );

  const ref = useRef<HTMLDivElement>(null);
  // ---------------------------------------------------- HOOKS MANAGEMENT ---------------------------------------------------//
  // useEffect(() => {
  //   getEmptiesData();
  // }, [terminalTab]);

  useEffect(() => {
    let datas = empties?.data;
    if (datas) {
      setEmptyData(datas);
      setUpdateTime(empties.updatedTime);
      myDispatch(setEmptiesData(datas));
    } else {
      setEmptyData([]);
    }
  }, [empties]);

  useEffect(() => {
    const allValues = getInitialFilters(terminalTab);
    if (allValues) {
      const [newports, sslValue, types] = JSON.parse(allValues);
      setPortsArray(newports.value);
      setSslArray(sslValue.value);
      setContainerTypes(types.value);
    }
  }, [terminalTab, getInitialFilters(terminalTab)]);

  useEffect(() => {
    const div = ref.current;
    div?.addEventListener("scroll", () => {
      setScroll(Math.abs(div?.scrollTop));
    });
    return div?.removeEventListener("scroll", () => setScroll(0));
  }, []);

  // ---------------------------------------------------- HANDLE FUNCTIONS ---------------------------------------------------//
  // const getEmptiesData = () => {
  //   myDispatch(getEmptyData(terminalTab));
  // };

  const handleActive = (acc: any) => {
    setShift(acc.Shift);

    if (todayDate === acc.Date) setIsToday(true);
    else setIsToday(false);
  };

  const handleWeekActive = (acc: any) => {
    setShift(acc.Shift);
    setLAShift(currentShift);
    setWeekDateValue(moment(acc.formatWeek, "MM-DD-YYYY").format("LLLL"));

    // if (todayDate === acc.Date) setIsToday(true);
    // else setIsToday(false);
  };

  const handleLAShiftActive = (shiftData: any) => {
    setLAShift(shiftData.Shift);
  };

  useEffect(() => {
    if (terminalTab === "LA") {
      setLAShift(currentShift);
    }
  }, [currentShift]);

  useEffect(() => {
    if (terminalTab === "LA") {
      myDispatch(fetchGateHours());
    }
  }, []);

  return (
    <div ref={ref} className="page-wrapper">
      {!eLoading && <Loader />}
      <div className="position-relative subheader-wrapper">
        <SubHeaderTab val="emptyCorrect" />
        <div className="page-fluid">
          <div className="top-page">
            <TerminalTabs terminal={terminalTab} value="currentEmpties" />
            <SearchFilter
              tab={tab}
              terminal={terminalTab}
              isTomorrow={isTomorrow}
              page="currentEmpties"
            />
            {terminalTab === "LA" && (
              <WeeklyEmptyCard
                value="currentEmpties"
                terminal={terminalTab}
                date={todayDate}
                handleActive={handleWeekActive}
                shift={terminalTab === "LA" ? LAshift : shift}
              />
            )}
            <TopEmptyCard
              value="currentEmpties"
              terminal={terminalTab}
              date={weekDateValue}
              handleActive={handleActive}
              shift={terminalTab === "LA" ? LAshift : shift}
              handleLACardActive={handleLAShiftActive}
            />
          </div>
          <div className="">
            <table className="table table-card table-card--borderless w-100 mb-30 table-custom table--empties">
              <TableHeader
                updateTime={updateTime}
                emptyData={empytData}
                portsArray={portsArray}
                terminal={terminalTab}
                scroll={scroll}
                shift={terminalTab === "LA" ? LAshift : shift}
                isTomorrow={isTomorrow}
                date={weekDateValue}
              />
              {terminalTab === "NY" && (
                <>
                  {!isToday && !isTomorrow ? (
                    <tbody>
                      <tr>
                        <td colSpan={100}>No data found</td>
                      </tr>
                    </tbody>
                  ) : (
                    <React.Fragment>
                      {sslArray?.map((ssl: SmallTypesI, index: number) => {
                        return (
                          ssl.checked && (
                            <NYEmptyTable
                              portsArray={portsArray}
                              containerTypes={containerTypes}
                              shift={shift}
                              emptyData={empytData}
                              data={ssl.label}
                              key={index}
                              terminal={terminalTab}
                            />
                          )
                        );
                      })}
                    </React.Fragment>
                  )}
                </>
              )}

              {terminalTab === "LA" && (
                <React.Fragment>
                  {sslArray?.map((ssl: SmallTypesI, index: number) => {
                    return (
                      ssl.checked && (
                        <LAEmptyTable
                          portsArray={portsArray}
                          containerTypes={containerTypes}
                          shift={LAshift}
                          emptyData={empytData}
                          data={ssl.label}
                          key={index}
                          date={weekDateValue}
                          terminal={terminalTab}
                        />
                      )
                    );
                  })}
                </React.Fragment>
              )}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewEmptyCorrection;
