import React, { useEffect, useRef, useState } from "react";
import { IconDual, IconReset } from "../../../../Common/Components/Icons/Icons";
import { HeaderPropsI, SmallTypesI } from "../../location";
import PortImages from "../../PortImages";
import { useAppContext } from "contexts/AppContext";
import { getEmptyData } from "pages/EmptyReturn/action";
import {
  checkGateStatus,
  getOpenCloseInfo,
  getTimeDifference,
} from "pages/EmptyReturn/Common/utils";
import { IconWarning } from "Common/Icons";
import SSLMAP from "../sslMapper.json";
import moment from "moment";

const TableHeader = (props: HeaderPropsI) => {
  const {
    scroll,
    terminal,
    portsArray,
    emptyData,
    updateTime,
    shift,
    isTomorrow,
    date,
    sslLabel,
    fromUI,
  } = props;
  const { state, myDispatch } = useAppContext();
  const { gatehour } = state.emptyStates;

  // ---------------------------------------------------- STATE MANAGEMENT---------------------------------------------------//
  const [isScrollable, setIsScrollable] = useState(false);
  const [shiftNumber, setShiftNumber] = useState(1);

  // const ref = useRef<HTMLTableSectionElement>(null);
  // ---------------------------------------------------- HOOKS MANAGEMENT ---------------------------------------------------//
  useEffect(() => {
    if (scroll > 250) {
      setIsScrollable(true);
    } else {
      setIsScrollable(false);
    }
  }, [scroll]);

  useEffect(() => {
    if (shift === "shift1") {
      setShiftNumber(1);
    } else {
      setShiftNumber(2);
    }
  }, [shift]);

  const handleRefresh = () => {
    myDispatch(getEmptyData(terminal, isTomorrow || false));
  };

  const gateRemarks = (filterGateData: any) => {
    switch (shift) {
      case "shift1":
        return filterGateData?.remarks1;
      case "shift2":
        return filterGateData?.remarks2;
      case "shift3":
        return filterGateData?.remarks3;
      default:
        break;
    }
  };

  const filterGateHours = (port: any) => {
    const gateData = gatehour?.find((ff: any) => ff?.key === port.label);
    const filterGateData = gateData?.data?.gateHours?.find(
      (filtered: any) => filtered.date === moment(date, "LLLL").format("LL")
    );
    return filterGateData;
  };

  return (
    <thead className={`table-head text-center ${isScrollable ? "sticky-head" : ""}`}>
      <tr>
        {isScrollable ? (
          <th rowSpan={3} className="bg-gray-100 rounded-left--5 w-0 p-2"></th>
        ) : (
          <th rowSpan={4} className="bg-gray-100 align-bottom rounded-left--5 w-0 p-2">
            <div className="d-flex flex-column align-items-center align-items-center">
              <div className="img-wrapper mb-40">
                {terminal ? <img src={PortImages.PortLogo} alt="" /> : null}
              </div>
            </div>
          </th>
        )}
      </tr>

      <tr>
        {portsArray.map((port: SmallTypesI, index: number) => {
          let label = port.label.replaceAll("_", " ");
          const empties = emptyData?.find((ff) => ff?.key?.replaceAll("_", " ") === label);
          const findAvailabilityOfSsl: any = SSLMAP.find(
            (s) => s.label?.replaceAll("_", " ") === sslLabel
          );
          const sslAvailableForPort: boolean =
            terminal === "NY"
              ? findAvailabilityOfSsl?.nyPorts.includes(label)
              : findAvailabilityOfSsl?.laPorts.includes(label);
          // console.log(findAvailabilityOfSsl, port)
          return (
            <React.Fragment key={index}>
              {sslAvailableForPort && (
                <th className="p-10 w-10">
                  <div className="d-inline-flex flex-column align-items-center">
                    <div className="img-wrapper h-30px">
                      <img
                        src={PortImages[label.replace(/\s/g, "_")]}
                        alt={label}
                        className="h-100 w-100 img-contain"
                      />
                    </div>
                    <h6 className="text-primary mt-2 mb-0 title text-break-spaces">
                      {label.replace("_", " ")}
                    </h6>
                    {empties?.alert && (
                      <div className="position-relative ">
                        <IconWarning className="text-primary wh-10px text-warning mt-2 tooltip-icon-label" />
                        <div
                          className="tooltip tooltip--gray-700 mw-300 mt-3"
                          data-placement={
                            label === portsArray[portsArray.length - 1].label
                              ? "left"
                              : "bottom-center"
                          }
                        >
                          {empties.alert}
                          <span className="arrow"></span>
                        </div>
                      </div>
                    )}
                  </div>
                </th>
              )}
            </React.Fragment>
          );
        })}
      </tr>
    </thead>
  );
};

export default TableHeader;
