import { ICronTableData, IHeader } from "./types/TTimeScaleTypes";

const hasNo = [
  { label: "Has", value: "HAS" },
  { label: "Has No", value: "HAS_NO" },
];

const fieldsExp = [
  { label: "Last Free Day", value: "lastFreeDay" },
  { label: "ETA", value: "eta" },
  { label: "Status", value: "status" },
  { label: "Customs Release, Hold", value: "custom" },
  { label: "Freight Release, Hold", value: "freight" },
  { label: "Port Note", value: "portNote" },
];

const terminalTypes = [
  { label: "All", value: "all" },
  { label: "Marine", value: "marine" },
  { label: "Rail", value: "rail" },
  { label: "Depot", value: "depot" },
  { label: "Other", value: "other" },
];

const terminals = [
  { label: "LBCT", value: "lbct" },
  { label: "OICT", value: "oict" },
  { label: "YTI", value: "yti" },
  { label: "FENIX", value: "fenix" },
  { label: "GCT BAYONNE", value: "gctbayonne" },
  { label: "GCTNY", value: "gctny" },
  { label: "(all terminals)", value: "allterminals" },
];

const userChangedExp = [
  { label: "Last Free Day", value: "lastFreeDay" },
  { label: "ETA", value: "eta" },
  { label: "Status", value: "status" },
  { label: "Customs Release, Hold", value: "custom" },
  { label: "Freight Release, Hold", value: "freight" },
  { label: "Port Note", value: "portNote" },
];

const source = [
  {
    label: "Standalone",
    value: "baseline",
  },
  {
    label: "Drayos",
    value: "drayosTrack",
  },
];

export { hasNo, terminalTypes, terminals, userChangedExp, fieldsExp, source };
