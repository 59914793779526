import { SelectFieldWithoutControl } from "Common/Components/Inputs/CustomSelectField";
import { useAppContext } from "contexts/AppContext";
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { setSuccessfulContainersParams } from "../action";
import datapoint from "../dataPoints.json";
import FilterForm from "./FilterForm";

//   Status and data points filter
const STATUS_OPTIONS = Object.keys(datapoint).map((item: any) => ({
  label: item,
  value: item,
}));

const SearchFilter = () => {
  const { state, myDispatch } = useAppContext();
  const { searchParams, searchPost } = state.successfulContainerStates;

  const [searchPayload, setSearchPayload] = useState<any>();

  // Default Status Selection
  const defaultStatus = STATUS_OPTIONS.find((st: any) => st.value === searchPost?.status);
  const [status, setStatus] = useState<any>(defaultStatus);

  const temp: any = datapoint;
  // Default Data Point Selection
  const defaultDataPointOptions = temp[status?.value];
  const defaultDataPoints = defaultDataPointOptions.find(
    (d: any) => d.value === searchPost?.dataPoints?.[0]
  );
  const [defaultDataPoint, setDefaultDataPoint] = useState<any>(defaultDataPoints);
  // Data Points based on status selection
  const [dataPoints, setDataPoints] = useState<any>();

  // On Search
  const onChangeInput = (event: any) => {
    let val = event.target.value;
    const searchedContainers = val?.replace(/[^A-Z0-9]+/gi, "");

    let searchValue = { ...searchParams, containerNo: searchedContainers };
    myDispatch(setSuccessfulContainersParams(searchPayload, searchValue));
  };
  const debouncedChangeHandler = useCallback(_.debounce(onChangeInput, 1000), []);

  // Handle  Status and Datapoints Change
  const handleStatusChange = (data: any) => {
    setStatus(data);
    setDefaultDataPoint(null);
    setDataPoints(temp[data.value]);
  };

  const handleDataPoints = (points: any) => {
    setDefaultDataPoint(points.map((point: any) => point));
    if (status?.value) {
      const searchPostD = {
        ...searchPost,
        status: status.value,
        dataPoints: points.map((point: any) => point.value),
      };
      setSearchPayload(searchPostD);
      searchParams.page = 1;
      myDispatch(setSuccessfulContainersParams(searchPostD, searchParams));
    }
  };

  // For Default Selection
  useEffect(() => {
    if (status?.value) {
      setDataPoints(temp[status.value]);
    }
  });

  return (
    <div className="filter-row d-flex align-items-center">
      {/* Search */}
      <div className="app-search header-search">
        <span className="search-icon"> </span>
        <div className="position-relative">
          <input
            type="search"
            className="form-control input-search-left"
            placeholder="Search Containers..."
            defaultValue={""}
            onChange={debouncedChangeHandler}
          />
        </div>
      </div>
      {/* Status filter */}
      <SelectFieldWithoutControl
        className="w-200 mr-2"
        classNamePrefix="select"
        isLoading={false}
        isSearchable={true}
        name="status"
        onChange={handleStatusChange}
        options={STATUS_OPTIONS}
        size="small"
        value={status}
      />
      {/* DataPoints filter based on status */}
      <SelectFieldWithoutControl
        className="min-200 mr-2"
        classNamePrefix="select"
        isLoading={false}
        isClearable={true}
        isSearchable={true}
        isMulti={true}
        name="status"
        onChange={handleDataPoints}
        options={dataPoints}
        size="small"
        value={defaultDataPoint}
      />
      {/* Filter Popup */}
      <FilterForm />
    </div>
  );
};

export default SearchFilter;
