import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import {
  IconCheck,
  IconTimes,
  IconReward,
  IconPlus,
  IconVisa,
  IconApple,
} from "Common/Components/Icons/Icons";
import CustomSelectField from "Common/Components/Inputs/CustomSelectField";
import { useForm } from "react-hook-form";
import { PaymentTypes } from "../onboarding";
import { countryName } from "../Common/constant";
import { yupResolver } from "@hookform/resolvers/yup";
import { paymentInfoScheme } from "../onBoardingValidation";
import { useAppContext } from "contexts/AppContext";
import { postSubscription } from "../action";
import CustomInputField from "Common/Components/Inputs/CustomInputField";
import { IconCalendar } from "Common/Icons";
import Stepper from "./Stepper";

const PaymentModal = (props: any) => {
  const { AddNewCompanyModal, handlePreviousStep, handleNextStep, planId, customerId, activeStep } =
    props;
  const {
    state: { paymentStates },
    myDispatch,
  } = useAppContext();

  // REACT HOOK FORM
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm<PaymentTypes>({
    mode: "onBlur",
    resolver: yupResolver(paymentInfoScheme),
  });

  const onFormSubmit = (data: PaymentTypes) => {
    const dateSplit = data.expiryDate.split("/");
    const finalData = {
      cardNumber: data.cardInfo.toString(),
      expiryMonth: dateSplit[0],
      expiryYear: dateSplit[1],
      cvc: data.cvc.toString(),
    };
    if (planId && customerId) {
      myDispatch(postSubscription(finalData, planId, customerId));
    }
  };

  useEffect(() => {
    if (paymentStates.successMessage) {
      handleNextStep(2);
    }
  }, [paymentStates]);

  return (
    <Modal show={AddNewCompanyModal} centered animation={false} size="sm">
      <Modal.Header className="py-20 px-30 justify-content-center">
        <Modal.Title>
          <div className="text-center"></div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-60 ">
        <form onSubmit={handleSubmit(onFormSubmit)}>
          {!paymentStates.successMessage && paymentStates.errorMessage && (
            <div className="alert alert-warning" role="alert">
              {paymentStates.errorMessage}
            </div>
          )}
          <div className="mb-20">
            <button
              type="button"
              className="btn btn-dark bg-dark-100 text-center p-2 rounded-6 shadow-6 font-14 text-white w-100 mb-10 d-flex justify-content-center align-items-center"
            >
              <IconApple className="mr-2" />
              {/* <IconPlus className="mr-2" /> */}
              Pay
            </button>
          </div>
          <div className=" text-gray-500 text-center mb-20"> Or Pay with Card </div>
          <div className="card mb-0">
            <div className="form-wrapper">
              <div className="form-row ">
                {/* <div className="form-group col-md-12">
                  <label className="col-form-label">Email</label>
                  <input type="text" className="form-control " placeholder="Enter Group Name" />
                </div> */}
                <div className=" payment form-group col-md-12 mb-0">
                  <label className="col-form-label">Card Information</label>
                  <CustomInputField
                    autoComplete="off"
                    placeholder="1234 1234 1234 1234"
                    name="cardInfo"
                    type="number"
                    format="visa"
                    required
                    error={Boolean(errors.cardInfo)}
                    register={register}
                    helper={errors.cardInfo ? errors.cardInfo.message : ""}
                    className="form-control"
                    inputFieldOnly
                  />
                  {Boolean(errors.cardInfo) && (
                    <div className="mt-2 error" style={{ color: "red" }}>
                      {errors.cardInfo ? errors.cardInfo.message : ""}
                    </div>
                  )}
                </div>
                <div className="form-row no-gutters mx-0">
                  <div
                    className=" payment form-group col-6 pr-0"
                    style={{ borderRadius: " 0px 0px 0px 5px" }}
                  >
                    <CustomInputField
                      autoComplete="off"
                      placeholder="MM/YY"
                      name="expiryDate"
                      type="string"
                      required
                      error={Boolean(errors.expiryDate)}
                      register={register}
                      helper={errors.expiryDate ? errors.expiryDate.message : ""}
                      className="form-control"
                      inputFieldOnly
                    />
                  </div>
                  <div className=" payment form-group col-6 pl-0">
                    <CustomInputField
                      autoComplete="off"
                      placeholder="CVC"
                      name="cvc"
                      type="number"
                      required
                      error={Boolean(errors.cvc)}
                      register={register}
                      helper={errors.cvc ? errors.cvc.message : ""}
                      className="form-control"
                      inputFieldOnly
                    />
                  </div>
                </div>
                <div className=" payment form-group col-md-12">
                  <label className="col-form-label">Name on Card</label>
                  <CustomInputField
                    autoComplete="off"
                    placeholder="John Doe"
                    name="cardName"
                    type="string"
                    required
                    error={Boolean(errors.cardName)}
                    register={register}
                    helper={errors.cardName ? errors.cardName.message : ""}
                    className="form-control"
                    inputFieldOnly
                  />
                </div>
                {/* <div className="form-group col-md-12">
                  <label className="col-form-label">Country or Region</label>
                  <CustomSelectField
                    name="countryName"
                    options={countryName}
                    control={control}
                    isClearable={true}
                    size="small"
                    placeholder="Select Here.."
                  />
                </div> */}
                {/* <div className="form-group col-md-12 mt-0">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Zip Code"
                    {...register("zipCode")}
                  />
                </div> */}
              </div>
              <div className="subscribe mb-20 mt-10">
                <button
                  type="submit"
                  className="btn btn-primary bg-blue p-2 text-center rounded-6 shadow-6 font-14 text-white w-100 mb-10 d-flex justify-content-center align-items-center"
                >
                  {paymentStates.pLoading && (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                  {/* <IconPlus className="mr-2" /> */}
                  Subscribe
                </button>
                <div className="text-gray-500 mt-15 font-12">
                  By Confirming your subscription, you allow PortPro to charge your card for further
                  payments in accordance with their terms. You can cancel anytime in your trial and
                  you will not be charged.
                </div>
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-end align-items-center w-100">
          <Stepper activeStep={activeStep} />
          <button
            onClick={() => handlePreviousStep(2)}
            className="btn btn-outline-light justify-content-center align-items-center py-10 px-15"
            type="button"
          >
            Back
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
export default PaymentModal;
