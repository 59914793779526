import { useEffect, useState, useRef } from "react";
import SubHeaderTab from "Common/Components/SubHeaderTab";
import Toastr from "Common/Components/Toastr";
import moment from "moment";
import { portList, portToFileName } from "./constants";
import { getUploadedHTML, uploadVesselHTML } from "../action";
import VesselScheduleUpload from "./VesselScheduleUpload";

const intialValuesUpload = {
  data: "",
  filename: "",
};

const initalValuesToast = {
  message: "",
  type: "",
};

const UploadHTML = () => {
  const initialRender = useRef(true);
  const [running, setRunning] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [uploadData, setUploadData] = useState<{ [key: string]: string }>(intialValuesUpload);
  const [lastUpdated, setLastUpdated] = useState<string>();
  const [showToast, setShowToast] = useState<any>(initalValuesToast);
  const [portSelected, setPortSelected] = useState<string>("");

  const PORTS_TO_UPLOAD = portList.map((item) => ({ label: item.value, value: item.label }));
  const handleHTML = (val: any) => {
    setUploadData({ ...uploadData, data: val });
  };

  const resetToast = (time: number) => {
    setTimeout(() => {
      setShowToast(initalValuesToast);
    }, time);
  };
  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }
    fetchUploadedHTML();
  }, [portSelected]);

  const fetchUploadedHTML = async () => {
    const { filename } = uploadData;
    setUploadData({ filename });
    try {
      setIsLoading(true);
      const { status, data: responseData } = await getUploadedHTML(filename);
      if (status === 200 && responseData.success) {
        if (responseData.lastUpdated) {
          setLastUpdated(moment(responseData.lastUpdated).format("lll"));
        }
        setUploadData({ ...uploadData, data: responseData.body });
        setShowToast({
          message: `Successfully fetched vessel schedule HTML for ${portSelected}`,
          type: "success",
        });
        setIsLoading(false);

        return;
      }
      setShowToast({
        message: `Html for ${portSelected} not found! Please upload.`,
        type: "error",
      });
      setIsLoading(false);
      setUploadData({ ...uploadData, data: "" });
    } catch (error) {
      setUploadData({ ...uploadData, data: "" });
      setIsLoading(false);
      setShowToast({
        message: `Uploaded html for ${portSelected} couldn't be fetched`,
        type: "error",
      });
    }
  };
  const handlePortChange = async (newport: { key: string; value: string }): Promise<void> => {
    const filename = portToFileName[newport.value];
    setUploadData({ filename });
    setPortSelected(newport.value);
  };

  const handleUploadClick = async (e: any): Promise<void> => {
    e.preventDefault();
    if (!uploadData.data) {
      setShowToast({ message: "Please input HTML!", type: "error" });
      return;
    }
    if (!uploadData.data.startsWith("<body")) {
      setShowToast({ message: "Wrong html format! Should start with <body", type: "error" });
      return;
    }
    setRunning(true);
    try {
      const { status, data: responseData } = await uploadVesselHTML(uploadData);
      if (status === 200 && responseData.message === "success") {
        const currentDate = moment().format("lll");
        setLastUpdated(currentDate);
        setShowToast({ message: "Successfully Uploaded", type: "success" });
        setRunning(false);
      } else {
        setRunning(false);
        setShowToast({ message: "Upload Failed", type: "error" });
      }
    } catch (error) {
      setRunning(false);
      setShowToast({ message: "Upload Failed", type: "error" });
    }
  };

  return (
    <div className="page-wrapper">
      <div className="position-relative subheader-wrapper">
        <SubHeaderTab val="supports" />
        <VesselScheduleUpload
          PORTS_TO_UPLOAD={PORTS_TO_UPLOAD}
          uploadData={uploadData}
          lastUpdated={lastUpdated}
          handleHTML={handleHTML}
          running={running}
          handlePortChange={handlePortChange}
          handleUploadClick={handleUploadClick}
          isLoading={isLoading}
          setShowToast={setShowToast}
        />
      </div>
      {["success", "error"].includes(showToast?.type) && (
        <Toastr
          show={true}
          status={showToast.type}
          message={showToast.message}
          reset={resetToast(2000)}
        />
      )}
    </div>
  );
};

export default UploadHTML;
