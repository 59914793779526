import _ from "lodash";
import * as XLSX from "xlsx";
export function isArrayWithLength(arr: any) {
  return Array.isArray(arr) && arr.length;
}

export const getPermissionArrays = (array1: any, role: string) => {
  const filterPermissions: any = array1.filter(({ permissions }: any) => {
    if (!permissions) return true;
    else if (!isArrayWithLength(permissions)) return true;
    else return _.intersection(permissions, [role]).length;
  });
  return filterPermissions;
};

const isIFrame = (input: HTMLElement | null): input is HTMLIFrameElement =>
  input !== null && input.tagName === "IFRAME";

export const tablePrint = (label = "gate hours", type = "print") => {
  const content = document.getElementById("export-table")!.outerHTML;

  let htmlToPrint = `
      <style>
        table * {
          color: black;
          background: transparent!important;
        }

        table {
          text-align: left;
          border: 1px solid #000;
          border-width: 1px 1px 1px 1px !important;
          border-collapse: collapse;
        }

        th, td {
          border: 1px solid #000;
          border-width: 0 1px 1px 0 !important;
          padding: 0.5rem;
        }

        td *{
          display: inline-block;
          margin-right: 5px;
        }
        
        
        td img {
          width: 40px;
          display: none,
        }
        
        .MuiAvatar-root {
          display: none;
        }

        input, textarea,
        button, svg{
          display: none!important;
        }

        @media print{
          @page {
          size: landscape;
          }    
          .no-print, .no-print *{
            display: none !important;
          }
        }

      </style>
    `;

  htmlToPrint += content;
  const frame = document.getElementById("ifmcontentstoprint")!;
  if (isIFrame(frame) && frame.contentWindow) {
    const pri = frame.contentWindow;
    pri.document.open();
    pri.document.write(htmlToPrint);
    pri.document.close();
    pri.document.title = label;
    pri.focus();
    pri.print();
  }
};

export const exportToExcel = (documentLabel: string) => {
  const SheetNames: string[] = [];
  const Sheets = {};
  const tableEl = document.getElementById("export-table")!;

  const wb: any = { SheetNames, Sheets };
  const ws9 = XLSX.utils.table_to_sheet(tableEl, { raw: true });
  // Get total number of columns
  const totalColumns = tableEl.querySelectorAll("th").length;

  // Hide last columns ~ actions buttons
  ws9["!cols"] = [];
  ws9["!cols"][totalColumns - 1] = { hidden: true };
  wb.SheetNames.push("Excel Sheet");
  wb.Sheets["Excel Sheet"] = ws9;
  XLSX.writeFile(wb, `${documentLabel}.xlsx`, { cellStyles: true });
};
