import React, { useState } from "react";
import { IconDocument, IconInfoFocus, IconNotes, IconSearchData } from "Common/Icons";
import ContainerInfo from "./tabComponents/ContainerInfo";
import Documents from "./tabComponents/Documents";
import Audit from "./tabComponents/Audit";
import Notes from "./tabComponents/Notes";
interface IProps {
  details: any;
}
const tabComponents = [
  // {
  //   id: 2,
  //   label: "Container Info",
  //   icon: <IconInfoFocus />,
  // },
  {
    id: 1,
    label: "Documents",
    icon: <IconDocument />,
  },
  // {
  //   id: 3,
  //   label: "Audit",
  //   icon: <IconSearchData />,
  // },
  // {
  //   id: 3,
  //   label: "Notes",
  //   icon: <IconNotes />,
  // },
];
const DetailTab = (props: IProps) => {
  const { details } = props;
  const [isLoadTabActive, setisLoadTabActive] = useState<any | null>(1);
  const handleLoadTabActive = (i: number) => {
    setisLoadTabActive(isLoadTabActive !== i ? i : null);
  };
  return (
    <>
      <div className="card mb-0">
        {/* Import Availability Tabs */}
        <ul className="nav nav-tabs nav-tabs-custom nav-justified nav-border-bottom">
          {tabComponents.map((item) => (
            <li className="nav-item" key={item.id}>
              <div
                className={`nav-link ${isLoadTabActive === item.id ? "active" : ""}`}
                onClick={() => handleLoadTabActive(item.id)}
              >
                <div className="text-primary">{item.icon}</div>
                <div className="nav-link__text">{item.label}</div>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div className="tab-content">
        {/* Tab Contents */}
        {/* {isLoadTabActive === 2 && <ContainerInfo values={details} />} */}
        {isLoadTabActive === 1 && <Documents values={details} />}
        {/* {isLoadTabActive === 3 && <Audit />} */}
        {/* {isLoadTabActive === 3 && <Notes />} */}
      </div>
    </>
  );
};

export default DetailTab;
