import SubHeaderTab from "Common/Components/SubHeaderTab";
import { useAppContext } from "contexts/AppContext";

import React, { useEffect, useState } from "react";

import { CustomIconSpreadSheet } from "../../Common/Components/Icons/CustomIcons";
import { IconDownload, IconPlus, IconSendEmail } from "../../Common/Components/Icons/Icons";
import { getMissingContainers, setMissingContainersParams } from "./action";
import MissingContainerTable from "./Components/MissingContainerTable";
import SearchFilter from "./Components/SearchFilter";
import Pagination from "Common/Components/Pagination";
import DownloadCsv from "pages/ReportContainer/Components/DownloadCsv";

const MissingContainers = () => {
  const { state, myDispatch } = useAppContext();

  const { missingContainerCount, missingContainers, searchParams, searchPost } =
    state.missingContainerStates;
  const { limit, page } = searchParams;

  useEffect(() => {
    if (searchPost) getAllMissingContainers();
  }, [searchParams, searchPost]);

  const getAllMissingContainers = () => {
    const response = getMissingContainers(searchPost, searchParams);
    myDispatch(response);
  };

  // Paginate
  const onPaginate = (val: any) => {
    let searchParamsD = { ...searchParams, page: val };
    myDispatch(setMissingContainersParams(searchPost, searchParamsD));
  };
  return (
    <div className="page-wrapper">
      <div className="position-relative subheader-wrapper">
        <SubHeaderTab
          val="reports"
          buttons={
            <DownloadCsv
              page="problemContainer"
              searchParams={searchParams}
              searchPost={searchPost}
            />
          }
        />
        <div className="page-fluid">
          <div className="top-page">
            <div className="pb-15 d-flex align-items-center">
              <h4 className="mb-0">Missing Report Containers</h4>
              <div className="ml-auto">
                <Pagination
                  className={"ml-auto"}
                  pageRangeDisplayed={5}
                  totalItemsCount={missingContainerCount}
                  itemsCountPerPage={limit}
                  onChange={onPaginate}
                  activePage={page}
                />
              </div>
            </div>
            <SearchFilter />
          </div>
          <MissingContainerTable />
        </div>
      </div>
    </div>
  );
};

export default MissingContainers;
