import React, { Fragment, useCallback, useEffect, useState } from "react";
import { SelectFieldWithoutControl } from "Common/Components/Inputs/CustomSelectField";
import { useAppContext } from "contexts/AppContext";
import _ from "lodash";
import Datetime from "react-datetime";
import { IconCalendar } from "Common/Icons";
import { LOAD_TYPE, MOVE_TYPE, FULL_EMPTY } from "../constant";
import { Form } from "react-bootstrap";
import { getAppointmentSlotReport } from "../action";
import moment from "moment";

const SearchFilter = (props: any) => {
  const { setShowToaster } = props;

  const [selectedOptions, setSelectedOptions] = useState<any>(props.params);

  const handleOptValChange = (val: any, key: string) => {
    let parameters = { ...selectedOptions };
    parameters[key] = val;
    setSelectedOptions({ ...parameters });
  };

  const handleInputValChange = (event: any, key: string) => {
    const parameters = { ...selectedOptions };
    parameters[key] = event.target.value.trim();
    setSelectedOptions({ ...parameters });
  };

  const onSearch = () => {
    if (selectedOptions.startDate && selectedOptions.endDate && selectedOptions.appointmentId) {
      props.setParams(selectedOptions);
    } else {
      setShowToaster(true);
    }
  };

  return (
    <Fragment>
      <div className="filter-row">
        <div className="d-flex align-items-center mt-2">
          <div className="d-flex align-items-center mx-2 w-75">
            <div className="input-wrapper w-200 mr-2">
              <Datetime
                inputProps={{
                  placeholder: "Select start date..",
                }}
                onChange={(value) => handleOptValChange(value, "startDate")}
                closeOnSelect
                value={selectedOptions?.startDate ? moment(selectedOptions.startDate) : ""}
              />
              <div className="input-icon">
                <IconCalendar />
              </div>
            </div>
            <div className="input-wrapper w-200 mr-2">
              <Datetime
                inputProps={{
                  placeholder: "Select end date..",
                }}
                onChange={(value) => handleOptValChange(value, "endDate")}
                closeOnSelect
                value={selectedOptions.endDate ? moment(selectedOptions?.endDate) : ""}
              />
              <div className="input-icon">
                <IconCalendar />
              </div>
            </div>
            <Form.Control
              type="text"
              className="ml-2 w-50"
              placeholder="Insert APPT_ID"
              onChange={(e: any) => {
                handleInputValChange(e, "appointmentId");
              }}
            />
            <button className="btn btn-success ml-2" onClick={() => onSearch()}>
              {/* {apptReportLoading && (
              <span
                className="spinner-border spinner-border-sm mr-2"
                role="status"
                aria-hidden="true"
              ></span>
            )} */}
              Generate Report
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SearchFilter;
