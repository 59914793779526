import SubHeaderTab from "Common/Components/SubHeaderTab";
import { useAppContext } from "contexts/AppContext";
import { getDuplicateArrayofObjects } from "pages/EmptyReturn/Common/utils";
import { TERMINAL_CHOICES } from "pages/EmptyReturn/constant";
import React, { useEffect, useState } from "react";
import SearchFilter from "./SeachFilter";
import EmptyTable from "./EmptyTable";
import Screenshot from "./Screenshot";

const EmptyCorrection = () => {
  const { state } = useAppContext();
  const { userSelectedTerminals } = state.appStates;
  // ---------------------------------------------------- STATE MANAGEMENT---------------------------------------------------//
  const [selectedTerminal, setSelectedTerminal] = useState<any>({});
  const [payloadData, setPayloadData] = useState<any>(null);
  const [s3Url, setS3Url] = useState<any>();
  const [allTerminals, setAllTerminals] = useState<any>([]);
  // ---------------------------------------------------- HOOKS MANAGEMENT ---------------------------------------------------//
  useEffect(() => {
    const totalTerminals = getDuplicateArrayofObjects(
      TERMINAL_CHOICES,
      userSelectedTerminals,
      "code",
      "code"
    );
    setAllTerminals(totalTerminals);
    totalTerminals.length && setSelectedTerminal(totalTerminals[0]);
  }, [userSelectedTerminals]);
  // ---------------------------------------------------- HANDLE FUNCTION---------------------------------------------------//
  const handleTerminalChange = (data: any) => {
    setPayloadData(data);
  };

  const handleUrlChange = (url: any) => {
    setS3Url(url);
  };

  return (
    <div className="page-wrapper">
      <div className="position-relative subheader-wrapper">
        <SubHeaderTab val="emptyCorrectionItems" />
        <div className="page-fluid ">
          <div className="top-page">
            <div className="pb-15 d-flex align-items-center">
              <h4 className="mb-0">Empty Correction</h4>
            </div>
            {/* Filter Row */}
            <SearchFilter
              onTerminalChange={handleTerminalChange}
              terminal={selectedTerminal}
              allTerminals={allTerminals}
              data={payloadData}
            />
          </div>
          <div className="table-responsive">
            <table className="table table-card">
              <thead>
                <tr>
                  <th>Sno</th>
                  <th>SSL</th>
                  <th>Size/Types</th>
                  <th>Shift</th>
                  <th>Remarks</th>
                  <th>Status</th>
                  <th>Empty Return Location</th>
                </tr>
              </thead>
              <tbody>
                <EmptyTable data={payloadData} selectedTerminal={selectedTerminal} s3url={s3Url} />
                <Screenshot onUrlChange={handleUrlChange} />
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmptyCorrection;
