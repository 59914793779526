import React, { Fragment, useCallback, useEffect, useState } from "react";
import CustomSelectField, {
  SelectFieldWithoutControl,
} from "Common/Components/Inputs/CustomSelectField";
import { useAppContext } from "contexts/AppContext";
import _ from "lodash";
import { setSuccessfulContainersParams } from "../../SuccessfulDataReport/action";
import { getDuplicateArrayofObjects } from "pages/EmptyReturn/Common/utils";
import { getDrayosSummary } from "../action";
import DownloadSyncContainer from "./DownloadSyncContainer";
import DownloadCompareStatus from "./DownloadCompareStatus";

interface IFilterValues {
  status?: string;
  missing_fields?: any;
  port?: string;
}

const statusOpts = [
  { label: "All", value: "all" },
  { label: "On Vessel", value: "On Vessel" },
  { label: "Not Available", value: "Not Available" },
  { label: "Available", value: "Available" },
  { label: "Outgated", value: "Outgated" },
  { label: "Terminated", value: "Terminated" },
];

const SearchFilter = (props: any) => {
  const { state, myDispatch } = useAppContext();
  const { ports } = state.configStates;
  const { searchPost, sLoading, carriers, drLoading } = state.reportDRStates;

  let carriersOpts = [{ label: "All", value: "ALL" }];
  carriersOpts = [
    ...carriersOpts,
    ...carriers.map((opt: any) => ({ label: opt.carrier, value: opt._id })),
  ];

  const [showAlert, setShowAlert] = useState(false);
  const handleCarrierChange = (val: any) => {
    let parameters = { ...props.params };
    parameters.carrier = val.value;
    props.setParams(parameters);
  };
  const handleStatusChange = (val: any) => {
    let parameters = { ...props.params };
    parameters.trackStatus = val.value;
    props.setParams(parameters);
  };
  const onSearch = (isReturn: boolean) => {
    if (!props.params.carrier) {
      setShowAlert(true);
    } else {
      setShowAlert(false);
      myDispatch(getDrayosSummary(props.params, isReturn));
    }
  };
  const onChangeInput = (event: any) => {
    let val = event.target.value;
    let parameters = { ...props.params };
    parameters.recent = val;
    props.setParams(parameters);
  };
  const debouncedChangeHandler = useCallback(_.debounce(onChangeInput, 1000), []);
  return (
    <Fragment>
      <div className="filter-row d-flex align-items-center">
        {/* Search */}
        <div className="app-search header-search">
          {/* <span className="search-icon"> </span> */}
          <div className="position-relative">
            {/* <input
            type="search"
            className="form-control input-search-left"
            placeholder="Search Containers..."
            defaultValue={props?.params?.recent}
            onChange={debouncedChangeHandler}
          /> */}
          </div>
        </div>

        <SelectFieldWithoutControl
          isLoading={false}
          isClearable={true}
          isSearchable={true}
          name="carrier"
          options={carriersOpts}
          onChange={(e: any) => handleCarrierChange(e)}
          size="small"
          className="min-200 mr-2"
          placeholder="Select Carrier"
          value={
            props.params.carrier &&
            carriersOpts.find((opt: any) => opt.value == props.params.carrier)
          }
        />
        <SelectFieldWithoutControl
          isLoading={false}
          isClearable={true}
          isSearchable={true}
          name="Status"
          options={statusOpts}
          onChange={(e: any) => handleStatusChange(e)}
          size="small"
          className="min-200 mr-2"
          placeholder="Select Status"
          value={
            props?.params?.status &&
            statusOpts.find((opt: any) => opt.value == props?.params?.status)
          }
        />

        <div className="input-group">
          <input
            type="number"
            className="col-1 form-control min-10 mr-2"
            defaultValue={props?.params?.recent}
            onChange={debouncedChangeHandler}
            step={1}
            min={1}
          />
        </div>
        {props.params.carrier && props.params.carrier !== "ALL" && (
          <DownloadSyncContainer carrier={props.params.carrier} />
        )}
        {props.params.carrier && props.params.carrier !== "ALL" && (
          <DownloadCompareStatus carrier={props.params.carrier} />
        )}
        <button
          className="btn btn-success ml-2"
          onClick={() => onSearch(false)}
          disabled={sLoading}
        >
          {drLoading && (
            <span
              className="spinner-border spinner-border-sm mr-2"
              role="status"
              aria-hidden="true"
            ></span>
          )}
          Generate Report
        </button>

        <button className="btn btn-success ml-2" onClick={() => onSearch(true)} disabled={sLoading}>
          {drLoading && (
            <span
              className="spinner-border spinner-border-sm mr-2"
              role="status"
              aria-hidden="true"
            ></span>
          )}
          Return Location Report
        </button>
      </div>
      <div className="filter-row d-flex align-items-center">
        {showAlert && (
          <div className="col alert alert-warning" role="alert">
            Please Select Carrier
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default SearchFilter;
