import SubHeaderTab from "Common/Components/SubHeaderTab";
import { useAppContext } from "contexts/AppContext";
import { useEffect } from "react";
import Pagination from "Common/Components/Pagination";
import { getArchiveContainers, setArchiveContainersParams } from "./action";
import ArchiveContainerTable from "./Component/ArchiveContainerTable";
import FilterSearch from "./Component/FilterRow";

const ArchiveContainer = () => {
  const { state, myDispatch } = useAppContext();
  const { searchParams, archiveContainerCount, searchPost } = state.archiveContainerStates;
  const { limit, page } = searchParams;

  useEffect(() => {
    getAllArchiveContainers();
  }, [searchParams, searchPost]);

  // fetch all archive Containers
  const getAllArchiveContainers = async () => {
    const response = await getArchiveContainers(searchPost, searchParams);
    myDispatch(response);
  };

  // Paginate
  const onPaginate = (val: any) => {
    let searchParamsD = { ...searchParams, page: val };
    myDispatch(setArchiveContainersParams(searchPost, searchParamsD));
  };

  return (
    <div className="page-wrapper">
      <div className="position-relative subheader-wrapper">
        <SubHeaderTab val="reports" />
        <div className="page-fluid">
          <div className="top-page">
            <div className="pb-15 d-flex align-items-center">
              <h4 className="mb-0">Archive Container</h4>
              <div className="ml-auto">
                <Pagination
                  className={"ml-auto"}
                  pageRangeDisplayed={5}
                  totalItemsCount={archiveContainerCount}
                  itemsCountPerPage={limit}
                  onChange={onPaginate}
                  activePage={page}
                />
              </div>
            </div>
            <FilterSearch />
          </div>
          <ArchiveContainerTable />
        </div>
      </div>
    </div>
  );
};

export default ArchiveContainer;
