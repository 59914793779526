import { IconCancelCircle } from "Common/Icons";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, FormControl, FormLabel, Modal, Row } from "react-bootstrap";
import Loader from "Common/Components/Loader";
import { useAppContext } from "contexts/AppContext";
import { getAllUsers, updateScacCode } from "pages/Dashboard/action";
import { CustomInputArrayField } from "Common/Components/Inputs/CustomInputArrayField";

const UpdateScacPop = (props: any) => {
  //API Initialize
  const { state, myDispatch } = useAppContext();
  const { userSearchPost, updateScacResponse } = state.dashboardStates;
  const {
    showUpdateScacModal,
    handleClose,
    selectedUser,
    handleUpdateScacToast,
    otherScac,
    mainScac,
    searchParam,
  } = props;
  const { cLoading } = state.dashboardStates;
  const [inputScacCode, setInputScacCode] = useState(mainScac);
  const [inputOtherScacCode, setInputOtherScacCode] = useState<any>(otherScac);
  const [isEditable, setIsEditable] = useState(false); // To control editability

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputScacCode(e.target.value);
  };

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      console.log("Enter key pressed");
      event.preventDefault();
    }
    // Make the field editable when a key is pressed
    setIsEditable(true);
  };

  const handleOtherScacChange = (newValues: string) => {
    setInputOtherScacCode(newValues);
  };

  const handleUpdateScacFormSubmit = async (e: any) => {
    e.preventDefault();
    const data = {
      userId: selectedUser?._id,
      carrierScac: inputScacCode,
      otherScac: inputOtherScacCode,
    };
    const response = updateScacCode(data);
    await myDispatch(response);
    if (updateScacResponse.success || Object.keys(updateScacResponse).length === 0) {
      await myDispatch(getAllUsers(userSearchPost, searchParam));
    }
    const scacCodeInfo = {
      scacCode: inputScacCode,
      id: selectedUser._id,
      otherScac: inputOtherScacCode,
    };
    response.payload
      .then((res) => {
        handleClose(scacCodeInfo);
      })
      .catch((err) => {
        handleClose();
      });
    handleUpdateScacToast(true);
  };

  return (
    <React.Fragment>
      {cLoading && <Loader />}
      <Modal show={showUpdateScacModal} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Update Scac Form</Modal.Title>
          <button className="btn btn-link" onClick={handleClose}>
            <IconCancelCircle onClick={handleClose} />
          </button>
        </Modal.Header>

        <Modal.Body>
          <Form onSubmit={handleUpdateScacFormSubmit}>
            <Form.Group className="mb-3" controlId="formScacCodeInput">
              <FormLabel>Updating Scac Code For: {selectedUser?.email}</FormLabel>
              <FormControl
                type="text"
                placeholder="Enter Scac Code"
                value={inputScacCode}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown} // Trigger editable mode
                readOnly={!isEditable} // Make field editable based on keydown
              />
            </Form.Group>
            <FormLabel>Updating Other Scac Code For: {selectedUser?.email}</FormLabel>
            <CustomInputArrayField
              value={inputOtherScacCode}
              onChange={handleOtherScacChange}
              placeholder="Enter value"
              className="custom-class"
            />

            <Modal.Footer>
              <Button variant="secondary" type="button" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" type="submit">
                Update
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default UpdateScacPop;
