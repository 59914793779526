import { Fragment, useState } from "react";
import moment from "moment";
import Loader from "Common/Components/Loader";
import { caseStatus } from "Common/Components/StatusBadge";
import { useAppContext } from "contexts/AppContext";
import { handleSort } from "pages/EmptyReturn/Common/utils";
import ContainerDetails from "pages/Importavailability/component/ContainerDetails";
import { generateLFDColor, getDetailedValue } from "utils/commonFunctions";
import { tableHeaders } from "../constant";
import { setSuccessfulContainersParams } from "../action";

const SuccessfulDataReportTable = () => {
  const { state, myDispatch } = useAppContext();
  const { loggedUser } = state.appStates;
  const { containerSizes, containerOwners, containerTypes, ports } = state.configStates;
  const { successfulContainers, searchParams, searchPost, sLoading } =
    state.successfulContainerStates;
  const { page, limit } = searchParams;

  const [headers, setTableHeader] = useState<any | null>(tableHeaders);
  const [viewDetails, setViewDetails] = useState<any | null>();

  // get size and type
  const getSizeType = (containerSize: any, containerType: any) => {
    let sizeVal = getDetailedValue(containerSize, containerSizes);

    let typeVal = getDetailedValue(containerType, containerTypes);
    return `${sizeVal} ${sizeVal && typeVal && "-"} ${typeVal}`;
  };
  // get port
  const getPortTerminalName = (val: any, vtype: "port" | "terminal") => {
    if (typeof val === "string") {
      return getDetailedValue(val, vtype === "port" ? ports : loggedUser?.terminal);
    } else {
      return val?.name;
    }
  };
  // get Empty Return
  const getEmptyReturn = (vals: any) => {
    let emptyReturn: string[] = [];
    vals.length > 0 &&
      vals.map((val: any) => {
        if (typeof val === "object") {
          emptyReturn.push(val.port);
        } else {
          let portname = getPortTerminalName(val, "port");
          if (portname) emptyReturn.push(portname);
        }
      });
    return emptyReturn;
  };

  // Sorting
  const onSort = (obj: any) => {
    const searchParamsD = handleSort(obj, headers, searchParams);
    myDispatch(setSuccessfulContainersParams(searchPost, searchParamsD));
  };

  return (
    <Fragment>
      <div className="table-responsive" style={{ height: "calc(100vh - 255px)" }}>
        {sLoading && <Loader />}
        <table className="table table-card">
          <thead>
            <tr>
              <th scope="col" className="">
                Sno
              </th>
              {headers.map((item: any, i: number) => {
                let sortIcon = "table-sort table-sort--default";
                if (item.sort === "-" && item.active) {
                  sortIcon = "table-sort table-sort--ascending";
                }
                if (item.sort === "+" && item.active) {
                  sortIcon = "table-sort table-sort--descending";
                }
                return (
                  <th key={i} onClick={() => onSort(item)} className={item.sort ? sortIcon : ""}>
                    {item.label}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {successfulContainers && successfulContainers.length ? (
              successfulContainers.map((successfulContainer: any, i: number) => {
                return (
                  <tr key={i}>
                    <td className="text-center">{(page - 1) * limit + i + 1}</td>
                    <td>{caseStatus(successfulContainer.status)}</td>
                    <td
                      className="pointer text-primary"
                      onClick={() => {
                        setViewDetails(successfulContainer);
                      }}
                    >
                      {successfulContainer.containerNo}
                    </td>
                    <td>
                      {successfulContainer.updatedAt
                        ? moment(successfulContainer.updatedAt).format("lll")
                        : ""}
                    </td>
                    <td>{successfulContainer.userId.companyName}</td>
                    <td>{successfulContainer.userId.email}</td>
                    <td>
                      {successfulContainer.custom && (
                        <p>{"Custom: " + successfulContainer.custom}</p>
                      )}
                      {successfulContainer.freight && (
                        <p>{"Freight: " + successfulContainer.freight}</p>
                      )}
                      {successfulContainer.brokerHold && (
                        <p>{"Broker: " + successfulContainer.brokerHold}</p>
                      )}
                    </td>
                    <td>{getDetailedValue(successfulContainer.containerOwner, containerOwners)}</td>
                    <td>
                      {getSizeType(
                        successfulContainer.containerSize,
                        successfulContainer.containerType
                      )}
                    </td>
                    <td>{getPortTerminalName(successfulContainer.portId, "port")}</td>
                    <td>
                      {successfulContainer.eta
                        ? moment(successfulContainer.eta).format("MM/DD")
                        : successfulContainer.sslEta
                        ? moment(successfulContainer.sslEta).format("MM/DD")
                        : ""}
                    </td>
                    <td>
                      {successfulContainer.dischargeDate
                        ? moment(successfulContainer.dischargeDate).format("MM/DD")
                        : successfulContainer.sslDischargeDate
                        ? moment(successfulContainer.sslDischargeDate).format("MM/DD")
                        : ""}
                    </td>
                    <td
                      className={generateLFDColor(
                        successfulContainer.lastFreeDay,
                        successfulContainer.status
                      )}
                    >
                      {successfulContainer.lastFreeDay &&
                        moment(successfulContainer.lastFreeDay).format("MM/DD")}
                    </td>

                    <td>
                      {successfulContainer.outGateDate &&
                        moment(successfulContainer.outGateDate).format("MM/DD")}
                    </td>
                    <td className="">
                      {getEmptyReturn(successfulContainer.emptyReturnLocation)
                        .toString()
                        .replaceAll("_", " ")}
                    </td>
                    <td>
                      {successfulContainer.terminatedDate
                        ? moment(successfulContainer.terminatedDate).format("MM/DD")
                        : successfulContainer.inGate?.date
                        ? moment(successfulContainer.inGate?.date).format("MM/DD")
                        : ""}
                    </td>
                    <td>{successfulContainer.vesselName}</td>
                    <td>{successfulContainer.voyageName}</td>
                    <td>{successfulContainer.billOfLading}</td>
                    <td>{successfulContainer.grossWeight}</td>
                    <td>{successfulContainer.seal}</td>
                    <td>{successfulContainer.yardLocation}</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={100}>Data Not Found</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {/* Container Details Modal */}
      {viewDetails && viewDetails.containerNo && (
        <ContainerDetails values={viewDetails} onCancel={() => setViewDetails(null)} />
      )}
    </Fragment>
  );
};

export default SuccessfulDataReportTable;
