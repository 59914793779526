import React from "react";

const Notes = () => {
  return (
    <div className="form-wrapper p-10">
      <form action="">
        <div className="form-group mb-15">
          <h5> Notes</h5>
          <textarea
            className="form-control font-14"
            placeholder="Enter a public notes"
            rows={6}
          ></textarea>
        </div>
      </form>
    </div>
  );
};

export default Notes;
