import { nyPort, ports } from "pages/EmptyReturn/constant";
import sslFile from "../EmptyReturn/sslFile.json";
import nySslFile from "../EmptyReturn/nysslFile.json";

export interface DataTypesI {
  port: string;
  remarks: string;
  remarks2?: string;
  shift1: string;
  shift2: string;
  ssl: string;
  types: string;
}
export interface EmptiesTypes {
  key: string;
  data: {
    data: DataTypesI[];
    s3url: string;
  };
  alert?: string;
}
export interface SmallTypesI {
  label: string;
  checked: boolean;
}
export interface ValueTypes {
  label: string;
  value: string;
}

export const LA_SHIFT_OPTIONS = [
  { value: "shift1", label: "shift1" },
  { value: "shift2", label: "shift2" },
  { value: "shift3", label: "shift3" },
];

export const NY_SHIFT_OPTIONS = [
  { value: "shift1", label: "shift1" },
  // { value: "shift2", label: "shift2" },
  // { value: "shift3", label: "shift3" },
];

export const LA_STATUS_OPTIONS = [
  { value: "OPEN", label: "OPEN" },
  { value: "RESTRICTED", label: "RESTRICTED" },
  { value: "DUAL", label: "DUAL" },
  { value: "CONTACT SSL", label: "CONTACT SSL" },
  { value: "NONE", label: "NONE" },
];

export const NY_STATUS_OPTIONS = [
  { value: "OPEN", label: "OPEN" },
  { value: "RESTRICTED", label: "RESTRICTED" },
  // { value: "DUAL", label: "DUAL" },
  { value: "CONTACT SSL", label: "CONTACT SSL" },
  { value: "NONE", label: "NONE" },
];

export const LA_PORT_CHOICES: any = ports.map((pp) => ({
  label: pp.title.replaceAll("_", " "),
  value: pp.title,
}));

export const NY_PORT_CHOICES = nyPort.map((pp) => ({
  label: pp.title.replaceAll("_", " "),
  value: pp.title,
}));

export const getInitialFilters = (terminal: string) => {
  let temp1: any = [
    {
      key: "PORTS",
      value: [],
      label: [],
    },
    {
      key: "SSL",
      value: [],
      label: [],
    },
    {
      key: "CONTAINER TYPES",
      value: [],
      label: [],
    },
  ];

  if (terminal === "LA") {
    if (!localStorage.getItem("LAEmptyFilter")) {
      ports.map((pp) => {
        return temp1[0].value.push({
          label: pp.title,
          value: pp.title,
        });
      });
      sslFile.shipping_lines.map((ss) => {
        return temp1[1].value.push({
          label: ss,
          value: ss,
        });
      });
      sslFile.container_types.map((ct) => {
        return temp1[2].value.push({
          label: ct,
          value: ct,
        });
      });
      localStorage.setItem("LAEmptyFilter", JSON.stringify(temp1));
      return localStorage.getItem("LAEmptyFilter");
    } else {
      return localStorage.getItem("LAEmptyFilter");
    }
  } else if (terminal === "NY") {
    if (!localStorage.getItem("NYEmptyFilter")) {
      nyPort.map((pp) => {
        return temp1[0].value.push({
          label: pp.title,
          value: pp,
        });
      });
      nySslFile.shipping_lines.map((ss) => {
        return temp1[1].value.push({
          label: ss,
          value: ss,
        });
      });
      nySslFile.container_types.map((ct) => {
        return temp1[2].value.push({
          label: ct,
          value: ct,
        });
      });
      localStorage.setItem("NYEmptyFilter", JSON.stringify(temp1));
      return localStorage.getItem("NYEmptyFilter");
    } else {
      return localStorage.getItem("NYEmptyFilter");
    }
  }
};
