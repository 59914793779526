import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import CustomInputField from "Common/Components/Inputs/CustomInputField";
import CustomSelectField, {
  SelectFieldWithoutControl,
} from "Common/Components/Inputs/CustomSelectField";
import { CompanyInfoTypes } from "../onboarding";
import { yupResolver } from "@hookform/resolvers/yup";
import { companyInfoSchema } from "../onBoardingValidation";
import { useAppContext } from "contexts/AppContext";
import { addCompanyInfo } from "reducers/action/appAction";
import Stepper from "./Stepper";

const options = [
  // { value: "broker", label: "Broker" },
  // { value: "consignee", label: "Consignee" },
  // { value: "shipper", label: "Shipper" },
  { value: "trucker", label: "Trucker" },
];

interface IProps {
  AddNewCompanyModal: any;
  handleNextStep: (value: number) => void;
  activeStep: number;
}

const AddCompanyModal = (props: IProps) => {
  const { AddNewCompanyModal, handleNextStep, activeStep } = props;
  const { state, myDispatch } = useAppContext();
  const { companyAddress, companyName, companyType, fullName, role } = state.appStates.loggedUser;

  const [isCompanyInfo, setIsCompanyInfo] = useState(false);
  const [selectCompanyType, setSelectCompanyType] = useState<any>(options[0]);
  const [companyTypeError, setCompanyTypeError] = useState("");

  // REACT HOOK FORM
  const {
    register,
    formState: { errors },
    handleSubmit,
    setError,
    control,
  } = useForm<CompanyInfoTypes>({
    mode: "onBlur",
    resolver: yupResolver(companyInfoSchema),
  });

  const onFormSubmit = (data: CompanyInfoTypes) => {
    if (role === "Employee") {
      const finalData = {
        ...data,
      };
      myDispatch(addCompanyInfo(finalData));
      setIsCompanyInfo(true);
    } else {
      if (selectCompanyType?.value) {
        const finalData = {
          ...data,
          companyType: selectCompanyType.value,
        };
        myDispatch(addCompanyInfo(finalData));
        setIsCompanyInfo(true);
      } else {
        setCompanyTypeError("Company Type is required");
      }
    }
  };

  const handleCompanyType = (value: any) => {
    setSelectCompanyType(value);
  };

  useEffect(() => {
    if (isCompanyInfo && !state.appStates.errorMessage) {
      handleNextStep(0);
    }
  }, [state]);

  useEffect(() => {
    if (companyType) {
      setSelectCompanyType(options.find((opt) => opt.value === companyType));
    }
  }, [state.appStates.loggedUser]);

  return (
    <Modal show={AddNewCompanyModal} centered animation={false} size="sm">
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <Modal.Header className="py-20 px-30 justify-content-center">
          <Modal.Title>
            <div className="text-center">
              <div className="font-20 font-weight-500 text-gray-900 pb-1">
                {role !== "Employee" && "Company"} Info
              </div>
              <div className="font-12 font-weight-normal text-gray-500">
                Fill out required company info
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="nav-border-bottom">
          <div className="card mb-0">
            <div className="form-wrapper">
              <div className="form-row ">
                {/* <span className="text-danger"> {state.appStates.errorMessage} </span>
                <span className="text-success"> {state.appStates.message} </span> */}
                {/* <h3 className="text-center mb-50">Start Improving Your Shipping!</h3> */}
                {state.appStates.errorMessage && (
                  <div className="alert alert-warning" role="alert">
                    {state.appStates.errorMessage}
                  </div>
                )}
                <div className="form-group mb-0 col-md-12">
                  <CustomInputField
                    autoComplete="off"
                    placeholder="Enter Group Name"
                    name="fullName"
                    defaultValue={fullName || ""}
                    type="text"
                    required
                    error={Boolean(errors.fullName)}
                    register={register}
                    helper={errors.fullName ? errors.fullName.message : ""}
                    label={
                      <span>
                        Your Full Name<span className="text-danger">*</span>
                      </span>
                    }
                    className="form-control"
                  />
                </div>
                {role !== "Employee" && (
                  <React.Fragment>
                    <div className="form-group col-md-12">
                      <SelectFieldWithoutControl
                        label="Company Type"
                        required
                        name="companyType"
                        options={options}
                        value={selectCompanyType}
                        onChange={(value) => handleCompanyType(value)}
                        isClearable={false}
                        size="small"
                        placeholder="Select Here.."
                      />
                      {companyTypeError && (
                        <div className="mt-2 error" style={{ color: "red" }}>
                          {companyTypeError}
                        </div>
                      )}
                    </div>
                    <div className="form-group mb-0 col-md-12">
                      <CustomInputField
                        autoComplete="off"
                        placeholder="e.g BigTruckCompany"
                        name="companyName"
                        defaultValue={companyName || ""}
                        type="text"
                        required
                        error={Boolean(errors.companyName)}
                        register={register}
                        helper={errors.companyName ? errors.companyName.message : ""}
                        label={
                          <span>
                            Company Name<span className="text-danger">*</span>
                          </span>
                        }
                        className="form-control"
                      />
                    </div>
                    {/* <div className="form-group col-md-12">
                      <CustomInputField
                        autoComplete="off"
                        placeholder="e.g 2118 Thornridge Cir. Syracuse, Connecticut 35624"
                        name="companyAddress"
                        defaultValue={companyAddress || ""}
                        type="text"
                        required
                        error={Boolean(errors.companyAddress)}
                        register={register}
                        helper={errors.companyAddress ? errors.companyAddress.message : ""}
                        label={
                          <span>
                            Company Address<span className="text-danger">*</span>
                          </span>
                        }
                        className="form-control"
                      />
                    </div> */}
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-between w-100 align-items-center">
            {/* <button className="btn btn-outline-light mr-10" onClick={hideModal}>
            Back
          </button> */}
            {/* <button
              onClick={() => handleNextStep(0)}
              className="mr-4 btn btn-outline-light"
              type="button"
            >
              Skip
            </button> */}
            <Stepper activeStep={activeStep} />
            <button
              disabled={Boolean(Object.keys(errors).length)}
              className="btn btn-outline-light"
              type="submit"
            >
              {state.appStates.loading && (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
              Continue
            </button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default AddCompanyModal;
