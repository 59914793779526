import React, { useEffect, useState } from "react";
import SubHeaderTab from "Common/Components/SubHeaderTab";
import { useAppContext } from "contexts/AppContext";
import SuccessfulDataReportTable from "./Components/SuccessfulMatricsTable";
import SearchFilter from "./Components/SearchFilter";

import DownloadCsv from "pages/ReportContainer/Components/DownloadCsv";
import { getCarrierDetail } from "pages/DrayosSummaryReport/action";
import { getSuccessfulMatricsContainers } from "./action";
import DownloadMatrixCsv from "pages/DrayosSummaryReport/Components/DownloadMatrix";
import Matrix from "pages/DrayosSummaryReport/Components/Matrix";

const SucessfulMatricsContainerReport = () => {
  const { state, myDispatch } = useAppContext();
  const {
    successfulMatricContainers,
    successfulMatricContainerCount,
    totalSuccessfulMatricContainerCount,
  } = state.SucessfulMatricsContainerStates;
  // const [params, setParams] = useState<any>({ recent: 45, carrier: "ALL" });
  // const { carriers, crLoading } = state.reportDRStates;

  //   useEffect(() => {
  //   myDispatch(getCarrierDetail());
  // }, []);

  useEffect(() => {
    getAllSuccessfulMatricsContainers();
  }, []);

  const getAllSuccessfulMatricsContainers = () => {
    const response = getSuccessfulMatricsContainers();
    myDispatch(response);
  };

  return (
    <div className="page-wrapper">
      <div className="position-relative subheader-wrapper">
        <SubHeaderTab
          val="reports"
          buttons={<DownloadMatrixCsv page="successfulMatricContainer" />}
        />
        <div className="page-fluid">
          <div className="top-page">
            <div className="pb-15 d-flex align-items-center">
              <div className="ml-auto"></div>
            </div>
            <div className="font-18 font-weight-bold d-flex justify-content-center align-items-center mb-20">
              Duration Metric: {successfulMatricContainerCount}/
              {totalSuccessfulMatricContainerCount}
            </div>
            <SearchFilter />
            {/* <Matrix /> */}
          </div>
          <SuccessfulDataReportTable />
        </div>
      </div>
    </div>
  );
};

export default SucessfulMatricsContainerReport;
