import CustomSelectField from "Common/Components/Inputs/CustomSelectField";
import { IconFilter, IconReset, IconTimesThin } from "Common/Icons";
import { useAppContext } from "contexts/AppContext";
import _ from "lodash";
import { getDuplicateArrayofObjects } from "pages/EmptyReturn/Common/utils";
import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { resetSuccessfulContainersParams, setSuccessfulContainersParams } from "../action";
import { ISuccessfulContainerFilterValues } from "../successfulContainer";

const FilterForm = () => {
  const { state, myDispatch } = useAppContext();
  const { containerOwners, noCredentialPorts } = state.configStates;
  const { userSelectedTerminals } = state.appStates;
  const { searchParams, searchPost } = state.successfulContainerStates;

  const [showFilter, setShowFilter] = useState(false);

  // Port Options
  const userSelectedPorts = getDuplicateArrayofObjects(
    noCredentialPorts,
    userSelectedTerminals,
    "terminalCode",
    "code"
  );

  const PORT_CHOICES = userSelectedPorts.map((it: any) => ({
    label: it.name,
    value: it._id,
  }));

  // React Hook Form
  const { reset, register, handleSubmit, control } = useForm({
    defaultValues: searchPost,
    shouldUnregister: false,
    mode: "all",
  });

  const onFormSubmit: SubmitHandler<ISuccessfulContainerFilterValues> = async (values) => {
    let data: any = {};
    data.portId = values.portId?.value || values.portId || "";
    data.containerOwner = values.containerOwner?.value || values.containerOwner || "";
    let searchPostD = { ...searchPost, ...data };

    myDispatch(setSuccessfulContainersParams(searchPostD, searchParams));
  };

  const handleReset = () => {
    myDispatch(resetSuccessfulContainersParams());
  };

  useEffect(() => {
    if (searchPost.reset) {
      reset({
        portId: "",
        containerOwner: "",
      });
    }
  }, [searchPost]);

  return (
    <>
      <div className="ml-auto d-flex">
        <button className="btn btn-outline-light" onClick={() => setShowFilter(!showFilter)}>
          <IconFilter className="mr-2" />
          Filter
        </button>
      </div>
      {showFilter && (
        <React.Fragment>
          <div className="bdpopup-backdrop"></div>
          <div className="bdpopup">
            <div className="bdpopup__content">
              {/* Close Filter */}
              <button
                className="btn btn-none bdpopup__close text-white bg-gray-500 rounded-circle"
                onClick={() => setShowFilter(false)}
              >
                <IconTimesThin className="close-icon" />
              </button>
              {/* Reset FIlter */}
              <form onSubmit={handleSubmit(onFormSubmit)}>
                {/* Reset FIlter */}
                <div className="d-flex px-30 align-items-center justify-content-between py-20">
                  <p className="font-20 mb-0">Filters</p>
                  <button
                    type="button"
                    className="btn btn-outline-light btn-sm py-1"
                    onClick={() => handleReset()}
                  >
                    <IconReset />
                  </button>
                </div>
                <div
                  className="mt-30 px-30 overflow-y-scroll"
                  style={{ height: "calc(100vh - 200px)" }}
                >
                  <div className="form-group form-row">
                    <label className="col-md-5 col-form-label">Port</label>
                    <div className="col-md-7">
                      <CustomSelectField
                        isLoading={false}
                        isClearable={true}
                        isSearchable={true}
                        name="portId"
                        options={PORT_CHOICES}
                        control={control}
                        size="small"
                      />
                    </div>
                  </div>
                  <div className="form-group form-row">
                    <label className="col-md-5 col-form-label">SSL</label>
                    <div className="col-md-7">
                      <CustomSelectField
                        isLoading={false}
                        isClearable={true}
                        isSearchable={true}
                        name="containerOwner"
                        options={containerOwners.map((ct: any) => ({
                          label: ct.name,
                          value: ct._id,
                        }))}
                        control={control}
                        size="small"
                      />
                    </div>
                  </div>
                </div>
                <div className="bdpopup__footer page-fluid">
                  <button type="submit" className="btn btn-primary btn-block">
                    Apply
                  </button>
                </div>
              </form>
            </div>
          </div>
        </React.Fragment>
      )}
    </>
  );
};

export default FilterForm;
