import React, { useState } from "react";
import { IconInfoFocus, IconDocument, IconSearchData, IconNotes } from "Common/Icons";
import ContainerEditForm from "./ContainerEditForm";
import Audit from "../tabComponents/Audit";
import Notes from "../tabComponents/Notes";
import Documents from "../tabComponents/Documents";

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

const tabItems = [
  {
    id: 1,
    label: "Container Info",
    icon: <IconInfoFocus />,
  },
  {
    id: 2,
    label: "Documents",
    icon: <IconDocument />,
  },
  // {
  //   id: 3,
  //   label: "Audit",
  //   icon: <IconSearchData />,
  // },
  {
    id: 4,
    label: "Notes",
    icon: <IconNotes />,
  },
];

const ContainerEdit = () => {
  const [isLoadTabActive, setisLoadTabActive] = useState<number | null>(1);
  const handleLoadTabActive = (i: React.SetStateAction<number | null>) => {
    setisLoadTabActive(isLoadTabActive === i ? null : i);
  };

  return (
    <div className="page-fluid">
      <div className="card mb-0">
        <ul className="nav nav-tabs nav-tabs-custom nav-justified nav-border-bottom">
          {tabItems.map((tabItem) => (
            <li className="nav-item" key={tabItem.id}>
              <div
                className={`nav-link ${isLoadTabActive === tabItem.id ? "active" : ""}`}
                onClick={() => handleLoadTabActive(tabItem.id)}
              >
                <div className="text-primary">{tabItem.icon}</div>
                <div className="nav-link__text">{tabItem.label}</div>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div className="tab-loads">
        {isLoadTabActive === 1 && <ContainerEditForm />}
        {/* {isLoadTabActive === 3 && <Audit />} */}
        {isLoadTabActive === 4 && <Notes />}
      </div>
    </div>
  );
};

export default ContainerEdit;
