import CustomSelectField from "Common/Components/Inputs/CustomSelectField";
import { IconReset, IconTimesThin } from "Common/Icons";
import { useAppContext } from "contexts/AppContext";
import { getDuplicateArrayofObjects } from "pages/EmptyReturn/Common/utils";
import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { resetWrongDataReportParams, setWrongDataReportParams } from "../action";
import { IWrongContainerFilterValues } from "../wrongcontainers";

interface IProps {
  hideFilter: any;
}

const WrongDataReportFilter = (props: IProps) => {
  const { hideFilter } = props;
  const { state, myDispatch } = useAppContext();
  const { searchParams, searchPost } = state.wrongDataReportStates;
  const { containerOwners, noCredentialPorts } = state.configStates;
  const { userSelectedTerminals } = state.appStates;

  const userSelectedPorts = getDuplicateArrayofObjects(
    noCredentialPorts,
    userSelectedTerminals,
    "terminalCode",
    "code"
  );

  const PORT_CHOICES = userSelectedPorts.map((it: any) => ({
    label: it.name,
    value: it._id,
  }));

  // REACT HOOK FORM
  const {
    reset,
    register,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm<IWrongContainerFilterValues>({
    defaultValues: searchPost,
    shouldUnregister: false,
    mode: "all",
  });

  const onFormSubmit: SubmitHandler<IWrongContainerFilterValues> = async (values) => {
    let data: any = {};
    data.portId = values.portId?.value || values.portId || "";
    data.containerOwner = values.containerOwner?.value || values.containerOwner || "";
    let searchPostD = { ...searchPost, ...data };
    searchParams.page = 1;
    myDispatch(setWrongDataReportParams(searchParams, searchPostD));
  };

  const handleReset = () => {
    myDispatch(resetWrongDataReportParams());
  };

  useEffect(() => {
    if (searchPost.reset) {
      reset(searchPost);
    }
  }, [searchPost]);

  return (
    <React.Fragment>
      <div className="bdpopup-backdrop"></div>
      <div className="bdpopup">
        <div className="bdpopup__content">
          {/* Close Filter */}
          <button
            className="btn btn-none bdpopup__close text-white bg-gray-500 rounded-circle"
            onClick={hideFilter}
          >
            <IconTimesThin className="close-icon" />
          </button>
          {/* Reset FIlter */}
          <form onSubmit={handleSubmit(onFormSubmit)}>
            {/* Reset FIlter */}
            <div className="d-flex px-30 align-items-center justify-content-between py-20">
              <p className="font-20 mb-0">Filters</p>
              <button
                type="button"
                className="btn btn-outline-light btn-sm py-1"
                onClick={() => handleReset()}
              >
                <IconReset />
              </button>
            </div>
            <div
              className="mt-30 px-30 overflow-y-scroll"
              style={{ height: "calc(100vh - 200px)" }}
            >
              <div className="form-group form-row">
                <label className="col-md-5 col-form-label">Port</label>
                <div className="col-md-7">
                  <CustomSelectField
                    isLoading={false}
                    isClearable={true}
                    isSearchable={true}
                    name="portId"
                    options={PORT_CHOICES}
                    control={control}
                    size="small"
                  />
                </div>
              </div>
              <div className="form-group form-row">
                <label className="col-md-5 col-form-label">SSL</label>
                <div className="col-md-7">
                  <CustomSelectField
                    isLoading={false}
                    isClearable={true}
                    isSearchable={true}
                    name="containerOwner"
                    options={containerOwners.map((ct: any) => ({ label: ct.name, value: ct._id }))}
                    control={control}
                    size="small"
                  />
                </div>
              </div>
            </div>
            <div className="bdpopup__footer page-fluid">
              <button type="submit" className="btn btn-primary btn-block">
                Apply
              </button>
            </div>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
};

export default WrongDataReportFilter;
