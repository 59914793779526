import React, { Fragment, useState, useCallback } from "react";
import { Button, InputGroup, FormControl, Row, Col } from "react-bootstrap";

interface CustomInputArrayFieldProps {
  value: string[];
  onChange: (newValues: any) => void;
  placeholder?: string;
  className?: string;
}

export const CustomInputArrayField: React.FC<CustomInputArrayFieldProps> = ({
  value,
  onChange,
  placeholder,
  className,
}) => {
  const [inputValue, setInputValue] = useState("");

  const handleAddValue = useCallback(() => {
    if (inputValue.trim() === "") return;
    onChange([...value, inputValue.trim()]);
    setInputValue("");
  }, [inputValue, value, onChange]);

  const handleRemoveValue = useCallback(
    (index: number) => {
      const newValues = value.filter((_, i) => i !== index);
      onChange(newValues);
    },
    [value, onChange]
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleAddValue();
    }
  };

  return (
    <Fragment>
      <div className={`custom-input-array-field ${className}`}>
        <InputGroup className="mb-3">
          <FormControl
            type="text"
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            placeholder={placeholder || "Enter a value and press Enter"}
          />
          <Button variant="primary" onClick={handleAddValue}>
            Add
          </Button>
        </InputGroup>

        <div className="values-list">
          <Row>
            {value.map((item, index) => (
              <Col xs="auto" key={index} className="d-flex align-items-center mb-2">
                <span className=" bg-primary text-wrap me-2 px-3 py-2">{item}</span>
                <Button
                  variant="outline-danger"
                  size="sm"
                  onClick={() => handleRemoveValue(index)}
                  className="remove-button"
                >
                  &times;
                </Button>
              </Col>
            ))}
          </Row>
        </div>
      </div>
    </Fragment>
  );
};
