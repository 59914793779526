import React, { useState, useEffect, SyntheticEvent } from "react";
import { Modal } from "react-bootstrap";
import { CustomRocket } from "Common/Components/Icons/CustomIcons";
import { yupResolver } from "@hookform/resolvers/yup";
import { InviteScheme } from "./onboardingValidation";
import { InviteValue } from "../onboarding";
import { useForm, SubmitHandler } from "react-hook-form";
import { useAppContext } from "contexts/AppContext";
import CustomCreatableSelect from "Common/Components/Inputs/CustomCreatable";
import { getInvitation } from "../action";
import Toastr from "Common/Components/Toastr";

const options: any[] = [];
interface IProps {
  showModal: any;
  hideModal: any;
  onCancel: any;
  showNextModal: any;
}
const InviteModal = (props: any) => {
  const {
    state: { appStates },
    myDispatch,
  } = useAppContext();
  const { activeStep, handleActive, setShowModal, showModal } = props;

  //React Hooks Form
  const {
    register,
    formState: { errors },
    control,
    handleSubmit,
  } = useForm<InviteValue>({
    mode: "onBlur",
    resolver: yupResolver(InviteScheme),
  });

  //FORM SUBMIT
  const onFormSubmit: SubmitHandler<InviteValue> = async (data) => {
    const email: string[] = data.email.map((em) => em.value);
    await myDispatch(getInvitation(email));
  };

  useEffect(() => {
    if (appStates.message) {
      handleActive(0);
    }
  }, [appStates]);

  return (
    <Modal show={showModal && activeStep === 0} centered animation={false} size="sm">
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <Modal.Header className="py-40 px-30 bg-brand-50 justify-content-center flex-column">
          <CustomRocket />
          <div className="text-center mt-10">
            <div className="font-30 font-weight-500 text-gray-900 pb-1">Welcome!</div>
            <div className="font-12 font-weight-normal text-gray-500">
              Now you can feel the power of TrackOS!
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className="pt-20 px-30 pb-50">
          {appStates.isInviteSuccess && appStates.message && (
            <Toastr show={true} status="success" message={appStates.message} />
          )}
          {!appStates.isInviteSuccess && appStates.errorMessage && (
            <Toastr show={true} status="error" message={appStates.errorMessage} />
          )}
          <React.Fragment>
            <div className="text-center">
              <div className="font-16 font-weight-500 text-gray-900 pb-1">
                Let’s invite your Team!
              </div>
              <div className="font-12 font-weight-normal text-gray-500">
                Please add your team members email addresses below to invite
              </div>
              <div className="font-weight-normal text-gray-500">them to join your account!</div>
            </div>
            <div className="form-wrapper mt-20">
              <div className="form-group mb-1">
                <CustomCreatableSelect
                  label=""
                  options={options}
                  name="email"
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placeholder="Enter email address.."
                  error={Boolean(errors.email)}
                  helper={errors.email ? errors.email : []}
                  control={control}
                />
              </div>
            </div>
            <div className="font-12 font-weight-normal text-gray-400">
              You can add multiple address by Tab.
            </div>
            <div className="font-12 font-weight-normal text-gray-400">
              E.g. email1@gmail.com email2@gmail.com email3@gmail.com
            </div>
          </React.Fragment>
        </Modal.Body>
        <Modal.Footer>
          <div className="">
            <button type="button" className="btn mr-20" onClick={() => handleActive(0)}>
              Do it later
            </button>
            {/* <button
              type="button"
              className="btn btn-outline-primary mr-10"
              onClick={() => setShowModal(false)}
            >
              Do it later
            </button> */}
            <button className="btn btn-primary text-white" type="submit">
              {appStates.loading && (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
              Continue
            </button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
export default InviteModal;
