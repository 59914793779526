export const initialPaymentValues = {
  pLoading: false,
  paymentData: [],
  checkoutData: null,
  subscriptionData: null,
  successMessage: "",
};

export const onboardingReducer = (
  state: any,
  action: {
    type: string;
    payload: any;
  }
) => {
  switch (action.type) {
    case "GET_PAYMENT_PLANS_INPROGRESS": {
      return {
        ...state,
        pLoading: true,
      };
    }
    case "GET_PAYMENT_PLANS_SUCCESS": {
      return {
        ...state,
        pLoading: false,
        paymentData: action.payload.data?.data || [],
      };
    }

    case "GET_PAYMENT_PLANS_REJECTED": {
      return {
        ...state,
        pLoading: false,
        errorMessage: action.payload.errorMessage,
      };
    }

    case "POST_CHECKOUT_INPROGRESS": {
      return {
        ...state,
        pLoading: true,
        errorMessage: null,
      };
    }

    case "POST_CHECKOUT_SUCCESS": {
      return {
        ...state,
        pLoading: false,
        checkoutData: action.payload.data.data.customer,
      };
    }

    case "POST_CHECKOUT_REJECTED": {
      return {
        ...state,
        pLoading: false,
        errorMessage: action.payload.errorMessage,
      };
    }

    case "ADD_SUBSCRIPTION_INPROGRESS": {
      return {
        ...state,
        pLoading: true,
        errorMessage: null,
      };
    }

    case "ADD_SUBSCRIPTION_SUCCESS": {
      const getItem = JSON.parse(localStorage.getItem("loggedUser") || "{}");
      const newItem = {
        ...getItem,
        isPaid: true,
      };
      localStorage.setItem("loggedUser", JSON.stringify(newItem));
      return {
        ...state,
        pLoading: false,
        subscriptionData: action.payload.data.data.subscription,
        successMessage: action.payload.data.message,
      };
    }

    case "ADD_SUBSCRIPTION_REJECTED": {
      return {
        ...state,
        pLoading: false,
        errorMessage: action.payload.errorMessage,
      };
    }

    default:
      return state;
  }
};
