import React, { useState } from "react";

import { IconTimesThin } from "../../../../Common/Icons";
import FilterForm from "./FilterForm";

interface IProps {
  hideFilter: any;
}

const ImportAvailabilityFilterPop = (props: IProps) => {
  const { hideFilter } = props;
  return (
    <React.Fragment>
      <div className="bdpopup-backdrop"></div>
      <div className="bdpopup">
        <div className="bdpopup__content">
          {/* Close Filter */}
          <button
            className="btn btn-none bdpopup__close text-white bg-gray-500 rounded-circle"
            onClick={hideFilter}
          >
            <IconTimesThin className="close-icon" />
          </button>
          {/* Reset FIlter */}
          <FilterForm />
        </div>
      </div>
    </React.Fragment>
  );
};

export default ImportAvailabilityFilterPop;
