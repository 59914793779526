const Stepper = (props: any) => {
  const { activeStep } = props;
  return (
    <div className="d-flex w-100">
      <div
        className={`dot w-10px h-10px wh-10px rounded-circle mr-10 ${
          activeStep == 0 ? "bg-primary" : "bg-gray-100 "
        }`}
      ></div>
      <div
        className={`dot w-10px h-10px wh-10px rounded-circle mr-10 ${
          activeStep === 1 ? "bg-primary" : "bg-gray-100"
        }`}
      ></div>
      <div
        className={`dot w-10px h-10px wh-10px rounded-circle mr-10 ${
          activeStep === 2 ? "bg-primary" : "bg-gray-100"
        }`}
      ></div>
    </div>
  );
};

export default Stepper;
