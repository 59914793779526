import React, { useEffect, useState } from "react";
import {
  IconDual,
  IconPlus,
  IconWarning,
  IconAngleArrowRight,
  IconAngleArrowTop,
  IconCheck,
  IconTimes,
  IconForward,
} from "Common/Components/Icons/Icons";
import { DataTypesI, EmptiesTypes, LaPropsI } from "../location";
import nySslFile from "../nysslFile.json";

const NYEmptyTable = (props: LaPropsI) => {
  const { data, emptyData, shift, portsArray, containerTypes, getEmptyAppointmentSlots } = props;

  // ---------------------------------------------------- STATE MANAGEMENT---------------------------------------------------//
  const [emptyReturnsInfo, setEmptyReturnsInfo] = useState<string[]>(nySslFile.shipping_lines);
  // ---------------------------------------------------- HANDLE FUNCTIONS ---------------------------------------------------//

  // console.log("EMPTYAPPOINTMENT FROM FIREBASE",emptyAppointmentSlotsFromFirebase);

  const showEmptyReturnsInfo = (ssl: string) => {
    if (emptyReturnsInfo.includes(ssl)) {
      const temp = emptyReturnsInfo.filter((fil) => fil !== ssl);
      setEmptyReturnsInfo([...temp]);
    } else {
      const temp = emptyReturnsInfo;
      temp.push(ssl);
      setEmptyReturnsInfo([...temp]);
    }
  };

  const filterEmptyReturn = (empty: any, types: any) => {
    let sslData: any;
    const find = emptyData?.find((fil: EmptiesTypes) => fil?.key === empty?.label);
    sslData = find?.data?.data?.find(
      (ss: DataTypesI) => ss.ssl === data && ss.types === types.label
    );

    return sslData;
  };

  // const checkStatus = ({ shift1, types }: any, port: string) => {
  //   let value;

  //   if (port === "GCT_NY") {
  //     if (shift1 && shift1.includes("GCT")) {
  //       const elements = shift1.replace(/\s|-|_/g, "");
  //       const index = elements.indexOf("T") + 1;
  //       value = elements.slice(0, index) + "_" + elements.slice(index);
  //     } else {
  //       value = shift1.replace(/-|\s/, "_").toUpperCase();
  //     }
  //   } else {
  //     value = shift1.replace("-", " ").toUpperCase();
  //   }

  //   if (sslFile.shipping_lines.includes(value)) {
  //     return "WARNING";
  //   } else if (value.includes("UNRESTRICTED")) {
  //     return "YES";
  //   } else if (value.includes("NO") || value.includes("CLOSED") || value.includes("RESTRICTED")) {
  //     return "NO";
  //   } else if (
  //     (port === "PNCT" || port === "MAHER_TERMINAL" || port === "GCT_NY") &&
  //     value !== "" &&
  //     staticPort.filter((fil) => fil !== port).includes(value.toUpperCase())
  //   ) {
  //     // const filter = filterEmptyReturn(
  //     //   { label: value, checked: true },
  //     //   { label: types, checked: true }
  //     // );
  //     // if (
  //     //   filter?.shift1.toUpperCase().includes("NO") ||
  //     //   filter?.shift1.toUpperCase().includes("RESTRICTED") ||
  //     //   filter?.shift1.toUpperCase().includes("CLOSED")
  //     // ) {
  //     //   return "NO";
  //     // }
  //     return "FORWARD";
  //   } else if (value.includes(port.toUpperCase())) {
  //     return "YES";
  //   } else if (value.includes("DUAL")) {
  //     return "DUAL";
  //   } else if (value === "+") {
  //     return "+";
  //   } else if (value.includes("YES") || value.includes("OPEN")) {
  //     return "YES";
  //   } else {
  //     return "  ";
  //   }
  // };

  // const EmptiesIcon = (text: string) => {
  //   switch (text) {
  //     case "DUAL":
  //       return <IconDual className="wh-10px text-warning" />;
  //     case "YES":
  //       return <IconCheck className="text-primary wh-20px" />;
  //     case "+":
  //       return <IconPlus className="text-primary" />;
  //     case "WARNING":
  //       return null;
  //     case "NO":
  //       return <IconTimes className="text-error-500 wh-20px" />;
  //     case "FORWARD":
  //       return <IconForward className="text-success" />;
  //     default:
  //       break;
  //   }
  // };

  const EmptiesIcon = (text: string) => {
    switch (text) {
      case "OPEN":
        return "success";
      case "RESTRICTED":
        return "danger";
      case "CLOSED":
        return "danger";
      case "CONTACT SSL":
        return "secondary";
      default:
        break;
    }
  };

  return (
    <>
      <tbody className="mb-4">
        <tr>
          <td colSpan={100}>
            <div className="d-flex align-items-center">
              <span className="font-14 text-dark font-weight-500">{data}</span>
              <button className="btn btn-link" onClick={() => showEmptyReturnsInfo(data)}>
                {emptyReturnsInfo.includes(data) ? <IconAngleArrowTop /> : <IconAngleArrowRight />}
              </button>
            </div>
          </td>
        </tr>
        {emptyReturnsInfo.includes(data) && (
          <>
            {containerTypes.map((types: any, index: number) => {
              return (
                <tr key={index}>
                  {types.checked && (
                    <React.Fragment>
                      <td width="100"> {types.label} </td>
                      {portsArray.map((pp, pIndex) => {
                        if (pp.checked) {
                          const sslData = filterEmptyReturn(pp, types);
                          const totalAvailableAppointmentSlots =
                            sslData &&
                            ["OPEN", "DUAL"].includes(sslData[shift]) &&
                            getEmptyAppointmentSlots
                              ? getEmptyAppointmentSlots(sslData)
                              : null;
                          return sslData && sslData.hasOwnProperty("shift1") ? (
                            <td key={pIndex} className="text-center">
                              <div className="d-flex flex-column align-items-center justify-content-center">
                                <span className={`text-${EmptiesIcon(sslData.shift1)}`}>
                                  {sslData.shift1}
                                </span>
                                {["OPEN", "DUAL"].includes(sslData[shift]) &&
                                totalAvailableAppointmentSlots ? (
                                  <span className="text-center text-secondary">
                                    {totalAvailableAppointmentSlots}
                                  </span>
                                ) : null}
                                <div className="position-relative tooltip-label">
                                  {sslData.remarks && (
                                    <IconWarning className="ml-1 text-primary wh-10px text-warning" />
                                  )}
                                  {sslData.remarks ? (
                                    <div
                                      className="tooltip tooltip--gray-700 mw-200"
                                      data-placement="top-center"
                                    >
                                      {sslData.remarks}
                                      <span className="arrow"></span>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </td>
                          ) : (
                            <td key={pIndex} className="text-center"></td>
                          );
                        }
                      })}
                    </React.Fragment>
                  )}
                </tr>
              );
            })}
            <tr>
              <td width="100">Remarks</td>
              {portsArray.map((pp, pIndex) => {
                if (pp.checked) {
                  const sslData = filterEmptyReturn(pp, { label: "20 ST", checked: true });
                  return sslData && sslData?.moreRemarks ? (
                    <td key={pIndex} className="text-center">
                      <div className="position-relative tooltip-label">
                        <IconWarning className="text-primary wh-10px text-warning" />
                        <div
                          className="tooltip tooltip--gray-700 mw-300"
                          data-placement="top-center"
                        >
                          {sslData?.moreRemarks}
                          <span className="arrow"></span>
                        </div>
                      </div>
                    </td>
                  ) : (
                    <td key={pIndex} className="text-center"></td>
                  );
                }
              })}
            </tr>
          </>
        )}
      </tbody>
    </>
  );
};
export default NYEmptyTable;
