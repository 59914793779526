import { IconFilter } from "Common/Icons";
import { useAppContext } from "contexts/AppContext";
import _ from "lodash";
import { getAllUsers, setSuperAdminsParams } from "pages/Dashboard/action";
import { useCallback, useEffect, useState } from "react";

const SearchFilter = () => {
  const { state, myDispatch } = useAppContext();
  const { superAdminCriteria, searchParams } = state.dashboardStates;
  const [superAdminEmail, setSuperAdminEmail] = useState<string>("");

  // On Search
  const onChangeInput = (event: any) => {
    let val = event.target.value;
    setSuperAdminEmail(val?.trim());
  };
  const debouncedChangeHandler = useCallback(_.debounce(onChangeInput, 1000), []);

  useEffect(() => {
    let searchValue = { ...superAdminCriteria, email: superAdminEmail };
    myDispatch(setSuperAdminsParams(searchValue, searchParams));
    myDispatch(getAllUsers(searchValue, searchParams));
  }, [superAdminEmail]);

  return (
    <div className="filter-row  mt-20 d-flex align-items-center">
      {/* Search */}
      <div className="app-search header-search">
        <span className="search-icon"> </span>
        <div className="position-relative">
          <input
            type="search"
            className="form-control input-search-left"
            placeholder="Search..."
            defaultValue={superAdminEmail}
            onChange={debouncedChangeHandler}
          />
        </div>
      </div>
    </div>
  );
};

export default SearchFilter;
