import { isLoggedIn } from "utils";
export const initialConfigValues = {
  loading: false,
  errorMessage: null,
  terminals: [],
  ports: [],
  containerTypes: [],
  containerSizes: [],
  containerOwners: [],
  countData: [],
  noCredentialPorts: [],
  assignLoading: false,
  assignPortErMessage: false,
  portCredential: [],
  maherError: null,
  maherLoginSuccess: false,
  maherResponse: {},
};

export const configDataReducer = (
  state: any,
  action: {
    type: string;
    payload: any;
    value: any;
  }
) => {
  switch (action.type) {
    case "GETTERMINALLIST_SUCCESS": {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        terminals: action.payload.data.data,
      };
    }
    case "GETPORTLIST_SUCCESS": {
      let ports = action.payload.data.data;
      return {
        ...state,
        loading: false,
        errPortMessage: null,
        ports: ports,
        noCredentialPorts: ports,
      };
    }
    case "GETADDEDPORT_LIST_INPROGRESS": {
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    }
    case "GETADDEDPORT_LIST_SUCCESS": {
      let ports = action.payload.data.data;
      let allPorts = state.ports.map((port: any) => {
        let portFound = ports.find((p: any) => p.portId === (port.portId ? port.portId : port._id));
        if (portFound) {
          return { ...port, ...portFound };
        }
        return port;
      });
      return {
        ...state,
        loading: false,
        errorMessage: null,
        ports: allPorts,
      };
    }
    case "GETADDEDPORT_LIST_REJECTED": {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.errorMessage,
      };
    }
    case "PORT_CREDENTIAL_INPROGRESS": {
      return {
        ...state,
        assignLoading: true,
        assignPortId: action.value,
        assignPortErMessage: null,
      };
    }

    case "PORT_CREDENTIAL_SUCCESS": {
      let res = action.payload.data.customerCredential;
      let allPorts = state.ports.map((port: any) => {
        if (port.portId === res.portId) {
          port.portUsername = res.portUsername;
          port.portPassword = res.portPassword;
        }
        return port;
      });
      // localStorage.setItem("loggedUser", JSON.stringify(user));
      return {
        ...state,
        assignLoading: false,
        assignPortId: action.value,
        assignPortErMessage: null,
        ports: allPorts,
      };
    }
    case "PORT_CREDENTIAL_REJECTED": {
      return {
        ...state,
        assignLoading: false,
        assignPortErMessage: action.payload.errorMessage,
        assignPortId: action.value,
      };
    }
    case "GETCONTAINERSIZE_LIST_SUCCESS": {
      return {
        ...state,
        containerSizes: action.payload.data.data,
      };
    }
    case "GETCONTAINERTYPE_LIST_SUCCESS": {
      return {
        ...state,
        containerTypes: action.payload.data.data,
      };
    }
    case "GETCONTAINEROWNER_LIST_SUCCESS": {
      return {
        ...state,
        containerOwners: action.payload.data.data,
      };
    }

    case "COUNTSTATUS_INPROGRESS": {
      return {
        ...state,
        loading: true,
      };
    }
    case "COUNTSTATUS_SUCCESS": {
      return {
        ...state,
        loading: false,
        countData: action.payload.data.count,
      };
    }
    case "GETTOKEN_LIST_INPROGRESS": {
      return {
        ...state,
        loading: true,
      };
    }
    case "GETTOKEN_LIST_SUCCESS": {
      let tokens = action.payload.data.data;
      // console.log(tokens, "token update");
      return {
        ...state,
        loading: false,
        tokens: tokens,
      };
    }
    case "GETTOKEN_LIST_REJECTED": {
      return {
        ...state,
        loading: false,
      };
    }
    case "UPDATETOKEN_LIST_SUCCESS": {
      let updatetokens = action.payload.data.data;
      return {
        ...state,
        loading: false,
        updatetokens: updatetokens,
      };
    }
    case "LOGIN_MAHER_USER_SUCCESS": {
      let maherErrorMsg = action.payload.data.maherError;
      return {
        ...state,
        maherResponse: action.payload.data,
        maherError: maherErrorMsg,
        maherLoginSuccess: true,
      };
    }
    case "LOGIN_MAHER_USER_INPROGRESS": {
      return {
        ...state,
        maherError: null,
        maherLoginSuccess: false,
      };
    }
    case "LOGIN_MAHER_USER_REJECTED": {
      return {
        ...state,
        maherError: null,
        maherLoginSuccess: false,
      };
    }

    case "VALIDATE_PORT_CREDENTIAL_INPROGRESS": {
      return {
        ...state,
        assignLoading: true,
        assignPortId: action.value,
        assignPortErMessage: null,
      };
    }

    case "VALIDATE_PORT_CREDENTIAL_SUCCESS": {
      let res = action.payload.data.data;
      let allPorts = state.ports.map((port: any) => {
        for (let i = 0; i < res.length; i++) {
          if (port.portId === res[i].value.portId) {
            port.portUsername = res[i].value.portUsername;
            port.portPassword = res[i].value.portPassword;
            port.auth_failed = res[i].value.auth_failed;
            port.failedMessage = res[i].value.failedMessage;
          }
        }
        return port;
      });
      // localStorage.setItem("loggedUser", JSON.stringify(user));
      return {
        ...state,
        assignLoading: false,
        assignPortId: action.value,
        assignPortErMessage: null,
        ports: allPorts,
      };
    }

    case "VALIDATE_PORT_CREDENTIAL_REJECTED": {
      return {
        ...state,
        assignLoading: false,
        assignPortErMessage: action.payload.data.errorMessage,
        assignPortId: action.value,
      };
    }

    default:
      return state;
  }
};
