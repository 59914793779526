import {
  IconAngleArrowDown,
  IconAngleArrowRight,
  IconBillingCheck,
  IconCalendar,
  IconFile,
  IconFileCheck,
  IconPlus,
  IconUsers,
} from "Common/Icons";
import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";

const cardItems = [
  {
    id: 1,
    title: "Container Info",
    icon: <IconFile className="text-white" />,
    path: "/dashboard/containers",
  },
  // {
  //   id: 2,
  //   title: "Create Super Admin",
  //   icon: <IconPlus className="text-white" />,
  //   path: "/dashboard/Super_Admin",
  // },

  // {
  //   id: 3,
  //   title: "User Info",
  //   icon: <IconUsers className="text-white" />,
  //   path: "/dashboard/User_Info",
  // },
];
const getLastItem = (thePath: string) => thePath.substring(thePath.lastIndexOf("/") + 1);

const DashboardCard = () => {
  return (
    <div className="form-row hasactive">
      {cardItems.map((cardItem, i) => (
        <div className="col-md my-2" key={i}>
          <NavLink
            to={cardItem.path}
            // className={`ncard d-flex flex-column justify-content-between p-10 ${
            //   isCardActive === cardItem.id ? "active" : ""
            // }`}
            className={({ isActive }) =>
              isActive || cardItem.path?.includes(getLastItem(window.location.pathname))
                ? "ncard d-flex flex-column justify-content-between p-10 active"
                : "ncard d-flex flex-column justify-content-between p-10"
            }
          >
            <div className="d-flex align-items-center ">
              <div className="bg-primary p-2  d-flex  justify-content-center  align-items-center wh-30px rounded-10">
                {cardItem.icon}
              </div>
              <div className="font-normal font-14 ml-2 font-weight-500">{cardItem.title}</div>

              <div className="ml-auto">
                <IconAngleArrowRight />
              </div>
              {/* <div className="font-12 font-weight-500 mt-2 mb-2">{cardItem.title}</div> */}
            </div>

            {/* <div className="font-medium font-20 mt-10">${cardItem.price}</div> */}
          </NavLink>
        </div>
      ))}
    </div>
  );
};

export default DashboardCard;
