import DashboardFilterPop from "./filter";
import { IconAngleArrowLeft, IconAngleArrowRight, IconFilter, IconReset } from "Common/Icons";
import React, { useState } from "react";
import { fetchGateHours } from "../action";
import { useAppContext } from "contexts/AppContext";
import moment from "moment";
import { terminalNameConstant } from "../constant";

const SearchFilter = (props: any) => {
  const { todayDate, setTodayDate } = props;
  const [showFilter, setShowFilter] = useState(false);
  const { state, myDispatch } = useAppContext();
  const { userSelectedTerminals } = state.appStates;

  const handleShowFilter = () => {
    setShowFilter(!showFilter);
  };

  const handleRefresh = async () => {
    const terminalCode = userSelectedTerminals?.[0]?.code;
    if (terminalCode) {
      const response = fetchGateHours({ terminal: terminalNameConstant[terminalCode] });
      myDispatch(response);
    }
    setTodayDate(moment().format("lll"));
  };

  const handleWeekChange = async (val: string) => {
    let dateNow = todayDate;
    if (val === "prev") dateNow = moment(todayDate).subtract(6, "days").format("lll");
    if (val === "next") dateNow = moment(todayDate).add(6, "days").format("lll");
    setTodayDate(dateNow);
    const terminalCode = userSelectedTerminals?.[0]?.code;
    if (terminalCode) {
      let data = {
        date: moment(dateNow).toISOString(),
        terminal: terminalNameConstant[terminalCode],
      };
      const response = fetchGateHours(data);
      myDispatch(response);
    }
  };

  return (
    <React.Fragment>
      <div className="filter-row d-flex align-items-center justify-content-center">
        <div className="app-search header-search">
          <button
            className="btn btn-outline-light mr-2"
            onClick={() => {
              handleWeekChange("prev");
            }}
          >
            <IconAngleArrowLeft className="mr-2" />
            Prev Week
          </button>
          <button
            className="btn btn-outline-light mr-2"
            onClick={() => {
              handleWeekChange("next");
            }}
          >
            <IconAngleArrowRight className="mr-2" />
            Next Week
          </button>
        </div>

        {/* Filter */}
        <div className="ml-auto d-flex">
          <button
            className="btn btn-outline-light mr-2"
            onClick={() => {
              handleRefresh();
            }}
          >
            <IconReset className="mr-2" />
            Refresh
          </button>
          <button className="btn btn-outline-light" onClick={() => setShowFilter(!showFilter)}>
            <IconFilter className="mr-2" />
            Filter
          </button>
          {/* <div className=""></div> */}
        </div>
        {showFilter && <DashboardFilterPop hideFilter={() => setShowFilter(false)} />}
      </div>
    </React.Fragment>
  );
};

export default SearchFilter;
