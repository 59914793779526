import moment from "moment";

export const gateIcon = (text: string) => {
  const result = text.replaceAll(/\u200B/g, "");
  switch (result) {
    case "OPEN":
      return "success";
    case "CLOSED":
      return "danger";
    case "PASHA ONLY":
      return "success";
    default:
      break;
  }
};

export const getCurrentWeek = (date?: string) => {
  const days = [];
  let weekStart = moment().startOf("isoWeek");
  if (date) {
    weekStart = moment(date).startOf("isoWeek");
  }

  for (let i = 0; i <= 6; i++) {
    days.push(moment(weekStart).add(i, "days"));
  }
  return days;
};

export const getWeekData = (date: string) => {
  const weekDateArr = getCurrentWeek(date);
  const weekData = weekDateArr?.map((wD: any) => {
    return {
      date: wD.format("LL"),
      day: wD.format("dddd"),
    };
  });
  return weekData;
};
