import React from "react";
import { Link } from "react-router-dom";

const NoMatch = () => {
  return (
    <div className="page-wrapper">
      <div className="position-relative subheader-wrapper">
        <div className="page-fluid">
          <div className="card">
            <div className="card-body">
              <h2>It looks like you're lost...</h2>
              <p>
                <Link to="/">Go to the home page</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoMatch;
