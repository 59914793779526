import Papa from "papaparse";
import { useAppContext } from "contexts/AppContext";
import { getContainerStatusReport } from "../action";
import { useEffect } from "react";

declare global {
  interface Navigator {
    msSaveBlob?: (blob: any, defaultName?: string) => boolean;
  }
}
const DownloadCompareStatus = (props: any) => {
  const { state, myDispatch } = useAppContext();

  const { statusLoading, ReportContainerStatus } = state.reportDRStates;

  console.log(ReportContainerStatus, "csv list data");

  const onDownloadStatus = () => {
    myDispatch(getContainerStatusReport(props.carrier));
  };

  useEffect(() => {
    ReportContainerStatus?.length > 0 && onDownload();
  }, [ReportContainerStatus]);

  const onDownload = () => {
    let csvval = convertToCsv(ReportContainerStatus);
    console.log("downloading...");
    let csv = Papa.unparse(csvval);
    let csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    let csvURL = null;
    if (navigator.msSaveBlob) {
      csvURL = navigator.msSaveBlob(csvData, `syncedLoads_${new Date().getTime()}.csv`);
    } else {
      csvURL = window.URL.createObjectURL(csvData);
    }
    let tempLink: any = document.createElement("a");
    tempLink.href = csvURL;
    tempLink.setAttribute("download", `syncedLoads_${new Date().getTime()}.csv`);
    tempLink.click();
  };

  return (
    <>
      <button
        className="btn btn-primary ml-10"
        onClick={() => onDownloadStatus()}
        disabled={statusLoading}
      >
        {statusLoading && (
          <span
            className="spinner-border spinner-border-sm mr-2"
            role="status"
            aria-hidden="true"
          ></span>
        )}
        Download Status Report
      </button>
    </>
  );
};

export default DownloadCompareStatus;

const convertToCsv = (values: any) => {
  let csvalues = [];
  csvalues = values.map((val: any) => {
    let csvVal: any = {};
    csvVal = { ...val };
    csvVal.drayosStatus = val.drayosStatus;
    csvVal.trackosStatus = val.trackosStatus;
    return csvVal;
  });
  return csvalues;
};
