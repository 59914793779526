import React, { Fragment, useCallback, useEffect, useState } from "react";
import CustomSelectField, {
  SelectFieldWithoutControl,
} from "Common/Components/Inputs/CustomSelectField";
import { useAppContext } from "contexts/AppContext";
import _ from "lodash";
import Datetime from "react-datetime";
import { setSuccessfulContainersParams } from "../../SuccessfulDataReport/action";
import { getDuplicateArrayofObjects } from "pages/EmptyReturn/Common/utils";
import { IconCalendar } from "Common/Icons";
import moment from "moment";

const SearchFilter = (props: any) => {
  const { state, myDispatch } = useAppContext();
  const { ports, containerOwners, containerSizes, containerTypes, terminals } = state.configStates;
  const { searchPost, sLoading, drLoading } = state.reportDRStates;

  let portsOpts = ports.map((opt: any) => ({
    label: opt.name,
    value: opt.name,
    terminal: opt.terminalCode,
  }));
  let sizeOpts = containerSizes.map((opt: any) => ({ label: opt.name, value: opt.name }));
  let typeOpts = containerTypes.map((opt: any) => ({ label: opt.name, value: opt.name }));
  let sslOpts = containerOwners.map((opt: any) => ({ label: opt.name, value: opt.name }));
  let terminalOpts = terminals.map((opt: any) => ({ label: opt.name, value: opt.code }));

  const [showAlert, setShowAlert] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<any>(props.params);

  const handleOptValChange = (val: any, key: string) => {
    // console.log("val key",val, key);
    let parameters = { ...selectedOptions };
    parameters[key] = key == "date" ? val : val ? val.value : null;
    setSelectedOptions({ ...parameters });
  };

  const onSearch = (isReturn: boolean) => {
    if (!selectedOptions.port) {
      setShowAlert(true);
    } else {
      setShowAlert(false);
      console.log("selected options", selectedOptions);
      props.setParams(selectedOptions);
      // setSelectedOptions(props.params);
      //   myDispatch(getDrayosSummary(props.params, isReturn));
    }
  };

  return (
    <Fragment>
      <div className="filter-row d-flex align-items-center">
        {/* Search */}
        {/* Date */}
        <div className="input-wrapper w-200 mr-2">
          <Datetime
            inputProps={{
              placeholder: "Select date..",
            }}
            onChange={(value) => handleOptValChange(moment(value).toISOString(), "date")}
            closeOnSelect
            value={moment(selectedOptions.date)}
          />
          <div className="input-icon">
            <IconCalendar />
          </div>
        </div>
        {/* Terminal  */}
        <SelectFieldWithoutControl
          isLoading={false}
          isClearable={true}
          isSearchable={true}
          name="terminal"
          options={terminalOpts}
          onChange={(e: any) => {
            handleOptValChange(e, "terminal");
          }}
          size="small"
          className="min-200 mr-2"
          placeholder="Select terminal"
          value={
            selectedOptions.terminal &&
            terminalOpts.find((opt: any) => opt.value == selectedOptions.terminal)
          }
        />
        {/* ports  */}
        <SelectFieldWithoutControl
          isLoading={false}
          isClearable={true}
          isSearchable={true}
          name="port"
          options={portsOpts.filter((opt: any) => opt.terminal == selectedOptions.terminal)}
          onChange={(e: any) => handleOptValChange(e, "port")}
          size="small"
          className="min-200 mr-2"
          placeholder="Select Port"
          value={
            selectedOptions.port && portsOpts.find((opt: any) => opt.value == selectedOptions.port)
          }
        />
        {/* ssl  */}
        <SelectFieldWithoutControl
          isLoading={false}
          isClearable={true}
          isSearchable={true}
          name="ssl"
          options={sslOpts}
          onChange={(e: any) => handleOptValChange(e, "ssl")}
          size="small"
          className="min-200 mr-2"
          placeholder="Select SSL"
          value={
            selectedOptions.ssl && sslOpts.find((opt: any) => opt.value == selectedOptions.ssl)
          }
        />

        {/* sizeOpts  */}
        <SelectFieldWithoutControl
          isLoading={false}
          isClearable={true}
          isSearchable={true}
          name="size"
          options={sizeOpts}
          onChange={(e: any) => handleOptValChange(e, "size")}
          size="small"
          className="min-200 mr-2"
          placeholder="Select Size"
          value={
            selectedOptions.size && sizeOpts.find((opt: any) => opt.value == selectedOptions.size)
          }
        />

        {/* type  */}
        <SelectFieldWithoutControl
          isLoading={false}
          isClearable={true}
          isSearchable={true}
          name="type"
          options={typeOpts}
          onChange={(e: any) => handleOptValChange(e, "type")}
          size="small"
          className="min-200 mr-2"
          placeholder="Select Type"
          value={
            selectedOptions.type && typeOpts.find((opt: any) => opt.value == selectedOptions.type)
          }
        />

        <button
          className="btn btn-success ml-2"
          onClick={() => onSearch(false)}
          disabled={sLoading}
        >
          {drLoading && (
            <span
              className="spinner-border spinner-border-sm mr-2"
              role="status"
              aria-hidden="true"
            ></span>
          )}
          Generate Report
        </button>
      </div>
      <div className="filter-row d-flex align-items-center">
        {showAlert && (
          <div className="col alert alert-warning" role="alert">
            Please Select Port
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default SearchFilter;
