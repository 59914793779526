import React, { Fragment, useCallback, useEffect, useState } from "react";
import { SelectFieldWithoutControl } from "Common/Components/Inputs/CustomSelectField";
import { useAppContext } from "contexts/AppContext";
import _ from "lodash";
import Datetime from "react-datetime";
import { IconCalendar } from "Common/Icons";
import { LOAD_TYPE, MOVE_TYPE, FULL_EMPTY } from "../constant";
import { Form } from "react-bootstrap";
import { resetAppointmentReportParams } from "../action";

const SearchFilter = (props: any) => {
  const { state, myDispatch } = useAppContext();
  const { ports } = state.configStates;
  const { apptEnabledCarrier, apptReportLoading } = state.reportDRStates;

  let portsOpts = ports.map((opt: any) => ({
    label: opt.name,
    value: opt.name,
    terminal: opt.terminalCode,
  }));

  let apptStatusOpts = apptEnabledCarrier?.APPT_STATUS?.map((val: string) => ({
    label: val,
    value: val,
  }));

  let carrierOpts = apptEnabledCarrier?.CARRIERS?.map((opt: any) => ({
    label: opt.email,
    value: opt.carrier,
  }));

  let loadStatusOpts = apptEnabledCarrier?.LOAD_STATUS?.map((opt: any) => ({
    label: opt,
    value: opt,
  }));

  let moveTypeOpts = MOVE_TYPE.map((opt: any) => ({
    label: opt,
    value: opt,
  }));
  let loadTypeOpts = LOAD_TYPE.map((opt: any) => ({
    label: opt,
    value: opt,
  }));
  let fullEmptyOpts = FULL_EMPTY.map((opt: any) => ({
    label: opt,
    value: opt,
  }));

  const [selectedOptions, setSelectedOptions] = useState<any>(props.params);

  const handleOptValChange = (val: any, key: string) => {
    let parameters = { ...selectedOptions };
    parameters[key] = val;
    setSelectedOptions({ ...parameters });
  };

  const handleInputValChange = (event: any, key: string) => {
    const parameters = { ...selectedOptions };
    parameters[key] = event.target.value.trim();
    setSelectedOptions({ ...parameters });
  };

  const onSearch = () => {
    props.setParams(selectedOptions);
    myDispatch(resetAppointmentReportParams());
  };

  return (
    <Fragment>
      <div className="filter-row">
        <div className="d-flex align-items-center">
          <SelectFieldWithoutControl
            isLoading={false}
            isClearable={true}
            isSearchable={true}
            name="CARRIER"
            options={carrierOpts}
            onChange={(e: any) => {
              handleOptValChange(e, "CARRIER");
            }}
            size="small"
            className="min-200 mr-2"
            placeholder="Select CARRIER"
            value={
              selectedOptions.CARRIER &&
              carrierOpts.find((opt: any) => opt.value === selectedOptions.CARRIER)
            }
          />
          <SelectFieldWithoutControl
            isLoading={false}
            isClearable={true}
            isSearchable={true}
            name="PORT"
            options={portsOpts}
            onChange={(e: any) => {
              handleOptValChange(e, "PORT");
            }}
            size="small"
            className="min-200 mr-2"
            placeholder="Select PORT"
            value={
              selectedOptions.PORT &&
              loadStatusOpts.find((opt: any) => opt.value === selectedOptions.PORT)
            }
          />
          <SelectFieldWithoutControl
            isLoading={false}
            isClearable={true}
            isSearchable={true}
            name="LOAD_STATUS"
            options={loadStatusOpts}
            onChange={(e: any) => {
              handleOptValChange(e, "LOAD_STATUS");
            }}
            size="small"
            className="min-200 mr-2"
            placeholder="Select LOAD_STATUS"
            value={
              selectedOptions.LOAD_STATUS &&
              loadStatusOpts.find((opt: any) => opt.value === selectedOptions.LOAD_STATUS)
            }
          />
          <SelectFieldWithoutControl
            isLoading={false}
            isClearable={true}
            isSearchable={true}
            name="APPT_STATUS"
            options={apptStatusOpts}
            onChange={(e: any) => {
              handleOptValChange(e, "APPT_STATUS");
            }}
            size="small"
            className="min-200 mr-2"
            placeholder="Select APPT_STATUS"
            value={
              selectedOptions.APPT_STATUS &&
              apptStatusOpts.find((opt: any) => opt.value === selectedOptions.APPT_STATUS)
            }
          />
          <SelectFieldWithoutControl
            isLoading={false}
            isClearable={true}
            isSearchable={true}
            name="LOAD_TYPE"
            options={loadTypeOpts}
            onChange={(e: any) => {
              handleOptValChange(e, "LOAD_TYPE");
            }}
            size="small"
            className="min-200 mr-2"
            placeholder="Select LOAD_TYPE"
            value={
              selectedOptions.LOAD_TYPE &&
              loadTypeOpts.find((opt: any) => opt.value === selectedOptions.LOAD_TYPE)
            }
          />

          <SelectFieldWithoutControl
            isLoading={false}
            isClearable={true}
            isSearchable={true}
            name="MOVE_TYPE"
            options={moveTypeOpts}
            onChange={(e: any) => {
              handleOptValChange(e, "MOVE_TYPE");
            }}
            size="small"
            className="min-200 mr-2"
            placeholder="Select MOVE_TYPE"
            value={
              selectedOptions.MOVE_TYPE &&
              moveTypeOpts.find((opt: any) => opt.value === selectedOptions.MOVE_TYPE)
            }
          />

          <SelectFieldWithoutControl
            isLoading={false}
            isClearable={true}
            isSearchable={true}
            name="FULL_EMPTY"
            options={fullEmptyOpts}
            onChange={(e: any) => {
              handleOptValChange(e, "FULL_EMPTY");
            }}
            size="small"
            className="min-200 mr-2"
            placeholder="Select FULL_EMPTY"
            value={
              selectedOptions.FULL_EMPTY &&
              fullEmptyOpts.find((opt: any) => opt.value === selectedOptions.FULL_EMPTY)
            }
          />
        </div>

        <div className="d-flex align-items-center mt-4">
          <div className="d-flex align-items-center mx-2 w-75">
            <Form.Control
              type="text"
              className="mr-2 w-25"
              placeholder="Insert CONTAINER"
              onChange={(e: any) => {
                handleInputValChange(e, "CONTAINER");
              }}
            />

            <Form.Control
              type="text"
              className="w-25"
              placeholder="Insert LOAD"
              onChange={(e: any) => {
                handleInputValChange(e, "LOAD");
              }}
            />

            <Form.Control
              type="text"
              className="ml-2 w-50"
              placeholder="Insert APPT_ID"
              onChange={(e: any) => {
                handleInputValChange(e, "APPT_ID");
              }}
            />
          </div>
          {/* <SelectFieldWithoutControl
            isLoading={false}
            isClearable={true}
            isSearchable={true}
            name="CONTAINER"
            options={containerOpts}
            onChange={(e: any) => {
              handleOptValChange(e, "CONTAINER");
            }}
            size="small"
            className="min-200 mr-2"
            placeholder="Select CONTAINER"
            value={
              selectedOptions.CONTAINER &&
              containerOpts.find((opt: any) => opt.value === selectedOptions.CONTAINER)
            }
          /> */}
          {/* <SelectFieldWithoutControl
            isLoading={false}
            isClearable={true}
            isSearchable={true}
            name="LOAD"
            options={loadOpts}
            onChange={(e: any) => {
              handleOptValChange(e, "LOAD");
            }}
            size="small"
            className="min-200 mr-2"
            placeholder="Select LOAD"
            value={
              selectedOptions.LOAD &&
              loadOpts.find((opt: any) => opt.value === selectedOptions.LOAD)
            }
          /> */}
          <button className="btn btn-success ml-2" onClick={() => onSearch()}>
            {/* {apptReportLoading && (
              <span
                className="spinner-border spinner-border-sm mr-2"
                role="status"
                aria-hidden="true"
              ></span>
            )} */}
            Generate Report
          </button>
        </div>
      </div>
    </Fragment>
  );
};

export default SearchFilter;
