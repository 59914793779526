import Loader from "Common/Components/Loader";
import { caseStatus } from "Common/Components/StatusBadge";
import { useAppContext } from "contexts/AppContext";
import moment from "moment";
import { handleSort } from "pages/EmptyReturn/Common/utils";
import { useState } from "react";
import { getDetailedValue } from "utils/commonFunctions";
import { setProblemContainersParams } from "../action";
import { tableHeaders } from "../constant";

const ProblemContainerTable = () => {
  const { state, myDispatch } = useAppContext();
  const { loggedUser } = state.appStates;
  const { problemContainers, pErrorMessage, pLoading, searchParams, searchPost } =
    state.problemContainerStates;
  const { containerSizes, containerOwners, containerTypes, ports } = state.configStates;
  const { page, limit } = searchParams;

  const [headers, setTableHeader] = useState<any | null>(tableHeaders);

  // get size and type
  const getSizeType = (containerSize: any, containerType: any) => {
    let sizeVal = getDetailedValue(containerSize, containerSizes);

    let typeVal = getDetailedValue(containerType, containerTypes);
    return `${sizeVal} ${sizeVal && typeVal && "-"} ${typeVal}`;
  };
  // get port
  const getPortTerminalName = (val: any, vtype: "port" | "terminal") => {
    if (typeof val === "string") {
      return getDetailedValue(val, vtype === "port" ? ports : loggedUser?.terminal);
    } else {
      return val?.name;
    }
  };
  // get Empty Return
  const getEmptyReturn = (vals: any) => {
    let emptyReturn: string[] = [];
    vals.length > 0 &&
      vals.map((val: any) => {
        if (typeof val === "object") {
          emptyReturn.push(val.port);
        } else {
          let portname = getPortTerminalName(val, "port");
          if (portname) emptyReturn.push(portname);
        }
      });
    return emptyReturn;
  };

  // Sorting
  const onSort = (obj: any) => {
    const searchParamsD = handleSort(obj, headers, searchParams);
    myDispatch(setProblemContainersParams(searchPost, searchParamsD));
  };

  return (
    <div className="table-responsive" style={{ height: "calc(100vh - 268px)" }}>
      {pLoading && <Loader />}
      <table className="table table-card">
        <thead>
          <tr>
            <th scope="col" className="">
              Sno
            </th>
            {headers.map((item: any, i: number) => {
              let sortIcon = "table-sort table-sort--default";
              if (item.sort === "-" && item.active) {
                sortIcon = "table-sort table-sort--ascending";
              }
              if (item.sort === "+" && item.active) {
                sortIcon = "table-sort table-sort--descending";
              }
              return (
                <th key={i} onClick={() => onSort(item)} className={item.sort ? sortIcon : ""}>
                  {item.label}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {problemContainers && problemContainers.length ? (
            problemContainers.map((problemContainer: any, i: number) => {
              return (
                <tr key={i}>
                  <td className="text-center">{(page - 1) * limit + i + 1}</td>
                  <td>{caseStatus(problemContainer.status)}</td>
                  <td>
                    {problemContainer.updatedAt
                      ? moment(problemContainer.updatedAt).format("lll")
                      : ""}
                  </td>
                  <td>{problemContainer.message}</td>
                  <td>{problemContainer.containerNo}</td>
                  <td>
                    {problemContainer.custom && <p>{"Custom: " + problemContainer.custom}</p>}
                    {problemContainer.freight && <p>{"Freight: " + problemContainer.freight}</p>}
                    {problemContainer.brokerHold && (
                      <p>{"Broker: " + problemContainer.brokerHold}</p>
                    )}
                  </td>
                  <td>{getDetailedValue(problemContainer.containerOwner, containerOwners)}</td>
                  <td>
                    {getSizeType(problemContainer.containerSize, problemContainer.containerType)}
                  </td>
                  <td>{getPortTerminalName(problemContainer.portId, "port")}</td>
                  <td>
                    {problemContainer.eta
                      ? moment(problemContainer.eta).format("MM/DD")
                      : problemContainer.eta
                      ? moment(problemContainer.sslEta).format("MM/DD")
                      : ""}
                  </td>
                  <td>
                    {problemContainer.status !== "On Vessel" &&
                      problemContainer.dischargeDate &&
                      moment(problemContainer.dischargeDate).format("MM/DD")}
                  </td>
                  <td>
                    {problemContainer.status !== "On Vessel" &&
                      problemContainer.lastFreeDay &&
                      moment(problemContainer.lastFreeDay).format("MM/DD")}
                  </td>

                  <td>
                    {problemContainer.status !== "On Vessel" &&
                      problemContainer.outGateDate &&
                      moment(problemContainer.outGateDate).format("MM/DD")}
                  </td>
                  <td className="">
                    {getEmptyReturn(problemContainer.emptyReturnLocation)
                      .toString()
                      .replaceAll("_", " ")}
                  </td>
                  <td>
                    {problemContainer.status !== "On Vessel" && problemContainer.terminatedDate
                      ? moment(problemContainer.terminatedDate).format("MM/DD")
                      : problemContainer.inGate?.date
                      ? moment(problemContainer.inGate?.date).format("MM/DD")
                      : ""}
                  </td>
                  <td>{problemContainer.vesselName}</td>
                  <td>{problemContainer.voyageName}</td>
                  <td>{problemContainer.billOfLading}</td>
                  <td>{problemContainer.grossWeight}</td>
                  <td>{problemContainer.seal}</td>
                  <td>{problemContainer.yardLocation}</td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={100}>Data Not Found</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ProblemContainerTable;
