export const tableHeaders = [
  {
    label: "Reference_Number",
    value: "drayosRef",
    sort: "-",
  },
  {
    label: "Container No",
    value: "containerNo",
    sort: "-",
  },

  {
    label: "Pick Up Terminal",
    value: "Pick Up Terminal",
    sort: "-",
  },

  {
    label: "Status",
    value: "status",
    sort: "-",
  },

  {
    label: "Created AT",
    value: "createdAt",
    sort: "-",
  },

  {
    label: "Duration",
    value: "Duration",
    sort: "-",
  },
];
