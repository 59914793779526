import React, { Fragment, useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { ResetFormValue } from "../login";
import CustomInputField from "Common/Components/Inputs/CustomInputField";
import { resetSchema } from "./AuthValidation";
import { useAppContext } from "contexts/AppContext";
import { loginUser, onAuthReset, resetPassword } from "reducers/action/appAction";
// import { isLoggedIn as isAUth } from "utils";

const ResetForm = () => {
  let navigate = useNavigate();

  const { userId } = useParams();
  const { state, myDispatch } = useAppContext();
  const { errorMessage, isResetSuccess, loading, isLoggedIn, resetEmail } = state.appStates;

  const [token, setToken] = useState("");

  useEffect(() => {
    return () => {
      // On Unmount reset the state
      myDispatch(onAuthReset());
    };
  }, []);

  // REACT HOOK FORM
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<ResetFormValue>({
    mode: "onBlur",
    resolver: yupResolver(resetSchema),
  });

  // FORM SUBMIT
  const onFormSubmit: SubmitHandler<ResetFormValue> = async (data) => {
    const finalData = {
      resetToken: token,
      password: data.password,
    };

    let res = await resetPassword(finalData);

    myDispatch(res);
    res.payload.then(async (resp) => {
      const loginData = {
        email: resp.data.data,
        password: data.password,
      };
      let logged = await loginUser(loginData);
      myDispatch(logged);
    });
  };

  useEffect(() => {
    if (userId) {
      const resetToken = userId.split("activationToken=");

      setToken(resetToken[1]);
    }
  }, [userId]);

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/");
    }
  }, [isLoggedIn]);

  return (
    <Fragment>
      <div className="signup-wrapper--right bg-animation h-100 p-20">
        <div className="d-flex align-items-center justify-content-end">
          <div className="text-muted mr-10">Already have an account?</div>
          <Link to="/login">
            <button className="btn btn btn-outline-light">Sign in</button>
          </Link>
        </div>
        <div className="d-flex align-items-center justify-content-center h-100">
          <div className="form-wrapper w-max p-50 bg-white rounded-10 shadow-5">
            <h3 className="text-center mb-50">Start Improving Your Shipping!</h3>
            <form className="form-sm" onSubmit={handleSubmit(onFormSubmit)}>
              {errorMessage && (
                <div className="alert alert-warning" role="alert">
                  {errorMessage}
                </div>
              )}
              <CustomInputField
                autoComplete="off"
                placeholder="Password*"
                name="password"
                type="password"
                required
                register={register}
                error={Boolean(errors.password)}
                helper={errors.password ? errors.password.message : ""}
                className="form-control"
                label={
                  <span>
                    <span className="text-danger">*</span> Password
                  </span>
                }
              />
              <CustomInputField
                placeholder="Confirm Password*"
                autoComplete="off"
                name="confirmPassword"
                type="password"
                required
                rules={{ maxLength: 8 }}
                error={Boolean(errors.confirmPassword)}
                register={register}
                helper={errors.confirmPassword ? errors.confirmPassword.message : ""}
                label={
                  <span>
                    <span className="text-danger">*</span> Confirm Password
                  </span>
                }
                className="form-control"
              />

              <div className="boxes mt-4">{/* <input type="checkbox" id="box-1" /> */}</div>

              <div className="text-center">
                <button type="submit" className="btn btn-primary btn-lg btn-block mt-40">
                  {loading && (
                    <span
                      className="spinner-border spinner-border-sm mr-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                  Change Password
                </button>
              </div>

              <p className="pt-3 text-center">
                Don't have an account?
                <Link to="/signup" className="ml-2">
                  Sign Up
                </Link>
              </p>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ResetForm;
