import { IconWarning } from "Common/Icons";
import { gateIcon } from "../utils";

export const StatusWrapper = (props: any) => {
  const { children, dayValue, value } = props;
  return (
    <td className="text-center">
      <div className="d-flex align-items-center">
        <span
          className={`text-left text-${dayValue && dayValue[value] && gateIcon(dayValue[value])}`}
        >
          {(dayValue && dayValue[value]) || ""}
        </span>
        {children}
      </div>
    </td>
  );
};

export const getRemarks = (remarks: string) => {
  if (remarks) {
    return (
      <div className="position-relative tooltip-label ml-2 no-print">
        <IconWarning className="text-primary wh-10px text-warning ml-1" />
        <div className="tooltip tooltip--gray-700 mw-200" data-placement="top-center">
          {remarks}
          <span className="arrow"></span>
        </div>
      </div>
    );
  }
  return null;
};
