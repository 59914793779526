import { IconTimes } from "Common/Icons";
import { useAppContext } from "contexts/AppContext";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm, SubmitHandler } from "react-hook-form";
import { CookiesUpdateValues, SessionUpdateValues } from "../support";
import { setContainerCredentialsParams, getRequiredCookiesByPort } from "../action";
import { updateCookies } from "../action";
import CustomTextAreaField from "Common/Components/Inputs/CustomTextAreaField";
interface IProps {
  credentials: any;
  onCancel: () => void;
  sLoading: Boolean;
  closeModalAfterSuccess: () => void;
  toastHandler: () => void;
}

const CookieUpdateModal = (props: IProps) => {
  const { state, myDispatch } = useAppContext();
  const { searchPost } = state.supportStates;

  const { credentials, sLoading, onCancel, closeModalAfterSuccess, toastHandler } = props;

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<CookiesUpdateValues>();

  const onFormSubmit: SubmitHandler<CookiesUpdateValues> = async (data) => {
    const requestBody: any = {
      id: credentials?._id,
      userId: credentials?.userId?._id,
      portId: credentials?.portId?._id,
      cookies: typeof data.cookies === "string" ? JSON.parse(data.cookies) : data.cookies,
      portUsername: credentials?.portUsername,
      portPassword: credentials?.portPassword,
    };

    const response = updateCookies(requestBody);
    myDispatch(response);
    response?.payload
      .then(async (res: any) => {
        closeModalAfterSuccess();
        myDispatch(
          getRequiredCookiesByPort({
            ports: searchPost.ports,
          })
        );
        toastHandler();
      })
      .catch((err) => {
        toastHandler();
        console.log(err);
      });
  };

  return (
    <>
      <Modal show={credentials} dialogClassName={"modal-sm"}>
        <Modal.Header>
          <Modal.Title className="w-100 d-flex align-items-center justify-content-between">
            <h5 className="font-20 mb-0">Update Cookies</h5>
            <div className="d-flex ml-auto">
              <button className="btn btn-white shadow-none" onClick={onCancel}>
                <IconTimes className="mr-2" />
                Close
              </button>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="form-sm" onSubmit={handleSubmit(onFormSubmit)}>
            <div className="col-16">
              <CustomTextAreaField
                defaultValue={JSON.stringify(credentials?.cookies)}
                className="form-control mb-0"
                placeholder={`Update Cookies`}
                name="cookies"
                required
                register={register}
                autoComplete="off"
                rows={3}
                dense
              />
            </div>
            <div className="text-center">
              <button
                type="submit"
                className={
                  `btn btn-primary btn-lg btn-block mt-40`
                  // sLoading ? "btn-success" : "btn-primary "
                }
              >
                {sLoading && (
                  <span
                    className="spinner-border spinner-border-sm mr-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
                Confirm
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CookieUpdateModal;
