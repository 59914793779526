import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { IconAngleArrowDown, IconAngleArrowRight } from "Common/Icons";

import { useAppContext } from "contexts/AppContext";
import { getcontainerCountStatus, setAvailableParams } from "../action";
import { getObjByKey } from "utils/commonFunctions";
import { statusType, tableHeaders } from "../constant";

const CountCards = (): React.ReactElement => {
  const { state, myDispatch } = useAppContext();
  // const { loggedUser } = state.appStates;
  const { countData, containers, cErrorMessage, searchParams, searchPost } = state.containerStates;
  const [statusTypes, setStatusTypes] = useState<any[]>([]);
  const [selectedCard, setSelectedCard] = useState<string | null>(null);

  const [criteriaVal, setCriteriaVal] = useState<any>({});
  const [criteriaKey, setCriteriaKey] = useState<string | null>(null);
  const [headers, setTableHeader] = useState<any | null>(tableHeaders);

  // useEffect(() => {
  //   let statusTypesOpt = statusTypes.map((status) => {
  //     containers.forEach((container: any) => {
  //       if (container.status === status.label) {
  //         status.count = status.count + 1;
  //       }
  //     });
  //     return status;
  //   });
  //   setStatusTypes(statusTypesOpt);
  // }, [containers]);
  useEffect(() => {
    if (!searchPost.status) {
      setSelectedCard(null);
      setCriteriaKey(null);
    }
  }, [searchPost]);

  useEffect(() => {
    if (Object.keys(countData).length > 0) {
      let countVals = [];
      for (const [key, value] of Object.entries(countData)) {
        if (typeof value !== "string") {
          countVals.push({
            key: key,
            value: value,
          });
        }
      }
      setStatusTypes(countVals);
    }
  }, [countData]);

  const onCountClick = (val: any, isSub: boolean) => {
    let keyVal;
    if (!isSub) {
      let keys = val?.value?.length > 0 && val?.value[0] ? Object.keys(val?.value[0]) : [];
      keyVal = keys.length > 0 ? keys[0] : null;
    } else if (isSub) {
      keyVal = val.length > 0 ? val[0] : null;
    }
    keyVal !== selectedCard ? setSelectedCard(keyVal) : setSelectedCard(null);
    let statusFound = statusType.find((ss) => ss.value === (isSub ? val[0] : val.key));
    if (statusFound) {
      let searchPostD = { ...searchPost };
      if (criteriaVal && Object.keys(criteriaVal).length > 0) {
        Object.keys(criteriaVal).forEach((key) => {
          if (searchPostD[key]) {
            delete searchPostD[key];
          }
        });
      }

      let criteria = getObjByKey(val, statusFound.criteria);
      setCriteriaVal(criteria);
      if (statusFound.value !== criteriaKey) {
        searchPostD = { ...searchPostD, ...criteria };
        setCriteriaKey(statusFound.value);
      } else {
        setCriteriaKey(null);
      }
      searchParams.page = 1;

      // Sorting on Filter Status
      let sortParam = "";
      let sortString = ``;
      if (keyVal === "containerVesselCount") {
        sortParam = "ETA";
      } else if (keyVal === "containerCount") {
        sortParam = "LFD";
      }
      headers.map((D: any) => {
        if (D.label === sortParam) {
          D.sort = D.sort === "-" || D.sort === "" ? "+" : "";
          sortString = `${D.sort === "+" ? "" + `${D.value}` : "-createdAt"}`;
          D.active = true;
        } else {
          D.active = false;
        }
      });
      searchParams.sort = sortString;
      myDispatch(setAvailableParams(searchPostD, searchParams));
    }
  };

  const isSelectedCard = (val: any) => {
    if (val && selectedCard) {
      let keys = Object.keys(val);
      return keys.includes(selectedCard);
    }
    return false;
  };

  return (
    <Fragment>
      <div className={`form-row hasactive mb-3`}>
        {statusTypes.map((item: any, index) => {
          const statusLabel = statusType.find((ss) => ss.value === item.key)?.label;
          return (
            <div className="col-xl col-md" key={index}>
              <div
                className={`ncard d-flex flex-column justify-content-between p-10 ${
                  isSelectedCard(item.value[0]) ? "active" : ""
                }`}
                onClick={() => onCountClick(item, false)}
              >
                <div className="d-flex align-items-center mb-10">
                  <div
                    className={`mr-1 sbox sbox--default ${
                      criteriaKey === item.key ? "sbox--active" : ""
                    }`}
                  >
                    {item.key === "onVessel" && (
                      <span>
                        {item.value[0]?.containerVesselCount?.count
                          ? item.value[0]?.containerVesselCount?.count
                          : 0}
                      </span>
                    )}
                    {item.key === "port" && (
                      <span>
                        {item.value[0]?.containerCount?.count
                          ? item.value[0]?.containerCount?.count
                          : 0}
                      </span>
                    )}
                    {item.key === "notFound" && (
                      <span>
                        {item.value[0]?.notFoundCount?.count
                          ? item.value[0]?.notFoundCount?.count
                          : 0}
                      </span>
                    )}
                    {item.key === "outgated" && (
                      <span>
                        {item.value[0]?.outgatedCount?.count
                          ? item.value[0]?.outgatedCount?.count
                          : 0}
                      </span>
                    )}
                    {item.key === "terminated" && (
                      <span>
                        {item.value[0]?.terminatedCount?.count
                          ? item.value[0]?.terminatedCount?.count
                          : 0}
                      </span>
                    )}
                  </div>
                  <div className="font-medium">
                    {statusType.find((ss) => ss.value === item.key)?.label}
                  </div>
                  <div className="ml-auto">
                    {isSelectedCard(item.value[0]) ? (
                      <IconAngleArrowDown />
                    ) : (
                      <IconAngleArrowRight />
                    )}
                  </div>
                </div>
                {(item.key === "onVessel" || item.key === "port") && item.value?.length && (
                  <div className="d-flex align-items-center justify-content-between form-row">
                    {Object.entries(item.value[0]).map((val: any, ii: number) => {
                      if (ii > 0) {
                        const status = statusType.find((ss) => ss.value === val[0]);
                        return (
                          <div
                            className="col-6"
                            key={ii}
                            onClick={(e) => {
                              e.stopPropagation();
                              onCountClick(val, true);
                            }}
                          >
                            <div
                              className={`bbox bbox--default ${
                                status?.value === criteriaKey ? "bbox--active" : ""
                              } `}
                            >
                              <div className="bbox__title">{status?.label}</div>
                              <div className="bbox__number">{val[1]?.count}</div>
                            </div>
                          </div>
                        );
                      }
                    })}
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </Fragment>
  );
};

export default CountCards;
