export const initialMissingContainerValues = {
  missingContainers: [],
  mLoading: false,
  errorMessage: "",
  missingContainerCount: 0,

  searchParams: {
    limit: 50,
    page: 1,
  },
};

export const missingContainerReducer = (
  state: any,
  action: {
    type: string;
    payload: any;
  }
) => {
  switch (action.type) {
    case "MISSINGCONTAINERS_INPROGRESS": {
      return {
        ...state,
        mLoading: true,
      };
    }
    case "MISSINGCONTAINERS_SUCCESS": {
      let missingContainers = action.payload.data.data;
      // console.log("check missing data", missingContainers);
      return {
        ...state,
        mLoading: false,
        missingContainers: missingContainers,
        missingContainerCount: action.payload.data.count,
      };
    }
    case "MISSINGCONTAINERS_REJECTED": {
      return {
        ...state,
        mLoading: false,
        missingContainers: [],
        errorMessage: action.payload.errorMessage,
        missingContainerCount: 0,
      };
    }
    case "SET_MISSINGCONTAINERS_PARAMS": {
      let param = action.payload.searchParams;
      let post = action.payload.searchPost;
      return {
        ...state,
        searchParams: param,
        searchPost: { ...post },
      };
    }
    case "RESET_MISSINGCONTAINERS_PARAMS": {
      return {
        ...state,
        searchPost: { reset: true },
        searchParams: {
          limit: 50,
          page: 1,
        },
      };
    }
    default:
      return state;
  }
};
