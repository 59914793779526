import React, { useState, useCallback, useEffect } from "react";
import _ from "lodash";
import { useAppContext } from "contexts/AppContext";
import { getAllUsers, setUserInfoAvailableParams } from "pages/Dashboard/action";
import Loader from "Common/Components/Loader";
import { Col, Form, Row } from "react-bootstrap";
import { SelectFieldWithoutControl } from "Common/Components/Inputs/CustomSelectField";
import { APP_SYSTEM } from "pages/Dashboard/Common/constant";

const SearchFilter = () => {
  //API Initialize
  const { state, myDispatch } = useAppContext();
  const { filterSearchPost, cLoading, userSearchParams, userData, drayosUsers } =
    state.dashboardStates;

  //STATE MANAGEMENT
  const [email, setEmail] = useState<string>("");
  const [paid, setPaid] = useState<any>(true);
  const [selectedCarrier, setSelectedCarrier] = useState<any>();
  const [isDrayos, setIsDrayos] = useState<any>(true);
  const [carriersOption, setCarriersOption] = useState<any>();
  //HOOK MANAGEMENT
  // useEffect(() => {
  //   getUserList();
  // }, []);

  useEffect(() => {
    if (filterSearchPost?.email !== email) {
      getUserList(email);
    }
  }, [email, paid, isDrayos]);

  useEffect(() => {
    searchFilterOption();
  }, [userData, drayosUsers]);

  // HANDLE FUNCTION
  const getUserList = async (emailId?: string) => {
    let data: any = { isPaid: paid };
    if (isDrayos) {
      data.email = emailId;
    } else {
      data.system = APP_SYSTEM.SHIP_OS;
      delete data.email;
    }
    if (email) Object.assign(data, { email: email });
    let userSearchParam = { ...userSearchParams, page: 1 };
    myDispatch(setUserInfoAvailableParams(data, userSearchParam));
    myDispatch(getAllUsers(data, userSearchParam));
  };

  // Search FUNCTION
  const onChangeInput = (event: any) => {
    let val = event.target.value;
    // setEmail(val?.trim());
  };

  const debouncedChangeHandler = useCallback(_.debounce(onChangeInput, 1000), []);

  const handleCarrierChange = (user: any) => {
    setSelectedCarrier(user || null);
    setEmail(user?.value || null);
  };

  const searchFilterOption = () => {
    if (userData?.length && drayosUsers?.length) {
      const carrierOpts = _.intersectionBy(userData, drayosUsers, "_id")
        .map((obj: any) => {
          const foundCarrier = _.find(drayosUsers, { _id: obj?._id });
          return {
            ...obj,
            carrierEmail: foundCarrier?.email,
          };
        })
        .filter(Boolean);

      setCarriersOption(carrierOpts);
    }
  };

  return (
    <React.Fragment>
      {cLoading && <Loader />}
      <div className="filter-row d-flex align-items-center mt-15">
        <div className="app-search header-search">
          {/* <span className="search-icon"> </span> */}
          <div className="position-relative" style={{ width: "300px" }}>
            <SelectFieldWithoutControl
              className="w-1000 mr-2"
              classNamePrefix="select"
              isLoading={false}
              isClearable={true}
              isSearchable={true}
              name="carrier"
              options={drayosUsers?.map((user: any) => ({
                label: user.email,
                value: user._id,
              }))}
              placeholder="Select Carrier..."
              value={selectedCarrier}
              onChange={(value: any) => handleCarrierChange(value)}
            />
          </div>
        </div>
        <div className="ml-80">
          <Row>
            <Col xs="auto">
              <Form.Group as={Row} className="mb-1">
                <Form.Label column sm="6">
                  Is Paid? :
                </Form.Label>
                <Col sm="4">
                  <Form.Check
                    inline
                    name="paid"
                    label=""
                    onChange={(event) => setPaid(event?.target?.checked)}
                    type="switch"
                    defaultChecked
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col xs="auto">
              <Form.Group as={Row} className="mb-1">
                <Form.Label column sm="7">
                  Is Drayos? :
                </Form.Label>
                <Col sm="4">
                  <Form.Check
                    inline
                    name="isDrayos"
                    onChange={(event) => setIsDrayos(event?.target?.checked)}
                    type="switch"
                    defaultChecked
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SearchFilter;
