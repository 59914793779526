import Loader from "Common/Components/Loader";
import { useAppContext } from "contexts/AppContext";
import moment from "moment";
import React, { Fragment } from "react";

function EmptiesSlotTable() {
  const { state, myDispatch } = useAppContext();
  const { emptiesSlotLoading, emptiesSlotSummary, emptiesSlotSearchParams } = state.reportDRStates;
  // const { emptiesSlotLoading, emptiesSlotSearchParams } = state.reportDRStates;

  let timeZone = "America/New_York";
  if (emptiesSlotSearchParams.terminal == 1) {
    timeZone = "America/Los_Angeles";
  }

  return (
    <Fragment>
      <div
        className="table-responsive"
        id="dispatchTable"
        style={{ height: "calc(100vh - 250px)" }}
      >
        {emptiesSlotLoading && <Loader />}
        <table className="table table-card" id="myTable">
          <thead>
            <tr>
              <th scope="col" className="">
                <div className="d-flex align-items-center justify-content-between">
                  <span className="mr-1">#</span>
                </div>
              </th>
              <th>Port</th>
              <th>SSL</th>
              <th>Size Type</th>
              <th>Slot Date</th>
              <th>Start Time</th>
              <th>End Time</th>
              <th>Total Slot</th>
              <th>Total Used</th>
              <th>Available Slot</th>
              <th>Remarks</th>
            </tr>
          </thead>
          <tbody>
            {emptiesSlotSummary.length === 0 && (
              <tr>
                <td colSpan={100}>Please Update Filter to get data</td>
              </tr>
            )}
            {emptiesSlotSummary.map((element: any, i: number) => {
              return (
                <Fragment key={i}>
                  <tr key={i}>
                    <td>
                      <div className="d-flex align-items-center justify-content-between">
                        <span className="mr-1">{i + 1}</span>
                        <div className="form-check"></div>
                      </div>
                    </td>
                    <td>{element.port}</td>
                    <td>{element.ssl}</td>
                    <td>{element.sizeType}</td>
                    <td>
                      {element.appointmentSlotDate
                        ? moment(element.appointmentSlotDate).tz(timeZone).format("YYYY-MM-DD")
                        : "-"}
                    </td>
                    <td>
                      {element.slotStart
                        ? moment(element.slotStart).tz(timeZone).format("HH:mm")
                        : "-"}
                    </td>
                    <td>
                      {element.slotEnd ? moment(element.slotEnd).tz(timeZone).format("HH:mm") : "-"}
                    </td>

                    <td>{element.totalSlots}</td>
                    <td>{element.totalUsedSlots}</td>
                    <td>
                      {element.totalAvailableSlots && <span>{element.totalAvailableSlots}</span>}
                      {!element.totalAvailableSlots &&
                        element.totalAvailableSlots !== 0 &&
                        element.totalSlots &&
                        element.totalUsedSlots && (
                          <span>
                            {parseInt(element.totalSlots) - parseInt(element.totalUsedSlots)}
                          </span>
                        )}
                      {!element.totalAvailableSlots && !element.slotEnd && !element.slotStart && (
                        <span>0</span>
                      )}
                    </td>
                    <td>{element.info}</td>
                  </tr>
                </Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
    </Fragment>
  );
}

export default EmptiesSlotTable;
