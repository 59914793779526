import { api } from "config";
import { objToQueryParams, removeNullEmpty } from "utils/commonFunctions";

export const getMissingContainers = (data: any, params?: any) => {
  let postData = removeNullEmpty(data);
  let url = "api/dashboard/missingDataReport";
  url = params ? url + "?" + objToQueryParams(params) : url;

  return {
    type: "MISSINGCONTAINERS",
    payload: api.post(url, postData),
  };
};

export const setMissingContainersParams = (searchPost?: any, searchParams?: any) => {
  return {
    type: "SET_MISSINGCONTAINERS_PARAMS",
    payload: { searchPost, searchParams },
  };
};

export const resetMissingContainersParams = () => {
  return {
    type: "RESET_MISSINGCONTAINERS_PARAMS",
  };
};
