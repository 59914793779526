import React, { useEffect, useRef, useState } from "react";
import { IconDual, IconReset } from "../../../../Common/Components/Icons/Icons";
import { HeaderPropsI, SmallTypesI } from "../../location";
import PortImages from "../../PortImages";
import { useAppContext } from "contexts/AppContext";
import { getEmptyData } from "pages/EmptyReturn/action";
import {
  checkGateStatus,
  getOpenCloseInfo,
  getTimeDifference,
} from "pages/EmptyReturn/Common/utils";
import { IconWarning } from "Common/Icons";
import moment from "moment";
import { newMarketWithTwoShift, terminalNameConstant } from "pages/EmptyReturn/constant";

const TableHeader = (props: HeaderPropsI) => {
  const {
    scroll,
    terminal,
    terminalCode,
    portsArray,
    emptyData,
    updateTime,
    shift,
    isTomorrow,
    date,
  } = props;
  const { state, myDispatch } = useAppContext();
  const { gatehour } = state.emptyStates;

  // ---------------------------------------------------- STATE MANAGEMENT---------------------------------------------------//
  const [isScrollable, setIsScrollable] = useState(false);
  const [shiftNumber, setShiftNumber] = useState(1);

  // const ref = useRef<HTMLTableSectionElement>(null);
  // ---------------------------------------------------- HOOKS MANAGEMENT ---------------------------------------------------//
  useEffect(() => {
    if (scroll > 250) {
      setIsScrollable(true);
    } else {
      setIsScrollable(false);
    }
  }, [scroll]);

  useEffect(() => {
    if (shift === "shift1") {
      setShiftNumber(1);
    } else {
      setShiftNumber(2);
    }
  }, [shift]);

  const handleRefresh = () => {
    myDispatch(getEmptyData(terminalNameConstant[terminalCode || terminal], isTomorrow || false));
  };

  const gateRemarks = (filterGateData: any) => {
    switch (shift) {
      case "shift1":
        return filterGateData?.remarks1;
      case "shift2":
        return filterGateData?.remarks2;
      case "shift3":
        return filterGateData?.remarks3;
      default:
        break;
    }
  };

  const filterGateHours = (port: any) => {
    const gateData = gatehour?.find((ff: any) => ff?.key === port.label);
    const filterGateData = gateData?.data?.gateHours?.find(
      (filtered: any) => filtered.date === moment(date, "LLLL").format("LL")
    );
    return filterGateData;
  };

  return (
    <thead className={`table-head text-center ${isScrollable ? "sticky-head" : ""}`}>
      <tr>
        {isScrollable ? (
          <th rowSpan={3} className="bg-gray-100 rounded-left--5 w-0 p-2">
            <button onClick={() => handleRefresh()} className="btn btn-white w-100">
              <IconReset className="mr-10" />
              Refresh
            </button>
          </th>
        ) : (
          <th rowSpan={4} className="bg-gray-100 align-bottom rounded-left--5 w-0 p-2">
            <div className="d-flex flex-column align-items-center align-items-center">
              <div className="img-wrapper mb-40">
                {terminalCode || terminal ? <img src={PortImages.PortLogo} alt="" /> : null}
              </div>
              <button onClick={() => handleRefresh()} className="btn btn-white w-100">
                <IconReset className="mr-10" />
                Refresh
              </button>
            </div>
          </th>
        )}
      </tr>

      <tr>
        {portsArray.map((port: SmallTypesI, index: number) => {
          const empties = emptyData?.find((ff) => ff?.key === port.label);
          const filterGateData = filterGateHours(port);
          return (
            <React.Fragment key={index}>
              {port.checked && (
                <th className="p-10 w--10">
                  <div className="d-inline-flex flex-column align-items-center">
                    <div className="img-wrapper h-30px">
                      <img
                        src={PortImages[port.label.replace(/\s/g, "_")]}
                        alt={port.label}
                        className="h-100 w-100 img-contain"
                      />
                    </div>
                    <h6 className="text-primary mt-2 mb-0 title text-break-spaces">
                      {port.label.replace("_", " ")}
                    </h6>
                    {empties?.alert && (
                      <div className="position-relative ">
                        <IconWarning className="text-primary wh-10px text-warning mt-2 tooltip-icon-label" />
                        <div
                          className="tooltip tooltip--gray-700 mw-300 mt-3"
                          data-placement={
                            port.label === portsArray[portsArray.length - 1].label
                              ? "left"
                              : "bottom-center"
                          }
                        >
                          {empties.alert}
                          <span className="arrow"></span>
                        </div>
                      </div>
                    )}
                  </div>
                </th>
              )}
            </React.Fragment>
          );
        })}
      </tr>

      {terminal === "LA" ||
        (terminalCode && newMarketWithTwoShift.includes[terminalCode] && (
          <tr className="position-sticky">
            {portsArray.map((port: SmallTypesI, index: number) => {
              const filterGateData = filterGateHours(port);
              return (
                <React.Fragment key={index}>
                  {port.checked && (
                    <th className="p-10 rounded-0" style={{ top: isScrollable ? "144px" : "" }}>
                      {checkGateStatus(shift, filterGateData) === "CLOSED" ? (
                        <span className="badge badge-gray-50">CLOSED</span>
                      ) : checkGateStatus(shift, filterGateData) === "OPEN" ? (
                        <span className="badge badge-light-green">OPEN</span>
                      ) : (
                        <span></span>
                      )}
                    </th>
                  )}
                </React.Fragment>
              );
            })}
          </tr>
        ))}
      {/* <tr>
        {portsArray.map((port: SmallTypesI, index: number) => {
          let isOpen;
          const isClosed = getOpenCloseInfo(port.label, shiftNumber, terminal);
          if (terminal === "LA" && emptyData?.length) {
            const empties = emptyData?.find((ff) => ff?.key === port.label);
            if (empties && empties.data?.data?.length) {
              isOpen = empties.data.data.length;
            }
          } else if (terminal === "NY" && emptyData.length) {
            const empties = emptyData?.find((ff) => ff?.key === port.label);
            if (empties && empties.data?.data?.length) {
              isOpen = empties.data.data.length;
            }
          }
          return (
            <React.Fragment key={index}>
              {port.checked && (
                <td
                  className="p-10 rounded-0"
                  style={{ visibility: isScrollable ? "hidden" : "visible" }}
                >
                  {isOpen && isClosed ? (
                    <span className="badge badge-gray-50">CLOSED</span>
                  ) : isOpen ? (
                    <span className="badge badge-light-green">OPEN</span>
                  ) : (
                    <span className="badge badge-gray">Not Available</span>
                  )}
                </td>
              )}
            </React.Fragment>
          );
        })}
      </tr> */}
      <tr>
        {portsArray.map((port: SmallTypesI, index: number) => {
          return (
            <React.Fragment key={index}>
              {port.checked && (
                <td
                  className="py-15 px-10 rounded-0 w-10"
                  style={{ visibility: isScrollable ? "hidden" : "visible" }}
                >
                  <span className="font-12 font-weight-normal text-dark">
                    {updateTime ? getTimeDifference(updateTime) + " min ago" : "Not Available"}
                  </span>
                </td>
              )}
            </React.Fragment>
          );
        })}
      </tr>
    </thead>
  );
};

export default TableHeader;
