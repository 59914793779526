import Loader from "Common/Components/Loader";
import { useAppContext } from "contexts/AppContext";
import React, { useState } from "react";
import _ from "lodash";
import { useEffect, useRef } from "react";
import { DayTypeI, GateHourI, TableHeaderType } from "../location";
import { getRemarks, StatusWrapper } from "../Common/CustomTableCell";
import { STATIC_DAYS } from "./Constant";
import { getCurrentWeek } from "../Common/utils";
import { getWeekData } from "../utils";

const GateTableNewMarket = (props: any) => {
  const { state } = useAppContext();
  const { todayDate } = props;
  const { gatehour, gLoading } = state.emptyStates;

  const [shiftExists, setShiftExists] = useState({ shift1: false, shift2: false, shift3: false });
  const [portIndex, setPortIndex] = useState(0);
  const [tableHeaders, setTableHeaders] = useState<TableHeaderType[]>();

  useEffect(() => {
    setShiftExists({ shift1: false, shift2: false, shift3: false });
    checkDay();
    checkData();
  }, [gatehour]);

  const checkDay = () => {
    let allGateHoursDataForHeader: TableHeaderType[] = [];
    gatehour?.forEach((ar: GateHourI) => {
      const portKey = ar?.key?.replaceAll("_", " ");
      const currentTableHeaders = ar.data.gateHours.map((hr: DayTypeI) => {
        //boolean flag to check existence of multiple shifts
        hr.shift1 &&
          setShiftExists((prevState) => ({
            ...prevState,
            shift1: true,
          }));
        hr.shift2 &&
          setShiftExists((prevState) => ({
            ...prevState,
            shift2: true,
          }));
        hr.shift3 &&
          setShiftExists((prevState) => ({
            ...prevState,
            shift3: true,
          }));
        return {
          date: hr?.date,
          day: hr?.day.toUpperCase(),
          shift1: hr?.shift1,
          shift2: hr?.shift2,
          shift3: hr?.shift3,
          key: portKey,
        };
      });
      allGateHoursDataForHeader = [...allGateHoursDataForHeader, ...currentTableHeaders];
    });
    if (Array.isArray(allGateHoursDataForHeader) && allGateHoursDataForHeader?.length) {
      const findUniqueDayDate = _.uniqBy(
        allGateHoursDataForHeader,
        (header) => `${header?.date}-${header?.day}`
      ).sort((a, b) => {
        const dateA: any = new Date(a?.date);
        const dateB: any = new Date(b?.date);
        return dateA - dateB;
      });
      setTableHeaders(findUniqueDayDate);
    }
  };
  const checkData = () => {
    let remIndex = 0;
    gatehour?.some((entry: any, index: any) => {
      if (entry?.data?.gateHours?.length) {
        remIndex = index;
        return true;
      }
    });
    setPortIndex(remIndex);
  };
  return (
    <div
      className="table-responsive  mt-15"
      id="export-table"
      style={{ height: "calc(100vh - 268px)" }}
    >
      {gLoading && <Loader />}
      <table className="table table-card gate-table table--card table--tooltip ">
        <thead className="table-background ">
          <tr>
            <th scope="col" rowSpan={2}>
              Sno
            </th>
            <th rowSpan={2}>Terminal</th>
            {Array.isArray(tableHeaders) && tableHeaders?.length
              ? tableHeaders.map((gate: TableHeaderType, thindex: number) => {
                  return (
                    <th
                      key={thindex}
                      colSpan={shiftExists.shift3 ? 3 : shiftExists.shift2 ? 2 : 1}
                      className="text-center"
                    >
                      {gate.day}
                      <div className="mt-1"> {gate.date}</div>
                    </th>
                  );
                })
              : ""}
          </tr>
          <tr>
            {Array.isArray(tableHeaders) && tableHeaders?.length
              ? tableHeaders.map((gate: TableHeaderType, thindex: number) => {
                  return (
                    <React.Fragment key={thindex}>
                      {shiftExists.shift1 && <th>Shift 1</th>}
                      {shiftExists.shift2 && <th>Shift 2</th>}
                      {shiftExists.shift3 && <th> Shift 3</th>}
                    </React.Fragment>
                  );
                })
              : ""}
          </tr>
        </thead>
        <tbody>
          {gatehour && gatehour?.length ? (
            _.compact(gatehour).map((gh: any, i: number) => {
              return (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{gh?.key.replaceAll(/_/g, " ")}</td>
                  {tableHeaders?.map((th: TableHeaderType, idx) => {
                    let dayValue: any = gh?.data?.gateHours?.find(
                      (ff: TableHeaderType) =>
                        ff.day?.toUpperCase()?.includes(th.day) && ff.date.includes(th.date)
                    );
                    if (dayValue) {
                      dayValue = {
                        ...dayValue,
                        status1:
                          dayValue?.status1?.toUpperCase() || dayValue?.status?.toUpperCase(),
                        status2: dayValue?.status2?.toUpperCase(),
                        status3: dayValue?.status3?.toUpperCase(),
                      };
                      return (
                        <React.Fragment key={idx}>
                          {
                            <StatusWrapper dayValue={dayValue} value="status1">
                              {getRemarks(dayValue?.remarks1 || dayValue?.remarks)}
                            </StatusWrapper>
                          }

                          {dayValue.status2 ? (
                            <StatusWrapper dayValue={dayValue} value="status2">
                              {getRemarks(dayValue?.remarks2)}
                            </StatusWrapper>
                          ) : null}

                          {dayValue.status3 ? (
                            <StatusWrapper dayValue={dayValue} value="status3">
                              {getRemarks(dayValue?.remarks3)}
                            </StatusWrapper>
                          ) : null}
                        </React.Fragment>
                      );
                    } else {
                      return (
                        <React.Fragment key={idx}>
                          <td className="text-center"></td>
                          {shiftExists.shift2 ? <td className="text-center"></td> : null}
                          {shiftExists.shift3 ? <td className="text-center"></td> : null}
                        </React.Fragment>
                      );
                    }
                  })}
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={100}>No data found</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default GateTableNewMarket;
