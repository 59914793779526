import { api } from "config";
import { objToQueryParams, removeNullEmpty } from "utils/commonFunctions";

export const getArchiveContainers = (data: any, params?: any) => {
  let postData = removeNullEmpty(data);
  let url = "api/container/getAllArchivedContainers";
  url = params ? url + "?" + objToQueryParams(params) : url;

  return {
    type: "ARCHIVECONTAINERS",
    payload: api.post(url, postData),
  };
};

export const setArchiveContainersParams = (searchPost?: any, searchParams?: any) => {
  return {
    type: "SET_ARCHIVECONTAINERS_PARAMS",
    payload: { searchPost, searchParams },
  };
};
