import React, { Fragment, useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useNavigate } from "react-router-dom";
import { SignUpFormValue } from "../login";
import CustomInputField from "Common/Components/Inputs/CustomInputField";
import { signUpSchema } from "./AuthValidation";
import { loginUser, onAuthReset, signupUser } from "reducers/action/appAction";

import { useAppContext } from "contexts/AppContext";

const SignUpForm = () => {
  let navigate = useNavigate();
  const { state, myDispatch } = useAppContext();
  const { errorMessage, isLoggedIn, loading, errorSignUpMessage, isSignUpSuccess } =
    state.appStates;

  useEffect(() => {
    return () => {
      // On Unmount reset the state
      myDispatch(onAuthReset());
    };
  }, []);

  // REACT HOOK FORM
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<SignUpFormValue>({
    mode: "onBlur",
    resolver: yupResolver(signUpSchema),
  });

  // FORM SUBMIT
  const onFormSubmit: SubmitHandler<SignUpFormValue> = async (data) => {
    let response = await signupUser(data);
    myDispatch(response);
    response.payload.then(async (res) => {
      const finalData = {
        email: data.email,
        password: data.password,
      };
      let logged = await loginUser(finalData);
      myDispatch(logged);
    });
  };

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/");
    }
  }, [state.appStates]);
  return (
    <Fragment>
      <div className="signup-wrapper--right bg-animation h-100 p-20">
        <div className="d-flex align-items-center justify-content-end">
          <div className="text-muted mr-10">Already have an account?</div>
          <Link to="/login">
            <button className="btn btn btn-outline-light bg-white">Sign in</button>
          </Link>
        </div>
        <div className="d-flex align-items-center justify-content-center h-100">
          <div className="form-wrapper w-max p-50 bg-white rounded-10 shadow-5">
            <h3 className="text-center mb-50">Sign Up for Early Access to drayOS Lite</h3>
            <form className="form-sm" onSubmit={handleSubmit(onFormSubmit)}>
              {!isSignUpSuccess && errorMessage && (
                <div className="alert alert-warning" role="alert">
                  {errorMessage}
                </div>
              )}
              {!isSignUpSuccess && (
                <Fragment>
                  {/* <CustomInputField
                    autoComplete="off"
                    placeholder="Company Name*"
                    name="companyName"
                    type="text"
                    required
                    error={Boolean(errors.companyName)}
                    register={register}
                    helper={errors.companyName ? errors.companyName.message : ""}
                    label={
                      <span>
                        <span className="text-danger">*</span> Company Name
                      </span>
                    }
                    className="form-control"
                  /> */}
                  <CustomInputField
                    autoComplete="off"
                    placeholder="Email*"
                    name="email"
                    type="text"
                    required
                    error={Boolean(errors.email)}
                    register={register}
                    helper={errors.email ? errors.email.message : ""}
                    label={
                      <span>
                        <span className="text-danger">*</span> Email
                      </span>
                    }
                    className="form-control"
                  />

                  {/* <CustomInputField
                    autoComplete="off"
                    placeholder="Username*"
                    name="username"
                    type="text"
                    required
                    register={register}
                    error={Boolean(errors.username)}
                    helper={errors.username ? errors.username.message : ""}
                    label={
                      <span>
                        <span className="text-danger">*</span> User Name
                      </span>
                    }
                    className="form-control"
                  /> */}
                  <CustomInputField
                    autoComplete="off"
                    placeholder="Password*"
                    name="password"
                    type="password"
                    required
                    rules={{ maxLength: 8 }}
                    error={Boolean(errors.password)}
                    register={register}
                    helper={errors.password ? errors.password.message : ""}
                    label={
                      <span>
                        <span className="text-danger">*</span> Password
                      </span>
                    }
                    className="form-control"
                  />
                  <CustomInputField
                    placeholder="Confirm Password*"
                    autoComplete="off"
                    name="confirmPassword"
                    type="password"
                    required
                    rules={{ maxLength: 8 }}
                    error={Boolean(errors.confirmPassword)}
                    register={register}
                    helper={errors.confirmPassword ? errors.confirmPassword.message : ""}
                    label={
                      <span>
                        <span className="text-danger">*</span> Password
                      </span>
                    }
                    className="form-control"
                  />
                </Fragment>
              )}

              <div className="text-center">
                {!isSignUpSuccess && (
                  <button type="submit" className="btn btn-primary btn-lg btn-block mt-40">
                    {loading && (
                      <span
                        className="spinner-border spinner-border-sm mr-2"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}
                    Sign up
                  </button>
                )}
                {/* {isSignUpSuccess && (
                  <button type="button" className="btn btn-success btn-lg btn-block mt-40">
                    Please Check Your Email for Activation
                  </button>
                )} */}
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default SignUpForm;
