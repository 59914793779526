export const statusOpts = [
  "Available",
  "Not Available",
  "Outgated",
  "Terminated",
  "On Vessel",
  "Pending",
];

export const holds = [
  {
    label: "RELEASED",
    value: "RELEASED",
  },
  {
    label: "HOLD",
    value: "HOLD",
  },
];

export const broker = [
  {
    label: "YES",
    value: "YES",
  },
  {
    label: "NO",
    value: "NO",
  },
];

export const tableHeaders = [
  {
    label: "Reference #",
    value: "reference_number",
    sort: "-",
  },
  {
    label: "Container#",
    value: "containerNo",
    sort: "-",
  },

  {
    label: "Port",
    value: "shipperName",
    sort: "-",
  },
  {
    label: "SSL",
    value: "containerOwnerName",
    sort: "-",
  },
  {
    label: "Size",
    value: "containerSizeName",
    sort: "-",
  },
  {
    label: "Type",
    value: "containerTypeName",
    sort: "-",
  },
  {
    label: "Status",
    value: "status",
    sort: "-",
  },
  // {
  //   label: "Trackos Status",
  //   value: "myApp_tracking_status",
  //   sort: "-",
  // },
  {
    label: "Holds",
    value: "custom",
    sort: "-",
  },

  {
    label: "ETA",
    value: "eta",
    sort: "-",
  },
  {
    label: "User ETA",
    value: "eta",
    sort: "-",
  },
  {
    label: "Discharge Date",
    value: "systemGeneratedDischarge",
    sort: "-",
  },
  {
    label: "User Discharge Date",
    value: "dischargeDate",
    sort: "-",
  },
  {
    label: "LFD",
    value: "systemGeneratedLastFreeDay",
    sort: "-",
  },
  {
    label: "User LFD",
    value: "lastFreeDay",
    sort: "-",
  },
  {
    label: "Out Gate",
    value: "systemGeneratedOutGateDate",
    sort: "-",
  },

  {
    label: "TIR OUT",
    value: "tirOut",
    sort: "-",
  },

  {
    label: "Terminated",
    value: "systemGeneratedInGateDate",
    sort: "-",
  },

  {
    label: "TIR IN",
    value: "tirIn",
    sort: "-",
  },
  {
    label: "Vessel Name",
    value: "vesselName",
    sort: "-",
  },
  {
    label: "Voyage",
    value: "voyageName",
    sort: "-",
  },
  {
    label: "BOL",
    value: "billOfLading",
    sort: "-",
  },
  {
    label: "Seal",
    value: "seal",
    sort: "-",
  },
];

export const statusType = [
  {
    label: "Container on Vessel",
    value: "onVessel",
    criteria: "containerVesselCriteria",
  },
  {
    label: "Containers at Port",
    value: "port",
    criteria: "portContainerCriteria",
  },
  {
    label: "Out Gated",
    value: "outgated",
    criteria: "outgatedCriteria",
  },
  {
    label: "Containers Returned",
    value: "terminated",
    criteria: "terminatedCriteria",
  },

  {
    label: "Pending",
    value: "pending",
    criteria: "containerVesselCriteria",
  },
  {
    label: "On Hold",
    value: "holdsCount",
    criteria: "holdCountCriteria",
  },
  {
    label: "Released",
    value: "releasedCount",
    criteria: "releasedCountCriteria",
  },
  {
    label: "Last Free Days",
    value: "lastFreeDayCount",
    criteria: "lastFreeDaysCriteria",
  },
  {
    label: "Vessel",
    value: "containerVesselCount",
    criteria: "containerVesselCriteria",
  },
  {
    label: "Pickup Appts",
    value: "pickedUpDaysCount",
    criteria: "containerVesselCriteria",
  },
  {
    label: "Not Available",
    value: "notAvailableCount",
    criteria: "notAvailableContainerCriteria",
  },
  {
    label: "Available",
    value: "availableCount",
    criteria: "availableContainerCriteria",
  },
  {
    label: "Not Found",
    value: "notFound",
    criteria: "notFoundCriteria",
  },
  {
    label: "Pickup Appts",
    value: "containerCount",
    criteria: "portContainerCriteria",
  },
];

export const statusMapper = {
  On_Vessel: ["PENDING"],
  Not_Available: ["PENDING"],
  Available: [
    "PENDING",
    "AVAILABLE",
    "DISPATCHED",
    "DEPARTED",
    "CHASSISPICK_ARRIVED",
    "CHASSISPICK_DEPARTED",
  ],
  Outgated: [
    "PULLCONTAINER_ARRIVED",
    "PULLCONTAINER_DEPARTED",
    "DELIVERLOAD_ARRIVED",
    "DELIVERLOAD_DEPARTED",
    "DROPCONTAINER_ARRIVED",
    "DROPCONTAINER_DEPARTED",
    "HOOKCONTAINER_ARRIVED",
    "HOOKCONTAINER_DEPARTED",
    "RETURNCONTAINER_ARRIVED",
    "RETURNCONTAINER_DEPARTED",
    "CHASSISTERMINATION_ARRIVED",
    "CHASSISTERMINATION_DEPARTED",
    "COMPLETED",
    "APPROVED",
    "BILLING",
    "UNAPPROVED",
    "REBILLING",
    "PARTIAL_PAID",
    "FULL_PAID",
  ],
  Terminated: [
    "RETURNCONTAINER_ARRIVED",
    "RETURNCONTAINER_DEPARTED",
    "CHASSISTERMINATION_ARRIVED",
    "CHASSISTERMINATION_DEPARTED",
    "COMPLETED",
    "APPROVED",
    "BILLING",
    "UNAPPROVED",
    "REBILLING",
    "PARTIAL_PAID",
    "FULL_PAID",
  ],
};
