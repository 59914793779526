import { IconFilter } from "Common/Icons";
import _ from "lodash";
import { useState } from "react";
import WrongDataReportFilter from "./FilterPop";

const SearchFilter = () => {
  const [showFilter, setShowFilter] = useState(false);

  return (
    <div className="filter-row d-flex align-items-center">
      {/* Filter */}
      <div className="ml-auto d-flex">
        <button className="btn btn-outline-light" onClick={() => setShowFilter(!showFilter)}>
          <IconFilter className="mr-2" />
          Filter
        </button>
      </div>
      {showFilter && <WrongDataReportFilter hideFilter={() => setShowFilter(false)} />}
    </div>
  );
};

export default SearchFilter;
