import moment from "moment";
import { IconAngleArrowDown, IconAngleArrowRight } from "Common/Components/Icons/Icons";
import React, { useEffect, useState } from "react";
import { cardItems } from "../constant";
import {
  compareTimeRange,
  getCurrentWeek,
  getRealTime,
  getTimeZone,
  handlePrevShift,
} from "./utils";
import shiftPort from "./shift.json";
import { useAppContext } from "contexts/AppContext";
import { getEmptyData } from "../action";

const WeeklyEmptyCard = (props: any) => {
  const { date, terminal, value, handleActive, shift } = props;
  const { state, myDispatch } = useAppContext();

  const todayDate = moment().tz("America/Los_Angeles").subtract(7, "hours").format("MM-DD-YYYY");
  const prevShiftDate = moment().subtract(1, "days").format("MM-DD-YYYY");

  // ---------------------------------------------------- STATE MANAGEMENT---------------------------------------------------//
  const [isCardActive, setCardActive] = useState(1);
  const [weeklyDateVal, setWeeklyDate] = useState(getCurrentWeek()[0]);

  // ---------------------------------------------------- HANDLE FUNCTIONS ---------------------------------------------------//
  const handleCardActive = (card: any) => {
    setCardActive(card.id);
    if (card.isTomorrow) {
      myDispatch(getEmptyData(terminal, card.isTomorrow));
    } else {
      myDispatch(getEmptyData(terminal, false));
    }
    handleWeekData(card);
  };

  // ---------------------------------------------------- HOOKS MANAGEMENT ---------------------------------------------------//
  useEffect(() => {
    getEmptiesData();
  }, [weeklyDateVal]);

  const getEmptiesData = () => {
    if (weeklyDateVal === todayDate) {
      myDispatch(getEmptyData(terminal, false));
    } else {
      myDispatch(getEmptyData(terminal, false, weeklyDateVal));
    }
  };

  const handleWeekData = (weekDateObj: any) => {
    if (weekDateObj.weekDate) {
      setWeeklyDate(weekDateObj.weekDate);
    }
    handleActive(weekDateObj);
  };

  return (
    <div className="top-page mb-10">
      {/* Cards Row */}
      <div className={`form-row ${isCardActive ? "hasactive" : ""}`}>
        {cardItems(date, terminal, value).map((cardItem: any, index: number) => {
          return (
            <div key={index} className="col-md">
              <div
                className={`ncard d-flex justify-content-between p-10 ${
                  weeklyDateVal === cardItem.weekDate ? "active" : ""
                }`}
                onClick={() => handleWeekData(cardItem)}
              >
                <div className="d-flex flex-column">
                  <div className="font-12 font-weight-500 text-gray-900">{cardItem.title}</div>
                  <div className="font-12 font-weight-normal text-gray-500">{cardItem.Date}</div>
                </div>
                <div className="d-flex align-items-center">
                  <div className="ml-auto">
                    {weeklyDateVal === cardItem.weekDate ? (
                      <IconAngleArrowDown />
                    ) : (
                      <IconAngleArrowRight />
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default WeeklyEmptyCard;
