import _ from "lodash";
export const initialDrayosReportValues = {
  loading: false,
  standardPorts: [],
  overAllReports: [],
  baseLineSnapshotReport: {},
  standardPortLoading: false,
};

export const drayosReportReducer = (
  state: any,
  action: {
    type: string;
    payload: any;
  }
) => {
  switch (action.type) {
    case "STANDARD_RAW_PORTS_INPROGRESS": {
      return {
        ...state,
        standardPortLoading: true,
        standardPorts: [],
      };
    }
    case "STANDARD_RAW_PORTS_SUCCESS": {
      let ports = action.payload.data;
      // let summary = action.payload;
      // summary = summary?.length > 0 ? _.orderBy(summary, ["missingCount"], ["desc"]) : [];
      return {
        ...state,
        standardPortLoading: false,
        standardPorts: ports,
      };
    }
    case "STANDARD_RAW_PORTS_REJECTED": {
      return {
        ...state,
        standardPortLoading: false,
        standardPorts: [],
      };
    }
    // OVERALL_REPORT

    case "OVERALL_REPORT_INPROGRESS": {
      return {
        ...state,
        loading: true,
        overAllReports: [],
      };
    }
    case "OVERALL_REPORT_SUCCESS": {
      let overAllReports = action.payload.data;
      // let summary = action.payload;
      // summary = summary?.length > 0 ? _.orderBy(summary, ["missingCount"], ["desc"]) : [];
      return {
        ...state,
        loading: false,
        overAllReports: overAllReports,
      };
    }
    case "OVERALL_REPORT_REJECTED": {
      return {
        ...state,
        loading: false,
        overAllReports: [],
      };
    }

    case "BASE_LINE_SNAPSHOT_REPORT_INPROGRESS": {
      return {
        ...state,
        loading: true,
        baseLineSnapshotReport: [],
      };
    }

    case "BASE_LINE_SNAPSHOT_REPORT_SUCCESS": {
      return {
        ...state,
        loading: false,
        baseLineSnapshotReport: action.payload.data,
      };
    }

    case "BASE_LINE_SNAPSHOT_REPORT_REJECTED": {
      return {
        ...state,
        loading: true,
        baseLineSnapshotReport: action.payload.data,
      };
    }
    default:
      return state;
  }
};
