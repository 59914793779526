import { getPermissionArrays } from "Common/utils";
import _ from "lodash";
import Authentication from "pages/Authentication";
import Bookings from "pages/Bookings";
import Dashboard from "pages/Dashboard";
import EmptyHistory from "pages/EmptyReturn/History";
import EmptyReturnLocations from "pages/EmptyReturn/Locations";
import TerminalScreengrabs from "pages/EmptyReturn/Screenshot";
import Homepage from "pages/Homepage";
import Importavailability from "pages/Importavailability";
import NoMatch from "pages/NoMatch";
import OnboardingInvite from "pages/OnboardingModal/Components/OnboardingInvite";
import PortCredentials from "pages/Settings/Ports";
import UpdateProfile from "pages/Settings/UpdateProfile";
import StatusCard from "pages/StatusCard";
import Tracking from "pages/Tracking";
import { Outlet, Navigate } from "react-router-dom";
import Layout from "Theme/Layout";
import { isLoggedIn } from "utils";
import Invite from "pages/Settings/Invite";
import VesselSchedule from "VesselSchedule";
import ProblemContainer from "pages/ProblemContainer";
import UserInfo from "pages/Dashboard/UserInfo";
import CreateSuperAdmin from "pages/Dashboard/CreateSuperAdmin";
import NotUpdatedContainers from "pages/Dashboard/NotUpdatedContainers";
import SelectTerminals from "pages/OnboardingModal/Components/SelectTerminals";
import TerminalSelect from "pages/Settings/Terminal/terminal";
import ReportContainer from "pages/ReportContainer";
import UpdateToken from "pages/Settings/UpdateToken/UpdateToken";
import MissingContainers from "pages/MissingContainers";
import WrongDataReport from "pages/WrongDataReport";
import SuccessfulDataReport from "pages/SuccessfulDataReport";
import VesselReport from "pages/VesselReport";
import ArchiveContainer from "pages/ArchiveContainer";
import GateHour from "pages/EmptyReturn/GateHour";
import EmptyCorrection from "pages/EmptyCorrection";
import NewEmpty from "pages/NewEmpty";
import NewEmptyCorrection from "pages/NewEmptyCorrection/Locations";
import DrayosSummaryReport from "pages/DrayosSummaryReport";
import SucessfulMatricsContainerReport from "pages/SucessfulMatricsContainerReport";
import ContainerCredentials from "pages/Support";

import UploadFolder from "pages/UploadFolder";
import UploadHTML from "pages/Support/UploadHTML";
import Token from "pages/Support/Token";
import TTIBlankIndexUpdater from "pages/Support/BlankIndexUpdater";
import EmptiesSlotReport from "pages/EmptiesSlotReport";
import Cookie from "pages/Support/Cookie";
import EmptyReturnLocationSlots from "pages/EmptyReturn/LocationSlots";
import AppointmentReport from "pages/AppointmentReport";
import AppointmentSlotReport from "pages/AppointmentSlotReport";
import DrayosStandAloneReport from "pages/DrayosStandAloneReport";
import DrayosStandAloneReportSnapShot from "pages/DrayosStandaloneReportSnapshot";

import TimeScaleReport from "pages/TimeScaleReport";
import SiteTracker from "pages/Support/SiteTracker";

export const routes = () => {
  const { role } = JSON.parse(localStorage.getItem("loggedUser") || "{}");

  const mainRoutes: any = [
    {
      path: "/",
      element: !isLoggedIn() ? <Outlet /> : <Navigate to="/" />,
      children: [
        {
          path: "/user",
          element: <Outlet />,
          children: [
            { path: "verify/:userId", element: <Authentication fromType="userActive" /> },
            { path: "password-reset/:userId", element: <Authentication fromType="reset" /> },
          ],
        },
        { path: "/login", element: <Authentication fromType="login" /> },
        { path: "/signup", element: <Authentication fromType="signup" /> },
        // { path: "/user/verify/", element: <Authentication fromType="confirmation" /> },
        { path: "/forget", element: <Authentication fromType="forget" /> },
        // { path: "/reset/:token", element: <Authentication fromType="reset" /> },
        { path: "/", element: <Navigate to="/login" /> },
      ],
    },
    {
      path: "/",
      element: isLoggedIn() ? <Layout /> : <Navigate to="/login" />, //This is a layout for your page {optional}
      children: [
        { index: true, element: <Navigate to="/import-availability" />, permissions: [] }, //The true component needed for your index page goes here {mandatory}
        {
          path: "/tracking",
          element: <Tracking />,
          permissions: ["SuperAdmin", "Admin"],
        },

        //Dashboard
        {
          path: "/dashboard/containers",
          element: <Dashboard tab="Container_Info" />,
          permissions: ["SuperAdmin", "Admin", "Employee"],
        },
        {
          path: "/dashboard/AllUser_Info",
          element: <UserInfo tab="User_Info" />,
          permissions: ["SuperAdmin"],
        },
        // {
        //   path: "/dashboard/User_Info",
        //   element: <UserInfo tab="User_Info" />,
        //   permissions: ["SuperAdmin"],
        // },
        {
          path: "/dashboard/Super_Admin",
          element: <CreateSuperAdmin tab="Super_Admin" />,
          permissions: ["SuperAdmin"],
        },
        // { path: "/empty-returns", element: <EmptyReturn /> },
        {
          path: "/current-empties/LA-LB",
          element: <EmptyReturnLocations tab="LA" terminalTab="LA" />,
          permissions: ["SuperAdmin", "Admin", "Employee"],
        },
        {
          path: "/current-empties/NJ-NY",
          element: <EmptyReturnLocations tab="NY" terminalTab="NY" />,
          permissions: ["SuperAdmin", "Admin", "Employee"],
        },
        {
          path: "/current-empties/:terminalCode",
          element: <EmptyReturnLocations tab="" terminalTab="" />,
          permissions: ["SuperAdmin", "Admin", "Employee"],
        },
        {
          path: "/current-emptiesSlots/la-lb",
          element: <EmptyReturnLocationSlots tab="LA" terminalTab="LA" />,
          permissions: ["SuperAdmin", "Admin", "Employee"],
        },
        {
          path: "/current-emptiesSlots/nj-ny",
          element: <EmptyReturnLocationSlots tab="NY" terminalTab="NY" />,
          permissions: ["SuperAdmin", "Admin", "Employee"],
        },
        {
          path: "/empty-history/per-diem-records",
          element: <EmptyHistory tab="Per Diem Records" />,
          permissions: ["SuperAdmin", "Admin", "Employee"],
        },
        {
          path: "/empty-history/terminal-screengrabs",
          element: <TerminalScreengrabs tab="Terminal Screengrabs" />,
          permissions: ["SuperAdmin", "Admin", "Employee"],
        },
        {
          path: "/gate-hour",
          element: <GateHour />,
          permissions: ["SuperAdmin", "Admin", "Employee"],
        },
        // { path: "/current-empties/NY", element: <EmptyReturn returnData="NJEMPTY" /> },
        {
          path: "/bookings",
          element: <Bookings />,
          permissions: ["SuperAdmin", "Admin", "Employee"],
        },
        {
          path: "/import-availability",
          element: <Importavailability />,
          permissions: ["SuperAdmin", "Admin", "Employee"],
        },
        // { path: "/onboarding-modal", element: <OnCompanyModalInfo /> },
        {
          path: "/status-card",
          element: <StatusCard />,
          permissions: ["SuperAdmin", "Admin", "Employee"],
        },

        // { path: "/onboarding-modal", element: <OnboardingModal /> },
        {
          path: "/invite-modal",
          element: <OnboardingInvite />,
          permissions: ["SuperAdmin", "Admin", "Employee"],
        },
        {
          path: "/status-card",
          element: <StatusCard />,
          permissions: ["SuperAdmin", "Admin", "Employee"],
        },
        // Settings
        {
          path: "/settings/port-credentials",
          element: <PortCredentials />,
          permissions: ["SuperAdmin", "Admin"],
        },
        {
          path: "/settings/update-profile",
          element: <UpdateProfile />,
          permissions: ["SuperAdmin", "Admin"],
        },
        {
          path: "/settings/invite-team",
          element: <Invite />,
          permissions: ["SuperAdmin", "Admin"],
        },
        {
          path: "/settings/select-terminal",
          element: <TerminalSelect />,
          permissions: ["SuperAdmin", "Admin"],
        },
        {
          path: "/settings/update-token",
          element: <UpdateToken />,
          permissions: ["SuperAdmin", "Admin"],
        },

        // no routes match
        { path: "*", element: <NoMatch /> },

        //Vessel Schedule
        {
          path: "/vessel-schedule",
          element: <VesselSchedule />,
        },

        {
          path: "/time-scale-reports",
          element: <TimeScaleReport />,
        },
        // Problem Container
        { path: "/reports/problem-containers", element: <ProblemContainer /> },

        //  Not Updated Containers
        { path: "/reports/not-updated-containers", element: <NotUpdatedContainers /> },
        //Report Container
        {
          path: "/reports/report-container",
          element: <ReportContainer />,
        },
        {
          path: "/reports/missing-report-container",
          element: <MissingContainers />,
        },
        {
          path: "/reports/wrong-data-reports",
          element: <WrongDataReport />,
        },
        {
          path: "/reports/successful-data-reports",
          element: <SuccessfulDataReport />,
        },
        {
          path: "/reports/drayos-data-reports",
          element: <DrayosSummaryReport />,
        },
        {
          path: "/vessel-report",
          element: <VesselReport />,
        },
        {
          path: "/reports/archive-container",
          element: <ArchiveContainer />,
        },
        {
          path: "/reports/appointment-report",
          element: <AppointmentReport />,
          permissions: ["SuperAdmin"],
        },
        {
          path: "/reports/appointment-slot-report",
          element: <AppointmentSlotReport />,
          permissions: ["SuperAdmin"],
        },
        {
          path: "/admin-correction",
          element: <EmptyCorrection />,
          permissions: ["SuperAdmin", "Admin", "Employee"],
        },
        {
          path: "/new-empty-correction/NJ-NY",
          element: <NewEmptyCorrection tab="NY" terminalTab="NY" />,
          permissions: ["SuperAdmin", "Admin", "Employee"],
        },
        {
          path: "/new-empty-correction/LA-LB",
          element: <NewEmptyCorrection tab="LA" terminalTab="LA" />,
          permissions: ["SuperAdmin", "Admin", "Employee"],
        },
        {
          path: "/reports/successful-matrics-container-report",
          element: <SucessfulMatricsContainerReport />,
        },
        {
          path: "/reports/empties-slot-report",
          element: <EmptiesSlotReport />,
        },
        {
          path: "/reports/drayos-standalone-report",
          element: <DrayosStandAloneReport />,
          permissions: ["SuperAdmin"],
        },
        {
          path: "/reports/drayos-baseline-snapshot-report",
          element: <DrayosStandAloneReportSnapShot />,
          permissions: ["SuperAdmin"],
        },
        {
          path: "/support/sessions",
          element: <ContainerCredentials />,
          permissions: ["SuperAdmin"],
        },
        {
          path: "/support/tokens",
          element: <Token />,
          permissions: ["SuperAdmin"],
        },
        {
          path: "/support/cookies",
          element: <Cookie />,
          permissions: ["SuperAdmin"],
        },
        {
          path: "/support/tti-blankindex-updater",
          element: <TTIBlankIndexUpdater />,
          permissions: ["SuperAdmin"],
        },
        {
          path: "/support/upload-folder",
          element: <UploadFolder />,
          permissions: ["SuperAdmin"],
        },
        {
          path: "/support/upload-html",
          element: <UploadHTML />,
          permissions: ["SuperAdmin"],
        },
        {
          path: "/support/site-tracker",
          element: <SiteTracker />,
          permissions: ["SuperAdmin"],
        },
      ],
    },
  ];

  if (role) {
    const filterRoutes: any = getPermissionArrays(mainRoutes[1].children, role);
    mainRoutes[1].children = filterRoutes;
    return mainRoutes;
  }
  return mainRoutes;
};
