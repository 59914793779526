import _ from "lodash";
import { isLoggedIn } from "utils";
const auth: boolean = JSON.parse(localStorage.getItem("loggedUser") ?? "false");

export const initialValues = {
  isLoggedIn: isLoggedIn(),
  isActive: isLoggedIn(),
  message: "",
  loggedUser: auth,
  loading: false,
  errorMessage: null,
  isSignUpSuccess: false,
  isInviteSuccess: null,
  onboardingTab: "terminal",
  ploading: false,
  assignPortId: null,
  isResetSuccess: false,
  resetMessage: "",
  resetEmail: "",
  userSelectedTerminals: [],
};

export const appReducer = (
  state: any,
  action: {
    type: string;
    payload: any;
    value: any;
  }
) => {
  switch (action.type) {
    case "SIGNIN_INPROGRESS": {
      return {
        ...state,
        loading: true,
        errorMessage: null,
        isLoggedIn: false,
      };
    }
    case "SIGNIN_SUCCESS": {
      let loggedUser = action.payload.data.data;
      loggedUser.terminal = _.uniqBy(loggedUser.terminal, "_id");
      loggedUser.terminal.map((item: any) => {
        localStorage.setItem("timeZone", item.timeZone);
        return {
          ...item,
          ports: [],
        };
      });
      localStorage.setItem("accessToken", action.payload.data.accessToken);
      localStorage.setItem("refreshToken", action.payload.data.refreshToken);
      localStorage.setItem("loggedUser", JSON.stringify(loggedUser));
      return {
        ...state,
        loading: false,
        loggedUser: loggedUser,
        isLoggedIn: true,
        errorMessage: null,
      };
    }
    case "SIGNIN_REJECTED": {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.errorMessage,
        isLoggedIn: false,
      };
    }
    case "SIGNOUT": {
      localStorage.clear();
      return {
        ...state,
        isLoggedIn: false,
      };
    }

    case "SETLOGGEDUSER": {
      localStorage.setItem("loggedUser", JSON.stringify(action.payload));
      return {
        ...state,
        loggedUser: action.payload,
      };
    }

    // SignUp User Reducer
    case "SIGNUP_INPROGRESS": {
      return {
        ...state,
        loading: true,
        errorMessage: null,
        isLoggedIn: false,
      };
    }
    case "SIGNUP_SUCCESS": {
      return {
        ...state,
        errorMessage: null,
        isSignUpSuccess: true,
        loading: false,
      };
    }
    case "SIGNUP_REJECTED": {
      return {
        ...state,
        errorMessage: action.payload.errorMessage,
        isSignUpSuccess: false,
        loading: false,
      };
    }

    // Activate User Reducer
    case "ACTIVATEUSER_INPROGRESS": {
      return {
        ...state,
        isActive: false,
        loading: true,
        errorMessage: null,
      };
    }
    case "ACTIVATEUSER_SUCCESS": {
      return {
        ...state,
        isActive: true,
        loading: false,
        errorMessage: null,
      };
    }
    case "ACTIVATEUSER_REJECTED": {
      return {
        ...state,
        isActive: false,
        loading: false,
        errorMessage: action.payload.errorMessage,
      };
    }

    // Forgot User Reducer
    case "FORGOTPASSWORD_INPROGRESS": {
      return {
        ...state,
        loading: true,
        errorMessage: null,
        isResetSuccess: false,
      };
    }
    case "FORGOTPASSWORD_SUCCESS": {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        isResetSuccess: true,
        resetEmail: action.payload.data,
      };
    }
    case "FORGOTPASSWORD_REJECTED": {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.errorMessage,
        isResetSuccess: false,
      };
    }

    // Reset User Reducer
    case "RESETPASSWORD_INPROGRESS": {
      return {
        ...state,
        loading: true,
        errorMessage: null,
        // isLoggedIn: false,
      };
    }
    case "RESETPASSWORD_SUCCESS": {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        resetMessage: action.payload.data.message,
      };
    }
    case "RESETPASSWORD_REJECTED": {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.errorMessage,
      };
    }
    // Assign Terminal To User
    case "ASSIGNTERMINAL_INPROGRESS": {
      return {
        ...state,
        loading: true,
        assignErMessage: null,
        resetSuccess: false,
        onboardingTab: "terminal",
      };
    }
    case "ASSIGNTERMINAL_SUCCESS": {
      let loggedUser = action.payload.data.data;
      loggedUser.terminal = loggedUser.terminal.map((item: any) => {
        localStorage.setItem("timeZone", item.timeZone);
        return {
          ...item,
          ports: state.loggedUser.terminal.find((i: any) => i._id === item._id)?.ports,
        };
      });
      localStorage.setItem("loggedUser", JSON.stringify(loggedUser));
      return {
        ...state,
        loading: false,
        assignErMessage: null,
        resetSuccess: true,
        onboardingTab: "port",
        loggedUser: loggedUser,
        userSelectedTerminals: action.payload.data.data.terminal,
      };
    }
    case "ASSIGNTERMINAL_REJECTED": {
      return {
        ...state,
        loading: false,
        assignErMessage: action.payload.errorMessage,
        resetSuccess: false,
        onboardingTab: "port",
      };
    }

    case "SET_ONBOARDING_TAB": {
      return {
        ...state,
        onboardingTab: action.payload,
      };
    }

    case "AUTHSTATE_RESET": {
      return {
        ...initialValues,
        loggedUser: state.loggedUser,
        isLoggedIn: state.isLoggedIn,
      };
    }
    case "USER_ACTIVATED_SUCCESS": {
      return {
        ...state,
        message: action.payload.data.message,
      };
    }
    case "USER_ACTIVATED_REJECTED": {
      return {
        ...state,
        errorMessage: action.payload.errorMessage,
      };
    }
    case "ADD_COMPANY_INFO_INPROGRESS": {
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    }
    case "ADD_COMPANY_INFO_SUCCESS": {
      // const getItem = JSON.parse(localStorage.getItem("loggedUser") || "{}");
      // const newItem = {
      //   ...getItem,
      //   isCompanyDetailFill: true,
      // };
      localStorage.setItem("loggedUser", JSON.stringify(action.payload.data.data));
      return {
        ...state,
        loading: false,
        errorMessage: null,
        message: action.payload.data.message,
        loggedUser: action.payload.data.data,
      };
    }
    case "ADD_COMPANY_INFO_REJECTED": {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.errorMessage,
        message: "",
      };
    }
    case "USER_INVITATION_INPROGRESS": {
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    }
    case "USER_INVITATION_SUCCESS": {
      return {
        ...state,
        loading: false,
        message: action.payload.data.message,
        errorMessage: null,
        isInviteSuccess: true,
      };
    }
    case "USER_INVITATION_REJECTED": {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.errorMessage,
        isInviteSuccess: false,
      };
    }

    case "USERTERMINALLIST_SUCCESS": {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        userSelectedTerminals: action.payload.data.data,
      };
    }
    case "USERTERMINALLIST_REJECTED": {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.errorMessage,
        userSelectedTerminals: [],
      };
    }

    default:
      return state;
  }
};
