import moment, { Moment } from "moment";
import "moment-timezone";
import shift from "./shift.json";

const format = "HH:mm";
const midnight = moment("00:00", format);

export const getTimeDifference = (utc: string) => {
  const local = moment.utc(utc).local();
  const timeNow = moment();
  const difference = Math.round(moment.duration(timeNow.diff(local)).asMinutes());
  return difference;
};

export const getOpenCloseInfo = (port: string, value: number, zone: string) => {
  let compareTime;
  const Shift: any = shift;
  const day = new Date().toLocaleDateString("en-US", { weekday: "long" });
  const timeZone = getTimeZone(zone);
  const timeNow = getRealTime(timeZone);

  const findDay = Shift[port].find((item: any) => item.day === day.toUpperCase());

  if (findDay !== undefined) {
    const shift1 = findDay.SHIFT1.split("-");
    const shift2 = findDay.SHIFT2.split("-");

    if (value === 1) {
      compareTime = compareTimeRange(shift1, timeNow, 1);
    } else {
      compareTime = compareTimeRange(shift2, timeNow, 2);
    }
    return compareTime;
    // const compareTime = compareTimeRange(shift1, timeNow, 1, morning);
    // if (compareTime) {

    //   return compareTime;
    // } else {
    //   return compareTimeRange(shift2, timeNow, 2, morning);
    // }
  }
};

export const compareTimeRange = (time: string[], realTime: any, value: number) => {
  let first, second;
  if (value !== 1 && realTime.isAfter(midnight)) {
    first = moment(time[0], format).subtract(1, "days");
    second = moment(time[1], format).add(1, "days");
  } else if (value !== 1) {
    first = moment(time[0], format);
    second = moment(time[1], format).add(1, "days");
  } else {
    first = moment(time[0], format);
    second = moment(time[1], format);
  }

  if (realTime.isBetween(first, second) || realTime.isSame(first)) {
    return false;
  } else {
    return true;
  }

  // if (value === 2) {
  //   if ((realTime <= first && realTime <= second) || (realTime >= first && realTime <= second)) {
  //     return false;
  //   } else {
  //     return true;
  //   }
  // } else {
  //   if (realTime >= first && realTime <= second) {
  //     return false;
  //   } else {
  //     return true;
  //   }
  // }
};

export const getTimeZone = (zone: string) => {
  if (zone === "LA") {
    return "America/Los_Angeles";
  } else {
    return "America/New_York";
  }
};

export const getRealTime = (timeZone: string) => {
  const time = moment().format(format);
  return moment(time, format);
};

export const getTodayDate = () => {
  return new Date();
};

export const getDuplicateArrayofObjects = (
  array1: any,
  array2: any,
  array1Value: string,
  array2Value: string
) => {
  return array1.filter((item1: any) =>
    array2.some((item2: any) => item1[array1Value] === item2[array2Value])
  );
};

export const handleSort = (
  obj: any,
  array: any,
  searchParams: any
  // searchPost: any,
  // callback: (searchPost: any, searchParams: any) => void
) => {
  let sortString = ``;
  array.map((D: any) => {
    if (D.value === obj.value) {
      D.sort = D.sort === "-" ? "+" : "-";
      sortString = `${D.sort === "+" ? "" : D.sort}${D.value}`;
      D.active = true;
    } else {
      D.active = false;
    }
    return D;
  });
  if (sortString !== "") {
    const searchParamsD = {
      ...searchParams,
      sort: sortString,
    };
    return searchParamsD;
    // callback(searchPost, searchParamsD);
    // searchParams.sort = sortString;
    // callback(searchPost, searchParamsD);
  }
};

export const findDuplicates = (arr: any) => {
  const uniqueElements = new Set();
  const filteredArr = arr.filter((el: any) => {
    const duplicate = uniqueElements.has(el.containerNo);
    uniqueElements.add(el.containerNo);
    return !duplicate;
  });
  return filteredArr;
};

export const getCurrentWeek = () => {
  let days = [];
  let weekStart = moment().subtract(7, "hours").startOf("isoWeek");

  for (let i = 0; i <= 6; i++) {
    days.push(moment(weekStart).add(i, "days").format("MM-DD-YYYY"));
  }

  const todayDate = moment().subtract(7, "hours").tz("America/Los_Angeles");
  const todayDateIndex = days.indexOf(todayDate.format("MM-DD-YYYY"));
  const now = todayDate.format("HH:mm");

  if (todayDate.isAfter(midnight) && todayDate.isAfter(moment("07:00", format))) {
    days = days.slice(todayDateIndex);
  } else if (moment(now, format).isAfter(moment("07:00", format))) {
    days = days.slice(todayDateIndex);
  } else {
    days = days.slice(todayDateIndex - 1);
  }
  return days;
};

export const nth = function (d: number) {
  switch (d) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

export const getShiftActive = (shiftCount: number) => {
  let shiftTime = "";
  const now = moment().format("HH:mm");
  const currentTime = moment(now, format);
  if (
    currentTime.isSameOrAfter(moment("07:00", format)) &&
    currentTime.isSameOrBefore(moment("17:00", format))
  ) {
    shiftTime = "shift1";
  } else if (
    shiftCount === 3 &&
    currentTime.isAfter(midnight) &&
    currentTime.isBetween(moment("03:00", format), moment("07:00", format))
  ) {
    shiftTime = "shift3";
  } else if (
    currentTime.isAfter(moment("17:00", format)) &&
    currentTime.isSameOrBefore(moment("24:00", format))
  ) {
    shiftTime = "shift2";
  } else if (
    currentTime.isAfter(midnight) &&
    currentTime.isBetween(moment("00:00", format), moment("07:00", format))
  ) {
    shiftTime = "shift2";
  } else {
    shiftTime = "shift1";
  }
  return shiftTime;
};

export const checkGateStatus = (shiftType: any, filterGateData: any) => {
  switch (shiftType) {
    case "shift1":
      return filterGateData?.status1;
    case "shift2":
      return filterGateData?.status2;
    case "shift3":
      return filterGateData?.status3;
    default:
      break;
  }
};

export const handlePrevShift = (shiftType: any) => {
  if (moment().isBefore(midnight) && shiftType === "shift2") {
    return true;
  } else if (
    moment().isAfter(midnight) &&
    moment().isBetween(moment("00:00", "HH:mm"), moment("07:00", "HH:mm"))
  ) {
    return true;
  } else {
    return false;
  }
};
