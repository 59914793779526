import { useAppContext } from "contexts/AppContext";
import { getTimeZone, getTodayDate } from "pages/EmptyReturn/Common/utils";
import React, { useEffect, useState } from "react";
import moment, { Moment } from "moment";

const Screenshot = (props: any) => {
  // ---------------------------------------------------- PROPS---------------------------------------------------//
  const { onUrlChange } = props;
  // ---------------------------------------------------- STATE MANAGEMENT---------------------------------------------------//
  const [s3Url, setS3Url] = useState<any>("");
  const [loading, setLoading] = useState(false);
  const { state, myDispatch } = useAppContext();
  const { empties, eLoading } = state.emptyStates;
  // ---------------------------------------------------- HOOKS MANAGEMENT ---------------------------------------------------//
  useEffect(() => {
    let datas = empties?.data;
    if (datas?.length && eLoading) {
      setLoading(true);
      const s3UrlData = datas[0]?.data?.s3url;
      // if (s3Url !== s3UrlData) {
      if (Array.isArray(s3UrlData)) {
        setS3Url(s3UrlData?.reverse());
        onUrlChange(s3UrlData?.reverse());
      } else {
        setS3Url(s3UrlData);
        onUrlChange(s3UrlData);
      }
    } else {
      setS3Url("");
      setLoading(false);
    }
    // }
  }, [empties, eLoading]);

  return (
    <>
      <tr>
        <td colSpan={8}>
          <div className="img-wrapper d-flex flex-column p-30 bg-white rounded-6  ">
            {s3Url ? (
              <>
                {!Array.isArray(s3Url) ? (
                  <embed
                    onLoad={() => setLoading(false)}
                    onError={() => setLoading(false)}
                    width="100%"
                    className=""
                    height="570px"
                    // style={{ Heigth: "560px" }}
                    type="text/html"
                    src={s3Url}
                  />
                ) : (
                  s3Url.map((url: string, i: number) => (
                    <embed
                      key={i}
                      onLoad={() => setLoading(false)}
                      onError={() => setLoading(false)}
                      width="100%"
                      type="text/html"
                      height="570px"
                      className=""
                      src={url}
                    />
                  ))
                )}
              </>
            ) : null}
          </div>
        </td>
      </tr>
    </>
  );
};

export default Screenshot;
