import axios from "axios";
import _ from "lodash";
import moment from "moment";
import { headers } from "config/api";
import jwt_decode from "jwt-decode";

export default function jsonToQueryParams(obj: any) {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
}

export function objToQueryParams(obj: any) {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(
        encodeURIComponent(p) +
          "=" +
          encodeURIComponent(typeof obj[p] === "string" ? obj[p] : JSON.stringify(obj[p]))
      );
    }
  return str.join("&");
}

export function getDetailedValue(val: string, obj: any[]) {
  let found = obj?.find((item: any) => (item.portId ? item.portId === val : item._id === val));
  return found ? found.name : "";
}

export function removeNullEmpty(obj: any[]) {
  const filteredObject = _.pickBy(obj, (v) => v !== null && v !== undefined && v !== "");
  return filteredObject;
}

export function getObjByKey(obj: any, key: string) {
  let result: any;
  for (let property in obj) {
    if (obj.hasOwnProperty(property)) {
      if (property === key) {
        return obj[key]; // returns the value
      } else if (typeof obj[property] === "object") {
        // in case it is an object
        result = getObjByKey(obj[property], key);
        if (typeof result !== "undefined") {
          return result;
        }
      }
    }
  }
}

export function showDate(status: string, dateType: string) {
  let lists = [
    {
      status: "On Vessel",
      show: ["eta"],
    },
    {
      status: "Available",
      show: ["eta", "dischargeDate", "lfd"],
    },
    {
      status: "Not Available",
      show: ["eta", "dischargeDate", "lfd"],
    },
    {
      status: "Outgated",
      show: ["eta", "dischargeDate", "lfd", "outgatedDate"],
    },

    {
      status: "Terminated",
      show: ["eta", "dischargeDate", "lfd", "outgatedDate", "terminatedDate"],
    },
    {
      status: "Not Found",
      show: [],
    },
  ];
  let isShow = true;
  let statusItem = lists.find((item: any) => item.status === status);
  if (statusItem) {
    isShow = statusItem.show.includes(dateType);
  }
  return isShow;
}

export function generateLFDColor(date: string, status: string) {
  let color = "";
  if (status === "Available" || status === "Not Available") {
    if (date) {
      if (
        moment(date).startOf("day").diff(moment().startOf("day"), "days") === 0 ||
        moment(date).startOf("day").diff(moment().startOf("day"), "days") < 0
      ) {
        color = "bg-danger text-white text-center";
      } else if (moment(date).startOf("day").diff(moment().startOf("day"), "days") === 1) {
        color = "bg-warning-500 text-center";
      } else if (moment(date).startOf("day").diff(moment().startOf("day"), "days") === 2) {
        color = "bg-warning-200 text-center";
      }
    }
  }
  return color;
}

export function downloadImage(
  imageUrl: any,
  imageLabel: any,
  imageDate?: any,
  setDownloading?: any
) {
  delete headers.Authorization;
  if (!imageUrl) return;
  setDownloading(true);
  axios({
    url: imageUrl,
    responseType: "blob",
    headers,
  })
    .then((response) => {
      const url = window.URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${imageLabel}-${imageDate}.jpg`);
      document.body.appendChild(link);
      link.click();
    })
    .finally(() => {
      setDownloading(false);
    });
}

export function awaitTimer(ms: any) {
  return new Promise((res) => setTimeout(res, ms));
}

export function getTokenColor(val: any) {
  let { tokenValue, portCode } = val;
  let expTime = moment(val.expiresAt);
  let hr = 48;
  if (portCode !== "ITS TERMINAL") {
    // Decode the token
    const decodedToken: any = jwt_decode(tokenValue);
    // Get the expiration time from the token
    expTime = moment.unix(decodedToken?.exp);
    hr = 2;
  }
  // Get the current time
  const currentTime = moment();

  // Check token expiration status
  if (currentTime.isSameOrAfter(expTime)) {
    return "bg-danger"; // Token is expired
  } else if (expTime.diff(currentTime, "hours") <= hr) {
    return "bg-warning-100"; // Token is going to expire in hr hour
  } else {
    return "bg-success"; // Token is not expired
  }
}
