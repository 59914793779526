import React, { useEffect, useState } from "react";
import SubHeaderTab from "Common/Components/SubHeaderTab";
import { useAppContext } from "contexts/AppContext";
import Pagination from "Common/Components/Pagination";
import DownloadCsv from "pages/ReportContainer/Components/DownloadCsv";
import { getContainerCredentialsByPort } from "./action";
import SuccessfulDataReportTable from "./Components/CredentialsTable";
import SearchFilter from "./Components/SearchFilter";

const ContainerCredentials = () => {
  const { state, myDispatch } = useAppContext();
  const { searchParams, searchPost } = state.supportStates;
  // const { limit, page } = searchParams;

  useEffect(() => {
    getContainerCredentials();
  }, [searchParams, searchPost]);

  const getContainerCredentials = () => {
    const response = getContainerCredentialsByPort({
      ports: searchPost.ports,
      time: searchPost.time,
    });
    myDispatch(response);
  };
  // Paginate
  // const onPaginate = (val: any) => {
  //   let searchParamsD = { ...searchParams, page: val };
  //   myDispatch(setSuccessfulContainersParams(searchPost, searchParamsD));
  // };
  return (
    <div className="page-wrapper">
      <div className="position-relative subheader-wrapper">
        <SubHeaderTab val="supports" />
        <div className="page-fluid">
          <div className="top-page">
            <div className="pb-15 d-flex align-items-center">
              <h4 className="mb-0">Container Credentials</h4>
              <div className="ml-auto">
                {/* <Pagination
                  className={"ml-auto"}
                  pageRangeDisplayed={5}
                  totalItemsCount={successfulContainerCount}
                  itemsCountPerPage={limit}
                  onChange={onPaginate}
                  activePage={page}
                /> */}
              </div>
            </div>
            <SearchFilter />
          </div>
          <SuccessfulDataReportTable />
        </div>
      </div>
    </div>
  );
};

export default ContainerCredentials;
