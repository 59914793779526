import sslFile from "./sslFile.json";
import nySslFile from "./nysslFile.json";
import moment from "moment";
import { getCurrentWeek, nth } from "./Common/utils";

export const cardItems: any = (
  date: string,
  terminal: string,
  value: string,
  shift?: string,
  shiftCount?: number
) => {
  const weekDateArr = getCurrentWeek();
  if (terminal === "LA" && weekDateArr?.length && value === "currentEmpties" && !shift) {
    const weekData = weekDateArr?.map((wD: any, index) => {
      return {
        id: index + 1,
        Shift: "shift1",
        Date: moment(wD, "MM-DD-YYYY").format("LL"),
        title: moment(wD, "MM-DD-YYYY").format("dddd"),
        weekDate: wD,
        formatWeek: wD,
      };
    });
    return weekData;
  } else if (terminal === "LA" && shift) {
    const shiftArray = Array.from(Array(shiftCount).keys());
    const shiftsList = shiftArray?.map((num) => {
      return {
        id: num + 1,
        Shift: `shift${num + 1}`,
        status: `status${num + 1}`,
        Date: date,
        title: `${num + 1}${nth(num + 1)} Shift`,
      };
    });
    return shiftsList;
  } else if (terminal === "NY" && value === "currentEmpties") {
    return [
      {
        id: 1,
        Shift: "shift1",
        Date: date,
        title: "1st Shift",
        isTomorrow: false,
      },
      {
        id: 2,
        Shift: "shift1",
        Date: moment().add(1, "day").format("dddd, LL"),
        title: "1st Shift",
        isTomorrow: true,
      },
    ];
  } else {
    return [
      {
        id: 1,
        Shift: "shift1",
        Date: date,
        title: "1st Shift",
        isTomorrow: false,
      },
    ];
  }
};

export const ports = [
  {
    code: 1,
    title: "APM_TERMINAL",
  },
  {
    code: 2,
    title: "FENIX",
  },
  {
    code: 3,
    title: "LBCT",
  },
  {
    code: 4,
    title: "EVERPORT TERMINAL",
  },
  {
    code: 5,
    title: "PCT",
  },
  {
    code: 6,
    title: "PIER_A",
  },
  {
    code: 7,
    title: "YTI",
  },
  {
    code: 8,
    title: "WBCT",
  },
  {
    code: 9,
    title: "ITS_TERMINAL",
  },
  {
    code: 10,
    title: "TRAPAC",
  },
  {
    code: 11,
    title: "TTI",
  },
];

export const nyPorts = [
  {
    code: 1,
    title: "APM",
  },
  {
    code: 2,
    title: "MAHER_TERMINAL",
  },
  {
    code: 3,
    title: "GCT_BAYONNE",
  },
  {
    code: 4,
    title: "GCT_NY",
  },
  {
    code: 5,
    title: "PNCT",
  },
  {
    code: 6,
    title: "C&C MAINTENANCE",
  },
  {
    code: 7,
    title: "COLUMBIA CONTAINER SERVICES 1100",
  },
  {
    code: 8,
    title: "COLUMBIA CONTAINER SERVICES 1155",
  },
  {
    code: 9,
    title: "IRONBOUND HYATT",
  },
  {
    code: 10,
    title: "IRONBOUND DELANCY",
  },
];

export const getInitialFilter = (terminal: string) => {
  let temp1: any = [
    {
      key: "PORTS",
      value: [],
      label: [],
      checked: true,
    },
    {
      key: "SSL",
      value: [],
      checked: true,
      label: [],
    },
    {
      key: "CONTAINER TYPES",
      value: [],
      checked: true,
      label: [],
    },
  ];

  if (terminal === "LA") {
    if (!localStorage.getItem("LAEmptyFilter")) {
      ports.map((pp) => {
        return temp1[0].value.push({
          label: pp.title,
          checked: true,
          value: pp.title,
        });
      });
      sslFile.shipping_lines.map((ss) => {
        return temp1[1].value.push({
          label: ss,
          checked: true,
          value: ss,
        });
      });
      sslFile.container_types.map((ct) => {
        return temp1[2].value.push({
          label: ct,
          checked: true,
          value: ct,
        });
      });
      localStorage.setItem("LAEmptyFilter", JSON.stringify(temp1));
      return localStorage.getItem("LAEmptyFilter");
    } else {
      return localStorage.getItem("LAEmptyFilter");
    }
  } else if (terminal === "NY") {
    if (!localStorage.getItem("NYEmptyFilter")) {
      nyPorts.map((pp) => {
        return temp1[0].value.push({
          label: pp.title,
          checked: true,
          value: pp,
        });
      });
      nySslFile.shipping_lines.map((ss) => {
        return temp1[1].value.push({
          label: ss,
          checked: true,
          value: ss,
        });
      });
      nySslFile.container_types.map((ct) => {
        return temp1[2].value.push({
          label: ct,
          checked: true,
          value: ct,
        });
      });
      localStorage.setItem("NYEmptyFilter", JSON.stringify(temp1));
      return localStorage.getItem("NYEmptyFilter");
    } else {
      return localStorage.getItem("NYEmptyFilter");
    }
  }
};

export const emptyTabItems: any = {
  currentEmpties: [
    {
      id: 1,
      title: "LA/LB",
      path: "/current-empties/LA-LB",
      label: "LA",
    },
    {
      id: 2,
      title: "NJ/NY",
      path: "/current-empties/NJ-NY",
      label: "NY",
    },
  ],
  historyEmpties: [
    {
      id: 1,
      title: "Per Diem Records",
      path: "/empty-history/per-diem-records",
      label: "Per Diem Records",
    },
    {
      id: 2,
      title: "Terminal Screengrabs",
      path: "/empty-history/terminal-screengrabs",
      label: "Terminal Screengrabs",
    },
  ],
};

export const SSL_CHOICES = sslFile.shipping_lines.map((ss) => ({
  label: ss,
  value: ss,
}));

export const LA_PORT_CHOICES = ports.map((pp) => ({
  label: pp.title.replaceAll("_", " "),
  value: pp.title,
}));

export const NY_PORT_CHOICES = nyPorts
  .filter((item) => item.title !== "C&C MAINTENANCE")
  .filter((item) => item.title !== "COLUMBIA CONTAINER SERVICES 1100")
  .filter((item) => item.title !== "COLUMBIA CONTAINER SERVICES 1155")
  .filter((item) => item.title !== "IRONBOUND HYATT")
  .filter((item) => item.title !== "IRONBOUND DELANCY")
  .map((pp) => ({
    label: pp.title.replaceAll("_", " "),
    value: pp.title,
  }));

export const TERMINAL_CHOICES = emptyTabItems.currentEmpties.map((tt: any) => ({
  label: tt.title,
  value: tt.label,
  code: tt.id,
}));

export const TYPES_CHOICES = sslFile.container_types.map((type) => ({
  label: type,
  value: type,
}));

export const staticPort = [
  "APM",
  "GCT_NY",
  "GCT_BAYONNE",
  "MAHER_TERMINAL",
  "C&C MAINTENANCE",
  "COLUMBIA CONTAINER SERVICES 1100",
  "COLUMBIA CONTAINER SERVICES 1155",
  "IRONBOUND HYATT",
  "IRONBOUND DELANCY",
  "PNCT",
];
