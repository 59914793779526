import * as React from "react";
import { Outlet, Link, useRoutes, useParams } from "react-router-dom";
import { routes } from "./Routes/Routes";
import "./assets/scss/style/style.scss";
import "react-datetime/css/react-datetime.css";

export default function App() {
  // The useRoutes() hook allows you to define your routes as JavaScript objects
  // instead of <Routes> and <Route> elements. This is really just a style
  // preference for those who prefer to not use JSX for their routes config.
  let element = useRoutes(routes());

  return <React.Fragment>{element}</React.Fragment>;
}
