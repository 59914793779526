import { IconFilter } from "Common/Icons";
import { useAppContext } from "contexts/AppContext";
import _ from "lodash";
import { getNotUpdatedContainers, setNotUpdatedContainersParams } from "pages/Dashboard/action";
import { useCallback, useEffect, useState } from "react";
import NotupdatedContainersFilter from "./FilterPop";

interface IProps {
  setIsDrayos: (val: boolean) => void;
  isDrayos: boolean;
}
const SearchFilter = (props: IProps) => {
  const { setIsDrayos, isDrayos } = props;
  const { state, myDispatch } = useAppContext();
  const { searchParams } = state.problemContainerStates;
  // const [notUpdatedContainerNo, setNotUpdatedContainerNo] = useState<string>("");
  const [showFilter, setShowFilter] = useState(false);

  // On Search
  const onChangeInput = (event: any) => {
    let val = event.target.value;
    const notUpdatedContainerNo = val?.replace(/[^A-Z0-9]+/gi, "");
    searchParams.page = 1;
    let searchValue = { ...searchParams, containerNo: notUpdatedContainerNo };
    myDispatch(setNotUpdatedContainersParams(searchValue));
  };

  const debouncedChangeHandler = useCallback(_.debounce(onChangeInput, 1000), []);

  return (
    <div className="filter-row d-flex align-items-center">
      {/* Search */}
      <div className="app-search header-search">
        <span className="search-icon"> </span>
        <div className="position-relative">
          <input
            type="search"
            className="form-control input-search-left"
            placeholder="Search..."
            defaultValue={""}
            onChange={debouncedChangeHandler}
          />
        </div>
      </div>
      {/* Filter */}
      <div className="ml-auto d-flex">
        <div className=" flex-row align-items-center justify-content-center mr-10">
          <ul className="nav nav-compact" role="tablist">
            <li className="nav-item">
              <a
                className={`nav-link text-capitalize ${isDrayos && " active"}`}
                data-bs-toggle="tab"
                href="javascript:void(0)"
                role="tab"
                onClick={() => setIsDrayos(true)}
              >
                Drayos
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link text-capitalize ${!isDrayos && " active"}`}
                data-bs-toggle="tab"
                href="javascript:void(0)"
                role="tab"
                onClick={() => setIsDrayos(false)}
              >
                DrayosLite
              </a>
            </li>
          </ul>
        </div>
        <button className="btn btn-outline-light" onClick={() => setShowFilter(!showFilter)}>
          <IconFilter className="mr-2" />
          Filter
        </button>
      </div>
      {showFilter && <NotupdatedContainersFilter hideFilter={() => setShowFilter(false)} />}
    </div>
  );
};

export default SearchFilter;
