import { IconCalendar } from "Common/Icons";
import React, { useRef, useState } from "react";
import Datetime from "react-datetime";
import { Controller } from "react-hook-form";

const CustomDateField = (props: any) => {
  const { name, control, placeholder } = props;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }) => {
        return (
          <React.Fragment>
            <Datetime
              inputProps={{
                placeholder: placeholder,
              }}
              closeOnSelect
              value={value ? value : ""}
              onChange={onChange}
              renderInput={(val) => {
                return <input {...val} value={value ? val.value : ""} />;
              }}
            />
            <div className="input-icon">
              <IconCalendar />
            </div>
          </React.Fragment>
        );
      }}
    />
  );
};

export default CustomDateField;
