import { api } from "config";
import _ from "lodash";
import { objToQueryParams, removeNullEmpty } from "utils/commonFunctions";
import { IContainerFilterValues, IContainerPostValues } from "./containers";

export const onAvailabilityReset = () => {
  return {
    type: "CONTAINERSTATE_RESET",
  };
};

export const setAvailableParams = (searchPost?: any, searchParams?: any) => {
  return {
    type: "SET_AVAILABLEPARAMS",
    payload: { searchPost, searchParams },
  };
};
export const resetAvailableParams = () => {
  return {
    type: "RESET_AVAILABLEPARAMS",
  };
};
export const getContainerByContainerNo = (value?: string | null) => {
  return {
    type: "CONTAINERLISTS",
    payload: api.get(`api/container/getContainerByContainerNo?containerNo=${value}`),
  };
};
export const getContainerLists = (data?: any, params?: any) => {
  delete data.reset;
  let postData = removeNullEmpty(data);
  let url = "api/container/getAllContainersByUserId";
  url = params ? url + "?" + objToQueryParams(params) : url;
  return {
    type: "CONTAINERLISTS",
    payload: api.post(url, postData),
  };
};

export const setContainerLists = (data?: any, params?: any) => {
  let url = "api/container/getAllContainersByUserId?limit=50&page=1";
  return {
    type: "SET_CONTAINERLISTS",
    payload: api.post(url, {}),
  };
};

export const refreshContainer = (id?: string) => {
  return {
    type: "REFRESH_CONTAINER",
    payload: api.get("api/scheduler/single/" + id),
  };
};

export const addContainer = (data?: { containers: IContainerPostValues[] }) => {
  return {
    type: "ADDCONTAINER",
    // payload: api.post("api/container/createContainer", data),
    payload: api.post("api/container/createContainerAndPort", data),
  };
};
export const toggleAddContainer = (data?: boolean) => {
  return {
    type: "ADDCONTAINER_TOGGLE",
    payload: data,
  };
};

export const getContainerInfo = (containerNo: string) => {
  let postData = {
    containerNo: containerNo,
  };
  return {
    type: "ADDCONTAINER",
    payload: api.post("/api/port/getInfo", postData),
    value: "filter",
  };
};

//COUNT STATUS
export const getcontainerCountStatus = (data?: any) => {
  delete data.reset;
  let postData = removeNullEmpty(data);
  let url = "api/container/containerCountStatus";
  return {
    type: "COUNTSTATUS",
    payload: api.post(url, postData),
  };
};

export const setSelectedContainers = (data: IContainerFilterValues) => {
  return {
    type: "SELECTED_CONTAINER",
    payload: { data },
  };
};

export const updateContainerById = (data: any) => {
  return {
    type: "UPDATE_CONTAINER",
    payload: api.put("api/container/updateContainerById", data),
  };
};

export const getAddedbyTeam = () => {
  return {
    type: "GET_ADDED_BY_TEAM",
    payload: api.get("api/auth/team"),
  };
};

export const getYTISizeType = (data: any) => {
  return {
    type: "GET_YTI_SIZE_TYPE",
    payload: api.post("api/cronsizetype", data),
  };
};

export const getEirDocument = (data: any) => {
  return {
    type: "GET_EIR_DOCUMENT",
    payload: api.post("api/container/getEirDocuments", data),
  };
};

export const fetchGateInfo = (data: any) => {
  return {
    type: "FETCH_GATE_INFO",
    payload: api.post("api/cronsizetype/gateresponse", data),
  };
};

export const getDateInfo = (data: any) => {
  return {
    type: "GET_DATE",
    payload: api.post("api/cronsizetype/getdate", data),
  };
};

export const getYmluEta = (data: any) => {
  return {
    type: "GET_YMLUETA",
    payload: api.post("api/cronsizetype/ymluVesselEta", data),
  };
};
export const findDuplicateContainer = (data: any) => {
  return {
    type: "DUPLICATE_CONTAINER",
    payload: api.post("api/container/findDuplicateContainer", data),
  };
};

export const getMissingDocs = (data: any) => {
  return {
    type: "MISSING_DOCS",
    payload: api.post("api/cronsizetype/getMissingDocs", data),
  };
};

export const getArchiveContainer = (data: any) => {
  return {
    type: "GET_ARCHIVE_CONTAINER",
    payload: api.post("api/container/archiveContainer", data),
  };
};

export const getTimeSlots = (params: any, type: any) => {
  let appointmentType = type === "pickup" ? "getSlots" : "emptiesSlot";
  let url = `api/appointment/${appointmentType}`;
  url = params ? url + "?" + objToQueryParams(params) : url;
  return {
    type: "GET_TIMESLOTS",
    payload: api.get(url),
  };
};

export const bookAppointment = (data: any, type: any) => {
  let appointmentType = type === "pickup" ? "pickup" : "empty";
  let url = `api/appointment/${appointmentType}`;

  return {
    type: "BOOK_APPOINTMENT",
    payload: api.post(url, data),
  };
};
