export const tableHeaders = [
  {
    label: "Status",
    value: "status",
    sort: "-",
  },
  {
    label: "Container#",
    value: "containerNo",
    sort: "-",
  },
  {
    label: "Last Traced",
    value: "lasttraced",
    sort: "-",
  },
  {
    label: "Company Name",
    value: "companyName",
    sort: "-",
  },

  {
    label: "Email",
    value: "email",
    sort: "-",
  },
  {
    label: "Holds",
    value: "custom",
    sort: "-",
  },
  {
    label: "SSL",
    value: "containerOwner",
    sort: "-",
  },
  {
    label: "Size/Type",
    value: "containerSize",
    sort: "-",
  },
  {
    label: "PickUp Terminal",
    value: "portId",
    sort: "-",
  },
  {
    label: "ETA",
    value: "eta",
    sort: "-",
  },
  {
    label: "Discharge Date",
    value: "dischargeDate",
    sort: "-",
  },
  {
    label: "LFD",
    value: "lastFreeDay",
    sort: "-",
  },

  {
    label: "Out Gate",
    value: "outGateDate",
    sort: "-",
  },
  {
    label: "Return Terminal",
    value: "emptyReturnLocation",
    sort: "-",
  },
  {
    label: "Terminated",
    value: "terminatedDate",
    sort: "-",
  },
  {
    label: "Vessel Name",
    value: "vesselName",
    sort: "-",
  },
  {
    label: "Voyage",
    value: "voyageName",
    sort: "-",
  },
  {
    label: "BOL",
    value: "billOfLading",
    sort: "-",
  },
  {
    label: "Weight",
    value: "grossWeight",
    sort: "-",
  },
  {
    label: "Seal",
    value: "seal",
    sort: "-",
  },
  {
    label: "Yard Location",
    value: "yardLocation",
    sort: "-",
  },
];
