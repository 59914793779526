import React from "react";

interface IProps {
  item?: any;
  error?: string;
}
const CredentialStatusLabel = (props: IProps) => {
  const { item } = props;
  if (item.isLoginRequired && item.portUsername && !item.auth_failed) {
    return <div className={`font-12, font-weight-normal text-brand-500`}>Credentials Added</div>;
  } else if (item.isLoginRequired && !item.portUsername) {
    return (
      <div className={`font-12, font-weight-normal text-error-500`}>Credentials Not Added</div>
    );
  } else if (item.isLoginRequired && item.auth_failed) {
    return <div className={`font-12, font-weight-normal text-error-500`}>{item.failedMessage}</div>;
  } else {
    return (
      <div className={`font-12, font-weight-normal text-gray-500`}>Credentials Not Required</div>
    );
  }
};
export default CredentialStatusLabel;
