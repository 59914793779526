import SubHeaderTab from "Common/Components/SubHeaderTab";
import Pagination from "Common/Components/Pagination";
import NotUpdatedContainersTable from "./Components/NotUpdatedContainersTable";
import { useAppContext } from "contexts/AppContext";
import { getNotUpdatedContainers, setNotUpdatedContainersParams } from "../action";
import { useEffect, useState } from "react";
import SearchFilter from "./Components/SearchFilter";
import DownloadCsv from "pages/ReportContainer/Components/DownloadCsv";

const NotUpdatedContainers = () => {
  const { state, myDispatch } = useAppContext();
  const { notUpdatedContainersCount, searchParams } = state.dashboardStates;

  const [isDrayos, setIsDrayos] = useState<any>(false);

  searchParams.isDrayos = isDrayos;
  // fetch not updated containers
  const fetchNotUpdatedContainers = async () => {
    const response = await getNotUpdatedContainers(searchParams);
    myDispatch(response);
  };

  useEffect(() => {
    fetchNotUpdatedContainers();
  }, [searchParams, isDrayos]);

  // Pagination
  const onPaginate = (val: any) => {
    let searchParamsD = { ...searchParams, page: val };
    myDispatch(setNotUpdatedContainersParams(searchParamsD));
  };

  return (
    <div className="page-wrapper">
      <div className="position-relative subheader-wrapper">
        <SubHeaderTab
          val="reports"
          buttons={<DownloadCsv page="notUpdatedContainersItems" searchParams={searchParams} />}
        />
        <div className="page-fluid">
          <div className="top-page">
            <div className="pb-15 d-flex align-items-center">
              <h4 className="mb-0">Not Updated Containers</h4>
              <div className="ml-auto">
                <Pagination
                  className={"ml-auto"}
                  pageRangeDisplayed={5}
                  totalItemsCount={notUpdatedContainersCount}
                  itemsCountPerPage={searchParams.limit}
                  onChange={onPaginate}
                  activePage={searchParams.page}
                />
              </div>
            </div>
            <SearchFilter setIsDrayos={setIsDrayos} isDrayos={isDrayos} />
          </div>
          <NotUpdatedContainersTable />
        </div>
      </div>
    </div>
  );
};

export default NotUpdatedContainers;
