import axios from "axios";
import { api } from "config";
import { headers } from "../../config/api";
import { SignUpFormValue } from "pages/Authentication/login";
import { boolean } from "yup";

const maherApi = axios.create({
  baseURL: `https://mahercsp.maherterminals.com/`,
});

export const onAuthReset = () => {
  return {
    type: "AUTHSTATE_RESET",
  };
};
// Log In User
export const loginUser = async (data: { email: string; password: string }) => {
  return {
    type: "SIGNIN",
    payload: api.post("api/auth/login", data),
  };
};

export const logoutUser = () => {
  return {
    type: "SIGNOUT",
  };
};
// Set Logged User
export const setLoggedUser = (values: any) => {
  return {
    type: "SETLOGGEDUSER",
    payload: values,
  };
};
// Sign Up User
export const signupUser = async (data: SignUpFormValue) => {
  return {
    type: "SIGNUP",
    payload: api.post("api/auth/signup", data),
  };
};
// Confirm And Activate User Account
export function activateUser(data: { activationToken: string | undefined }) {
  return {
    type: "ACTIVATEUSER",
    payload: api.post("api/auth/activation", data),
  };
}
// Reset Password
export const resetPassword = async (data: { resetToken?: string; password: string }) => {
  return {
    type: "RESETPASSWORD",
    payload: api.post("api/auth/password/reset", data),
  };
};

// Forgot Password
export const forgotPassword = async (data: { email: string }) => {
  return {
    type: "FORGOTPASSWORD",
    payload: api.post("api/auth/password/passwordForgot", data),
  };
};
// Assign Terminal To User
export const assignTerminal = async (data: { terminalId: string[] }) => {
  return {
    type: "ASSIGNTERMINAL",
    payload: api.post("api/terminal/assign", data),
  };
};

// get Terminal List
export const getTerminalList = async () => {
  return {
    type: "GETTERMINALLIST",
    payload: api.get("api/terminal/all"),
  };
};

// get user selected Terminal
export const getUserTerminalList = async () => {
  return {
    type: "USERTERMINALLIST",
    payload: api.get("api/terminal/user/selectedTerminal"),
  };
};

// get Port List
export const getPortList = () => {
  return {
    type: "GETPORTLIST",
    payload: api.get("api/port/all"),
  };
};

export const getCredPortList = (id: NumberConstructor) => {
  return {
    type: "GETADDEDPORT_LIST",
    // payload: api.get("/api/containerCredential/all"),
    payload: api.get("/api/containerCredential/user/" + id),
  };
};

// Assi
// Assign Port Credential
export const assignPortCred = (data: any, isUpdate?: boolean) => {
  return {
    type: "PORT_CREDENTIAL",
    payload: api.post("api/containerCredential/create", data),
    value: data.portId,
  };
};

// get ContainerType List
export const getContainerTypeList = () => {
  return {
    type: "GETCONTAINERTYPE_LIST",
    payload: api.get("api/containerType/getAllContainerTypes"),
  };
};

// get ContainerSize List
export const getContainerSizeList = () => {
  return {
    type: "GETCONTAINERSIZE_LIST",
    payload: api.get("api/containerSize/getAllContainerSizes"),
  };
};

// get ContainerOwner List
export const getContainerOwnerList = () => {
  return {
    type: "GETCONTAINEROWNER_LIST",
    payload: api.get("api/containerOwner/getAllContainerOwners"),
  };
};

// add company info
export const addCompanyInfo = (data: any) => {
  return {
    type: "ADD_COMPANY_INFO",
    payload: api.post("api/auth/companyData", data),
  };
};

//get Token List
export const getAllToken = () => {
  return {
    type: "GETTOKEN_LIST",
    payload: api.get("api/port/getAllToken"),
  };
};

//get Update Token
export const getUpdateToken = (data: any) => {
  return {
    type: "UPDATETOKEN_LIST",
    payload: api.post("api/port/updateToken", data),
  };
};

export const loginMaherUser = (data: any) => {
  return {
    type: "LOGIN_MAHER_USER",
    payload: maherApi.post("cspgateway/rest/services/userService/loginUser", data),
  };
};

//Validate port credentials
export const validatePortCred = (data: any) => {
  return {
    type: "VALIDATE_PORT_CREDENTIAL",
    payload: api.post("api/containerCredential/validate", data),
    value: data.map((each: any) => each.portId),
  };
};
