import _ from "lodash";

export const initialContainerValues = {
  cLoading: false,
  cErrorMessage: null,
  containers: [],
  EIRDocument: [],
  containerCount: 0,
  cAddLoading: false,
  mLoading: false,
  addContainerCount: 0,
  showAddContainerModal: false,
  cAddErrorMessage: null,
  cAddSuccessMessage: null,
  hasAddedArchive: false,
  searchPost: {
    terminalId: null,
  },
  searchParams: {
    limit: 50,
    page: 1,
    sort: "-createdAt",
  },
  countData: {},
  selectedContainer: {},
  addedByTeam: [],
  sizeType: [],
  errorMessage: null,
  getdate: [],
  tLoading: false,
  timeSlots: [],
  bLoading: false,
  apptError: "",
  timeSlotError: null,
};

let searchPost = {
  containerNo: "",
  custom: "",
  freight: "",
  billOfLading: "",
  vesselCode: "",
  voyageName: "",
  dischargeDate: "",
  outGateDate: "",
  grossWeight: "",
  seal: "",
  yardLocation: "",
  demurrage: "",
  lastFreeDay: "",
  containerOwner: "",
  containerSize: "",
  containerType: "",
  status: "",
  portId: "",
  reset: true,
  pickUpFrom: "",
  pickUpTo: "",
  emptyReturnLocation: "",
  addedBy: "",
};

export const containerReducer = (
  state: any,
  action: {
    type: string;
    payload: any;
  }
) => {
  switch (action.type) {
    case "RESET_AVAILABLEPARAMS": {
      return {
        ...state,
        searchPost: { ...state.searchPost, ...searchPost },
        searchParams: {
          limit: 50,
          page: 1,
        },
      };
    }
    case "SET_AVAILABLEPARAMS": {
      let post = action.payload.searchPost ? action.payload.searchPost : state.searchPost;
      let param = action.payload.searchParams ? action.payload.searchParams : state.searchParams;
      return {
        ...state,
        searchPost: { ...post, reset: false },
        searchParams: param,
      };
    }
    case "CONTAINERLISTS_INPROGRESS": {
      return {
        ...state,
        cLoading: true,
        containerCount: state.containerCount,
      };
    }
    case "CONTAINERLISTS_SUCCESS": {
      let containers = action.payload.data.data;
      return {
        ...state,
        cLoading: false,
        cErrorMessage: typeof containers === "string" ? containers : null,
        containers: typeof containers === "string" ? [] : containers,
        containerCount: action.payload.data.count,
        sizeType: state.sizeType,
        gateResponse: state.gateResponseData,
      };
    }

    case "CONTAINERLISTS_REJECTED": {
      return {
        ...state,
        cLoading: false,
        containers: [],
        cErrorMessage: action.payload.errorMessage,
        containerCount: 0,
      };
    }
    case "SET_CONTAINERLISTS_SUCCESS": {
      let containers = action.payload.data.data;
      return {
        ...state,
        cLoading: false,
        cErrorMessage: typeof containers === "string" ? containers : null,
        containers: typeof containers === "string" ? [] : containers,
        containerCount: action.payload.data.count,
      };
    }
    case "REFRESH_CONTAINER_SUCCESS": {
      return {
        ...state,
      };
    }
    case "ADDCONTAINER_TOGGLE": {
      return {
        ...state,
        cAddErrorMessage: null,
        cAddSuccessMessage: null,
        showAddContainerModal: action.payload,
      };
    }
    case "ADDCONTAINER_INPROGRESS": {
      return {
        ...state,
        cAddLoading: true,
        showAddContainerModal: true,
        cAddErrorMessage: null,
        cAddSuccessMessage: null,
      };
    }
    case "ADDCONTAINER_SUCCESS": {
      let containers = action.payload.data.data;
      let parseUser;
      containers = containers.filter((cc: any) => !!cc);
      const user = localStorage.getItem("loggedUser");
      if (user) {
        parseUser = JSON.parse(user);
      }
      return {
        ...state,
        // addContainerCount: parseUser?.terminal[0]?.code === 1 ? containers.length : 0,
        addContainerCount: containers.length,
        cAddLoading: false,
        cAddErrorMessage: null,
        cAddSuccessMessage: action.payload.data.message,
        containers: [...containers, ...state.containers],
        showAddContainerModal: false,
      };
    }

    case "ADDCONTAINER_REJECTED": {
      return {
        ...state,
        cAddLoading: false,
        addContainerCount: 0,
        showAddContainerModal: true,
        cAddErrorMessage: action.payload.errorMessage,
        cAddSuccessMessage: null,
      };
    }
    case "COUNTSTATUS_INPROGRESS": {
      return {
        ...state,
        countAddLoading: true,
      };
    }
    case "COUNTSTATUS_SUCCESS": {
      let countData = action.payload.data;
      return {
        ...state,
        countAddLoading: false,
        countData: countData,
      };
    }

    case "COUNTSTATUS_REJECTED": {
      return {
        ...state,
        countAddLoading: false,
      };
    }

    case "CONTAINERSTATE_RESET": {
      return {
        ...initialContainerValues,
      };
    }
    // case for selected container in import availability
    case "SELECTED_CONTAINER": {
      return {
        ...state,
        selectedContainer: action.payload.data,
      };
    }
    case "UPDATE_CONTAINER_INPROGRESS": {
      return {
        ...state,
        cLoading: true,
      };
    }
    case "UPDATE_CONTAINER_SUCCESS": {
      let container = action.payload.data.data;
      return {
        ...state,
        cLoading: false,
        selectedContainer: action.payload.data,
        containers: state.containers.map((cc: any) => (cc._id === container._id ? container : cc)),
      };
    }
    case "UPDATE_CONTAINER_REJECTED": {
      return {
        ...state,
        cLoading: false,
        errorMessage: action.payload.errorMessage,
      };
    }
    case "GET_ADDED_BY_TEAM_SUCCESS": {
      return {
        ...state,
        addedByTeam: action.payload.data.data,
      };
    }
    case "GET_ADDED_BY_TEAM_REJECTED": {
      return {
        ...state,
        errorMessage: action.payload.errorMessage,
      };
    }
    case "GET_YTI_SIZE_TYPE_INPROGRESS": {
      return {
        ...state,
        cLoading: false,
      };
    }
    case "GET_YTI_SIZE_TYPE_SUCCESS": {
      let sizeType = action.payload.data;
      const currentData = state.containers;
      if (sizeType?.length) {
        let containerID = sizeType.map((sT: any) => sT._id);
        containerID.map((cont_id: string) => {
          const findContainer = sizeType.find((ct: any) => ct._id === cont_id);
          const updateIndex = state.containers.findIndex((cntr: any) => cntr._id === cont_id);
          if (updateIndex !== -1) {
            // if exists updating that object from array or merging
            currentData[updateIndex] = {
              ...currentData[updateIndex],
              ...findContainer,
            };
          }
        });
      }
      return {
        ...state,
        cLoading: false,
        sizeType: sizeType,
        containers: currentData,
      };
    }
    case "GET_YTI_SIZE_TYPE_REJECTED": {
      return {
        ...state,
        cLoading: false,
      };
    }
    case "GET_EIR_DOCUMENT_INPROGRESS": {
      return {
        ...state,
      };
    }
    case "GET_EIR_DOCUMENT_SUCCESS": {
      let EIRDocument = action.payload.data;

      //value which are already in reducer should use state.
      const currentData = state.containers;
      const updateIndex = state.containers.findIndex((cntr: any) => cntr._id === EIRDocument._id);
      currentData[updateIndex] = EIRDocument;

      return {
        ...state,
        cLoading: false,
        containers: currentData,
      };
    }
    case "GET_EIR_DOCUMENT_REJECTED": {
      return {
        ...state,
      };
    }
    case "FETCH_GATE_INFO_INPROGRESS": {
      return {
        ...state,
        cLoading: false,
      };
    }
    case "FETCH_GATE_INFO_SUCCESS": {
      let gateResponseData = action.payload.data;
      let currentContainerData = state.containers;

      if (gateResponseData?.length) {
        let containerID = gateResponseData.map((gR: any) => gR?._id);

        containerID.map((cont_id: string) => {
          const findContainerByID = gateResponseData.find((ct: any) => ct._id === cont_id);
          const updateIndex = state.containers.findIndex((cntr: any) => cntr._id === cont_id);

          if (updateIndex !== -1) {
            currentContainerData[updateIndex] = {
              ...currentContainerData[updateIndex],
              ...findContainerByID,
            };
          }
        });
      }
      return {
        ...state,
        cLoading: false,
        containers: currentContainerData,
        gateResponse: gateResponseData,
      };
    }
    case "FETCH_GATE_INFO_REJECTED": {
      return {
        ...state,
        cLoading: false,
        gdLoading: true,
      };
    }
    case "GET_DATE_INPROGRESS": {
      return {
        ...state,
        cLoading: false,
      };
    }

    case "GET_DATE_SUCCESS": {
      // getting payload data which is in array
      let getdate = action.payload.data;

      // temporary storing containers reducer value in currentData for updating later
      const currentData = state.containers;
      if (getdate?.length) {
        // getting container id from those array which will be in array of string
        let containerID = getdate.map((gR: any) => gR._id);
        // mapping list of container ids in string to get single container at a time.
        containerID.map((cont_id: string) => {
          // finding or extracting that container updated data (getdate) which is in object arrays from payload
          const findContainer = getdate.find((ct: any) => ct._id === cont_id);
          // find that container index ie. (updateIndex) from containers reducer
          const updateIndex = state.containers.findIndex((cntr: any) => cntr._id === cont_id);
          // checking if that index exists or not
          if (updateIndex !== -1) {
            // if exists updating that object from array or merging
            currentData[updateIndex] = {
              ...currentData[updateIndex],
              ...findContainer,
            };
          }
        });
      }
      return {
        ...state,
        cLoading: false,
        containers: currentData,
        getdate: getdate,
        addContainerCount: 0,
      };
    }
    case "GET_DATE_REJECTED": {
      return {
        ...state,
        cLoading: false,
        addContainerCount: 0,
      };
    }

    case "GET_ARCHIVE_CONTAINER_INPROGRESS": {
      return {
        ...state,
        cLoading: false,
      };
    }

    case "GET_ARCHIVE_CONTAINER_SUCCESS": {
      let archiveContainers = action.payload.data;
      return {
        ...state,
        cLoading: false,
        hasAddedArchive: true,
        archiveContainers: archiveContainers,
      };
    }
    case "GET_TIMESLOTS_SUCCESS": {
      return {
        ...state,
        tLoading: false,
        timeSlots: action.payload.data?.slots,
        timeSlotError: action.payload.data?.error,
      };
    }
    case "GET_TIMESLOTS_INPROGRESS": {
      return {
        ...state,
        tLoading: true,
        timeSlots: null,
        timeSlotError: null,
      };
    }
    case "GET_TIMESLOTS_REJECTED": {
      return {
        ...state,
        tLoading: false,
        timeSlotError: null,
      };
    }

    case "GET_YMLUETA_INPROGRESS": {
      return {
        ...state,
        cLoading: false,
      };
    }

    case "GET_YMLUETA_SUCCESS": {
      let geteta = action.payload.data;
      const currentData = state.containers;
      if (geteta?.length) {
        let containerID = geteta.map((gR: any) => gR._id);
        containerID.map((cont_id: string) => {
          const findContainer = geteta.find((ct: any) => ct._id === cont_id);
          const updateIndex = state.containers.findIndex((cntr: any) => cntr._id === cont_id);
          if (updateIndex !== -1) {
            currentData[updateIndex] = {
              ...currentData[updateIndex],
              ...findContainer,
            };
          }
        });
      }
      return {
        ...state,
        cLoading: false,
        containers: currentData,
        geteta: geteta,
        addContainerCount: 0,
      };
    }
    case "GET_YMLUETA_REJECTED": {
      return {
        ...state,
        cLoading: false,
        addContainerCount: 0,
      };
    }
    case "DUPLICATE_CONTAINER_INPROGRESS": {
      return {
        ...state,
        cLoading: false,
        cAddErrorMessage: null,
      };
    }
    case "DUPLICATE_CONTAINER_SUCCESS": {
      let newContainer = state.containers;
      let filterduplicatecontainer = action.payload.data.data || [];
      if (filterduplicatecontainer?.length) {
        newContainer = state.containers.filter((cn: any) =>
          filterduplicatecontainer.some((fl: any) => fl.containerNo !== cn.containerNo)
        );
      }
      return {
        ...state,
        cLoading: false,
        containers: [...filterduplicatecontainer, ...newContainer],
        filterduplicatecontainer: filterduplicatecontainer,
      };
    }
    case "DUPLICATE_CONTAINER_REJECTED": {
      return {
        ...state,
        cLoading: false,
        cAddErrorMessage: action.payload.data,
      };
    }
    case "MISSING_DOCS_INPROGRESS": {
      return {
        ...state,
        mLoading: true,
        cAddErrorMessage: null,
      };
    }
    case "MISSING_DOCS_SUCCESS": {
      let missingdocs = action.payload.data.data;
      let containerID = missingdocs?._id;
      const currentData = state.containers;
      const updateIndex = state.containers.findIndex((cntr: any) => cntr._id === containerID);
      // console.log(updateIndex, "inddex");
      currentData[updateIndex] = missingdocs;
      // console.log(currentData[updateIndex], "update");

      return {
        ...state,
        mLoading: false,
        containers: currentData,
        missingdocs: missingdocs,
      };
    }
    case "MISSING_DOCS_REJECTED": {
      return {
        ...state,
        mLoading: false,
        cAddErrorMessage: action.payload.data,
      };
    }

    case "BOOK_APPOINTMENT_SUCCESS": {
      let pickupApptRes = action.payload.data?.data;
      let currentData = state.containers;
      let emptyApptRes = action.payload.data?.data[0]?.emptyAppt;

      if (pickupApptRes?.length) {
        let containerID = pickupApptRes.map((pD: any) => pD?._id);
        containerID.map((cont_id: any) => {
          const findContainerByID = pickupApptRes.find((ct: any) => ct._id === cont_id);
          const updateIndex = pickupApptRes.findIndex((ct: any) => ct._id === cont_id);

          if (updateIndex !== -1) {
            currentData[updateIndex] = {
              ...currentData[updateIndex],
              ...findContainerByID,
            };
          }
        });
      }
      return {
        ...state,
        pickApptData: action.payload.data?.data,
        emptyApptData: emptyApptRes,
        bLoading: false,
        containers: currentData,
        apptError: null,
        timeSlotError: null,
      };
    }
    case "BOOK_APPOINTMENT_INPROGRESS": {
      return {
        ...state,
        bLoading: true,
        apptError: null,
      };
    }
    case "BOOK_APPOINTMENT_REJECTED": {
      return {
        ...state,
        bLoading: false,
        apptError: "Booking Appointment Failed",
      };
    }
    default:
      return state;
  }
};
