import jwt_decode from "jwt-decode";

interface IJwtVals {
  foo: string;
  exp: number;
  iat: number;
}

// Check if user is Logged in
export const isLoggedIn = () => {
  if (localStorage.getItem("accessToken") && localStorage.getItem("loggedUser")) {
    if (localStorage.getItem("accessToken") === "undefined") {
      return false;
    } else {
      const token = localStorage.getItem("accessToken")?.substr(7);
      if (token) {
        const decoded: IJwtVals = jwt_decode(token);
        if (decoded.exp < Date.now() / 1000) {
          localStorage.clear();
          return false;
        }
        return true;
      }
    }
  }
  // return false for logged Out / signup/signin Url
  return false;
};

// Check if user has Right permission
export const isPermitted = (permit: Array<string>) => {
  let permited = true;
  // Permission  Logic Add here
  if (localStorage.getItem("loggedUser") && localStorage.getItem("permission")) {
  }
  return permited;
};

export const isTerminalChoosen = (terminal: number) => {
  let loggedUser = JSON.parse(localStorage.getItem("loggedUser") || "{}");
  if (loggedUser && loggedUser?.terminal.length > 0) {
    let found = loggedUser.terminal.find((tt: any) => tt.code === terminal);
    if (found) return true;
  } else return false;
};
