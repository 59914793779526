import { IconDownload } from "Common/Icons";
import { useAppContext } from "contexts/AppContext";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { getDetailedValue } from "utils/commonFunctions";
import moment from "moment";
// import { read, writeFileXLSX } from "xlsx";
import * as XLSX from "xlsx";

const EXCEL_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const EXCEL_EXTENSION = ".xlsx";

interface IProps {
  page: string;
  searchPost?: any;
  searchParams?: any;
}

const DownloadCsv = (props: IProps) => {
  const { state, myDispatch } = useAppContext();
  const { drayosSummary } = state.reportDRStates;

  const [downloading, setDownloading] = useState<any | null>(null);
  const onDownload = async () => {
    setDownloading(true);
    convertToCSV(drayosSummary, props.page);
    setDownloading(false);
  };
  return (
    <button disabled={downloading} className="btn btn-success" onClick={() => onDownload()}>
      {downloading && (
        <span
          className="spinner-border spinner-border-sm mr-2"
          role="status"
          aria-hidden="true"
        ></span>
      )}

      <IconDownload />
    </button>
  );
};

export default DownloadCsv;

const convertToCSV = (values: any[], page: string) => {
  let data = [];
  data = values.map((value: any, key: number) => {
    let csvVal: any = {};
    csvVal["S no"] = key + 1;
    csvVal.Port = value.port;
    csvVal["Mapped Status"] = value.status;
    csvVal["Drayos Status"] = JSON.stringify(value.OperationalStatus);
    csvVal["Missing Field"] = value.missing_fields;
    csvVal["Total Count"] = value.totalCount;
    csvVal["Success Count"] = value.successCount;
    csvVal["Missing Count"] = value.missingCount;
    csvVal["Return Location Details"] = JSON.stringify(value.Return_location);
    csvVal["Missing Containers Tracking Status"] = JSON.stringify(value.missing_Tracking_Status);
    csvVal["Success Containers Tracking Status"] = JSON.stringify(value.success_Tracking_Status);
    csvVal["Error Details"] = JSON.stringify(value.errorDetails);
    csvVal["Error Containers"] = JSON.stringify(value.errorContainers);
    return csvVal;
  });
  let fdata: Blob;
  const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
  const workbook: XLSX.WorkBook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
  const excelBuffer: any = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  fdata = new Blob([excelBuffer], {
    type: EXCEL_TYPE,
  });

  let csvURL = null;

  csvURL = window.URL.createObjectURL(fdata);
  let tempLink = document.createElement("a");
  tempLink.href = csvURL;
  tempLink.setAttribute("download", `${page + new Date().getTime() + EXCEL_EXTENSION}`);
  tempLink.click();
};
