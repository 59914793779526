import { CustomIconSpreadSheet } from 'Common/Components/Icons/CustomIcons'
import { SelectFieldWithoutControl } from 'Common/Components/Inputs/CustomSelectField'
import SubHeaderTab from 'Common/Components/SubHeaderTab'
import { IconCalendar, IconDownload, IconSendEmail } from 'Common/Icons'
import axios from 'axios'
import moment from 'moment'
import { getDuplicateArrayofObjects } from 'pages/EmptyReturn/Common/utils'
import { portList } from 'pages/EmptyReturn/GateHour/Constant'
import React, { useEffect, useState } from 'react'
import Datetime from 'react-datetime'


const UploadFolder = () => {
  const [file, setFile] = useState();
  const [selectTIR, setSelectedtTIR] = React.useState();
  const [selectPort, setSelectedPort] = React.useState();
  const [formState, setFormState] = useState({
    reference_number: '',
    containerNo: "",
    date:""
  })
  const PORT_CHOICES = portList.map((item) => ({ label: item.value, value: item.label }));
  const TIR = [
    { value: "TIR IN", label: "TIR IN" },
    { value: "TIR OUT", label: "TIR OUT" },
  ];

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      paddingTop: 50,
    },
    preview: {
      marginTop: 50,
      display: "flex",
      flexDirection: "column",
    },
    image: { maxWidth: "100%", maxHeight: 320 },
    delete: {
      cursor: "pointer",
      padding: 15,
      background: "red",
      color: "white",
      border: "none",
    },
  };


  const handleInput = (e) => {
    const inputName = e.target.name;
    const value = e.target.value;

    setFormState((prev) => {
      return {
        ...prev,
        [inputName]: value
      };
    });
  };

  // const handleDateChange = (dateValue) => {
  //   const ContainerDate = moment(dateValue);
  //   setDate(ContainerDate);
  // };

  const handlePortChange = (newport) => {
    setSelectedPort(newport.value);
  };

  const handleTIRChange = (newTIR) => {
    setSelectedtTIR(newTIR.value)
  }


  const [preview, setPreview] = useState()
  //file

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  let previewElement = null;
  if (file) {
    previewElement = (
      <object data={URL.createObjectURL(file)} type="application/pdf" width="100%" height="500px">
        <p>Sorry, the PDF could not be displayed.</p>
      </object>
    );
  }

  const payload = {
    reference_number: formState.reference_number,
    containerNo: formState.containerNo,
    selectTIR: selectTIR,
    selectPort: selectPort,
    date:formState.date,
  }

  const handleUploadClick = (e) => {
    e.preventDefault();
    let Ref = formState.reference_number
    console.log(Ref)
    let formData = new FormData();
    formData.append("file", file);
    formData.append('reference_number', payload.reference_number)
    formData.append('containerNo', payload.containerNo)
    formData.append('type', payload.selectTIR)
    formData.append('port', payload.selectPort)
    formData.append('date', payload.date)

    axios({
      url: `${process.env.REACT_APP_API_URL}api/uploader/tir`,
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "0",
        "Content-type": "application/json",
        Authorization: localStorage.getItem("accessToken"),
      },
      data: formData,
    })
  }

  const removeSelectedfile = () => {
    setFile();
  };

  return (
    <div className="page-wrapper"><div className="position-relative subheader-wrapper">
    <SubHeaderTab val="supports" />
        <div className="page-fluid">
          <div className="top-page">
            <div className="py-15 d-flex align-items-center">
              <h4>Tir Upload Section</h4>
            </div>
          </div>
          <div className="filter-row d-flex align-items-center">
            <input type="text"
              className="form-control w-10 mr-2"
              placeholder="Reference number"
              name="reference_number"
              onChange={handleInput}
            />

            <input type="text"
              className="form-control w-10 mr-2"
              placeholder="ContainerNo"
              name="containerNo"
              onChange={handleInput}
            />

            <SelectFieldWithoutControl
              className="w-200 mr-2"
              classNamePrefix="select"
              isLoading={false}
              isSearchable={true}
              name="port"
              options={PORT_CHOICES}
              onChange={(value) => handlePortChange(value)}
              size="small"
              placeholder='Select port'
              value={selectPort && PORT_CHOICES.find((gt) => gt.value === selectPort)}
            />

            <SelectFieldWithoutControl
              className="w-200 mr-2"
              classNamePrefix="select"
              isLoading={false}
              isSearchable={true}
              name="containerNo"
              options={TIR}
              onChange={(value) => handleTIRChange(value)}
              size="small"
              placeholder='Select Tir Type'
              value={selectTIR && TIR.find((gt) => gt.value === selectTIR)}

            />

            <input type="text"
              className="form-control w-10 mr-2"
              placeholder="MM/DD/YYYY HH:mm"
              name="date"
              onChange={handleInput}
            />

            {/* <div className="input-wrapper w-200 ml-2">
              <Datetime
                inputProps={{
                  placeholder: "Select Date",
                }}
                onChange={(value) => {
                  handleDateChange(value);
                }}
                value={date}
                closeOnSelect
              />
              <div className="input-icon">
                <IconCalendar />
              </div>
            </div> */}
          </div>
          <div className='d-flex card mt-10  w-100 py-20'>
            <div className='col-lg' style={{maxWidth: 250}}>
            <div class="custom-file">
              <input type="file" class="custom-file-input" id={file?.name} src={file} onChange={handleFileChange} accept='application/pdf'/>
              <label class="custom-file-label text-truncate" for={file?.name}>{file?.name? file.name: "Choose File"}</label>
            </div>
              {/* <input type="file" accept="application/pdf" src={file} onChange={handleFileChange} /> */}
              <div className='mt-10'>
                <button className='btn btn-primary' onClick={handleUploadClick}>Upload</button>
                <button className='btn btn-danger ml-3' onClick={removeSelectedfile}>Remove</button>
              </div>
            </div>
            <div className='col-lg'>
              {previewElement}
            </div>
          </div>
          
        </div>
      </div>
    </div>
  )
}



export default UploadFolder