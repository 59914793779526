import { api } from "config";
import { objToQueryParams, removeNullEmpty } from "utils/commonFunctions";

export const getAppointmentReport = (params?: any) => {
  let url = "api/dashboard/appointmentReport";
  let postData = removeNullEmpty(params);
  url = params ? url + "?" + objToQueryParams(postData) : url;
  return {
    type: "GET_APPOINTMENT_REPORT",
    payload: api.get(url),
  };
};

export const getAppointmentEnabledCarriers = () => {
  let url = "api/dashboard/prefetchAppointmentData";
  return {
    type: "GET_APPOINTMENT_ENABLED_CARRIER",
    payload: api.get(url),
  };
};

export const setAppointmentReportParams = (searchParams?: any) => {
  console.log("searchParams", searchParams);
  delete searchParams.reset;
  return {
    type: "SET_APPOINTMENT_REPORT_PARAMS",
    payload: { searchParams },
  };
};

export const resetAppointmentReportParams = () => {
  return {
    type: "RESET_APPOINTMENT_REPORT_PARAMS",
  };
};
