import * as yup from "yup";

export const loginSchema = yup
  .object({
    email: yup.string().email("Must be a valid email").max(255).required("Email is required"),
    password: yup.string().required("Password is required"),
  })
  .required();

export const signUpSchema = yup
  .object({
    // username: yup
    //   .string()
    //   .required("User Name is required")
    //   .matches(/^.*((?=.*[a-zA-Z0-9])).*$/, "Must Be Alpha Numeric"),
    email: yup.string().email("Must be a valid email").max(255).required("Email is required"),
    password: yup
      .string()
      .required("Password is required")
      .min(8, "Password must be at least 8 character long")
      .max(20, "Password must be at best 20 character long"),
    confirmPassword: yup.string().test("passwords-match", "Passwords must match", function (value) {
      return this.parent.password === value;
    }),
  })
  .required();

export const forgetSchema = yup
  .object({
    email: yup.string().email("Must be a valid email").max(255).required("Email is required"),
  })
  .required();

export const resetSchema = yup
  .object({
    password: yup
      .string()
      .required("Password is required")
      .min(8, "Password must be at least 8 character long")
      .max(20, "Password must be at best 20 character long"),
    confirmPassword: yup.string().test("passwords-match", "Passwords must match", function (value) {
      return this.parent.password === value;
    }),
  })
  .required();
