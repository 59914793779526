import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { IconAngleArrowRight } from "Common/Icons";
import { ITerminalTypes } from "pages/OnboardingModal/onboarding";
interface IProps {
  l: number;
  onCardClick: (terminal: ITerminalTypes) => void;
  terminal: ITerminalTypes;
  selectedTerminal: ITerminalTypes | undefined;
}

const CardView = (props: IProps): React.ReactElement => {
  const { terminal, l, onCardClick, selectedTerminal } = props;
  return (
    <Fragment>
      <div className={`col-xl col-md-${12 / l} col-sm-6 col-6 my-2`}>
        <div
          className={`ncard d-flex flex-column justify-content-between p-3 ${
            selectedTerminal && selectedTerminal._id === terminal._id ? "active" : ""
          }`}
          onClick={() => onCardClick(terminal)}
        >
          <div className="d-flex align-items-center mb-10">
            <div className="ml-2">
              <div className="font-medium mb-0 text-capitalize">{terminal.name.toLowerCase()}</div>
            </div>
            <div className="form-check ml-auto">
              <IconAngleArrowRight />
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between form-row mt-2">
            <div className="col-lg">
              <div className={`font-weight-bold font-20 `}>
                <span className="font-size-regular"></span>
              </div>
            </div>
            <div className="col-lg d-flex align-items-center justify-content-end">
              {/* <RadialChart percent={percent} /> */}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CardView;
