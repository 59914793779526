export const standAloneReportHeaders = [
  {
    label: "CONTAINERNO",
    value: "containerno",
    sort: "-",
  },
  {
    label: "REFERENCE_NUMBER",
    value: "reference_number",
    sort: "-",
  },
  {
    label: "SHIPPERNAME",
    value: "shippername",
    sort: "-",
  },
  {
    label: "CAUTIONCOUNT",
    value: "cautioncount",
    sort: "-",
  },
  {
    label: "ERRORMESSAGE",
    value: "errormessage",
    sort: "-",
  },
  {
    label: "LASTCONTAINERTRACEDDATE",
    value: "lastcontainertraceddate",
    sort: "-",
  },
  {
    label: "CARRIER",
    value: "carrier",
    sort: "-",
  },
  //   {
  //     "label": "SHIPPERINFO",
  //     "value": "shipperinfo",
  //     "sort": "-"
  //   }
];
