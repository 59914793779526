import { api } from "config";
import { SessionUpdateValues } from "./support";
import _ from "lodash";
import { objToQueryParams, removeNullEmpty } from "utils/commonFunctions";
import { prodApi } from "pages/DrayosSummaryReport/action";

export const setContainerCredentialsParams = (searchPost?: any, searchParams?: any) => {
  delete searchParams.reset;
  return {
    type: "SET_CONTAINERCREDENTIALS_PARAMS",
    payload: { searchPost, searchParams },
  };
};

export const resetSuccessfulContainersParams = (searchPost?: any, searchParams?: any) => {
  return {
    type: "RESET_SUCCESSFULCONTAINERS_PARAMS",
  };
};

export const getContainerCredentialsByPort = ({
  ports,
  time,
}: {
  ports: [string];
  time: string;
}) => {
  let url = `api/containerCredential/lastUpdated?ports=${ports}&time=${time}`;
  return {
    type: "CONTAINERCREDENTIALS",
    payload: api.get(url),
  };
};

export const updateContainerCredentials = (data: any) => {
  const url = "api/containerCredential/session";
  return {
    type: "UPDATE_CONTAINERCREDENTIALS",
    payload: api.put(url, data),
  };
};

export const addNewSession = (data: any) => {
  const url = "api/token/setNewSessions";
  return {
    type: "ADD_NEWSESSION",
    payload: api.post(url, data),
  };
};

export const testContainerCredentials = async (data: any) => {
  const url = "api/token/testContainerCredentials";
  return {
    type: "TEST_CONTAINERCREDENTIALS",
    payload: await api.post(url, data),
  };
};
export const getAlltokensByPort = (data?: any) => {
  return {
    type: "TOKENLIST",
    payload: api.post(`api/token/getAlltokensByPort`, data),
  };
};

export const updateTokensValue = (data: any) => {
  return {
    type: "UPDATETOKEN",
    payload: api.post(`api/token/updateTokensById`, data),
  };
};

export const applyTokenValue = (id: string) => {
  return {
    type: "UPDATETOKEN",
    payload: api.get(`api/token/applyTokenValueById?id=` + id),
  };
};

export const getRequiredCookiesByPort = (data?: any) => {
  return {
    type: "COOKIELIST",
    payload: api.post(`api/containerCredential/getCredentialForCookies`, data),
  };
};

export const updateCookies = (data?: any) => {
  return {
    type: "UPDATECOOKIES",
    payload: api.post(`api/container-credential/upsert-cookies`, data),
  };
};

export const getBlankIndex = (fileName?: string) => {
  let url = `api/scheduler/readBucket`;
  url = fileName ? url + "?" + "filename=" + fileName : url;
  // console.log("getBlankIndex action");
  // console.log(url);
  return {
    type: "GETBLANKINDEX",
    payload: api.get(url),
  };
};

export const updateBlankIndex = (fileName: string, data?: any) => {
  let url = `api/scheduler/updateBucket`;
  let formattedData = [
    data.blankIndex.split(" ").map(Number),
    JSON.stringify(data.sslValue.split(" ")),
  ];
  return {
    type: "UPDATEBLANKINDEX",
    payload: api.post(url, { filename: fileName, data: JSON.stringify(formattedData) }),
  };
};

export const updateLBCTCookies = (data: any) => {
  const url = "api/containerCredential/lbctAppointmentCookies";
  return {
    type: "UPDATELBCTCOOKIES",
    payload: api.post(url, data),
  };
};

export const getUploadedHTML = (fileName: string) => {
  const url = "api/scheduler/readBucket";
  return api.get(`${url}?filename=${fileName}`);
};

export const uploadVesselHTML = (data: any) => {
  const url = "api/scheduler/updateBucket";
  return api.post(url, data);
};

export const getPortConfig = (data: any) => {
  const url = "api/standard-port/get-port-config";
  return {
    type: "PORTCONFIG",
    payload: api.get(`${url}?port=${data}`),
  };
};

export const updateTerminals = (data: any) => {
  const url = "api/standard-port";

  return {
    type: "UPDATETERMINALS",
    payload: api.put(url, data, {
      headers: {
        "Content-Type": "application/json",
      },
    }),
  };
};
