import Loader from "Common/Components/Loader";
import SubHeaderTab from "Common/Components/SubHeaderTab";
import { useAppContext } from "contexts/AppContext";
import { useEffect, useState } from "react";
import { Fragment } from "react";
import { ProgressBar } from "react-bootstrap";
import SearchFilter from "./Components/SearchFilter";
import axios from "axios";
import { resolve4 } from "dns";
import {
  getBaseLineSnapShotReport,
  getStandardRawPorts,
} from "pages/DrayosStandAloneReport/action";
import { baseLineSnapShotReportHeader } from "./constant";

const DrayosStandAloneReportSnapShot = () => {
  const initialParams = {
    startDate: null,
    endDate: null,
    portCode: null,
  };
  const { state, myDispatch } = useAppContext();
  const [params, setParams] = useState<any>(initialParams);
  const { loading, standardPorts, baseLineSnapshotReport } = state.drayosReportStates;
  const [SnapShotReport, setSnapShotReport] = useState<any>([]);

  useEffect(() => {
    if (baseLineSnapshotReport) {
      setSnapShotReport(baseLineSnapshotReport);
    }
  }, [params, myDispatch]);

  useEffect(() => {
    if (params.startDate && params.endDate && params.portCode) {
      myDispatch(getBaseLineSnapShotReport(params));
    }
  }, [standardPorts, params.startDate, params.endDate]);

  useEffect(() => {
    myDispatch(getStandardRawPorts());
  }, [params]);

  return (
    <div className="page-wrapper">
      <div className="position-relative subheader-wrapper">
        {loading && <Loader />}
        <SubHeaderTab val="reports" />
        <div className="page-fluid">
          <div className="top-page">
            <div className="pb-15 d-flex align-items-center">
              <h4 className="mb-0">Drayos StandAlone Report Snapshot</h4>
            </div>
          </div>
        </div>
        <SearchFilter setParams={setParams} />
        {params.startDate && params.endDate && params.portCode && SnapShotReport.length > 0 && (
          <Fragment>
            <div className="table-responsive" style={{ height: "calc(100vh - 255px)" }}>
              <table className="table table-card">
                <thead>
                  <tr>
                    <th scope="col" className="">
                      SNo.
                    </th>
                    {baseLineSnapShotReportHeader.map((item: any, i: number) => {
                      return <th key={i}>{item.label}</th>;
                    })}
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(SnapShotReport) &&
                    SnapShotReport?.length > 0 &&
                    SnapShotReport?.map((element: any, i: number) => {
                      return (
                        <Fragment key={i}>
                          <tr key={i}>
                            <td>
                              <div className="d-flex align-items-center justify-content-between">
                                <span className="mr-1">{i + 1}</span>
                                <div className="form-check"></div>
                              </div>
                            </td>
                            <td>{element.total_to_track}</td>
                            <td>{element.totalTracked}</td>
                            <td>{element.successFullyTracked}</td>
                            <td>{element.totalError}</td>
                            <td>{element.noDataError}</td>
                            <td>{element.loginError}</td>
                            <td>{element.failedScrapeError}</td>
                            <td>{element.successRatio}</td>
                            <td>{element.portCode}</td>
                            <td>{element.createdAt}</td>
                            <td>{element.updatedAt}</td>
                          </tr>
                        </Fragment>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </Fragment>
        )}
        {!params.startDate && !params.endDate && !params.portCode && (
          <p style={{ padding: 10 }}>please select all the field</p>
        )}

        {params.startDate && params.endDate && params.portCode && !loading && (
          <p style={{ padding: 10 }}>Information Not found</p>
        )}

        {!SnapShotReport.success && <p style={{ padding: 10 }}>{SnapShotReport.errorMessage}</p>}
      </div>
    </div>
  );
};

export default DrayosStandAloneReportSnapShot;
