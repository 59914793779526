const SearchFilter = () => {
  return (
    <div className="filter-row d-flex align-items-center">
      {/* Search */}
      <div className="app-search header-search">
        <span className="search-icon"> </span>
        <div className="position-relative">
          <input
            type="search"
            className="form-control input-search-left"
            placeholder="Search ..."
          />
        </div>
      </div>
    </div>
  );
};

export default SearchFilter;
