import React, { useState } from "react";
import { IconFilter } from "../../../Common/Components/Icons/Icons";

const BookingFilterRow = ({}) => {
  return (
    <React.Fragment>
      <div className="filter-row d-flex align-items-center">
        <div className="app-search header-search">
          <span className="search-icon"> </span>
          <div className="position-relative">
            <input
              type="search"
              className="form-control input-search-left"
              placeholder="Search..."
              value=""
            />
          </div>
        </div>

        <div className="ml-auto d-flex">
          <button className="btn btn-outline-light ">
            <IconFilter className="mr-2" />
            Filter
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default BookingFilterRow;
