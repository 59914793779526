import { useEffect, useState } from "react";
import SubHeaderTab from "Common/Components/SubHeaderTab";
// import SearchFilter from "../Common/SearchFilter";
import { useAppContext } from "contexts/AppContext";
import Loader from "Common/Components/Loader";
import moment from "moment";
import EditInputForm from "./EditInputForm";
import ReadOnlyInputForm from "./ReadOnlyInputForm";
import { getBlankIndex, updateBlankIndex } from "../action";
import { api } from "config";
import Toastr from "Common/Components/Toastr";
// interface InputValueInterface {
//   blankindex: string;
//   sslValue: string;
// }

const TTIBlankIndexUpdater = () => {
  const ttiWebSiteUrl = "https://www.ttilgb.com/main/index.do";
  const { myDispatch, state } = useAppContext();
  const { stLoading, tLoading, tErrorMessage, ttiBlankIndexUpdater } = state.supportStates;
  const [editMode, setEditMode] = useState(false);
  const [inputValue, setInputValue] = useState<any>({
    blankIndex: "",
    sslValue: "",
  });
  const [updateSuccess, setUpdateSuccess] = useState(false);
  //s3 bucketFile Name
  const fileName = "blankIndex.json";

  //when page is loaded function is run
  useEffect(() => {
    let ignore = false;
    if (!ignore) {
      myDispatch(getBlankIndex(fileName));
    }
    return () => {
      ignore = true;
    };
    // };
  }, []);

  const editClickHandler = () => {
    setEditMode(true);
    if (inputValue.sslValue === "" && inputValue.blankIndex === "") {
      setInputValue((prev: any) => ({
        ...prev,
        blankIndex: ttiBlankIndexUpdater?.blankIndex,
        sslValue: ttiBlankIndexUpdater?.sslValue,
      }));
    }
  };

  const submitClickHandler = async (e: any) => {
    e?.preventDefault();
    setEditMode(false);

    if (inputValue.sslValue !== "" && inputValue.blankIndex !== "") {
      const response = updateBlankIndex(fileName, inputValue);
      myDispatch(response);
      response.payload.then(async (res) => {
        setUpdateSuccess(true);
        // console.log(updateSuccess,ttiBlankIndexUpdater,"inside payload response");
      });
    }

    //for Toaster set to flase every 2 sec
    setTimeout(() => {
      setUpdateSuccess(false);
    }, 2000);
  };

  const saveClickHandler = (e: any) => {
    e?.preventDefault();
    setEditMode(false);
  };

  const inputChangeHandler = (e: any, toEditField: string) => {
    let inputFieldValue = { ...inputValue };
    inputFieldValue[toEditField] = e?.target?.value;
    setInputValue(inputFieldValue);
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="position-relative subheader-wrapper">
          <SubHeaderTab val={"supports"} />
          {updateSuccess && ttiBlankIndexUpdater.success && (
            <Toastr show={true} status="success" message={"Blank Index Updated SuccessFully"} />
          )}

          <div className="page-fluid">
            <div className="top-page">
              <div className="pb-15 d-flex align-items-center">
                <h4 className="mb-0">TTI Blank Index Updater </h4>
              </div>
              {editMode ? (
                <EditInputForm
                  inputChangeHandler={inputChangeHandler}
                  saveClickHandler={saveClickHandler}
                  inputValue={inputValue}
                />
              ) : (
                <ReadOnlyInputForm
                  submitClickHandler={submitClickHandler}
                  editClickHandler={editClickHandler}
                  inputValue={inputValue}
                  ttiBlankIndexUpdater={ttiBlankIndexUpdater}
                />
              )}

              <div className="img-wrapper p-30 bg-white rounded-6 mt-15 position-relative">
                <embed
                  src={ttiWebSiteUrl}
                  width="100%"
                  className=""
                  height="600px"
                  type="text/html"
                  title="TTI website"
                ></embed>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TTIBlankIndexUpdater;
