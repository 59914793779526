import { useAppContext } from "contexts/AppContext";
import _ from "lodash";
import React, { useCallback, useState } from "react";
import { setArchiveContainersParams } from "../action";

const FilterSearch = () => {
  const { state, myDispatch } = useAppContext();
  const { searchParams, searchPost } = state.archiveContainerStates;
  //   On Search
  const onChangeInput = (event: any) => {
    let val = event.target.value;
    const archiveContainerNo = val?.replace(/[^A-Z0-9]+/gi, "");

    let searchValue = { ...searchPost, containerNo: archiveContainerNo };
    myDispatch(setArchiveContainersParams(searchValue, searchParams));
  };
  const debouncedChangeHandler = useCallback(_.debounce(onChangeInput, 1000), []);
  return (
    <React.Fragment>
      <div className="filter-row d-flex align-items-center">
        <div className="app-search header-search">
          <span className="search-icon"> </span>
          <div className="position-relative">
            <input
              type="search"
              className="form-control input-search-left"
              placeholder="Search..."
              defaultValue={""}
              onChange={debouncedChangeHandler}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default FilterSearch;
