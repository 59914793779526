import { IconPrinter, IconTimes, IconTrash } from "Common/Icons";
import React from "react";
import { Modal } from "react-bootstrap";
import DetailTab from "./DetailTab";

interface IProps {
  values: any;
  onCancel: () => void;
}

const ContainerDetails = (props: IProps) => {
  const { values, onCancel } = props;

  return (
    <Modal show={values && values.containerNo} centered size="xl">
      <div className="bg-gray-50 h-100 rounded-5">
        <Modal.Title className="d-flex align-items-center px-20 py-15">
          <h5 className="font-20 mb-0">{values && values.containerNo}</h5>
          <div className="d-flex ml-auto">
            <button className="btn btn-white shadow-none" onClick={onCancel}>
              <IconTimes className="mr-2" />
              Close
            </button>
          </div>
        </Modal.Title>
        {values && values.containerNo && (
          <Modal.Body className="p-20 pt-0">
            <DetailTab details={values} />
          </Modal.Body>
        )}
      </div>
    </Modal>
  );
};

export default ContainerDetails;
