import SubHeaderTab from "Common/Components/SubHeaderTab";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Toastr from "Common/Components/Toastr";
import { useAppContext } from "contexts/AppContext";
import { assignPortCred, getAllToken, getUpdateToken } from "reducers/action/appAction";
import TokenFieldArray from "./TokenFieldArray";

const UpdateToken = () => {
  const { state, myDispatch } = useAppContext();
  const { tokens } = state.configStates;
  // console.log(tokens, "index token");

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [showToastr, setShowToastr] = useState<boolean>(false);
  let [defaultValues, setDefaultValues] = useState<any>([]);

  const {
    register,
    control,
    handleSubmit,
    getValues,
    formState: { errors },
    reset,
    setValue,
  } = useForm();

  useEffect(() => {
    let DefaultValues = { tokens: tokens };
    reset(DefaultValues);
  }, [tokens]);

  const onSubmit = (data: any) => {
    setIsSaving(true);
    let response;
    let allSave: any[] = [];
    data.tokens.map((tt: any) => {
      let postdata: any = {
        code: tt.code ? tt.code : "",
        token: tt.token ? tt.token : "",
      };
      response = getUpdateToken(postdata);
      allSave.push(myDispatch(response));
      response.payload.then().finally(() => {
        setIsSaving(false);
        setShowToastr(true);
      });
    });
  };

  //fetch Token
  useEffect(() => {
    getToken();
  }, []);

  const getToken = async () => {
    const response = await getAllToken();
    myDispatch(response);
  };

  return (
    <div className="page-wrapper">
      <div className="position-relative subheader-wrapper">
        <SubHeaderTab val="settingsItems" />
      </div>
      {showToastr && (
        <Toastr show={showToastr} status="success" message="Updated Token Successfully" />
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="page-fluid mt-20">
          <h3 className="mb-20">Update Token</h3>
          <TokenFieldArray {...{ register, control, defaultValues, getValues, setValue, errors }} />
          <div className="mt-3 text-right">
            <button type="submit" className="btn btn-primary">
              {isSaving && (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
              Update
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default UpdateToken;
