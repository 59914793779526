import React, { useEffect, useState } from "react";
import {
  IconAngleArrowRight,
  IconAngleArrowTop,
  IconCheck,
  IconDual,
  IconTimes,
  IconWarning,
} from "Common/Components/Icons/Icons";
import { DataTypesI, EmptiesTypes, LaPropsI } from "../location";
import sslFile from "../sslFile.json";
import { SelectFieldWithoutControl } from "Common/Components/Inputs/CustomSelectField";
import { LA_STATUS_OPTIONS, NY_STATUS_OPTIONS } from "pages/EmptyCorrection/constant";
import { getEmptyCorrections } from "../action";
import { useAppContext } from "contexts/AppContext";
import moment from "moment";

const LAEmptyTable = (props: any) => {
  const { data, emptyData, shift, portsArray, containerTypes, terminal } = props;
  const { state, myDispatch } = useAppContext();
  // ---------------------------------------------------- STATE MANAGEMENT---------------------------------------------------//
  const [emptyReturnsInfo, setEmptyReturnsInfo] = useState<string[]>(sslFile.shipping_lines);
  const [isShown, setIsShown] = useState<any>({});
  const [status, setStatus] = useState<any>({});
  const [responseStatus, setResponseStatus] = useState<any>({});
  const todayDate = moment().format("");

  // ---------------------------------------------------- HANDLE FUNCTIONS ---------------------------------------------------//
  const showEmptyReturnsInfo = (ssl: string) => {
    if (emptyReturnsInfo.includes(ssl)) {
      const temp = emptyReturnsInfo.filter((fil) => fil !== ssl);
      setEmptyReturnsInfo([...temp]);
    } else {
      const temp = emptyReturnsInfo;
      temp.push(ssl);
      setEmptyReturnsInfo([...temp]);
    }
  };
  const checkStatus = (str: any) => {
    const value = str.toUpperCase();
    if (value.includes("NO") || value.includes("CLOSED") || value.includes("RESTRICTED")) {
      return "NO";
    } else if (value.includes("DUAL") && value.includes("YES")) {
      return "YES";
    } else if (value.includes("DUAL")) {
      return "DUAL";
    } else {
      return "YES";
    }
  };

  const filterEmptyReturn = (empty: any, types: any) => {
    let sslData: any;
    const find = emptyData?.find((fil: EmptiesTypes) => fil?.key === empty?.label);
    sslData = find?.data?.data?.find(
      (ss: DataTypesI) => ss.ssl === data && ss.types === types.label
    );

    return sslData;
  };

  const EmptiesIcon = (text: string) => {
    switch (text.trim()) {
      case "OPEN":
        return "success";
      case "RESTRICTED":
        return "danger";
      case "CONTACT SSL":
        return "secondary";
      case "DUAL":
        return "warning";
      default:
        break;
    }
  };

  const sslShiftData = (shiftType: string, sslData: any) => {
    switch (shiftType) {
      case "shift1":
        return sslData.shift1;
      case "shift2":
        return sslData.shift2;
      case "shift3":
        return sslData.shift3;
      default:
        break;
    }
  };

  const handleClick = (activeCell: any, portIndex: any) => {
    setIsShown({ activeCell: activeCell, portIndex: portIndex });
  };
  const handleStatusChange = (
    statusVal: any,
    pp: any,
    types: any,
    date: any,
    selectedTerminal: any,
    ssldata: any,
    shifts: any
  ) => {
    setStatus(statusVal);
    const payloaddata = {
      portMarket: selectedTerminal,
      port: pp.label,
      date: date,
      ssl: ssldata,
      types: types.label,
      [shift]: statusVal.value,
      remarks: "",
      emptyReturnLocation: "",
    };
    const response = getEmptyCorrections(payloaddata);
    console.log(response, "response");

    myDispatch(response);
    response.payload.then((resp) => {
      setResponseStatus(resp.data.data);
    });
  };

  return (
    <>
      <tbody>
        <tr>
          <td colSpan={100}>
            <div className="d-flex align-items-center">
              <span className="font-14 text-dark font-weight-500">{data}</span>
              <button className="btn btn-link" onClick={() => showEmptyReturnsInfo(data)}>
                {emptyReturnsInfo.includes(data) ? <IconAngleArrowTop /> : <IconAngleArrowRight />}
              </button>
            </div>
          </td>
        </tr>
        {emptyReturnsInfo.includes(data) && (
          <>
            {containerTypes.map((types: any, index: number) => {
              return (
                <tr key={index}>
                  {types.checked && (
                    <React.Fragment>
                      <td width="100"> {types.label} </td>
                      {portsArray.map((pp: any, pIndex: any) => {
                        if (pp.checked) {
                          // const find = emptyData?.find(
                          //   (fil: EmptiesTypes) => fil?.key === pp.label
                          // );
                          // const sslData: any = find?.data?.data?.find(
                          //   (ss: DataTypesI) => ss.ssl === data && ss.types === types.label
                          // );
                          let sslData = filterEmptyReturn(pp, types);
                          if (
                            sslData?.port === responseStatus?.port &&
                            sslData?.types === responseStatus?.types &&
                            sslData?.ssl === responseStatus?.ssl
                          ) {
                            sslData = responseStatus?.shift1
                              ? { ...sslData, shift1: responseStatus?.shift1 }
                              : sslData;
                            sslData = responseStatus?.shift2
                              ? { ...sslData, shift2: responseStatus?.shift2 }
                              : sslData;
                          }
                          return sslData && sslData[shift] ? (
                            <td key={pIndex} className="text-center">
                              <div className="d-flex flex-column align-items-center justify-content-center">
                                <span
                                  className={`pointer text-${EmptiesIcon(
                                    sslShiftData(shift, sslData)
                                  )}`}
                                  onClick={() => {
                                    handleClick(index, pIndex);
                                  }}
                                >
                                  {sslShiftData(shift, sslData)}
                                </span>
                                {isShown.activeCell === index && isShown.portIndex === pIndex && (
                                  <div className="mt-2">
                                    <SelectFieldWithoutControl
                                      className="min-200"
                                      classNamePrefix="select"
                                      isLoading={false}
                                      isClearable={false}
                                      isSearchable={false}
                                      name="status"
                                      options={
                                        terminal === "LA" ? LA_STATUS_OPTIONS : NY_STATUS_OPTIONS
                                      }
                                      size="small"
                                      placeholder="Select Status"
                                      value={
                                        terminal === "LA"
                                          ? status &&
                                            LA_STATUS_OPTIONS.find(
                                              (sta: any) => sta.status === status
                                            )
                                          : status &&
                                            NY_STATUS_OPTIONS.find(
                                              (sta: any) => sta.status === status
                                            )
                                      }
                                      onChange={(value: any) =>
                                        handleStatusChange(
                                          value,
                                          pp,
                                          types,
                                          todayDate,
                                          terminal,
                                          data,
                                          shift
                                        )
                                      }
                                    />
                                  </div>
                                )}
                                {/* {checkStatus(sslData[shift]) === "DUAL" ? (
                                  <IconDual className="text-primary wh-10px text-warning" />
                                ) : checkStatus(sslData[shift]) === "YES" ? (
                                  <IconCheck className="text-primary wh-20px" />
                                ) : (
                                  <IconTimes className="text-error-500 wh-20px" />
                                )} */}

                                {sslData?.remarks?.length > 1 ? (
                                  <div className="position-relative tooltip-label">
                                    <IconWarning className="text-primary wh-10px text-warning ml-1" />

                                    <div
                                      className="tooltip tooltip--gray-700 mw-200"
                                      data-placement="top-center"
                                    >
                                      {sslData.remarks}
                                      <span className="arrow"></span>
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            </td>
                          ) : (
                            <td key={pIndex} className="text-center"></td>
                          );
                        }
                      })}
                    </React.Fragment>
                  )}
                </tr>
              );
            })}
          </>
        )}
      </tbody>
    </>
  );
};
export default LAEmptyTable;
