import jwt_decode from "jwt-decode";
import CustomDateField from "Common/Components/Inputs/CustomDateField";
import CustomInputField from "Common/Components/Inputs/CustomInputField";
import CustomSelectField from "Common/Components/Inputs/CustomSelectField";
import CustomTextAreaField from "Common/Components/Inputs/CustomTextAreaField";
import Loader from "Common/Components/Loader";
import { useAppContext } from "contexts/AppContext";
import moment from "moment";
import { updateContainerById } from "pages/Importavailability/action";
import { statusOpts, holds, broker } from "pages/Importavailability/constant";
import { Fragment, useEffect } from "react";
import { useForm } from "react-hook-form";
import { applyTokenValue, updateTokensValue } from "../action";
import { getTokenColor } from "utils/commonFunctions";

interface SelectedTokenTypes {
  _id: any;
  password: string;
  userName: string;
  portCode: string;
  tokenValue: string;
  expiresAt: string;
  isDisable: boolean;
}
interface IJwtVals {
  foo: string;
  exp: number;
  iat: number;
}

const TokenUpdateForm = (props: any) => {
  const { index, val, selectedPort } = props;
  const { state, myDispatch } = useAppContext();
  const { ports, containerOwners, containerSizes, containerTypes } = state.configStates;

  const { stLoading, tErrorMessage, tokenList } = state.supportStates;

  // REACT HOOK FORM
  const {
    setValue,
    formState: { errors },
    register,
    control,
    reset,
    handleSubmit,
  } = useForm<SelectedTokenTypes>({
    shouldUnregister: false,
    defaultValues: {
      ...val,
      portCode: val?.portCode,
    },
    mode: "onBlur",
  });

  useEffect(() => {
    reset({
      ...val,
      portCode: val?.portCode,
    });
  }, [val]);

  const onFormSubmit = async (data: SelectedTokenTypes) => {
    const finalData = {
      ...data,
    };
    if (
      [
        "VIGNIT",
        "TRAPAC",
        "HLT",
        "GCT NY",
        "EVERGLADES",
        "VIGNIT_EMPTIES",
        "EMODAL NY",
        "EMODAL LA",
      ].includes(data.portCode)
    ) {
      finalData.expiresAt = moment().add(24, "hours").toISOString();
    }
    if (finalData.tokenValue) {
      finalData.tokenValue = finalData.tokenValue.trim();
    }
    // console.log(finalData.expiresAt);
    myDispatch(updateTokensValue(finalData));
  };

  const getBgColor = () => {
    if (
      val.tokenValue &&
      val.tokenValue != "" &&
      val.tokenValue.length > 20 &&
      val.expiresAt &&
      [
        "VIGNIT",
        "TRAPAC",
        "HLT",
        "GCT NY",
        "EVERGLADES",
        "VIGNIT_EMPTIES",
        "EMODAL NY",
        "EMODAL LA",
        "ITS TERMINAL",
      ].includes(val.portCode)
    ) {
      return getTokenColor(val);
    } else return "";
  };

  return (
    <Fragment>
      <form id={"hook-form" + index + val._id} onSubmit={handleSubmit(onFormSubmit)}>
        <div className="form-row-md mb-10">
          <div className="form-group mb-0 col-md-1">{index + 1}</div>
          {/* <input type="hidden" {...register(`isDisable`)} /> */}
          <div className="form-group mb-0 col-md">
            <CustomInputField
              inputFieldOnly
              placeholder="Enter portCode"
              name="portCode"
              type="text"
              register={register}
              className="form-control"
              readOnly={true}
            />
          </div>
          <div className="form-group mb-0 col-md">
            <CustomInputField
              inputFieldOnly
              placeholder="Enter UserName"
              name="userName"
              type="text"
              register={register}
              className="form-control"
              readOnly={!val.isNew}
            />
          </div>
          <div className="form-group mb-0 col-md">
            <CustomInputField
              inputFieldOnly
              placeholder="Enter Password"
              name="password"
              type="text"
              register={register}
              className="form-control"
              readOnly={!val.isNew}
            />
          </div>
          <div className="form-group mb-0 col-md">
            <CustomInputField
              inputFieldOnly
              placeholder="Expires at"
              name="expiresAt"
              type="text"
              register={register}
              className={`form-control ${getBgColor()}`}
            />
          </div>
        </div>
        <div className="form-row-md mb-10">
          <div className="form-group mb-0 col-md">
            <CustomTextAreaField
              placeholder="Enter Token"
              name="tokenValue"
              register={register}
              className="form-control"
              rows={5}
              dense={false}
            />
          </div>
        </div>
        {selectedPort.includes("EVERPORT") && (
          <div className="form-row-md mb-10">
            <div className="form-group mb-0 col-md">
              <CustomInputField
                inputFieldOnly
                placeholder="Enter skValue"
                name="skValue"
                type="text"
                register={register}
                className="form-control"
              />
            </div>
          </div>
        )}
        {val?.portCode && ["EMODAL NY", "EMODAL LA", "VIGNIT_EMPTIES"].includes(val.portCode) && (
          <div className="form-row-md mb-10">
            <div className="form-group mb-0 col-md">
              <CustomTextAreaField
                placeholder="Enter CookieVal"
                name="cookieValue"
                register={register}
                className="form-control"
                rows={5}
                dense={false}
              />
            </div>
          </div>
        )}
        <div className="form-row-md mb-10">
          <div className="form-group mb-0 col-md">
            <strong>Disable:</strong>{" "}
            <input type="checkbox" {...register("isDisable")} className="mx-3" />
            {!["EMODAL LA", "EMODAL NY", "VIGNIT_EMPTIES"].includes(val?.portCode) && (
              <button
                type="button"
                className={`btn float-right mb-15 ml-10 ${
                  stLoading ? "btn-disabled" : "btn-warning "
                }`}
                onClick={() => myDispatch(applyTokenValue(val._id))}
              >
                Apply Token
              </button>
            )}
            <button
              type="submit"
              className={`btn float-right mb-15 ${stLoading ? "btn-disabled" : "btn-success "}`}
            >
              {stLoading && (
                <span
                  className="spinner-border spinner-border-sm mr-2"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
              Update
            </button>
          </div>
        </div>
      </form>
      <hr />
    </Fragment>
  );
};

export default TokenUpdateForm;
