import moment from "moment";
import { getDetailedValue, showDate } from "utils/commonFunctions";

export default class ContainerUtils {
  container: any;

  containerSizes: any;

  containerTypes: any;

  sizeType: any;

  userSelectedTerminals: any;

  timeZone: string | null;

  constructor(
    container: any,
    containerSizes: any,
    containerTypes: any,
    sizeType: any,
    userSelectedTerminals: any
  ) {
    this.container = container;
    this.containerSizes = containerSizes;
    this.containerTypes = containerTypes;
    this.sizeType = sizeType;
    this.userSelectedTerminals = userSelectedTerminals;
    this.timeZone = localStorage.getItem("timeZone");
  }

  // get Size type Function
  getSizeType = (containerSize: any, containerType: any) => {
    let sizeVal = getDetailedValue(containerSize, this.containerSizes);
    let typeVal = getDetailedValue(containerType, this.containerTypes);
    return `${sizeVal} ${sizeVal && typeVal && "-"} ${typeVal}`;
  };

  // get YTI Size and Type
  getYTISizeType = (containerNo: string) => {
    if (this.sizeType.length > 0) {
      const containerSizeList = this.sizeType?.find((sT: any) => sT?.containerNo === containerNo);
      const containerTypeList = this.sizeType?.find((sT: any) => sT?.containerNo === containerNo);
      const sizeVal = getDetailedValue(containerSizeList?.containerSize, this.containerSizes);
      const typeVal = getDetailedValue(containerTypeList?.containerType, this.containerTypes);
      return `${sizeVal} - ${typeVal}`;
    }
  };

  getContainerSizeType(addContainerCount: number, i: number) {
    if (
      !this.container.containerSize &&
      !this.container.containerType &&
      this.userSelectedTerminals.length &&
      i < addContainerCount
    ) {
      return <div className=" react-loading-skeleton " />;
    } else if (this.container.containerSize && this.container.containerType) {
      return this.getSizeType(this.container.containerSize, this.container.containerType);
    } else if (this.container.sslContainerType && this.container.sslContainerSize) {
      return this.getSizeType(this.container.sslContainerSize, this.container.sslContainerType);
    } else {
      this.getYTISizeType(this.container.containerNo);
    }
  }

  getContainerEta(addContainerCount: number, i: number) {
    if (!this.container.eta && this.userSelectedTerminals.length && i < addContainerCount) {
      return <div className=" react-loading-skeleton " />;
    } else if (showDate(this.container.status, "eta") && this.container.eta) {
      return moment(this.container.eta).format("MM/DD");
    } else if (showDate(this.container.status, "eta") && this.container.sslEta) {
      return moment(this.container.sslEta).format("MM/DD");
    } else if (showDate(this.container.status, "eta") && this.container.sslVesselEta) {
      return moment(this.container.sslVesselEta).format("MM/DD");
    } else {
      return "";
    }
  }

  getContainerDischargeDate(addContainerCount: number, i: number) {
    if (
      this.timeZone &&
      showDate(this.container.status, "dischargeDate") &&
      !this.container.dischargeDate &&
      this.userSelectedTerminals.length &&
      i < addContainerCount
    ) {
      return <div className=" react-loading-skeleton " />;
    } else if (showDate(this.container.status, "dischargeDate") && this.container.dischargeDate) {
      return (
        this.timeZone && moment(this.container.dischargeDate).tz(this.timeZone).format("MM/DD")
      );
    } else if (
      showDate(this.container.status, "dischargeDate") &&
      this.container.sslDischargeDate &&
      this.timeZone
    ) {
      return moment(this.container.sslDischargeDate).tz(this.timeZone).format("MM/DD");
    } else {
      return "";
    }
  }

  getContainerTerminatedDate(addContainerCount: number, i: number) {
    if (
      showDate(this.container.status, "terminatedDate") &&
      this.container.terminatedDate &&
      this.timeZone
    ) {
      return moment(this.container.terminatedDate).tz(this.timeZone).format("MM/DD HH:mm");
    } else if (
      showDate(this.container.status, "terminatedDate") &&
      this.container.inGate?.date &&
      this.timeZone
    ) {
      return moment(this.container.inGate?.date).tz(this.timeZone).format("MM/DD HH:mm");
    } else {
      return "";
    }
  }

  getContainerVesselName(addContainerCount: number, i: number) {
    if (!this.container.vesselName && this.userSelectedTerminals.length && i < addContainerCount) {
      return <div className=" react-loading-skeleton " />;
    } else if (this.container.vesselName) {
      return this.container.vesselName;
    } else {
      return this.container.sslVesselName;
    }
  }

  getContainerVoyageName(addContainerCount: number, i: number) {
    if (!this.container.voyageName && this.userSelectedTerminals.length && i < addContainerCount) {
      return <div className=" react-loading-skeleton " />;
    } else if (this.container.voyageName) {
      return this.container.voyageName;
    } else {
      return this.container.sslVoyageName;
    }
  }
}
