import { api } from "config";

export const getAppointmentSlotReport = (params?: any) => {
  const { appointmentId, startTime, endTime } = params;
  const url = "api/appointments-system/history-slot";
  const payload = {
    appointmentId,
    startTime,
    endTime,
  };

  return {
    type: "GET_APPOINTMENT_SLOT_REPORT",
    payload: api.post(url, payload),
  };
};
