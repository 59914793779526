import React, { Component, Fragment, KeyboardEventHandler } from "react";
import { Controller, FieldError } from "react-hook-form";
import CreatableSelect from "react-select/creatable";

export interface SelectPropsI {
  isClearable?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  isRtl?: boolean;
  isSearchable?: boolean;
  isMulti?: boolean;
  className?: string;
  classNamePrefix?: string;
  defaultValue?: Object;
  name: any;
  options: any;
  size?: string;
  value?: any;
  placement?: any;
  placeholder?: any;
  onChange?: (value: any, action: any) => void;
  backspaceRemovesValue?: boolean;
  escapeClearsValue?: boolean;
  error: boolean;
  helper?: any;
  label: string;
  required?: boolean;
  control?: any;
}

const components = {
  DropdownIndicator: null,
};
const smallSelectStyle = {
  control: (base: any, state: any) => ({
    ...base,
    minHeight: 30,
    borderColor: "#BFCCDA",
    borderRadius: "3px",
  }),
  menu: (base: any) => ({
    ...base,
    innerHeight: 30,
  }),
  menuList: (base: any) => ({
    ...base,
  }),
  indicatorsContainer: (provided: any, state: any) => ({
    ...provided,
    height: 30,
    color: "#394E66",
  }),
  indicatorSeparator: (base: any) => ({
    ...base,
    margin: "0",
    backgroundColor: "#BFCCDA",
  }),
  multiValue: (base: any) => ({
    ...base,
    backgroundColor: "#687D96",
    color: "#ffffff",
  }),
  multiValueLabel: (base: any) => ({
    ...base,
    color: "#ffffff",
  }),
};
const mediumSelectStyle = {
  control: (base: any, state: any) => ({
    ...base,
    minHeight: 40,
    borderColor: "#BFCCDA",
    borderRadius: "3px",
  }),
  menu: (base: any) => ({
    ...base,
    innerHeight: 40,
  }),
  menuList: (base: any) => ({
    ...base,
  }),
  indicatorsContainer: (provided: any, state: any) => ({
    ...provided,
    height: 40,
    color: "#394E66",
  }),
  indicatorSeparator: (base: any) => ({
    ...base,
    margin: "0",
    backgroundColor: "#BFCCDA",
  }),
  multiValue: (base: any) => ({
    ...base,
    backgroundColor: "#687D96",
    color: "#ffffff",
  }),
  multiValueLabel: (base: any) => ({
    ...base,
    color: "#ffffff",
  }),
};
// React Select Input
const CustomCreatableSelect = (props: SelectPropsI) => {
  const {
    name,
    options,
    isMulti,
    isClearable,
    size,
    placement,
    placeholder,
    className,
    label,
    required,
    error,
    helper,
    control,
  } = props;

  return (
    <Fragment>
      <div className={`form-group `}>
        <label className="col-form-label">
          {label} {required && <span className="text-danger">*</span>}
        </label>
        <Controller
          name={name}
          control={control}
          render={({ field: { onChange, value } }) => (
            <CreatableSelect
              components={components}
              options={options}
              className={className}
              value={value}
              styles={
                size === "small"
                  ? smallSelectStyle
                  : size === "medium"
                  ? mediumSelectStyle
                  : mediumSelectStyle
              }
              isMulti={isMulti}
              isClearable={isClearable}
              menuPlacement={placement || "auto"}
              placeholder={placeholder}
              onChange={onChange}
            />
          )}
        />
        {error && (
          <div className="mt-2 error" style={{ color: "red" }}>
            {helper?.length && helper[0].value.message}
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default CustomCreatableSelect;
