import { IconCalendar, IconFilter } from "Common/Icons";
import moment, { Moment } from "moment";
import { useAppContext } from "contexts/AppContext";
import React, { useCallback, useEffect, useState } from "react";
import Datetime from "react-datetime";
import { getAllUsers, getfilterContainer, setDashboardAvailableParams } from "../action";
import DashboardFilterPop from "../filter";
import _ from "lodash";
import { getTodayDate } from "pages/EmptyReturn/Common/utils";

const SearchFilter = () => {
  //API Initialize
  const { state, myDispatch } = useAppContext();
  const { cLoading, containers, filterSearchPost, searchParams } = state.dashboardStates;

  // HOOK MANAGEMENT
  //form date
  const [containerNo, setContainers] = useState<string>("");
  const [date, setDate] = useState<Moment>(moment());

  //ON Click Filter
  const [showFilter, setShowFilter] = useState(false);

  const handleDateChange = async (dateValue: any) => {
    const ContainerDate = moment(dateValue).format("YYYY-MM-DD");
    const searchPostD = {
      ...filterSearchPost,
      date: ContainerDate,
    };
    const response = await getfilterContainer(searchPostD, searchParams);
    myDispatch(response);
    setDate(dateValue);
  };

  const onChangeInput = (event: any) => {
    let val = event.target.value;
    setContainers(val?.replace(/[^A-Z0-9]+/gi, ""));
    // setContainers(val?.trim());
  };

  const debouncedChangeHandler = useCallback(_.debounce(onChangeInput, 1000), []);

  useEffect(() => {
    setDate(moment(getTodayDate()));
  }, []);

  useEffect(() => {
    if (filterSearchPost?.containerNo !== containerNo) {
      const ContainerDate = moment(date).format("YYYY-MM-DD");
      const searchPostD = {
        ...filterSearchPost,
        containerNo,
        date: ContainerDate,
      };
      myDispatch(getfilterContainer(searchPostD, searchParams));
    }
  }, [containerNo]);

  return (
    <div className="filter-row d-flex align-items-center pt-15 mt-15">
      {/* Search Bar */}
      <div className="app-search header-search">
        <span className="search-icon"> </span>
        <div className="position-relative">
          <input
            type="search"
            className="form-control input-search-left"
            placeholder="Search..."
            defaultValue={containerNo}
            onChange={debouncedChangeHandler}
          />
        </div>
      </div>
      {/* Select Date */}
      <div className="input-wrapper w-200 ">
        <Datetime
          inputProps={{
            placeholder: "Select date..",
          }}
          onChange={(value) => handleDateChange(value)}
          closeOnSelect
          value={date}
        />
        <div className="input-icon">
          <IconCalendar />
        </div>
      </div>
      {/* Filter */}
      <div className="ml-auto d-flex">
        <button className="btn btn-outline-light" onClick={() => setShowFilter(!showFilter)}>
          <IconFilter className="mr-2" />
          Filter
        </button>
      </div>
      {showFilter && <DashboardFilterPop hideFilter={() => setShowFilter(false)} />}
    </div>
  );
};

export default SearchFilter;
