export const appointmentReportTableHeaders = [
  { label: "LOAD", value: "load", sort: "-" },
  { label: "CONTAINER", value: "container", sort: "-" },
  { label: "PORT", value: "port", sort: "-" },
  { label: "CARRIER", value: "carrier", sort: "-" },
  { label: "PORT_USERNAME", value: "port_username", sort: "-" },
  { label: "PORT_PASSWORD", value: "port_password", sort: "-" },
  { label: "APPT_ID", value: "appt_id", sort: "-" },
  { label: "LOAD_STATUS", value: "load_status", sort: "-" },
  { label: "APPT_STATUS", value: "appt_status", sort: "-" },
  { label: "LOAD_TYPE", value: "load_type", sort: "-" },
  { label: "SYSTEM_REMARKS", value: "system_remarks", sort: "-" },
  { label: "FULL_EMPTY", value: "full_empty", sort: "-" },
  { label: "MOVE_TYPE", value: "move_type", sort: "-" },
  { label: "Requested Time/DateTime", value: "requested_Time/DateTime", sort: "-" },
  { label: "Accepted Time/DateTime", value: "ACCEPTEDDATETIMESLOTS", sort: "-" },
  { label: "REMARKS", value: "remarks", sort: "-" },
];

export const LOAD_STATUS = [
  "PENDING",
  "AVAILABLE",
  "DEPARTED",
  "DISPATCHED",
  "CHASSISPICK_ARRIVED",
  "CHASSISPICK_DEPARTED",
  "PULLCONTAINER_ARRIVED",
  "PULLCONTAINER_DEPARTED",
  "DELIVERLOAD_ARRIVED",
  "DELIVERLOAD_DEPARTED",
  "DROPCONTAINER_ARRIVED",
  "DROPCONTAINER_DEPARTED",
  "HOOKCONTAINER_ARRIVED",
  "HOOKCONTAINER_DEPARTED",
  "RETURNCONTAINER_ARRIVED",
  "RETURNCONTAINER_DEPARTED",
  "CHASSISTERMINATION_ARRIVED",
  "CHASSISTERMINATION_DEPARTED",
  "COMPLETED",
  "APPROVED",
  "BILLING",
  "UNAPPROVED",
  "REBILLING",
  "PARTIAL_PAID",
  "FULL_PAID",
];
export const APPT_STATUS = [
  "CONFIRMED",
  "DECLINED",
  "PENDING",
  "CANCELLED",
  "TENTATIVE",
  "REJECTED",
  "DELETED",
  "COMPLETED",
];

export const LOAD_TYPE = Object.freeze(["IMPORT", "EXPORT"]);
export const MOVE_TYPE = Object.freeze(["EMPTY", "PICKUP"]);
export const FULL_EMPTY = Object.freeze(["EMPTY", "FULL"]);
