import TopCards from "Common/Components/TopCards/TopCards";

const TopCard = () => {
  const cardData = [
    {
      id: 1,
      cardMainName: "Containers on Vessel",
      cardSubNameOne: "On Hold",
      cardSubNameTwo: "Released",
    },
    {
      id: 2,
      cardMainName: "Containers Discharged",
      cardSubNameOne: "On Hold",
      cardSubNameTwo: "Released",
    },

    {
      id: 3,
      cardMainName: "Available",
      cardSubNameOne: "Available",
      cardSubNameTwo: "Available",
    },
    {
      id: 4,
      cardMainName: "Not Available",
      cardSubNameOne: "Not Available",
      cardSubNameTwo: "Not Available",
    },
    {
      id: 5,
      cardMainName: "Outdated",
      cardSubNameOne: "In Port",
      cardSubNameTwo: "In Yard",
    },
    {
      id: 6,
      cardMainName: "Terminated",
      cardSubNameOne: "In Port",
      cardSubNameTwo: "In Yard",
    },
  ];

  return <TopCards cardData={cardData} />;
};

export default TopCard;
