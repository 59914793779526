import { IconDownload, IconPlus, IconSendEmail } from "Common/Components/Icons/Icons";
import { useState } from "react";
import Pagination from "../../Common/Components/Pagination";
import SubHeaderTab from "../../Common/Components/SubHeaderTab";
import { CustomIconSpreadSheet } from "../../Common/Components/Icons/CustomIcons";
import BookingFilterRow from "./Components/FilterRow";
import BookingsTable from "./Components/BookingsTable";
import AddNewBooking from "./Components/AddNewBookings";
import TopCards from "../../Common/Components/TopCards/TopCards";

const cardData = [
  {
    id: 1,
    cardMainName: "Containers on Vessel",
    cardSubNameOne: "On Hold",
    cardSubNameTwo: "Released",
  },
  {
    id: 2,
    cardMainName: "Empties Out",
    cardSubNameOne: "Last Free Days",
    cardSubNameTwo: "Pickup Appts",
  },

  {
    id: 3,
    cardMainName: "Loads In",
    cardSubNameOne: "In Port",
    cardSubNameTwo: "In Yard",
  },
];

const Bookings = () => {
  const [showAddModal, setShowAddModal] = useState(false);

  const handleShowAddModal = () => {
    setShowAddModal(true);
  };
  const handleHideAddModal = () => {
    setShowAddModal(false);
  };
  return (
    <div className="page-wrapper">
      <div className="position-relative subheader-wrapper">
        <SubHeaderTab
          title="Bookings"
          buttons={
            <>
              <button className="btn btn-outline-light mr-2">
                <CustomIconSpreadSheet />
              </button>
              <button className="btn btn-outline-light mr-2">
                <IconDownload />
              </button>
              <button className="btn btn-outline-light mr-2">
                <IconSendEmail />
              </button>
              <button className="btn btn-success" onClick={handleShowAddModal}>
                <IconPlus className="mr-2" />
                Add New Booking
              </button>
            </>
          }
          val={"bookings"}
        />
        <div className="page-fluid">
          <div className="top-page">
            <div className="pb-15 d-flex align-items-center">
              <h4 className="mb-0">757 Bookings</h4>
              {/* <Pagination className={"ml-auto"} /> */}
            </div>
            {/*Cards */}
            <TopCards cardData={cardData} />
            {/* Filter Row */}
            <BookingFilterRow />
            <BookingsTable />
          </div>
        </div>
        {showAddModal && (
          <AddNewBooking showAddModal={handleShowAddModal} hideAddModal={handleHideAddModal} />
        )}
      </div>
    </div>
  );
};

export default Bookings;
