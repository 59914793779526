import { Fragment } from "react";
import { standAloneReportHeaders } from "../constant";

function StandAloneReportTable(props: { standAloneReport: Array<Object> }) {
  const { standAloneReport } = props;
  return (
    <Fragment>
      <div className="table-responsive" style={{ height: "calc(100vh - 255px)" }}>
        <table className="table table-card">
          <thead>
            <tr>
              <th scope="col" className="">
                SNo.
              </th>
              {standAloneReportHeaders.map((item: any, i: number) => {
                return <th key={i}>{item.label}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {Array.isArray(standAloneReport) &&
              standAloneReport?.length > 0 &&
              standAloneReport?.map((element: any, i: number) => {
                return (
                  <Fragment key={i}>
                    <tr key={i}>
                      <td>
                        <div className="d-flex align-items-center justify-content-between">
                          <span className="mr-1">{i + 1}</span>
                          <div className="form-check"></div>
                        </div>
                      </td>
                      <td>{element.containerNo}</td>
                      <td>{element.reference_number}</td>
                      <td>{element.shipperName}</td>
                      <td>{element.cautionCount}</td>
                      <td>{element.errorMessage}</td>
                      <td>{element.lastContainerTracedDate}</td>
                      <td>{element.carrier}</td>
                    </tr>
                  </Fragment>
                );
              })}
          </tbody>
        </table>
      </div>
    </Fragment>
  );
}

export default StandAloneReportTable;
