import Loader from "Common/Components/Loader";
import SubHeaderTab from "Common/Components/SubHeaderTab";
import Toastr from "Common/Components/Toastr";
import { useAppContext } from "contexts/AppContext";
import { useEffect, useState } from "react";
import { getAppointmentSlotReport } from "./action";
import AppointmentSlotReportTable from "./Components/AppointmentSlotReportTable";
import SearchFilter from "./Components/SearchFilter";
import moment from "moment";

const AppointmentSlotReport = () => {
  const { state, myDispatch } = useAppContext();
  const { apptReportSearchParams, apptSlotLoading, apptReportCount } = state.reportDRStates;

  const initialParams = {
    appointmentId: null,
    startDate: null,
    endDate: null,
  };

  const [params, setParams] = useState<any>(initialParams);
  const [showToaster, setShowToaster] = useState<boolean>(false);
  // const timeZone = localStorage.getItem("timeZone");
  useEffect(() => {
    const { appointmentId, startDate, endDate } = params;
    if (appointmentId && startDate && endDate) {
      const payload = {
        startTime: startDate.toISOString(),
        endTime: endDate.toISOString(),
        appointmentId: appointmentId,
      };

      myDispatch(getAppointmentSlotReport(payload));
    }
  }, [params]);

  setTimeout(() => {
    setShowToaster(false);
  }, 5 * 1000);

  return (
    <div className="page-wrapper">
      <div className="position-relative subheader-wrapper">
        {apptSlotLoading && <Loader />}
        <SubHeaderTab val="reports" />
        <div className="page-fluid">
          <div className="top-page">
            <div className="pb-15 d-flex align-items-center">
              <h4 className="mb-0">Appointment Slot Report</h4>
              <div className="ml-auto">
                {/* <Pagination */}
                {/*   className={"ml-auto"} */}
                {/*   pageRangeDisplayed={5} */}
                {/*   totalItemsCount={apptReportCount} */}
                {/*   itemsCountPerPage={apptReportSearchParams.size} */}
                {/*   onChange={onPaginate} */}
                {/*   activePage={apptReportSearchParams.page} */}
                {/* /> */}
              </div>
            </div>
            <SearchFilter params={params} setParams={setParams} setShowToaster={setShowToaster} />
          </div>
          <AppointmentSlotReportTable params={params} />
        </div>
      </div>
      {showToaster && (
        <Toastr show={true} status="error" message="Please select all field to Generate Report" />
      )}
    </div>
  );
};

export default AppointmentSlotReport;
