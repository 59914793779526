import { Fragment, useState } from "react";
import moment from "moment";
import Loader from "Common/Components/Loader";
import { caseStatus } from "Common/Components/StatusBadge";
import { useAppContext } from "contexts/AppContext";

import { tableHeaders } from "../constant";

const SuccessfulDataReportTable = (props: any) => {
  const { state, myDispatch } = useAppContext();

  const { successfulMatricContainers, sLoading } = state.SucessfulMatricsContainerStates;

  const [headers, setTableHeader] = useState<any | null>(tableHeaders);

  return (
    <Fragment>
      <div className="table-responsive" style={{ height: "calc(100vh - 255px)" }}>
        {sLoading && <Loader />}
        <table className="table table-card">
          <thead>
            <tr>
              <th scope="col" className="">
                Sno
              </th>
              {headers.map((item: any, i: number) => {
                return <th key={i}>{item.label}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {successfulMatricContainers && successfulMatricContainers.length ? (
              successfulMatricContainers.map((successfulMatContainer: any, i: number) => {
                return (
                  <tr key={i}>
                    <td className="text-center">{i + 1}</td>
                    <td>{successfulMatContainer?.drayosRef}</td>
                    <td className="pointer text-primary">{successfulMatContainer?.containerNo}</td>
                    <td>{successfulMatContainer?.portId}</td>
                    <td>{caseStatus(successfulMatContainer?.status)}</td>
                    <td>{moment(successfulMatContainer?.createdAt).format("MM/DD/YYYY")}</td>
                    <td>{successfulMatContainer?.Duration}</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={100}>Data Not Found</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </Fragment>
  );
};

export default SuccessfulDataReportTable;
