import * as yup from "yup";

export const superAdminSchema = yup
  .object({
    email: yup.string().email("Must be a valid email").max(255).required("Email is required"),
    password: yup
      .string()
      .required("Password is required")
      .min(8, "Password must be at least 8 character long")
      .max(20, "Password must be at best 20 character long"),
  })
  .required();
