import Loader from "Common/Components/Loader";
import { caseStatus } from "Common/Components/StatusBadge";
import { useAppContext } from "contexts/AppContext";
import moment from "moment";
import { getNotUpdatedContainers, setNotUpdatedContainersParams } from "pages/Dashboard/action";
import { handleSort } from "pages/EmptyReturn/Common/utils";
import { useState } from "react";
import { getDetailedValue } from "utils/commonFunctions";
import { tableHeaders } from "./constant";

const NotUpdatedContainersTable = () => {
  const { state, myDispatch } = useAppContext();
  const { loggedUser } = state.appStates;
  const { notUpdatedContainers, searchParams, cLoading, errorMessage } = state.dashboardStates;
  const { containerSizes, containerOwners, containerTypes, ports } = state.configStates;
  const { page, limit, sort } = searchParams;

  const [headers, setTableHeader] = useState<any | null>(tableHeaders);

  // get size and type
  const getSizeType = (containerSize: any, containerType: any) => {
    let sizeVal = getDetailedValue(containerSize, containerSizes);

    let typeVal = getDetailedValue(containerType, containerTypes);
    return `${sizeVal} ${sizeVal && typeVal && "-"} ${typeVal}`;
  };
  // get port
  const getPortTerminalName = (val: any, vtype: "port" | "terminal") => {
    if (typeof val === "string") {
      return getDetailedValue(val, vtype === "port" ? ports : loggedUser?.terminal);
    } else {
      return val?.name;
    }
  };
  // get Empty Return
  const getEmptyReturn = (vals: any) => {
    let emptyReturn: string[] = [];
    vals.length > 0 &&
      vals.map((val: any) => {
        if (typeof val === "object") {
          emptyReturn.push(val.port);
        } else {
          let portname = getPortTerminalName(val, "port");
          if (portname) emptyReturn.push(portname);
        }
      });
    return emptyReturn;
  };

  // Sorting
  const onsort = (obj: any) => {
    const searchParamsD = handleSort(obj, headers, searchParams);
    myDispatch(setNotUpdatedContainersParams(searchParamsD));
  };

  return (
    <div className="table-responsive" style={{ height: "calc(100vh - 268px)" }}>
      {cLoading && <Loader />}
      <table className="table table-card">
        <thead>
          <tr>
            <th scope="col" className="">
              Sno
            </th>
            {headers.map((item: any, i: number) => {
              let sortIcon = "table-sort table-sort--default";
              if (item.sort === "-" && item.active) {
                sortIcon = "table-sort table-sort--ascending";
              }
              if (item.sort === "+" && item.active) {
                sortIcon = "table-sort table-sort--descending";
              }
              return (
                <th key={i} onClick={() => onsort(item)} className={item.sort ? sortIcon : ""}>
                  {item.label}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {notUpdatedContainers && notUpdatedContainers.length && !errorMessage ? (
            notUpdatedContainers.map((container: any, i: number) => {
              return (
                <tr key={i}>
                  <td className="text-center">{(page - 1) * limit + i + 1}</td>
                  <td>{container.containerNo}</td>
                  <td>{caseStatus(container.status)}</td>
                  <td>
                    {container.custom && <p>{"Custom: " + container.custom}</p>}
                    {container.freight && <p>{"Freight: " + container.freight}</p>}
                    {container.brokerHold && <p>{"Broker: " + container.brokerHold}</p>}
                  </td>
                  <td>{getDetailedValue(container.containerOwner, containerOwners)}</td>
                  <td>{getSizeType(container.containerSize, container.containerType)}</td>
                  <td>{getPortTerminalName(container.portId, "port")}</td>
                  <td>
                    {container.eta
                      ? moment(container.eta).format("MM/DD")
                      : container.eta
                      ? moment(container.sslEta).format("MM/DD")
                      : ""}
                  </td>
                  <td>
                    {container.status !== "On Vessel" &&
                      container.dischargeDate &&
                      moment(container.dischargeDate).format("MM/DD")}
                  </td>
                  <td>
                    {container.status !== "On Vessel" &&
                      container.lastFreeDay &&
                      moment(container.lastFreeDay).format("MM/DD")}
                  </td>
                  <td>
                    {container.status !== "On Vessel" &&
                      container.outGateDate &&
                      moment(container.outGateDate).format("MM/DD")}
                  </td>
                  <td className="">
                    {getEmptyReturn(container.emptyReturnLocation).toString().replaceAll("_", " ")}
                  </td>
                  <td>
                    {container.status !== "On Vessel" && container.terminatedDate
                      ? moment(container.terminatedDate).format("MM/DD")
                      : container.inGate?.date
                      ? moment(container.inGate?.date).format("MM/DD")
                      : ""}
                  </td>
                  <td>{container.vesselName}</td>
                  <td>{container.voyageName}</td>
                  <td>{container.vesselCode}</td>
                  <td>{container.billOfLading}</td>
                  <td>{container.grossWeight}</td>
                  <td>{container.seal}</td>
                  <td>{container.yardLocation}</td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={100}>{errorMessage}</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default NotUpdatedContainersTable;
