import Loader from "Common/Components/Loader";
import { useAppContext } from "contexts/AppContext";
import React, { Fragment, useState } from "react";
import { appointmentSlotReportTableHeaders } from "../constant";

function AppointmentSlotReportTable(props: { params: string }) {
  const { state } = useAppContext();
  const { params } = props;
  const { apptSlotReport, apptSlotReportLoading } = state.reportDRStates;
  const [more, setMore] = useState<any | null>();
  return (
    <Fragment>
      <div className="table-responsive" style={{ height: "calc(100vh - 255px)" }}>
        {apptSlotReportLoading && <Loader />}
        <table className="table table-card">
          <thead>
            <tr>
              <th scope="col" className="">
                SNo.
              </th>
              {appointmentSlotReportTableHeaders.map((item: any, i: number) => {
                return <th key={i}>{item.label}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {Array.isArray(apptSlotReport) && apptSlotReport.length <= 0 && (
              <tr>
                <td colSpan={100}>No Data is Available</td>
              </tr>
            )}
            {Object.keys(params).length < 0 && (
              <tr>
                <td colSpan={100}>Please Update Filter to get data</td>
              </tr>
            )}
            {Array.isArray(apptSlotReport) &&
              apptSlotReport?.length > 0 &&
              apptSlotReport
                ?.sort(
                  (a: any, b: any) =>
                    new Date(b?.timestamp).getTime() - new Date(a?.timestamp).getTime()
                )
                ?.map((element: any, i: number) => {
                  const slotList = element?.slots?.map(
                    (list: any) => `${list?.date} ${list?.startTime}-${list?.endTime}`
                  );
                  return (
                    <Fragment key={i}>
                      <tr key={i}>
                        <td>
                          <div className="d-flex align-items-center justify-content-between">
                            <span className="mr-1">{i + 1}</span>
                            <div className="form-check"></div>
                          </div>
                        </td>
                        <td>{element?.id}</td>
                        <td>{element?.appointmentId}</td>
                        <td>{element?.containerNo}</td>
                        <td>
                          {Array.isArray(slotList) && slotList.length ? (
                            <div
                              style={{
                                maxWidth: 200,
                                whiteSpace: "break-spaces",
                                lineBreak: "anywhere",
                              }}
                            >
                              <span onClick={() => setMore(i === more ? null : i)}>
                                {more !== i && (
                                  <span>
                                    {slotList[0]}
                                    {slotList.length > 1 && <span>...</span>}
                                  </span>
                                )}
                                {more === i && (
                                  <span>
                                    {slotList.map((val: any) => {
                                      return <p>{val}</p>;
                                    })}
                                  </span>
                                )}
                              </span>
                            </div>
                          ) : (
                            "No Slot Available"
                          )}
                        </td>
                        <td>{element?.timestamp}</td>
                        <td>{element?.lineScac}</td>
                        <td>{element?.containerSizeType}</td>
                        <td>{element?.loadType}</td>
                        <td>{element?.moveType}</td>
                        <td>{element?.portCode}</td>
                        <td>{element?.createdAt}</td>
                        <td>{element?.updatedAt}</td>
                      </tr>
                    </Fragment>
                  );
                })}
          </tbody>
        </table>
      </div>
    </Fragment>
  );
}

export default AppointmentSlotReportTable;
