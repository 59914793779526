export const tableHeaders = [
  {
    label: "USERID",
    value: "email",
    sort: "-",
  },
  {
    label: "Port",
    value: "port",
    sort: "-",
  },
  {
    label: "Username",
    value: "portUsername",
    sort: "-",
  },
  {
    label: "Password",
    value: "portPassword",
    sort: "-",
  },
  {
    label: "Session",
    value: "sessions",
    sort: "-",
  },
  {
    label: "Meta",
    value: "meta",
    sort: "-",
  },
  {
    label: "Auth Failed",
    value: "auth_failed",
    sort: "-",
  },
  {
    label: "Session Failed",
    value: "session_failed",
    sort: "-",
  },
  {
    label: "Last Updated",
    value: "updatedAt",
    sort: "-",
  },
  {
    label: "Session Update",
    sort: "-",
  },
  {
    label: "Test",
    sort: "-",
  },
];

export const cookieTableHeaders = [
  {
    label: "USERID",
    value: "id",
    sort: "-",
  },
  {
    label: "Port",
    value: "port",
    sort: "-",
  },
  {
    label: "Username",
    value: "portUsername",
    sort: "-",
  },
  {
    label: "Password",
    value: "portPassword",
    sort: "-",
  },
  {
    label: "Cookies",
    value: "cookies",
    sort: "-",
  },
  // {
  //   label: "Meta",
  //   value: "meta",
  //   sort: "-",
  // },
  {
    label: "Auth Failed",
    value: "auth_failed",
    sort: "-",
  },
  {
    label: "Session Failed",
    value: "session_failed",
    sort: "-",
  },
  {
    label: "Last Updated",
    value: "updatedAt",
    sort: "-",
  },
  {
    label: "Cookie Update",
    sort: "-",
  },
];
