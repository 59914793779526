export const initialWrongDataValues = {
  wrongContainersData: [],
  wLoading: false,
  wErrorMessage: null,
  wrongDataReportCount: 0,
  searchParams: {
    limit: 50,
    page: 1,
  },
  searchPost: {},
};

export const wrongDataReportReducer = (
  state: any,
  action: {
    type: string;
    payload: any;
  }
) => {
  switch (action.type) {
    case "WRONGDATAREPORT_INPROGRESS": {
      return {
        ...state,
        wLoading: true,
        wrongDataReportCount: 0,
      };
    }
    case "WRONGDATAREPORT_SUCCESS": {
      let wrongDataReport = action.payload.data.data;
      return {
        ...state,
        wLoading: false,
        wErrorMessage: typeof wrongDataReport === "string" ? wrongDataReport : null,
        wrongContainersData: wrongDataReport,
        wrongDataReportCount: action.payload.data.count,
      };
    }
    case "WRONGDATAREPORT_REJECTED": {
      return {
        ...state,
        wLoading: false,
        wrongContainersData: [],
        wErrorMessage: action.payload.errorMessage,
        wrongDataReportCount: 0,
      };
    }
    case "SET_WRONGDATAREPORT_PARAMS": {
      let param = action.payload.searchParams;
      let post = action.payload.searchPost;
      return {
        ...state,
        searchParams: param,
        searchPost: { ...post },
      };
    }
    case "RESET_WRONGDATAREPORT_PARAMS": {
      return {
        ...state,
        searchParams: {
          limit: 50,
          page: 1,
        },
        searchPost: { reset: true },
      };
    }
    default:
      return state;
  }
};
