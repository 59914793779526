import React, { Fragment, useCallback, useEffect, useState } from "react";
import CustomSelectField, {
  SelectFieldWithoutControl,
} from "Common/Components/Inputs/CustomSelectField";
import { useAppContext } from "contexts/AppContext";
import _ from "lodash";
import Datetime from "react-datetime";
import { getDuplicateArrayofObjects } from "pages/EmptyReturn/Common/utils";
import { IconCalendar } from "Common/Icons";
import moment from "moment";
import { getInitialFilters } from "pages/EmptyCorrection/constant";

const SearchFilter = (props: any) => {
  const { state, myDispatch } = useAppContext();
  // const { ports, containerOwners, containerSizes, containerTypes, terminals } = state.configStates;
  const { sLoading, drLoading } = state.reportDRStates;
  const { sslArray, containerTypes, portsArray } = props;

  const [selectedOptions, setSelectedOptions] = useState<any>(props.params);

  const handleOptValChange = (val: any, key: string) => {
    // console.log("val key",val, key);
    let parameters = { ...selectedOptions };
    parameters[key] = key === "date" ? val : val ? val.value : null;
    setSelectedOptions({ ...parameters });
  };
  // console.log("selected options", selectedOptions);

  const onSearch = (isReturn: boolean) => {
    props.setParams(selectedOptions);
  };

  return (
    <Fragment>
      <div className="filter-row d-flex align-items-center">
        {/* Search */}
        {/* Date */}
        <div className="input-wrapper w-200 mr-2">
          <Datetime
            inputProps={{
              placeholder: "Select date..",
            }}
            onChange={(value) => handleOptValChange(moment(value).toISOString(), "date")}
            closeOnSelect
            value={moment(selectedOptions?.date)}
          />
          <div className="input-icon">
            <IconCalendar />
          </div>
        </div>

        {/* ssl  */}
        <SelectFieldWithoutControl
          isLoading={false}
          isClearable={true}
          isSearchable={true}
          name="ssl"
          // options={sslOpts}
          options={sslArray?.map((opt: any) => ({ value: opt.label, label: opt.label }))}
          onChange={(e: any) => handleOptValChange(e, "ssl")}
          size="small"
          className="min-200 mr-2"
          placeholder="Select SSL"
          value={
            selectedOptions?.ssl && sslArray.find((opt: any) => opt.value === selectedOptions.ssl)
          }
          required
        />

        {/* sizeOpts  */}
        <SelectFieldWithoutControl
          isLoading={false}
          isClearable={true}
          isSearchable={true}
          name="size"
          options={containerTypes?.map((opt: any) => ({ value: opt.label, label: opt.label }))}
          onChange={(e: any) => handleOptValChange(e, "sizeType")}
          size="small"
          className="min-200 mr-2"
          placeholder="Select Size"
          value={
            selectedOptions?.sizeType &&
            containerTypes?.find((opt: any) => opt.label === selectedOptions.sizeType)
          }
          required
        />

        <button
          className="btn btn-success ml-2"
          onClick={() => onSearch(false)}
          disabled={sLoading}
        >
          {drLoading && (
            <span
              className="spinner-border spinner-border-sm mr-2"
              role="status"
              aria-hidden="true"
            ></span>
          )}
          Generate Report
        </button>
      </div>
    </Fragment>
  );
};

export default SearchFilter;
