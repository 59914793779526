import { IconCancelCircle, IconPen } from "Common/Components/Icons/Icons";
import Loader from "Common/Components/Loader";
import { IconCheckCircle, IconLocationCancel } from "Common/Icons";
import { useAppContext } from "contexts/AppContext";
import {
  cancelSubscription,
  getAllUsers,
  getDrayoCarrierDetail,
  setUserInfoAvailableParams,
  updateGpaAuthorization,
} from "pages/Dashboard/action";
import { getCarrierDetail } from "../../../DrayosSummaryReport/action";
import { APP_SYSTEM, UsertableHeaders } from "pages/Dashboard/Common/constant";
import React, { useEffect, useState } from "react";
import { getDetailedValue } from "utils/commonFunctions";
import SubscriptionPop from "./SubscriptionPop";
import _, { curry } from "lodash";
import Toastr from "Common/Components/Toastr";
import { Button } from "react-bootstrap";
import UpdateScacPop from "./UpdateScacPop";

const UserInfoTable = () => {
  //API Initialize
  const { state, myDispatch } = useAppContext();
  const {
    uLoading,
    userData,
    userSearchPost,
    userSearchParams,
    errorMessage,
    drayosUsers,
    scacLoading,
    scacError,
  } = state.dashboardStates;
  const { terminals, ports } = state.configStates;
  const { limit, page } = userSearchParams;

  //STATE MANAGEMENT
  const [headers, setTableHeader] = useState<any | null>(UsertableHeaders);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const [showUpdateScacModal, setShowUpdateScacModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [showToast, setShowToast] = useState<boolean | null>(false);
  const [showUpdateScacToast, setShowUpdateScacToast] = useState<boolean | null>(false);
  const [scacCodeArray, setScacCodeArray] = useState<any>([]);
  const [gpaAuthorizationArray, setGpaAuthorizationArray] = useState<any>([]);
  const [gpaAuthorizationStatus, setGpaAuthorizationStatus] = useState<any>({
    toast: null,
    success: null,
  });
  const [otherScac, setOtherScac] = useState<any>({ otherScac: null });
  const [mainScac, setMainScac] = useState<any>("");
  const [searchParam, setSearchParam] = useState<any>();

  useEffect(() => {
    drayosUsers.length == 0 && myDispatch(getDrayoCarrierDetail());
  }, []);

  // Cancel Standalone Subscription
  const cancelTrackosSubcription = (userVal: any) => {
    myDispatch(
      cancelSubscription({ email: userVal.email, subscriptionId: userVal.subscriptionId })
    );
  };
  const handleClose = () => {
    setShowSubscriptionModal(false);
    setSelectedUser({});
  };

  const handleUpdateScacClose = (scacObj: any) => {
    if (scacObj) {
      const findScacObjIndex = scacCodeArray.findIndex((o: any) => o.id === scacObj.id);
      if (findScacObjIndex !== -1) {
        const updatedArray = [...scacCodeArray];
        updatedArray[findScacObjIndex] = scacObj;
        setScacCodeArray(updatedArray);
      } else {
        setScacCodeArray((prev: any) => [...prev, scacObj]);
      }
    }
    setShowUpdateScacModal(false);
    setSelectedUser({});
  };

  const handleToast = (show: boolean) => {
    if (showToast !== null) {
      setShowToast(show);
    }
  };

  const handleUpdateScacToast = (show: boolean) => {
    if (showUpdateScacToast !== null) {
      setShowUpdateScacToast(show);
    }
  };

  const mappingUserdataAndCarriers = (userId: string, toDisplay: string) => {
    const matchingUser = drayosUsers.find((d: any) => d._id === userId);

    return matchingUser ? matchingUser[toDisplay] : null;
  };

  const mappingTrackos = (userId: string) => {
    if (Array.isArray(drayosUsers) && drayosUsers.length) {
      const matchingUser = drayosUsers.find((d: any) => d._id === userId);
      return matchingUser;
    }
  };

  //for Toaster set to false every 5 sec
  setTimeout(() => {
    setShowToast(false);
    setShowUpdateScacToast(false);
    setGpaAuthorizationStatus({ toast: null, success: null });
  }, 5000);
  //function to handle subscriptions
  const handleSubscriptions = (userVal: any) => {
    let currentSub = { ...userVal };
    setShowSubscriptionModal(true);
    setSelectedUser(currentSub);
    // const response =  cancelSubscription({ email, subscriptionId });
    // myDispatch(response);
  };

  const handleUpdateScac = (userVal: any) => {
    let currentSub = { ...userVal };
    setShowUpdateScacModal(true);
    setSelectedUser(currentSub);
    setOtherScac(currentSub.otherScac);
    setMainScac(currentSub.carrierScac);
  };

  const handleGpaAuthorization = (userInfo: any, updateGpa: boolean) => {
    if (updateGpa !== null) {
      const payload = { carrierId: userInfo?._id, toAuthorize: updateGpa };
      const response = updateGpaAuthorization(payload);
      myDispatch(response);
      response.payload
        .then((res) => {
          const findScacObjIndex = gpaAuthorizationArray.findIndex(
            (o: any) => o.carrierId === payload.carrierId
          );
          if (findScacObjIndex !== -1) {
            const updatedArray = [...gpaAuthorizationArray];
            updatedArray[findScacObjIndex] = payload;
            setGpaAuthorizationArray(updatedArray);
          } else {
            setGpaAuthorizationArray((prev: any) => [...prev, payload]);
          }
          setGpaAuthorizationStatus({ toast: true, success: true });
        })
        .catch((err) => {
          setGpaAuthorizationStatus({ toast: true, success: false });
        });
    }
  };

  // Sorting
  const onsort = (obj: any) => {
    let sortString = ``;
    headers.map((D: any) => {
      if (D.value === obj.value) {
        D.sort = D.sort === "-" || D.sort === "" ? "+" : "-";
        sortString = `${D.sort === "+" ? "" : D.sort}${D.value}`;
        D.active = true;
      } else {
        D.active = false;
      }
      return D;
    });
    if (sortString !== "") {
      const searchParamsD = {
        ...userSearchParams,
        sort: sortString,
      };
      setSearchParam(searchParamsD);
      myDispatch(setUserInfoAvailableParams(userSearchPost, searchParamsD));
      myDispatch(getAllUsers(userSearchPost, searchParamsD));
    }
  };
  //HANDLE FUNCTION
  const getPortTerminalName = (val: any, vtype: "port" | "terminal") => {
    if (typeof val === "string") {
      return getDetailedValue(val, vtype === "port" ? ports : terminals);
    } else {
      return val?.name;
    }
  };
  return (
    <React.Fragment>
      <div
        className="table-responsive"
        id="userInfoTable"
        style={{ height: "calc(100vh - 344px)" }}
      >
        {(!drayosUsers.length || uLoading) && <Loader />}
        {scacLoading && <Loader />}
        <table className="table table-card" id="userInfoTable">
          <thead>
            <tr>
              <th scope="col" className="">
                Sno
              </th>
              {UsertableHeaders.map((item: any, i: number) => {
                let sortIcon = "table-sort table-sort--default";
                if (item.sort === "-" && item.active) {
                  sortIcon = "table-sort table-sort--ascending";
                }
                if (item.sort === "+" && item.active) {
                  sortIcon = "table-sort table-sort--descending";
                }
                return (
                  <th key={i} onClick={() => onsort(item)} className={item.sort ? sortIcon : ""}>
                    {item.label}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {drayosUsers.length && userData.length ? (
              userData.map((userVal: any, i: number) => {
                const findScacCode = scacCodeArray.find((scac: any) => scac.id === userVal._id);
                const findGpaAuthorization: any = gpaAuthorizationArray.find(
                  (gpa: any) => gpa.carrierId === userVal._id
                );
                let isGpaEnabled = findGpaAuthorization
                  ? findGpaAuthorization?.toAuthorize
                  : userVal?.gpaEnabled;

                const mappedTrackos = mappingTrackos(userVal._id) ?? {};

                return (
                  <tr key={i}>
                    <td className="text-center">{(page - 1) * limit + i + 1}</td>
                    <td>{mappedTrackos.carrier}</td>
                    <td> {userVal.companyType}</td>
                    <td>{mappedTrackos.system}</td>
                    <td>{mappedTrackos.email || userVal.email}</td>
                    <td> {userVal.role}</td>
                    <td>
                      {userVal.terminal?.length > 0 &&
                        getPortTerminalName(userVal.terminal[0], "terminal")}
                    </td>
                    <td>
                      {userVal?.email?.includes("@trackos.com") ? (
                        <strong>DRAYOS TRACK</strong>
                      ) : (
                        userVal.subSubscriptionType
                      )}
                    </td>
                    <td> {userVal.subscriptionType}</td>
                    <td> {userVal.isPaid ? "Yes" : "No"}</td>
                    <td>
                      {!userVal.email.includes("@trackos.com") && (
                        <div className="d-flex justify-content-center align-items-center">
                          <div className="position-relative tooltip-label">
                            <button
                              onClick={() => cancelTrackosSubcription(userVal)}
                              className="btn btn-link"
                            >
                              <IconCancelCircle className="d-flex justify-content-center align-items-center text-danger" />
                            </button>
                            <div
                              className="tooltip tooltip--gray-700 mw-200"
                              data-placement="top-center"
                            >
                              Disable Standalone Account
                            </div>
                          </div>
                        </div>
                      )}
                      {userVal.email.includes("@trackos.com") && (
                        <div className="d-flex justify-content-center align-items-center">
                          {userVal.isPaid ? (
                            <div className="position-relative tooltip-label">
                              <button
                                onClick={() => handleSubscriptions(userVal)}
                                className="btn btn-link"
                              >
                                <IconCancelCircle className="d-flex justify-content-center align-items-center text-danger" />
                              </button>
                              <div
                                className="tooltip tooltip--gray-700 mw-200"
                                data-placement="top-center"
                              >
                                Disable subscription
                              </div>
                            </div>
                          ) : (
                            <div className="position-relative tooltip-label">
                              <button
                                onClick={() => handleSubscriptions(userVal)}
                                className="btn btn-link"
                              >
                                <IconCheckCircle className="d-flex justify-content-center align-items-center text-success" />
                              </button>
                              <div
                                className="tooltip tooltip--gray-700 mw-200"
                                data-placement="top-center"
                              >
                                Enable subscription
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </td>
                    <td>{findScacCode ? findScacCode?.scacCode : userVal?.carrierScac}</td>
                    <td>
                      {findScacCode
                        ? findScacCode?.otherScac?.map((scac: any) => {
                            return (
                              <span key={scac} style={{ marginRight: "8px" }}>
                                {scac}
                              </span>
                            );
                          })
                        : userVal.otherScac?.map((scac: any) => {
                            return (
                              <span key={scac} style={{ marginRight: "8px" }}>
                                {scac}
                              </span>
                            );
                          })}
                    </td>
                    <td>
                      <Button onClick={() => handleUpdateScac(userVal)}>Update Scac</Button>
                    </td>
                    <td className="text-center">{isGpaEnabled ? "Yes" : "No"}</td>
                    <td>
                      {userVal.email.includes("@trackos.com") && (
                        <div className="d-flex justify-content-center align-items-center">
                          {isGpaEnabled === true && (
                            <div className="position-relative tooltip-label">
                              <button
                                onClick={() => handleGpaAuthorization(userVal, false)}
                                className="btn btn-link"
                              >
                                <IconCancelCircle className="d-flex justify-content-center align-items-center text-danger" />
                              </button>
                              <div
                                className="tooltip tooltip--gray-700 mw-200"
                                data-placement="top-center"
                              >
                                Disable GPA Authorization
                              </div>
                            </div>
                          )}
                          {isGpaEnabled === false && (
                            <div className="position-relative tooltip-label">
                              <button
                                onClick={() => handleGpaAuthorization(userVal, true)}
                                className="btn btn-link"
                              >
                                <IconCheckCircle className="d-flex justify-content-center align-items-center text-success" />
                              </button>
                              <div
                                className="tooltip tooltip--gray-700 mw-200"
                                data-placement="top-center"
                              >
                                Enable GPA Authorization
                              </div>
                            </div>
                          )}
                          {(isGpaEnabled === null || isGpaEnabled === undefined) && (
                            <div className="position-relative tooltip-label"></div>
                          )}
                        </div>
                      )}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={100}>No data found. Please, Select correct filters.</td>
              </tr>
            )}
          </tbody>
        </table>
        {showSubscriptionModal && (
          <SubscriptionPop
            handleClose={handleClose}
            showSubscriptionModal={showSubscriptionModal}
            selectedUser={selectedUser}
            handleToast={handleToast}
            mappingUserdataAndCarriers={mappingUserdataAndCarriers}
          />
        )}
        {showUpdateScacModal && (
          <UpdateScacPop
            handleClose={handleUpdateScacClose}
            showUpdateScacModal={showUpdateScacModal}
            selectedUser={selectedUser}
            handleUpdateScacToast={handleUpdateScacToast}
            otherScac={otherScac}
            mainScac={mainScac}
            searchParam={searchParam}
          />
        )}
        {showToast && errorMessage === "" && (
          <Toastr show={true} status="success" message={"Payment Status Updated SuccessFully"} />
        )}

        {showToast && errorMessage !== "" && (
          <Toastr show={true} status="error" message={"Error Updating Payment Status"} />
        )}

        {showUpdateScacToast && scacError === "" && (
          <Toastr show={true} status="success" message={"Scac Code Updated Successfully"} />
        )}

        {showUpdateScacToast && scacError !== null && scacError !== "" && (
          <Toastr show={true} status="error" message={"Error Updating Scac Code"} />
        )}
        {gpaAuthorizationStatus.toast && gpaAuthorizationStatus.success === true && (
          <Toastr show={true} status="success" message={"GPA Authorization Updated"} />
        )}
        {gpaAuthorizationStatus.toast && gpaAuthorizationStatus.success === false && (
          <Toastr show={true} status="error" message={"Error Updating GPA Authorization"} />
        )}
      </div>
    </React.Fragment>
  );
};

export default UserInfoTable;
