export const initialProblemContainerValues = {
  problemContainers: [],
  pLoading: false,
  pErrorMessage: null,
  problemContainerCount: 0,
  searchParams: {
    limit: 50,
    page: 1,
  },
  searchPost: {
    cautionCount: true,
  },
};

let filterSearchPost = {
  containerNo: "",
  custom: "",
  freight: "",
  billOfLading: "",
  vesselCode: "",
  voyageName: "",
  dischargeDate: "",
  outGateDate: "",
  grossWeight: "",
  seal: "",
  yardLocation: "",
  demurrage: "",
  lastFreeDay: "",
  containerOwner: "",
  containerSize: "",
  containerType: "",
  status: "",
  portId: "",
  reset: true,
  pickUpFrom: "",
  pickUpTo: "",
  emptyReturnLocation: "",
  addedBy: "",
};

export const problemContainerReducer = (
  state: any,
  action: {
    type: string;
    payload: any;
  }
) => {
  switch (action.type) {
    case "PROBLEMCONTAINERS_INPROGRESS": {
      return {
        ...state,
        pLoading: true,
        problemContainerCount: 0,
      };
    }
    case "PROBLEMCONTAINERS_SUCCESS": {
      let problemContainers = action.payload.data.containers;
      return {
        ...state,
        pLoading: false,
        pErrorMessage: typeof problemContainers === "string" ? problemContainers : null,
        problemContainers: action.payload.data.containers,
        problemContainerCount: action.payload.data.count,
      };
    }
    case "PROBLEMCONTAINERS_REJECTED": {
      return {
        ...state,
        pLoading: false,
        problemContainerReducer: [],
        pErrorMessage: action.payload.errorMessage,
        problemContainerCount: 0,
      };
    }
    case "SET_PROBLEMCONTAINERS_PARAMS": {
      let param = action.payload.searchParams;
      let post = action.payload.searchPost;
      return {
        ...state,
        searchParams: param,
        searchPost: { ...post, reset: false },
      };
    }
    case "RESET_PROBLEMCONTAINERS_PARAMS": {
      return {
        ...state,
        searchPost: { cautionCount: true, reset: true },
        searchParams: {
          limit: 50,
          page: 1,
        },
      };
    }
    default:
      return state;
  }
};
