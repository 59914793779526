import React, { useEffect, useRef, useState } from "react";
import { IconDual, IconReset } from "../../../../Common/Components/Icons/Icons";
import { HeaderPropsI, SmallTypesI } from "../../location";
import { nyPorts, ports } from "../../constant";
import PortImages from "../../PortImages";

const HistoryTableHeader = (props: HeaderPropsI) => {
  const { scroll, terminal, portsArray, emptyData } = props;

  // ---------------------------------------------------- STATE MANAGEMENT---------------------------------------------------//
  const [isScrollable, setIsScrollable] = useState(false);

  // const ref = useRef<HTMLTableSectionElement>(null);
  // ---------------------------------------------------- HOOKS MANAGEMENT ---------------------------------------------------//
  useEffect(() => {
    if (scroll > 250) {
      setIsScrollable(true);
    } else {
      setIsScrollable(false);
    }
  }, [scroll]);

  return (
    <thead className={`table-head text-center ${isScrollable ? "sticky-head" : ""}`}>
      <tr>
        {isScrollable ? (
          <th rowSpan={2} className="bg-gray-100 rounded-left--5 w-20px p-2">
            <button className="btn btn-white w-100">
              <IconReset className="mr-10" />
              Refresh
            </button>
          </th>
        ) : (
          <th rowSpan={4} className="bg-gray-100 align-bottom rounded-left--5  w-20px p-2">
            <div className="d-flex flex-column align-items-center align-items-center">
              <div className="img-wrapper mb-40">
                {terminal === "LA" ? <img src={PortImages.PortLogo} alt="" /> : null}
              </div>
              <button className="btn btn-white w-100">
                <IconReset className="mr-10" />
                Refresh
              </button>
            </div>
          </th>
        )}
      </tr>

      <tr>
        {portsArray.map((port: SmallTypesI, index: number) => {
          return (
            <React.Fragment key={index}>
              {port.checked && (
                <th className="p-10 w--10">
                  <div className="d-flex flex-column align-items-center">
                    <div className="img-wrapper h-30px">
                      <img
                        src={PortImages[port.label.replace(/\s/g, "_")]}
                        alt={port.label}
                        className="mh-100 h-auto w-100"
                      />
                    </div>
                    <h6 className="text-primary mt-2 mb-0 title">{port.label}</h6>
                  </div>
                </th>
              )}
            </React.Fragment>
          );
        })}
      </tr>
      {/* <tr>
        {portsArray.map((port: SmallTypesI, index: number) => {
          let find, isOpen;
          if (terminal === "LA" && emptyData?.length) {
            find = ports.find((item) => item.title === port.label);
            const empties = emptyData?.find((ff) => ff?.key === port.label);
            if (empties && empties.data?.data?.length) {
              isOpen = empties.data.data.length;
            }
          } else if (terminal === "NY" && emptyData?.length) {
            find = nyPorts.find((item) => item.title === port.label);
            const empties = emptyData?.find((ff) => ff?.key === port.label);
            if (empties && empties.data?.data?.length) {
              isOpen = empties.data.data.length;
            }
          }
          return (
            <React.Fragment key={index}>
              {port.checked && (
                <td
                  className="p-10 rounded-0"
                  style={{ visibility: isScrollable ? "hidden" : "visible" }}
                >
                  {isOpen ? (
                    <span className="badge badge-light-green">OPEN</span>
                  ) : (
                    <span className="badge badge-gray-50">N/A</span>
                  )}
                </td>
              )}
            </React.Fragment>
          );
        })}
      </tr> */}
    </thead>
  );
};

export default HistoryTableHeader;
