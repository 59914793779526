import React, { Fragment, useCallback, useEffect, useState } from "react";
import CustomSelectField, {
  SelectFieldWithoutControl,
} from "Common/Components/Inputs/CustomSelectField";
import { useAppContext } from "contexts/AppContext";
import _ from "lodash";
import Datetime from "react-datetime";
import moment, { Moment } from "moment";
import { IconCalendar, IconPrinter } from "Common/Icons";

const SearchFilter = (props: any) => {
  const { state, myDispatch } = useAppContext();
  const { setParams, params } = props;
  const { loading, standardPorts } = state.drayosReportStates;
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState<any>(params);

  const handleOptsChange = (e: any, type: string) => {
    const parameters = { ...selectedOptions };
    if (e.value) {
      parameters[type] = e.value;
      setParams((prevParams: any) => ({
        ...prevParams,
        ...parameters,
      }));
    }
  };

  const handleStartDateChange = (value: any) => {
    const formattedDate: any = moment(value).utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
    setParams((prevParams: any) => ({
      ...prevParams,
      startDate: formattedDate.toString(),
    }));
  };

  const handleEndDateChange = (value: any) => {
    const formattedDate: any = moment(value).utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
    setParams((prevParams: any) => ({
      ...prevParams,
      endDate: formattedDate.toString(),
    }));
  };

  return (
    <Fragment>
      <div className="filter-row d-flex align-items-center ">
        <SelectFieldWithoutControl
          isLoading={false}
          isClearable={true}
          isSearchable={true}
          name="selectPort"
          options={standardPorts?.map((pp: any) => ({ label: pp.portCode, value: pp.portCode }))}
          onChange={(e: any) => handleOptsChange(e, "portCode")}
          size="small"
          className="min-200 mr-2"
          placeholder="Select Port"
        />
        <div className="input-wrapper w-200 ml-5">
          <Datetime
            inputProps={{
              placeholder: "Start time..",
            }}
            onChange={handleStartDateChange}
            closeOnSelect
          />
          <div className="input-icon">
            <IconCalendar />
          </div>
        </div>
        <div className="input-wrapper w-200 ml-5">
          <Datetime
            inputProps={{
              placeholder: "End time..",
            }}
            onChange={handleEndDateChange}
            closeOnSelect
          />
          <div className="input-icon">
            <IconCalendar />
          </div>
        </div>
      </div>
      <div className="filter-row d-flex align-items-center"></div>
    </Fragment>
  );
};

export default SearchFilter;
