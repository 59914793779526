export const baseLineSnapShotReportHeader = [
  {
    label: "TOTAL_TO_TRACK",
    value: "total_to_track",
    sort: "-",
  },
  {
    label: "TOTAL_TRACKED",
    value: "totalTracked",
    sort: "-",
  },
  {
    label: "SUCCESSFULLYTRACKED",
    value: "successFullyTracked",
    sort: "-",
  },
  {
    label: "TOTALERROR",
    value: "totalError",
    sort: "-",
  },
  {
    label: "NODATAERROR",
    value: "noDataError",
    sort: "-",
  },
  {
    label: "LOGINERROR",
    value: "loginError",
    sort: "-",
  },
  {
    label: "FAILEDSCRAPERROR",
    value: "failedScrapeError",
    sort: "-",
  },
  {
    label: "SUCCESSRATIO",
    value: "successRatio",
    sort: "-",
  },
  {
    label: "PORTCODE",
    value: "portCode",
    sort: "-",
  },
  {
    label: "CREATEDAT",
    value: "createdAt",
    sort: "-",
  },
  {
    label: "UPDATEDAT",
    value: "updatedAt",
  },
];
