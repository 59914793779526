import React, { useEffect, useState } from "react";
import {
  IconDual,
  IconPlus,
  IconWarning,
  IconAngleArrowRight,
  IconAngleArrowTop,
  IconCheck,
  IconTimes,
  IconForward,
} from "Common/Components/Icons/Icons";
import { DataTypesI, EmptiesTypes, LaPropsI } from "../location";
import nySslFile from "../nysslFile.json";
import { SelectFieldWithoutControl } from "Common/Components/Inputs/CustomSelectField";
import { LA_STATUS_OPTIONS, NY_STATUS_OPTIONS } from "pages/EmptyCorrection/constant";
import moment from "moment";
import { getEmptyCorrections } from "../action";
import { useAppContext } from "contexts/AppContext";
import { CustomIconSaveOutline } from "Common/Components/Icons/CustomIcons";
import Loader from "Common/Components/Loader";

const NYEmptyTable = (props: any) => {
  const { data, emptyData, shift, portsArray, containerTypes, terminal } = props;
  const todayDate = moment().format("");
  // ---------------------------------------------------- STATE MANAGEMENT---------------------------------------------------//
  const [emptyReturnsInfo, setEmptyReturnsInfo] = useState<string[]>(nySslFile.shipping_lines);
  const [isShown, setIsShown] = useState<any>({});
  const [status, setStatus] = useState<any>({});
  const [payload, setpayload] = useState({});
  const { state, myDispatch } = useAppContext();
  const [updateStatus, setUpdateStatus] = useState<any>({});
  const [loading, setLoading] = useState(false);

  const portlist = portsArray.map((pp: any) => ({
    label: pp.label,
    value: pp.label,
  }));
  // ---------------------------------------------------- HANDLE FUNCTIONS ---------------------------------------------------//
  const showEmptyReturnsInfo = (ssl: string) => {
    if (emptyReturnsInfo.includes(ssl)) {
      const temp = emptyReturnsInfo.filter((fil) => fil !== ssl);
      setEmptyReturnsInfo([...temp]);
    } else {
      const temp = emptyReturnsInfo;
      temp.push(ssl);
      setEmptyReturnsInfo([...temp]);
    }
  };

  const filterEmptyReturn = (empty: any, types: any) => {
    let sslData: any;
    const find = emptyData?.find((fil: EmptiesTypes) => fil?.key === empty?.label);
    sslData = find?.data?.data?.find(
      (ss: DataTypesI) => ss.ssl === data && ss.types === types.label
    );

    return sslData;
  };
  const handleStatusChange = (
    statusVal: any,
    pp: any,
    types: any,
    date: any,
    selectedTerminal: any,
    ssldata: any,
    shifts: any
  ) => {
    setLoading(true);
    setStatus(statusVal);
    const payloaddata = {
      portMarket: selectedTerminal,
      port: pp.label,
      date: date,
      ssl: ssldata,
      types: types.label,
      [shift]: statusVal.value,
      remarks: "",
      emptyReturnLocation: "",
    };
    const response = getEmptyCorrections(payloaddata);

    myDispatch(response);
    response.payload.then((resp) => {
      setUpdateStatus(resp.data.data);
      setLoading(false);
    });
  };
  const handleClick = (activeCell: any, portIndex: any) => {
    setIsShown({ activeCell: activeCell, portIndex: portIndex });
  };

  const EmptiesIcon = (text: string) => {
    switch (text) {
      case "OPEN":
        return "success";
      case "RESTRICTED":
        return "danger";
      case "CLOSED":
        return "danger";
      case "CONTACT SSL":
        return "secondary";
      default:
        break;
    }
  };

  return (
    <>
      <tbody className="mb-4">
        <tr>
          <td colSpan={100}>
            <div className="d-flex align-items-center">
              <span className="font-14 text-dark font-weight-500">{data}</span>
              <button className="btn btn-link" onClick={() => showEmptyReturnsInfo(data)}>
                {emptyReturnsInfo.includes(data) ? <IconAngleArrowTop /> : <IconAngleArrowRight />}
              </button>
            </div>
          </td>
        </tr>
        {emptyReturnsInfo.includes(data) && (
          <>
            {containerTypes.map((types: any, index: number) => {
              return (
                <tr key={index}>
                  {types.checked && (
                    <React.Fragment>
                      <td width="100"> {types.label} </td>
                      {portsArray.map((pp: any, pIndex: any) => {
                        if (pp.checked) {
                          let sslData = filterEmptyReturn(pp, types);
                          if (
                            sslData?.port === updateStatus?.port &&
                            sslData?.types === updateStatus?.types &&
                            sslData?.ssl === updateStatus?.ssl
                          ) {
                            sslData = updateStatus
                              ? { ...sslData, shift1: updateStatus?.shift1 }
                              : sslData;
                          }
                          return sslData && sslData.hasOwnProperty("shift1") ? (
                            <td key={pIndex} className="text-center">
                              <div className="d-flex flex-column align-items-center justify-content-center">
                                <span
                                  className={` pointer text-${EmptiesIcon(sslData.shift1)}`}
                                  onClick={() => {
                                    handleClick(index, pIndex);
                                  }}
                                >
                                  {/* {loading && (
                                  <span key = {pIndex}
                                    className="spinner-border spinner-border-sm mr-2"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                )} */}
                                  {/* { loading && (<Loader/>)} */}
                                  {sslData.shift1}
                                </span>
                                {isShown.activeCell === index && isShown.portIndex === pIndex && (
                                  <div className="mt-2">
                                    <SelectFieldWithoutControl
                                      className="min-200"
                                      classNamePrefix="select"
                                      isLoading={false}
                                      isClearable={false}
                                      isSearchable={false}
                                      name="status"
                                      options={
                                        terminal === "LA" ? LA_STATUS_OPTIONS : NY_STATUS_OPTIONS
                                      }
                                      size="small"
                                      placeholder="Select.."
                                      value={
                                        terminal === "LA"
                                          ? status &&
                                            LA_STATUS_OPTIONS.find(
                                              (sta: any) => sta.status === status
                                            )
                                          : status &&
                                            NY_STATUS_OPTIONS.find(
                                              (sta: any) => sta.status === status
                                            )
                                      }
                                      onChange={(value: any) =>
                                        handleStatusChange(
                                          value,
                                          pp,
                                          types,
                                          todayDate,
                                          terminal,
                                          data,
                                          shift
                                        )
                                      }
                                    />
                                  </div>
                                )}
                                <div className="position-relative tooltip-label">
                                  {sslData.remarks && (
                                    <IconWarning className="ml-1 text-primary wh-10px text-warning" />
                                  )}
                                  {sslData.remarks ? (
                                    <div
                                      className="tooltip tooltip--gray-700 mw-200"
                                      data-placement="top-center"
                                    >
                                      {sslData.remarks}
                                      <span className="arrow"></span>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </td>
                          ) : (
                            <td key={pIndex} className="text-center"></td>
                          );
                        }
                      })}
                    </React.Fragment>
                  )}
                </tr>
              );
            })}
            <tr>
              <td width="100">Remarks</td>
              {portsArray.map((pp: any, pIndex: any) => {
                if (pp.checked) {
                  const sslData = filterEmptyReturn(pp, { label: "20 ST", checked: true });
                  return sslData && sslData?.moreRemarks ? (
                    <td key={pIndex} className="text-center">
                      <div className="position-relative tooltip-label">
                        <IconWarning className="text-primary wh-10px text-warning" />
                        <div
                          className="tooltip tooltip--gray-700 mw-300"
                          data-placement="top-center"
                        >
                          {sslData?.moreRemarks}
                          <span className="arrow"></span>
                        </div>
                      </div>
                    </td>
                  ) : (
                    <td key={pIndex} className="text-center"></td>
                  );
                }
              })}
            </tr>
          </>
        )}
      </tbody>
    </>
  );
};
export default NYEmptyTable;
