import { getPermissionArrays } from "Common/utils";
export const SettingTabs = (user: any, val: any) => {
  const mainItems: any = {
    emptyReturnItems: [
      {
        id: 1,
        title: "Curent Empties",
        path: "/current-empties",
        active: [
          "LA-LB",
          "NJ-NY",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
        ],
        terminal: true,
        permissions: [],
      },
      {
        id: 2,
        title: "Empty History",
        path: "/empty-history",
        active: ["per-diem-records", "terminal-screengrabs", "current-emptiesSlots"],
        terminal: true,
        permissions: [],
      },
      {
        id: 3,
        title: "Empty Slots",
        path: "/current-emptiesSlots",
        active: ["la-lb", "nj-ny"],
        terminal: true,
        permissions: [],
      },
    ],
    bookings: [
      {
        id: 1,
        title: "Bookings",
        path: "/bookings",
        active: [""],
        terminal: false,
        permissions: [],
      },
    ],
    importAvailabilityItems: [
      {
        id: 1,
        title: "Import Availability",
        path: "/import-availability",
        active: [""],
        terminal: false,
        permissions: [],
      },
      // {
      //   id: 2,
      //   title: "Archived",
      //   path: "/archived",
      //   active: [],
      //   terminal: false,
      // },
    ],
    settingsItems: [
      {
        id: 1,
        title: "Select Terminals",
        path: "/settings/select-terminal",
        active: ["select-terminal"],
        terminal: false,
        permissions: [],
      },
      {
        id: 2,
        title: "Port Credentials",
        path: "/settings/port-credentials",
        active: ["port-credentials"],
        terminal: false,
        permissions: [],
      },
      {
        id: 3,
        title: "Update Profile",
        path: "/settings/update-profile",
        active: ["update-profile"],
        terminal: false,
        permissions: [],
      },
      {
        id: 4,
        title: "Invite Your Team",
        path: "/settings/invite-team",
        active: ["invite-team"],
        terminal: false,
        permissions: [],
      },
      {
        id: 5,
        title: "Update Token",
        path: "/settings/update-token",
        active: ["update-token"],
        terminal: false,
        permissions: [],
      },
    ],
    problemContainersItems: [
      {
        id: 1,
        title: "Problem Containers",
        path: "/problem-containers",
        active: [""],
        terminal: false,
        permissions: [],
      },
    ],
    dashboard: [
      {
        id: 1,
        title: "Dashboard",
        path: "/dashboard/containers",
        active: ["containers"],
        terminal: false,
        permissions: ["SuperAdmin"],
      },
    ],
    vesselScheduleItems: [
      {
        id: 1,
        title: "Vessel Schedule",
        path: "/vessel-schedule",
        active: [""],
        terminal: false,
      },
    ],
    reports: [
      {
        id: 1,
        title: "Not Updated Containers",
        path: "/reports/not-updated-containers",
        active: [""],
        permissions: ["SuperAdmin"],
      },
      {
        id: 2,
        title: "Report Container",
        path: "/reports/report-container",
        active: [""],
        terminal: false,
        permissions: ["SuperAdmin"],
      },
      {
        id: 3,
        title: "Missing Container",
        path: "/reports/missing-report-container",
        active: [""],
        terminal: false,
        permissions: ["SuperAdmin"],
      },
      {
        id: 4,
        title: "Archive Container",
        path: "/reports/archive-container",
        active: [""],
        terminal: false,
        permissions: ["SuperAdmin"],
      },
      {
        id: 5,
        title: "Wrong Data Reports",
        path: "/reports/wrong-data-reports",
        active: [""],
        permissions: ["SuperAdmin"],
      },
      {
        id: 6,
        title: "Successful Data Reports",
        path: "/reports/successful-data-reports",
        active: [""],
        permissions: ["SuperAdmin"],
      },
      {
        id: 7,
        title: "Drayos Report",
        path: "/reports/drayos-data-reports",
        active: [""],
        permissions: ["SuperAdmin"],
      },
      {
        id: 8,
        title: "Successful Metrics Reports",
        path: "/reports/successful-matrics-container-report",
        active: [""],
        permissions: ["SuperAdmin"],
      },
      {
        id: 8,
        title: "Empties Slot Reports",
        path: "/reports/empties-slot-report",
        active: [""],
        permissions: ["SuperAdmin"],
      },
      {
        id: 9,
        title: "Appointment Report",
        path: "/reports/appointment-report",
        active: [""],
        permissions: ["SuperAdmin"],
      },
      {
        id: 10,
        title: "Appointment Slot Report",
        path: "/reports/appointment-slot-report",
        active: [""],
        permissions: ["SuperAdmin"],
      },
      {
        id: 11,
        title: "Drayos Standalone Report",
        path: "/reports/drayos-standalone-report",
        active: [""],
        permissions: ["SuperAdmin"],
      },
      {
        id: 12,
        title: "Drayos Standalone Report Snapshot",
        path: "/reports/drayos-baseline-snapshot-report",
        active: [""],
        permissions: ["SuperAdmin"],
      },
    ],

    gateHoursItems: [
      {
        id: 1,
        title: "Gate Hour",
        path: "/gate-hour",
        active: [""],
        terminal: false,
        permissions: [],
      },
    ],
    emptyCorrectionItems: [
      {
        id: 1,
        title: "Empty Correction",
        path: "/admin-empty",
        active: [""],
        terminal: false,
        permissions: [],
      },
    ],
    emptyCorrect: [
      {
        id: 1,
        title: "Empty Correction",
        path: "/new-empty-correction",
        active: ["LA-LB", "NJ-NY"],
        terminal: true,
        permissions: [],
      },
    ],
    supports: [
      {
        id: 1,
        title: "Sessions",
        path: "/support/sessions",
        active: [""],
        permissions: ["SuperAdmin"],
      },
      {
        id: 2,
        title: "Tokens",
        path: "/support/tokens",
        active: [""],
        permissions: ["SuperAdmin"],
      },
      {
        id: 3,
        title: "TTI Updater",
        path: "/support/tti-blankindex-updater",
        active: [""],
        permissions: ["SuperAdmin"],
      },
      {
        id: 4,
        title: "TIR Upload",
        path: "/support/upload-folder",
        active: [""],
        permissions: ["SuperAdmin"],
      },
      {
        id: 5,
        title: "Cookies",
        path: "/support/cookies",
        active: [""],
        permissions: ["SuperAdmin"],
      },
      {
        id: 6,
        title: "HTML Upload",
        path: "/support/upload-html",
        active: [""],
        permissions: ["SuperAdmin"],
      },
      {
        id: 7,
        title: "Site Tracker",
        path: "/support/site-tracker",
        active: [""],
        permissions: ["SuperAdmin"],
      },
    ],
  };
  if (user.role) {
    const filterLinks: any = getPermissionArrays(mainItems[val], user.role);
    return filterLinks;
  }
  return [];
};
