import CustomInputField from "Common/Components/Inputs/CustomInputField";
import { SelectFieldWithoutControl } from "Common/Components/Inputs/CustomSelectField";
import { IconCheck } from "Common/Icons";
import { useAppContext } from "contexts/AppContext";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { assignTerminal } from "reducers/action/appAction";
import { collapseTextChangeRangesAcrossMultipleVersions } from "typescript";
import { ITerminalTypes } from "../../OnboardingModal/onboarding";

const TerminalSelectForm = () => {
  const { state, myDispatch } = useAppContext();
  const { loggedUser, loading } = state.appStates;
  const { terminals } = state.configStates;
  const [allTerminals, setAllTerminals] = useState(terminals);

  const [selectedTerminal, setSelectedTerminal] = useState<ITerminalTypes | null>(null);

  const handleSelectedTerminal = (port: ITerminalTypes) => {
    loggedUser.role === "SuperAdmin" && setSelectedTerminal(port);
  };

  // Assign and Update Terminal
  const updateTerminal = async () => {
    if (selectedTerminal?._id) {
      let data = { terminalId: [selectedTerminal._id] };

      if (data.terminalId.length) {
        let res = await assignTerminal(data);
        myDispatch(res);
      } else {
        let emptyData: any = {};
        let res = await assignTerminal(emptyData);
        myDispatch(res);
      }
    }
  };

  useEffect(() => {
    const userSelectedTerminal = loggedUser?.terminal?.find((tt: any) => tt);
    setSelectedTerminal(userSelectedTerminal);
  }, [loggedUser]);

  useEffect(() => {
    if (loggedUser.role !== "SuperAdmin") {
      let termin = terminals.filter((tt: any) => {
        return loggedUser?.terminal?.find((ttt: any) => ttt._id === tt._id);
      });
      setAllTerminals(termin);
    } else {
      setAllTerminals(terminals);
    }
  }, [terminals]);

  return (
    <div className="w-auto text-right">
      <div className="form-wrapper bg-white p-4 rounded shadow-sm">
        <div className="c-list__grid d-flex flex-wrap">
          {allTerminals?.length > 0 &&
            allTerminals.map((terminal: ITerminalTypes, index: number) => (
              <div
                className={`clist__card clist__card--outline text-center py-15 px-30 ${
                  selectedTerminal?.name === terminal.name ? "active" : ""
                }`}
                key={index}
                onClick={() => handleSelectedTerminal(terminal)}
              >
                {terminal?.name?.toUpperCase()}
                <div className={selectedTerminal?.name === terminal.name ? "checked" : "unchecked"}>
                  <IconCheck className="text-white" />
                </div>
              </div>
            ))}
        </div>
      </div>
      <button className="btn btn-primary mt-20" onClick={() => updateTerminal()}>
        {loading && (
          <span
            className="spinner-border spinner-border-sm mr-2"
            role="status"
            aria-hidden="true"
          ></span>
        )}
        Update Terminal
      </button>
    </div>
  );
};

export default TerminalSelectForm;
