import Datetime from "react-datetime";
import { IconFilter, IconCalendar, IconPrinter } from "Common/Components/Icons/Icons";
import React, { useEffect, useState } from "react";
import FilterPop from "./filterPop";
import { SearchPropsI, ValueTypes } from "../location";
import { useAppContext } from "contexts/AppContext";
import { getFilterEmptiesData } from "pages/EmptyReturn/action";
import moment, { Moment } from "moment";
import { newMarketWithTwoShift, terminalNameConstant } from "pages/EmptyReturn/constant";
import { getDuplicateArrayofObjects, getTimeZone, getTodayDate } from "./utils";
import { SelectFieldWithoutControl } from "Common/Components/Inputs/CustomSelectField";
import axios from "axios";
import { headers } from "config/api";
import { downloadImage } from "utils/commonFunctions";

const SearchFilter = (props: SearchPropsI) => {
  const {
    terminal,
    terminalCode,
    tab,
    onTerminalChange,
    page,
    onDateChange,
    imageUrl,
    currentEmptyContainerOwner,
    isTomorrow,
  } = props;
  const { state, myDispatch } = useAppContext();
  const { userSelectedTerminals } = state.appStates;
  const { ports, terminals } = state.configStates;

  // ---------------------------------------------------- STATE MANAGEMENT---------------------------------------------------//
  const [ShowFilter, setShowFilter] = useState(false);
  const [date, setDate] = useState<Moment>();
  const [utcDate, setUtcDate] = useState("");
  const [selectedTerminal, setSelectedTerminal] = useState<ValueTypes>({
    value: userSelectedTerminals?.[0]?.code,
    label: userSelectedTerminals?.[0]?.name?.toUpperCase(),
    code: userSelectedTerminals?.[0]?.code,
  });
  const [selectedPort, setSelectedPort] = useState<ValueTypes | null>(null);
  const [downloading, setDownloading] = useState(false);
  const [portOptions, setPortOptions] = useState<any>();
  const [allTerminalOptions, setAllTerminalOptions] = useState<any>();

  let finalData =
    terminal && terminal !== ""
      ? { terminal: terminalNameConstant[terminal] }
      : {
          terminal: terminalNameConstant[selectedTerminal?.value],
        };

  // ---------------------------------------------------- HOOKS MANAGEMENT ---------------------------------------------------//
  useEffect(() => {
    const AVAILABLE_TERMINAL_CHOICES = terminals.map((termi: any) => {
      return { value: termi.code, label: termi.name.toUpperCase() };
    });
    AVAILABLE_TERMINAL_CHOICES.length && setAllTerminalOptions(AVAILABLE_TERMINAL_CHOICES);
    const newMarketPorts = ports.filter(
      (x: any) => x?.terminalCode === userSelectedTerminals?.[0]?.code
    );
    const NEW_MARKET_PORT_CHOICES = newMarketPorts.map((x: any) => {
      return { value: x.code, label: x.code };
    });
    setPortOptions(NEW_MARKET_PORT_CHOICES);
  }, [userSelectedTerminals, ports, terminals, selectedTerminal]);

  //use effect to select port when selected terminal changes
  useEffect(() => {
    Array.isArray(portOptions) && portOptions.length > 0 && setSelectedPort(portOptions?.[0]);
  }, [portOptions, allTerminalOptions, selectedTerminal]);

  useEffect(() => {
    if (utcDate && selectedTerminal.value && page === "historyEmpties") {
      const payload = {
        terminal: selectedTerminal.value || terminal,
        date: utcDate,
      };
      const timeZone = getTimeZone(selectedTerminal.value);
      onDateChange && onDateChange(moment(utcDate));
      myDispatch(getFilterEmptiesData(payload));
    } else if (date && selectedTerminal && page === "screenshot" && selectedPort) {
      const payloadPort =
        [1, 4].includes(Number(selectedTerminal?.value)) &&
        selectedPort?.value !== "EVERPORT TERMINAL"
          ? selectedPort?.value?.replaceAll(" ", "_")
          : selectedPort?.value;

      const payload = {
        terminal: terminalNameConstant[selectedTerminal?.value],
        date: utcDate,
        ports: [payloadPort],
      };
      myDispatch(getFilterEmptiesData(payload));
    }
  }, [utcDate, selectedTerminal, selectedPort, ports]);

  useEffect(() => {
    if (page === "historyEmpties" || ("screenshot" && selectedTerminal)) {
      const timeZone = getTimeZone(selectedTerminal.value);
      setDate(moment(getTodayDate()));
      onDateChange && onDateChange(moment(getTodayDate()));
      setUtcDate(getTodayDate().toISOString());
      const allAvailablePorts = ports.filter(
        (x: any) => x?.terminalCode === selectedTerminal?.value
      );
      const PORT_CHOICES = allAvailablePorts.map((x: any) => {
        return { value: x.code, label: x.code.toUpperCase() };
      });
      setPortOptions(PORT_CHOICES);
    }
  }, [selectedTerminal]);

  // ---------------------------------------------------- HANDLE FUNCTIONS ---------------------------------------------------//
  const handleShowFilter = () => {
    setShowFilter(true);
  };
  const handleHideFilter = () => {
    setShowFilter(false);
  };

  const handleDateChange = (dateValue: any) => {
    const timeZone = getTimeZone(selectedTerminal.value);
    const momentDateValue = moment(dateValue);
    setDate(momentDateValue);
    setUtcDate(momentDateValue.toISOString());
  };

  const handlePortChange = (port: ValueTypes) => {
    setSelectedPort(port);
  };

  const handleTerminalChange = (term: ValueTypes) => {
    setSelectedTerminal(term);
    setSelectedPort(null);
    onTerminalChange(term.value);
  };

  const filterEmptyData = (arr: string[][]) => {
    if (date && page === "historyEmpties") {
      Object.assign(finalData, {
        date: utcDate,
      });
    }
    if (arr[0].length) {
      Object.assign(finalData, { ports: arr[0] });
    }
    if (arr[1].length) {
      Object.assign(finalData, { ssl: arr[1] });
    }
    if (arr[2].length) {
      Object.assign(finalData, { types: arr[2] });
    }

    if (terminalCode) {
      finalData.terminal = terminalNameConstant[terminalCode];
    }

    myDispatch(getFilterEmptiesData({ ...finalData, isTomorrow }));
  };

  const download = () => {
    setDownloading(true);
    axios({
      url: imageUrl,
      responseType: "blob",
      headers,
    })
      .then((response) => {
        const url = window.URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${selectedPort?.label}-${date}.jpg`);
        document.body.appendChild(link);
        link.click();
      })
      .finally(() => {
        setDownloading(false);
      });
  };
  const s3ImageUrl =
    selectedPort?.label.startsWith("MAHER") || selectedPort?.label.startsWith("GCT BAYONNE")
      ? imageUrl[1]
      : imageUrl;

  return (
    <React.Fragment>
      <div className="filter-row d-flex align-items-center mb-10">
        {/* Search Bar */}
        {/* <div className="app-search header-search mr-20">
          <span className="search-icon"> </span>
          <div className="position-relative">
            <input
              type="search"
              className="form-control input-search-left w-200"
              placeholder="Search..."
            />
          </div>
        </div> */}
        {page === "currentEmpties" &&
          (userSelectedTerminals[0]?.code === 1 ||
            newMarketWithTwoShift.includes(terminalCode)) && (
            <h6 className="mb-0 text-danger">
              These matrices are subject to change on a per-shift basis.
            </h6>
          )}
        {!(
          terminal === "LA" ||
          terminal === "NY" ||
          (terminalCode && Number(terminalCode) > 2)
        ) && (
          <div className="input-wrapper w-200 mr-2">
            <Datetime
              inputProps={{
                placeholder: "Select date..",
              }}
              onChange={(value) => handleDateChange(value)}
              closeOnSelect
              value={date}
            />
            <div className="input-icon">
              <IconCalendar />
            </div>
          </div>
        )}

        {(tab === "Terminal Screengrabs" || tab === "Per Diem Records") && (
          <>
            <SelectFieldWithoutControl
              className="w-200 mr-2"
              classNamePrefix="select"
              isLoading={false}
              isClearable={false}
              isSearchable={false}
              name="terminal"
              options={allTerminalOptions}
              size="small"
              placeholder="Select Terminal"
              value={selectedTerminal}
              onChange={(value: any) => handleTerminalChange(value)}
            />

            {tab === "Terminal Screengrabs" && (
              <>
                <SelectFieldWithoutControl
                  className="w-300 mr-2"
                  classNamePrefix="select"
                  isLoading={false}
                  isClearable={false}
                  isSearchable={false}
                  name="port"
                  options={portOptions}
                  size="small"
                  value={selectedPort}
                  placeholder="Select Port"
                  onChange={(value: any) => handlePortChange(value)}
                />
                <div className="ml-20">
                  {/* <a href={imageUrl} download rel="noreferrer"> */}
                  <button
                    className=" btn btn-primary"
                    onClick={() =>
                      downloadImage(s3ImageUrl, selectedPort?.label, date, setDownloading)
                    }
                  >
                    {downloading && (
                      <span
                        className="spinner-border spinner-border-sm mr-2"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}
                    <IconPrinter className="mr-10" />
                    Download File
                  </button>
                  {/* </a> */}
                </div>
              </>
            )}
          </>
        )}
        {(terminal === "LA" || terminal === "NY" || tab === "Per Diem Records" || terminalCode) && (
          <div className="ml-auto d-flex">
            <button className="btn btn-outline-light" onClick={handleShowFilter}>
              <IconFilter className="mr-2" />
              Filter
            </button>
          </div>
        )}
      </div>
      {ShowFilter && (
        <FilterPop
          terminal={terminal || selectedTerminal.value}
          terminalCode={terminalCode}
          filterEmptyData={filterEmptyData}
          hideFilter={handleHideFilter}
          currentEmptyContainerOwner={currentEmptyContainerOwner}
        />
      )}
    </React.Fragment>
  );
};

export default SearchFilter;
