import { SelectFieldWithoutControl } from "Common/Components/Inputs/CustomSelectField";
import React, { useEffect, useState } from "react";
import {
  getInitialFilters,
  LA_PORT_CHOICES,
  NY_PORT_CHOICES,
  LA_SHIFT_OPTIONS,
  NY_SHIFT_OPTIONS,
  LA_STATUS_OPTIONS,
  NY_STATUS_OPTIONS,
} from "./constant";
import { useAppContext } from "contexts/AppContext";
import { getEmptyCorrections } from "pages/EmptyReturn/action";

const EmptyTable = (props: any) => {
  const { selectedTerminal, data, s3Url } = props;
  console.log(data, "data is here ");
  const { state, myDispatch } = useAppContext();
  // ---------------------------------------------------- STATE MANAGEMENT---------------------------------------------------//
  const [sslArray, setSslArray] = useState<any>([]);
  const [sslVal, setSslval] = useState<any>({});
  const [containerTypes, setContainerTypes] = useState<any>([]);
  const [containerVal, setContainerVal] = useState<any>({});
  const [shift, setShift] = useState<any>({});
  const [status, setStatus] = useState<any>({});
  const [emptyReturn, setEmptyRetrun] = useState<any>([]);
  const [portsArray, setPortsArray] = useState<any>([]);
  const [remarks, setRemarks] = useState<any>();
  // ---------------------------------------------------- HANDLE FUNCTION---------------------------------------------------//
  const handleSSlChange = (ssl: any) => {
    setSslval(ssl);
  };
  const handleContainerChange = (size: any) => {
    setContainerVal(size);
  };
  const handleShiftChange = (shiftVal: any) => {
    setShift(shiftVal);
  };
  const handleStatusChange = (statusVal: any) => {
    setStatus(statusVal);
  };
  const handleEmptyReturn = (emptylocation: any) => {
    setEmptyRetrun(emptylocation);
  };
  const handleChange = (e: any) => {
    setRemarks(e.target.value);
  };
  // ---------------------------------------------------- HOOKS MANAGEMENT ---------------------------------------------------//
  useEffect(() => {
    const allValues = getInitialFilters(selectedTerminal.value);
    if (allValues) {
      const [newports, sslValue, types] = JSON.parse(allValues);
      setPortsArray(newports.value);
      setSslArray(sslValue.value);
      setContainerTypes(types.value);
    }
  }, [selectedTerminal, getInitialFilters(selectedTerminal.value)]);

  // ----------------------------------------------------API Hit---------------------------------------------------//
  const handleSubmit = () => {
    if (
      data &&
      data.ports &&
      selectedTerminal &&
      data.date &&
      sslVal.value &&
      containerVal.value &&
      status.value &&
      shift.value
    ) {
      const payload = {
        portMarket: selectedTerminal.value,
        port: data.ports,
        date: data.date,
        ssl: sslVal.label,
        types: containerVal.label,
        s3Url: s3Url,
        [shift.value]: status.value,
        emptyReturnLocation: emptyReturn.map((emp: any) => emp.value),
        remarks: remarks,
      };
      myDispatch(getEmptyCorrections(payload));
    }
  };
  return (
    <tr>
      <td className="">1</td>
      <td>
        <SelectFieldWithoutControl
          className="min-200"
          classNamePrefix="select"
          isLoading={false}
          isClearable={false}
          isSearchable={false}
          name="ssl"
          options={sslArray}
          size="small"
          placeholder="Select SSL"
          value={sslVal && sslArray.find((st: any) => st.label === sslVal)}
          onChange={(value: any) => handleSSlChange(value)}
        />
      </td>
      <td>
        <SelectFieldWithoutControl
          className="min-200"
          classNamePrefix="select"
          isLoading={false}
          isClearable={false}
          isSearchable={false}
          name="types"
          options={containerTypes}
          size="small"
          placeholder="Select Size Type"
          value={containerVal && containerTypes.find((st: any) => st.label === containerVal)}
          onChange={(value: any) => handleContainerChange(value)}
        />
      </td>
      <td>
        <SelectFieldWithoutControl
          className="min-200"
          classNamePrefix="select"
          isLoading={false}
          isClearable={false}
          isSearchable={false}
          name="shift"
          options={selectedTerminal.value === "LA" ? LA_SHIFT_OPTIONS : NY_SHIFT_OPTIONS}
          size="small"
          placeholder="Select Shift"
          value={
            selectedTerminal.value === "LA"
              ? shift && LA_SHIFT_OPTIONS.find((st: any) => st.shift === shift)
              : shift && NY_SHIFT_OPTIONS.find((st: any) => st.shift === shift)
          }
          onChange={(value: any) => handleShiftChange(value)}
        />
      </td>
      <td>
        <div className="form-group mb-0 min-200">
          <input
            type="message"
            name="remarks"
            className="form-control"
            onChange={handleChange}
            placeholder="remarks"
          />
        </div>
      </td>
      <td>
        <SelectFieldWithoutControl
          className="min-200"
          classNamePrefix="select"
          isLoading={false}
          isClearable={false}
          isSearchable={false}
          name="status"
          options={selectedTerminal.value === "LA" ? LA_STATUS_OPTIONS : NY_STATUS_OPTIONS}
          size="small"
          placeholder="Select Status"
          value={
            selectedTerminal.value === "LA"
              ? status && LA_STATUS_OPTIONS.find((sta: any) => sta.status === status)
              : status && NY_STATUS_OPTIONS.find((sta: any) => sta.status === status)
          }
          onChange={(value: any) => handleStatusChange(value)}
        />
      </td>
      <td>
        <SelectFieldWithoutControl
          className="min-200"
          classNamePrefix="select"
          isLoading={false}
          isClearable={true}
          isSearchable={true}
          isMulti={true}
          name="emptyReturnLocation"
          size="small"
          options={selectedTerminal.value === "LA" ? LA_PORT_CHOICES : NY_PORT_CHOICES}
          value={emptyReturn}
          onChange={(value: any) => handleEmptyReturn(value)}
        />
      </td>
      <td>
        <button className="btn btn-primary" onClick={() => handleSubmit()}>
          Submit
        </button>
      </td>
    </tr>
  );
};

export default EmptyTable;
