import SubHeaderTab from "Common/Components/SubHeaderTab";
import Toastr from "Common/Components/Toastr";
import { useAppContext } from "contexts/AppContext";
import { useEffect } from "react";
import { getAllUsers, setSuperAdminsParams } from "../action";
import { DashboardPropsI } from "../dashboard";
import DashboardCard from "../DashboardCard";
import SuperAdminForm from "./Components/SuperAdminForm";
import SuperAdminTable from "./Components/SuperAdminTable";
import Pagination from "Common/Components/Pagination";
import SearchFilter from "./Components/SearchFilter";

const CreateSuperAdmin = (props: DashboardPropsI) => {
  const { tab } = props;
  const { state, myDispatch } = useAppContext();
  const {
    userCount,
    superAdminCriteria,
    isSuperAdminSuccess,
    isDeleteSuccess,
    message,
    errorMessage,
    searchParams,
  } = state.dashboardStates;

  const getSuperAdmins = async () => {
    const response = await getAllUsers(superAdminCriteria, searchParams);
    myDispatch(response);
  };

  // useEffect(() => {
  //   getSuperAdmins();
  // }, [searchParams]);

  // Pagination
  const onPaginate = (val: any) => {
    let searchParamsD = { ...searchParams, page: val };
    myDispatch(setSuperAdminsParams(superAdminCriteria, searchParamsD));
    getSuperAdmins();
  };
  return (
    <div className="page-wrapper">
      <div className="position-relative subheader-wrapper">
        <SubHeaderTab val="dashboard" />
        <div className="page-fluid">
          {!isSuperAdminSuccess && errorMessage && (
            <Toastr show={true} status="error" message={errorMessage} />
          )}
          {isSuperAdminSuccess && (
            <Toastr show={true} status="success" message={"Super Admin Created Successfully!"} />
          )}
          {isDeleteSuccess && <Toastr show={true} status="success" message={message} />}
          {!isDeleteSuccess && errorMessage && (
            <Toastr show={true} status="success" message={errorMessage} />
          )}
          <div className="top-page">
            <div className="pb-15 d-flex align-items-center">
              <h4 className="mb-0">Super Admins List</h4>
              <div className="ml-auto">
                <Pagination
                  className={"ml-auto"}
                  pageRangeDisplayed={5}
                  totalItemsCount={userCount}
                  itemsCountPerPage={searchParams.limit}
                  onChange={onPaginate}
                  activePage={searchParams.page}
                />
              </div>
            </div>
            <DashboardCard />
          </div>
          <SuperAdminForm />
          <SearchFilter />
          <SuperAdminTable />
        </div>
      </div>
    </div>
  );
};

export default CreateSuperAdmin;
