import { useEffect, useRef, useState } from "react";
import TableHeader from "./Components/TableHeader";
import SubHeaderTab from "Common/Components/SubHeaderTab";
import SearchFilter from "./Components/SearchFilter";
import { useAppContext } from "contexts/AppContext";
import TerminalTabs from "../Common/TerminalTabs";
import { getInitialFilter } from "../constant";
import { EmptiesTypes, LocationPropsI, SmallTypesI } from "../location";
import Loader from "Common/Components/Loader";
import moment from "moment";
import { getCurrentWeek, getShiftActive, getShiftFromTime } from "../Common/utils";
import "moment-timezone";
import EmptySlotAvailabilityTable from "./Components/EmptySlotAvailabilityTable";
import Download from "./Components/Download";
import { getFilteredEmptiesSlotData, setSearchParams } from "../../EmptiesSlotReport/action";
import allSSL from "./sslMapper.json";

const EmptyReturnLocationSlots = (props: LocationPropsI) => {
  const { terminalTab, tab } = props;
  const { state, myDispatch } = useAppContext();
  const {
    emptiesSlotSearchParams,
    emptiesSlotDownloading,
    filteredEmptiesSlot,
    filteredEmptiesSlotBuffer,
  } = state.reportDRStates;
  const { isTomorrow, shiftCount } = state.emptyStates;
  const todayDate = moment().format("LLLL");
  const currentShift = getShiftActive(shiftCount);

  // ---------------------------------------------------- STATE MANAGEMENT---------------------------------------------------//
  const [empytData, setEmptyData] = useState<EmptiesTypes[]>([]);
  const [emptySlotData, setEmptySlotData] = useState<EmptiesTypes[]>([]);
  const [scroll, setScroll] = useState(0);
  const [shift, setShift] = useState<string>(currentShift);
  const [LAshift, setLAShift] = useState<string>(currentShift);
  const [sslArray, setSslArray] = useState<SmallTypesI[]>([]);
  const [portsArray, setPortsArray] = useState<SmallTypesI[]>([]);
  const [containerTypes, setContainerTypes] = useState<SmallTypesI[]>([]);
  const [updateTime, setUpdateTime] = useState("");
  const [weekDateValue, setWeekDateValue] = useState(
    terminalTab === "LA" ? moment(getCurrentWeek()[0], "MM-DD-YYYY").format("LLLL") : todayDate
  );
  const divRef = useRef<HTMLDivElement>(null);
  const [timeZonedNowDate, setTimeZonedNowDate] = useState<string | null>(
    terminalTab === "NY"
      ? moment().tz("America/New_York").toISOString()
      : moment().tz("America/Los_Angeles").toISOString()
  );
  // ---------------------------------------------------- HOOKS MANAGEMENT ---------------------------------------------------//
  const [params, setParams] = useState<any>({
    terminal: terminalTab === "NY" ? 2 : 1,
    date: timeZonedNowDate,
  });

  useEffect(() => {
    if (params.sizeType && params.ssl && params.terminal && params.date) {
      const size = params.sizeType.split(" ")[0];
      const type = params.sizeType.split(" ")[1];

      const filteredEmptiesSlotsPayload = {
        terminal: params.terminal,
        date: params.date,
        size: size,
        type: type,
        ssl: params.ssl,
      };

      myDispatch(setSearchParams(filteredEmptiesSlotsPayload));
      myDispatch(getFilteredEmptiesSlotData(filteredEmptiesSlotsPayload));
    }
  }, [params.terminal, params.date, params.sizeType, params.ssl]);

  useEffect(() => {
    const allValues = getInitialFilter(terminalTab);
    if (allValues) {
      const [newports, sslValue, types] = JSON.parse(allValues);
      setPortsArray(newports.value);
      setSslArray(sslValue.value);
      setContainerTypes(types.value);
    }
  }, [terminalTab, getInitialFilter(terminalTab)]);

  useEffect(() => {
    const div = divRef.current;
    div?.addEventListener("scroll", () => {
      setScroll(Math.abs(div?.scrollTop));
    });
    return div?.removeEventListener("scroll", () => setScroll(0));
  }, []);
  // console.log("AKSLJDJASKD",filteredEmptiesSlot);
  // ---------------------------------------------------- HANDLE FUNCTIONS ---------------------------------------------------//

  const getEmptyAppointmentSlotsFromEmptyReport = (sslData: any, shiftString: string) => {
    const slotData: any = emptySlotData;

    if (sslData && slotData) {
      const { ssl, types, port } = sslData;
      const foundSSL: any = allSSL.find((s) => s.values.includes(ssl));
      const sslCode = foundSSL ? foundSSL.code : ssl;
      const allPortData: any = slotData.filter(
        (x: any) =>
          x?.port === port &&
          [types, "ALL"].includes(x?.sizeType) &&
          [sslCode, "ALL"].includes(x?.ssl)
      );
      if (allPortData && allPortData.length > 0) {
        const totalAvailableAppointmentSlots = allPortData?.reduce((sum: any, current: any) => {
          if (current && current.appointmentSlotDate) {
            const appointmentSlotDate: any = moment(current.appointmentSlotDate);
            const timeZoneDate = moment(params.date);

            const getSlotStartShift = getShiftFromTime(current?.slotStart, shiftCount);
            if (
              moment(timeZoneDate).isSame(appointmentSlotDate, "day") &&
              current.totalAvailableSlots &&
              getSlotStartShift === shiftString
            ) {
              return sum + current.totalAvailableSlots;
            } else {
              return sum;
            }
          } else {
            return null;
          }
        }, 0);
        return totalAvailableAppointmentSlots && totalAvailableAppointmentSlots > 0
          ? totalAvailableAppointmentSlots
          : 0;
      }
    }
  };

  const getDocument = () => {
    let payload = { ...emptiesSlotSearchParams };
    payload.isGenerateReport = true;
    if (payload.size && payload.type && payload.ssl && payload.terminal && payload.date) {
      const responseBuffer = getFilteredEmptiesSlotData(payload);
      myDispatch(responseBuffer);
      responseBuffer.payload.then((response) => {
        if (response?.data?.data?.buffer?.data) {
          const fileName = `emptySlotsReport_${params.ssl}_${params.sizeType}_${Date.now()}.pdf`;
          var byteArray = new Uint8Array(response.data.data.buffer.data);
          var a = window.document.createElement("a");
          a.href = window.URL.createObjectURL(
            new Blob([byteArray], { type: "application/octet-stream" })
          );
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
      });
    }
  };

  return (
    <div ref={divRef} className="page-wrapper">
      {emptiesSlotDownloading && <Loader />}
      <div className="position-relative subheader-wrapper">
        <SubHeaderTab
          val="emptyReturnItems"
          buttons={
            emptiesSlotSearchParams.size &&
            emptiesSlotSearchParams.ssl &&
            emptiesSlotSearchParams.terminal &&
            emptiesSlotSearchParams.date &&
            filteredEmptiesSlot.length > 0 &&
            params.ssl &&
            params.sizeType &&
            params.date && <Download getDocument={getDocument} running={emptiesSlotDownloading} />
          }
        />
        <div className="page-fluid">
          <div className="top-page">
            <TerminalTabs terminal={terminalTab} value="currentEmpties" />

            <SearchFilter
              sslArray={sslArray}
              containerTypes={containerTypes}
              portsArray={portsArray}
              params={params}
              setParams={setParams}
              terminalTab={terminalTab}
            />
          </div>
          <div>
            <table className="table table-card table-card--borderless w-100 mb-30 table-custom table--empties">
              {params.ssl && params.sizeType && filteredEmptiesSlot.length > 0 && (
                <TableHeader
                  updateTime={updateTime}
                  emptyData={empytData}
                  portsArray={portsArray}
                  terminal={terminalTab}
                  sslLabel={params.ssl}
                  scroll={scroll}
                  shift={terminalTab === "LA" ? LAshift : shift}
                  isTomorrow={isTomorrow}
                  date={weekDateValue}
                  fromUI={"emptiesSLot"}
                />
              )}
              {/* {sslArray?.map((ssl: SmallTypesI, index: number) => {
                return (
                  ssl.checked && (
                    <EmptyTable
                      portsArray={portsArray}
                      containerTypes={containerTypes}
                      shift={shift}
                      emptyData={empytData}
                      data={ssl.label}
                      // data={selectedOptions.ssl}
                      key={index}
                      getEmptyAppointmentSlots={getEmptyAppointmentSlots}
                    />
                  )
                );å
              })} */}
              {(!params.ssl || !params.sizeType) && (
                <tbody>
                  <tr>
                    <td colSpan={100}>Please Select SSL and Size to view the data</td>
                  </tr>
                </tbody>
              )}
              {params.ssl && params.sizeType && filteredEmptiesSlot.length <= 0 && (
                <tbody>
                  <tr>
                    <td colSpan={100}>Data Not Available</td>
                  </tr>
                </tbody>
              )}
              {params.ssl && params.sizeType && filteredEmptiesSlot.length > 0 && (
                <EmptySlotAvailabilityTable
                  portsArray={portsArray}
                  emptyData={empytData}
                  terminaTab={terminalTab}
                />
              )}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmptyReturnLocationSlots;
