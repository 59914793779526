import CustomTextAreaField from "Common/Components/Inputs/CustomTextAreaField";
import { IconInfoCircleFilled } from "Common/Icons";
import { useFieldArray } from "react-hook-form";
import UpdateTokenLabel from "./UpdateTokenLabel";

const TokenFieldArray = (props: any) => {
  const { register, control } = props;
  const { fields } = useFieldArray({
    control,
    name: "tokens",
  });
  return (
    <ul>
      {fields.map((item: any, index) => {
        return (
          <div key={index} className={`ncard p-10 border rounded-5 border-gray-100 mb-10 active`}>
            <div className="row justify-content-between">
              <div className="col-5 d-flex justify-content-between">
                <div className="align-center align-center d-flex flex-column justify-content-center align-items-center">
                  <div className="font-16 font-weight-500 text-gray-900">{item.code}</div>
                  <UpdateTokenLabel item={item} />
                </div>
              </div>
              <div className="col-7 ">
                <div className="row align-items-center justify-content-between" key={item.id}>
                  {item.token ? (
                    <>
                      <div className="col-12">
                        <CustomTextAreaField
                          defaultValue={item.token}
                          className="form-control mb-0"
                          placeholder={`Update Token`}
                          name={`tokens[${index}].token`}
                          required
                          register={register}
                          autoComplete="off"
                          rows={3}
                          dense
                        />
                      </div>
                    </>
                  ) : (
                    <div className=" col-8 d-flex justify-content-center align-items-center">
                      <span className="badge-gray-200 mr-10 rounded-circle mr-15 w-20px h-20px d-inline-flex align-items-center justify-content-center">
                        <IconInfoCircleFilled className="text-muted" />
                      </span>
                      <h5 className="font-14 d-inline-block mb-0">There is no Token Required</h5>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </ul>
  );
};

export default TokenFieldArray;
