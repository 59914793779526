import React, { Fragment, useCallback, useEffect, useState } from "react";
import SubHeaderTab from "Common/Components/SubHeaderTab";
import { IconPlus } from "Common/Icons/index";
import AddCompanyModal from "./Components/AddCompanyModal";
import SubscriptionModal from "./Components/SubscriptionModal";
import PaymentModal from "./Components/PaymentModal";
import { useAppContext } from "contexts/AppContext";

const OnCompanyModalInfo = (props: any) => {
  const { isPaid, isCompanyInfo, handleInvite } = props;
  const [AddNewCompanyModal, setAddCompanyModal] = useState(true);
  const [activeStep, setActiveStep] = useState(0);
  const [planId, setPlanId] = useState("");
  const [customerId, setCustomerId] = useState("");

  const { state, myDispatch } = useAppContext();
  const { loggedUser } = state.appStates;

  useEffect(() => {
    if (!isCompanyInfo && isPaid && activeStep === 0) {
      setActiveStep(1);
    }
  }, [props]);

  const handleNextStep = (value: number) => {
    if (value === 2 || loggedUser.isPaid) {
      handleInvite(true);
    } else {
      setActiveStep(value + 1);
    }
  };

  const handleFindPlan = (plan: string, customer: string) => {
    setPlanId(plan);
    setCustomerId(customer);
    setActiveStep(2);
  };

  const handlePreviousStep = (value: number) => {
    setActiveStep(value - 1);
  };

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <AddCompanyModal
            AddNewCompanyModal={AddNewCompanyModal}
            handleNextStep={handleNextStep}
            activeStep={activeStep}
          />
        );
      case 1:
        return (
          <SubscriptionModal
            AddNewCompanyModal={AddNewCompanyModal}
            handleNextStep={handleNextStep}
            handlePreviousStep={handlePreviousStep}
            handleFindPlan={handleFindPlan}
            setAddCompanyModal={setAddCompanyModal}
            activeStep={activeStep}
          />
        );
      case 2:
        return (
          <PaymentModal
            AddNewCompanyModal={AddNewCompanyModal}
            handleNextStep={handleNextStep}
            handlePreviousStep={handlePreviousStep}
            planId={planId}
            customerId={customerId}
            activeStep={activeStep}
          />
        );
      default:
        break;
    }
  };

  return <Fragment> {getStepContent(activeStep)} </Fragment>;
};

export default OnCompanyModalInfo;
