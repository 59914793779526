import React, { useEffect } from "react";
import { IconReset, IconTimesThin } from "../../../Common/Components/Icons/Icons";
import { FilterPropsI, MainArrayTypesI } from "../location";
import { getInitialFilter, getInitialFilterForNewMarket } from "../constant";
import { useAppContext } from "contexts/AppContext";

const FilterPop = (props: FilterPropsI) => {
  const { hideFilter, filterEmptyData, terminal, terminalCode, currentEmptyContainerOwner } = props;
  const { state } = useAppContext();
  const { ports, containerOwners } = state.configStates;
  let tempArr;

  // ---------------------------------------------------- STATE MANAGEMENT---------------------------------------------------//
  const [mainArray, setMainArr] = React.useState<MainArrayTypesI[]>([]);

  // ---------------------------------------------------- HOOKS MANAGEMENT ---------------------------------------------------//

  useEffect(() => {
    if ((terminal === "NY" || terminal === "LA") && !terminalCode) {
      const allValues = getInitialFilter(terminal);
      if (allValues) {
        setMainArr(JSON.parse(allValues));
      }
      //for new market
    } else if (terminalCode) {
      const allValues = getInitialFilterForNewMarket(
        terminalCode,
        ports,
        currentEmptyContainerOwner
      );
      if (allValues) {
        setMainArr(JSON.parse(allValues));
      }
    }
  }, [
    terminalCode &&
      currentEmptyContainerOwner?.length &&
      getInitialFilterForNewMarket(terminalCode, ports, currentEmptyContainerOwner),
    getInitialFilter(terminal),
  ]);

  // ---------------------------------------------------- HANDLE FUNCTIONS ---------------------------------------------------//
  const handleCheckValue = (
    event: React.ChangeEvent<HTMLInputElement>,
    firstIndex: number,
    childIndex: number
  ) => {
    tempArr = mainArray;
    tempArr[firstIndex].value[childIndex].checked = event.currentTarget.checked;
    setMainArr([...tempArr]);
  };
  const handleAllCheck = (event: React.ChangeEvent<HTMLInputElement>, firstIndex: number) => {
    tempArr = mainArray;
    tempArr[firstIndex].checked = event.currentTarget.checked;
    tempArr[firstIndex].value.map((ff) => {
      return (ff.checked = event.currentTarget.checked);
    });
    setMainArr([...tempArr]);
  };

  const handleFilter = () => {
    const tempPorts: string[] = [];
    const tempSSL: string[] = [];
    const tempTypes: string[] = [];
    const mainFilter: string[][] = [];
    localStorage.setItem(
      `${(terminal !== "" && terminal) || terminalCode}EmptyFilter`,
      JSON.stringify(mainArray)
    );
    hideFilter();
    mainArray.map((item) => {
      item.key === "PORTS" && item.value.map((vv) => vv.checked && tempPorts.push(vv.label));
      item.key === "SSL" && item.value.map((vv) => vv.checked && tempSSL.push(vv.label));
      item.key === "CONTAINER TYPES" &&
        item.value.map((vv) => vv.checked && tempTypes.push(vv.label));
      return null;
    });
    mainFilter.push(tempPorts, tempSSL, tempTypes);

    filterEmptyData(mainFilter);
  };

  const handleResetFilter = () => {
    localStorage.removeItem(`${(terminal !== "" && terminal) || terminalCode}EmptyFilter`);

    //set filter after removing the item
    if ((terminal === "NY" || terminal === "LA") && !terminalCode) {
      getInitialFilter(terminal);
    } else if (terminalCode) {
      getInitialFilterForNewMarket(terminalCode, ports, currentEmptyContainerOwner);
    }
    const getTerminalArray = localStorage.getItem(
      `${(terminal !== "" && terminal) || terminalCode}EmptyFilter`
    );
    if (getTerminalArray) {
      setMainArr(JSON.parse(getTerminalArray));
    }
  };

  return (
    <React.Fragment>
      <div className="bdpopup-backdrop"></div>
      <div className="bdpopup">
        <div className="bdpopup__content">
          <button className="btn btn-none bdpopup__close text-white" onClick={hideFilter}>
            <IconTimesThin className="close-icon" />
          </button>
          <div className="d-flex px-30 align-items-center justify-content-between pt-20">
            <p className="font-20 mb-0">Filters</p>
            <button onClick={() => handleResetFilter()} className="btn btn-outline-light">
              <IconReset className="" />
            </button>
          </div>
          <React.Fragment>
            <div
              className="px-30 bdpopup__content__scroll"
              style={{ height: "calc(100% - 182px)" }}
            >
              {mainArray.map((item, Iindex) => {
                return (
                  <React.Fragment key={Iindex}>
                    <div>
                      <div className="row">
                        <div className="col-md-12 d-flex align-items-center mt-20">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="Ports"
                              checked={item.checked}
                              onChange={(e) => handleAllCheck(e, Iindex)}
                            />
                            <label
                              className="form-check-label font-weight-500 font-14 d-flex align-items-center"
                              htmlFor={`${item.key}`}
                            >
                              {item.key}
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {item.value.map((pp: any, index: number) => {
                          return (
                            pp?.label !== "C&C MAINTENANCE" &&
                            pp?.label !== "COLUMBIA CONTAINER SERVICES" &&
                            pp?.label !== "IRONBOUND HYATT" &&
                            pp?.label !== "IRONBOUND DELANCY" && (
                              <div key={index} className="col-md-6 d-flex align-items-center mt-20">
                                <div className="form-check">
                                  <React.Fragment>
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id={`${pp?.label}`}
                                      checked={pp?.checked}
                                      onChange={(e) => handleCheckValue(e, Iindex, index)}
                                    />
                                    <label
                                      className="form-check-label font-weight-500 font-14 d-flex align-items-center"
                                      htmlFor={`${pp?.label}`}
                                      style={{ lineHeight: "21px" }}
                                    >
                                      {pp?.label?.replace("_", " ")}
                                    </label>
                                  </React.Fragment>
                                </div>
                              </div>
                            )
                          );
                        })}
                      </div>
                    </div>
                    {Iindex !== 2 && <div className="hr-light"></div>}
                  </React.Fragment>
                );
              })}
            </div>
            <div className="bdpopup__footer page-fluid">
              <button
                onClick={() => handleFilter()}
                className=" btn btn-primary w-100 justify-content-center align-items-center text-center"
              >
                Apply
              </button>
            </div>
          </React.Fragment>
        </div>
      </div>
    </React.Fragment>
  );
};

export default FilterPop;
