import React, { Fragment, useEffect, useState } from "react";
import SubHeaderTab from "Common/Components/SubHeaderTab";
import { IconPlus } from "Common/Icons/index";
import InviteModal from "./InviteModal";
import LoginCredential from "./LoginCredential";
import { useAppContext } from "contexts/AppContext";
import { getCredPortList, getTerminalList } from "reducers/action/appAction";
import SelectTerminals from "./SelectTerminals";
import SelectPortMarkets from "./SelectPortMarkets";
import _ from "lodash";
import Toastr from "Common/Components/Toastr";

const OnboardingInvite = (props: any) => {
  const { isOnboarding } = props;
  const { state, myDispatch } = useAppContext();
  const { onboardingTab } = state.appStates;
  const { ports, loading, assignLoading, assignPortErMessage } = state.configStates;
  const loggedUser = JSON.parse(localStorage.getItem("loggedUser") || "{}");
  const [activeStep, setActiveStep] = useState(loggedUser.role === "SuperAdmin" ? 1 : 0);
  const [showModal, setShowModal] = useState(isOnboarding);
  const [portsArray, setPorts] = useState<any>([]);
  const [selectedPorts, setSelectedPorts] = useState(null);

  // const fetchTerminalList = async () => {
  //   let res = await getTerminalList();
  //   myDispatch(res);
  // };

  // useEffect(() => {
  //   fetchTerminalList();
  // }, []);

  const handleActive = (active: number) => {
    setActiveStep(active + 1);
  };
  const handlePrevious = (previous: number) => {
    setActiveStep(previous - 1);
  };

  const handlePorts = (port: any, steps: number) => {
    setPorts(port);
    setActiveStep(steps + 1);
  };

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <InviteModal
            activeStep={activeStep}
            handleActive={handleActive}
            showModal={showModal}
            setShowModal={setShowModal}
          />
        );
      case 1:
        return (
          <SelectTerminals
            activeStep={activeStep}
            handleActive={handleActive}
            showModal={showModal}
            handlePrevious={handlePrevious}
            setShowModal={setShowModal}
          />
        );
      case 2:
        return (
          <SelectPortMarkets
            activeStep={activeStep}
            handleActive={handleActive}
            showModal={showModal}
            handlePorts={handlePorts}
            handlePrevious={handlePrevious}
          />
        );
      case 3:
        return (
          <LoginCredential
            activeStep={activeStep}
            handleActive={handleActive}
            handlePrevious={handlePrevious}
            showModal={showModal}
            setShowModal={setShowModal}
            ports={portsArray}
          />
        );
      default:
        return <div />;
    }
  };

  return (
    <div className="">
      {assignLoading && assignPortErMessage === null && (
        <Toastr show={true} status="success" message={"Port Credentials Added!"} />
      )}
      {showModal && getStepContent(activeStep)}
    </div>
  );
};

export default OnboardingInvite;
