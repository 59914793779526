export const STATIC_DAYS = [
  "MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY",
  "SATURDAY",
  "SUNDAY",
];

export const portList = [
  {
    value: "APM_TERMINAL",
    label: "APM_TERMINAL",
  },
  {
    value: "FENIX",
    label: "FENIX",
  },
  {
    value: "TTI",
    label: "TTI",
  },
  {
    value: "LBCT",
    label: "LBCT",
  },
  {
    value: "EVERPORT TERMINAL",
    label: "EVERPORT TERMINAL",
  },
  {
    value: "PCT",
    label: "PCT",
  },
  {
    value: "PIER_A",
    label: "PIER_A",
  },
  {
    value: "YTI",
    label: "YTI",
  },
  {
    value: "WBCT",
    label: "WBCT",
  },
  {
    value: "ITS_TERMINAL",
    label: "ITS_TERMINAL",
  },
  {
    value: "TRAPAC",
    label: "TRAPAC",
  },
];
