import { yupResolver } from "@hookform/resolvers/yup";
import CustomInputField from "Common/Components/Inputs/CustomInputField";
import { useAppContext } from "contexts/AppContext";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { createSuperAdmin } from "../../action";
import { superAdminSchema } from ".././SuperAdminValidation";

const SuperAdminForm = () => {
  const { state, myDispatch } = useAppContext();

  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(superAdminSchema),
  });

  const onFormSubmit = async (data: any) => {
    let response = await createSuperAdmin(data);
    myDispatch(response);
  };

  return (
    <div className="form-wrapper mt-15 bg-white px-3 pt-3 rounded shadow-sm">
      <form className="form-md" onSubmit={handleSubmit(onFormSubmit)}>
        <div className="row align-items-center">
          <div className="col-md-5">
            <CustomInputField
              placeholder="Email *"
              name="email"
              type="text"
              required
              register={register}
              autoComplete="off"
              error={Boolean(errors.email)}
              helper={errors.email ? errors.email.message : ""}
              label={
                <span>
                  <span className="text-danger">*</span> Email
                </span>
              }
              className="form-control"
            />
          </div>
          <div className="col-md-5">
            <CustomInputField
              placeholder="Password"
              autoComplete="off"
              name="password"
              type="password"
              required
              error={Boolean(errors.password)}
              register={register}
              helper={errors.password ? errors.password.message : ""}
              className="form-control"
              label={
                <span>
                  <span className="text-danger">*</span> Password
                </span>
              }
            />
          </div>
          <div className="text-right">
            <button className="btn btn-primary" type="submit">
              {state.dashboardStates.loading && (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
              Create Super Admin
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SuperAdminForm;
