import React, { useEffect, useRef, useState } from "react";
import SubHeaderTab from "Common/Components/SubHeaderTab";
import SearchFilter from "../Common/SearchFilter";
import { useAppContext } from "contexts/AppContext";
import { EmptiesTypes, HistoryPropsI, SmallTypesI } from "../location";
import { setEmptiesData } from "../action";
import { getInitialFilter } from "../constant";
import TerminalTabs from "../Common/TerminalTabs";
import HistoryTableHeader from "./Components/HistoryTableHeader";
import Loader from "Common/Components/Loader";
import moment, { Moment } from "moment";
import LAEmptyTable from "../Common/LAEmptyTable";
import NYEmptyTable from "../Common/NYEmptyTable";
import TopEmptyCard from "../Common/TopEmptyCard";

const EmptyHistory = (props: HistoryPropsI) => {
  const { tab } = props;
  const { state, myDispatch } = useAppContext();
  const { empties, eLoading } = state.emptyStates;
  const { userSelectedTerminals } = state.appStates;
  // ---------------------------------------------------- STATE MANAGEMENT---------------------------------------------------//
  const [empytData, setEmptyData] = useState<EmptiesTypes[]>([]);
  const [scroll, setScroll] = useState(0);
  const [shift, setShift] = useState<string>("shift1");
  const [sslArray, setSslArray] = useState<SmallTypesI[]>([]);
  const [portsArray, setPortsArray] = useState<SmallTypesI[]>([]);
  const [containerTypes, setContainerTypes] = useState<SmallTypesI[]>([]);
  const [terminalValue, setTerminalValue] = useState("");
  const [date, setDate] = useState("");
  const ref = useRef<HTMLDivElement>(null);

  // ---------------------------------------------------- HOOKS MANAGEMENT ---------------------------------------------------//
  useEffect(() => {
    let datas = empties?.data;
    setEmptyData(datas);
    myDispatch(setEmptiesData(datas));
  }, [empties]);

  useEffect(() => {
    setTerminalValue(userSelectedTerminals[0]?.name === "New Jersey/NY" ? "NY" : "LA");
  }, [userSelectedTerminals]);

  useEffect(() => {
    const div = ref.current;
    div?.addEventListener("scroll", () => {
      setScroll(Math.abs(div?.scrollTop));
    });
    return div?.removeEventListener("scroll", () => setScroll(0));
  }, []);

  useEffect(() => {
    const allValues = getInitialFilter(terminalValue);
    if (allValues) {
      const [newports, sslValue, types] = JSON.parse(allValues);
      setPortsArray(newports.value);
      setSslArray(sslValue.value);
      setContainerTypes(types.value);
    }
  }, [terminalValue, getInitialFilter(terminalValue)]);

  // ---------------------------------------------------- HANDLE FUNCTIONS ---------------------------------------------------//
  const onTerminalChange = (value: string) => {
    setTerminalValue(value);
  };

  const onDateChange = (dd: Moment) => {
    setDate(moment(dd).format("LLLL"));
  };

  const handleActive = (acc: any) => {
    setShift(acc.Shift);
  };

  const handleLAShiftActive = (shiftData: any) => {
    setShift(shiftData.Shift);
  };

  return (
    <div ref={ref} className="page-wrapper">
      {!eLoading && <Loader />}
      <div className="position-relative subheader-wrapper">
        <SubHeaderTab val="emptyReturnItems" />
        <div className="page-fluid">
          <div className="top-page">
            <TerminalTabs terminal={tab} value="historyEmpties" />
            <SearchFilter
              tab={tab}
              terminal=""
              onTerminalChange={onTerminalChange}
              page="historyEmpties"
              onDateChange={onDateChange}
            />
            <TopEmptyCard
              date={date}
              handleActive={handleActive}
              terminal={terminalValue}
              value="historyEmpties"
              shift={shift}
              handleLACardActive={handleLAShiftActive}
            />
          </div>
          <div className="">
            <table className="table table-card table-card--borderless w-100 mb-30">
              <HistoryTableHeader
                emptyData={empytData}
                portsArray={portsArray}
                terminal={terminalValue}
                scroll={scroll}
              />
              {terminalValue === "LA" ? (
                <React.Fragment>
                  {sslArray?.map((ssl: SmallTypesI, index: number) => {
                    return (
                      ssl.checked && (
                        <LAEmptyTable
                          portsArray={portsArray}
                          containerTypes={containerTypes}
                          shift={shift}
                          emptyData={empytData}
                          data={ssl.label}
                          key={index}
                        />
                      )
                    );
                  })}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {sslArray?.map((ssl: SmallTypesI, index: number) => {
                    return (
                      ssl.checked && (
                        <NYEmptyTable
                          portsArray={portsArray}
                          containerTypes={containerTypes}
                          shift={shift}
                          emptyData={empytData}
                          data={ssl.label}
                          key={index}
                        />
                      )
                    );
                  })}
                </React.Fragment>
              )}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmptyHistory;
