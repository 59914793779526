import _ from "lodash";
export const initialSupportValues = {
  containerCredentials: [],
  sLoading: false,
  errorMessage: "",
  containerCredentialsCount: 0,

  searchParams: {
    limit: 100,
    page: 1,
  },
  searchPost: {
    ports: ["WBCT", "EVERPORT TERMINAL", "MAHER TERMINAL", "TTI"],
    time: "1",
  },
  tLoading: false,
  tErrorMessage: null,
  tokenList: [],
  stLoading: false,
  tokenVal: {},
  cookieList: [],
  ttiBlankIndexUpdater: {
    blankIndex: "",
    sslValue: "",
    formattedValue: "",
    success: null,
    status: null,
  },
  lbctCookies: [],
  cloading: false,
  portConfig: {},
  updateTerminals: {},
};

export const supportReducers = (
  state: any,
  action: {
    type: string;
    payload: any;
  }
) => {
  switch (action.type) {
    case "CONTAINERCREDENTIALS_INPROGRESS": {
      return {
        ...state,
        sLoading: true,
      };
    }
    case "CONTAINERCREDENTIALS_SUCCESS": {
      let containerCredentials = action.payload.data.data;
      return {
        ...state,
        sLoading: false,
        containerCredentials: containerCredentials,
        containerCredentialsCount: action.payload.data.count,
      };
    }
    case "CONTAINERCREDENTIALS_REJECTED": {
      return {
        ...state,
        sLoading: false,
        containerCredentials: [],
        errorMessage: action.payload.errorMessage,
        containerCredentialsCount: 0,
      };
    }
    case "SET_CONTAINERCREDENTIALS_PARAMS": {
      let param = action.payload.searchParams ? action.payload.searchParams : state.searchParams;
      let post = action.payload.searchPost ? action.payload.searchPost : state.searchPost;
      return {
        ...state,
        searchParams: param,
        searchPost: { ...post },
      };
    }
    case "UPDATE_CONTAINERCREDENTIALS_INPROGRESS": {
      return {
        ...state,
        sLoading: true,
      };
    }
    case "UPDATE_CONTAINERCREDENTIALS_SUCCESS": {
      let container = action.payload.data.data;
      return {
        ...state,
        sLoading: false,
        containerCredentials: state.containerCredentials.map((cc: any) =>
          cc._id === container._id
            ? {
                ...cc,
                sessions: container?.sessions,
                meta: container?.meta,
                auth_failed: container?.auth_failed,
                session_failed: container?.session_failed,
              }
            : cc
        ),
        containerCredentialsCount: action.payload.data.count,
      };
    }
    case "ADD_NEWSESSION_INPROGRESS": {
      return {
        ...state,
        sLoading: true,
      };
    }
    case "ADD_NEWSESSION_SUCCESS": {
      let container = action.payload.data.data;
      return {
        ...state,
        sLoading: false,
        containerCredentials: state.containerCredentials.map((cc: any) =>
          cc._id === container._id
            ? {
                ...cc,
                sessions: container.sessions,
                meta: container?.meta,
                auth_failed: container?.auth_failed,
                session_failed: container?.session_failed,
              }
            : cc
        ),
        containerCredentialsCount: action.payload.data.count,
      };
    }
    case "SET_AVAILABLEPARAMS": {
      let post = action.payload.searchPost ? action.payload.searchPost : state.searchPost;
      let param = action.payload.searchParams ? action.payload.searchParams : state.searchParams;
      return {
        ...state,
        searchPost: { ...post, reset: false },
        searchParams: param,
      };
    }

    // List Tokens
    case "TOKENLIST_INPROGRESS": {
      return {
        ...state,
        tLoading: true,
      };
    }
    case "TOKENLIST_SUCCESS": {
      let tokenList = action.payload.data.data;
      // console.log("tokensuccess list reducer");
      return {
        ...state,
        tLoading: false,
        tErrorMessage: typeof tokenList === "string" ? tokenList : null,
        tokenList: typeof tokenList === "string" ? [] : tokenList,
      };
    }
    case "TOKENLIST_REJECTED": {
      return {
        ...state,
        tLoading: false,
        tokenList: [],
        tErrorMessage: action.payload.errorMessage,
      };
    }

    case "UPDATETOKEN_INPROGRESS": {
      return {
        ...state,
        stLoading: true,
      };
    }
    case "UPDATETOKEN_SUCCESS": {
      let tokenVal = action.payload.data.data;
      console.log("tokensuccess token", tokenVal);
      return {
        ...state,
        stLoading: false,
        tokenVal: action.payload.data,
        tokenList: state.tokenList.map((cc: any) => (cc._id === tokenVal._id ? tokenVal : cc)),
      };
    }
    case "UPDATETOKEN_REJECTED": {
      return {
        ...state,
        stLoading: false,
        errorMessage: action.payload.errorMessage,
      };
    }
    //list cookies
    case "COOKIELIST_INPROGRESS": {
      return {
        ...state,
        stLoading: true,
      };
    }

    case "COOKIELIST_SUCCESS": {
      return {
        ...state,
        stLoading: false,
        cookieList: action.payload.data.data,
      };
    }

    case "COOKIELIST_REJECTED": {
      return {
        ...state,
        stLoading: false,
        errorMessage: action.payload.data.message,
      };
    }
    case "GETBLANKINDEX_SUCCESS": {
      //  console.log(action.payload)
      let responseData = action.payload.data.body;
      responseData = JSON.parse(responseData);
      const blankIndex = responseData?.[0]?.join(" ");
      const ssl = responseData?.[1]?.join(" ");

      return {
        ...state,
        ttiBlankIndexUpdater: {
          blankIndex: blankIndex,
          sslValue: ssl,
        },
        stloading: false,
      };
    }

    case "GETBLANKINDEX_REJECTED": {
      return {
        ...state,
        stLoading: false,
        errorMessage: action.payload.errorMessage,
      };
    }

    case "UPDATEBLANKINDEX_SUCCESS": {
      return {
        ...state,
        ttiBlankIndexUpdater: {
          message: action.payload.data.message,
          status: action.payload.status,
          success: true,
        },
        stloading: false,
      };
    }

    case "UPDATEBLANKINDEX_REJECTED": {
      return {
        ...state,
        stLoading: false,
        ttiBlankIndexUpdater: {
          success: false,
          status: action.payload.status,
        },
        errorMessage: action.payload.errorMessage,
      };
    }

    case "UPDATELBCTCOOKIES_SUCCESS": {
      let lbctCookies = action.payload?.data?.data?.[0]?.response;
      if (lbctCookies?.caution) {
        lbctCookies.session_failed = true;
      } else if (lbctCookies) {
        lbctCookies.session_failed = false;
      }
      return {
        ...state,
        uLoading: false,
        lbctCookies: lbctCookies,
        cookieList: state.cookieList?.map((cl: any) =>
          cl?.portId?._id === lbctCookies?.portId &&
          cl.portUsername === lbctCookies?.portUsername &&
          cl.portPassword === lbctCookies?.portPassword
            ? {
                ...cl,
                session_failed: lbctCookies?.session_failed,
              }
            : cl
        ),
      };
    }

    case "UPDATELBCTCOOKIES_REJECTED": {
      return {
        ...state,
        uLoading: false,
        errorMessage: action.payload.message,
      };
    }

    case "UPDATELBCTCOOKIES_INPROGRESS": {
      return {
        ...state,
        uLoading: true,
      };
    }

    // Port Config for Alerts
    case "PORTCONFIG_INPROGRESS": {
      return {
        ...state,
        stLoading: true,
      };
    }

    case "PORTCONFIG_SUCCESS": {
      return {
        ...state,
        stLoading: false,
        portConfig: action.payload.data,
      };
    }

    case "PORTCONFIG_REJECTED": {
      return {
        ...state,
        stLoading: false,
        errorMessage: action.payload.data,
      };
    }

    // Update Terminals
    case "UPDATETERMINALS_INPROGRESS": {
      console.log("INPROGRESS");
      return {
        ...state,
        stLoading: true,
      };
    }

    case "UPDATETERMINALS_SUCCESS": {
      console.log("SUCCESS");
      return {
        ...state,
        stLoading: false,
        updateTerminals: action.payload.data,
      };
    }

    case "UPDATETERMINALS_REJECTED": {
      console.log("REJECTED");
      return {
        ...state,
        stLoading: false,
        errorMessage: action.payload.data,
      };
    }

    default:
      return state;
  }
};
