import { IconDownload, IconPlus, IconSendEmail } from "Common/Components/Icons/Icons";
import SubHeaderTab from "Common/Components/SubHeaderTab";
import BookingFilterRow from "pages/Bookings/Components/FilterRow";
import { useState } from "react";
import TokenFilterRow from "./TokenFilterRow";
import TokenList from "./TokenList";
import { useAppContext } from "contexts/AppContext";
import { applyTokenValue, getAlltokensByPort } from "../action";
import { awaitTimer } from "utils/commonFunctions";

interface INewToken {
  hitCount: number;
  tokenValue: string;
  portCode: string;
  userName: string;
  password: string;
  expiresAt: string;
  isNew: boolean;
  isDisable: boolean;
}

const Token = () => {
  const { state, myDispatch } = useAppContext();

  const { tLoading, tokenList } = state.supportStates;
  const [selectedPort, setSelectedPort] = useState<any | null>();
  const [newToken, setNewToken] = useState<INewToken[]>();

  const getAllTokens = (portCode: { label: string; value: string }) => {
    let data = { portCode: portCode.label };
    setSelectedPort(portCode.label);
    setNewToken([]);
    myDispatch(getAlltokensByPort(data));
  };

  const applyAllTokens = async () => {
    if (tokenList.length > 0) {
      for (const element of tokenList) {
        if (!element.isDisable) {
          myDispatch(applyTokenValue(element._id));
          await awaitTimer(3000);
        }
      }
    }
  };

  const addNewToken = () => {
    if (selectedPort) {
      let newTokens = newToken ? [...newToken] : [];
      let newValue = {
        hitCount: 0,
        tokenValue: "",
        portCode: selectedPort,
        userName: "",
        password: "",
        expiresAt: "",
        isNew: true,
        isDisable: false,
      };
      newTokens.unshift(newValue);
      setNewToken(newTokens);
    }
  };
  return (
    <div className="page-wrapper">
      <div className="position-relative subheader-wrapper">
        <SubHeaderTab val="supports" />
        <div className="page-fluid">
          <div className="top-page">
            <div className="pb-15 d-flex align-items-center">
              <h4 className="mb-0">Tokens</h4>
              {/* <Pagination className={"ml-auto"} /> */}
            </div>
            <TokenFilterRow
              getAllTokens={getAllTokens}
              addNewToken={addNewToken}
              selectedPort={selectedPort}
              applyAll={applyAllTokens}
            />
            <TokenList selectedPort={selectedPort} newToken={newToken} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Token;
