const portList = [
  {
    value: "TRAPAC",
    label: "TRAPAC",
  },
  {
    value: "TRAPAC OAK",
    label: "TRAPAC OAK",
  },
];

const portToFileName: { [key: string]: string } = {
  TRAPAC: "trapac_vesselSchedule.html",
  "TRAPAC OAK": "trapac_oak_vesselSchedule.html",
};

export { portList, portToFileName };
