import CustomSelectField, {
  CustomAsyncSelectField,
  SelectFieldWithoutControl,
} from "Common/Components/Inputs/CustomSelectField";
import { IconCancelCircle, IconReset, IconTimesThin } from "Common/Icons";
import { containerTypes, containerSizes } from "constants/containers";
import { useAppContext } from "contexts/AppContext";
import {
  resetAvailableParams,
  subscriptionStatus,
  getAllUsers,
  setUserInfoAvailableParams,
} from "pages/Dashboard/action";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import Loader from "Common/Components/Loader";
// import FilterForm from "./filterForm";

const SubscriptionPop = (props: any) => {
  const {
    showSubscriptionModal,
    handleClose,
    selectedUser,
    handleToast,
    mappingUserdataAndCarriers,
  } = props;
  const { state, myDispatch } = useAppContext();
  const { cLoading, userSearchPost, userSearchParams } = state.dashboardStates;
  const [paymentStatus, setPaymentStatus] = useState<boolean>(selectedUser.isPaid);
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [alreadyAvailableFeatures, setAlreadyAvailableFeatures] = useState<any>([{}]);

  const DRAYOSTRACKFEATURES = [
    "IMPORT_AVAILABILITY_INFO",
    "TIR_DOCUMENT",
    "EMPTY_RETURN_LOCATIONS",
    "PERDIEM_HISTORY",
    "EXPORT_INFO",
    "APPOINTMENT",
    "MANUAL_BOOKING",
  ];

  const DRAYOSTRACKFEATURES_CHOICES = DRAYOSTRACKFEATURES.map((elem) => {
    return { label: elem.replaceAll("_", " "), value: elem };
  });

  useEffect(() => {
    if (selectedUser.isPaid && selectedUser.drayosTrackFeatures !== 0) {
      const availableFeatures = selectedUser.drayosTrackFeatures;
      const availableFeaturesArray = DRAYOSTRACKFEATURES_CHOICES.map((val) => {
        if (availableFeatures.includes(val.value)) {
          return val;
        }
      });
      setAlreadyAvailableFeatures(availableFeaturesArray);
    } else {
      setAlreadyAvailableFeatures([]);
    }
  }, []);

  //   const handleReset = () => {
  //     myDispatch(resetAvailableParams());
  //   };
  const handleFeaturesChange = (e: any) => {
    setAlreadyAvailableFeatures(e);
    let features = e.map((val: any) => {
      return val.value;
    });
    setSelectedFeatures(features);
  };

  const handlePaymentSwitch = (e: any) => {
    if (paymentStatus) {
      setPaymentStatus(false);
    } else {
      setPaymentStatus(true);
    }
  };
  const handleSubscriptionFormSubmit = (e: any) => {
    e.preventDefault();

    //paymentStatus is false then [] and selected features is not clicked then default all drayosfeatures
    const response = subscriptionStatus({
      isPaid: paymentStatus,
      userId: selectedUser._id,
      drayosTrackFeatures: !paymentStatus
        ? []
        : selectedFeatures.length !== 0
        ? selectedFeatures
        : DRAYOSTRACKFEATURES,
    });
    // console.log("drayosTrackFeautres",response)
    myDispatch(response);
    response.payload
      .then(async (res: any) => {
        handleToast(true);
        handleClose();
        setAlreadyAvailableFeatures([]);
        //get all updated user after subscription
        myDispatch(setUserInfoAvailableParams(userSearchPost, userSearchParams));
        myDispatch(getAllUsers(userSearchPost, userSearchParams));
      })
      .catch((err) => {
        handleToast(true);
        console.log(err);
      });
  };
  return (
    <React.Fragment>
      {cLoading && <Loader />}
      <Modal show={showSubscriptionModal} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Subscriptions Form</Modal.Title>
          <button onClick={handleClose} className="btn btn-link">
            <IconCancelCircle onClick={handleClose} />
          </button>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>
                Email address:{" "}
                <strong>{mappingUserdataAndCarriers(selectedUser?._id, "email")}</strong>
              </Form.Label>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Drayos Track Features</Form.Label>
              <SelectFieldWithoutControl
                isLoading={false}
                isClearable={true}
                isSearchable={true}
                isMulti={true}
                name="drayosTrackFeatures"
                options={DRAYOSTRACKFEATURES_CHOICES}
                onChange={(e: any) => handleFeaturesChange(e)}
                value={alreadyAvailableFeatures}
                size="small"
                placeholder="Select Features..."
                required
              />
              {/* <Form.Control type="email" placeholder="name@example.com" autoFocus /> */}
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
              <Row>
                <Col className="d-flex align-items-center" xs="auto">
                  <Form.Label className="font-weight-bold m-0">Paid:</Form.Label>
                </Col>

                <Col>
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    onChange={(e) => handlePaymentSwitch(e)}
                    // label="Check this switch"
                    defaultChecked={paymentStatus}
                  />
                </Col>
              </Row>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubscriptionFormSubmit}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default SubscriptionPop;
