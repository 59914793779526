import { SelectFieldWithoutControl } from "Common/Components/Inputs/CustomSelectField";
import { useAppContext } from "contexts/AppContext";
import React, { useEffect, useState } from "react";
import { getTimeScaleReport, getTimeScaleTableData } from "../action";
import { getDrayoCarrierDetail } from "pages/Dashboard/action";
import { getStandardRawPorts } from "pages/DrayosStandAloneReport/action";
import Datetime from "react-datetime";
import { IconCalendar } from "Common/Icons";
import { fieldsExp, hasNo, source, terminalTypes, userChangedExp } from "../expressions";

const SearchFilter = (props: any) => {
  const { state, myDispatch } = useAppContext();
  const { setParams, params } = props;

  const { drayosUsers } = state?.dashboardStates;
  const { standardPorts } = state?.drayosReportStates;

  const carrierOptions =
    drayosUsers?.map((opt: any) => ({
      label: opt?.email,
      value: opt?._id,
    })) || [];

  const portOptions =
    standardPorts?.map((opt: any) => ({
      label: opt?.portCode,
      value: opt?.portCode,
    })) || [];

  const handleOptValChange = (e: any, type: string) => {
    const parameters = { ...params };

    if (e) {
      parameters[type] = e;

      setParams((prevParams: any) => ({
        ...prevParams,
        ...parameters,
      }));
    }

    console.log("parameters", params, e);
  };

  const handleGenerate = () => {
    console.log("selectedOptions", params);
    if (params?.startDate && params?.endDate) {
      const payloads = {
        fields: [],
      };
      const { startDate, endDate, fields, userChanged, hasNo: has, ...optionalParams } = params;
      if (has) {
        if (fields.length > 0) {
          payloads.fields = fields.map((el: any) => el.value);
        } else {
          payloads.fields = [];
        }
      }
      console.log(payloads);
      //To dynamically select field weith only value and remove null value fields
      const timeScaleReportPayload = {
        startDate,
        endDate,
        isProblemContainer: props.isProblemContainer,
        ...(payloads.fields?.length > 0 && { hasType: has }),
        ...(payloads.fields?.length > 0 && { fields: payloads.fields }),
        ...(userChanged?.length > 0 && { userChanged: userChanged.map((el: any) => el.value) }),
        ...Object.keys(optionalParams).reduce((acc: any, key) => {
          if (
            optionalParams[key] != null &&
            optionalParams[key] !== "" &&
            optionalParams[key] !== undefined
          ) {
            acc[key] = optionalParams[key];
          }
          return acc;
        }, {}),
      };

      console.log(timeScaleReportPayload);
      myDispatch(
        getTimeScaleReport({
          ...timeScaleReportPayload,
          baselineMetricType: "METRIC_DOCUMENT_COUNT",
        })
      );

      myDispatch(
        getTimeScaleTableData({
          ...timeScaleReportPayload,
          baselineMetricType: "METRIC_OVERVIEW",
        })
      );
    }
  };
  useEffect(() => {
    myDispatch(getDrayoCarrierDetail());
    myDispatch(getStandardRawPorts());
  }, []);
  return (
    <div className="filter-row d-flex align-items-center">
      <SelectFieldWithoutControl
        isLoading={false}
        isSearchable={true}
        name="CARRIER"
        options={carrierOptions}
        onChange={(e: any) => {
          handleOptValChange(e?.value, "carrierId");
        }}
        size="small"
        className="min-150 mr-2"
        placeholder="Select Carrier"
        // value={
        //   selectedOptions.CARRIER &&
        //   carrierOpts.find((opt: any) => opt.value === selectedOptions.CARRIER)
        // }
      />

      <SelectFieldWithoutControl
        isLoading={false}
        isSearchable={true}
        name="selectShipper"
        options={portOptions}
        onChange={(e: any) => handleOptValChange(e?.value, "portCode")}
        size="small"
        className="min-150 mr-2"
        placeholder="Select Shipper Name"
      />
      <div className="input-wrapper w-150 mr-2">
        <Datetime
          onChange={(value: any) =>
            handleOptValChange(value.format("YYYY-MM-DDTHH:mm:ss"), "startDate")
          }
          closeOnSelect
          inputProps={{
            placeholder: "Select start date..",
          }}
        />
        <div className="input-icon">
          <IconCalendar />
        </div>
      </div>
      <div className="input-wrapper w-150 mr-2">
        <Datetime
          onChange={(value: any) => {
            handleOptValChange(value.format("YYYY-MM-DDTHH:mm:ss"), "endDate");
          }}
          closeOnSelect
          inputProps={{
            placeholder: "Select end date..",
          }}
        />
        <div className="input-icon">
          <IconCalendar />
        </div>
      </div>
      <SelectFieldWithoutControl
        isLoading={false}
        isSearchable={true}
        name="terminalTypes"
        options={terminalTypes}
        onChange={(e: any) => handleOptValChange(e?.value, "terminalTypes")}
        size="small"
        className="min-150 mr-2"
        placeholder="Terminal Types"
      />

      <SelectFieldWithoutControl
        defaultValue={"standalone"}
        isLoading={false}
        isSearchable={true}
        name="source"
        options={source}
        onChange={(e: any) => {
          console.log(e);
          handleOptValChange(e?.value, "source");
        }}
        size="small"
        className="min-150 mr-2"
        placeholder="Source"
      />
      <SelectFieldWithoutControl
        isLoading={false}
        isSearchable={true}
        name="hasNo"
        options={hasNo}
        onChange={(e: any) => handleOptValChange(e?.value, "hasNo")}
        size="small"
        className=" mr-2"
        placeholder="Has/Has No"
      />
      <SelectFieldWithoutControl
        isDisabled={!params?.hasNo}
        isLoading={false}
        isMulti={true}
        isSearchable={true}
        name="hasNo"
        options={fieldsExp}
        onChange={(e: any) => handleOptValChange(e, "fields")}
        size="small"
        className="min-150 mr-2"
        placeholder="Fields"
      />
      <SelectFieldWithoutControl
        isMulti={true}
        isLoading={false}
        isSearchable={true}
        name="userChanged"
        options={userChangedExp}
        onChange={(e: any) => {
          console.log(e);
          handleOptValChange(e, "userChanged");
        }}
        size="small"
        className="min-150 mr-2"
        placeholder="User changed"
      />

      <div className="d-flex align-items-center mr-2">
        <input
          checked={props.isProblemContainer}
          onChange={() => props.setIsProblemContainer(!props.isProblemContainer)}
          id="problem"
          type="checkbox"
        />
        <label htmlFor="problem" className="ml-2 mt-1">
          Problem Container
        </label>
      </div>

      <button
        disabled={!params?.startDate || !params?.endDate}
        className="btn btn-success ml-2"
        onClick={() => handleGenerate()}
      >
        Generate
      </button>
    </div>
  );
};

export default SearchFilter;
