import _ from "lodash";
import SubHeaderTab from "Common/Components/SubHeaderTab";
import { useCallback, useEffect, useState } from "react";
import { getStandardRawPorts } from "pages/DrayosStandAloneReport/action";
import { useAppContext } from "contexts/AppContext";
import CustomTextAreaField from "Common/Components/Inputs/CustomTextAreaField";
import { SelectFieldWithoutControl } from "Common/Components/Inputs/CustomSelectField";
import { getPortConfig, updateTerminals } from "pages/Support/action";
import { Modal } from "react-bootstrap";
import { IconCancelCircle, IconTimes } from "Common/Icons";
import Loader from "Common/Components/Loader";

const SiteTracker = () => {
  const { state, myDispatch } = useAppContext();
  const { standardPorts, standardPortLoading } = state.drayosReportStates;
  const { portConfig, errorMessage } = state.supportStates;

  const [filteredPorts, setFilteredPorts] = useState<any>(standardPorts);
  const [portCode, setPortCode] = useState(null);
  const [updatedConfig, setUpdatedConfig] = useState<any>({});
  const [activeStatus, setActiveStatus] = useState<boolean>(true);
  const [expectedStatus, setExpectedStatus] = useState();

  // popup
  const [openPopup, setOpenPopup] = useState(false);
  const [portSpecificConfig, setportSpecificConfig] = useState<any>({});

  useEffect(() => {
    myDispatch(getStandardRawPorts());
  }, []);

  useEffect(() => {
    if (portSpecificConfig?.portCode) {
      myDispatch(getPortConfig(portSpecificConfig?.portCode));
    }
  }, [portSpecificConfig]);

  const handleActiveStatus = (e: any) => {
    setActiveStatus(e.currentTarget.checked);
  };

  useEffect(() => {
    if (portSpecificConfig) {
      setActiveStatus(portSpecificConfig?.isSiteTracker);
      setUpdatedConfig(portSpecificConfig?.config);
      setExpectedStatus(
        portSpecificConfig?.expectedStatus ? portSpecificConfig?.expectedStatus : ""
      );
    }
  }, [portCode, portSpecificConfig, portSpecificConfig]);

  const handleUpdateTerminals = () => {
    if (portSpecificConfig && Object.keys(portSpecificConfig).length > 0) {
      const dataToUpdate = {
        _id: portConfig?.[0]?._id,
        portCode: portSpecificConfig.portCode,
        config: updatedConfig,
        expectedStatus: expectedStatus,
        isSiteTracker: activeStatus,
      };
      myDispatch(updateTerminals([dataToUpdate]));
      myDispatch(getStandardRawPorts());
      setOpenPopup(false);
    }
  };

  const handlePortConfig = (config: any) => {
    setportSpecificConfig(config);
    setOpenPopup(true);
  };

  const onChangeInput = (event: any) => {
    let val = event.target.value;
    let filteredNewPorts = standardPorts.filter((sp: any) => sp.portCode.includes(val));
    setFilteredPorts(filteredNewPorts);
  };

  const debouncedChangeHandler = useCallback(_.debounce(onChangeInput, 1000), []);

  return (
    <>
      <div className="page-wrapper">
        <div className="position-relative subheader-wrapper">
          <SubHeaderTab val="supports" />
          <div className="page-fluid">
            <div className="top-page">
              <div className="py-15 d-flex align-items-center">
                <h4>Site Tracker</h4>
              </div>
            </div>
            <div className="filter-row d-flex align-items-center">
              <div className="app-search header-search">
                <span className="search-icon"> </span>
                <div className="position-relative">
                  <input
                    type="search"
                    className="form-control input-search-left"
                    placeholder="Search Port ..."
                    onChange={debouncedChangeHandler}
                  />
                </div>
              </div>
            </div>

            {standardPortLoading && <Loader />}
            <table className="table table-card">
              <thead>
                <tr>
                  <th>Sn</th>
                  <th>Port Name</th>
                  <th>Tracker Status</th>
                  <th>Action</th>
                </tr>
                {filteredPorts?.length > 0 ? (
                  filteredPorts?.map((port: any, index: number) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{port.portCode}</td>
                        <td>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            checked={port.isSiteTracker}
                          />
                        </td>
                        <td>
                          <button
                            className="btn btn-success"
                            onClick={() => handlePortConfig(port)}
                          >
                            UPDATE
                          </button>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={4}>No port Available</td>
                  </tr>
                )}
              </thead>
            </table>
          </div>
        </div>
      </div>

      {openPopup ? (
        <Modal show={openPopup}>
          <Modal.Header>
            <Modal.Title>Update {portSpecificConfig.portCode} config</Modal.Title>
            <button className="btn btn-link" onClick={() => setOpenPopup(false)}>
              <IconCancelCircle onClick={() => setOpenPopup(false)} />
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="form-row-md mb-10">
              <div className="form-group mb-0 col-md">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="activeStatus"
                    checked={activeStatus}
                    onChange={(e) => handleActiveStatus(e)}
                  />
                  <label
                    className="form-check-label font-weight-500 font-14 d-flex align-items-center"
                    htmlFor={`activeStatus`}
                  >
                    Active Tracker
                  </label>
                </div>

                <br />
                <p>Config</p>
                <CustomTextAreaField
                  placeholder="Port Config"
                  name="tokenValue"
                  className="form-control"
                  rows={20}
                  dense={false}
                  value={updatedConfig}
                  onChange={(val: any) => setUpdatedConfig(val)}
                />
                <br />
                <p>Expected Status</p>
                <CustomTextAreaField
                  name="expectedStatus"
                  className="form-control"
                  rows={1}
                  dense={false}
                  onChange={(val: any) => setExpectedStatus(val)}
                  value={expectedStatus}
                />
                <br />
              </div>
            </div>
            <div className="text-right">
              <button className="btn btn-success" onClick={handleUpdateTerminals}>
                SUBMIT
              </button>
            </div>
          </Modal.Body>
        </Modal>
      ) : (
        <> </>
      )}
    </>
  );
};

export default SiteTracker;
