import SubHeaderTab from "Common/Components/SubHeaderTab";
import { IconPlus } from "Common/Icons";
import React from "react";
import { DashboardPropsI } from "./dashboard";
import DashboardTabs from "./Common/DashboardTabs";
import SearchFilter from "./Common/SearchFilter";
import DashboardCard from "./DashboardCard";
import DashboardContainerTable from "./Common/DashboardContainerTable";
import Pagination from "Common/Components/Pagination";
import { useAppContext } from "contexts/AppContext";
import { getfilterContainer, setDashboardAvailableParams } from "./action";

const Dashboard = (props: DashboardPropsI) => {
  //API Initialize
  const { state, myDispatch } = useAppContext();
  const { cLoading, containerCount, searchParams, filterSearchPost } = state.dashboardStates;
  const { tab } = props;

  const getDashboardContainer = async (params: any) => {
    const response = await getfilterContainer(filterSearchPost, params);
    myDispatch(setDashboardAvailableParams(filterSearchPost, params));
    myDispatch(response);
  };

  // HANDLING FUNCTION
  const onPaginate = (val: any) => {
    let searchParamsD = { ...searchParams, page: val };
    getDashboardContainer(searchParamsD);
  };

  return (
    <div className="page-wrapper">
      <div className="position-relative subheader-wrapper">
        <SubHeaderTab
          val="dashboard"
          // buttons={
          //   <div className="">
          //     <button className="btn btn-success">
          //       <IconPlus className="mr-2" />
          //       Add New Container
          //     </button>
          //   </div>
          // }
        />
        <div className="page-fluid">
          <div className="top-page">
            <div className="pb-15 d-flex align-items-center">
              <h4 className="mb-0">Containers</h4>
              <div className="ml-auto">
                <Pagination
                  className={"ml-auto"}
                  pageRangeDisplayed={5}
                  totalItemsCount={containerCount}
                  itemsCountPerPage={searchParams.limit}
                  onChange={onPaginate}
                  activePage={searchParams.page}
                />
              </div>
            </div>
            <DashboardCard />
            {/* <DashboardTabs user={tab} value="dashboardTab" /> */}
            <SearchFilter />
            <DashboardContainerTable />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
