import React, { Fragment, useEffect, useState } from "react";
import { useAppContext } from "contexts/AppContext";
import { getCredPortList, getPortList, setLoggedUser } from "reducers/action/appAction";
import { ITerminalTypes } from "./OnboardingModal/onboarding";
import OnCompanyModalInfo from "./OnboardingModal/OnCompanyModalInfo";
import OnboardingInvite from "./OnboardingModal/Components/OnboardingInvite";

const Homepage = () => {
  const { state, myDispatch } = useAppContext();
  const { loggedUser, onboardingTab } = state.appStates;
  const { ports } = state.configStates;

  const [isOnboarding, setIsOnboarding] = useState(true);
  const [isCompanyInfo, setIsCompany] = useState(true);
  const [isPaid, setIsPaid] = useState(true);

  useEffect(() => {
    ports.length === 0 && getAddedPorts();
    if (!loggedUser.isCompanyDetailFill && !loggedUser.isPaid) {
      setIsCompany(true);
      setIsPaid(true);
    } else if (loggedUser.isCompanyDetailFill && !loggedUser.isPaid) {
      setIsCompany(false);
      setIsPaid(true);
    } else if (loggedUser.isCompanyDetailFill && loggedUser.isPaid) {
      setIsCompany(false);
      setIsPaid(false);
    }
    if (loggedUser.isTerminalChoosen) {
      setIsOnboarding(false);
    }
  }, []);

  const getAddedPorts = () => {
    let resPort = getPortList();
    myDispatch(resPort);
    resPort.payload.then((res) => {
      myDispatch(getCredPortList(loggedUser._id));
    });
  };

  const handleInvite = (value: boolean) => {
    setIsCompany(false);
    setIsPaid(false);
    setIsOnboarding(true);
  };

  useEffect(() => {
    if (onboardingTab === "done") {
      setIsOnboarding(false);
      getAddedPorts();
    }
  }, [onboardingTab]);

  useEffect(() => {
    if (loggedUser?.terminal && !!loggedUser.terminal.length) {
      let terminals = loggedUser.terminal.map((terminal: ITerminalTypes) => {
        if (terminal?.ports) {
          terminal.ports = ports?.filter((port: any) => {
            if (port.terminalCode === terminal.code) {
              port.terminalId = terminal._id;
              return port;
            }
          });
        }
        return terminal;
      });
      loggedUser.terminal = terminals;
      myDispatch(setLoggedUser(loggedUser));
    }
  }, [ports]);
  return (
    <Fragment>
      {isPaid && (
        <OnCompanyModalInfo
          isPaid={isPaid}
          isCompanyInfo={isCompanyInfo}
          handleInvite={handleInvite}
        />
      )}
      {!isPaid && isOnboarding && <OnboardingInvite isOnboarding={isOnboarding} />}
    </Fragment>
  );
};

export default Homepage;
