import React, { Component, Fragment } from "react";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import AsyncSelect from "react-select/async";

export interface SelectPropsI {
  isClearable?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  isRtl?: boolean;
  isSearchable?: boolean;
  isMulti?: boolean;
  className?: string;
  classNamePrefix?: string;
  defaultValue?: Object;
  name: any;
  options: any;
  label?: string;
  value?: any;
  control?: any;
  size?: string;
  placeholder?: string;
  onChange?: (value: any) => void;
  required?: boolean;
  helper?: string;
  error?: boolean;
  defaultOptions?: boolean;
}

export const smallSelectStyle = {
  control: (base: any, state: any) => ({
    ...base,
    minHeight: 30,
    borderColor: "#BFCCDA",
    borderRadius: "3px",
    boxShadow: "none",
    "&:hover": {
      borderColor: "#687d96",
      boxShadow: "none",
    },
    "&:focus": {
      border: "1px solid var(--color-primary)",
      paddingTop: "6.5px",
      paddingBottom: "6.5px",
      boxShadow: "none",
    },
    "&:active": {
      border: "1px solid var(--color-primary)",
      boxShadow: "none",
    },
  }),
  menu: (base: any) => ({
    ...base,
    innerHeight: 30,
    zIndex: 2,
  }),
  menuList: (base: any) => ({
    ...base,
  }),
  indicatorsContainer: (provided: any, state: any) => ({
    ...provided,
    height: 30,
    color: "#394E66",
  }),
  indicatorSeparator: (base: any) => ({
    ...base,
    margin: "0",
    backgroundColor: "#BFCCDA",
  }),
};
const mediumSelectStyle = {
  control: (base: any, state: any) => ({
    ...base,
    minHeight: 40,
    borderColor: "#BFCCDA",
    borderRadius: "3px",
  }),
  menu: (base: any) => ({
    ...base,
    innerHeight: 40,
    zIndex: 2,
  }),
  menuList: (base: any) => ({
    ...base,
  }),
  indicatorsContainer: (provided: any, state: any) => ({
    ...provided,
    height: 40,
    color: "#394E66",
  }),
  indicatorSeparator: (base: any) => ({
    ...base,
    margin: "0",
    backgroundColor: "#BFCCDA",
  }),
};

const CustomSelectField = (props: SelectPropsI) => {
  const {
    className,
    classNamePrefix,
    defaultValue,
    isDisabled,
    isLoading,
    isClearable,
    isSearchable,
    isRtl,
    isMulti,
    name,
    options,
    label,
    control,
    size,
    placeholder,
    helper,
    required,
    error,
  } = props;

  return (
    <Fragment>
      <div className={`form-group mb-0`}>
        {label && (
          <label className="col-form-label">
            {label} {required && <span className="text-danger">*</span>}
          </label>
        )}
        {/* @ts-ignore */}
        <Controller
          name={name}
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <Select
                isClearable
                className={className}
                classNamePrefix={classNamePrefix}
                isDisabled={isDisabled || false}
                isLoading={isLoading}
                isSearchable={isSearchable}
                defaultValue={defaultValue}
                isRtl={isRtl}
                isMulti={isMulti}
                placeholder={placeholder}
                options={options}
                value={value ? options.find((option: any) => option.value === value) : null}
                onChange={onChange}
                styles={
                  size === "small"
                    ? smallSelectStyle
                    : size === "medium"
                    ? mediumSelectStyle
                    : mediumSelectStyle
                }
              />
            );
          }}
        />
        {error && (
          <div className="mt-2 error" style={{ color: "red" }}>
            {helper}
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default CustomSelectField;

export const SelectFieldWithoutControl = (props: SelectPropsI) => {
  const {
    className,
    classNamePrefix,
    defaultValue,
    isDisabled,
    isLoading,
    isClearable,
    isSearchable,
    isRtl,
    isMulti,
    name,
    options,
    label,
    control,
    size,
    placeholder,
    helper,
    value,
    onChange,
    required,
    error,
  } = props;

  return (
    <Fragment>
      <div className={`form-group mb-0`}>
        {label && (
          <label className="col-form-label">
            {label} {required && <span className="text-danger">*</span>}
          </label>
        )}
        <Select
          isClearable={isClearable}
          className={className}
          classNamePrefix={classNamePrefix}
          isDisabled={isDisabled || false}
          isLoading={isLoading}
          isSearchable={isSearchable}
          isRtl={isRtl}
          isMulti={isMulti}
          placeholder={placeholder}
          options={options}
          value={value}
          onChange={onChange}
          styles={
            size === "small"
              ? smallSelectStyle
              : size === "medium"
              ? mediumSelectStyle
              : mediumSelectStyle
          }
        />
      </div>
    </Fragment>
  );
};

export const CustomAsyncSelectField = (props: SelectPropsI) => {
  const {
    className,
    classNamePrefix,
    defaultValue,
    isDisabled,
    isLoading,
    isClearable,
    isSearchable,
    isRtl,
    isMulti,
    name,
    options,
    label,
    control,
    size,
    placeholder,
    helper,
    required,
    error,
    defaultOptions,
  } = props;

  return (
    <Fragment>
      <div className={`form-group mb-0`}>
        {label && (
          <label className="col-form-label">
            {label} {required && <span className="text-danger">*</span>}
          </label>
        )}
        <Controller
          name={name}
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <AsyncSelect
                cacheOptions
                isClearable
                className={className}
                classNamePrefix={classNamePrefix}
                isDisabled={isDisabled || false}
                isLoading={isLoading}
                isSearchable={isSearchable}
                defaultValue={defaultValue}
                isRtl={isRtl}
                isMulti={isMulti}
                placeholder={placeholder}
                loadOptions={options}
                defaultOptions={defaultOptions}
                // value={value ? options.find((option: any) => option.value === value) : null}
                onInputChange={onChange}
                styles={
                  size === "small"
                    ? smallSelectStyle
                    : size === "medium"
                    ? mediumSelectStyle
                    : mediumSelectStyle
                }
              />
            );
          }}
        />
        {error && (
          <div className="mt-2 error" style={{ color: "red" }}>
            {helper}
          </div>
        )}
      </div>
    </Fragment>
  );
};
