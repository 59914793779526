import { IconDownload } from "Common/Icons";
import { useAppContext } from "contexts/AppContext";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { getReportContainers, getReportContainersCsv, setReportContainersParams } from "../action";
import { getDetailedValue } from "utils/commonFunctions";
import moment from "moment";
// import { read, writeFileXLSX } from "xlsx";
import * as XLSX from "xlsx";

const EXCEL_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const EXCEL_EXTENSION = ".xlsx";

interface IProps {
  page: string;
  searchPost?: any;
  searchParams?: any;
}

const DownloadCsv = (props: IProps) => {
  const { state, myDispatch } = useAppContext();
  const { reportContainerCount, csvReportContainers, csvLoading } = state.reportContainerStates;
  const { containerSizes, containerOwners, containerTypes, ports } = state.configStates;
  const { loggedUser } = state.appStates;
  const { searchParams, searchPost, page } = props;
  // get size and type
  const getSizeType = (val: any, isSize: boolean = false) => {
    if (isSize) {
      return getDetailedValue(val, containerSizes);
    } else {
      return getDetailedValue(val, containerTypes);
    }
  };
  // get port /terminal / ssl
  const getPortTerminalName = (val: any, vtype: "port" | "terminal" | "ssl") => {
    if (typeof val === "string") {
      return getDetailedValue(
        val,
        vtype === "port" ? ports : vtype === "terminal" ? loggedUser?.terminal : containerOwners
      );
    } else {
      return val?.name;
    }
  };

  // get Empty Return
  const getEmptyReturn = (vals: any) => {
    let emptyReturn: string[] = [];
    vals.length > 0 &&
      vals.map((val: any) => {
        if (typeof val === "object") {
          emptyReturn.push(val.port);
        } else {
          let portname = getPortTerminalName(val, "port");
          if (portname) emptyReturn.push(portname);
        }
      });
    return emptyReturn;
  };

  const onDownload = async () => {
    let postData = { ...searchPost };
    postData.isCsv = true;
    const response = getReportContainersCsv(postData, searchParams, page);
    myDispatch(response);
    response.payload.then((res) => {
      let data = res?.data?.data ? res?.data?.data : res?.data?.containers;
      if (data) {
        convertToCSV(data, getSizeType, getPortTerminalName, getEmptyReturn, page);
      }
    });
  };
  return (
    <button disabled={csvLoading} className="btn btn-success" onClick={() => onDownload()}>
      {csvLoading && (
        <span
          className="spinner-border spinner-border-sm mr-2"
          role="status"
          aria-hidden="true"
        ></span>
      )}

      <IconDownload />
    </button>
  );
};

export default DownloadCsv;

const convertToCSV = (
  values: any[],
  getSizeType: any,
  getPortTerminalName: any,
  getEmptyReturn: any,
  page: string
) => {
  let data = [];
  data = values.map((value: any, key: number) => {
    let csvVal: any = {};
    csvVal["S no"] = key + 1;
    if (page !== "importAvailability") {
      csvVal._id = value._id;
      csvVal.userId = value.userId?._id;
    }
    csvVal["Container #"] = value.containerNo;
    csvVal.Status = value.status;
    csvVal.Custom = value.custom;
    csvVal.Freight = value.freight;
    csvVal["Broker Hold"] = value.brokerHold;
    csvVal.SSL = getPortTerminalName(value.containerOwner, "ssl");
    csvVal["Container Size"] = getSizeType(value.containerSize, true);
    csvVal["Container Type"] = getSizeType(value.containerType);
    csvVal["Pick Up Terminal"] = getPortTerminalName(value.portId, "port");
    csvVal.ETA = value.eta
      ? moment(value.eta).format("MM/DD")
      : value.sslEta
      ? moment(value.sslEta).format("MM/DD")
      : "";
    csvVal["Discharge Date"] =
      value.status !== "On Vessel" && value.dischargeDate
        ? moment(value.dischargeDate).format("MM/DD")
        : value.sslDischargeDate
        ? moment(value.sslDischargeDate).format("MM/DD")
        : "";

    csvVal.LFD =
      value.status !== "On Vessel" && value.lastFreeDay
        ? moment(value.lastFreeDay).format("MM/DD")
        : "";

    csvVal["Out Gate Date"] =
      value.status !== "On Vessel" && value.outGateDate
        ? moment(value.outGateDate).format("MM/DD")
        : "";
    csvVal["Return Terminal"] = getEmptyReturn(value.emptyReturnLocation)
      .toString()
      .replaceAll("_", " ");
    csvVal["Terminated Date"] =
      value.status !== "On Vessel" && value.terminatedDate
        ? moment(value.terminatedDate).format("MM/DD")
        : value.inGate?.date
        ? moment(value.inGate?.date).format("MM/DD")
        : "";
    csvVal["Vessel Name"] = value.vesselName;
    csvVal["Voyage Name"] = value.voyageName;
    csvVal["Bill Of Lading"] = value.billOfLading;
    csvVal.Weight = value.grossWeight;
    csvVal.Seal = value.seal;
    csvVal["Yard Location"] = value.yardLocation;
    if (page === "problemContainer") {
      csvVal.cautionCount = value.cautionCount;
      csvVal.message = value.message;
    }
    return csvVal;
  });
  let fdata: Blob;
  const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
  const workbook: XLSX.WorkBook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
  const excelBuffer: any = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  fdata = new Blob([excelBuffer], {
    type: EXCEL_TYPE,
  });

  // const replacer = (key: any, value: any) => (value === null ? "" : value); // specify how you want to handle null values here
  // const header = Object.keys(data[0]);
  // const csv = [
  //   header.join(","), // header row first
  //   ...data.map((row) =>
  //     header.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(",")
  //   ),
  // ].join("\r\n");
  // let csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  let csvURL = null;

  csvURL = window.URL.createObjectURL(fdata);
  let tempLink = document.createElement("a");
  tempLink.href = csvURL;
  tempLink.setAttribute("download", `${page}_${new Date().getTime() + EXCEL_EXTENSION}`);
  tempLink.click();
};
