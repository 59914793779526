import { useAppContext } from "contexts/AppContext";
import { useState } from "react";
import { Link } from "react-router-dom";
import { emptyTabItems, terminalNameConstant } from "../constant";
import { EmptyTabI, TerminalPropsI } from "../location";

const TerminalTabs = (props: TerminalPropsI) => {
  const { state, myDispatch } = useAppContext();
  const { loggedUser } = state.appStates;
  const { terminal, value, terminalCode } = props;
  const [isTabActive, setIsTabActive] = useState(
    (terminalCode && terminalNameConstant[terminalCode]) || terminal
  );

  const handleTerminalChange = (label: string) => {
    setIsTabActive(label);
  };

  return (
    <div className="d-flex flex-row align-items-center justify-content-between w-100 pb-20">
      <ul className="nav nav-compact border-0 p-0" role="tablist">
        {emptyTabItems[value].map((tabItem: EmptyTabI) => {
          if (value === "currentEmpties") {
            let found = loggedUser?.terminal.find((tt: any) => tt.code === tabItem.id);
            if (!found) {
              return;
            }
          }
          return (
            <li className="nav-item" key={tabItem.id}>
              <Link
                to={tabItem.path}
                className={`nav-link ml-2 ${
                  isTabActive === tabItem.label ? "active" : "rounded-3 bg-light"
                }`}
                onClick={() => handleTerminalChange(tabItem.label)}
              >
                {tabItem.title}
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default TerminalTabs;
