import React, { Fragment } from "react";

interface IStatusBadgeProps {
  status: string;
  statusType?: string;
}
export const caseStatus = (statusName: string) => {
  switch (statusName?.toLowerCase()) {
    case "on vessel":
      return <span className="badge badge-dark-brown">On Vessel</span>;
    case "unload_from_vessel":
      return <span className="badge $badgeLightBrown">Unload From Vessel</span>;
    case "available":
      return <span className="badge badge-success">Available</span>;
    case "outgated":
      return <span className="badge badge-gray-700">Outgated</span>;
    case "terminated":
      return <span className="badge  badge-secondary">Terminated</span>;
    case "gate_in":
      return <span className="badge badge-secondary">Gate In</span>;
    case "in_yard":
      return <span className="badge badge-brand-300">In Yard</span>;
    case "grounded":
      return <span className="badge badge-brand-300">Grounded</span>;
    case "not found":
      return <span className="badge text-gray-500">Not Found</span>;
    case "not available":
      return <span className="badge badge-warning">Not Available</span>;
    case "pending":
      return <span className="badge badge-warning">Pending</span>;
    case "duplicate container":
      return <span className="badge badge-danger">Duplicate Container</span>;
    default:
      return <span className="badge text-gray-500">N/A</span>;
  }
};

const StatusBadge = (props: IStatusBadgeProps) => {
  const { status, statusType = "status" } = props;

  const caseCustom = (statusName: string) => {
    switch (statusName?.toLowerCase()) {
      case "hold":
        return <span className="badge badge-warning">Hold</span>;
      case "released":
        return <span className="badge badge-success">Released</span>;
      default:
        return <span className="badge text-gray-500">N/A</span>;
    }
  };
  return (
    <Fragment>
      {statusType === "status" && caseStatus(status)}
      {statusType === "custom" && caseCustom(status)}
      {statusType === "freight" && caseCustom(status)}
    </Fragment>
  );
};

export default StatusBadge;
