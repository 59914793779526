import axios from "axios";
import { api } from "config";
import { ICronData, IPayloadTimeScale, IStatus } from "./types/TTimeScaleTypes";

export const TIMESCALE_REPORT_TYPES = {
  GETTIMESCALEREPORT: "GETTIMESCALEREPORT",
  GETTIMESCALEREPORT_INPROGRESS: "GETTIMESCALEREPORT_INPROGRESS",
  GETTIMESCALEREPORT_SUCCESS: "GETTIMESCALEREPORT_SUCCESS",
  GETTIMESCALEREPORT_REJECTED: "GETTIMESCALEREPORT_REJECTED",
  GETTRACKED: "GETTRACKED",
  GETTRACKED_INPROGRESS: "GETTRACKED_INPROGRESS",
  GETTRACKED_SUCCESS: "GETTRACKED_SUCCESS",
  GETTRACKED_REJECTED: "GETTRACKED_REJECTED",
  GETTABLEDATA: "GETTABLEDATA",
  GETTABLEDATA_INPROGRESS: "GETTABLEDATA_INPROGRESS",
  GETTABLEDATA_SUCCESS: "GETTABLEDATA_SUCCESS",
  GETTABLEDATA_REJECTED: "GETTABLEDATA_REJECTED",
};

export const getTimeScaleReport = (payload: IPayloadTimeScale) => {
  const statusPromise = api.post("/api/snapshot-log/tracking-snapshot", {
    ...payload,
    specificField: "status",
  });
  const etaPromise = api.post("/api/snapshot-log/tracking-snapshot", {
    ...payload,
    specificField: "eta",
  });
  const freightPromise = api.post("/api/snapshot-log/tracking-snapshot", {
    ...payload,
    specificField: "freight",
  });
  const lastFreeDayPromise = api.post("/api/snapshot-log/tracking-snapshot", {
    ...payload,
    specificField: "lastFreeDay",
  });
  const res = Promise.allSettled([statusPromise, etaPromise, freightPromise, lastFreeDayPromise]);

  return {
    type: TIMESCALE_REPORT_TYPES.GETTIMESCALEREPORT,
    payload: res,
  };
};

export const getTimeScaleTableData = (payload: IPayloadTimeScale) => {
  const res = api.post<ICronData>("/api/snapshot-log/tracking-snapshot", payload);
  return {
    type: TIMESCALE_REPORT_TYPES.GETTABLEDATA,
    payload: res,
  };
};

export const getLFDDetail = (isTracked: boolean) => {
  const res = axios.get("https://mocki.io/v1/90d910a3-7548-43c6-b204-e04edcf98c1f");
  return {
    type: TIMESCALE_REPORT_TYPES.GETTRACKED,
    payload: res,
  };
};
