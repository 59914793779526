import { Modal } from "react-bootstrap";
import React, { Fragment, useState } from "react";
import { useAppContext } from "contexts/AppContext";
import TokenUpdateForm from "./TokenUpdateForm";
import Loader from "Common/Components/Loader";

const TokenList = (props: any) => {
  const { state, myDispatch } = useAppContext();
  const { tLoading, tokenList } = state.supportStates;
  let tokenLists = [...(props.newToken ? props.newToken : []), ...tokenList];
  return (
    <React.Fragment>
      {tLoading && <Loader />}
      <div className="table-responsive" style={{ height: "calc(100vh - 385px)" }}>
        <div className="form-row-md my-10">
          <div className="form-group mb-0 col-md-1 font-weight-500">SNo</div>
          <div className="form-group mb-0 col-md font-weight-500">Port</div>
          <div className="form-group mb-0 col-md font-weight-500">UserName</div>
          <div className="form-group mb-0 col-md font-weight-500">Password</div>
          <div className="form-group mb-0 col-md font-weight-500">Expired At</div>
        </div>

        {tokenLists.map((val: any, index: number) => {
          // console.log(val)
          return (
            <TokenUpdateForm
              key={val.id}
              index={index}
              val={val}
              selectedPort={props.selectedPort}
            />
          );
        })}
      </div>
    </React.Fragment>
  );
};

export default TokenList;
