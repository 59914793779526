import CredentialStatusLabel from "Common/Components/CredentialStatusLabel/CredentialStatusLabel";
import CustomInputField from "Common/Components/Inputs/CustomInputField";
import { IconInfoCircleFilled } from "Common/Icons";
import { useAppContext } from "contexts/AppContext";
import { useEffect } from "react";
import { useFieldArray } from "react-hook-form";

const FieldArray = (props: any) => {
  const { state, myDispatch } = useAppContext();
  const { maherError } = state.configStates;
  const { register, control, defaultValues, setValue, getValues } = props;
  const { fields } = useFieldArray({
    control,
    name: "ports",
  });
  return (
    <ul>
      {fields.map((item: any, index) => {
        return (
          <div key={index} className={`ncard p-10 border rounded-5 border-gray-100 mb-10 active`}>
            <div className="row justify-content-between">
              <div className="col-5 d-flex justify-content-between">
                <div className="align-center">
                  <div className="font-16 font-weight-500 text-gray-900">{item.code}</div>
                  <CredentialStatusLabel item={item} error={maherError} />
                </div>
              </div>
              <div className="col-7 align-center">
                <div className="row align-items-center justify-content-between" key={item.id}>
                  {item.isLoginRequired ? (
                    <>
                      <div
                        className={`col-6 form-group mb-0 ${
                          item.code.includes("MAHER") && maherError !== null && "error"
                        }`}
                      >
                        <CustomInputField
                          defaultValue={item.portUsername}
                          placeholder={`Enter Username`}
                          name={`ports[${index}].portUsername`}
                          type="text"
                          register={register}
                          className="form-control"
                          dense
                        />
                      </div>
                      <div
                        className={`col-6 form-group mb-0 ${
                          item.code.includes("MAHER") && maherError !== null && "error"
                        }`}
                      >
                        <CustomInputField
                          defaultValue={item.portPassword}
                          placeholder={`Enter Password`}
                          name={`ports[${index}].portPassword`}
                          type="password"
                          register={register}
                          className="form-control"
                          dense
                        />
                      </div>
                    </>
                  ) : (
                    <div className=" col-8 d-flex justify-content-center align-items-center">
                      <span className="badge-gray-200 mr-10 rounded-circle mr-15 w-20px h-20px d-inline-flex align-items-center justify-content-center">
                        <IconInfoCircleFilled className="text-muted" />
                      </span>
                      <h5 className="font-14 d-inline-block mb-0">There is no login required</h5>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </ul>
  );
};

export default FieldArray;
