import React, { Fragment, useState } from "react";
import { useAppContext } from "contexts/AppContext";
import Loader from "Common/Components/Loader";
import DetailTable from "./DetailTable";
import { statusMapper } from "../constant";
import { Modal } from "react-bootstrap";
import { getContainerDetailedError, getMissingReportContainers } from "../action";
import { IconTimes } from "Common/Icons";
import Download from "./Download";
import Pagination from "Common/Components/Pagination";

const SummaryTable = (props: any) => {
  const { state, myDispatch } = useAppContext();
  const { drayosSummary, drLoading, mloading } = state.reportDRStates;
  const [detail, setDetail] = useState<any | null>(null);
  const [pop, setPop] = useState<any | null>(null);

  const onRowClick = (element: any, i: Number, reportType: string) => {
    element = { ...element, type: reportType };
    element.page = 1;
    element.limit = 500;
    getFullDetail(element);
    setDetail(element);
  };

  const onStatusClick = (status: any) => {
    switch (status) {
      case "On_Vessel":
        setPop({ title: "On Vessel", description: statusMapper.On_Vessel });
        break;
      case "Available":
        setPop({ title: "Available", description: statusMapper.Available });
        break;
      case "Outgated":
        setPop({ title: "Outgated", description: statusMapper.Outgated });
        break;
      case "Terminated":
        setPop({ title: "Terminated", description: statusMapper.Terminated });
        break;
      default:
        setPop({ title: "Hello", description: "Description" });
        break;
    }
  };

  const onPaginate = (val: any) => {
    let payload = { ...detail };
    payload.page = val;
    setDetail(payload);
    getFullDetail(payload);
  };

  // Gett Full details of Containers including errors
  const getFullDetail = (val: any) => {
    let rePort = getMissingReportContainers(val, props.params);
    myDispatch(rePort);
    if (val.type) {
      rePort.payload.then((res: any) => {
        if (res?.data?.data?.length > 0) {
          let drayosRefs = res.data.data.map((cc: any) => cc.reference_number);
          myDispatch(getContainerDetailedError(drayosRefs));
        }
      });
    }
  };
  return (
    <Fragment>
      <div
        className="table-responsive"
        id="dispatchTable"
        style={{ height: "calc(100vh - 250px)" }}
      >
        {drLoading && <Loader />}
        <table className="table table-card" id="myTable">
          <thead>
            <tr>
              <th scope="col" className="">
                <div className="d-flex align-items-center justify-content-between">
                  <span className="mr-1">#</span>
                </div>
              </th>
              <th>Port</th>
              <th>Status</th>
              <th>Missing Field</th>
              <th>Total Count</th>
              <th>Success Count</th>
              <th>Missing Count</th>
            </tr>
          </thead>
          <tbody>
            {drayosSummary.length === 0 && (
              <tr>
                <td colSpan={100}>Please Click Above to Generate Missing Report</td>
              </tr>
            )}
            {drayosSummary.map((element: any, i: number) => {
              return (
                <Fragment key={i}>
                  <tr key={i}>
                    <td>
                      <div className="d-flex align-items-center justify-content-between">
                        <span className="mr-1">{i + 1}</span>
                        <div className="form-check"></div>
                      </div>
                    </td>
                    <td>{element.port}</td>
                    <td onClick={() => onStatusClick(element.status)}>{element.status}</td>
                    <td>{element.missing_fields}</td>
                    <td>{element.totalCount}</td>
                    <td onClick={() => !mloading && onRowClick(element, i, "success")}>
                      {element.successCount}
                    </td>
                    <td onClick={() => !mloading && onRowClick(element, i, "missing")}>
                      {element.missingCount}
                    </td>
                  </tr>
                </Fragment>
              );
            })}
          </tbody>
        </table>
      </div>

      {detail && (
        <Modal show={!!detail} centered size="xl" dialogClassName="modal-90w" scrollable={true}>
          <div className="bg-gray-50 h-100 rounded-5">
            <Modal.Title className="d-flex align-items-center px-20 py-15">
              <h5 className="font-20 mb-0">
                {detail.status +
                  " " +
                  detail.port +
                  " containers' " +
                  detail.type +
                  " " +
                  detail.missing_fields}
              </h5>
              <div className="d-flex ml-auto">
                <Download detail={detail} />
                <button className="btn btn-white shadow-none" onClick={() => setDetail(null)}>
                  <IconTimes className="mr-2" />
                  Close
                </button>
              </div>
            </Modal.Title>

            <Modal.Body
              className="p-20 pt-0"
              style={{
                maxHeight: "calc(100vh - 210px)",
                overflowY: "auto",
              }}
            >
              {<DetailTable detail={detail} />}
            </Modal.Body>
            <Modal.Footer>
              <Pagination
                className={"ml-auto"}
                pageRangeDisplayed={5}
                totalItemsCount={2000}
                itemsCountPerPage={500}
                onChange={onPaginate}
                activePage={detail.page}
              />

              <button className="btn btn-danger" onClick={() => setDetail(null)}>
                Close
              </button>
              <button className="btn btn-primary" onClick={() => setDetail(null)}>
                Understood
              </button>
            </Modal.Footer>
          </div>
        </Modal>
      )}
      {pop && (
        <Modal show={!!pop.title} centered size="xl" dialogClassName="modal-90w" scrollable={true}>
          <div className="bg-gray-50 h-100 rounded-5">
            <Modal.Title className="d-flex align-items-center px-20 py-15">
              <h5 className="font-20 mb-0">{pop.title}</h5>
              <div className="d-flex ml-auto">
                <button className="btn btn-white shadow-none" onClick={() => setPop(null)}>
                  <IconTimes className="mr-2" />
                  Close
                </button>
              </div>
            </Modal.Title>

            <Modal.Body
              className="p-20 pt-0"
              style={{
                maxHeight: "calc(100vh - 210px)",
                overflowY: "auto",
              }}
            >
              <strong>We are Mapping following Operational Statuses:</strong>
              {pop.description.map((st: any, idx: number) => {
                return (
                  <p>
                    {idx + 1}. {st}
                  </p>
                );
              })}
            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-danger" onClick={() => setPop(null)}>
                Close
              </button>
              <button className="btn btn-primary" onClick={() => setPop(null)}>
                Understood
              </button>
            </Modal.Footer>
          </div>
        </Modal>
      )}
    </Fragment>
  );
};
export default SummaryTable;
