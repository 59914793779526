export const initialarchiveContainerValues = {
  archiveContainers: [],
  rLoading: false,
  errorMessage: "",
  archiveContainerCount: 0,

  searchParams: {
    limit: 50,
    page: 1,
  },
  searchPost: {},
};

export const archiveContainerReducer = (
  state: any,
  action: {
    type: string;
    payload: any;
  }
) => {
  switch (action.type) {
    case "ARCHIVECONTAINERS_INPROGRESS": {
      return {
        ...state,
        rLoading: true,
      };
    }
    case "ARCHIVECONTAINERS_SUCCESS": {
      let archiveContainers = action.payload.data.data;
      return {
        ...state,
        rLoading: false,
        archiveContainers: archiveContainers,
        archiveContainerCount: action.payload.data.count,
      };
    }
    case "ARCHIVECONTAINERS_REJECTED": {
      return {
        ...state,
        rLoading: false,
        archiveContainers: [],
        errorMessage: action.payload.errorMessage,
        archiveContainerCount: 0,
      };
    }
    case "SET_ARCHIVECONTAINERS_PARAMS": {
      let param = action.payload.searchParams;
      let post = action.payload.searchPost;
      return {
        ...state,
        searchParams: param,
        searchPost: { ...post },
      };
    }
    case "RESET_ARCHIVECONTAINERS_PARAMS": {
      return {
        ...state,
        searchParams: {
          limit: 50,
          page: 1,
        },
      };
    }
    default:
      return state;
  }
};
