import _ from "lodash";

export const initaldashboardValues = {
  cLoading: false,
  uLoading: false,
  containers: [],
  filterContainers: [],
  filterSearchPost: {},
  userSearchPost: {},
  userData: [],
  isSuperAdminSuccess: false,
  errorMessage: "",
  superAdmins: [],
  isDeleteSuccess: false,
  containerCount: 0,
  userCount: 0,
  notUpdatedContainers: [],
  notUpdatedContainersCount: 0,
  searchParams: {
    limit: 50,
    page: 1,
  },
  userSearchParams: {
    limit: 50,
    page: 1,
  },
  superAdminCriteria: {
    role: "SuperAdmin",
  },
  drayosUsers: [],
  scacLoading: false,
  scacError: null,
  updateScacResponse: {},
};

let searchParams = {
  containerNo: "",
  containerOwner: "",
  containerSize: "",
  containerType: "",
  portId: "",
};

export const dashboardReducer = (
  state: any,
  action: {
    type: string;
    payload: any;
  }
) => {
  switch (action.type) {
    case "DASHBOARD_FILTER_CONTAINERS_INPROGRESS": {
      return {
        ...state,
        cLoading: true,
      };
    }

    case "DASHBOARD_FILTER_CONTAINERS_SUCCESS": {
      const filterContainers = action.payload.data.containers;
      return {
        ...state,
        containers: filterContainers,
        containerCount: action.payload.data.count,
        cLoading: false,
      };
    }
    case "RESET_AVAILABLEPARAMS": {
      return {
        ...state,
        filterSearchPost: { reset: true },
        searchParams: {
          limit: 50,
          page: 1,
        },
      };
    }
    case "SET_DASHBOARD_PARAMS": {
      let payload = action.payload.filterSearchPost;
      let param = action.payload.searchParams;

      return {
        ...state,
        filterSearchPost: { ...payload, reset: false },
        searchParams: param,
      };
    }

    case "GET_ALL_USERS_INPROGRESS": {
      return {
        ...state,
        uLoading: true,
        isSuperAdminSuccess: false,
        isDeleteSuccess: false,
      };
    }
    case "GET_ALL_USERS_SUCCESS": {
      let userData = action.payload.data.data;
      userData = userData.filter((data: any) => data.system !== "tmx");
      return {
        ...state,
        userData: userData,
        userCount: action.payload.data.count,
        uLoading: false,
        isSuperAdminSuccess: false,
        isDeleteSuccess: false,
      };
    }

    case "GET_ALL_USERS_REJECTED": {
      return {
        ...state,
        uLoading: false,
      };
    }

    case "SET_USER_PARAMS": {
      let payload = action.payload.userSearchPost;
      let param = action.payload.userSearchParams;

      return {
        ...state,
        userSearchPost: { ...payload },
        userSearchParams: param,
      };
    }

    case "CREATE_SUPERADMIN_INPROGRESS": {
      return {
        ...state,
        uLoading: true,
        isSuperAdminSuccess: false,
      };
    }
    case "CREATE_SUPERADMIN_SUCCESS": {
      const user = action.payload.data.user;
      return {
        ...state,
        uLoading: false,
        isSuperAdminSuccess: true,
        userData: [...state.userData, user],
      };
    }
    case "CREATE_SUPERADMIN_REJECTED": {
      return {
        ...state,
        uLoading: false,
        isSuperAdminSuccess: false,
        errorMessage: action.payload.errorMessage,
      };
    }
    case "SUPERADMIN_LIST_SUCCESS": {
      return {
        ...state,
        cLoading: false,
        superAdmins: action.payload.data.users,
        isSuperAdminSuccess: false,
        isDeleteSuccess: false,
        errorMessage: null,
      };
    }
    case "SUPERADMIN_LIST_INPROGRESS": {
      return {
        ...state,
        cLoading: true,
        isSuperAdminSuccess: false,
        isDeleteSuccess: false,
        errorMessage: null,
      };
    }
    case "SUPERADMIN_LIST_REJECTED": {
      return {
        ...state,
        cLoading: false,
        errorMessage: action.payload.errorMessage,
      };
    }
    case "DELETE_SUPERADMIN_SUCCESS": {
      return {
        ...state,
        uLoading: false,
        isDeleteSuccess: true,
        message: action.payload.data.message,
        userData: state.userData.filter((user: any) => user._id !== action.payload.data.user._id),
      };
    }
    case "DELETE_SUPERADMIN_INPROGRESS": {
      return {
        ...state,
        uLoading: true,
        isDeleteSuccess: false,
      };
    }
    case "DELETE_SUPERADMIN_REJECTED": {
      return {
        ...state,
        uLoading: false,
        isDeleteSuccess: false,
        message: action.payload.data.message,
      };
    }
    case "RESET_SUPERADMINS_PARAMS": {
      return {
        ...state,
        searchParams: {
          limit: 50,
          page: 1,
          reset: true,
        },
        superAdminCriteria: { reset: true },
      };
    }
    case "SET_SUPERADMINS_PARAMS": {
      let param = action.payload.searchParams;
      let payload = action.payload.searchPost;
      return {
        ...state,
        searchParams: param,
        superAdminCriteria: { ...payload },
        uLoading: true,
      };
    }
    case "UPDATE_CONTAINER_INPROGRESS": {
      return {
        ...state,
        cLoading: true,
      };
    }
    case "UPDATE_CONTAINER_SUCCESS": {
      const editData = action.payload.data.data;
      const currenData = state.containers;
      const findIndex = currenData.findIndex((ct: any) => ct._id === editData._id);
      currenData[findIndex] = editData;
      return {
        ...state,
        cLoading: false,
        containers: currenData,
      };
    }

    case "UPDATE_CONTAINER_REJECTED": {
      return {
        ...state,
        cLoading: true,
        errorMessage: action.payload.data?.errorMessage,
      };
    }

    case "CANCEL_SUBSCRIPTIONS_INPROGRESS": {
      return {
        ...state,
        cLoading: true,
      };
    }

    case "CANCEL_SUBSCRIPTIONS_SUCCESS": {
      const data = action.payload.data.data;
      return {
        ...state,
        cLoading: false,
        userData: state.userData.filter((us: any) => us._id !== data._id),
      };
    }

    case "CANCEL_SUBSCRIPTIONS_REJECTED": {
      return {
        ...state,
        cLoading: false,
      };
    }

    case "SUBSCRIPTION_STATUS_INPROGRESS": {
      return {
        ...state,
        cLoading: true,
      };
    }

    case "SUBSCRIPTION_STATUS_SUCCESS": {
      // console.log("new subscriptions reducer ACTION PAYLOAD", action.payload);
      const response = action.payload;
      return {
        ...state,
        cLoading: false,
      };
    }

    case "SUBSCRIPTION_STATUS_REJECTED": {
      // console.log("new subscriptions rejected reducer");
      return {
        ...state,
        cLoading: false,
        errorMessage: action.payload.errorMessage,
      };
    }
    // Not Updated Containers
    case "NOTUPDATEDCONTAINERS_LIST_SUCCESS": {
      return {
        ...state,
        cLoading: false,
        notUpdatedContainers: action.payload.data.data,
        notUpdatedContainersCount: action.payload.data.count,
      };
    }
    case "NOTUPDATEDCONTAINERS_LIST_INPROGRESS": {
      return {
        ...state,
        cLoading: true,
        notUpdatedContainersCount: state.notUpdatedContainersCount,
      };
    }
    case "NOTUPDATEDCONTAINERS_LIST_REJECTED": {
      return {
        ...state,
        cLoading: false,
        errorMessage: action.payload.errorMessage,
        notUpdatedContainersCount: state.notUpdatedContainersCount,
      };
    }
    case "RESET_NOTUPDATEDCONTAINERS_PARAMS": {
      return {
        ...state,
        searchParams: {
          limit: 50,
          page: 1,
          reset: true,
        },
      };
    }
    case "SET_NOTUPDATEDCONTAINERS_PARAMS": {
      let param = action.payload.searchParams ? action.payload.searchParams : state.searchParams;
      return {
        ...state,
        searchParams: param,
        cLoading: true,
      };
    }
    // VOYAGE UPDATES
    case "UPDATE_VOYAGE_INPROGRESS": {
      return {
        ...state,
        cLoading: true,
      };
    }
    case "UPDATE_VOYAGE_SUCCESS": {
      const editVoyageData = action.payload.data;
      return {
        ...state,
        cLoading: false,
        editVoyageData: editVoyageData,
      };
    }

    case "UPDATE_VOYAGE_REJECTED": {
      return {
        ...state,
        cLoading: true,
      };
    }

    case "DRAYOSCARRIER_DETAIL_SUCCESS": {
      const drayosUsers = action.payload.data.companies;
      return {
        ...state,
        drayosUsers: drayosUsers ? drayosUsers : [],
      };
    }

    case "UPDATESCAC_CODE_SUCCESS": {
      return {
        ...state,
        scacError: "",
        scacLoading: false,
        updateScacResponse: action.payload.data,
      };
    }

    case "UPDATESCAC_CODE_INPROGRESS": {
      return {
        ...state,
        scacLoading: true,
      };
    }

    case "UPDATESCAC_CODE_REJECTED": {
      return {
        ...state,
        scacError: action.payload,
        scacLoading: false,
        updateScacResponse: action.payload.data,
      };
    }

    // case "UPDATEGPA_AUTHORIZATION_SUCCESS": {
    //   return {
    //     ...state,
    //     scacError: action.payload,
    //     scacLoading: false,
    //   };
    // }

    // case "UPDATEGPA_AUTHORIZATION_REJECT": {
    //   return {
    //     ...state,
    //     scacError: action.payload,
    //     scacLoading: false,
    //   };
    // }

    // case "UPDATEGPA_AUTHORIZATION_INPROGRESS": {
    //   return {
    //     ...state,
    //     scacError: action.payload,
    //     scacLoading: false,
    //   };
    // }

    default:
      return state;
  }
};
