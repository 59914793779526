import { CustomIconSpreadSheet } from "Common/Components/Icons/CustomIcons";
import SubHeaderTab from "Common/Components/SubHeaderTab";
import { IconPrinter } from "Common/Icons";
import { exportToExcel, tablePrint } from "Common/utils";
import { useAppContext } from "contexts/AppContext";
import moment from "moment";
import SearchFilter from "./SearchFilter";
import React, { useEffect, useState } from "react";
import { fetchGateHours } from "../action";

import GateTable from "./GateTable";
import { terminalNameConstant, timeZoneFormatter } from "../constant";
import GateTableNewMarket from "./GateTableNewMarket";
import "moment-timezone";

const GateHour = () => {
  const { state, myDispatch } = useAppContext();
  const { userSelectedTerminals } = state.appStates;
  const { gatehour, gLoading } = state.emptyStates;

  const [terminalCode, setTerminalCode] = useState<number | string>(
    userSelectedTerminals?.[0]?.code
  );
  const [todayDate, setTodayDate] = useState<any>();

  // dynamic date
  useEffect(() => {
    setTerminalCode(userSelectedTerminals?.[0]?.code);
  }, [userSelectedTerminals]);

  useEffect(() => {
    if (terminalCode) {
      getHours();
      const timeZone = timeZoneFormatter[terminalCode];
      const todayDateMoment = moment().tz(timeZone);
      setTodayDate(todayDateMoment.format("LLLL"));
    }
  }, [terminalCode]);

  const getHours = () => {
    myDispatch(fetchGateHours({ terminal: terminalNameConstant[terminalCode] }));
  };

  return (
    <div className="page-wrapper">
      <div className="position-relative subheader-wrapper">
        <SubHeaderTab
          val="gateHoursItems"
          buttons={
            <div className="">
              <button
                className="btn btn-outline-light btn--sm mr-2"
                onClick={() => exportToExcel("gatehours")}
              >
                <CustomIconSpreadSheet className="mr-2" />
                Excel
              </button>
              <button
                className="btn btn-outline-light btn--sm mr-2"
                onClick={() => tablePrint("Gate Hours")}
              >
                <IconPrinter className="mr-2" />
                Print
              </button>
            </div>
          }
        />
        <div className="page-fluid">
          <div className="top-page">
            <div className="pb-15 d-flex align-items-center flex-column">
              <h4 className="mb-0 font-weight-700 font-24 text-primary">Gate Hour</h4>
              <div className="font-14  font-weight-500">
                <span>{todayDate}</span>
              </div>
            </div>
            <SearchFilter todayDate={todayDate} setTodayDate={setTodayDate} />
          </div>
          <div>
            <iframe
              title="gate-hours"
              id="ifmcontentstoprint"
              style={{
                height: "0px",
                width: "0px",
                position: "absolute",
              }}
            ></iframe>
            {Number(terminalCode) <= 2 ? (
              <GateTable todayDate={todayDate} />
            ) : (
              <GateTableNewMarket todayDate={todayDate} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GateHour;
