import { useAppContext } from "contexts/AppContext";
import React from "react";
import { Link, NavLink } from "react-router-dom";
import * as tabs from "../../constants/settings";
interface ISubHeaderTabProps {
  val:
    | "emptyReturnItems"
    | "bookings"
    | "importAvailabilityItems"
    | "settingsItems"
    | "problemContainersItems"
    | "dashboard"
    | "notUpdatedContainersItems"
    | "vesselScheduleItems"
    | "VesselReportContainer"
    | "gateHoursItems"
    | "emptyCorrectionItems"
    | "emptyCorrect"
    | "supports"
    | "reports";
  title?: string;
  buttons?: any;
}
const getLastItem = (thePath: string) => thePath.substring(thePath.lastIndexOf("/") + 1);

const SubHeaderTab = (props: ISubHeaderTabProps): React.ReactElement => {
  const { val, title, buttons } = props;
  const { state } = useAppContext();
  const { loggedUser, onboardingTab } = state.appStates;
  const { userSelectedTerminals } = state.appStates;

  const getPaths = (item: any) => {
    let path = item.path;
    if (item.terminal && userSelectedTerminals?.length > 0) {
      //for current-empties route or condition for empties-Slots
      if (item.path === "/current-empties" || item.path === "/current-emptiesSlots") {
        if (Number(userSelectedTerminals[0].code) <= 2) {
          path = `${item.path}/${
            userSelectedTerminals[0]?.name === "New Jersey/NY" ? item.active[1] : item.active[0]
          }`;
        } else {
          path = `${item.path}/${userSelectedTerminals[0]?.code}`;
        }
      } else {
        path = `${item.path}/${
          userSelectedTerminals[0]?.name === "New Jersey/NY" ? item.active[1] : item.active[0]
        }`;
      }
    }
    return path;
  };
  return (
    <div className="subheader">
      <div className="subheader__inner d-flex align-items-center py-0">
        <div className="d-flex flex-row align-items-center justify-content-between w-100">
          <ul
            className="nav nav-tabs nav-tabs-custom flex-grow-1 overflow-x-auto text-nowrap flex-nowrap mr-2"
            role="tablist"
          >
            {title && (
              <li className="nav-item">
                <Link className={`nav-link`} to={""}>
                  <span className="hide-menu">{title}</span>
                </Link>
              </li>
            )}
            {!title &&
              tabs.SettingTabs(loggedUser, val).map((item: any, i: number) => {
                return (
                  <li className="nav-item" key={i}>
                    <NavLink
                      key={i}
                      to={getPaths(item)}
                      // to={`${item.path}/${
                      //   userSelectedTerminals[0]?.name === "New Jersey/NY"
                      //     ? item.active[1]
                      //     : item.active[0]
                      // }`}
                      className={({ isActive }) =>
                        isActive || item.active?.includes(getLastItem(window.location.pathname))
                          ? "nav-link active"
                          : "nav-link"
                      }
                    >
                      <span className="hide-menu">{item.title}</span>
                    </NavLink>
                  </li>
                );
              })}
          </ul>
          <div className="ml-auto">{buttons}</div>
        </div>
      </div>
    </div>
  );
};

export default SubHeaderTab;
