import React, { useState, useEffect } from "react";
import { useAppContext } from "contexts/AppContext";
import { SelectFieldWithoutControl } from "Common/Components/Inputs/CustomSelectField";
import { IconReset } from "Common/Icons";
import { getDuplicateArrayofObjects } from "pages/EmptyReturn/Common/utils";
import { fetchGateHours } from "pages/EmptyReturn/action";
import { portList } from "../Constant";
import { IPort } from "pages/EmptyReturn/location";
import { terminalNameConstant } from "pages/EmptyReturn/constant";

interface IPayload {
  terminal: string;
  port: string;
}

const FilterForm = () => {
  const { state, myDispatch } = useAppContext();
  const { ports } = state.configStates;
  const { userSelectedTerminals } = state.appStates;

  // const PORT_CHOICES = portList.map((item: any) => ({ label: item.value, value: item.label }));
  const [portValue, setPortValue] = useState();
  const [portOptions, setPortOptions] = useState();

  useEffect(() => {
    const terminalCode: number = userSelectedTerminals?.[0]?.code;
    if (terminalCode) {
      const PORT_CHOICES = ports
        .filter((p: IPort) => p.terminalCode === terminalCode)
        .map((o: IPort) => {
          return { value: o.code, label: o.code };
        });
      setPortOptions(PORT_CHOICES);
    }
  }, [userSelectedTerminals, ports]);

  const handlePortChange = (port: any) => {
    setPortValue(port);

    const terminalCode: number = userSelectedTerminals?.[0]?.code;

    if (terminalCode) {
      //for inconsistent underscore key value
      const payloadPort =
        [1, 4].includes(terminalCode) && port.value !== "EVERPORT TERMINAL"
          ? port.value.replaceAll(" ", "_")
          : port.value;

      const payload: IPayload = {
        terminal: terminalNameConstant[terminalCode],
        port: payloadPort,
      };

      myDispatch(fetchGateHours(payload));
    }
  };

  return (
    <React.Fragment>
      {/* Reset FIlter */}
      <div className="d-flex px-30 align-items-center justify-content-between py-20">
        <p className="font-20 mb-0">Filters</p>
        <button type="button" className="btn btn-outline-light btn-sm py-1">
          <IconReset />
        </button>
      </div>
      {/* Filter List */}
      <div className="mt-30 px-30 overflow-y-scroll" style={{ height: "calc(100vh - 200px)" }}>
        <React.Fragment>
          <div className="form-group form-row">
            <label className="col-md-5 col-form-label">Port</label>
            <div className="col-md-7">
              <SelectFieldWithoutControl
                isLoading={false}
                isClearable={true}
                isSearchable={true}
                name="port"
                value={portValue}
                onChange={(value: any) => handlePortChange(value)}
                // onChange={(value) => handleDateChange(value)}
                options={portOptions}
                size="small"
              />
            </div>
          </div>
        </React.Fragment>
        <div className="hr-light my-30"></div>
      </div>
      <div className="bdpopup__footer page-fluid">
        <button type="submit" className="btn btn-primary btn-block">
          Apply
        </button>
      </div>
    </React.Fragment>
  );
};
export default FilterForm;
