import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm, SubmitHandler } from "react-hook-form";
import { IconFilter, IconReset, IconTags, IconTimesThin } from "../../../../Common/Icons";
import { IContainerFilterValues } from "pages/Importavailability/containers";
import CustomInputField from "Common/Components/Inputs/CustomInputField";
import { useAppContext } from "contexts/AppContext";
import {
  setAvailableParams,
  resetAvailableParams,
  getAddedbyTeam,
} from "pages/Importavailability/action";
import CustomSelectField from "Common/Components/Inputs/CustomSelectField";
import CustomDateField from "Common/Components/Inputs/CustomDateField";
import moment, { Moment } from "moment";
import { holds, statusOpts } from "pages/Importavailability/constant";
import Datetime from "react-datetime";
import { getDuplicateArrayofObjects } from "pages/EmptyReturn/Common/utils";

const FilterForm = () => {
  const { state, myDispatch } = useAppContext();

  const { searchPost, searchParams, resetPost, resetParms, addedByTeam } = state.containerStates;
  const { containerOwners, containerSizes, containerTypes, noCredentialPorts } = state.configStates;
  const { userSelectedTerminals } = state.appStates;
  const userSelectedPorts = getDuplicateArrayofObjects(
    noCredentialPorts,
    userSelectedTerminals,
    "terminalCode",
    "code"
  );

  const PORT_CHOICES = userSelectedPorts.map((it: any) => ({
    label: it.name,
    value: it._id,
  }));
  const TEAM_CHOICES = addedByTeam.map((ad: any) => ({ label: ad.email, value: ad._id }));

  useEffect(() => {
    myDispatch(getAddedbyTeam());
  }, []);

  // REACT HOOK FORM
  const {
    reset,
    register,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm<IContainerFilterValues>({
    defaultValues: searchPost,
    shouldUnregister: false,
    mode: "all",
  });

  const onFormSubmit: SubmitHandler<IContainerFilterValues> = async (values) => {
    let data = { ...values };
    data.containerOwner = data.containerOwner?.value
      ? data.containerOwner?.value
      : data.containerOwner;
    data.containerType = data.containerType?.value ? data.containerType?.value : data.containerType;
    data.containerSize = data.containerSize?.value ? data.containerSize?.value : data.containerSize;
    data.status = data.status?.value ? data.status?.value : data.status;
    data.custom = data.custom?.value ? data.custom?.value : data.custom;
    data.freight = data.freight?.value ? data.freight?.value : data.freight;
    data.emptyReturnLocation = data.emptyReturnLocation?.value
      ? data.emptyReturnLocation?.value
      : data.emptyReturnLocation;
    data.portId = data.portId?.value ? data.portId?.value : data.portId;
    data.pickUpFrom = data.pickUpFrom?.value
      ? moment(data.pickUpFrom?.value).utc().format()
      : data.pickUpFrom;
    data.pickUpTo = data.pickUpTo?.value
      ? moment(data.pickUpTo.value).utc().format()
      : data.pickUpTo;
    data.addedBy = data.addedBy?.value ? data.addedBy?.value : data.addedBy;
    let searchPostD = { ...searchPost, ...data };
    searchParams.page = 1;
    myDispatch(setAvailableParams(searchPostD, searchParams));
  };

  const handleReset = () => {
    myDispatch(resetAvailableParams());
  };

  useEffect(() => {
    if (searchPost.reset) {
      reset(searchPost);
    }
  }, [searchPost]);

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onFormSubmit)}>
        {/* Reset FIlter */}
        <div className="d-flex px-30 align-items-center justify-content-between py-20">
          <p className="font-20 mb-0">Filters</p>
          <button
            type="button"
            className="btn btn-outline-light btn-sm py-1"
            onClick={() => handleReset()}
          >
            <IconReset />
          </button>
        </div>
        {/* Filter List */}
        <div className="mt-30 px-30 overflow-y-scroll" style={{ height: "calc(100vh - 200px)" }}>
          {/* <div className="form-group row no-gutters">
            <label className="col-md-5 col-form-label">Pickup APT From</label>
            <div className="col-md-7">
              <div className="input-wrapper">
                <CustomDateField
                  name="pickUpFrom"
                  placeholder="Select Pickup APT From date"
                  control={control}
                />
              </div>
            </div>
          </div> */}
          {/* <div className="form-group row no-gutters">
            <label className="col-md-5 col-form-label">Pickup APT To</label>
            <div className="col-md-7">
              <div className="input-wrapper">
                <CustomDateField
                  name="pickUpTo"
                  placeholder="Select Pickup APT To date"
                  control={control}
                />
              </div>
            </div>
          </div> */}

          {/* <div className="hr-light"></div> */}

          {/* <div className="form-group form-row">
            <label className="col-md-5 col-form-label">Trade Type</label>
            <div className="col-md-7">
              <input type="text" className="form-control" placeholder="Select..."></input>
            </div>
          </div> */}
          {/* <div className="form-group form-row">
            <label className="col-md-5 col-form-label">PickUp Terminal</label>
            <div className="col-md-7">
              <CustomSelectField
                isLoading={false}
                isClearable={true}
                isSearchable={true}
                name="portId"
                options={PORT_CHOICES}
                control={control}
                size="small"
              />
            </div>
          </div> */}
          {/* <div className="form-group form-row">
            <label className="col-md-5 col-form-label">Destination</label>
            <div className="col-md-7">
              <input type="text" className="form-control" placeholder="Select..."></input>
            </div>
          </div> */}
          {/* <div className="form-group form-row">
            <label className="col-md-5 col-form-label">Return Terminal</label>
            <div className="col-md-7">
              <CustomSelectField
                isLoading={false}
                isClearable={true}
                isSearchable={true}
                name="emptyReturnLocation"
                options={PORT_CHOICES}
                control={control}
                size="small"
              />
            </div>
          </div> */}
          {/* <div className="form-group form-row">
            <label className="col-md-5 col-form-label">Current Location</label>
            <div className="col-md-7">
              <CustomInputField
                className="form-control"
                label={"Yard Name"}
                placeholder="Yard Name"
                name="yardLocation"
                type="text"
                // required
                register={register}
                autoComplete="off"
                inputFieldOnly={true}
              />
            </div>
          </div> */}
          {/* <div className="form-group form-row">
            <label className="col-md-5 col-form-label">Added By</label>
            <div className="col-md-7">
              <CustomSelectField
                isLoading={false}
                isClearable={true}
                isSearchable={true}
                name="addedBy"
                options={TEAM_CHOICES}
                control={control}
                size="small"
              />
            </div>
          </div> */}
          {/* <div className="form-group form-row">
            <label className="col-md-5 col-form-label">Status</label>
            <div className="col-md-7">
              <CustomSelectField
                isLoading={false}
                isClearable={true}
                isSearchable={true}
                name="status"
                options={statusOpts.map((mm) => ({ label: mm, value: mm }))}
                control={control}
                size="small"
              />
            </div>
          </div> */}
          {/* <div className="form-group form-row">
            <label className="col-md-5 col-form-label">Custom</label>
            <div className="col-md-7 ">
              <CustomSelectField
                isLoading={false}
                isClearable={true}
                isSearchable={true}
                name="custom"
                options={holds}
                control={control}
                size="small"
              />
            </div>
          </div> */}
          {/* <div className="form-group form-row">
            <label className="col-md-5 col-form-label">Freight</label>
            <div className="col-md-7">
              <CustomSelectField
                isLoading={false}
                isClearable={true}
                isSearchable={true}
                name="freight"
                options={holds}
                control={control}
                size="small"
              />
            </div>
          </div> */}
          <div className="form-group form-row">
            <label className="col-md-5 col-form-label">Pick Up Terminal</label>
            <div className="col-md-7">
              <CustomSelectField
                isLoading={false}
                isClearable={true}
                isSearchable={true}
                name="portId"
                options={PORT_CHOICES}
                control={control}
                size="small"
              />
            </div>
          </div>
          <div className="form-group form-row">
            <label className="col-md-5 col-form-label">Return Terminal</label>
            <div className="col-md-7">
              <CustomSelectField
                isLoading={false}
                isClearable={true}
                isSearchable={true}
                name="emptyReturnLocation"
                options={PORT_CHOICES}
                control={control}
                size="small"
              />
            </div>
          </div>

          <React.Fragment>
            <div className="form-group form-row">
              <label className="col-md-5 col-form-label">Container Type</label>
              <div className="col-md-7">
                <CustomSelectField
                  isLoading={false}
                  isClearable={true}
                  isSearchable={true}
                  name="containerType"
                  options={containerTypes.map((ct: any) => ({ label: ct.name, value: ct._id }))}
                  control={control}
                  size="small"
                />
              </div>
            </div>
            <div className="form-group form-row">
              <label className="col-md-5 col-form-label">Container Size</label>
              <div className="col-md-7">
                <CustomSelectField
                  isLoading={false}
                  isClearable={true}
                  isSearchable={true}
                  name="containerSize"
                  options={containerSizes.map((ct: any) => ({ label: ct.name, value: ct._id }))}
                  control={control}
                  size="small"
                />
              </div>
            </div>
            <div className="form-group form-row">
              <label className="col-md-5 col-form-label">SSL</label>
              <div className="col-md-7">
                <CustomSelectField
                  isLoading={false}
                  isClearable={true}
                  isSearchable={true}
                  name="containerOwner"
                  options={containerOwners.map((ct: any) => ({ label: ct.name, value: ct._id }))}
                  control={control}
                  size="small"
                />
              </div>
            </div>
            {/* <div className="form-group form-row">
              <label className="col-md-5 col-form-label">Voyage</label>
              <div className="col-md-7">
                <CustomInputField
                  className="form-control"
                  label={"Voyage Name"}
                  placeholder="Voyage Name"
                  name="voyageName"
                  type="text"
                  // required
                  register={register}
                  autoComplete="off"
                  inputFieldOnly={true}
                />
              </div>
            </div> */}

            {/* <div className="form-group form-row">
              <label className="col-md-5 col-form-label">Vessel</label>
              <div className="col-md-7">
                <CustomInputField
                  className="form-control"
                  label={"Vessel Name"}
                  placeholder="Vessel Name"
                  name="vesselName"
                  type="text"
                  // required
                  register={register}
                  autoComplete="off"
                  inputFieldOnly={true}
                />
              </div>
            </div> */}

            {/* <div className="form-group form-row">
              <label className="col-md-5 col-form-label">Fees</label>
              <div className="col-md-7 d-flex">
                <div className="form-check pr-30">
                  <input className="form-check-input" type="radio" id="yes" />
                  <label className="form-check-label" htmlFor="yes">
                    Yes
                  </label>
                </div>
                <div className="form-check pr-30">
                  <input className="form-check-input" type="radio" id="no" />
                  <label className="form-check-label" htmlFor="no">
                    No
                  </label>
                </div>
              </div>
            </div> */}
          </React.Fragment>
          <div className="hr-light my-30"></div>
        </div>
        <div className="bdpopup__footer page-fluid">
          <button type="submit" className="btn btn-primary btn-block">
            Apply
          </button>
        </div>
      </form>
    </React.Fragment>
  );
};

export default FilterForm;
