export const initialreportContainerValues = {
  reportContainers: [],
  rLoading: false,
  errorMessage: "",
  reportContainerCount: 0,
  csvReportContainers: [],
  csvLoading: false,

  searchParams: {
    limit: 50,
    page: 1,
  },
  searchPost: {
    portFilter: "",
  },
};

export const reportContainerReducer = (
  state: any,
  action: {
    type: string;
    payload: any;
  }
) => {
  switch (action.type) {
    case "REPORTCONTAINERS_INPROGRESS": {
      return {
        ...state,
        rLoading: true,
      };
    }
    case "REPORTCONTAINERS_SUCCESS": {
      let reportContainers = action.payload.data.data;
      return {
        ...state,
        rLoading: false,
        reportContainers: reportContainers,
        reportContainerCount: action.payload.data.count,
      };
    }
    case "REPORTCONTAINERS_REJECTED": {
      return {
        ...state,
        rLoading: false,
        reportContainers: [],
        errorMessage: action.payload.errorMessage,
        reportContainerCount: 0,
      };
    }
    case "SET_REPORTCONTAINERS_PARAMS": {
      let param = action.payload.searchParams;
      let post = action.payload.searchPost;
      return {
        ...state,
        searchParams: param,
        searchPost: { ...post, reset: false },
      };
    }
    case "RESET_REPORTCONTAINERS_PARAMS": {
      return {
        ...state,
        searchPost: { reset: true },
        searchParams: {
          limit: 50,
          page: 1,
        },
      };
    }

    case "REPORTCONTAINERSCSV_INPROGRESS": {
      return {
        ...state,
        csvLoading: true,
        csvReportContainers: [],
      };
    }
    case "REPORTCONTAINERSCSV_SUCCESS": {
      let reportContainers = action.payload.data.data;
      return {
        ...state,
        csvLoading: false,
        csvReportContainers: reportContainers,
      };
    }
    case "REPORTCONTAINERSCSV_REJECTED": {
      return {
        ...state,
        csvLoading: false,
        csvReportContainers: [],
        errorMessage: action.payload.errorMessage,
      };
    }
    default:
      return state;
  }
};
