import Loader from "Common/Components/Loader";
import SubHeaderTab from "Common/Components/SubHeaderTab";
import { useAppContext } from "contexts/AppContext";
import { useEffect, useState } from "react";
import { getGetOverAllReport, getStandardRawPorts } from "./action";
import SearchFilter from "./Components/SearchFilter";
import { ProgressBar } from "react-bootstrap";
import StandAloneReportTable from "./Components/StandAloneReportTable";
import "./Components/CustomStyle.css";
import { ITrackingReport } from "./types";

const DrayosStandAloneReport = () => {
  const { state, myDispatch } = useAppContext();
  const { overAllReports, loading, standardPorts } = state.drayosReportStates;

  const [report, setReport] = useState<any>(null);
  const [successPercentage, setSuccesspercentage] = useState(0);
  const [standAloneReport, setStandAloneReport] = useState<any>([]);
  const [standAloneReportType, setStandAloneReportType] = useState<String>("");

  const initialParams = {
    hour: null,
    portCode: null,
  };

  const [params, setParams] = useState<any>(initialParams);

  useEffect(() => {
    if (overAllReports.length > 0) {
      const { isContainerReport, trackingReportType } = isTrackingReport(overAllReports[0]);
      if (!isContainerReport) {
        setStandAloneReport([]);
        setStandAloneReportType("");
        setReport(overAllReports[0]);
        setSuccesspercentage(Math.round(overAllReports?.[0]?.successRatio * 100));
      } else {
        const containerReport = overAllReports[0];
        setStandAloneReportType(trackingReportType);
        if (Array.isArray(containerReport[trackingReportType])) {
          setStandAloneReport(containerReport[trackingReportType]);
        }
      }
    }
  }, [overAllReports]);

  useEffect(() => {
    if (params.portCode && params.hour) {
      myDispatch(getGetOverAllReport(params));
    }
  }, [standardPorts]);

  type ReportObj = {
    [key: string]: number | Object[];
  };

  const isTrackingReport = (reportObj: ReportObj): ITrackingReport => {
    const standAloneObj = { isContainerReport: false, trackingReportType: "" };

    Object.keys(reportObj).some((key): ITrackingReport | undefined => {
      if (reportObj[key] && typeof reportObj[key] === "number") {
        standAloneObj.trackingReportType = key;
        return standAloneObj;
      }

      if (reportObj[key] && typeof Array.isArray(reportObj[key])) {
        standAloneObj.trackingReportType = key;
        standAloneObj.isContainerReport = true;
        return standAloneObj;
      }
    });

    return standAloneObj;
  };

  useEffect(() => {
    myDispatch(getStandardRawPorts());
  }, [params]);

  const handleHeaderClick = (headerType: string) => {
    if (headerType) {
      const payload = {
        ...params,
        trackingReportType: headerType,
      };
      myDispatch(getGetOverAllReport(payload));
    }
  };

  //   failedScrapes
  // :
  // 199
  // successFullyTracked
  // :
  // 1097
  // successRatio
  // :
  // 0.8464506172839507
  // totalTracked
  // :
  // 1296
  // total_to_track
  // :
  // 1780
  return (
    <div className="page-wrapper">
      <div className="position-relative subheader-wrapper">
        {loading && <Loader />}
        <SubHeaderTab val="reports" />
        <div className="page-fluid">
          <div className="top-page">
            <div className="pb-15 d-flex align-items-center">
              <h4 className="mb-0">Drayos StandAlone Report</h4>
              <div className="ml-auto">
                {/* <Pagination */}
                {/*   className={"ml-auto"} */}
                {/*   pageRangeDisplayed={5} */}
                {/*   totalItemsCount={apptReportCount} */}
                {/*   itemsCountPerPage={apptReportSearchParams.size} */}
                {/*   onChange={onPaginate} */}
                {/*   activePage={apptReportSearchParams.page} */}
                {/* /> */}
              </div>
            </div>
            <SearchFilter setParams={setParams} />
          </div>
          {/* <AppointmentSlotReportTable /> */}
          {params.portCode && params.hour && report?.total_to_track && (
            <>
              <div className="card" style={{ width: "100%" }}>
                <div className="card-body">
                  <h5 className="card-title">
                    <ProgressBar
                      variant="success"
                      now={successPercentage}
                      label={`${successPercentage}%`}
                    />
                  </h5>
                  <div className="card-text">
                    <h2>Success Ratio: {successPercentage}%</h2>
                    <h2>Total to Track: {report.total_to_track}</h2>
                    <h2
                      className={
                        standAloneReportType === "totalTrackedContainers"
                          ? "clickable-header underline"
                          : "clickable-header"
                      }
                      onClick={() => handleHeaderClick("totalTrackedContainers")}
                    >
                      Total Tracked: {report.totalTracked}
                    </h2>
                    <h2
                      className={
                        standAloneReportType === "successFullyTrackedContainers"
                          ? "clickable-header underline"
                          : "clickable-header"
                      }
                      onClick={() => handleHeaderClick("successFullyTrackedContainers")}
                    >
                      Successful Track: {report.successFullyTracked}
                    </h2>
                    <h2
                      className={
                        standAloneReportType === "failedScrapesContainers"
                          ? "clickable-header underline"
                          : "clickable-header"
                      }
                      onClick={() => handleHeaderClick("failedScrapesContainers")}
                    >
                      Unsuccessful Track: {report.failedScrapes}
                    </h2>
                  </div>
                </div>
              </div>
            </>
          )}
          {!params.portCode || (!params.hour && <p>Please Select the port and hour.</p>)}
          {params.portCode && standAloneReportType && (
            <h4 className="mt-2 pb-15 d-flex align-items-center justify-content-center">
              {standAloneReportType?.toUpperCase()} List For {params.portCode}
            </h4>
          )}
          {Array.isArray(standAloneReport) && standAloneReport.length ? (
            <StandAloneReportTable standAloneReport={standAloneReport} />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default DrayosStandAloneReport;
