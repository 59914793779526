import React, { SetStateAction, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import {
  IconAngleArrowRight,
  IconCheck,
  IconEye,
  IconEyeClosed,
  IconAngleArrowDown,
  IconInfo,
} from "Common/Icons/index";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppContext } from "contexts/AppContext";
import * as yup from "yup";
import { assignPortCred } from "reducers/action/appAction";
import CustomInputField from "Common/Components/Inputs/CustomInputField";

export const formSchema = yup
  .object({
    portUsername: yup.string().required("User Name is required"),
    portPassword: yup.string().required("Password is required"),
  })
  .required();

const PortLoginForm = (props: any) => {
  const {
    activeStep,
    handleActive,
    handlePrevious,
    setShowModal,
    showModal,
    handleSelectedPorts,
    closeModal,
    formValues,
  } = props;
  const { state, myDispatch } = useAppContext();

  const {
    getValues,
    register,
    // formState: { errors },
    handleSubmit,
    setValue,
  } = useForm<any>({
    mode: "onBlur",
    // resolver: yupResolver(formSchema),
  });

  // FORM SUBMIT
  const onFormSubmit = async (data: any) => {
    let postdata = {
      _id: null,
      portId: formValues.portId ? formValues.portId : formValues._id,
      portUsername: data.portUsername,
      portPassword: data.portPassword,
    };
    let response;
    if (formValues.portId) {
      postdata._id = formValues._id;
    }
    response = assignPortCred(postdata);

    myDispatch(response);
    response.payload.then((res: any) => {
      handleSelectedPorts(null, true);
    });
  };

  useEffect(() => {
    if (formValues) {
      setValue("portPassword", formValues.portPassword);
      setValue("portUsername", formValues.portUsername);
    }
  }, [formValues]);

  return (
    <Modal show={showModal && activeStep === 4} centered animation={false} size="xl">
      <form className="mb-2" onSubmit={handleSubmit(onFormSubmit)}>
        <Modal.Header className="justify-content-center">
          <Modal.Title>Update Credential</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Connected */}
          <div>
            <div className="row bg-brand-50 py-2 rounded-3 ">
              <div className="col-4 d-flex align-items-center">
                <div className="">
                  <label className="font-14 font-weight-500">{formValues.code}</label>
                  <p className="text-brand-500 mb-0">Connected!</p>
                </div>
              </div>
              {formValues.isLoginRequired ? (
                <React.Fragment>
                  <div className="col-4">
                    <div className="form-group mr-10">
                      <CustomInputField
                        // defaultValue={username}
                        label={formValues.name + `'s UserName`}
                        placeholder={`Enter Username for ` + formValues.name}
                        name="portUsername"
                        type="text"
                        // required
                        register={register}
                        // error={Boolean(errors.portUsername)}
                        // helper={errors.portUsername ? errors.portUsername.message : ""}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-4 ">
                    <CustomInputField
                      // defaultValue={password}
                      label={formValues.name + `'s Password`}
                      placeholder={`Enter Password for ` + formValues.name}
                      name="portPassword"
                      type="password"
                      // required
                      register={register}
                      // error={Boolean(errors.portPassword)}
                      // helper={errors.portPassword ? errors.portPassword.message : ""}
                      className="form-control"
                    />
                  </div>
                </React.Fragment>
              ) : (
                <div className=" col-8 d-flex justify-content-center align-items-center">
                  <span className="badge-gray-200 mr-10 rounded-circle mr-15 w-20px h-20px d-inline-flex align-items-center justify-content-center">
                    <IconInfo />
                  </span>
                  <h5 className="font-14 d-inline-block mb-0">There is no login required</h5>
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-outline-light py-2 mr-10"
            onClick={() => setShowModal(false)}
            type="button"
          >
            Close
          </button>
          <button
            className="btn btn-outline-light py-2 mr-10"
            onClick={() => handlePrevious(activeStep)}
            type="button"
          >
            Back
          </button>
          <button type="submit" className="btn btn-primary py-2">
            {state.appStates.assignLoading && (
              <span
                className="spinner-border spinner-border-sm mr-2"
                role="status"
                aria-hidden="true"
              ></span>
            )}
            Save
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default PortLoginForm;

/* No login required */

// <div>
//   <form>
//     <div className="row align-items-center badge-brand-50 py-2 rounded-3 no-gutters">
//       <div className="col-4">
//         <div className="px-10">
//           <label className="font-14 font-weight-500 text-gray-900">Miami Port</label>
//           <p className="text-brand-500 mb-0">Connected!</p>
//         </div>
//       </div>
//       <div className="col-4">
//         <>
//           <div className="d-flex justify-content-center align-items-center">
//             <span className="badge-gray-200 mr-10 rounded-circle mr-15 w-20px h-20px d-inline-flex align-items-center justify-content-center">
//               <IconInfo />
//             </span>
//             <h5 className="font-14 d-inline-block mb-0">There is no login required</h5>
//           </div>
//         </>
//       </div>
//     </div>
//   </form>
// </div>;

//  <div>
//    <form className="mb-2">
//      <div className="row align-items-center bg-danger-light py-2 rounded-3 no-gutters">
//        <div className="col-4">
//          <div className="px-10">
//            <label className="font-14 font-weight-500">VIG</label>
//            <p className="text-error-500 mb-0">You’ve missed the data</p>
//          </div>
//        </div>
//        <div className="col-4">
//          <div className="form-group mb-0 mr-10">
//            <input className="form-control border-orange" placeholder="Port Username" />
//          </div>
//        </div>
//        <div className="col-4">
//          <div className="input-wrapper mr-2">
//            <input
//              type={showPassword ? "text" : "password"}
//              className="form-control border-orange"
//              placeholder="Enter Password"
//            />
//            <div className="input-icon" onClick={togglePasswordVisibility}>
//              {showPassword ? <IconEye /> : <IconEyeClosed />}
//            </div>
//          </div>
//        </div>
//      </div>
//    </form>
//  </div>;
//  <div>
//    <form className="mb-2">
//      <div className="row align-items-center bg-warning-50 py-2 rounded-3 no-gutters">
//        <div className="col-4">
//          <div className="px-10">
//            <label className="font-14 font-weight-500">NIT</label>
//            <p className="text-warning-500 mb-0">You’ve missed the data</p>
//          </div>
//        </div>
//        <div className="col-4">
//          <div className="form-group mb-0 mr-10">
//            <input className="form-control border-error-500" placeholder="Port Username" />
//          </div>
//        </div>
//        <div className="col-4">
//          <div className="input-wrapper mr-2">
//            <input
//              type={showPassword ? "text" : "password"}
//              className="form-control border-error-500"
//              placeholder="Enter Password"
//            />
//            <div className="input-icon" onClick={togglePasswordVisibility}>
//              {showPassword ? <IconEye /> : <IconEyeClosed />}
//            </div>
//          </div>
//        </div>
//      </div>
//    </form>
//  </div>;
