import { IconAngleArrowDown, IconAngleArrowRight } from "Common/Components/Icons/Icons";
import React, { useEffect, useState } from "react";
import { cardItems, newMarketWithTwoShift, terminalNameConstant } from "../constant";
import { compareTimeRange, getRealTime, getTimeZone } from "./utils";
import shiftPort from "./shift.json";
import { useAppContext } from "contexts/AppContext";
import { getEmptyData } from "../action";

const TopEmptyCard = (props: any) => {
  const { handleActive, date, terminal, terminalCode, value, shift, handleLACardActive } = props;
  const { state, myDispatch } = useAppContext();
  const { userSelectedTerminals } = state.appStates;
  const { isTomorrow, shiftCount } = state.emptyStates;

  // ---------------------------------------------------- STATE MANAGEMENT---------------------------------------------------//
  const [isCardActive, setCardActive] = useState(1);
  // ---------------------------------------------------- HANDLE FUNCTIONS ---------------------------------------------------//
  const handleCardActive = (card: any) => {
    setCardActive(card.id);
    if (terminal === "NY" || !newMarketWithTwoShift.includes(terminalCode)) {
      if (card.isTomorrow) {
        myDispatch(getEmptyData(terminalNameConstant[terminalCode || terminal], card.isTomorrow));
      } else {
        myDispatch(getEmptyData(terminalNameConstant[terminalCode || terminal], false));
      }
    }
    handleActive(card);
  };

  // ---------------------------------------------------- HOOKS MANAGEMENT ---------------------------------------------------//

  //this use effect is for NY and New martket empties
  useEffect(() => {
    if (terminal === "NY" && !terminalCode) {
      getEmptiesData();
    } else if (terminal !== "NY" && terminalCode) {
      getEmptiesData();
    }
  }, [terminal, terminalCode]);

  const getEmptiesData = () => {
    myDispatch(getEmptyData(terminalNameConstant[terminalCode || terminal], false));
  };

  useEffect(() => {
    if (shift === "shift1") {
      setCardActive(1);
    } else if (shift === "shift2") {
      setCardActive(2);
    } else {
      setCardActive(3);
    }
  }, [shift]);

  // useEffect(() => {
  //   let time;
  //   const timeZone = getTimeZone(terminal);
  //   const timeNow = getRealTime(timeZone);
  //   if (terminal === "LA") {
  //     time = shiftPort.APM_TERMINAL[1].SHIFT1.split("-");
  //   } else {
  //     time = shiftPort.GCT_BAYONNE[1].SHIFT1.split("-");
  //   }
  //   const compareTime = compareTimeRange(time, timeNow, 1);
  //   if (!compareTime && terminal === "LA") {
  //     setCardActive(1);
  //     handleActive("shift1");
  //   } else if (!compareTime && terminal === "NY")  {
  //     setCardActive(1);
  //     handleActive("shift1");
  //   } else {
  //     setCardActive(2);
  //     handleActive("shift2");
  //   }
  // }, [terminal]);
  return (
    <div className="top-page mb-10">
      {/* Cards Row */}
      <div className={`form-row ${isCardActive ? "hasactive" : ""}`}>
        {/* Card items for NY and HOUSTON */}
        {terminal === "NY" || (terminalCode && !newMarketWithTwoShift.includes(terminalCode)) ? (
          <>
            {cardItems(date, (terminal !== "" && terminal) || terminalCode, value).map(
              (cardItem: any, index: number) => (
                <div key={index} className="col-md">
                  <div
                    className={`ncard d-flex justify-content-between p-10 ${
                      isCardActive === cardItem.id ? "active" : ""
                    }`}
                    onClick={() => handleCardActive(cardItem)}
                  >
                    <div className="d-flex flex-column">
                      <div className="font-12 font-weight-500 text-gray-900">{cardItem.title}</div>
                      <div className="font-12 font-weight-normal text-gray-500">
                        {cardItem.Date}
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="ml-auto">
                        {isCardActive === cardItem.id ? (
                          <IconAngleArrowDown />
                        ) : (
                          <IconAngleArrowRight />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )
            )}
          </>
        ) : (
          <>
            {cardItems(
              date,
              (terminal !== "" && terminal) || terminalCode,
              value,
              shift,
              shiftCount
            ).map((cardItem: any, index: number) => {
              return (
                <div key={index} className="col-md">
                  <div
                    className={`ncard d-flex justify-content-between p-10 ${
                      isCardActive === cardItem.id ? "active" : ""
                    }`}
                    onClick={() => handleLACardActive(cardItem)}
                  >
                    <div className="d-flex flex-column">
                      <div className="font-12 font-weight-500 text-gray-900">{cardItem.title}</div>
                      <div className="font-12 font-weight-normal text-gray-500">
                        {cardItem.Date}
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="ml-auto">
                        {isCardActive === cardItem.id ? (
                          <IconAngleArrowDown />
                        ) : (
                          <IconAngleArrowRight />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        )}
      </div>
    </div>
  );
};

export default TopEmptyCard;
