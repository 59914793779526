import { api } from "config";
import { objToQueryParams, removeNullEmpty } from "utils/commonFunctions";

export const getProblemContainers = (data: any, params?: any) => {
  let postData = removeNullEmpty(data);
  let url = "api/dashboard/filterContainer";
  url = params ? url + "?" + objToQueryParams(params) : url;

  return {
    type: "PROBLEMCONTAINERS",
    payload: api.post(url, postData),
  };
};

export const setProblemContainersParams = (searchPost?: any, searchParams?: any) => {
  return {
    type: "SET_PROBLEMCONTAINERS_PARAMS",
    payload: { searchPost, searchParams },
  };
};

export const resetProblemContainersParams = () => {
  return {
    type: "RESET_PROBLEMCONTAINERS_PARAMS",
  };
};
