import React, { Fragment, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm, SubmitHandler } from "react-hook-form";

import CustomInputField from "Common/Components/Inputs/CustomInputField";
import CustomTextAreaField from "Common/Components/Inputs/CustomTextAreaField";
import CustomSelectField from "Common/Components/Inputs/CustomSelectField";
import Loader from "Common/Components/Loader";
import { useAppContext } from "contexts/AppContext";
import { IContainerFormValues, IContainerPostValues } from "../containers";

import { containerSizes, containerTypes } from "constants/containers";
import {
  addContainer,
  fetchGateInfo,
  findDuplicateContainer,
  getDateInfo,
  getYmluEta,
  getYTISizeType,
  setContainerLists,
} from "../action";
import _ from "lodash";
import { getDetailedValue } from "utils/commonFunctions";
import { findDuplicates } from "pages/EmptyReturn/Common/utils";
interface ITerminalOpts {
  label: string;
  options: { label: string; value: string }[];
}

export const formSchema = yup
  .object({
    containerNo: yup.string().required("Container No  is required"),
    // .matches(/^[a-zA-Z]{4}[0-9]{7}$/, "Container numbers have the format XXXU1234567"),
    // containerOwner: yup.string().required("Container Owner  is required"),
  })
  .required();

const ContainerAddForm = (): React.ReactElement => {
  const { state, myDispatch } = useAppContext();
  const {
    cAddLoading,
    showAddContainerModal,
    cAddErrorMessage,
    lAddErrorMessage,
    searchParams,
    searchPost,
  } = state.containerStates;
  const { noCredentialPorts, terminals } = state.configStates;
  const { loggedUser, userSelectedTerminals } = state.appStates;
  const [cntrErr, setCntrErr] = useState<string | null>(null);

  // console.log("error message check", cAddErrorMessage);
  //Initilized
  // useEffect(() => {
  //   getContainerList();
  // }, []);
  // //CALL
  // const getContainerList = async () => {
  //   const response = await fetchDischargeDate({ containerNo: "BMOU4849554" });
  //   myDispatch(response);
  // };

  // console.log(ports, "ports");
  // REACT HOOK FORM
  const {
    reset,
    register,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm<IContainerFormValues>({
    mode: "onBlur",
    resolver: yupResolver(formSchema),
  });

  // FORM SUBMIT
  const onFormSubmit: SubmitHandler<IContainerFormValues> = async (data) => {
    let containerNo: string[] = data.containerNo.replace(/,\s+/g, ",").split(/[\n,\s+]/);
    containerNo = _.compact(containerNo);
    let containers: IContainerPostValues[] = [];
    let allContainers: string[] = [];
    containerNo.forEach((cn: string) => {
      if (cn.match(/^[a-zA-Z]{4}[0-9]{7}$/)) {
        containers.push({
          containerNo: cn?.toUpperCase(),
        });
        allContainers.push(cn);
        setCntrErr(null);
      } else {
        setCntrErr("Some Container are invalid.Please check again");
      }
    });

    const filteredDuplicateContainers = findDuplicates(containers);
    let postdata = {
      containers: filteredDuplicateContainers,
    };
    cntrErr === null && containers.length > 50 && setCntrErr("You can add maximum 50 containers");

    if (cntrErr === null && containers.length > 0 && containers.length <= 50) {
      let resp = addContainer(postdata);
      myDispatch(resp);

      let postpayload = {
        containers,
      };
      let duplicatecontainers = await findDuplicateContainer(postpayload);
      myDispatch(duplicatecontainers);

      resp.payload
        .then(async (res) => {
          //for ymlu containers
          const responseData = res.data.data;
          let payload = {
            containers: responseData.filter((rd: any) => rd.status !== "Not Found"),
          };
          if (payload?.containers?.length > 0) {
            let ymluresponse = await getYmluEta(payload);
            myDispatch(ymluresponse);
          }

          // For YTI Size and Type
          const requiredPortID = noCredentialPorts.find((port: any) => port.name === "YTI");
          const filteredDataArray = res.data.data.filter(
            (filtered: any) =>
              filtered.portId === requiredPortID._id &&
              (!filtered.containerSize || !filtered.containerType)
          );
          if (
            filteredDataArray.length > 0 &&
            userSelectedTerminals.length &&
            userSelectedTerminals[0]?.code === 1
          ) {
            // required containerID to post
            const containerID = filteredDataArray.map((filteredData: any) => filteredData._id);
            let postContainerID = {
              containers: filteredDataArray,
            };

            let YTISizeResponse = await getYTISizeType(postContainerID);
            myDispatch(YTISizeResponse);
          }

          // For Fetching Gate Information
          const outGatedContainers = res.data.data.filter(
            (filteredRes: any) =>
              (getDetailedValue(filteredRes?.terminalCode, terminals) === "LOS ANGELES" &&
                filteredRes?.status === "Outgated") ||
              filteredRes?.status === "Terminated"
          );
          if (
            outGatedContainers.length > 0 &&
            userSelectedTerminals.length &&
            userSelectedTerminals[0]?.code === 1
          ) {
            const containerIDs = outGatedContainers.map((outGated: any) => outGated._id);
            let postContainers = {
              containerIds: containerIDs,
            };

            let GateResponse = fetchGateInfo(postContainers);
            myDispatch(GateResponse);

            GateResponse.payload.then(async (response) => {
              myDispatch(setContainerLists(searchPost, searchParams));
            });
          }

          // For get data Information
          if (userSelectedTerminals.length) {
            const getDataContainers = res.data.data.filter((filtered: any) => filtered._id);
            if (getDataContainers.length) {
              const containerIds = getDataContainers.map((filteredData: any) => filteredData._id);
              const containersData = res.data.data;
              const timeZone = localStorage.getItem("timeZone");
              let postContainerID = {
                timeZone,
                containers: containersData,
              };

              let getDataResponse = await getDateInfo(postContainerID);

              myDispatch(getDataResponse);
            }
          }
          reset({
            containerNo: "",
          });
        })
        .finally(() => {
          reset();
        });
    }
  };

  return (
    <Fragment>
      <form onSubmit={handleSubmit(onFormSubmit)}>
        {/* <Modal.Body> */}
        {cAddErrorMessage && (
          <div className="alert alert-warning" role="alert">
            {cAddErrorMessage}
          </div>
        )}
        <CustomTextAreaField
          className="form-control"
          // label={"Container Nos*"}
          placeholder="Container Nos"
          name="containerNo"
          required
          register={register}
          autoComplete="off"
          error={Boolean(errors?.containerNo)}
          helper={errors?.containerNo ? errors?.containerNo?.message : ""}
          rows={5}
          dense={false}
          // onBlur={onBlur}s
        />
        {cntrErr && (
          <span className="error" style={{ color: "red" }}>
            {cntrErr}
          </span>
        )}
        {/* <div className="row">
            <div className="col">
              <CustomSelectField
                label={"Container Size"}
                className="basic-single"
                classNamePrefix="select"
                // defaultValue={colourOptions[0]}
                isLoading={false}
                isClearable={true}
                isSearchable={true}
                name="containerSize"
                options={containerSizes}
                control={control}
              />
            </div>
            <div className="col">
              <CustomSelectField
                label={"Container Type *"}
                className="basic-single"
                classNamePrefix="select"
                // defaultValue={colourOptions[0]}
                isLoading={false}
                isClearable={true}
                isSearchable={true}
                name="containerType"
                options={containerTypes}
                control={control}
              />
            </div>
          </div>
          <CustomInputField
            className="form-control"
            label={"Container Owner"}
            placeholder="Container Owner"
            name="containerOwner"
            type="text"
            required
            register={register}
            autoComplete="off"
            error={Boolean(errors?.containerOwner)}
            helper={errors?.containerOwner ? errors?.containerOwner?.message : ""}
          />
          <CustomSelectField
            label={"Port *"}
            className="basic-single"
            classNamePrefix="select"
            // defaultValue={colourOptions[0]}
            isLoading={false}
            isClearable={true}
            isSearchable={true}
            name="portId"
            options={getTerminalOptions()}
            // options={ports.map((port: any) => ({ value: port._id, label: port.name }))}
            control={control}
          /> */}
        {/* </Modal.Body> */}
        {/* <Modal.Footer> */}
        {/* <button className="btn btn-close" onClick={() => closeModal()}>
            Cancel
          </button> */}
        <button
          type="submit"
          className={`btn float-right mb-15 ${cAddLoading ? "btn-disabled" : "btn-success "}`}
        >
          {cAddLoading && (
            <span
              className="spinner-border spinner-border-sm mr-2"
              role="status"
              aria-hidden="true"
            ></span>
          )}
          Add Container
        </button>
        {/* </Modal.Footer> */}
      </form>
    </Fragment>
  );
};
export default ContainerAddForm;
