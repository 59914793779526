import React, { useEffect } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import Header from "Common/Components/Header";
import Sidebar from "Common/Components/Sidebar";
import { useAppContext } from "contexts/AppContext";
import {
  getContainerSizeList,
  getContainerOwnerList,
  getContainerTypeList,
  getPortList,
  getCredPortList,
  getUserTerminalList,
  getTerminalList,
} from "reducers/action/appAction";
import Homepage from "pages/Homepage";
import moment from "moment";

const Layout = () => {
  let navigate = useNavigate();
  const { state, myDispatch } = useAppContext();
  const { loggedUser, onboardingTab, isLoggedIn } = state.appStates;
  const { containerSizes, containerOwners, containerTypes, ports } = state.configStates;

  const timeZone = localStorage.getItem("timeZone");
  timeZone && moment.tz.setDefault(timeZone);

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login");
    } else {
      containerSizes.length === 0 && myDispatch(getContainerSizeList());
      containerOwners.length === 0 && myDispatch(getContainerOwnerList());
      containerTypes.length === 0 && myDispatch(getContainerTypeList());
      ports.length === 0 && getAddedPorts();
    }
  }, [isLoggedIn]);

  const getUserTerminal = async () => {
    const response = await getUserTerminalList();
    myDispatch(response);
  };

  const getTerminal = async () => {
    const response = await getTerminalList();
    myDispatch(response);
  };
  useEffect(() => {
    getUserTerminal();
    getTerminal();
  }, []);

  const getAddedPorts = () => {
    let resPort = getPortList();
    myDispatch(resPort);
    resPort.payload.then((res) => {
      myDispatch(getCredPortList(loggedUser._id));
    });
  };

  return (
    <React.Fragment>
      <div id="main-wrapper">
        <Header />
        <Sidebar />
        <Homepage />
        <Outlet />
      </div>
    </React.Fragment>
  );
};

export default Layout;
