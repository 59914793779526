import _ from "lodash";
export const initialreportDRRValues = {
  drLoading: false,
  drayosSummary: [],
  missingDatas: [],
  mloading: false,
  carriers: [],
  syncContainers: [],
  // EmptiesSlot Reports
  emptiesSlotSummary: [],
  emptiesSlotLoading: false,
  emptiesSlotSearchParams: {
    limit: 50,
    page: 1,
  },
  emptiesSlotSearchPost: {
    cautionCount: true,
  },
  filteredEmptiesSlot: [],
  emptiesSlotDownloading: false,
  apptEnabledCarrier: [],
  apptLoading: false,
  apptReport: [],
  apptReportLoading: false,
  apptReportSearchParams: {
    size: 50,
    page: 1,
  },
  apptReportCount: 0,
  apptSlotReport: [],
  apptSlotReportLoading: false,
};

export const reportDRReducer = (
  state: any,
  action: {
    type: string;
    payload: any;
  }
) => {
  switch (action.type) {
    case "DRAYOS_SUMMARYREPORT_INPROGRESS": {
      return {
        ...state,
        drLoading: true,
        drayosSummary: [],
      };
    }
    case "DRAYOS_SUMMARYREPORT_SUCCESS": {
      let summary = action.payload.data;
      // let summary = action.payload;
      summary = summary?.length > 0 ? _.orderBy(summary, ["missingCount"], ["desc"]) : [];
      return {
        ...state,
        drLoading: false,
        drayosSummary: summary,
      };
    }
    case "DRAYOS_SUMMARYREPORT_REJECTED": {
      return {
        ...state,
        drLoading: false,
        drayosSummary: [],
      };
    }

    case "MISSINGREPORTCONTAINERS_INPROGRESS": {
      return {
        ...state,
        mloading: true,
        missingDatas: [],
      };
    }
    case "MISSINGREPORTCONTAINERS_SUCCESS": {
      let summary = action.payload.data.data;
      // let summary = action.payload;
      return {
        ...state,
        mloading: false,
        missingDatas: summary,
      };
    }
    case "MISSINGREPORTCONTAINERS_REJECTED": {
      return {
        ...state,
        mloading: false,
        missingDatas: [],
      };
    }

    //

    case "CONTAINER_DETAILED_ERROR_INPROGRESS": {
      return {
        ...state,
        mErrloading: true,
        // missingDatas: [],
      };
    }
    case "CONTAINER_DETAILED_ERROR_SUCCESS": {
      let summary = action.payload.data.data;
      let detail = state.missingDatas;
      if (summary?.length > 0 && detail?.length > 0) {
        detail = detail.map((md: any) => {
          let error = summary.find((summar: any) => summar.containerNo === md.containerNo);
          if (error) {
            md.error = error;
          }
          return md;
        });
      }
      return {
        ...state,
        mErrloading: false,
        missingDatas: detail,
      };
    }
    case "CONTAINER_DETAILED_ERROR_REJECTED": {
      return {
        ...state,
        mErrloading: false,
        // missingDatas: [],
      };
    }

    case "CARRIER_DETAIL_INPROGRESS": {
      return {
        ...state,
        crLoading: true,
        carriers: [],
      };
    }
    case "CARRIER_DETAIL_SUCCESS": {
      let carriers = action?.payload?.data?.companies ? action.payload.data.companies : [];
      return {
        ...state,
        crLoading: false,
        carriers: carriers ? carriers : [],
      };
    }
    case "CARRIER_DETAIL_REJECTED": {
      return {
        ...state,
        crLoading: false,
        carriers: [],
      };
    }

    case "SYNC_LOAD_INPROGRESS": {
      return {
        ...state,
        syncLoading: true,
        syncContainers: [],
      };
    }
    case "SYNC_LOAD_SUCCESS": {
      let containers = action.payload?.data?.mapped ? action.payload?.data?.mapped : [];
      return {
        ...state,
        syncLoading: false,
        syncContainers: containers,
      };
    }
    case "SYNC_LOAD_REJECTED": {
      return {
        ...state,
        syncLoading: false,
        syncContainers: [],
      };
    }

    case "RESET_MISSINGREPORTCONTAINERS_PARAMS": {
      return {
        ...initialreportDRRValues,
      };
    }
    case "CONTAINERSTATUS_REPORT_INPROGRESS": {
      return {
        ...state,
        statusLoading: true,
        ReportContainerStatus: [],
      };
    }
    case "CONTAINERSTATUS_REPORT_SUCCESS": {
      let ContainerStatus = action.payload.data.data;
      return {
        ...state,
        statusLoading: false,
        ReportContainerStatus: ContainerStatus,
      };
    }
    case "CONTAINERSTATUS_REPORT_REJECTED": {
      return {
        ...state,
        statusLoading: false,
        ReportContainerStatus: [],
      };
    }

    case "DRAYOS_EMPTIESSLOT_INPROGRESS": {
      return {
        ...state,
        emptiesSlotLoading: true,
        emptiesSlotSummary: [],
      };
    }
    case "DRAYOS_EMPTIESSLOT_SUCCESS": {
      let list = action.payload.data.data;
      return {
        ...state,
        emptiesSlotLoading: false,
        emptiesSlotSummary: list,
      };
    }
    case "DRAYOS_EMPTIESSLOT_REJECTED": {
      return {
        ...state,
        emptiesSlotLoading: false,
        emptiesSlotSummary: [],
      };
    }

    case "DRAYOS_FILTERED_EMPTIESSLOTDATA_INPROGRESS": {
      return {
        ...state,
        emptiesSlotDownloading: true,
      };
    }
    case "DRAYOS_FILTERED_EMPTIESSLOTDATA_SUCCESS": {
      if (action.payload.data.data.buffer) {
        return {
          ...state,
          emptiesSlotDownloading: false,
        };
      } else {
        return {
          ...state,
          filteredEmptiesSlot: action.payload.data.data,
          emptiesSlotDownloading: false,
        };
      }
    }
    case "DRAYOS_FILTERED_EMPTIESSLOTDATA_REJECTED": {
      return {
        ...state,
        emptiesSlotDownloading: false,
      };
    }
    case "SET_EMPTIESSLOT_PARAMS": {
      let param = action.payload.searchParams;
      let post = action.payload.searchPost;
      return {
        ...state,
        emptiesSlotSearchParams: param,
        emptiesSlotSearchPost: { ...post, reset: false },
      };
    }
    case "GET_APPOINTMENT_ENABLED_CARRIER_INPROGRESS": {
      return {
        ...state,
        apptLoading: true,
      };
    }
    case "GET_APPOINTMENT_ENABLED_CARRIER_SUCCESS": {
      return {
        ...state,
        apptLoading: false,
        apptEnabledCarrier: action.payload.data.data,
      };
    }
    case "GET_APPOINTMENT_ENABLED_CARRIER_REJECTED": {
      return {
        ...state,
        apptLoading: false,
      };
    }
    case "GET_APPOINTMENT_REPORT_INPROGRESS": {
      return {
        ...state,
        apptReportLoading: true,
      };
    }
    case "GET_APPOINTMENT_REPORT_SUCCESS": {
      return {
        ...state,
        apptReportLoading: false,
        apptReport: action.payload.data.data.data,
        apptReportCount: action.payload.data.data.TotalCount,
      };
    }
    case "GET_APPOINTMENT_REPORT_REJECTED": {
      return {
        ...state,
        apptReportLoading: false,
      };
    }
    case "RESET_APPOINTMENT_REPORT_PARAMS": {
      return {
        ...state,
        searchParams: {
          limit: 50,
          page: 1,
          reset: true,
        },
      };
    }

    case "GET_APPOINTMENT_SLOT_REPORT_INPROGRESS": {
      return {
        ...state,
        apptSlotReportLoading: true,
      };
    }
    case "GET_APPOINTMENT_SLOT_REPORT_SUCCESS": {
      return {
        ...state,
        apptSlotReportLoading: false,
        apptSlotReport: action.payload.data.slotsData,
      };
    }
    case "GET_APPOINTMENT_SLOT_REPORT_REJECTED": {
      return {
        ...state,
        apptSlotReportLoading: false,
      };
    }

    case "SET_APPOINTMENT_REPORT_PARAMS": {
      let param = action.payload.searchParams
        ? action.payload.searchParams
        : state.apptReportSearchParams;
      return {
        ...state,
        apptReportSearchParams: param,
      };
    }
    default:
      return state;
  }
};
