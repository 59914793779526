import React, { useEffect, useState } from "react";
import { useAppContext } from "contexts/AppContext";

import SubHeaderTab from "Common/Components/SubHeaderTab";
import SearchFilter from "./Components/SearchFilter";
import SummaryTable from "./Components/SummaryTable";
import DownloadCsv from "./Components/DownloadCsv";
import { getCarrierDetail } from "./action";

const DrayosSummaryReport = () => {
  const { state, myDispatch } = useAppContext();
  const { carriers, crLoading } = state.reportDRStates;
  const [params, setParams] = useState<any>({ recent: 45, carrier: "ALL" });

  useEffect(() => {
    myDispatch(getCarrierDetail());
  }, []);
  return (
    <div className="page-wrapper">
      <div className="position-relative subheader-wrapper">
        <SubHeaderTab val="reports" buttons={<DownloadCsv page="drayosSummary" />} />
        <div className="page-fluid">
          <div className="top-page">
            <div className="pb-15 d-flex align-items-center">
              <h4 className="mb-0">Drayos Summary Report</h4>
            </div>
            <SearchFilter params={params} setParams={setParams} />
          </div>

          <SummaryTable params={params} />
        </div>
      </div>
    </div>
  );
};

export default DrayosSummaryReport;
