import React, { useEffect } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { IDashboardContainerFilterValues } from "../dashboard";
import { useAppContext } from "contexts/AppContext";
import CustomSelectField, {
  CustomAsyncSelectField,
} from "Common/Components/Inputs/CustomSelectField";
import { IconReset } from "Common/Icons";
import {
  getAllUsers,
  getfilterContainer,
  resetAvailableParams,
  setDashboardAvailableParams,
} from "../action";
import { getDuplicateArrayofObjects } from "pages/EmptyReturn/Common/utils";

const FilterForm = () => {
  //API Initialize
  const { state, myDispatch } = useAppContext();
  const { filterSearchPost, userData, searchParams } = state.dashboardStates;
  const { containerOwners, containerSizes, containerTypes, noCredentialPorts } = state.configStates;

  const { userSelectedTerminals } = state.appStates;

  const userSelectedPorts = getDuplicateArrayofObjects(
    noCredentialPorts,
    userSelectedTerminals,
    "terminalCode",
    "code"
  );

  //CALL Filter API
  const filterContainers = async (data: any) => {
    const response = await getfilterContainer(data, searchParams);
    myDispatch(response);
  };

  // REACT HOOK FORM
  const {
    reset,
    register,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm<IDashboardContainerFilterValues>({
    defaultValues: filterSearchPost,
    shouldUnregister: false,
    mode: "all",
  });

  // HOOK FORM SUBMIT
  const onFormSubmit: SubmitHandler<IDashboardContainerFilterValues> = async (values) => {
    let data: any = {};
    data.containerOwner = values.containerOwner?.value || "";
    data.containerType = values.containerType?.value || "";
    data.containerSize = values.containerSize?.value || "";
    data.portId = values.portId?.value || "";
    // data.userId = values.userId || "";
    data.userId = values.userId?.value || values.userId || "";
    let searchPostD = { ...filterSearchPost, ...data };
    myDispatch(filterContainers(searchPostD));
    myDispatch(setDashboardAvailableParams(searchPostD, searchParams));
  };

  const UserList = async (email?: string) => {
    const data = {};
    if (email) Object.assign(data, { email });
    const response = await getAllUsers(data);
    myDispatch(response);
    return response.payload;
  };

  // useEffect(() => {
  //   UserList();
  // }, []);

  // API CALL FOR RESET
  useEffect(() => {
    if (filterSearchPost.reset) {
      reset(filterSearchPost);
    }
  }, [filterSearchPost]);

  const handleReset = () => {
    myDispatch(resetAvailableParams());
  };

  //Port Options
  const PORT_CHOICES = userSelectedPorts.map((it: any) => ({
    label: it.name,
    value: it._id,
  }));

  const promiseOptions = (inputValue: string) =>
    new Promise<any>((resolve) => {
      setTimeout(async () => {
        await UserList(inputValue).then((res) => {
          resolve(
            res.data?.data?.splice(0, 10).map((ud: any) => ({ label: ud.email, value: ud._id }))
          );
        });
      }, 1000);
    });

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onFormSubmit)}>
        {/* Reset FIlter */}
        <div className="d-flex px-30 align-items-center justify-content-between py-20">
          <p className="font-20 mb-0">Filters</p>
          <button
            type="button"
            className="btn btn-outline-light btn-sm py-1"
            onClick={() => handleReset()}
          >
            <IconReset />
          </button>
        </div>
        {/* Filter List */}
        <div className="mt-30 px-30 overflow-y-scroll" style={{ height: "calc(100vh - 200px)" }}>
          <React.Fragment>
            <div className="form-group form-row">
              <label className="col-md-5 col-form-label">Port</label>
              <div className="col-md-7">
                <CustomSelectField
                  isLoading={false}
                  isClearable={true}
                  isSearchable={true}
                  name="portId"
                  options={PORT_CHOICES}
                  control={control}
                  size="small"
                />
              </div>
            </div>
            <div className="form-group form-row">
              <label className="col-md-5 col-form-label">Container Type</label>
              <div className="col-md-7">
                <CustomSelectField
                  isLoading={false}
                  isClearable={true}
                  isSearchable={true}
                  name="containerType"
                  options={containerTypes.map((ct: any) => ({ label: ct.name, value: ct._id }))}
                  control={control}
                  size="small"
                />
              </div>
            </div>
            <div className="form-group form-row">
              <label className="col-md-5 col-form-label">Container Size</label>
              <div className="col-md-7">
                <CustomSelectField
                  isLoading={false}
                  isClearable={true}
                  isSearchable={true}
                  name="containerSize"
                  options={containerSizes.map((ct: any) => ({ label: ct.name, value: ct._id }))}
                  control={control}
                  size="small"
                />
              </div>
            </div>
            <div className="form-group form-row">
              <label className="col-md-5 col-form-label">SSL</label>
              <div className="col-md-7">
                <CustomSelectField
                  isLoading={false}
                  isClearable={true}
                  isSearchable={true}
                  name="containerOwner"
                  options={containerOwners.map((ct: any) => ({ label: ct.name, value: ct._id }))}
                  control={control}
                  size="small"
                />
              </div>
            </div>
            <div className="form-group form-row">
              <label className="col-md-5 col-form-label">Company Name</label>
              <div className="col-md-7">
                <CustomAsyncSelectField
                  isLoading={false}
                  isClearable={true}
                  isSearchable={true}
                  name="userId"
                  defaultOptions={true}
                  options={promiseOptions}
                  control={control}
                  size="small"
                />
              </div>
            </div>
            {/* <div className="form-group form-row">
              <label className="col-md-5 col-form-label">Company Name</label>
              <div className="col-md-7">
                <CustomSelectField
                  isLoading={false}
                  isClearable={true}
                  isSearchable={true}
                  name="userId"
                  defaultOptions={true}
                  options={userData.map((ud: any) => ({ label: ud.email, value: ud._id }))}
                  control={control}
                  size="small"
                />
              </div>
            </div> */}
          </React.Fragment>
          <div className="hr-light my-30"></div>
        </div>
        <div className="bdpopup__footer page-fluid">
          <button type="submit" className="btn btn-primary btn-block">
            Apply
          </button>
        </div>
      </form>
    </React.Fragment>
  );
};

export default FilterForm;
