import { useAppContext } from "contexts/AppContext";
import OnboardingInvite from "pages/OnboardingModal/Components/OnboardingInvite";
import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";

const Header = () => {
  const { state, myDispatch } = useAppContext();
  const { loggedUser } = state.appStates;
  const [showModal, setShowModal] = useState(false);
  return (
    <header className="topbar">
      <nav className="navbar top-navbar navbar-expand-md navbar-light">
        <div className="d-flex align-items-center">
          <span
            className="font-18 ml-3 d-lg-none"
            style={{ color: "#555b6d" }}
            data-sidebartype="mini-sidebar"
          >
            <i className="uil uil-bars"></i>
          </span>
          {/* Search Input*/}
          <div className={`app-search d-none header-search position-relative `}>
            <span className={`search-icon`}></span>
            <div className="position-relative">
              <input
                type={"search"}
                className="form-control input-search-left"
                placeholder="Search PortPro.."
                style={{ width: "454px" }}
              />
            </div>
          </div>
        </div>

        <div className="navbar-collapse collapse justify-content-end" id="navbarSupportedContent">
          <ul className="navbar-nav float-right d-flex align-items-center">
            {/* Quick Add Options */}
            {/* <li data-tip data-for="QuickAdd" className="nav-item position-relative">
              <span className="nav-link d-flex align-items-center">
                <button className="btn btn-success btn-circle btn-sm"></button>
              </span>
            </li> */}

            {/* Chat */}
            {/* <li className={`nav-item`}>
              <span
                data-tip
                data-for="Chat"
                id="floatingChatsSideBarHandler"
                className={`nav-link position-relative `}
              >
                <span className="notification-icon-header">2</span>
              </span>
            </li> */}

            <li className="nav-item">
              <span
                data-tip
                data-for="Email"
                id="floatingChatsSideBarHandler"
                className="nav-link text-gray-300"
                style={{ position: "relative" }}
              ></span>
            </li>

            <li className="nav-item nav-item-image pointer">
              <span className="d-flex align-items-center nav-link">
                <div className="avatar-circle">
                  <img
                    src="https://axle-logistics.s3.amazonaws.com/production/tms/shipment_WrEUM672717.png"
                    className="avatar-circle__image rounded-circle"
                    alt=""
                    width="30"
                  />
                </div>
                <span className="ml-2">
                  Hi,
                  <span className="ml-1 font-medium text-dark text-capitalize">
                    {loggedUser?.fullName}
                  </span>
                </span>
              </span>
            </li>
          </ul>
        </div>
      </nav>
      {showModal && <OnboardingInvite isOnboarding={showModal} />}
    </header>
  );
};

export default Header;
