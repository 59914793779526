import SubHeaderTab from "Common/Components/SubHeaderTab";
import { useAppContext } from "contexts/AppContext";
import { useEffect, useState } from "react";

import { IconDownload } from "Common/Icons";

import { getReportContainers, setReportContainersParams, getReportContainersCsv } from "./action";
import ReportContainerTable from "./Components/ReportContainerTable";
import ReportTabs from "./Components/ReportTabs";
import Pagination from "Common/Components/Pagination";
import SearchFilter from "./Components/SearchFilter";

import DownloadCsv from "./Components/DownloadCsv";
import { handleInputChange } from "react-select/dist/declarations/src/utils";

const ReportContainer = () => {
  const { state, myDispatch } = useAppContext();
  const { searchParams, searchPost, reportContainerCount, csvReportContainers, csvLoading } =
    state.reportContainerStates;
  const [isDrayos, setIsDrayos] = useState<any>(false);
  searchPost.isDrayos = isDrayos;
  const { limit, page } = searchParams;

  useEffect(() => {
    getAllReportContainers();
  }, [searchParams, searchPost, isDrayos]);

  // fetch Report Containers
  const getAllReportContainers = async () => {
    const response = await getReportContainers(searchPost, searchParams);
    myDispatch(response);
  };

  const onPaginate = (val: any) => {
    let searchParamsD = { ...searchParams, page: val };
    myDispatch(setReportContainersParams(searchPost, searchParamsD));
  };

  return (
    <div className="page-wrapper">
      <div className="position-relative subheader-wrapper">
        <SubHeaderTab
          val="reports"
          buttons={
            <DownloadCsv page="report" searchParams={searchParams} searchPost={searchPost} />
          }
        />
        <div className="page-fluid">
          <div className="top-page">
            <div className="pb-15 d-flex align-items-center">
              {/* <h4 className="mb-0">Report Container</h4> */}
              <ReportTabs />
              <div className="ml-auto">
                <Pagination
                  className={"ml-auto"}
                  pageRangeDisplayed={5}
                  totalItemsCount={reportContainerCount}
                  itemsCountPerPage={limit}
                  onChange={onPaginate}
                  activePage={page}
                />
              </div>
            </div>
            <SearchFilter setIsDrayos={setIsDrayos} isDrayos={isDrayos} />
          </div>
          <ReportContainerTable />
        </div>
      </div>
    </div>
  );
};
export default ReportContainer;
