import { ReactChild, ReactFragment, ReactPortal } from "react";
import { IconAngleArrowRight } from "../../Icons";

export interface CardProps {
  cardData: any;
}

const TopCards = (props: CardProps) => {
  const { cardData } = props;
  return (
    <div className={`form-row pb-15`}>
      {cardData.map((data: any, i: number) => {
        return (
          <div className="col-xl col-md-3 col-sm-6 col-6" key={i}>
            <div className={`ncard d-flex flex-column justify-content-between`}>
              <div className="d-flex align-items-center mb-10">
                <div className={`mr-1 sbox sbox--default`}>38</div>
                <div className="font-medium">{data.cardMainName}</div>
                <div className="ml-auto">
                  <IconAngleArrowRight />
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between form-row">
                <div className="col-6">
                  <div className={`bbox bbox--default`}>
                    <div className="bbox__title">{data.cardSubNameOne}</div>
                    <div className="bbox__number">20</div>
                  </div>
                </div>
                <div className="col-6">
                  <div className={`bbox bbox--default`}>
                    <div className="bbox__title">{data.cardSubNameTwo}</div>
                    <div className="bbox__number">1</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default TopCards;
