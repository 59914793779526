import { IconCalendar } from "Common/Components/Icons/Icons";
import React, { useEffect, useState } from "react";
import Datetime from "react-datetime";
import { SelectFieldWithoutControl } from "Common/Components/Inputs/CustomSelectField";
import moment, { Moment } from "moment";
import { getTimeZone, getTodayDate } from "pages/EmptyReturn/Common/utils";
import { useAppContext } from "contexts/AppContext";
import { getEmptyCorrections, getFilterEmptiesData } from "pages/EmptyReturn/action";
import { LA_PORT_CHOICES, NY_PORT_CHOICES } from "./constant";

const options = [{ value: "APM", label: "APM" }];

const SearchFilter = (props: any) => {
  // ---------------------------------------------------- PROPS---------------------------------------------------//
  const { terminal, onTerminalChange, allTerminals, data } = props;
  // ---------------------------------------------------- Dispatch API And Reducer---------------------------------------------------//
  const { state, myDispatch } = useAppContext();
  // ---------------------------------------------------- STATE MANAGEMENT---------------------------------------------------//
  const [date, setDate] = useState<Moment>();
  const [utcDate, setUtcDate] = useState("");
  const [selectedTerminal, setSelectedTerminal] = useState<any>({});
  const [selectedPort, setSelectedPort] = useState<any>(null);
  // ---------------------------------------------------- HANDLE FUNCTION---------------------------------------------------//
  const handleDateChange = (dateValue: any) => {
    setDate(moment(dateValue));
    setUtcDate(dateValue.toISOString());
  };
  const handleTerminalChange = (term: any) => {
    setSelectedTerminal(term);
    setSelectedPort(null);
  };
  const handlePortChange = (port: any) => {
    setSelectedPort(port);
  };
  // ---------------------------------------------------- HOOKS MANAGEMENT ---------------------------------------------------//
  useEffect(() => {
    if (terminal.value) {
      setSelectedPort(terminal.value === "LA" ? LA_PORT_CHOICES[0] : NY_PORT_CHOICES[0]);
    }
    if (allTerminals.length) {
      setSelectedTerminal(allTerminals[0]);
    }
  }, [terminal, allTerminals]);

  useEffect(() => {
    if (utcDate && selectedTerminal.value && selectedPort) {
      const payload = {
        terminal: selectedTerminal.value || terminal,
        date: utcDate,
        ports: [selectedPort.value],
      };
      myDispatch(getFilterEmptiesData(payload));
      onTerminalChange(payload);
    } else if (date && selectedTerminal && selectedPort) {
      const payload = {
        terminal: selectedTerminal.value,
        date: utcDate,
        ports: [selectedPort.value],
      };
      onTerminalChange(payload);
      myDispatch(getFilterEmptiesData(payload));
    }
  }, [utcDate, selectedTerminal, selectedPort]);

  useEffect(() => {
    if (selectedTerminal) {
      const timeZone = getTimeZone(selectedTerminal.value);
      setDate(moment(getTodayDate()));

      setUtcDate(getTodayDate().toISOString());
    }
  }, [selectedTerminal]);

  return (
    <React.Fragment>
      <div className="filter-row d-flex align-items-center">
        <div className="input-wrapper w-200 mr-2">
          <Datetime
            inputProps={{
              placeholder: "Select date..",
            }}
            closeOnSelect
            onChange={(value) => {
              handleDateChange(value);
            }}
            value={date}
          />
          <div className="input-icon">
            <IconCalendar />
          </div>
        </div>
        <SelectFieldWithoutControl
          className="w-200 mr-2"
          classNamePrefix="select"
          isLoading={false}
          isClearable={false}
          isSearchable={false}
          name="terminal"
          options={allTerminals}
          size="small"
          placeholder="Select Terminal"
          value={selectedTerminal}
          onChange={(value: any) => handleTerminalChange(value)}
        />
        <SelectFieldWithoutControl
          className="w-200 mr-2"
          classNamePrefix="select"
          isLoading={false}
          isClearable={false}
          isSearchable={false}
          name="terminal"
          options={selectedTerminal.value === "LA" ? LA_PORT_CHOICES : NY_PORT_CHOICES}
          size="small"
          value={selectedPort}
          placeholder="Select Port"
          onChange={(value: any) => handlePortChange(value)}
        />
        {/* <div className="ml-auto">
          <button className="btn btn-success">submit</button>
        </div> */}
      </div>
    </React.Fragment>
  );
};

export default SearchFilter;
