import * as yup from "yup";

export const companyInfoSchema = yup
  .object({
    fullName: yup.string().max(255).required("Full Name is required"),
    // companyType: yup.object().required("Company Type is required"),
    // companyName: yup.string().required("Company Name is required"),
    // companyAddress: yup.string().required("Company Name is required"),
  })
  .required();

export const paymentInfoScheme = yup
  .object({
    cardInfo: yup
      .number()
      .required("Card Details is required")
      .typeError("Card Details is required")
      .test(
        "len",
        "Must be exactly 15-16 numbers",
        (val) => val?.toString().length === 16 || val?.toString().length === 15
      ),
    expiryDate: yup
      .string()
      .required("Expiry Date is required")
      .test("check", "Expiry Date is Invalid", (val: any) => {
        return val ? val.includes("/") : "";
      }),
    cvc: yup
      .string()
      .typeError("Must be a number and string")
      .test(
        "len",
        "Must be exactly number and string",
        (val) => val?.toString().length === 3 || val?.toString().length === 4
      )
      .required("CVC Number is required"),
    cardName: yup.string().required("Card Name is required"),
  })
  .required();
