import SubHeaderTab from "Common/Components/SubHeaderTab";
import VesselTabs from "./Common/VesselTabs";
import SearchFilter from "./Common/SearchFilter";
import VesselScheduleTable from "./Common/VesselScheduleTable";
import { VesselSchedulePropsI } from "./vessel";
import { useEffect, useState } from "react";
import { useAppContext } from "contexts/AppContext";

const VesselSchedule = (props: any) => {
  const { tab } = props;
  const { state, myDispatch } = useAppContext();
  const { vessels, searchPost } = state.vesselStates;
  const { port } = searchPost;
  const [s3Url, setS3Url] = useState<string>("");

  useEffect(() => {
    let image = vessels && vessels[0]?.s3Url;

    if (image) {
      setS3Url(image);
    } else {
      setS3Url("");
    }
  });
  return (
    <div className="page-wrapper">
      <div className="position-relative subheader-wrapper">
        <SubHeaderTab val="vesselScheduleItems" />
        <div className="page-fluid">
          <div className="top-page">
            <div className="pb-15 d-flex align-items-center justify-content-between">
              <VesselTabs />
            </div>
            {/* Filter Row */}
            <SearchFilter imageUrl={s3Url} />
          </div>
          <VesselScheduleTable />
        </div>
      </div>
    </div>
  );
};

export default VesselSchedule;
