import React from "react";
import { IconFire, IconSnowFlake } from "Common/Components/Icons/Icons";
import { Modal } from "react-bootstrap";

export interface ModalPropsI {
  showAddModal: any;
  hideAddModal: any;
}

const BookingDetails = (props: ModalPropsI) => {
  const { showAddModal, hideAddModal } = props;
  return (
    <Modal show={showAddModal} centered animation={false} size="xl">
      <Modal.Header>
        <div className="d-flex flex-column">
          <div className="text-muted mb-1">Booking Details</div>
          <Modal.Title>
            TGCU5156546
            <IconFire className="text-danger ml-1" />
            <IconSnowFlake className="text-primary" />
          </Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body>
        {/* Booking Details */}
        <React.Fragment>
          <div className="font-14 font-medium mb-10">Booking Details</div>
          <div className="row">
            <div className="col-md">
              <div className="text-muted">Vessel</div>
              <div className="font-medium">Ever Laden</div>
            </div>
            <div className="col-md">
              <div className="text-muted">Voyage</div>
              <div className="font-medium">1034-050W</div>
            </div>
            <div className="col-md">
              <div className="text-muted">Port of Loading</div>
              <div className="font-medium">New York, NY United States</div>
            </div>
            <div className="col-md">
              <div className="text-muted">Port of Discharge</div>
              <div className="font-medium">Busan (ex Pusan), Korea, South</div>
            </div>
            <div className="col-md">
              <div className="text-muted">Port of Destination</div>
              <div className="font-medium">Busan (ex Pusan), Korea, South</div>
            </div>
          </div>
          <div className="hr-light"></div>
        </React.Fragment>

        {/* Vessel Times */}
        <React.Fragment>
          <div className="font-14 font-medium mb-10">Vessel Times</div>
          <div className="row">
            <div className="col-md">
              <div className="text-muted">ETA</div>
              <div className="font-medium">
                01/28/2022
                <div>06:00 PM</div>
              </div>
            </div>
            <div className="col-md">
              <div className="text-muted">ERD</div>
              <div className="font-medium">
                01/28/2022
                <div>06:00 PM</div>
              </div>
            </div>
            <div className="col-md">
              <div className="text-muted">Cargo Cutoff</div>
              <div className="font-medium">
                01/28/2022
                <div>06:00 PM</div>
              </div>
            </div>
            <div className="col-md">
              <div className="text-muted">Hazardous Cutoff</div>
              <div className="font-medium">
                01/28/2022
                <div>06:00 PM</div>
              </div>
            </div>
            <div className="col-md"></div>
          </div>
          <div className="hr-light"></div>
        </React.Fragment>

        {/* Information */}
        <React.Fragment>
          <div className="font-14 font-medium mb-10">Information</div>
          <div className="row">
            <div className="col-md">
              <div className="text-muted">Type & Size</div>
              <div className="font-medium">40’ 9’6’ Reefer</div>
            </div>
            <div className="col-md">
              <div className="text-muted">QTY</div>
              <div className="font-medium">3</div>
            </div>
            <div className="col-md">
              <div className="text-muted">Delivered</div>
              <div className="font-medium">3</div>
            </div>
            <div className="col-md">
              <div className="text-muted">Delivery Balance</div>
              <div className="font-medium">0</div>
            </div>
            <div className="col-md">
              <div className="text-muted">Received</div>
              <div className="font-medium">3</div>
            </div>
          </div>
          <div className="hr-light"></div>
        </React.Fragment>

        {/* Trucker Info */}
        <React.Fragment>
          <div className="font-14 font-medium mb-10">Trucker Info</div>
          <div className="row">
            <div className="col-md">
              <div className="text-muted">Trucker</div>
              <div className="font-medium">Team Trucking</div>
            </div>
          </div>
          <div className="hr-light"></div>
        </React.Fragment>

        {/* Auditing Information */}
        <React.Fragment>
          <div className="font-14 font-medium mb-10">Auditing Information</div>
          <div className="row">
            <div className="col-md">
              <div className="text-muted">Added by</div>
              <div className="font-medium">John Jhonson</div>
            </div>
            <div className="col-md">
              <div className="text-muted">Added at</div>
              <div className="font-medium">
                01/28/2022
                <div>06:00 PM</div>
              </div>
            </div>
            <div className="col-md"></div>
            <div className="col-md"></div>

            <div className="col-md"></div>
          </div>
        </React.Fragment>
        <textarea className="form-control mt-20" placeholder="Notes example..."></textarea>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-primary" onClick={hideAddModal}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default BookingDetails;
