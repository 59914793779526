import React from "react";

interface IProps {
  spinning?: boolean;
  fullScreen?: boolean;
}
const Loader = (props: IProps) => {
  return (
    <div className="page-loader-wrapper">
      <div className="page-loader"></div>
    </div>
  );
};
export default Loader;
