import React, { useEffect, useState } from "react";

import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Papa from "papaparse";
import { useAppContext } from "contexts/AppContext";
import { getSyncLoadDetail } from "../action";

declare global {
  interface Navigator {
    msSaveBlob?: (blob: any, defaultName?: string) => boolean;
  }
}
const DownloadSyncContainer = (props: any) => {
  const { state, myDispatch } = useAppContext();

  const { syncLoading, syncContainers } = state.reportDRStates;

  const onDownloadhh = () => {
    myDispatch(getSyncLoadDetail(props.carrier));
  };

  useEffect(() => {
    syncContainers.length > 0 && onDownload();
  }, [syncContainers]);

  const onDownload = () => {
    let csvval = convertToCsv(syncContainers);
    console.log("downloading...");
    let csv = Papa.unparse(csvval);
    let csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    let csvURL = null;
    if (navigator.msSaveBlob) {
      csvURL = navigator.msSaveBlob(csvData, `syncedLoads_${new Date().getTime()}.csv`);
    } else {
      csvURL = window.URL.createObjectURL(csvData);
    }
    let tempLink: any = document.createElement("a");
    tempLink.href = csvURL;
    tempLink.setAttribute("download", `syncedLoads_${new Date().getTime()}.csv`);
    tempLink.click();
  };
  return (
    <>
      <button className="btn btn-danger" onClick={() => onDownloadhh()} disabled={syncLoading}>
        {syncLoading && (
          <span
            className="spinner-border spinner-border-sm mr-2"
            role="status"
            aria-hidden="true"
          ></span>
        )}
        Download Synced Load
      </button>
    </>
  );
};

export default DownloadSyncContainer;

const convertToCsv = (values: any) => {
  let csvalues = [];
  csvalues = values.map((val: any) => {
    let csvVal: any = {};
    csvVal = { ...val };
    csvVal.systemGeneratedContainerSizeName = val.systemGeneratedContainerSizeName;
    csvVal.systemGeneratedEstimatedToArrive = val.systemGeneratedEstimatedToArrive;
    csvVal.systemGeneratedOwnerName = val.systemGeneratedOwnerName;
    csvVal.systemGeneratedTypeName = val.systemGeneratedTypeName;
    csvVal.systemGeneratedVesselName = val.systemGeneratedVesselName;
    csvVal.systemGeneratedVoyageName = val.systemGeneratedVoyageName;
    csvVal.systemGeneratedDischarge = val.systemGeneratedDischarge;
    return csvVal;
  });
  return csvalues;
};
