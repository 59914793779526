import React, { useState } from "react";
import { LogoPortProColor } from "Common/Components/Icons/CustomIcons";
import { IconCheckCircle } from "Common/Components/Icons/Icons";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";

const features = [
  {
    title: "Track your containers",
    desc: `ETA, LFD, Discharge Date Availability, Holds, Outgate Time and Ingate Time TIRs.`,
  },
  {
    title: "Per Diem Protection",
    desc: `View historical locations for your empty returns and fight against incorrect charges with screenshots of proof.`,
  },
  {
    title: "Empty Return Locations",
    desc: `View all your empty return locations in ONE place, and have a historical record of past days return availability.`,
  },
];

const testimonials: { id: number }[] = [];
for (let i = 1; i < 5; i++) {
  testimonials.push({
    id: i,
  });
}

const AuthSideBar = () => {
  const responsive = {
    sliderItems: {
      breakpoint: { max: 4000, min: 0 },
      items: 1,
      arrows: "false",
    },
  };
  return (
    <div className="signup-wrapper--left bg-white d-flex flex-column vh-100 p-40">
      <div className="logo-wrapper">
        <Link to={"/signup"}>
          <LogoPortProColor />
        </Link>
      </div>
      <p className="mt-25 mb-0 text-gray-500">
        The most intuitive transportation management system for container drayage carriers & a
        customer support team that goes the extra mile.
      </p>
      <div className="mt-70">
        <ul className="list-style-none">
          {features.map((feature, index) => (
            <li className="border-0 mb-20" key={index}>
              <h5>
                <IconCheckCircle className="text-primary mr-10" />
                {feature.title}
              </h5>
              <p className="text-gray-500 mt-10">{feature.desc}</p>
            </li>
          ))}
        </ul>
      </div>
      <div className="mt-auto d-none">
        <Carousel
          containerClass="custom-card-carousel"
          responsive={responsive}
          infinite={true}
          arrows={false}
          showDots={true}
          autoPlay={true}
          dotListClass="custom-dot"
        >
          {testimonials.map((testimonial) => {
            return (
              <div className="slider-item  h-100" key={testimonial.id}>
                <p>
                  “Amazing service! Claire helped me to reduce the shipping price a little and
                  shipped it immediately after purchasing. The amethyst cave I got is a beauty and I
                  c...”
                </p>
                <div className="mt-20 text-muted">
                  <div>Body Czeladka </div>
                  <div className="mt-1 font-weight-500">Director at BigPort</div>
                </div>
              </div>
            );
          })}
        </Carousel>
      </div>
    </div>
  );
};

export default AuthSideBar;
