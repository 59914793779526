import React, { useState } from "react";
import { Toast, ToastContainer } from "react-bootstrap";

interface ToastPropI {
  status: "success" | "warning" | "error";
  title?: string;
  message: string;
  show: boolean;
  reset?: any;
  position?:
    | "top-start"
    | "top-center"
    | "top-end"
    | "middle-start"
    | "middle-center"
    | "middle-end"
    | "bottom-start"
    | "bottom-center"
    | "bottom-end";
}

const Toastr = (props: ToastPropI) => {
  const { status, message, show, reset, title, position } = props;
  const [showToastr, setShowToastr] = useState(show);

  return (
    <div aria-live="polite" aria-atomic="true" className="position-relative">
      <ToastContainer position={position || "top-end"}>
        <Toast
          className="text-white"
          onClose={() => {
            setShowToastr(false);
            if (reset) {
              reset();
            }
          }}
          bg={status}
          delay={30000}
          show={showToastr}
          autohide
        >
          {title && (
            <Toast.Header>
              <strong className={`me-auto ${status} text-capitalize`}>{title}</strong>
            </Toast.Header>
          )}
          <Toast.Body>{message}</Toast.Body>
        </Toast>
      </ToastContainer>
    </div>
  );
};

export default Toastr;
