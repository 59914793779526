import { SetStateAction, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { IconCheck } from "Common/Icons/index";
import { useAppContext } from "contexts/AppContext";
import { IPortTypes, ITerminalTypes } from "../onboarding";
import _ from "lodash";
import { assignTerminal } from "reducers/action/appAction";

export interface ModalPropsI {
  showModal: any;
  hideModal: any;
  showNextModal: any;
}

const SelectPortMarkets = (props: any) => {
  const { activeStep, handleActive, showModal, handlePorts, handlePrevious } = props;
  const { state, myDispatch } = useAppContext();
  const { ports, loading } = state.configStates;
  const { loggedUser, assignPortErMessage, ploading } = state.appStates;
  const [selectAll, setSelectAll] = useState(false);
  const [selectedPort, setSelectedPort] = useState<any[]>([]);
  const [tempSelectedPort, setTempSelectedPort] = useState<any[]>([]);

  const handleSearchTerminal = (e: any) => {
    const text = e?.target.value;
    const temp = tempSelectedPort;
    const searchData = tempSelectedPort.filter((data: ITerminalTypes) => {
      return data.name.toUpperCase().includes(text.toUpperCase());
    });
    if (text) {
      setSelectedPort(searchData);
    } else {
      setSelectedPort(temp);
    }
  };

  const handleSelectedPort = (port: string) => {
    const temp = tempSelectedPort;
    const findIndex = tempSelectedPort.findIndex((tt: any) => tt.name === port);
    temp[findIndex].checked = !tempSelectedPort[findIndex].checked;
    setSelectedPort([...temp]);
    setTempSelectedPort([...temp]);
  };

  useEffect(() => {
    const temp: any[] = [];
    const tempPorts: any[] = [];
    loggedUser.terminal.map((term: any) => {
      return tempPorts.push(...ports.filter((pp: any) => pp.terminalCode === term.code));
    });
    if (tempPorts.length) {
      tempPorts.map((item: any) => {
        return temp.push({
          ...item,
          // checked: !!item.portId,
          checked: true,
        });
      });
      setSelectedPort([...temp]);
      setTempSelectedPort([...temp]);
    }
  }, [ports]);

  const handleSelectAll = (select: boolean) => {
    let allSelectedPort = selectedPort.map((pp: any) => {
      return {
        ...pp,
        checked: select,
      };
    });
    setSelectedPort(allSelectedPort);
    setTempSelectedPort(allSelectedPort);
    setSelectAll(select);
  };

  const handleNext = () => {
    const portSelected: any[] = [];
    selectedPort.map((select) => {
      return select.checked && portSelected.push(select);
    });
    if (portSelected.length) {
      handlePorts(portSelected, activeStep);
    }
  };

  return (
    <Modal show={showModal && activeStep === 2} centered animation={false} size="xl">
      <Modal.Header className="justify-content-center">
        <Modal.Title>Select Ports</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex align-items-center justify-content-center">
          <div className="app-search bg-gray-50 w-50 rounded-lg mr-20">
            <input
              type="text"
              className="input-search-left form-control py-2"
              placeholder="Search Terminal..."
              onChange={(e: any) => handleSearchTerminal(e)}
            />
            <span className="search-icon ml-1"></span>
          </div>
          <button
            className={`btn btn-outline-${selectAll ? "warning" : "light"}`}
            onClick={() => handleSelectAll(Boolean(!selectAll))}
          >
            {selectAll ? "Unselect All" : "Select All"}
          </button>
        </div>
        <div className="c-list__grid mt-40">
          {selectedPort?.length > 0 &&
            selectedPort.map((port: IPortTypes, index: number) => (
              <div
                className={`clist__card clist__card--outline text-center py-15 px-10 ${
                  port.checked ? "active" : ""
                }`}
                key={index}
                onClick={() => handleSelectedPort(port.name)}
              >
                {port.code}
                <div className={port.checked ? "checked" : "unchecked"}>
                  <IconCheck className="text-white" />
                </div>
              </div>
            ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-outline-light mr-10 " onClick={() => handlePrevious(activeStep)}>
          Back
        </button>
        <button className="btn btn-primary" onClick={() => handleNext()}>
          {state.appStates.loading && (
            <span
              className="spinner-border spinner-border-sm mr-1"
              role="status"
              aria-hidden="true"
            ></span>
          )}
          Next
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default SelectPortMarkets;
