import React, { Fragment, useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormValue } from "../login";

import { IconEye, IconEyeClosed } from "Common/Icons";
import CustomInputField from "Common/Components/Inputs/CustomInputField";
import { loginSchema } from "./AuthValidation";
import { useAppContext } from "contexts/AppContext";
import { loginUser, onAuthReset } from "reducers/action/appAction";
// import { isLoggedIn as isAUth } from "utils";

const Login = () => {
  let navigate = useNavigate();
  const { state, myDispatch } = useAppContext();
  const { errorMessage, isLoggedIn, loading } = state.appStates;
  // REACT HOOK FORM
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<FormValue>({
    mode: "onBlur",
    resolver: yupResolver(loginSchema),
  });

  // FORM SUBMIT
  const onFormSubmit: SubmitHandler<FormValue> = async (data) => {
    let logged = await loginUser(data);
    myDispatch(logged);
  };

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/");
    }
  }, [isLoggedIn]);

  useEffect(() => {
    return () => {
      // On Unmount reset the state
      myDispatch(onAuthReset());
    };
  }, []);

  return (
    <Fragment>
      <div className="signup-wrapper--right bg-animation h-100 p-20">
        <div className="d-flex align-items-center justify-content-end">
          <div className="text-muted mr-10">Don't have an account?</div>
          <Link to="/signup">
            <button className="btn btn btn-outline-light">Sign up</button>
          </Link>
        </div>
        <div className="d-flex align-items-center justify-content-center h-100">
          <div className="form-wrapper w-max p-50 bg-white rounded-10 shadow-5">
            <h3 className="text-center mb-50">Login to drayOS Lite</h3>
            {errorMessage && (
              <div className="alert alert-warning" role="alert">
                {errorMessage}
              </div>
            )}
            <form className="form-sm" onSubmit={handleSubmit(onFormSubmit)}>
              <CustomInputField
                placeholder="Email *"
                name="email"
                type="text"
                required
                register={register}
                autoComplete="off"
                error={Boolean(errors.email)}
                helper={errors.email ? errors.email.message : ""}
                label={
                  <span>
                    <span className="text-danger">*</span> Email
                  </span>
                }
                className="form-control"
              />

              <CustomInputField
                placeholder="Password"
                autoComplete="off"
                name="password"
                type="password"
                required
                error={Boolean(errors.password)}
                register={register}
                helper={errors.password ? errors.password.message : ""}
                className="form-control"
                label={
                  <span>
                    <span className="text-danger">*</span> Password
                  </span>
                }
              />
              <Link to="/forget" className="text-muted mr-10 d-flex justify-content-end">
                Forgot Password?
              </Link>
              <div className="form-group mt-40">
                <button type="submit" className="btn btn-primary btn-lg btn-block mt-40">
                  {loading && (
                    <span
                      className="spinner-border spinner-border-sm mr-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                  Sign In
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Login;
