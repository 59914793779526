export const dashboardTabItems: any = {
  dashboardTab: [
    {
      id: 1,
      title: "Container_Info",
      name: "Container Info",
      path: "/dashboard/containers",
      label: "Container Info",
    },
    {
      id: 2,
      title: "Super_Admin",
      name: "Container Info",
      path: "/dashboard/Super_Admin",
      label: "Super Admin",
    },
    // {
    //   id: 3,
    //   title: "User_Info",
    //   name: "Container Info",
    //   path: "/dashboard/User_Info",
    //   label: "User Info",
    // },
  ],
};

export const tableHeaders = [
  {
    label: "Container#",
    value: "containerNo",
    sort: "-",
  },
  {
    label: "Status",
    value: "status",
    sort: "-",
  },

  {
    label: "Holds",
    value: "custom",
    sort: "-",
  },
  {
    label: "SSL",
    value: "containerOwner",
    sort: "-",
  },
  {
    label: "Size/Type",
    value: "containerSize",
    sort: "-",
  },
  {
    label: "PickUp Terminal",
    value: "portId",
    sort: "-",
  },

  // {
  //   label: "Terminal",
  //   value: "terminalCode",
  //   sort: "-",
  // },

  {
    label: "ETA",
    value: "eta",
    sort: "-",
  },
  {
    label: "Discharge Date",
    value: "dischargeDate",
    sort: "-",
  },
  {
    label: "LFD",
    value: "lastFreeDay",
    sort: "-",
  },
  {
    label: "Out Gate",
    value: "outGateDate",
    sort: "-",
  },
  {
    label: "Terminated",
    value: "terminatedDate",
    sort: "-",
  },
  {
    label: "Vessel Name",
    value: "vesselName",
    sort: "-",
  },
  {
    label: "Voyage",
    value: "voyageName",
    sort: "-",
  },
  {
    label: "BOL",
    value: "billOfLading",
    sort: "-",
  },
  {
    label: "Weight",
    value: "grossWeight",
    sort: "-",
  },
  {
    label: "Seal",
    value: "seal",
    sort: "-",
  },
  {
    label: "Yard Location",
    value: "yardLocation",
    sort: "-",
  },
  {
    label: "Return Terminal",
    value: "emptyReturnLocation",
    sort: "-",
  },
  // {
  //   label: "Demurrage",
  //   value: "demurrage",
  //   sort: "-",
  // },
];

export const UsertableHeaders = [
  {
    label: "Company Name",
    value: "companyName",
    sort: "-",
  },
  {
    label: "Company Type",
    value: "companyType",
    sort: "-",
  },
  {
    label: "System",
    value: "system",
    sort: "-",
  },
  {
    label: "Email",
    value: "email",
    sort: "-",
  },
  {
    label: "Role",
    value: "role",
    sort: "-",
  },
  ,
  {
    label: "Terminal",
    value: "terminal",
    sort: "-",
  },
  {
    label: "sub Subscription Type",
    value: "subSubscriptionType",
    sort: "-",
  },
  {
    label: "subscription Type",
    value: "subscriptionType",
    sort: "-",
  },
  {
    label: "Paid",
    value: "paid",
    sort: "-",
  },
  {
    label: "Subscription",
    value: "cancelSubscription",
    sort: "-",
  },
  {
    label: "carrierScac",
    value: "carrierScac",
    sort: "-",
  },
  {
    label: "otherCarrierScac",
    value: "otherCarrierScac",
    sort: "-",
  },
  {
    label: "Update Scac",
    value: "updateScac",
    sort: "-",
  },
  {
    label: "GPA Authorized",
    value: "gpaAuthorized",
    sort: "-",
  },
  {
    label: "Update GPA Authorization",
    value: "updateGpaAuthorized",
    sort: "-",
  },
];

export const APP_SYSTEM: { [key: string]: string } = Object.freeze({
  SHIP_OS: "shipos",
  DRAY_OS: "drayos",
});
