import React from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

const ConfirmationAlertModal = (props: any) => {
  const {
    title,
    message,
    icon,
    buttons,
    showModal,
    onCancel = () => {},
    onSubmit = () => {},
  } = props;

  return (
    <Modal show={showModal} centered size="sm" backdrop="static">
      <Modal.Body className="p-0">
        <div className="text-center py-50 px-60 justify-content-center d-flex flex-column">
          <div className="mb-30">{icon}</div>
          <div className="">{title}</div>
          {/* <p className="text-muted mt-10 font-14">{message}</p> */}
          <Link to="/">
            <button
              type="button"
              // onClick={() => onConfirm()}
              className={`btn text-center btn-primary btn-lg btn-block`}
            >
              Click here to Login
            </button>
          </Link>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmationAlertModal;
