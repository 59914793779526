import { useEffect, useState } from "react";
import SubHeaderTab from "Common/Components/SubHeaderTab";
import Pagination from "Common/Components/Pagination";
import { useAppContext } from "contexts/AppContext";
import { getWrongDataReports, setWrongDataReportParams } from "./action";
import WrongDataReportTable from "./Components/WrongDataReportTable";
import SearchFilter from "./Components/SearchFilter";
import { IconDownload } from "Common/Icons";
import DownloadCsv from "pages/ReportContainer/Components/DownloadCsv";

const WrongDataReport = () => {
  const { state, myDispatch } = useAppContext();
  const { wrongDataReportCount, searchParams, searchPost } = state.wrongDataReportStates;

  useEffect(() => {
    getWrongContainersReport();
  }, [searchParams, searchPost]);

  // fetch Problem Containers
  const getWrongContainersReport = async () => {
    const response = await getWrongDataReports(searchParams, searchPost);
    myDispatch(response);
  };

  // Paginate
  const onPaginate = (val: any) => {
    let searchParamsD = { ...searchParams, page: val };
    myDispatch(setWrongDataReportParams(searchParamsD, searchPost));
  };

  const onDowloadCSV = () => {
    let searchParamsD = { ...searchParams, isCsv: true };
    myDispatch(setWrongDataReportParams(searchParamsD, searchPost));
  };

  return (
    <div className="page-wrapper">
      <div className="position-relative subheader-wrapper">
        <SubHeaderTab
          val="reports"
          buttons={
            <DownloadCsv
              page="wrongDataReport"
              searchParams={searchParams}
              searchPost={searchPost}
            />
          }
        />
        <div className="page-fluid">
          <div className="top-page">
            <div className="pb-15 d-flex align-items-center">
              <h4 className="mb-0">Wrong Data Reports</h4>
              <div className="ml-auto">
                <Pagination
                  className={"ml-auto"}
                  pageRangeDisplayed={5}
                  totalItemsCount={wrongDataReportCount}
                  itemsCountPerPage={searchParams.limit}
                  onChange={onPaginate}
                  activePage={searchParams.page}
                />
              </div>
            </div>
            <SearchFilter />
          </div>
          <WrongDataReportTable />
        </div>
      </div>
    </div>
  );
};

export default WrongDataReport;
