import { TIMESCALE_REPORT_TYPES } from "./action";
import { ICronData, IStatus } from "./types/TTimeScaleTypes";

type TInitialState = {
  eta: any[];
  status: any[];
  lastFreeDay: any[];
  freight: any[];
  loading: boolean;
  tableLoading: boolean;
  tableData: ICronData[];
  showCard: boolean;
  showTable: boolean;
};
export const initialReportTimeScale: TInitialState = {
  eta: [],
  status: [],
  lastFreeDay: [],
  freight: [],
  loading: false,
  tableLoading: false,
  tableData: [],
  showCard: false,
  showTable: false,
};

export const timeScaleReducer = (
  state: typeof initialReportTimeScale,
  action: {
    type: string;
    payload: any;
  }
) => {
  switch (action.type) {
    case TIMESCALE_REPORT_TYPES.GETTIMESCALEREPORT_INPROGRESS: {
      return {
        ...state,
        loading: true,
        showCard: false,
      };
    }

    case TIMESCALE_REPORT_TYPES.GETTIMESCALEREPORT_SUCCESS: {
      const fields: IStatus["type"][] = ["status", "eta", "freight", "lastFreeDay"];
      console.log("action.payload", action.payload);
      const allFields: any = {};
      action.payload.forEach((e: any, index: number) => {
        if (e.status === "fulfilled") {
          allFields[fields[index]] = e?.value?.data?.data || [];
        } else {
          allFields[fields[index]] = [];
        }
      });
      return {
        ...state,
        ...allFields,
        loading: false,
        showCard: true,
      };
    }

    case TIMESCALE_REPORT_TYPES.GETTABLEDATA_INPROGRESS: {
      return {
        ...state,
        tableLoading: true,
        showTable: false,
      };
    }

    case TIMESCALE_REPORT_TYPES.GETTABLEDATA_SUCCESS: {
      return {
        ...state,
        tableData: action.payload?.data?.data || [],
        tableLoading: false,
        showTable: true,
      };
    }

    default: {
      return state;
    }
  }
};
