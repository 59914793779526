import { useCallback, useEffect, useState } from "react";
import { IconCalendar, IconPrinter } from "Common/Icons";
import Datetime from "react-datetime";
import moment, { Moment } from "moment";
import { useAppContext } from "contexts/AppContext";
import { getVesselLists, setVesselParams } from "VesselSchedule/action";
import axios from "axios";
import { headers } from "config/api";
import { getTodayDate } from "pages/EmptyReturn/Common/utils";
import _ from "lodash";
import { downloadImage } from "utils/commonFunctions";

interface IProps {
  imageUrl?: string;
}
const SearchFilter = (props: IProps) => {
  const { imageUrl } = props;
  const { state, myDispatch } = useAppContext();
  const { vessels, searchParams, searchPost, vesselPort } = state.vesselStates;

  const { port } = searchPost;
  const [date, setDate] = useState<Moment>(moment());
  const [downloading, setDownloading] = useState(false);

  //Search Vessel Name
  const [vesselName, setVesselName] = useState<string>();

  const onChangeInput = (event: any) => {
    let val = event.target.value;
    setVesselName(val?.trim());
  };
  const debouncedChangeHandler = useCallback(_.debounce(onChangeInput, 1000), []);

  useEffect(() => {
    if (searchPost?.vesselName !== vesselName) {
      const vesselDate = moment(date).toISOString();
      const searchPostD = {
        ...searchPost,
        vesselName,
        date: [vesselDate],
      };
      myDispatch(getVesselLists(searchPostD, searchParams));
      // myDispatch(setVesselParams(searchPostD, searchParams));
    }
  }, [vesselName]);

  const handleDateChange = async (dateValue: any) => {
    const vesselDate = moment(dateValue).toISOString();
    const searchPostD = {
      ...searchPost,
      date: [vesselDate],
    };
    const response = await getVesselLists(searchPostD, searchParams);
    myDispatch(response);
    setDate(dateValue);
  };

  useEffect(() => {
    setDate(moment(getTodayDate()));
  }, [port]);

  return (
    <div className="filter-row d-flex align-items-center mt-2">
      {/* Search */}
      <div className="app-search header-search">
        <span className="search-icon"> </span>
        <div className="position-relative">
          <input
            type="search"
            className="form-control input-search-left w-150"
            placeholder="Search..."
            defaultValue={vesselName}
            onChange={debouncedChangeHandler}
          />
        </div>
      </div>

      {/* Select Date */}
      <div className="input-wrapper w-200 ml-5">
        <Datetime
          inputProps={{
            placeholder: "Select date..",
          }}
          onChange={(value) => handleDateChange(value)}
          closeOnSelect
          value={date}
        />
        <div className="input-icon">
          <IconCalendar />
        </div>
      </div>
      <button
        className="btn btn-primary ml-auto"
        onClick={() => downloadImage(imageUrl, vesselPort, date, setDownloading)}
      >
        {downloading && (
          <span
            className="spinner-border spinner-border-sm mr-2"
            role="status"
            aria-hidden="true"
          ></span>
        )}
        <IconPrinter className="mr-10" />
        Download File
      </button>
    </div>
  );
};

export default SearchFilter;
