import sslFile from "./sslFile.json";
import nySslFile from "./nysslFile.json";
import moment from "moment";
import { getCurrentWeek, nth } from "./Common/utils";

export const cardItems: any = (
  date: string,
  terminal: string,
  value: string,
  shift?: string,
  shiftCount?: number
) => {
  const weekDateArr = getCurrentWeek();
  if (terminal === "LA" && weekDateArr?.length && value === "currentEmpties" && !shift) {
    const weekData = weekDateArr?.map((wD: any, index) => {
      return {
        id: index + 1,
        Shift: "shift1",
        Date: moment(wD, "MM-DD-YYYY").format("LL"),
        title: moment(wD, "MM-DD-YYYY").format("dddd"),
        weekDate: wD,
        formatWeek: wD,
      };
    });
    return weekData;
  } //for LA and oakland
  else if (
    terminal === "LA" ||
    (Number(terminal) > 2 && newMarketWithTwoShift.includes(terminal) && shift)
  ) {
    const shiftArray = Array.from(Array(shiftCount).keys());
    const shiftsList = shiftArray?.map((num) => {
      return {
        id: num + 1,
        Shift: `shift${num + 1}`,
        status: `status${num + 1}`,
        Date: date,
        title: `${num + 1}${nth(num + 1)} Shift`,
      };
    });
    return shiftsList;
    //for Ny
  } else if (terminal === "NY" && value === "currentEmpties") {
    return [
      {
        id: 1,
        Shift: "shift1",
        Date: date,
        title: "1st Shift",
        isTomorrow: false,
      },
      {
        id: 2,
        Shift: "shift1",
        Date: moment().add(1, "day").format("dddd, LL"),
        title: "1st Shift",
        isTomorrow: true,
      },
    ];
  } else {
    //for all new market except oakland
    return [
      {
        id: 1,
        Shift: "shift1",
        Date: date,
        title: "1st Shift",
        isTomorrow: false,
      },
    ];
  }
};

export const ports = [
  {
    code: 1,
    title: "APM_TERMINAL",
  },
  {
    code: 2,
    title: "FENIX",
  },
  {
    code: 3,
    title: "LBCT",
  },
  {
    code: 4,
    title: "EVERPORT TERMINAL",
  },
  {
    code: 5,
    title: "PCT",
  },
  {
    code: 6,
    title: "PIER_A",
  },
  {
    code: 7,
    title: "YTI",
  },
  {
    code: 8,
    title: "WBCT",
  },
  {
    code: 9,
    title: "ITS_TERMINAL",
  },
  {
    code: 10,
    title: "TRAPAC",
  },
  {
    code: 11,
    title: "TTI",
  },
];

export const nyPorts = [
  {
    code: 1,
    title: "APM",
  },
  {
    code: 2,
    title: "MAHER_TERMINAL",
  },
  {
    code: 3,
    title: "GCT_BAYONNE",
  },
  {
    code: 4,
    title: "GCT_NY",
  },
  {
    code: 5,
    title: "PNCT",
  },
  {
    code: 6,
    title: "C&C MAINTENANCE",
  },
  {
    code: 7,
    title: "COLUMBIA CONTAINER SERVICES 1100",
  },
  {
    code: 8,
    title: "COLUMBIA CONTAINER SERVICES 1155",
  },
  {
    code: 9,
    title: "IRONBOUND HYATT",
  },
  {
    code: 10,
    title: "IRONBOUND DELANCY",
  },
];

export const getInitialFilter = (terminal: string) => {
  let temp1: any = [
    {
      key: "PORTS",
      value: [],
      checked: true,
    },
    {
      key: "SSL",
      value: [],
      checked: true,
    },
    {
      key: "CONTAINER TYPES",
      value: [],
      checked: true,
    },
  ];

  if (terminal === "LA") {
    if (!localStorage.getItem("LAEmptyFilter")) {
      ports.map((pp) => {
        return temp1[0].value.push({
          label: pp.title,
          checked: true,
        });
      });
      sslFile.shipping_lines.map((ss) => {
        return temp1[1].value.push({
          label: ss,
          checked: true,
        });
      });
      sslFile.container_types.map((ct) => {
        return temp1[2].value.push({
          label: ct,
          checked: true,
        });
      });
      localStorage.setItem("LAEmptyFilter", JSON.stringify(temp1));
      return localStorage.getItem("LAEmptyFilter");
    } else {
      return localStorage.getItem("LAEmptyFilter");
    }
  } else if (terminal === "NY") {
    if (!localStorage.getItem("NYEmptyFilter")) {
      nyPorts.map((pp) => {
        return temp1[0].value.push({
          label: pp.title,
          checked: true,
        });
      });
      nySslFile.shipping_lines.map((ss) => {
        return temp1[1].value.push({
          label: ss,
          checked: true,
        });
      });
      nySslFile.container_types.map((ct) => {
        return temp1[2].value.push({
          label: ct,
          checked: true,
        });
      });
      localStorage.setItem("NYEmptyFilter", JSON.stringify(temp1));
      return localStorage.getItem("NYEmptyFilter");
    } else {
      return localStorage.getItem("NYEmptyFilter");
    }
  }
};

export const getInitialFilterForNewMarket = (
  terminalCode: any,
  allPorts: any,
  containerOwners: any
) => {
  let temp1: any = [
    {
      key: "PORTS",
      value: [],
      checked: true,
    },
    {
      key: "SSL",
      value: [],
      checked: true,
    },
    {
      key: "CONTAINER TYPES",
      value: [],
      checked: true,
    },
  ];

  const newMarketPorts = allPorts.filter((p: any) => p.terminalCode === Number(terminalCode));
  if (terminalCode) {
    if (!localStorage.getItem(`${terminalCode}EmptyFilter`)) {
      newMarketPorts.map((pp: any) => {
        return temp1[0].value.push({
          label: pp.code,
          checked: true,
        });
      });

      containerOwners.map((ss: any) => {
        return temp1[1].value.push({
          label: ss?.sslLabel,
          checked: true,
        });
      });

      nySslFile.container_types.map((ct) => {
        return temp1[2].value.push({
          label: ct,
          checked: true,
        });
      });

      localStorage.setItem(`${terminalCode}EmptyFilter`, JSON.stringify(temp1));
      return localStorage.getItem(`${terminalCode}EmptyFilter`);
    } else {
      return localStorage.getItem(`${terminalCode}EmptyFilter`);
    }
  }
};

export const emptyTabItems: any = {
  currentEmpties: [
    {
      id: 1,
      title: "LA/LB",
      path: "/current-empties/LA-LB",
      label: "LA",
    },
    {
      id: 2,
      title: "NJ/NY",
      path: "/current-empties/NJ-NY",
      label: "NY",
    },
    { id: 4, title: "HOUSTON", path: "/current-empties/4", label: "HOUSTON" },

    {
      id: 5,
      title: "CHARLESTON",
      path: "/current-empties/5",
      label: "CHARLESTON",
    },
    {
      id: 6,
      title: "NORFOLK/VIRGINIA",
      path: "/current-empties/6",
      label: "NORFOLK",
    },
    {
      id: 8,
      title: "SAVANNAH",
      path: "/current-empties/8",
      label: "SAVANNAH",
    },
    {
      id: 9,
      title: "JACKSONVILLE",
      path: "/current-empties/9",
      label: "JACKSONVILLE",
    },
    {
      id: 10,
      title: "ATLANTA",
      path: "/current-empties/10",
      label: "ATLANTA",
    },
    {
      id: 11,
      title: "MIAMI",
      path: "/current-empties/11",
      label: "MIAMI",
    },
    {
      id: 12,
      title: "PHILADELPHIA",
      path: "/current-empties/12",
      label: "PHILADELPHIA",
    },
    {
      id: 13,
      title: "CALGARY",
      path: "/current-empties/13",
      label: "CALGARY",
    },
    {
      id: 14,
      title: "EDMONTON",
      path: "/current-empties/14",
      label: "EDMONTON",
    },
    {
      id: 15,
      title: "TORONTO",
      path: "/current-empties/15",
      label: "TORONTO",
    },
    {
      id: 16,
      title: "BOSTON",
      path: "/current-empties/16",
      label: "BOSTON",
    },
    {
      id: 17,
      title: "SEATTLETACOMA",
      path: "/current-empties/17",
      label: "SEATTLETACOMA",
    },
    {
      id: 18,
      title: "CHICAGO",
      path: "/current-empties/18",
      label: "CHICAGO",
    },
    {
      id: 19,
      title: "OAKLAND",
      path: "/current-empties/19",
      label: "OAKLAND",
    },
    {
      id: 20,
      title: "MINNESOTA",
      path: "/current-empties/20",
      label: "MINNESOTA",
    },
  ],
  historyEmpties: [
    {
      id: 1,
      title: "Per Diem Records",
      path: "/empty-history/per-diem-records",
      label: "Per Diem Records",
    },
    {
      id: 2,
      title: "Terminal Screengrabs",
      path: "/empty-history/terminal-screengrabs",
      label: "Terminal Screengrabs",
    },
  ],
};

export const SSL_CHOICES = sslFile.shipping_lines.map((ss) => ({
  label: ss,
  value: ss,
}));

export const LA_PORT_CHOICES = ports.map((pp) => ({
  label: pp.title.replaceAll("_", " "),
  value: pp.title,
}));

export const NY_PORT_CHOICES = nyPorts
  .filter((item) => item.title !== "C&C MAINTENANCE")
  .filter((item) => item.title !== "COLUMBIA CONTAINER SERVICES 1100")
  .filter((item) => item.title !== "COLUMBIA CONTAINER SERVICES 1155")
  .filter((item) => item.title !== "IRONBOUND HYATT")
  .filter((item) => item.title !== "IRONBOUND DELANCY")
  .map((pp) => ({
    label: pp.title.replaceAll("_", " "),
    value: pp.title,
  }));

export const TERMINAL_CHOICES = emptyTabItems.currentEmpties.map((tt: any) => ({
  label: tt.title,
  value: tt.label,
  code: tt.id,
}));

export const TYPES_CHOICES = sslFile.container_types.map((type) => ({
  label: type,
  value: type,
}));

export const staticPort = [
  "APM",
  "GCT_NY",
  "GCT_BAYONNE",
  "MAHER_TERMINAL",
  "C&C MAINTENANCE",
  "COLUMBIA CONTAINER SERVICES 1100",
  "COLUMBIA CONTAINER SERVICES 1155",
  "IRONBOUND HYATT",
  "IRONBOUND DELANCY",
  "PNCT",
];

export const NoAppointMentPorts = [
  "GCT NY",
  "PNCT",
  "MAHER TERMINAL",
  "BAYPORT TERMINAL",
  "BNSF",
  "BARBOURS CUT TERMINALS",
  "SHIPPERS CARSON EXPRESS",
  "STA LONG BEACH",
  "C&C MAINTENANCE",
  "COLUMBIA CONTAINER SERVICES 1100",
  "COLUMBIA CONTAINER SERVICES 1155",
  "IRONBOUND HYATT",
  "IRONBOUND DELANCY",
];
// for ny port
export const nyPort = [
  {
    code: 1,
    title: "APM",
  },
  {
    code: 2,
    title: "MAHER_TERMINAL",
  },
  {
    code: 3,
    title: "GCT_BAYONNE",
  },
  {
    code: 4,
    title: "GCT_NY",
  },
  {
    code: 5,
    title: "PNCT",
  },
  {
    code: 6,
    title: "C&C MAINTENANCE",
  },
  {
    code: 7,
    title: "COLUMBIA CONTAINER SERVICES 1100",
  },
  {
    code: 8,
    title: "COLUMBIA CONTAINER SERVICES 1155",
  },
  {
    code: 9,
    title: "IRONBOUND HYATT",
  },
  {
    code: 10,
    title: "IRONBOUND DELANCY",
  },
];

// get standard data

//all terminalcode and terminal name

interface TerminalNameConstantType {
  [key: string | number]: string;
}

export const terminalNameConstant: TerminalNameConstantType = Object.freeze({
  LA: "LA",
  NY: "NY",
  "1": "LA",
  "2": "NY",
  "3": "EUROPE",
  "4": "HOUSTON",
  "5": "CHARLESTON",
  "6": "NORFOLK",
  "7": "VIRGINIA",
  "8": "SAVANAH",
  "9": "JACKSONVILLE",
  "10": "ATLANTA",
  "11": "MIAMI",
  "12": "PHILADELPHIA",
  "13": "CALGARY",
  "14": "EDMONTON",
  "15": "TORONTO",
  "16": "BOSTON",
  "17": "SEATTLETACOMA",
  "18": "CHICAGO",
  "19": "OAKLAND",
  "20": "MINNESOTA",
});

export const timeZoneFormatter: TerminalNameConstantType = Object.freeze({
  LA: "America/Los_Angeles",
  NY: "America/New_York",
  HOUSTON: "America/Chicago",
  OAKLAND: "America/Los_Angeles",
  VIRGINIA: "America/New_York",
  NORFOLK: "America/New_York",
  SEATTLETACOMA: "America/Los_Angeles",
  MIAMI: "America/New_York",
  JACKSONVILLE: "America/New_York",
  CHARLESTON: "America/New_York",
  SAVANNAH: "America/New_York",
  ATLANTA: "America/New_York",
  PHILADELPHIA: "America/New_York",
  CALGARY: "America/Edmonton",
  TORONTO: "America/Toronto",
  BOSTON: "America/New_York",
  CHICAGO: "America/Chicago",
  MINNESOTA: "America/Los_Angeles",
  MEMPHIS: "America/New_York",
  MONTREAL: "America/New_York",
  VANCOUVER: "America/Vancouver",
  DETROIT: "America/New_York",
  DENVER: "America/Denver",
  DALLAS_FW: "America/Chicago",
  UTAH: "America/Denver",
  GREATER_NY: "America/New_York",
  ARIZONA: "America/Phoenix",
  NEVADA: "America/Los_Angeles",
  NEBRASKA: "America/Chicago",
  GREATER_TX: "America/Chicago",
  KENTUCKY: "America/New_York",
  KANSAS_CITY: "America/Chicago",
  NEWORLEANS: "America/Chicago",

  "1": "America/Los_Angeles",
  "2": "America/New_York",
  "3": "America/Amsterdam",
  "4": "America/Chicago",
  "5": "America/New_York",
  "6": "America/New_York",
  "7": "America/New_York",
  "8": "America/New_York",
  "9": "America/New_York",
  "10": "America/New_York",
  "11": "America/New_York",
  "12": "America/New_York",
  "13": "America/Edmonton",
  "14": "America/Edmonton",
  "15": "America/Toronto",
  "16": "America/New_York",
  "17": "America/Los_Angeles",
  "18": "America/Chicago",
  "19": "America/Los_Angeles",
  "20": "America/Los_Angeles",
  "21": "America/New_York",
  "22": "America/New_York",
  "23": "America/Vancouver",
  "24": "America/New_York",
  "25": "America/Denver",
  "26": "America/Chicago",
  "27": "America/Denver",
  "28": "America/New_York",
  "29": "America/Phoenix",
  "30": "America/Los_Angeles",
  "31": "America/Chicago",
  "32": "America/Chicago",
  "33": "America/New_York",
  "34": "America/Chicago",
  "35": "America/Chicago",
});

//new market only oakland have two shift
export const newMarketWithTwoShift: any = Object.freeze(["19"]);
