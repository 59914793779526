export const initialSuccessfulContainerValues = {
  successfulContainers: [],
  sLoading: false,
  errorMessage: "",
  successfulContainerCount: 0,

  searchParams: {
    limit: 50,
    page: 1,
  },
  searchPost: {
    status: "On Vessel",
    dataPoints: ["eta"],
  },
};

export const successfulContainerReducer = (
  state: any,
  action: {
    type: string;
    payload: any;
  }
) => {
  switch (action.type) {
    case "SUCCESSFULCONTAINERS_INPROGRESS": {
      return {
        ...state,
        sLoading: true,
      };
    }
    case "SUCCESSFULCONTAINERS_SUCCESS": {
      console.log("sucesscase", action);
      let successfulContainers = action.payload.data.data;
      return {
        ...state,
        sLoading: false,
        successfulContainers: successfulContainers,
        successfulContainerCount: action.payload.data.count,
      };
    }
    case "SUCCESSFULCONTAINERS_REJECTED": {
      return {
        ...state,
        sLoading: false,
        successfulContainers: [],
        errorMessage: action.payload.errorMessage,
        successfulContainerCount: 0,
      };
    }
    case "SET_SUCCESSFULCONTAINERS_PARAMS": {
      let param = action.payload.searchParams ? action.payload.searchParams : state.searchParams;
      let post = action.payload.searchPost ? action.payload.searchPost : state.searchPost;
      return {
        ...state,
        searchParams: param,
        searchPost: { ...post },
        sLoading: true,
      };
    }
    case "RESET_SUCCESSFULCONTAINERS_PARAMS": {
      return {
        ...state,
        searchParams: {
          limit: 50,
          page: 1,
          reset: true,
        },
        searchPost: { ...state.searchPost, portId: "", containerOwner: "", reset: true },
      };
    }
    //
    case "MISSINGREPORTCONTAINERS_INPROGRESS": {
      return {
        ...state,
        sLoading: true,
      };
    }
    case "MISSINGREPORTCONTAINERS_SUCCESS": {
      let missingContainers = action.payload.data.data;
      return {
        ...state,
        sLoading: false,
        missingContainers: missingContainers,
        missingContainerCount: action.payload.data.count,
      };
    }
    case "MISSINGREPORTCONTAINERS_REJECTED": {
      return {
        ...state,
        sLoading: false,
        missingContainers: [],
        errorMessage: action.payload.errorMessage,
        successfulContainerCount: 0,
      };
    }
    case "SET_MISSINGREPORTCONTAINERS_PARAMS": {
      let param = action.payload.searchParams ? action.payload.searchParams : state.searchParams;
      let post = action.payload.searchPost ? action.payload.searchPost : state.searchPost;
      return {
        ...state,
        searchParams: param,
        searchPost: { ...post },
        sLoading: true,
      };
    }
    case "RESET_MISSINGREPORTCONTAINERS_PARAMS": {
      return {
        ...state,
        searchParams: {
          limit: 50,
          page: 1,
          reset: true,
        },
        searchPost: { ...state.searchPost, portId: "", containerOwner: "", reset: true },
      };
    }
    default:
      return state;
  }
};
