import * as Yup from "yup";

export const InviteScheme = Yup.object().shape({
  email: Yup.array()
    .of(
      Yup.object()
        .shape({
          value: Yup.string().email("Please Provide a Valid Email"),
          label: Yup.string().email("Please Provide a Valid Email"),
          __isNew__: Yup.boolean(),
        })
        .required("Please Provide an Email")
    )
    .required("Required"),
});
