import React, { useEffect, useState } from "react";
import { Modal, Navbar } from "react-bootstrap";
// import "assets/scss/style/login.scss";

import AuthSideBar from "./Component/AuthSideBar";
import LoginForm from "./Component/LoginForm";
import SignUpForm from "./Component/SignUpForm";
import { PagePropsI } from "./login";
import { Link } from "react-router-dom";
import ConfirmationComponent from "./Component/ConfirmationComponent";
import ForgetForm from "./Component/ForgetForm";
import ResetForm from "./Component/ResetForm";
import UserActivateForm from "./Component/userActivateForm";

const Authentication = (props: PagePropsI) => {
  const { fromType } = props;
  const [pageVal, setPageVal] = useState({ title: "Login" });
  // -------------------- <START> STATE MANAGEMENT <START> ----------------------------------------
  const [isOpen, setIsOpen] = useState(false);
  const [toggleNav, setToggleNav] = useState(false);
  const [activeClass, setActiveClass] = useState("");
  const [email, setEmail] = useState("");
  // -------------------- <END> STATE MANAGEMENT <END> ---------------------------------------------

  // -------------------- <START> FUNCTIONS <START> ----------------------------------------
  const handleToggleNav = () => {
    setToggleNav(!toggleNav);
  };

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  // -------------------- <END> FUNCTIONS <END> ----------------------------------------

  // -------------------- <START> REACT HOOKS <START> ----------------------------------------
  useEffect(() => {
    //Add Class to body only on signin page
    document.getElementsByTagName("body")[0].className = "isloginpage";
  }, []);

  useEffect(() => {
    if (fromType === "login") {
      setPageVal({ title: "Login" });
    } else if (fromType === "signup") {
      setPageVal({ title: "Signup" });
    } else if (fromType === "forget" || fromType === "reset") {
      setPageVal({ title: "Reset Password" });
    }
  }, [fromType]);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 80) {
        setActiveClass("stick-to-top");
      } else {
        setActiveClass("");
      }
    });
  }, [activeClass]);
  // -------------------- <END> REACT HOOKS <END> ----------------------------------------

  return (
    <div className="signup-wrapper bg-gray-50">
      <div className="row no-gutters">
        <div className="col-lg-4 col-xl-3">
          <AuthSideBar />
        </div>
        <div className="col-lg-8 col-xl-9">
          {fromType === "login" && <LoginForm />}
          {fromType === "signup" && <SignUpForm />}
          {fromType === "forget" && <ForgetForm />}
          {fromType === "reset" && <ResetForm />}
          {fromType === "userActive" && <UserActivateForm />}
        </div>
      </div>
    </div>
  );
};

export default Authentication;
