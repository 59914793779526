import SubHeaderTab from "Common/Components/SubHeaderTab";
import { useAppContext } from "contexts/AppContext";
import { useEffect } from "react";

import { getVesselContainerReport, setReportContainersParams } from "./action";
import ReportContainerTable from "./Components/ReportContainerTable";
import ReportTabs from "./Components/ReportTabs";
import Pagination from "Common/Components/Pagination";

const VesselReportContainer = () => {
  const { state, myDispatch } = useAppContext();
  const { searchParams, searchPost, reportContainerCount, csvReportContainers, csvLoading } =
    state.vesselReportContainerStates;

  const { limit, page } = searchParams;

  useEffect(() => {
    getAllReportContainers();
  }, [searchParams, searchPost]);

  // fetch Report Containers
  const getAllReportContainers = async () => {
    const response = await getVesselContainerReport(searchPost, searchParams);
    myDispatch(response);
  };

  const onPaginate = (val: any) => {
    let searchParamsD = { ...searchParams, page: val };
    myDispatch(setReportContainersParams(searchPost, searchParamsD));
  };

  return (
    <div className="page-wrapper">
      <div className="position-relative subheader-wrapper">
        <SubHeaderTab val="reports" title="VESSEL CONTAINER REPORT" />

        <div className="page-fluid">
          <div className="top-page">
            <div className="pb-15 d-flex align-items-center">
              {/* <h4 className="mb-0">Report Container</h4> */}
              <div className="ml-auto">
                <Pagination
                  className={"ml-auto"}
                  pageRangeDisplayed={5}
                  totalItemsCount={reportContainerCount}
                  itemsCountPerPage={limit}
                  onChange={onPaginate}
                  activePage={page}
                />
              </div>
            </div>
          </div>
          <ReportContainerTable />
        </div>
      </div>
    </div>
  );
};
export default VesselReportContainer;
