import React from "react";
import { useEffect, useState } from "react";
import moment from "moment";
import { Modal } from "react-bootstrap";
import { getDetailedValue } from "utils/commonFunctions";
import { useAppContext } from "contexts/AppContext";
import Loader from "Common/Components/Loader";
interface IProps {
  values: any;
}

const Documents = (props: IProps) => {
  const { state, myDispatch } = useAppContext();
  const { ports, mLoading, containers } = state.containerStates;

  const { values } = props;
  // const [container, setContainer] = useState(null);
  const [viewImage, setViewImage] = useState<string | null>(null);
  const [docUrl, setDocUrl] = useState<string | "">("");

  const hasImage = (row: any) => {
    if (
      row.outGate?.s3Url ||
      row.inGate?.s3Url ||
      row.etaS3url ||
      (row.isUnAuthorized && row.status === "Outgated")
    ) {
      return true;
    }
    return false;
  };
  let msg = getwarningMsg(values, ports);
  // let msg = "";

  // useEffect(() => {
  //   const findCont = containers.find((ct:any) => ct._id === values._id);
  //   setContainer(findCont);
  // }, [containers]);

  useEffect(() => {
    if (viewImage === "ETA") {
      setDocUrl(values.etaS3url);
    } else if (viewImage === "OutGate") {
      setDocUrl(values.outGate?.s3Url);
    } else if (viewImage === "InGate") {
      setDocUrl(values.inGate?.s3Url);
    }
  }, [viewImage]);

  return (
    <React.Fragment>
      <div className="tab">
        <div className="bg-gray-50 pt-10">
          {!mLoading && msg && (
            <div className="alert alert-warning" role="alert">
              {msg}
            </div>
          )}
          {mLoading && <Loader />}
          {hasImage(values) ? (
            <div className="form-row-md">
              {values.etaS3url && (
                <div className="col-md-3">
                  <div
                    className={`card pointer mb-0 p-10 h-100 card--shadow-3`}
                    onClick={() => setViewImage("ETA")}
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <h5>{values.etaS3url && "ETA Image"}</h5>
                    </div>
                    <div className="document-preview">
                      <img
                        src={values.etaS3url}
                        height="100%"
                        alt=""
                        className="document-preview__image"
                      />
                    </div>
                    <div className="text-primary font-medium mb-2">{values.containerNo}</div>
                    <div className="text-muted mb-2">
                      {values.sslEta
                        ? moment(values.sslEta).format("MM/DD")
                        : values.eta
                        ? moment(values.eta).format("MM/DD")
                        : ""}
                    </div>
                  </div>
                </div>
              )}
              {values.inGate?.s3Url && (
                <div className="col-md-3">
                  <div
                    className={`card pointer mb-0 p-10 h-100 card--shadow-3`}
                    onClick={() => setViewImage("InGate")}
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <h5>{values.inGate?.s3Url && "InGate Image"}</h5>
                    </div>
                    <div className="document-preview">
                      <embed
                        type="text/html"
                        src={values.inGate?.s3Url}
                        width="100%"
                        height={"100%"}
                      ></embed>
                    </div>
                    <div className="text-primary font-medium mb-2">{values.containerNo}</div>
                    <div className="text-muted mb-2">
                      {moment(values.terminatedDate).format("MM/DD HH:mm")
                        ? moment(values.terminatedDate).format("MM/DD HH:mm")
                        : moment(values.inGate?.date).format("MM/DD HH:mm")}
                    </div>
                  </div>
                </div>
              )}
              {values.outGate?.s3Url && (
                <div className="col-md-3">
                  <div
                    className={`card pointer mb-0 p-10 h-100 card--shadow-3`}
                    onClick={() => setViewImage("OutGate")}
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <h5>{values.outGate?.s3Url && "OutGate Image"}</h5>
                    </div>
                    <div className="document-preview">
                      <embed
                        type="text/html"
                        src={values.outGate?.s3Url}
                        width="100%"
                        height="100%"
                      ></embed>
                    </div>
                    <div className="text-primary font-medium mb-2">{values.containerNo}</div>
                    <div className="text-muted mb-2">
                      {moment(values?.outGateDate).format("MM/DD HH:mm")
                        ? moment(values?.outGateDate).format("MM/DD HH:mm")
                        : moment(values.outGate?.date).format("MM/DD HH:mm")}
                    </div>
                  </div>
                </div>
              )}
              {values.isUnAuthorized && values.status === "Outgated" && !values.outGate && (
                <div className="col-md-3">
                  <div className={`card pointer mb-0 p-10 h-100 card--shadow-3`}>
                    <div className="d-flex justify-content-between align-items-center">
                      <h5>{"UnAuthorized"}</h5>
                    </div>
                    <div className="document-preview h-auto">
                      <img
                        src={require("assets/images/documents/maherUnauthorized.png")}
                        alt={"UnAuthorized"}
                        className="h-auto"
                      />
                    </div>
                    <div className="text-primary font-medium mb-2">{values.containerNo}</div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="p-40 text-center">
              <h4> {mLoading ? "" : "No Image Screenshots Available"}</h4>
            </div>
          )}
        </div>
      </div>
      <Modal show={viewImage ? true : false} centered animation={false} size="xl">
        <Modal.Header className="d-flex align-items-start modal-header--gray">
          <div>
            <h4 className="mb-0">
              {values && values.containerNo}'s {viewImage}
            </h4>
          </div>
        </Modal.Header>
        <Modal.Body className="py-30">
          <div style={{ height: "500px", overflow: "auto" }}>
            <embed type="text/html" src={docUrl} width="100%" height="100%"></embed>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-end">
          <div className="d-flex align-items-center">
            <button className="btn btn-primary" onClick={() => setViewImage(null)}>
              Close
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default Documents;

const getwarningMsg = (values: any, ports: any) => {
  let msg = null;

  if (values.status === "Terminated") {
    if (!values.outGate || (values.outGate && !values.outGate?.s3Url)) {
      msg = "Outgate document not found on " + getDetailedValue(values.portId, ports) + " Site!";
    }
    if (!values.inGate || (values.inGate && !values.inGate?.s3Url)) {
      msg = "Ingate document not found on " + getDetailedValue(values.portId, ports) + " Site!";
    }
    if (!values.inGate?.s3Url && !values.outGate && !values.outGate && !values.outGate?.s3Url) {
      msg =
        "Outgate && Ingate document not found on " +
        getDetailedValue(values.portId, ports) +
        " Site!";
    }
  }
  if (
    (values.status === "Outgated" && !values.outGate) ||
    (values.outGate && !values.outGate?.s3Url)
  ) {
    msg = "Outgate document not found on " + getDetailedValue(values.portId, ports) + " Site!";
  }
  return msg;
};
