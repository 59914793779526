import React, { useEffect, useRef, useState } from "react";
import TableHeader from "./Components/TableHeader";
import SubHeaderTab from "Common/Components/SubHeaderTab";
import SearchFilter from "../Common/SearchFilter";
import { useAppContext } from "contexts/AppContext";
import { setEmptiesData, getEmptyData, fetchGateHours } from "../action";
import TerminalTabs from "../Common/TerminalTabs";
import {
  NoAppointMentPorts,
  getInitialFilter,
  getInitialFilterForNewMarket,
  newMarketWithTwoShift,
  terminalNameConstant,
} from "../constant";
import { EmptiesTypes, LocationPropsI, SmallTypesI } from "../location";
import Loader from "Common/Components/Loader";
import moment from "moment";
import firebaseApp from "config/firebaseConfig";
import { getDatabase, onValue, ref, off } from "firebase/database";
import LAEmptyTable from "../Common/LAEmptyTable";
import NYEmptyTable from "../Common/NYEmptyTable";
import TopEmptyCard from "../Common/TopEmptyCard";
import { getCurrentWeek, getShiftActive, getTimeZone, handlePrevShift } from "../Common/utils";
import "moment-timezone";
import WeeklyEmptyCard from "../Common/WeeklyEmptyCard";
import { useParams } from "react-router-dom";
import NewMarketEmptyTable from "../Common/NewMarketEmptyTable";
import _ from "lodash";

const EmptyReturnLocations = (props: LocationPropsI) => {
  const { terminalTab, tab } = props;
  //for new market dev we pass terminalcode as params
  const { terminalCode } = useParams();
  const { state, myDispatch } = useAppContext();
  const { empties, eLoading, isTomorrow, shiftCount } = state.emptyStates;
  const { ports, containerOwners } = state.configStates;
  const { userSelectedTerminals } = state.appStates;
  const todayDate = moment().format("LLLL");
  const currentShift = getShiftActive(shiftCount);
  // ---------------------------------------------------- STATE MANAGEMENT---------------------------------------------------//
  const [empytData, setEmptyData] = useState<EmptiesTypes[]>([]);
  const [scroll, setScroll] = useState(0);
  const [shift, setShift] = useState<string>("shift1");
  const [LAshift, setLAShift] = useState<string>(currentShift);
  const [sslArray, setSslArray] = useState<SmallTypesI[]>([]);
  const [portsArray, setPortsArray] = useState<SmallTypesI[]>([]);
  const [containerTypes, setContainerTypes] = useState<SmallTypesI[]>([]);
  const [updateTime, setUpdateTime] = useState("");
  const [isToday, setIsToday] = useState(true);
  const [weekDateValue, setWeekDateValue] = useState(
    terminalTab === "LA" ? moment(getCurrentWeek()[0], "MM-DD-YYYY").format("LLLL") : todayDate
  );
  const [currentEmptyContainerOwner, setCurrentEmptyContainerOwner] = useState<Array<Object>>();
  const [emptyAppointmentSlotsFromFirebase, setEmptyAppointmentSlotsFromFirebase] = useState<
    string[] | null
  >(null);
  const divRef = useRef<HTMLDivElement>(null);
  const [timeZonedNowDate, setTimeZonedNowDate] = useState<object | null>(null);
  // ---------------------------------------------------- HOOKS MANAGEMENT ---------------------------------------------------//
  // useEffect(() => {
  //   getEmptiesData();
  // }, [terminalTab]);

  useEffect(() => {
    let datas = empties?.data;
    if (datas) {
      setEmptyData(datas);
      setUpdateTime(empties.updatedTime);
      myDispatch(setEmptiesData(datas));
    } else {
      setEmptyData([]);
    }
  }, [empties]);

  useEffect(() => {
    if ((terminalTab === "NY" || terminalTab === "LA") && !terminalCode) {
      const allValues = getInitialFilter(terminalTab);
      if (allValues) {
        const [newports, sslValue, types] = JSON.parse(allValues);
        setPortsArray(newports.value);
        setSslArray(sslValue.value);
        setContainerTypes(types.value);
      }
      //for new market
    } else if (terminalCode) {
      const onlyRequiredContainerOwner: Array<Object> = [];
      empties?.data?.forEach((owner: any) => {
        owner?.data?.data?.forEach((o: any) => {
          // console.log("OOOOOOOOOOO",o);
          onlyRequiredContainerOwner.push({ sslLabel: o.ssl, port: o.port });
        });
        return { sslLabel: owner.ssl, port: owner.port };
      });

      const uniqueSslLabel = _.uniqBy(onlyRequiredContainerOwner, "sslLabel");
      setCurrentEmptyContainerOwner(uniqueSslLabel);
      // console.log("uniqueSslLabel",currentEmptyContainerOwner);
      let allValues;

      if (Array.isArray(currentEmptyContainerOwner) && currentEmptyContainerOwner?.length > 0) {
        allValues = getInitialFilterForNewMarket(terminalCode, ports, currentEmptyContainerOwner);
      }

      if (allValues) {
        const [newports, sslValue, types] = JSON.parse(allValues);
        setPortsArray(newports.value);
        setSslArray(sslValue.value);
        setContainerTypes(types.value);
      }
    }
  }, [
    terminalTab,
    empties,
    getInitialFilter(terminalTab),
    terminalCode,
    Array.isArray(currentEmptyContainerOwner) &&
      currentEmptyContainerOwner.length > 0 &&
      getInitialFilterForNewMarket(terminalCode, ports, currentEmptyContainerOwner),
  ]);

  useEffect(() => {
    const div = divRef.current;
    div?.addEventListener("scroll", () => {
      setScroll(Math.abs(div?.scrollTop));
    });
    return div?.removeEventListener("scroll", () => setScroll(0));
  }, []);

  // ---------------------------------------------------- HANDLE FUNCTIONS ---------------------------------------------------//
  // const getEmptiesData = () => {
  //   myDispatch(getEmptyData(terminalTab));
  // };

  const handleActive = (acc: any) => {
    setShift(acc.Shift);

    if (todayDate === acc.Date) setIsToday(true);
    else setIsToday(false);
  };

  const handleWeekActive = (acc: any) => {
    setShift(acc.Shift);
    setLAShift(currentShift);
    setWeekDateValue(moment(acc.formatWeek, "MM-DD-YYYY").format("LLLL"));

    // if (todayDate === acc.Date) setIsToday(true);
    // else setIsToday(false);
  };

  const handleLAShiftActive = (shiftData: any) => {
    setLAShift(shiftData.Shift);
  };
  useEffect(() => {
    if (terminalTab === "LA" || newMarketWithTwoShift.includes(terminalCode)) {
      setLAShift(currentShift);
    }
  }, [currentShift]);

  useEffect(() => {
    // on component mount
    if (terminalTab === "LA") {
      myDispatch(fetchGateHours());
    } else if (terminalCode) {
      myDispatch(fetchGateHours({ terminal: terminalNameConstant[terminalCode] }));
    }

    let firebaseChannelName;
    const firebaseDB = getDatabase(firebaseApp);

    if (terminalTab === "LA") {
      firebaseChannelName = "emptiesAppointmentSlot/code_1";
      const timeZoneDate = moment().tz("America/Los_Angeles");
      setTimeZonedNowDate(timeZoneDate);
    } else {
      firebaseChannelName = "emptiesAppointmentSlot/code_2";
      const timeZoneDate = moment().tz("America/New_York");
      setTimeZonedNowDate(timeZoneDate);
    }

    // goOnline(firebaseDB);
    const emptyAppointmentSlotRef = ref(firebaseDB, firebaseChannelName);

    onValue(emptyAppointmentSlotRef, (snapshot) => {
      if (snapshot.exists()) {
        const firebaseData = JSON.parse(snapshot.val());
        setEmptyAppointmentSlotsFromFirebase(firebaseData);
      } else {
        console.log("not data in firebase");
        setEmptyAppointmentSlotsFromFirebase(null);
      }
    });

    // on component unmount
    return () => {
      //close firebase instance
      off(emptyAppointmentSlotRef);
    };
  }, []);

  const getEmptyAppointmentSlots = (sslData: any) => {
    const slotData: any = emptyAppointmentSlotsFromFirebase;
    // console.log(emptyAppointmentSlotsFromFirebase);
    const { ssl, types, port } = sslData;
    const sslTypeKey = [
      ssl + " " + types,
      "ALL" + " " + "ALL",
      "ALL" + " " + types,
      ssl + " " + "ALL",
    ];
    let allPortData: any = [];
    for (let i = 0; i < sslTypeKey.length; i++) {
      if (slotData?.hasOwnProperty(sslTypeKey[i])) {
        const portData = slotData[sslTypeKey[i]][port] || [];
        allPortData = [...allPortData, ...portData];
      }
    }
    const totalAvailableAppointmentSlots = allPortData.reduce((sum: number, current: any) => {
      if (current && current.appointmentSlotDate) {
        const appointmentSlotDate: any = moment(current.appointmentSlotDate);
        if (
          moment(timeZonedNowDate).isSame(appointmentSlotDate, "day") &&
          current.totalAvailableSlots
        ) {
          return sum + current.totalAvailableSlots;
        } else {
          return sum;
        }
      } else {
        return null;
      }
    }, 0);
    let label =
      totalAvailableAppointmentSlots > 0 || totalAvailableAppointmentSlots
        ? `${totalAvailableAppointmentSlots} Apts`
        : "No Apts";
    if (NoAppointMentPorts.includes(label)) label = "";
    return label;
  };

  return (
    <div ref={divRef} className="page-wrapper">
      {!eLoading && <Loader />}
      <div className="position-relative subheader-wrapper">
        <SubHeaderTab val="emptyReturnItems" />
        <div className="page-fluid">
          <div className="top-page">
            <TerminalTabs
              terminal={terminalTab}
              terminalCode={terminalCode}
              value="currentEmpties"
            />
            <SearchFilter
              tab={tab}
              terminal={terminalTab}
              terminalCode={terminalCode}
              isTomorrow={isTomorrow}
              currentEmptyContainerOwner={currentEmptyContainerOwner}
              page="currentEmpties"
            />
            {terminalTab === "LA" && (
              <WeeklyEmptyCard
                value="currentEmpties"
                terminal={terminalTab}
                terminalCode={terminalCode}
                date={todayDate}
                handleActive={handleWeekActive}
                shift={terminalTab === "LA" ? LAshift : shift}
              />
            )}

            <TopEmptyCard
              value="currentEmpties"
              terminal={terminalTab}
              terminalCode={terminalCode}
              date={weekDateValue}
              handleActive={handleActive}
              shift={
                terminalTab === "LA" || newMarketWithTwoShift.includes(terminalCode)
                  ? LAshift
                  : shift
              }
              handleLACardActive={handleLAShiftActive}
            />
          </div>
          <div className="">
            <table className="table table-card table-card--borderless w-100 mb-30 table-custom table--empties">
              <TableHeader
                updateTime={updateTime}
                emptyData={empytData}
                portsArray={portsArray}
                terminal={terminalTab}
                scroll={scroll}
                shift={
                  terminalTab === "LA" || newMarketWithTwoShift.includes(terminalCode)
                    ? LAshift
                    : shift
                }
                terminalCode={terminalCode}
                isTomorrow={isTomorrow}
                date={weekDateValue}
              />
              {terminalTab === "NY" && (
                <>
                  {!isToday && !isTomorrow ? (
                    <tbody>
                      <tr>
                        <td colSpan={100}>No data found</td>
                      </tr>
                    </tbody>
                  ) : (
                    <React.Fragment>
                      {sslArray?.map((ssl: SmallTypesI, index: number) => {
                        return (
                          ssl.checked && (
                            <NYEmptyTable
                              portsArray={portsArray}
                              containerTypes={containerTypes}
                              shift={shift}
                              emptyData={empytData}
                              data={ssl.label}
                              key={index}
                              getEmptyAppointmentSlots={getEmptyAppointmentSlots}
                            />
                          )
                        );
                      })}
                    </React.Fragment>
                  )}
                </>
              )}

              {terminalTab === "LA" && (
                <React.Fragment>
                  {sslArray?.map((ssl: SmallTypesI, index: number) => {
                    return (
                      ssl.checked && (
                        <LAEmptyTable
                          portsArray={portsArray}
                          containerTypes={containerTypes}
                          shift={LAshift}
                          emptyData={empytData}
                          data={ssl.label}
                          key={index}
                          date={weekDateValue}
                          getEmptyAppointmentSlots={getEmptyAppointmentSlots}
                        />
                      )
                    );
                  })}
                </React.Fragment>
              )}

              {terminalCode && (
                <React.Fragment>
                  {sslArray?.map((ssl: SmallTypesI, index: number) => {
                    return (
                      ssl.checked && (
                        <NewMarketEmptyTable
                          portsArray={portsArray}
                          containerTypes={containerTypes}
                          shift={newMarketWithTwoShift.includes(terminalCode) ? LAshift : shift}
                          emptyData={empytData}
                          data={ssl.label}
                          key={index}
                          date={weekDateValue}
                          getEmptyAppointmentSlots={getEmptyAppointmentSlots}
                        />
                      )
                    );
                  })}
                </React.Fragment>
              )}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmptyReturnLocations;
