import CredentialStatusLabel from "Common/Components/CredentialStatusLabel/CredentialStatusLabel";
import SubHeaderTab from "Common/Components/SubHeaderTab";
import Toastr from "Common/Components/Toastr";
import { IconPen } from "Common/Icons";
import { useAppContext } from "contexts/AppContext";
import EmptyReturnLocations from "pages/EmptyReturn/Locations";
import FieldArray from "pages/OnboardingModal/Components/FieldArray";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  assignPortCred,
  getCredPortList,
  loginMaherUser,
  validatePortCred,
} from "reducers/action/appAction";
import { isTerminalChoosen } from "utils";
import PortLoginForm from "./PortLoginForm";

const PortCredentials = () => {
  const { state, myDispatch } = useAppContext();
  const { ports, maherResponse, assignLoading } = state.configStates;
  const loggedUser = JSON.parse(localStorage.getItem("loggedUser") || "{}");
  const [portsArray, setPortsArray] = useState<any>([]);
  const [selectedPorts, setSelectedPorts] = useState<any[]>([]);
  const [showModal, setShowModal] = useState();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [showToastr, setShowToastr] = useState<boolean>(false);

  // get selected ports array and setting default Values
  // React Hook Form
  let [defaultValues, setDefaultValues] = useState<any>([]);
  let [filteredPorts, setFilteredPorts] = useState<any>([]);
  const {
    register,
    control,
    handleSubmit,
    getValues,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    defaultValues,
  });
  // Fetch Port Credentials
  const fetchCredPortList = async () => {
    let response = await getCredPortList(loggedUser?._id);
    myDispatch(response);
  };

  useEffect(() => {
    fetchCredPortList();
  }, []);

  useEffect(() => {
    if (ports.length) {
      const tempPorts: any[] = [];
      loggedUser.terminal.map((term: any) => {
        return tempPorts.push(...ports.filter((pp: any) => pp.terminalCode === term.code));
      });
      setFilteredPorts(tempPorts);
      let newPortValues: any[] = [];
      if (tempPorts?.length) {
        tempPorts.map((pp: any) => {
          return newPortValues.push({
            ...pp,
            isCredAdded: pp.portPassword && pp.portUsername,
            status: "Missed",
            portId: pp.portId ? pp.portId : pp._id,
          });
        });
      }
      let DefaultValues = { ports: newPortValues };
      reset(DefaultValues);
    }
  }, [ports]);

  // Handle Selected Ports
  const handleSelectedPorts = (selected: any, save: boolean) => {
    setShowModal(selected);
    setSelectedPorts(selected);
  };

  const onSubmit = async (data: any) => {
    setIsSaving(true);
    const credChanged = data.ports.filter(
      (each: any, i: number) =>
        (filteredPorts[i].portUsername !== each.portUsername ||
          filteredPorts[i].portPassword !== each.portPassword) &&
        !each.code.includes("APM")
    );
    const postData: any[] = credChanged.map((each: any) => {
      return {
        _id: each._id,
        portId: each.portId,
        port: each.code,
        portUsername: each.portUsername,
        portPassword: each.portPassword,
        isLogin: true,
      };
    });
    postData.length && (await myDispatch(validatePortCred(postData)));
    setIsSaving(false);
    setShowToastr(true);
  };
  return (
    <div className="page-wrapper">
      <div className="position-relative subheader-wrapper">
        <SubHeaderTab val="settingsItems" />
      </div>
      {!assignLoading && (
        <Toastr
          show={showToastr}
          status="success"
          message="Updated Port Credentials Successfully"
        />
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="page-fluid mt-20">
          <h3 className="mb-20">Port Credentials</h3>
          <FieldArray {...{ register, control, defaultValues, getValues, setValue, errors }} />
          <div className="mt-3 text-right">
            <button type="submit" className="btn btn-primary">
              {assignLoading && (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
              Update
            </button>
          </div>
        </div>
      </form>
      {showModal && (
        <PortLoginForm
          showModal={showModal}
          activeStep={4}
          formValues={selectedPorts}
          handleSelectedPorts={handleSelectedPorts}
          setShowModal={setShowModal}
        />
      )}
    </div>
  );
};

export default PortCredentials;
