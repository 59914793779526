import Loader from "Common/Components/Loader";
import { caseStatus } from "Common/Components/StatusBadge";
import { useAppContext } from "contexts/AppContext";
import moment from "moment";
import { handleSort } from "pages/EmptyReturn/Common/utils";
import ContainerDetails from "pages/Importavailability/component/ContainerDetails";
import ContainerUtils from "pages/Importavailability/component/utils";
import { Fragment, useState } from "react";
import { generateLFDColor, getDetailedValue } from "utils/commonFunctions";
import { setWrongDataReportParams } from "../action";
import { tableHeaders } from "../constant";

const WrongDataReportTable = () => {
  const { state, myDispatch } = useAppContext();
  const { loggedUser } = state.appStates;
  const { wrongContainersData, searchParams, wLoading } = state.wrongDataReportStates;
  const { containerSizes, containerOwners, containerTypes, ports } = state.configStates;
  const { page, limit } = searchParams;

  const [headers, setTableHeader] = useState<any | null>(tableHeaders);
  const [viewDetails, setViewDetails] = useState<any | null>();

  // get size and type
  const getSizeType = (containerSize: any, containerType: any) => {
    let sizeVal = getDetailedValue(containerSize, containerSizes);

    let typeVal = getDetailedValue(containerType, containerTypes);
    return `${sizeVal} ${sizeVal && typeVal && "-"} ${typeVal}`;
  };
  // get port
  const getPortTerminalName = (val: any, vtype: "port" | "terminal") => {
    if (typeof val === "string") {
      return getDetailedValue(val, vtype === "port" ? ports : loggedUser?.terminal);
    } else {
      return val?.name;
    }
  };
  // get Empty Return
  const getEmptyReturn = (vals: any) => {
    let emptyReturn: string[] = [];
    vals.length > 0 &&
      vals.map((val: any) => {
        if (typeof val === "object") {
          emptyReturn.push(val.port);
        } else {
          let portname = getPortTerminalName(val, "port");
          if (portname) emptyReturn.push(portname);
        }
      });
    return emptyReturn;
  };

  // Sorting
  const onSort = (obj: any) => {
    const searchParamsD = handleSort(obj, headers, searchParams);
    myDispatch(setWrongDataReportParams(searchParamsD));
  };

  return (
    <Fragment>
      <div className="table-responsive" style={{ height: "calc(100vh - 255px)" }}>
        {wLoading && <Loader />}
        <table className="table table-card">
          <thead>
            <tr>
              <th scope="col" className="">
                Sno
              </th>
              {headers.map((item: any, i: number) => {
                let sortIcon = "table-sort table-sort--default";
                if (item.sort === "-" && item.active) {
                  sortIcon = "table-sort table-sort--ascending";
                }
                if (item.sort === "+" && item.active) {
                  sortIcon = "table-sort table-sort--descending";
                }
                return (
                  <th key={i} onClick={() => onSort(item)} className={item.sort ? sortIcon : ""}>
                    {item.label}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {wrongContainersData && wrongContainersData.length ? (
              wrongContainersData.map((wrongContainerData: any, i: number) => {
                return (
                  <tr key={i}>
                    <td className="text-center">{(page - 1) * limit + i + 1}</td>
                    <td>{caseStatus(wrongContainerData.status)}</td>
                    <td
                      className="pointer text-primary"
                      onClick={() => {
                        setViewDetails(wrongContainerData);
                      }}
                    >
                      {wrongContainerData.containerNo}
                    </td>
                    <td>{wrongContainerData.userId.companyName}</td>
                    <td>{wrongContainerData.userId.email}</td>
                    <td>
                      {wrongContainerData.custom && <p>{"Custom: " + wrongContainerData.custom}</p>}
                      {wrongContainerData.freight && (
                        <p>{"Freight: " + wrongContainerData.freight}</p>
                      )}
                      {wrongContainerData.brokerHold && (
                        <p>{"Broker: " + wrongContainerData.brokerHold}</p>
                      )}
                    </td>
                    <td>{getDetailedValue(wrongContainerData.containerOwner, containerOwners)}</td>
                    <td>
                      {getSizeType(
                        wrongContainerData.containerSize,
                        wrongContainerData.containerType
                      )}
                    </td>
                    <td>{getPortTerminalName(wrongContainerData.portId, "port")}</td>
                    <td>
                      {wrongContainerData.eta
                        ? moment(wrongContainerData.eta).format("MM/DD")
                        : wrongContainerData.sslEta
                        ? moment(wrongContainerData.sslEta).format("MM/DD")
                        : ""}
                    </td>
                    <td>
                      {wrongContainerData.dischargeDate
                        ? moment(wrongContainerData.dischargeDate).format("MM/DD")
                        : wrongContainerData.sslDischargeDate
                        ? moment(wrongContainerData.sslDischargeDate).format("MM/DD")
                        : ""}
                    </td>
                    <td
                      className={generateLFDColor(
                        wrongContainerData.lastFreeDay,
                        wrongContainerData.status
                      )}
                    >
                      {wrongContainerData.lastFreeDay &&
                        moment(wrongContainerData.lastFreeDay).format("MM/DD")}
                    </td>

                    <td>
                      {wrongContainerData.outGateDate &&
                        moment(wrongContainerData.outGateDate).format("MM/DD")}
                    </td>
                    <td className="">
                      {getEmptyReturn(wrongContainerData.emptyReturnLocation)
                        .toString()
                        .replaceAll("_", " ")}
                    </td>
                    <td>
                      {wrongContainerData.terminatedDate
                        ? moment(wrongContainerData.terminatedDate).format("MM/DD")
                        : wrongContainerData.inGate?.date
                        ? moment(wrongContainerData.inGate?.date).format("MM/DD")
                        : ""}
                    </td>
                    <td>{wrongContainerData.vesselName}</td>
                    <td>{wrongContainerData.voyageName}</td>
                    <td>{wrongContainerData.billOfLading}</td>
                    <td>{wrongContainerData.grossWeight}</td>
                    <td>{wrongContainerData.seal}</td>
                    <td>{wrongContainerData.yardLocation}</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={100}>Data Not Found</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {/* Container Details Modal */}
      {viewDetails && viewDetails.containerNo && (
        <ContainerDetails values={viewDetails} onCancel={() => setViewDetails(null)} />
      )}
    </Fragment>
  );
};

export default WrongDataReportTable;
