import { api } from "config";

export const getActivated = (activationToken: any) => {
  return {
    type: "USER_ACTIVATED",
    payload: api.post("api/auth/activation", { activationToken }),
  };
};

export const getInvitation = (email: string[]) => {
  return {
    type: "USER_INVITATION",
    payload: api.post("/api/auth/invite", { email: email }),
  };
};

export const getPaymentPlans = () => {
  return {
    type: "GET_PAYMENT_PLANS",
    payload: api.get("api/auth/paymentPlans"),
  };
};

export const postCheckout = async (priceId: string) => {
  return {
    type: "POST_CHECKOUT",
    payload: api.post(`api/auth/checkout/${priceId}`),
  };
};

export const postSubscription = (data: any, priceId: string, customerId: string) => {
  return {
    type: "ADD_SUBSCRIPTION",
    payload: api.put(`api/auth/subscribe/${priceId}/${customerId}`, data),
  };
};
