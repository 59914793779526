export const appointmentSlotReportTableHeaders = [
  { label: "Id", value: "id", sort: "-" },
  { label: "AppointmentId", value: "appointmentId", sort: "-" },
  { label: "ContainerNo", value: "containerNo", sort: "-" },
  { label: "Slots", value: "slots", sort: "-" },
  { label: "Timestamp", value: "timestamp", sort: "-" },
  { label: "LineScac", value: "lineScac", sort: "-" },
  { label: "ContainerSizeType", value: "containerSizeType", sort: "-" },
  { label: "LoadType", value: "loadType", sort: "-" },
  { label: "MoveType", value: "moveType", sort: "-" },
  { label: "PortCode", value: "portCode", sort: "-" },
  { label: "CreatedAt", value: "createdAt", sort: "-" },
  { label: "UpdatedAt", value: "updatedAt", sort: "-" },
];

export const LOAD_STATUS = [
  "PENDING",
  "AVAILABLE",
  "DEPARTED",
  "DISPATCHED",
  "CHASSISPICK_ARRIVED",
  "CHASSISPICK_DEPARTED",
  "PULLCONTAINER_ARRIVED",
  "PULLCONTAINER_DEPARTED",
  "DELIVERLOAD_ARRIVED",
  "DELIVERLOAD_DEPARTED",
  "DROPCONTAINER_ARRIVED",
  "DROPCONTAINER_DEPARTED",
  "HOOKCONTAINER_ARRIVED",
  "HOOKCONTAINER_DEPARTED",
  "RETURNCONTAINER_ARRIVED",
  "RETURNCONTAINER_DEPARTED",
  "CHASSISTERMINATION_ARRIVED",
  "CHASSISTERMINATION_DEPARTED",
  "COMPLETED",
  "APPROVED",
  "BILLING",
  "UNAPPROVED",
  "REBILLING",
  "PARTIAL_PAID",
  "FULL_PAID",
];
export const APPT_STATUS = [
  "CONFIRMED",
  "DECLINED",
  "PENDING",
  "CANCELLED",
  "TENTATIVE",
  "REJECTED",
  "DELETED",
  "COMPLETED",
];

export const LOAD_TYPE = Object.freeze(["IMPORT", "EXPORT"]);
export const MOVE_TYPE = Object.freeze(["EMPTY", "PICKUP"]);
export const FULL_EMPTY = Object.freeze(["EMPTY", "FULL"]);
