import React, { Fragment, useEffect, useState } from "react";
import Loader from "Common/Components/Loader";
import { useAppContext } from "contexts/AppContext";
import { IconPen, IconSaveOutline } from "Common/Icons";
import { tableHeaders } from "./constant";
import { caseStatus } from "Common/Components/StatusBadge";
import { getDetailedValue, showDate } from "utils/commonFunctions";
import moment from "moment";
import CustomSelectField, {
  SelectFieldWithoutControl,
} from "Common/Components/Inputs/CustomSelectField";
import { getDuplicateArrayofObjects, handleSort } from "pages/EmptyReturn/Common/utils";
import { CustomIconSaveOutline } from "Common/Components/Icons/CustomIcons";
import {
  getfilterContainer,
  setDashboardAvailableParams,
  updateContainerById,
  updateVoyageById,
} from "../action";
import { getVesselLists } from "VesselSchedule/action";
import _ from "lodash";
import { Container } from "react-dom";

const DashboardContainerTable = () => {
  //API Initialize
  const { state, myDispatch } = useAppContext();
  const { cLoading, containers, filterSearchPost, searchParams, editVoyageData } =
    state.dashboardStates;
  const { loggedUser, userSelectedTerminals } = state.appStates;
  const { containerOwners, containerSizes, containerTypes, ports, noCredentialPorts } =
    state.configStates;
  const { allVessel } = state.vesselStates;

  const userSelectedPorts = getDuplicateArrayofObjects(
    noCredentialPorts,
    userSelectedTerminals,
    "terminalCode",
    "code"
  );

  const PORT_CHOICES = userSelectedPorts.map((it: any) => ({
    label: it.name,
    value: it._id,
  }));

  const [editMode, setEditMode] = useState<any>(null);
  const [emptyId, setEmptyId] = useState<any>(null);
  const [headers, setTableHeader] = useState<any | null>(tableHeaders);
  //
  const [voyageChoice, setVoyageChoice] = useState<any>([]); // array of object data is store in voyageChoice

  useEffect(() => {
    if (userSelectedTerminals.length) {
      myDispatch(getVesselLists({ terminalId: userSelectedTerminals[0]?.code }));
    }
  }, [userSelectedTerminals]);

  useEffect(() => {
    // come double array from allvessel reducer
    const temp = allVessel?.map((vs: any) => vs.data);
    // concat two array
    const status = [].concat.apply([], temp);
    // get option from that array in object in label and value
    const STATUS_CHOICE = status
      ?.filter((ft: any) => ft?.hasOwnProperty("voyageName"))
      ?.map((st: any) => ({ label: st.voyageName, value: st.voyageName }));
    // as there is repeated object so take out only unique object list
    const final = _.uniqBy(STATUS_CHOICE, "label");
    // update that unique object label and value into state
    setVoyageChoice(final);
  }, [allVessel]);

  //HANDLE FUNCTION
  //GET SIZE TYPE
  const getSizeType = (containerSize: any, containerType: any) => {
    let sizeVal = getDetailedValue(containerSize, containerSizes);

    let typeVal = getDetailedValue(containerType, containerTypes);
    return `${sizeVal} ${sizeVal && typeVal && "-"} ${typeVal}`;
  };

  //GET TERMINAL
  const getPortTerminalName = (val: any, vtype: "port" | "terminal") => {
    if (typeof val === "string") {
      return getDetailedValue(val, vtype === "port" ? ports : loggedUser?.terminal);
    } else {
      return val?.name;
    }
  };

  //EMPTY RETURN
  const getEmptyReturn = (vals: any) => {
    let emptyReturn: string[] = [];
    vals.length > 0 &&
      vals.map((val: any) => {
        if (typeof val === "object") {
          emptyReturn.push(val?.port);
        } else {
          let portname = getPortTerminalName(val, "port");
          if (portname) emptyReturn.push(portname);
        }
      });
    return emptyReturn;
  };

  const handleSaveEmptyReturn = async (id: string, emptyReturn: string[]) => {
    const data = {
      _id: id,
      emptyReturnLocation: emptyId ? [emptyId?.value || ""] : emptyReturn,
    };
    setEditMode(null);
    const response = await updateContainerById(data);
    myDispatch(response);
  };

  // Sorting
  const onsort = (obj: any) => {
    const searchParamsD = handleSort(obj, headers, searchParams);
    myDispatch(setDashboardAvailableParams(filterSearchPost, searchParamsD));
    myDispatch(getfilterContainer(filterSearchPost, searchParamsD));
  };

  // handle Voyage
  const handleVoyageChange = (option: any, index: number, container: any) => {
    const temp = voyageChoice;
    temp[index] = option;
    setVoyageChoice([...temp]);
    const payload = {
      portId: container.portId,
      originalVoyageValue: container.voyageName,
      newVoyageValue: option.value,
    };
    myDispatch(updateVoyageById(payload));
  };

  return (
    <React.Fragment>
      <div className="table-responsive" id="VesselTable" style={{ height: "calc(100vh - 351px)" }}>
        {cLoading && <Loader />}
        <table className="table table-card" id="myTable">
          <thead>
            <tr>
              <th scope="col" className="">
                Sno
              </th>
              {headers.map((item: any, i: number) => {
                let sortIcon = "table-sort table-sort--default";
                if (item.sort === "-" && item.active) {
                  sortIcon = "table-sort table-sort--ascending";
                }
                if (item.sort === "+" && item.active) {
                  sortIcon = "table-sort table-sort--descending";
                }
                return (
                  <th key={i} onClick={() => onsort(item)} className={item.sort ? sortIcon : ""}>
                    {item.label}
                  </th>
                );
              })}
              {/* {tableHeaders.map((item: any, i: number) => {
                return <th key={i}>{item.label}</th>;
              })} */}
            </tr>
          </thead>
          <tbody>
            {containers.length ? (
              containers.map((container: any, i: number) => {
                const empty = getEmptyReturn(container.emptyReturnLocation)
                  .toString()
                  .replaceAll("_", " ");
                return (
                  <tr key={i}>
                    <td className="text-center">{i + 1}</td>
                    <td> {container.containerNo}</td>
                    <td>{caseStatus(container.status)}</td>
                    <td>
                      {container.custom && <p>{"Custom: " + container.custom}</p>}
                      {container.freight && <p>{"Freight: " + container.freight}</p>}
                      {container.brokerHold && <p>{"Broker: " + container.brokerHold}</p>}
                    </td>
                    <td>{getDetailedValue(container.containerOwner, containerOwners)}</td>
                    <td>{getSizeType(container.containerSize, container.containerType)}</td>
                    <td>{getPortTerminalName(container.portId, "port")}</td>
                    <td>
                      {showDate(container.status, "eta") && container.eta
                        ? moment(container.eta).format("MM/DD")
                        : container.eta
                        ? moment(container.sslEta).format("MM/DD")
                        : ""}
                    </td>
                    <td>
                      {showDate(container.status, "dischargeDate") &&
                        container.dischargeDate &&
                        moment(container.dischargeDate).format("MM/DD")}
                    </td>
                    <td>
                      {showDate(container.status, "lfd") &&
                        container.lastFreeDay &&
                        moment(container.lastFreeDay).format("MM/DD")}
                    </td>
                    <td>
                      {showDate(container.status, "outgatedDate") &&
                        container.outGateDate &&
                        moment(container.outGateDate).format("MM/DD")}
                    </td>
                    <td>
                      {showDate(container.status, "terminatedDate") && container.terminatedDate
                        ? moment(container.terminatedDate).format("MM/DD")
                        : container.inGate?.date
                        ? moment(container.inGate?.date).format("MM/DD")
                        : ""}
                    </td>
                    <td>{container.vesselName}</td>
                    <td>
                      {container.voyageName}
                      <div className="mt-1">
                        <SelectFieldWithoutControl
                          isLoading={false}
                          isClearable={true}
                          isSearchable={true}
                          name="voyageId"
                          value={voyageChoice.find(
                            (pt: any) => pt?.value === container?.voyageName
                          )}
                          options={voyageChoice}
                          onChange={(option) => handleVoyageChange(option, i, container)}
                          size="small"
                          className="w-150"
                        />
                      </div>
                    </td>
                    <td>{container.billOfLading}</td>
                    <td>{container.grossWeight}</td>
                    <td>{container.seal}</td>
                    <td>{container.yardLocation}</td>
                    <td className="">
                      {editMode === i ? (
                        <div className="d-flex justify-content-center align-items-center">
                          <SelectFieldWithoutControl
                            isLoading={false}
                            isClearable={true}
                            isSearchable={true}
                            name="portId"
                            value={
                              emptyId
                                ? emptyId
                                : PORT_CHOICES.find(
                                    (pt: any) => pt.value === container?.emptyReturnLocation[0]
                                  )
                            }
                            options={PORT_CHOICES}
                            onChange={(value) => (value ? setEmptyId(value) : setEmptyId({}))}
                            size="small"
                            className="w-150"
                          />
                          <button
                            className="btn btn-link"
                            onClick={() =>
                              handleSaveEmptyReturn(container._id, container?.emptyReturnLocation)
                            }
                          >
                            <CustomIconSaveOutline />
                          </button>
                        </div>
                      ) : (
                        <React.Fragment>
                          {empty}
                          <button className="btn btn-link" onClick={() => setEditMode(i)}>
                            <IconPen />
                          </button>
                        </React.Fragment>
                      )}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={100}>No data found</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};
export default DashboardContainerTable;
