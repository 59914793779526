export const tableHeaders = [
  {
    label: "Full Name",
    value: "fullName",
    sort: "-",
  },
  {
    label: "Email",
    value: "email",
    sort: "-",
  },
  {
    label: "Role",
    value: "role",
    sort: "-",
  },
  {
    label: "Subscription Type",
    value: "subscriptionType",
    sort: "-",
  },
  {
    label: "Sub Subscription Type",
    value: "subSubscriptionType",
    sort: "-",
  },
  {
    label: "Company Type",
    value: "companyType",
    sort: "-",
  },
  {
    label: "Company Name",
    value: "companyName",
    sort: "-",
  },
  {
    label: "Company Address",
    value: "companyAddress",
    sort: "-",
  },
];
