import _ from "lodash";

export const initialVesselValues = {
  vLoading: false,
  vErrorMessage: null,
  vessels: [],
  vesselCount: 0,
  vesselPort: "",

  //Search Params
  searchPost: {
    port: [],
    date: [],
  },
  searchParams: {
    limit: 1,
    page: 1,
  },
};

export const vesselReducer = (
  state: any,
  action: {
    type: string;
    payload: any;
  }
) => {
  switch (action.type) {
    case "VESSELLISTS_INPROGRESS": {
      return {
        ...state,
        vLoading: true,
      };
    }

    case "VESSELLISTS_REJECTED": {
      return {
        ...state,
        vLoading: false,
        vessels: [],
      };
    }
    case "VESSELLISTS_SUCCESS": {
      let vessels = action.payload.data.data;
      return {
        ...state,
        vessels: vessels,
        vesselPort: action.payload.data.port,
        allVessel: action.payload?.data?.length ? action.payload?.data : [],
        vLoading: false,
      };
    }
    case "SETVESSELPARAM": {
      let post = action.payload.searchPost ? action.payload.searchPost : state.searchPost;
      let param = action.payload.searchParams ? action.payload.searchParams : state.searchParams;
      return {
        ...state,
        searchPost: { ...post },
        searchParams: param,
      };
    }
    default:
      return state;
  }
};
