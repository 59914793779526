import { IconReset } from "Common/Components/Icons/Icons";
import Loader from "Common/Components/Loader";
import { caseStatus } from "Common/Components/StatusBadge";
import { useAppContext } from "contexts/AppContext";
import moment from "moment";
import ContainerDetails from "pages/Importavailability/component/ContainerDetails";
import ContainerIngate from "pages/Importavailability/component/ContainerIngate";
import { Fragment, useState } from "react";
import { getDetailedValue } from "utils/commonFunctions";
import { getVesselContainerReport, setReportContainersParams, hitSingleContainer } from "../action";
import { tableHeaders } from "../constant";

const ReportContainerTable = () => {
  const { state, myDispatch } = useAppContext();
  const { reportContainers, rLoading, searchParams, searchPost, gettingData } =
    state.vesselReportContainerStates;
  const { loggedUser } = state.appStates;
  const { limit, page } = searchParams;
  const { containerSizes, containerOwners, containerTypes, ports } = state.configStates;

  const [headers, setTableHeader] = useState<any | null>(tableHeaders);
  const [viewDetails, setViewDetails] = useState<any | null>();
  const [viewIngate, setViewIngate] = useState<any | null>();
  // console.log("object", setViewDetails);

  //HANDLE FUNCTION

  // get size and type
  const getSizeType = (containerSize: any, containerType: any) => {
    let sizeVal = getDetailedValue(containerSize, containerSizes);

    let typeVal = getDetailedValue(containerType, containerTypes);
    return `${sizeVal} ${sizeVal && typeVal && "-"} ${typeVal}`;
  };
  // get port
  const getPortTerminalName = (val: any, vtype: "port" | "terminal") => {
    if (typeof val === "string") {
      return getDetailedValue(val, vtype === "port" ? ports : loggedUser?.terminal);
    } else {
      return val?.name;
    }
  };

  // get Empty Return
  const getEmptyReturn = (vals: any) => {
    let emptyReturn: string[] = [];
    vals.length > 0 &&
      vals.map((val: any) => {
        if (typeof val === "object") {
          emptyReturn.push(val.port);
        } else {
          let portname = getPortTerminalName(val, "port");
          if (portname) emptyReturn.push(portname);
        }
      });
    return emptyReturn;
  };

  // Sorting
  const onSort = (obj: any) => {
    let sortString = ``;
    headers.map((D: any) => {
      if (D.value === obj.value) {
        D.sort = D.sort === "-" || D.sort === "" ? "+" : "-";
        sortString = `${D.sort === "+" ? "" : D.sort}${D.value}`;
        D.active = true;
      } else {
        D.active = false;
      }
      return D;
    });
    if (sortString !== "") {
      const searchParamsD = {
        ...searchParams,
        sort: sortString,
      };
      myDispatch(setReportContainersParams(searchPost, searchParamsD));
    }
  };

  const getLatestDatafromlamda = (id: string) => {
    myDispatch(hitSingleContainer(id));
  };

  return (
    <Fragment>
      <div className="table-responsive" style={{ height: "calc(100vh - 249px)" }}>
        {rLoading && <Loader />}
        <table className="table table-card">
          <thead>
            <tr>
              <th scope="col" className="">
                Sno
              </th>
              {headers.map((item: any, i: number) => {
                let sortIcon = "table-sort table-sort--default";
                if (item.sort === "-" && item.active) {
                  sortIcon = "table-sort table-sort--ascending";
                }
                if (item.sort === "+" && item.active) {
                  sortIcon = "table-sort table-sort--descending";
                }
                return (
                  <th key={i} onClick={() => onSort(item)} className={item.sort ? sortIcon : ""}>
                    {item.label}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {reportContainers.length ? (
              reportContainers.map((reportContainer: any, i: number) => {
                return (
                  <tr key={i}>
                    <td className="text-center">{(page - 1) * limit + i + 1}</td>
                    <td>
                      <div
                        className="pointer text-primary"
                        onClick={() => {
                          setViewDetails(reportContainer);
                          // if (reportContainer.containerType || reportContainer.containerSize) {

                          // }
                        }}
                      >
                        {reportContainer.containerNo}
                      </div>
                    </td>
                    <td>{reportContainer.userId.companyName}</td>
                    <td>{reportContainer.userId.email}</td>
                    <td>{caseStatus(reportContainer.status)}</td>
                    <td>
                      {reportContainer.custom && <p>{"Custom: " + reportContainer.custom}</p>}
                      {reportContainer.freight && <p>{"Freight: " + reportContainer.freight}</p>}
                      {reportContainer.brokerHold && (
                        <p>{"Broker: " + reportContainer.brokerHold}</p>
                      )}
                    </td>
                    <td>{getDetailedValue(reportContainer.containerOwner, containerOwners)}</td>
                    <td>
                      {getSizeType(reportContainer.containerSize, reportContainer.containerType)}
                    </td>
                    <td>{getPortTerminalName(reportContainer.portId, "port")}</td>
                    <td>
                      {reportContainer.eta
                        ? moment(reportContainer.eta).format("MM/DD")
                        : reportContainer.eta
                        ? moment(reportContainer.sslEta).format("MM/DD")
                        : ""}
                    </td>
                    <td>
                      {reportContainer.status !== "On Vessel" &&
                        reportContainer.dischargeDate &&
                        moment(reportContainer.dischargeDate).format("MM/DD")}
                    </td>
                    <td>
                      {reportContainer.status !== "On Vessel" &&
                        reportContainer.lastFreeDay &&
                        moment(reportContainer.lastFreeDay).format("MM/DD")}
                    </td>

                    <td>
                      {reportContainer.status !== "On Vessel" &&
                        reportContainer.outGateDate &&
                        moment(reportContainer.outGateDate).format("MM/DD")}
                    </td>
                    <td className="">
                      {getEmptyReturn(reportContainer.emptyReturnLocation)
                        .toString()
                        .replaceAll("_", " ")}
                    </td>
                    <td>
                      {reportContainer.status !== "On Vessel" && reportContainer.terminatedDate
                        ? moment(reportContainer.terminatedDate).format("MM/DD")
                        : reportContainer.inGate?.date
                        ? moment(reportContainer.inGate?.date).format("MM/DD")
                        : ""}
                    </td>
                    <td>{reportContainer.vesselName}</td>
                    <td>{reportContainer.voyageName}</td>
                    <td>{reportContainer.billOfLading}</td>
                    <td>{reportContainer.yardLocation}</td>
                    <td>
                      <button
                        disabled={gettingData}
                        className="btn btn-success"
                        onClick={() => getLatestDatafromlamda(reportContainer._id)}
                      >
                        {gettingData && (
                          <span
                            className="spinner-border spinner-border-sm mr-2"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        )}

                        <IconReset />
                      </button>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={100}>Data Not Found</td>
              </tr>
            )}
          </tbody>
        </table>
        {viewIngate && viewIngate.containerNo && (
          <ContainerIngate values={viewIngate} onCloseModal={() => setViewIngate(null)} />
        )}
        {/* Container Details Modal */}
        {viewDetails && viewDetails.containerNo && (
          <ContainerDetails values={viewDetails} onCancel={() => setViewDetails(null)} />
        )}
      </div>
    </Fragment>
  );
};

export default ReportContainerTable;
