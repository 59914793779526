import SubHeaderTab from "Common/Components/SubHeaderTab";
import Toastr from "Common/Components/Toastr";
import { useAppContext } from "contexts/AppContext";
import { useEffect, useState } from "react";
import TerminalSelectForm from "./TerminalSelectForm";

const TerminalSelect = () => {
  const { state } = useAppContext();
  const { resetSuccess, assignErMessage, loading } = state.appStates;
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (loading) {
      setShow(true);
    }
  }, [loading]);

  return (
    <div className="page-wrapper">
      <div className="position-relative subheader-wrapper">
        <SubHeaderTab val="settingsItems" />
        <div className="page-fluid">
          {show && resetSuccess && !assignErMessage && (
            <Toastr show={true} status="success" message={"Updated Terminal Successfully!"} />
          )}
          {!resetSuccess && assignErMessage && (
            <Toastr show={true} status="error" message={"Select Terminal First!!"} />
          )}
          <div className="top-page">
            <div className="pb-15 d-flex align-items-center">
              <h4 className="mb-0">Select Terminals</h4>
            </div>
            <TerminalSelectForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TerminalSelect;
