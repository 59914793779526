import CustomDateField from "Common/Components/Inputs/CustomDateField";
import CustomInputField from "Common/Components/Inputs/CustomInputField";
import CustomSelectField from "Common/Components/Inputs/CustomSelectField";
import Loader from "Common/Components/Loader";
import { useAppContext } from "contexts/AppContext";
import moment from "moment";
import { updateContainerById } from "pages/Importavailability/action";
import { statusOpts, holds, broker } from "pages/Importavailability/constant";
import { SelectedContainerTypes } from "pages/Importavailability/containers";
import { useForm } from "react-hook-form";

const ContainerEditForm = () => {
  const { state, myDispatch } = useAppContext();
  const { ports, containerOwners, containerSizes, containerTypes } = state.configStates;
  const { selectedContainer, cLoading } = state.containerStates;

  // OPTIONS LIST FOR CUSTOM SELECT FIELD
  const PORTS_CHOICES = ports.map((it: any) => ({ label: it.name, value: it.portId || it._id }));
  const CONTAINER_SIZE_CHOICES = containerSizes.map((ct: any) => ({
    label: ct.name,
    value: ct._id,
  }));
  const SIZE_CHOICES = statusOpts.map((mm) => ({ label: mm, value: mm }));
  const TYPE_CHOICES = containerTypes.map((ct: any) => ({ label: ct.name, value: ct._id }));
  const CONTAINER_OWNER_CHOICES = containerOwners.map((cw: any) => ({
    label: cw.name,
    value: cw._id,
  }));

  // REACT HOOK FORM
  const {
    setValue,
    formState: { errors },
    register,
    control,
    reset,
    handleSubmit,
  } = useForm<SelectedContainerTypes>({
    shouldUnregister: false,
    defaultValues: {
      ...selectedContainer,
      portId: selectedContainer?.portId?._id,
    },
    mode: "onBlur",
  });

  const onFormSubmit = async (data: SelectedContainerTypes) => {
    const finalData = {
      ...data,
      portId:
        typeof data.portId === "string"
          ? ports.find((pp: any) => pp.portId === data.portId)
          : ports.find((pp: any) => pp._id === data.portId.value),
      pickUpFrom: data.pickUpFrom ? moment(data.pickUpFrom).utc().format() : null,
      pickUpTo: data.pickUpTo ? moment(data.pickUpTo).utc().format() : null,
      emptyDate: data.emptyDate ? moment(data.emptyDate).utc().format() : null,
      eta: data.eta ? moment(data.eta).utc().format() : null,
      lastFreeDay: data.lastFreeDay ? moment(data.lastFreeDay).utc().format() : null,
      returnDate: data.returnDate ? moment(data.returnDate).utc().format() : null,
      brokerHold: data.brokerHold ? data.brokerHold.value : "",
      custom: data.custom && typeof data.custom !== "string" ? data.custom.value : data.custom,
      freight: data.freight && typeof data.freight !== "string" ? data.freight.value : data.freight,
      carrierHold:
        data.carrierHold && typeof data.carrierHold !== "string"
          ? data.carrierHold.value
          : data.carrierHold,
      containerOwner:
        data.containerOwner && typeof data.containerOwner !== "string"
          ? data.containerOwner.value
          : data.containerOwner,
      status: data.status && typeof data.status !== "string" ? data.status.value : data.status,
      containerSize:
        data.containerSize && typeof data.containerSize !== "string"
          ? data.containerSize.value
          : data.containerSize,
      containerType:
        data.containerType && typeof data.containerType !== "string"
          ? data.containerType.value
          : data.containerType,
    };
    myDispatch(updateContainerById(finalData));
  };

  return (
    <div className="tab mt-10" id="tab-load-info">
      {cLoading && <Loader />}
      <form id="hook-form" onSubmit={handleSubmit(onFormSubmit)}>
        <div className="form-row-md">
          <div className="col-md-6">
            <div className="card pt-30 px-20 mb-10">
              <div>
                <h4 className="font-16 mb-20">Container Info</h4>
                <div className="form-row-md mb-10">
                  <div className="form-group mb-0 col-md-6">
                    <CustomSelectField
                      label="Port Name"
                      isLoading={false}
                      isClearable={true}
                      isSearchable={true}
                      name="portId"
                      options={PORTS_CHOICES}
                      size="small"
                      placeholder="Select Port"
                      control={control}
                    />
                  </div>
                  <div className="form-group col-md-3">
                    <label>Pick Up From</label>
                    <div className="input-wrapper">
                      <CustomDateField name="pickUpFrom" control={control} placeholder="MM/DD/YY" />
                    </div>
                  </div>
                  <div className="form-group col-md-3">
                    <label>Pick Up To</label>
                    <div className="input-wrapper">
                      <CustomDateField name="pickUpTo" control={control} placeholder="MM/DD/YY" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card pt-30 px-20 mb-10">
              <h4 className="font-16 mb-20">Customer</h4>
              <div className="form-row-md mb-10">
                <div className="form-group col-md-3">
                  <label>Vessel ETA</label>
                  <div className="input-wrapper">
                    <CustomDateField name="eta" control={control} placeholder="MM/DD/YY" />
                  </div>
                </div>
                <div className="form-group col-md-3">
                  <label>Last Free Day</label>
                  <div className="input-wrapper">
                    <CustomDateField name="lastFreeDay" control={control} placeholder="MM/DD/YY" />
                  </div>
                </div>
                <div className="form-group col-md-3">
                  <label>Empty Date</label>
                  <div className="input-wrapper">
                    <CustomDateField name="emptyDate" control={control} placeholder="MM/DD/YY" />
                  </div>
                </div>
                <div className="form-group col-md-3">
                  <label>Return Day</label>
                  <div className="input-wrapper">
                    <CustomDateField name="returnDate" control={control} placeholder="MM/DD/YY" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card pt-30 px-20 mb-10">
              <h4 className="font-16 mb-20">Equipment</h4>
              <div className="form-row-md mb-10">
                <div className="form-group col-md-3">
                  <label>Container #</label>
                  <CustomInputField
                    inputFieldOnly
                    placeholder="Container No"
                    name="containerNo"
                    type="text"
                    required
                    register={register}
                    className="form-control"
                  />
                </div>
                <div className="form-group mb-0 col-md-3">
                  <CustomSelectField
                    label="Status"
                    control={control}
                    isLoading={false}
                    isClearable={true}
                    isSearchable={true}
                    name="status"
                    options={SIZE_CHOICES}
                    size="small"
                    placeholder="Select"
                  />
                </div>
                <div className="form-group mb-0 col-md-3">
                  <CustomSelectField
                    label="Size"
                    isLoading={false}
                    isClearable={true}
                    isSearchable={true}
                    name="containerSize"
                    control={control}
                    options={CONTAINER_SIZE_CHOICES}
                    size="small"
                    placeholder="Select"
                  />
                </div>
                <div className="form-group mb-0 col-md-3">
                  <CustomSelectField
                    label="Type"
                    isLoading={false}
                    isClearable={true}
                    isSearchable={true}
                    control={control}
                    name="containerType"
                    options={TYPE_CHOICES}
                    size="small"
                    placeholder="Select"
                  />
                </div>
                <div className="form-group mb-0 col-md-3">
                  <CustomSelectField
                    label="Owner"
                    isLoading={false}
                    isClearable={true}
                    isSearchable={true}
                    name="containerOwner"
                    options={CONTAINER_OWNER_CHOICES}
                    size="small"
                    placeholder="Select"
                    control={control}
                  />
                </div>
                <div className="form-group col-md-3">
                  <label>Gross Weight</label>
                  <CustomInputField
                    inputFieldOnly
                    placeholder="Enter.."
                    name="grossWeight"
                    type="text"
                    register={register}
                    className="form-control"
                  />
                </div>
                <div className="form-group col-md-3">
                  <label>Seal #</label>
                  <CustomInputField
                    inputFieldOnly
                    placeholder="Enter.."
                    name="seal"
                    type="text"
                    register={register}
                    className="form-control"
                  />
                </div>
                <div className="form-group col-md-3">
                  <label>SCAC</label>
                  <CustomInputField
                    inputFieldOnly
                    placeholder="Enter.."
                    name="scac"
                    type="text"
                    register={register}
                    className="form-control"
                  />
                </div>
                <div className="form-group col-md-3">
                  <label>Yard Location</label>
                  <CustomInputField
                    inputFieldOnly
                    placeholder="Enter.."
                    name="yardLocation"
                    type="text"
                    register={register}
                    className="form-control"
                  />
                </div>
                <div className="form-group col-md-3">
                  <label>Demurrage</label>
                  <CustomInputField
                    inputFieldOnly
                    placeholder="Enter.."
                    name="demurrage"
                    type="text"
                    register={register}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <div className="card pt-30 px-20 mb-10">
              <h4 className="font-16 mb-20">Reference</h4>
              <div className="form-row-md mb-10">
                <div className="form-group col-md-6">
                  <label>Master Bill of Lading</label>
                  <CustomInputField
                    inputFieldOnly
                    placeholder="Enter.."
                    name="billOfLading"
                    type="text"
                    register={register}
                    className="form-control"
                  />
                </div>
                {/* <div className="form-group col-md-6">
                  <label>House Bill of Lading</label>
                  <input className="form-control" placeholder="e.g. AAA0000000000" />
                </div> */}
                {/* <div className="form-group col-md-6">
                  <label>Seal #</label>
                  <CustomInputField
                    inputFieldOnly
                    placeholder="Enter.."
                    name="seal"
                    type="text"
                    register={register}
                    className="form-control"
                  />
                </div> */}
                <div className="form-group col-md-6">
                  <label>Reference #</label>
                  <CustomInputField
                    inputFieldOnly
                    placeholder="Enter.."
                    name="reference"
                    type="text"
                    register={register}
                    className="form-control"
                  />
                </div>
                <div className="form-group col-md-4">
                  <label>Vessel Name</label>
                  <CustomInputField
                    inputFieldOnly
                    placeholder="Enter.."
                    name="vesselName"
                    type="text"
                    register={register}
                    className="form-control"
                  />
                </div>
                <div className="form-group col-md-4">
                  <label>Vessel Code</label>
                  <CustomInputField
                    inputFieldOnly
                    placeholder="Enter.."
                    name="vesselCode"
                    type="text"
                    register={register}
                    className="form-control"
                  />
                </div>
                <div className="form-group col-md-4">
                  <label>Voyage</label>
                  <CustomInputField
                    inputFieldOnly
                    placeholder="Enter.."
                    name="voyageName"
                    type="text"
                    register={register}
                    className="form-control"
                  />
                </div>
                <div className="form-group mb-0 col-md-3">
                  <CustomSelectField
                    label="Custom Hold"
                    isLoading={false}
                    isClearable={true}
                    isSearchable={true}
                    name="custom"
                    control={control}
                    options={holds}
                    size="small"
                    placeholder="Select"
                  />
                </div>
                <div className="form-group mb-0 col-md-3">
                  <CustomSelectField
                    label="Freight Hold"
                    isLoading={false}
                    isClearable={true}
                    isSearchable={true}
                    name="freight"
                    control={control}
                    options={holds}
                    size="small"
                    placeholder="Select"
                  />
                </div>
                <div className="form-group mb-0 col-md-3">
                  <CustomSelectField
                    label="Carrier Hold"
                    isLoading={false}
                    isClearable={true}
                    isSearchable={true}
                    name="carrierHold"
                    options={broker}
                    size="small"
                    placeholder="Select"
                    control={control}
                  />
                </div>
                <div className="form-group mb-0 col-md-3">
                  <CustomSelectField
                    label="Broker Hold"
                    isLoading={false}
                    isClearable={true}
                    isSearchable={true}
                    name="brokerHold"
                    options={broker}
                    size="small"
                    placeholder="Select"
                    control={control}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ContainerEditForm;
