import { useAppContext } from "contexts/AppContext";
import React, { useState } from "react";
import { Button, Form, Col, Row } from "react-bootstrap";

// interface IProps {
//   getAllTokens: any;
//   addNewToken: any;
// }

const ReadOnlyInputField = (props: any) => {
  const { submitClickHandler, editClickHandler, inputValue, ttiBlankIndexUpdater } = props;

  return (
    <>
      <Form onSubmit={(event) => submitClickHandler(event)}>
        <div className="filter-row p-4">
          <Row className="align-items-center w-50">
            <Col xs="auto">
              <Form.Label>Current Blank Index</Form.Label>
            </Col>
            <Col>
              <Form.Control
                type="text"
                value={
                  inputValue.blankIndex !== ""
                    ? inputValue.blankIndex
                    : ttiBlankIndexUpdater?.blankIndex
                }
                readOnly
              />
            </Col>
            <Col xs="auto">
              <Form.Label>Current SSL</Form.Label>
            </Col>
            <Col>
              <Form.Control
                type="text"
                value={
                  inputValue.sslValue !== "" ? inputValue.sslValue : ttiBlankIndexUpdater?.sslValue
                }
                readOnly
              />
            </Col>
            <div className="ml-4">
              <Button className="rounded" onClick={() => editClickHandler()}>
                Edit
              </Button>

              <Button className="rounded ml-4" type="submit">
                Submit
              </Button>
            </div>
          </Row>
        </div>
      </Form>
    </>
  );
};

export default ReadOnlyInputField;
