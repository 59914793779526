import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";
import { default as ReactPaginate } from "react-js-pagination";

interface IProps {
  pageCount?: number;
  className?: string;
  activePage: number;
  itemsCountPerPage?: number;
  totalItemsCount?: number;
  pageRangeDisplayed?: number;
  onChange: (event: any) => void;
}

const Paginate = (props: IProps): React.ReactElement => {
  const {
    className,
    pageCount,
    activePage,
    itemsCountPerPage,
    totalItemsCount,
    pageRangeDisplayed,
    onChange,
  } = props;
  return (
    <Fragment>
      <ReactPaginate
        innerClass="pagination mb-0"
        itemClass="page-item"
        linkClass="page-link"
        firstPageText="First"
        lastPageText="Last"
        pageRangeDisplayed={4}
        activePage={activePage}
        itemsCountPerPage={itemsCountPerPage}
        totalItemsCount={totalItemsCount || 0}
        onChange={(page) => onChange(page)}
      />
    </Fragment>
  );
};

export default Paginate;
