import CustomSelectField, {
  SelectFieldWithoutControl,
} from "Common/Components/Inputs/CustomSelectField";
import { useAppContext } from "contexts/AppContext";
import DownloadCsv from "pages/ReportContainer/Components/DownloadCsv";
import React, { useEffect, useState } from "react";
import { getMissingContainers, setMissingContainersParams } from "../action";
import datapoint from "../dataPoints.json";
// import { DownloadCsv } from "pages/ReportContainer/Components/DownloadCsv";

const SearchFilter = () => {
  const { state, myDispatch } = useAppContext();
  const { missingContainerCount, missingContainers, errorMessage, searchParams, searchPost } =
    state.missingContainerStates;
  const { limit, page } = searchParams;

  const STATUS_CHOICE = Object.keys(datapoint)?.map((item: any) => ({
    label: item,
    value: item,
  }));

  const [status, setStatus] = useState<any>(STATUS_CHOICE[0]);
  const [dataPoints, setDataPoints] = useState<any>([]);
  const [selectPoints, setSelectPoints] = useState([datapoint.Available[0]]);

  const handleStatusChange = (data: any) => {
    const temp: any = datapoint;
    setStatus(data);
    setDataPoints(temp[data.value]);
    setSelectPoints([]);
  };

  useEffect(() => {
    handleDataPointChange([datapoint.Available[0]]);
  }, []);

  const handleDataPointChange = (point: any) => {
    setSelectPoints(point);

    if (status?.value) {
      const searchPostD = {
        ...searchPost,
        status: status.value,
        dataPoints: point.map((item: any) => item.value),
      };
      searchParams.page = 1;
      myDispatch(setMissingContainersParams(searchPostD, searchParams));
    }
  };

  // const downloadCSV = () => {
  //   const searchPayload = {
  //     ...searchParams,
  //     isCsv: true,
  //   };
  //   myDispatch(setMissingContainersParams(searchPost, searchPayload));
  // };

  return (
    <React.Fragment>
      <div className="filter-row d-flex align-items-center">
        <div className="app-search header-search">
          <span className="search-icon"> </span>
          <div className="position-relative">
            <input
              type="search"
              className="form-control input-search-left"
              placeholder="Search Containers..."
            />
          </div>
        </div>
        <SelectFieldWithoutControl
          className="w-200 mr-2"
          classNamePrefix="select"
          isLoading={false}
          isClearable={true}
          isSearchable={true}
          name="status"
          size="small"
          placeholder="Select Status"
          value={status}
          onChange={handleStatusChange}
          options={STATUS_CHOICE}
        />
        <SelectFieldWithoutControl
          className="min-200 mr-2"
          classNamePrefix="select"
          isLoading={false}
          isClearable={true}
          isSearchable={true}
          isMulti={true}
          name="status"
          size="small"
          value={selectPoints}
          onChange={handleDataPointChange}
          options={dataPoints}
        />
      </div>
    </React.Fragment>
  );
};

export default SearchFilter;
