import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import SubHeaderTab from "Common/Components/SubHeaderTab";
import { CompanyInfoTypes } from "pages/OnboardingModal/onboarding";
import { companyInfoSchema } from "pages/OnboardingModal/onBoardingValidation";
import CustomInputField from "Common/Components/Inputs/CustomInputField";
import { useAppContext } from "contexts/AppContext";
import AddCompanyModal from "pages/OnboardingModal/Components/AddCompanyModal";
import { useEffect, useState } from "react";
import CompanyForm from "pages/OnboardingModal/Components/CompanyForm";
import { addCompanyInfo } from "reducers/action/appAction";
import { IconCheck } from "Common/Icons";
import Toastr from "Common/Components/Toastr";

const options = [
  // { value: "broker", label: "Broker" },
  // { value: "consignee", label: "Consignee" },
  // { value: "shipper", label: "Shipper" },
  { value: "trucker", label: "Trucker" },
];

const UpdateProfile = () => {
  const { state, myDispatch } = useAppContext();
  const { companyAddress, companyName, companyType, fullName, role, isUpdateSuccess } =
    state.appStates.loggedUser;

  const [isCompanyInfo, setIsCompanyInfo] = useState(false);
  const [selectCompanyType, setSelectCompanyType] = useState<any>({});
  const [companyTypeError, setCompanyTypeError] = useState("");

  // REACT HOOK FORM
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<CompanyInfoTypes>({
    mode: "onBlur",
    resolver: yupResolver(companyInfoSchema),
  });

  const onFormSubmit = (data: CompanyInfoTypes) => {
    if (role === "Employee") {
      const finalData = {
        ...data,
      };
      myDispatch(addCompanyInfo(finalData));
      setIsCompanyInfo(true);
    } else {
      if (selectCompanyType?.value) {
        const finalData = {
          ...data,
          companyType: selectCompanyType.value,
        };
        myDispatch(addCompanyInfo(finalData));
        setIsCompanyInfo(true);
      } else {
        setCompanyTypeError("Company Type is required");
      }
    }
  };

  const handleCompanyType = (value: any) => {
    setSelectCompanyType(value);
  };

  useEffect(() => {
    if (companyType) {
      setSelectCompanyType(options.find((opt) => opt.value === companyType));
    }
  }, [state.appStates.loggedUser]);

  return (
    <div className="page-wrapper">
      <div className="position-relative subheader-wrapper">
        {isCompanyInfo && state.appStates.message && (
          <Toastr show={true} status="success" message="Updated Successfully" />
        )}
        <SubHeaderTab val="settingsItems" />
        <div className="page-fluid">
          <div className="top-page">
            <h4>Update Profile</h4>
          </div>
          <div className="form-wrapper mt-15 bg-white p-4 rounded shadow-sm w-50">
            <form onSubmit={handleSubmit(onFormSubmit)}>
              <CompanyForm
                errors={errors}
                register={register}
                selectCompanyType={selectCompanyType}
                handleCompanyType={handleCompanyType}
                companyTypeError={companyTypeError}
              />
              <div className="text-right">
                <button
                  disabled={Boolean(Object.keys(errors).length)}
                  className="btn btn-primary"
                  type="submit"
                >
                  {state.appStates.loading && (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateProfile;
