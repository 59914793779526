import { api } from "config";
import { objToQueryParams, removeNullEmpty } from "utils/commonFunctions";

export const getSuccessfulMatricsContainers = () => {
  return {
    type: "SUCCESSFULMATRICSCONTAINERS",
    payload: api.post("api/drayos/durationReport"),
  };
};

export const setSuccessfuMatricsContainersParams = (searchPost?: any, searchParams?: any) => {
  delete searchParams.reset;
  return {
    type: "SET_SUCCESSFULMATRICSCONTAINERS_PARAMS",
    payload: { searchPost, searchParams },
  };
};

export const resetSuccessfulMatricsContainersParams = (searchPost?: any, searchParams?: any) => {
  return {
    type: "RESET_SUCCESSFULMATRICSCONTAINERS_PARAMS",
  };
};
