import React, { createContext, useContext, useReducer } from "react";

import combineReducers from "react-combine-reducers";
import { appReducer, initialValues } from "reducers/reducer/appReducer";
import { configDataReducer, initialConfigValues } from "reducers/reducer/configDataReducer";
import { wrapperDispatch } from "utils/reduceMiddleware";
import { containerReducer, initialContainerValues } from "pages/Importavailability/reducer";
import { emptyReducer, initialEmptyValues } from "pages/EmptyReturn/reducer";
import { initialPaymentValues, onboardingReducer } from "pages/OnboardingModal/reducer";
import { vesselReducer, initialVesselValues } from "VesselSchedule/reducer";
import {
  initialProblemContainerValues,
  problemContainerReducer,
} from "pages/ProblemContainer/reducer";
import { dashboardReducer, initaldashboardValues } from "pages/Dashboard/reducer";

import {
  initialreportContainerValues,
  reportContainerReducer,
} from "pages/ReportContainer/reducer";
import {
  initialMissingContainerValues,
  missingContainerReducer,
} from "pages/MissingContainers/reducer";
import { initialWrongDataValues, wrongDataReportReducer } from "pages/WrongDataReport/reducer";
import {
  initialSuccessfulContainerValues,
  successfulContainerReducer,
} from "pages/SuccessfulDataReport/reducer";
import { vesselContainerReducer, initialVesselContainervalues } from "pages/VesselReport/reducer";
import {
  archiveContainerReducer,
  initialarchiveContainerValues,
} from "pages/ArchiveContainer/reducer";
import { initialreportDRRValues, reportDRReducer } from "pages/DrayosSummaryReport/reducer";
import {
  initialSucessfulMatricsContainerValues,
  sucessfulMatricsContainerReducer,
} from "pages/SucessfulMatricsContainerReport/reducer";
import { initialSupportValues, supportReducers } from "pages/Support/reducer";
import {
  drayosReportReducer,
  initialDrayosReportValues,
} from "pages/DrayosStandAloneReport/reducer";
import { timeScaleReducer, initialReportTimeScale } from "pages/TimeScaleReport/reducer";
const AppContext = createContext<any>(null);

function AppContextProvider({ children }: { children: React.ReactNode }) {
  // Combine reducers
  const [rootReducer, initialVals] = combineReducers({
    appStates: [appReducer, initialValues],
    configStates: [configDataReducer, initialConfigValues],
    containerStates: [containerReducer, initialContainerValues],
    emptyStates: [emptyReducer, initialEmptyValues],
    paymentStates: [onboardingReducer, initialPaymentValues],
    vesselStates: [vesselReducer, initialVesselValues],
    problemContainerStates: [problemContainerReducer, initialProblemContainerValues],
    dashboardStates: [dashboardReducer, initaldashboardValues],
    reportContainerStates: [reportContainerReducer, initialreportContainerValues],
    missingContainerStates: [missingContainerReducer, initialMissingContainerValues],
    wrongDataReportStates: [wrongDataReportReducer, initialWrongDataValues],
    successfulContainerStates: [successfulContainerReducer, initialSuccessfulContainerValues],
    vesselReportContainerStates: [vesselContainerReducer, initialVesselContainervalues],
    archiveContainerStates: [archiveContainerReducer, initialarchiveContainerValues],
    reportDRStates: [reportDRReducer, initialreportDRRValues],
    SucessfulMatricsContainerStates: [
      sucessfulMatricsContainerReducer,
      initialSucessfulMatricsContainerValues,
    ],
    supportStates: [supportReducers, initialSupportValues],
    drayosReportStates: [drayosReportReducer, initialDrayosReportValues],
    timescaleReportStates: [timeScaleReducer, initialReportTimeScale],
  });
  const [state, dispatch] = useReducer(rootReducer, initialVals);
  const myDispatch = wrapperDispatch(dispatch);

  return <AppContext.Provider value={{ state, myDispatch }}>{children}</AppContext.Provider>;
}
const useAppContext = () => {
  return useContext(AppContext);
};

export { AppContextProvider, useAppContext };
