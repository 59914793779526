import SubHeaderTab from "Common/Components/SubHeaderTab";
import React, { Fragment, useEffect, useState } from "react";
import SearchFilter from "./Components/SearchFilter";
import moment from "moment";
import { useAppContext } from "contexts/AppContext";
import { getEmptiesSlot, runEmptiesSlotCron, setSearchParams } from "./action";
import EmptiesSlotTable from "./Components/EmptiesSlotTable";
import { IconReturn } from "Common/Icons";
import Toastr from "Common/Components/Toastr";

const EmptiesSlotReport = () => {
  const { state, myDispatch } = useAppContext();
  const [params, setParams] = useState<any>({
    terminal: 1,
    date: moment().toISOString(),
    port: "APM TERMINAL",
  });
  const { emptiesSlotLoading, emptiesSlotSummary, emptiesSlotSearchParams } = state.reportDRStates;

  const [searchAlert, setSearchAlert] = useState<string | null>(null);
  useEffect(() => {
    if (params.terminal && params.date) {
      // console.log("sdkjfgskldjfgsdlkjfglsdkjfgh", params);
      myDispatch(setSearchParams(params));
      myDispatch(getEmptiesSlot(params));
      setSearchAlert(null);
    } else {
      setSearchAlert("Please Select Terminal and Date");
    }
  }, [params]);

  const runCron = () => {
    // console.log("sadfjgsdfk");
    myDispatch(runEmptiesSlotCron());
  };
  //   console.log(params);
  return (
    <div className="page-wrapper">
      <div className="position-relative subheader-wrapper">
        <SubHeaderTab val="reports" buttons={<RunCron runCron={runCron} />} />
        <div className="page-fluid">
          <div className="top-page">
            <div className="pb-15 d-flex align-items-center">
              <h4 className="mb-0">Empties Slot Report</h4>
            </div>
            <SearchFilter params={params} setParams={setParams} />
            {searchAlert && (
              <div className="alert alert-danger" role="alert">
                {searchAlert}
              </div>
            )}
          </div>
          <EmptiesSlotTable />
        </div>
      </div>
    </div>
  );
};

export default EmptiesSlotReport;

const RunCron = (props: any) => {
  const [running, setRunning] = useState<boolean>(false);
  const [disable, setDisable] = useState<boolean>(false);
  const onRun = () => {
    props.runCron();
    setRunning(true);
    setDisable(true);
    setTimeout(() => {
      setRunning(false);
    }, 10000);
  };
  return (
    <Fragment>
      <button disabled={disable} className="btn btn-success" onClick={() => onRun()}>
        {running && (
          <span
            className="spinner-border spinner-border-sm mr-2"
            role="status"
            aria-hidden="true"
          ></span>
        )}

        <IconReturn />
      </button>
      {running && (
        <Toastr
          show={true}
          status="success"
          title="Empties Appointment Slot is running Successfully."
          message="Please, don't click on the button now!"
        />
      )}
    </Fragment>
  );
};
