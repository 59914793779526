import React from "react";
import { Spinner } from "react-bootstrap";

const TableLoader = () => {
  return (
    <div className="w-100 d-flex justify-content-center align-items-center">
      <p className="mr-3">Please wait table data is being fetched. It can take some time </p>

      <Spinner animation="border" />
    </div>
  );
};

export default TableLoader;
