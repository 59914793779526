import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { IconCheck, IconTimes, IconReward } from "Common/Components/Icons/Icons";
import { useAppContext } from "contexts/AppContext";
import { getPaymentPlans, postCheckout } from "../action";
import { plans } from "../Common/constant";
import Loader from "Common/Components/Loader";
import Stepper from "./Stepper";
import { IconWarningCircle } from "Common/Icons";
import { CustomIconWarningCircle } from "Common/Components/Icons/CustomIcons";

const SubscriptionModal = (props: any) => {
  const {
    AddNewCompanyModal,
    handlePreviousStep,
    handleNextStep,
    handleFindPlan,
    setAddCompanyModal,
    activeStep,
  } = props;

  const [paymentPlans, setPaymentPlans] = useState<any>([]);
  const [selectedIndex, setSelectedIndex] = useState(1);
  const [productId, selectedProductId] = useState("");

  const {
    state: { paymentStates },
    myDispatch,
  } = useAppContext();

  useEffect(() => {
    myDispatch(getPaymentPlans());
  }, []);

  const handleChoosePlan = async (id: any) => {
    if (id) {
      const response = await postCheckout(id);
      myDispatch(response);
      response.payload.then((res) => {
        handleFindPlan(res.data.data.customer.id, id);
      });
    }
  };

  useEffect(() => {
    if (paymentStates.paymentData.length) {
      let paymentPlanss = paymentStates.paymentData;
      setPaymentPlans(paymentPlanss);
      selectedProductId(paymentPlanss[1].id);
    }
  }, [paymentStates.paymentData]);

  const currencyChecker = (curr: string) => {
    switch (curr) {
      case "usd":
        return "$";

      default:
        break;
    }
  };

  // const handleSelect = (list: any, index: number) => {
  //   setSelectedIndex(index);
  //   selectedProductId(list.id);
  // };

  return (
    <Modal show={AddNewCompanyModal} centered animation={false} size="xl">
      <Modal.Header className="d-block text-center bg-gray-50">
        <Modal.Title>Choose a plan that works for you!</Modal.Title>
        <div className="font-14 mt-15">
          Over 200 businesses already use PortPro! Try our{" "}
          <span className="text-primary font-weight-bold">Free Early Access for 15 days! </span> ✌️
        </div>
      </Modal.Header>
      <Modal.Body className="px-150 bg-gray-50">
        {/* Subscription Tab */}
        <div className="d-none  flex-row align-items-center justify-content-center">
          <ul className="nav nav-compact" role="tablist">
            <li className="nav-item">
              <a
                className="nav-link active text-capitalize"
                data-bs-toggle="tab"
                href="#monthly"
                role="tab"
              >
                Monthly
              </a>
            </li>
            {/* <li className="nav-item">
              <a className="nav-link" data-bs-toggle="tab" href="#annually" role="tab">
                Annually
              </a>
            </li> */}
          </ul>
        </div>
        {paymentStates.pLoading && <Loader />}
        <div className="d-flex flex-row justify-content-center align-items-end">
          {paymentPlans.map((list: any, ind: number) => {
            const findList = plans.find((it) => it.key === list.nickname);
            return (
              <div
                className={`bg-white cursor-pointer w-50 rounded-5 ${ind % 2 ? "" : "mr-4"} ${
                  ind === selectedIndex ? "" : ""
                }`}
                // onClick={() => handleSelect(list, ind)}
                key={ind}
              >
                <h5
                  className={`${
                    list.nickname === "Track + Per Diem"
                      ? "bg-success text-white d-block"
                      : "d-none"
                  } p-10 mb-0 text-center rounded-top-6`}
                >
                  Early Access Offer!
                </h5>
                <div className="h-100 px-20 bg-white border-bottom rounded-top-6">
                  {/* <div className="font-20 font-weight-500 mb-10">{paymentPlans?.product?.name}</div> */}
                  <div className="font-20 font-weight-500 my-30 text-center">{list.nickname}</div>
                  <button
                    className="btn btn-lg btn-primary w-100 justify-content-center"
                    onClick={() => handleChoosePlan(list.id)}
                  >
                    {/* Select {paymentPlans?.product?.name} Plan */}
                    {/* Start My {list.nickname} Free Trial */}
                    Start My 15 Days Free Trial
                  </button>
                  <div className="d-flex align-items-bottom justify-content-center mt-20">
                    <div className="font-14 font-weight-500 mr-10"> then</div>
                    <div className="text-center">
                      {/* <h4 className="font-30">
                    {currencyChecker(paymentPlans?.product?.currency)}
                    {(paymentPlans?.product?.price / 100).toFixed(2)}/mo
                  </h4> */}
                      <h4 className="font-30 font-sm">${(list.amount / 100).toFixed(2)}/month</h4>
                      <div className="text-muted mb-20">
                        {/* for regular users ${(list.amount / 100) * 1.2}/month */}
                        for regular users ${list.nickname === "Track + Per Diem" ? "1,498" : "699"}
                        /month
                      </div>
                    </div>
                    <div
                      className={`"badge h-max rounded-16 px-2 py-1 ml-10 font-weight-bold font-12" ${
                        list.nickname === "Track + Per Diem" ? "badge-success" : "badge-gray-700"
                      }`}
                    >
                      {list.nickname === "Track + Per Diem" ? "50% " : "30%"} OFF
                    </div>
                  </div>
                </div>
                {/* Plans List */}
                <div className="px-25 pt-20">
                  {findList &&
                    findList.data.length &&
                    findList.data.map((planType: any, i) => (
                      <div
                        className={`d-flex mb-15 ${
                          !planType.isAvailable ? "o-20 text-gray-300" : null
                        }`}
                        key={`${ind}${i}`}
                      >
                        {planType.isAvailable ? (
                          <IconCheck className="text-primary mr-15 wh-30px" />
                        ) : (
                          <IconTimes className="text-gray-300 mr-15 wh-30px" />
                        )}
                        <div>
                          <h5
                            className={`font-weight-500 font-16 $ ${
                              !planType.isAvailable ? "o-20 text-gray-300" : null
                            }`}
                          >
                            {planType.title}
                          </h5>
                          <p className="mt-2 text-muted">{planType.desc}</p>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            );
          })}
        </div>
        <div className="d-flex align-items-center justify-content-center mt-40 mb-10">
          <div className="badge badge-gray-300 font-12 font-weight-normal text-center d-flex align-items-center p-10">
            <CustomIconWarningCircle className="mr-10 wh-20px" />
            This pricing is reserved for beta customers and early access members only.
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Stepper activeStep={activeStep} />
        {/* <button
          className="btn btn-outline-light mr-10 py-10 px-15"
          onClick={() => setAddCompanyModal(false)}
        >
          Skip
        </button> */}
        <button
          className="btn btn-outline-light mr-10 py-10 px-15"
          onClick={() => handlePreviousStep(1)}
        >
          Back
        </button>
        {/* <button
          disabled={paymentStates.pLoading}
          className="btn btn-primary py-10 px-15"
          onClick={() => handleChoosePlan()}
        >
          {paymentStates.pLoading && (
            <span
              className="spinner-border spinner-border-sm mr-2"
              role="status"
              aria-hidden="true"
            ></span>
          )}
          Continue
        </button> */}
      </Modal.Footer>
    </Modal>
  );
};

export default SubscriptionModal;
