import { api } from "config";
import { objToQueryParams, removeNullEmpty } from "utils/commonFunctions";

export const getVesselContainerReport = (data: any, params?: any) => {
  delete data.reset;
  let postData = removeNullEmpty(data);
  let url = "api/dashboard/etaMissingDataReport";
  url = params ? url + "?" + objToQueryParams(params) : url;
  return {
    type: "VESSELREPORTS",
    payload: api.get(url, postData),
  };
};

export const setReportContainersParams = (searchPost?: any, searchParams?: any) => {
  return {
    type: "SET_REPORTCONTAINERS_PARAMS",
    payload: { searchPost, searchParams },
  };
};

export const resetReportContainersParams = () => {
  return {
    type: "RESET_REPORTCONTAINERS_PARAMS",
  };
};

export const getReportContainersCsv = (data: any, params?: any, page?: string) => {
  let postData = removeNullEmpty(data);
  let postparams = removeNullEmpty(params);
  let url = "api/container/dataMissingReport";
  if (page === "notUpdatedContainersItems") {
    url = "api/container/getNotUpdatedContainers";
    postparams.isCsv = true;
  } else if (page === "problemContainer") {
    url = "api/dashboard/filterContainer";
  } else if (page === "importAvailability") {
    url = "api/container/getAllContainersByUserId";
  } else if (page === "missingContainer") {
    url = "api/dashboard/missingDataReport";
  } else if (page === "wrongDataReport") {
    url = "api/dashboard/wrongDataReport";
  } else if (page === "successfulContainer") {
    url = "api/dashboard/sucessfullDataReport";
  }
  url = params ? url + "?" + objToQueryParams(postparams) : url;
  return {
    type: "REPORTCONTAINERSCSV",
    payload:
      page === "notUpdatedContainersItems" ? api.get(url, postData) : api.post(url, postData),
  };
};

export const hitSingleContainer = (id: string) => {
  console.log("getting latest Data");
  return {
    type: "HIT_SINGLELATEST",
    payload: api.post("api/scheduler/single/" + id),
  };
};
