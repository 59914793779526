//

import axios from "axios";
import { api } from "config";
import _ from "lodash";

import jsonToQueryParams, { objToQueryParams, removeNullEmpty } from "utils/commonFunctions";

export const prodApi = axios.create({
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
    Expires: "0",
    // api.headers,
    xapikey: `${process.env.REACT_APP_XAPI}`,
  },
  baseURL: `${process.env.REACT_APP_PUBLIC_API}`,
  timeout: 360000,
});

const errorApi = axios.create({
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
    Expires: "0",
  },
  baseURL: `${process.env.REACT_APP_API_URL}`,
  timeout: 360000,
});
errorApi.interceptors.request.use(function (config: any) {
  const accessToken = localStorage.getItem("accessToken");
  const refreshToken = localStorage.getItem("refreshToken");
  config.headers.Authorization = `${accessToken ? accessToken : refreshToken}`;
  return config;
});
export const getDrayosSummary = (params: any, isReturn: boolean) => {
  let url = `v1/reports/getAllReport`;
  if (isReturn) url = `v1/reports/getReturnLocationReport`;
  if (params.trackStatus == "all") {
    delete params.trackStatus;
  }
  if (params.carrier == "ALL") {
    delete params.carrier;
  }
  if (params) {
    params = _.pickBy(params, _.identity);
    url = url + "?" + jsonToQueryParams(params);
  }
  return {
    type: "DRAYOS_SUMMARYREPORT",
    payload: prodApi.get(url),
  };
};

export const getMissingReportContainers = (data: any, params: any) => {
  let postData: any = removeNullEmpty(data);
  delete postData.missingCount;
  delete postData.successCount;
  delete postData.totalCount;
  delete postData.OperationalStatus;
  delete postData.errorDetails;
  delete postData.interval;
  delete postData.missing_Tracking_Status;
  delete postData.success_Tracking_Status;
  delete postData.Return_location;
  delete postData.errorContainers;
  let url = `v1/reports/getReport`;

  if (params) {
    params = _.pickBy(params, _.identity);
    url = url + "?" + jsonToQueryParams(params);
  }

  return {
    type: "MISSINGREPORTCONTAINERS",
    payload: prodApi.post(url, postData),
  };
};

export const getContainerDetailedError = (drayosRefs: any) => {
  let postData = {
    containers: drayosRefs,
    lastTime: 1,
  };

  let url = "api/errors/getContainerError";
  return {
    type: "CONTAINER_DETAILED_ERROR",
    payload: errorApi.post(url, postData),
  };
};

export const getCarrierDetail = () => {
  let url = "v1/reports/getCarriers";
  return {
    type: "CARRIER_DETAIL",
    payload: prodApi.get(url),
  };
};

export const getSyncLoadDetail = (carrierId: string) => {
  let url = "v1/reports/getNewReport?carrier=" + carrierId;
  return {
    type: "SYNC_LOAD",
    payload: prodApi.get(url),
  };
};

export const resetMissingReportContainersParams = (searchPost?: any, searchParams?: any) => {
  return {
    type: "RESET_MISSINGREPORTCONTAINERS_PARAMS",
  };
};
//container status report
export const getContainerStatusReport = (carrierId: string) => {
  let url = "v1/reports/getStatusReport?carrier=" + carrierId;
  return {
    type: "CONTAINERSTATUS_REPORT",
    payload: prodApi.get(url),
  };
};
