import React from "react";

import Papa from "papaparse";
import { useAppContext } from "contexts/AppContext";

declare global {
  interface Navigator {
    msSaveBlob?: (blob: any, defaultName?: string) => boolean;
  }
}
const Download = (props: any) => {
  const { state, myDispatch } = useAppContext();

  const { missingDatas, mloading, mErrloading } = state.reportDRStates;

  const onDownload = () => {
    let csvval = convertToCsv(missingDatas);
    console.log("downloading...");
    let csv = Papa.unparse(csvval);
    let csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    let csvURL = null;
    if (navigator.msSaveBlob) {
      csvURL = navigator.msSaveBlob(csvData, `${new Date().getTime()}.csv`);
    } else {
      csvURL = window.URL.createObjectURL(csvData);
    }
    let tempLink: any = document.createElement("a");
    tempLink.href = csvURL;
    tempLink.setAttribute("download", `${new Date().getTime()}.csv`);
    tempLink.click();
  };
  return (
    <button className="btn btn-danger" onClick={() => onDownload()}>
      Download
    </button>
  );
};

export default Download;

const convertToCsv = (values: any) => {
  let csvalues = [];
  csvalues = values.map((val: any) => {
    let csvVal: any = {};
    val.eta = val?.vessel?.eta;
    val.myApp_tracking_status = val?.error?.status;
    val.errMsg = val?.error?.message;
    delete val.shipper;
    delete val.error;
    delete val.vessel;
    csvVal = { ...val };
    return csvVal;
  });
  return csvalues;
};
