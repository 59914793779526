import CustomInputField from "Common/Components/Inputs/CustomInputField";
import CustomSelectField, {
  CustomAsyncSelectField,
} from "Common/Components/Inputs/CustomSelectField";
import { IconReset, IconTimes } from "Common/Icons";
import { useAppContext } from "contexts/AppContext";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm, SubmitHandler } from "react-hook-form";
import axios from "axios";

import { SessionUpdateValues } from "../support";
import { updateContainerCredentials } from "../action";
interface IProps {
  credentials: any;
  onCancel: () => void;
  sLoading: Boolean;
}

const EditCredentialModal = (props: IProps) => {
  const { state, myDispatch } = useAppContext();
  const { credentials, sLoading, onCancel } = props;

  const [session, setSession] = useState<any | null>(credentials.sessions);
  const [meta, setMeta] = useState<any | null>(credentials?.meta?.skValue);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<SessionUpdateValues>({
    defaultValues: {
      sessions: session,
      meta,
    },
  });

  const onFormSubmit: SubmitHandler<SessionUpdateValues> = async (data) => {
    const requestBody = {
      id: credentials._id,
      sessions: data.sessions,
      meta: data.meta,
      auth_failed: credentials.auth_failed,
      port: credentials.port,
    };
    if (credentials.port === "MAHER TERMINAL") {
      requestBody.sessions = { userData: credentials.sessions.userData, token: data.sessions };
    }
    const response = updateContainerCredentials(requestBody);
    myDispatch(response);
  };
  const handleSession = (data: string) => {
    setSession(data);
  };
  const handleMeta = (data: string) => {
    setMeta(data);
  };
  return (
    <>
      <Modal show={credentials && credentials.port} dialogClassName={"modal-sm"}>
        <Modal.Header>
          <Modal.Title className="w-100 d-flex align-items-center justify-content-between">
            <h5 className="font-20 mb-0">Update Session</h5>
            <div className="d-flex ml-auto">
              <button className="btn btn-white shadow-none" onClick={onCancel}>
                <IconTimes className="mr-2" />
                Close
              </button>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="form-sm" onSubmit={handleSubmit(onFormSubmit)}>
            <CustomInputField
              autoComplete="off"
              placeholder="Session"
              name="sessions"
              type="text"
              required
              register={register}
              label={<span>Session</span>}
              className="form-control"
              onChange={handleSession}
            />
            {credentials && credentials.port === "EVERPORT TERMINAL" ? (
              <CustomInputField
                autoComplete="off"
                placeholder="Meta"
                name="meta"
                type="text"
                required
                register={register}
                onChange={handleMeta}
                label={<span>Meta</span>}
                className="form-control"
              />
            ) : null}
            <div className="text-center">
              <button
                type="submit"
                className={
                  `btn btn-primary btn-lg btn-block mt-40`
                  // sLoading ? "btn-success" : "btn-primary "
                }
              >
                {sLoading && (
                  <span
                    className="spinner-border spinner-border-sm mr-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
                Confirm
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditCredentialModal;
