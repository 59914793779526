import CustomInputField from "Common/Components/Inputs/CustomInputField";
import { SelectFieldWithoutControl } from "Common/Components/Inputs/CustomSelectField";
import { useAppContext } from "contexts/AppContext";
import React, { useState } from "react";

const options = [
  // { value: "broker", label: "Broker" },
  // { value: "consignee", label: "Consignee" },
  // { value: "shipper", label: "Shipper" },
  { value: "trucker", label: "Trucker" },
];

interface IProps {
  errors: any;
  register: any;
  selectCompanyType: any;
  handleCompanyType: any;
  companyTypeError: any;
}

const CompanyForm = (props: IProps) => {
  const { errors, register, selectCompanyType, handleCompanyType, companyTypeError } = props;
  const { state, myDispatch } = useAppContext();
  const { companyAddress, companyName, companyType, fullName, role } = state.appStates.loggedUser;

  return (
    <div className="form-row ">
      {/* <span className="text-danger"> {state.appStates.errorMessage} </span>
                <span className="text-success"> {state.appStates.message} </span> */}
      {/* <h3 className="text-center mb-50">Start Improving Your Shipping!</h3> */}
      {state.appStates.errorMessage && (
        <div className="alert alert-warning" role="alert">
          {state.appStates.errorMessage}
        </div>
      )}
      <div className="form-group mb-0 col-md-12">
        <CustomInputField
          autoComplete="off"
          placeholder="Enter Group Name"
          name="fullName"
          defaultValue={fullName || ""}
          type="text"
          required
          error={Boolean(errors.fullName)}
          register={register}
          helper={errors.fullName ? errors.fullName.message : ""}
          label={
            <span>
              Your Full Name<span className="text-danger">*</span>
            </span>
          }
          className="form-control"
        />
      </div>
      {role !== "Employee" && (
        <React.Fragment>
          <div className="form-group col-md-12">
            <SelectFieldWithoutControl
              label="Company Type"
              required
              name="companyType"
              options={options}
              value={selectCompanyType}
              onChange={(value) => handleCompanyType(value)}
              isClearable={true}
              size="small"
              placeholder="Select Here.."
            />
            {companyTypeError && (
              <div className="mt-2 error" style={{ color: "red" }}>
                {companyTypeError}
              </div>
            )}
          </div>
          <div className="form-group mb-0 col-md-12">
            <CustomInputField
              autoComplete="off"
              placeholder="e.g BigTruckCompany"
              name="companyName"
              defaultValue={companyName || ""}
              type="text"
              required
              error={Boolean(errors.companyName)}
              register={register}
              helper={errors.companyName ? errors.companyName.message : ""}
              label={
                <span>
                  Company Name<span className="text-danger">*</span>
                </span>
              }
              className="form-control"
            />
          </div>
          {/* <div className="form-group col-md-12">
            <CustomInputField
              autoComplete="off"
              placeholder="e.g 2118 Thornridge Cir. Syracuse, Connecticut 35624"
              name="companyAddress"
              defaultValue={companyAddress || ""}
              type="text"
              required
              error={Boolean(errors.companyAddress)}
              register={register}
              helper={errors.companyAddress ? errors.companyAddress.message : ""}
              label={
                <span>
                  Company Address<span className="text-danger">*</span>
                </span>
              }
              className="form-control"
            />
          </div> */}
        </React.Fragment>
      )}
    </div>
  );
};

export default CompanyForm;
