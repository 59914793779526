import React, { Fragment, useEffect, useMemo, useState } from "react";
import Loader from "Common/Components/Loader";
import { caseStatus } from "Common/Components/StatusBadge";
import { useAppContext } from "contexts/AppContext";
import moment from "moment";
import { generateLFDColor, getDetailedValue, showDate } from "utils/commonFunctions";
import ContainerIngate from "./ContainerIngate";
import ContainerDetails from "./ContainerDetails";

import {
  getEirDocument,
  getMissingDocs,
  refreshContainer,
  setAvailableParams,
  setSelectedContainers,
} from "../action";
import { IContainerFilterValues } from "../containers";
import { tableHeaders } from "../constant";
import { handleSort } from "pages/EmptyReturn/Common/utils";
import ContainerUtils from "./utils";
import AppointmentModal from "./appointment/AppointmentModal";
interface IProps {
  setShowEditModal: (value: boolean) => void;
}

// const getTimeZone = () => {
//   const data = JSON.parse(localStorage.getItem("loggedUser")!);
//   const timeZone = data.terminal[0].timeZone;
//   return timeZone;
// };

const ContainerTable = (props: any): React.ReactElement => {
  const { setShowEditModal, selectContainer } = props;
  // const timeZone = getTimeZone();
  // moment.tz.setDefault("America/New_York");

  //API CALL
  const { state, myDispatch } = useAppContext();
  const {
    cLoading,
    containers,
    cErrorMessage,
    searchParams,
    searchPost,
    sizeType,
    addContainerCount,
  } = state.containerStates;

  const { containerSizes, containerOwners, containerTypes, terminals, ports } = state.configStates;
  const { loggedUser, userSelectedTerminals } = state.appStates;
  const { hasAddedArchive } = state.archiveContainerStates;
  const { page, limit, sort } = searchParams;
  //STATE MANAGEMENT
  const [viewRow, setViewRow] = useState<number | null>();
  const [viewIngate, setViewIngate] = useState<any | null>();
  const [viewDetails, setViewDetails] = useState<any | null>(null);
  const [containerId, setContainerId] = useState("");
  const [checkedContainer, setCheckedContainer] = useState<string[]>([]);
  const [apptSchedule, setApptSchedule] = useState<any | null>();
  const [apptType, setApptType] = useState<any | null>();
  const [headers, setTableHeader] = useState<any | null>(tableHeaders);
  const timeZone = localStorage.getItem("timeZone");

  useEffect(() => {
    if (hasAddedArchive) {
      setCheckedContainer([]);
    }
  }, [hasAddedArchive]);

  const EverportGateDocument = (ContainerId: any) => {
    setContainerId(ContainerId);
    const response = getEirDocument({
      containerId: [ContainerId],
    });
    response.payload.then((res) => setContainerId(""));
    myDispatch(response);
  };

  //HANDLE FUNCTION
  const getPortTerminalName = (val: any, vtype: "port" | "terminal") => {
    if (typeof val === "string") {
      return getDetailedValue(val, vtype === "port" ? ports : loggedUser?.terminal);
    } else {
      return val?.name;
    }
  };

  useEffect(() => {
    setViewRow(null);
    if (viewDetails) {
      const findCont = containers.find((ff: any) => ff._id === viewDetails._id);
      setViewDetails(findCont);
    }
  }, [state.containerStates]);

  const onRefreshContainer = (id: string) => {
    myDispatch(refreshContainer(id));
  };
  const hasImage = (row: any) => {
    if (row.outGate?.s3Url || row.inGate?.s3Url || row.etaS3url) {
      return true;
    }
    return false;
  };
  const getEmptyReturn = (vals: any) => {
    let emptyReturn: string[] = [];
    vals?.length > 0 &&
      vals?.map((val: any) => {
        if (typeof val === "object") {
          emptyReturn.push(val.port);
        } else {
          let portname = getPortTerminalName(val, "port");
          if (portname) emptyReturn.push(portname);
        }
      });
    return emptyReturn;
  };
  const handleEditContainer = (container: IContainerFilterValues) => {
    myDispatch(setSelectedContainers(container));
    setShowEditModal(true);
  };
  const onsort = (obj: any) => {
    const searchParamsD = handleSort(obj, headers, searchParams);
    myDispatch(setAvailableParams(searchPost, searchParamsD));
  };

  //missing document
  const handleMissingDocument = async (container: any) => {
    setViewDetails(container);
    if (
      (container.status === "Outgated" || container.status === "Terminated") &&
      !container.outGate?.s3Url &&
      !container.isDocsNotFound === true
    ) {
      const payload = {
        container: [container],
      };
      const response = await getMissingDocs(payload);
      myDispatch(response);
    }
  };
  const handleSelectContainer = (container_id: string, check: boolean) => {
    if (check) {
      setCheckedContainer([...checkedContainer, container_id]);
    } else {
      setCheckedContainer(checkedContainer.filter((cc: any) => !cc.includes(container_id)));
    }
    selectContainer(container_id, check);
  };

  return (
    <Fragment>
      <div
        className="table-responsive"
        id="dispatchTable"
        style={{ height: "calc(100vh - 200px)" }}
      >
        {cLoading && <Loader />}
        <table className="table table-card" id="myTable">
          <thead>
            <tr>
              <th scope="col" className="">
                <div className="d-flex align-items-center justify-content-between">
                  <span className="mr-1">#</span>
                  <span className="form-check">
                    <input className="form-check-input" type="checkbox" id="sr_no" />
                  </span>
                </div>
              </th>
              {headers.map((item: any, i: number) => {
                let sortIcon = "table-sort table-sort--default";
                if (item.sort === "-" && item.active) {
                  sortIcon = "table-sort table-sort--ascending";
                }
                if (item.sort === "+" && item.active) {
                  sortIcon = "table-sort table-sort--descending";
                }
                return (
                  <th key={i} onClick={() => onsort(item)} className={sort ? sortIcon : ""}>
                    <span
                      className={
                        item.label === "Discharge Date" ? "text-center text-break-spaces" : ""
                      }
                    >
                      {item.label}
                    </span>
                  </th>
                );
              })}
            </tr>
          </thead>

          <tbody>
            {containers.length === 0 && cErrorMessage && (
              <tr>
                <td colSpan={100}>{cErrorMessage}</td>
              </tr>
            )}
            {containers.map((container: any, i: number) => {
              const containerUtils = new ContainerUtils(
                container,
                containerSizes,
                containerTypes,
                sizeType,
                userSelectedTerminals
              );
              const port = getPortTerminalName(container.portId, "port");
              return (
                <Fragment key={i}>
                  <tr key={i}>
                    <td>
                      <div className="d-flex align-items-center justify-content-between">
                        <span className="mr-1">{(page - 1) * limit + i + 1}</span>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            checked={checkedContainer.includes(container._id)}
                            onChange={(e) => handleSelectContainer(container._id, e.target.checked)}
                            name="Select"
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div
                        className="pointer text-primary"
                        onClick={() => {
                          handleMissingDocument(container);
                          // setViewDetails(container);
                          // if (container.containerType || container.containerSize) {
                          // }
                        }}
                      >
                        {container.containerNo}
                      </div>
                    </td>
                    <td>{caseStatus(container.status)}</td>
                    <td>
                      {container.custom && <p>{"Custom: " + container.custom}</p>}
                      {container.freight && <p>{"Freight: " + container.freight}</p>}
                      {container.brokerHold && <p>{"Broker: " + container.brokerHold}</p>}
                    </td>
                    <td>{getDetailedValue(container.containerOwner, containerOwners)}</td>

                    <td>{containerUtils.getContainerSizeType(addContainerCount, i)}</td>

                    <td>{getPortTerminalName(container.portId, "port")}</td>
                    <td>{containerUtils.getContainerEta(addContainerCount, i)}</td>
                    <td>{containerUtils.getContainerDischargeDate(addContainerCount, i)}</td>
                    <td className={generateLFDColor(container.lastFreeDay, container.status)}>
                      {showDate(container.status, "lfd") &&
                        timeZone &&
                        container.lastFreeDay &&
                        moment(container.lastFreeDay).tz(timeZone).format("MM/DD")}
                    </td>

                    <td>
                      {showDate(container.status, "outgatedDate") &&
                        container.outGateDate &&
                        timeZone &&
                        moment(container.outGateDate).tz(timeZone).format("MM/DD HH:mm")}
                    </td>
                    <td className="">
                      {getEmptyReturn(container.emptyReturnLocation)
                        .toString()
                        .replaceAll("_", " ")}
                    </td>

                    <td>{containerUtils.getContainerTerminatedDate(addContainerCount, i)}</td>
                    <td>{containerUtils.getContainerVesselName(addContainerCount, i)}</td>

                    <td>{containerUtils.getContainerVoyageName(addContainerCount, i)}</td>
                    <td>{container.billOfLading}</td>
                    <td>{container.grossWeight}</td>
                    <td>{container.seal}</td>
                    <td>{container.yardLocation}</td>
                    <td
                      onClick={() => {
                        setApptSchedule(
                          port?.toUpperCase().includes("APM") || port?.includes("WBCT")
                            ? container
                            : null
                        );
                        setApptType("pickup");
                      }}
                    >
                      <div className="text-center cursor-pointer">
                        {container?.pickUpAppt && !Array.isArray(container?.pickUpAppt)
                          ? container?.pickUpAppt?.date + "-" + container?.pickUpAppt?.text
                          : "-"}
                      </div>
                    </td>
                    <td
                      onClick={() => {
                        setApptSchedule(port?.toUpperCase().includes("APM") ? container : null);
                        setApptType("empty");
                      }}
                    >
                      <div className="text-center cursor-pointer">
                        {container?.emptyAppt
                          ? container?.emptyAppt?.date + "-" + container?.emptyAppt?.text
                          : "-"}
                      </div>
                    </td>
                  </tr>

                  {viewRow === i && (
                    <tr>
                      <td></td>
                      <td colSpan={100}>
                        <div className="text-break p-2">
                          {container.extraTracerData &&
                            Object.entries(container.extraTracerData).map(([key, value], j) => {
                              if (!value) return;
                              return (
                                <div key={j}>
                                  {key} : {JSON.stringify(value)}
                                </div>
                              );
                            })}
                        </div>
                      </td>
                    </tr>
                  )}
                </Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
      {viewIngate && viewIngate.containerNo && (
        <ContainerIngate values={viewIngate} onCloseModal={() => setViewIngate(null)} />
      )}
      {/* Container Details Modal */}
      {viewDetails && viewDetails.containerNo && (
        <ContainerDetails values={viewDetails} onCancel={() => setViewDetails(null)} />
      )}
      {apptSchedule && apptSchedule.containerNo && (
        <AppointmentModal
          values={apptSchedule}
          apptType={apptType}
          onCancel={() => setApptSchedule(null)}
        />
      )}
    </Fragment>
  );
};
export default ContainerTable;
