import SubHeaderTab from "Common/Components/SubHeaderTab";
import React, { Fragment, useEffect, useState } from "react";
import SearchFilter from "./Components/SearchFilter";
import moment from "moment";
import { useAppContext } from "contexts/AppContext";
import {
  getAppointmentEnabledCarriers,
  getAppointmentReport,
  setAppointmentReportParams,
} from "./action";
import AppointmentReportTable from "./Components/AppointmentReportTable";
import Pagination from "Common/Components/Pagination";
import { IconReturn } from "Common/Icons";
import Toastr from "Common/Components/Toastr";
import Loader from "Common/Components/Loader";

const AppointmentReport = () => {
  const { state, myDispatch } = useAppContext();
  const { apptReportSearchParams, apptLoading, apptReportCount } = state.reportDRStates;

  const initialParams = {
    LOAD: null,
    CONTAINER: null,
    PORT: null,
    CARRIER: null,
    PORT_USERNAME: null,
    PORT_PASSWORD: null,
    APPT_ID: null,
    LOAD_STATUS: null,
    APPT_STATUS: null,
    LOAD_TYPE: null,
    FULL_EMPTY: null,
    MOVE_TYPE: null,
    REMARKS: null,
  };

  const [params, setParams] = useState<any>(initialParams);

  useEffect(() => {
    // myDispatch(setSearchParams(params));
    const reqQuery = {
      ...apptReportSearchParams,
      carrier: params?.CARRIER?.value,
      port: params?.PORT?.value,
      loadStatus: params?.LOAD_STATUS?.value,
      apptStatus: params?.APPT_STATUS?.value,
      container: params?.CONTAINER,
      load: params?.LOAD,
      loadType: params?.LOAD_TYPE?.value,
      fullempty: params?.FULL_EMPTY?.value,
      movetype: params?.MOVE_TYPE?.value,
      apptId: params?.APPT_ID,
    };

    myDispatch(getAppointmentReport(reqQuery));
  }, [params, apptReportSearchParams]);

  useEffect(() => {
    myDispatch(getAppointmentEnabledCarriers());
    myDispatch(getAppointmentReport());
  }, []);

  const onPaginate = (val: any) => {
    let searchParamsD = { ...apptReportSearchParams, page: val };
    myDispatch(setAppointmentReportParams(searchParamsD));
  };

  return (
    <div className="page-wrapper">
      <div className="position-relative subheader-wrapper">
        {apptLoading && <Loader />}
        <SubHeaderTab val="reports" />
        <div className="page-fluid">
          <div className="top-page">
            <div className="pb-15 d-flex align-items-center">
              <h4 className="mb-0">Appointment Report</h4>
              <div className="ml-auto">
                <Pagination
                  className={"ml-auto"}
                  pageRangeDisplayed={5}
                  totalItemsCount={apptReportCount}
                  itemsCountPerPage={apptReportSearchParams.size}
                  onChange={onPaginate}
                  activePage={apptReportSearchParams.page}
                />
              </div>
            </div>
            <SearchFilter params={params} setParams={setParams} />
          </div>
          <AppointmentReportTable />
        </div>
      </div>
    </div>
  );
};

export default AppointmentReport;
