import React, { Fragment, useCallback, useEffect, useState } from "react";
import CustomSelectField, {
  SelectFieldWithoutControl,
} from "Common/Components/Inputs/CustomSelectField";
import { useAppContext } from "contexts/AppContext";
import _ from "lodash";
import { getGetOverAllReport } from "../action";

const SearchFilter = (props: any) => {
  const { state, myDispatch } = useAppContext();
  const { setParams, params } = props;
  const { loading, standardPorts } = state.drayosReportStates;

  const [selectedOptions, setSelectedOptions] = useState<any>(params);

  const handleOptsChange = (e: any, type: string) => {
    const parameters = { ...selectedOptions };
    if (e.value) {
      parameters[type] = e.value;
      setParams((prevParams: any) => ({
        ...prevParams,
        ...parameters,
      }));
    }
  };
  return (
    <Fragment>
      <div className="filter-row d-flex align-items-center">
        <SelectFieldWithoutControl
          isLoading={false}
          isClearable={true}
          isSearchable={true}
          name="selectPort"
          options={standardPorts?.map((pp: any) => ({ label: pp.portCode, value: pp.portCode }))}
          onChange={(e: any) => handleOptsChange(e, "portCode")}
          size="small"
          className="min-200 mr-2"
          placeholder="Select Port"
        />
        <SelectFieldWithoutControl
          isLoading={false}
          isClearable={true}
          isSearchable={true}
          name="selectHour"
          options={Array.from({ length: 10 }, (v, i) => i + 1).map((pp: any) => ({
            label: pp,
            value: pp,
          }))}
          onChange={(e: any) => handleOptsChange(e, "hour")}
          size="small"
          className="min-200 mr-2"
          placeholder="Select Hours"
        />
      </div>
      <div className="filter-row d-flex align-items-center"></div>
    </Fragment>
  );
};

export default SearchFilter;
