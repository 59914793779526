import { CustomRocket } from "Common/Components/Icons/CustomIcons";
import CustomCreatableSelect from "Common/Components/Inputs/CustomCreatable";
import SubHeaderTab from "Common/Components/SubHeaderTab";
import { useAppContext } from "contexts/AppContext";
import { InviteValue } from "pages/OnboardingModal/onboarding";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, SubmitHandler } from "react-hook-form";
import { InviteScheme } from "pages/OnboardingModal/Components/onboardingValidation";
import { useEffect } from "react";
import { getInvitation } from "pages/OnboardingModal/action";
import Toastr from "Common/Components/Toastr";
const options: any[] = [];
interface IProps {
  showModal: any;
  hideModal: any;
  onCancel: any;
  showNextModal: any;
}
const Invite = (props: any) => {
  const {
    state: { appStates },
    myDispatch,
  } = useAppContext();
  // const { activeStep, handleActive, setShowModal, showModal } = props;
  //React Hooks Form
  const {
    register,
    formState: { errors },
    control,
    handleSubmit,
  } = useForm<InviteValue>({
    mode: "onBlur",
    resolver: yupResolver(InviteScheme),
  });

  //FORM SUBMIT
  const onFormSubmit: SubmitHandler<InviteValue> = async (data) => {
    const email: string[] = data.email.map((em) => em.value);
    await myDispatch(getInvitation(email));
  };
  // useEffect(() => {
  //   // if (appStates.message) {
  //   //   console.log(appStates.message);
  //   //   // handleActive(0);
  //   //   console.log("error");
  //   // }
  // }, [appStates]);
  return (
    <div className="page-wrapper">
      <div className="position-relative subheader-wrapper">
        <SubHeaderTab val="settingsItems" />
        <div className="page-fluid">
          <div className="top-page">
            <div className="form-wrapper bg-white w-50 rounded shadow-sm">
              <form onSubmit={handleSubmit(onFormSubmit)}>
                {appStates.isInviteSuccess && appStates.message && (
                  <Toastr show={true} status="success" message={appStates.message} />
                )}
                {!appStates.isInviteSuccess && appStates.errorMessage && (
                  <Toastr show={true} status="error" message={appStates.errorMessage} />
                )}
                <div className=" d-flex py-40 justify-content-center align-items-center bg-brand-50 flex-column ">
                  <CustomRocket className="" />
                  <div className="text-center mt-10">
                    <div className="font-22 font-weight-500 text-gray-900 pb-3">Welcome!</div>
                    <div className="font-14 font-weight-normal text-gray-500">
                      Now you can feel the power of TrackOS!
                    </div>
                  </div>
                </div>
                <div className="p-30">
                  <div className="text-center">
                    <div className="font-18 font-weight-500 text-gray-900 pb-2">
                      Let’s invite your Team!
                    </div>
                    <div className="font-12 font-weight-normal text-gray-500">
                      Please add your team members email addresses below to invite
                    </div>
                    <div className="font-weight-normal text-gray-500">
                      them to join your account!
                    </div>
                  </div>

                  <div className="form-wrapper mt-20">
                    <div className="form-group mb-1">
                      <CustomCreatableSelect
                        label=""
                        options={options}
                        name="email"
                        isMulti={true}
                        isClearable={true}
                        size="small"
                        placeholder="Enter email address.."
                        error={Boolean(errors.email)}
                        helper={errors.email ? errors.email : []}
                        control={control}
                      />
                    </div>
                  </div>
                  <div className="font-12 font-weight-normal text-gray-400">
                    You can add multiple address by Tab.
                  </div>
                  <div className="font-12 font-weight-normal text-gray-400">
                    E.g. email1@gmail.com email2@gmail.com email3@gmail.com
                  </div>
                </div>
                <div className="p-30 pt-0 d-flex justify-content-end align-items-center">
                  <button className="btn btn-primary text-white" type="submit">
                    {appStates.loading && (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}
                    Continue
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Invite;
