import { IconDocument } from "Common/Icons";
import React, { Fragment, useEffect, useState } from "react";

const Download = (props: any) => {
  return (
    <Fragment>
      <button className="btn btn-success" onClick={() => props.getDocument()}>
        {props.running && (
          <span
            className="spinner-border spinner-border-sm mr-2"
            role="status"
            aria-hidden="true"
          ></span>
        )}

        <IconDocument />
      </button>
    </Fragment>
  );
};

export default Download;
