import { CustomIconConfirm } from "Common/Components/Icons/CustomIcons";
import { Modal } from "react-bootstrap";

interface IProps {
  selectedDate: any;
  selectedSlot: any;
  onClose: () => void;
}
const ConfirmAppointment = (props: IProps) => {
  const { selectedDate, selectedSlot, onClose } = props;
  return (
    <>
      <Modal.Body>
        <div className="d-flex flex-column p-10 align-items-center text-center">
          <div>
            <CustomIconConfirm className="text-primary mb-20" />
          </div>
          <h3>The Appointment is successfully confirmed on</h3>
          <div className="mt-15 p-20 text-center bg-gray-50 font-20 font-weight-500 rounded-5 w-max">
            {selectedDate} • {selectedSlot?.text}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex">
          <button className="btn btn-outline-light" onClick={onClose}>
            Close
          </button>
        </div>
      </Modal.Footer>
    </>
  );
};

export default ConfirmAppointment;
