import React, { useState, useCallback, useEffect } from "react";
import { IconColumn, IconFilter } from "Common/Icons";
import _ from "lodash";
import { useAppContext } from "contexts/AppContext";
import { getContainerInfo, setAvailableParams } from "../action";
import ImportAvailabilityFilterPop from "./filter";

const FilterRow = () => {
  const { state, myDispatch } = useAppContext();
  const { searchPost, searchParams } = state.containerStates;
  const [showFilter, setShowFilter] = useState(false);
  const [containerNo, setContainers] = useState<string>("");

  const onChangeInput = (event: any) => {
    let val = event.target.value;
    setContainers(val?.replace(/[^A-Z0-9]+/gi, ""));
  };
  const debouncedChangeHandler = useCallback(_.debounce(onChangeInput, 1000), []);

  useEffect(() => {
    if (searchPost?.containerNo !== containerNo) {
      searchPost.containerNo = containerNo;
      searchParams.page = 1;
      myDispatch(setAvailableParams(searchPost, searchParams));
    }
  }, [containerNo]);
  return (
    <React.Fragment>
      <div className="filter-row d-flex align-items-center">
        {/* Search */}
        <div className="app-search header-search">
          <span className="search-icon"> </span>
          <div className="position-relative">
            <input
              type="search"
              className="form-control input-search-left"
              placeholder="Search..."
              defaultValue={containerNo}
              onChange={debouncedChangeHandler}
            />
          </div>
        </div>
        {/* Filter */}
        <div className="ml-auto d-flex">
          <button className="btn btn-outline-light" onClick={() => setShowFilter(!showFilter)}>
            <IconFilter className="mr-2" />
            Filter
          </button>
          {/* <div className="position-relative">
            <button className="btn btn-outline-light position-relative">
              <IconColumn />
            </button>
          </div> */}
        </div>
        {showFilter && <ImportAvailabilityFilterPop hideFilter={() => setShowFilter(false)} />}
      </div>
    </React.Fragment>
  );
};

export default FilterRow;
