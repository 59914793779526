import { Modal } from "react-bootstrap";
import React, { useState } from "react";
import BookingDetails from "./BookingDetails";

const BookingsTable = ({}) => {
  const [showBookingModal, setShowBookingModal] = useState(false);

  const handleShowBookingModal = () => {
    setShowBookingModal(true);
  };
  const handleHideAddModal = () => {
    setShowBookingModal(false);
  };

  //Table Datas
  const tableDatas = [];
  for (let i = 1; i < 20; i++) {
    tableDatas.push({
      id: i,
    });
  }

  return (
    <React.Fragment>
      <div className="table-responsive" style={{ height: "calc(100vh - 385px)" }}>
        <table className="table table-card">
          <thead>
            <tr>
              <th>
                <div className="from-check form-check--gray">
                  <input className="form-check-input" type="checkbox" id="sr_no" />
                </div>
              </th>
              <th>Booking #</th>
              <th>Status</th>
              <th>Line</th>
              <th>Vessel</th>
              <th>Voyage</th>
              <th>Lloyds #</th>
              <th>Last Update </th>
            </tr>
          </thead>
          <tbody>
            {tableDatas.map((tabledata) => {
              return (
                <tr key={tabledata.id}>
                  <td>
                    <div className="from-check form-check--gray">
                      <input className="form-check-input" type="checkbox" id="sr_no" />
                    </div>
                  </td>
                  <td>
                    <div className="pointer text-primary" onClick={handleShowBookingModal}>{`TGCU${
                      515650 + tabledata.id
                    }`}</div>
                  </td>
                  <td>
                    <span className="badge badge-secondary">Found</span>
                  </td>
                  <td>MSC</td>
                  <td>ALS FAUNA</td>
                  <td>BBRA-UA150A</td>
                  <td>92228923</td>
                  <td>12/16/2021 16:43</td>
                </tr>
              );
            })}
            ;
          </tbody>
        </table>
      </div>

      {showBookingModal && (
        <BookingDetails showAddModal={handleShowBookingModal} hideAddModal={handleHideAddModal} />
      )}
    </React.Fragment>
  );
};

export default BookingsTable;
