import React, { useState, useEffect } from "react";
import Calendar from "react-calendar";
import { useAppContext } from "contexts/AppContext";
import PortImages from "pages/EmptyReturn/PortImages";
import { Modal } from "react-bootstrap";
import { getDetailedValue } from "utils/commonFunctions";
import ConfirmAppointment from "./ConfirmAppointment";
import { bookAppointment, getContainerLists, getTimeSlots } from "pages/Importavailability/action";
import Loader from "Common/Components/Loader";
import Toastr from "Common/Components/Toastr";
interface IProps {
  values: any;
  onCancel: () => void;
  apptType?: string;
}
const AppointmentModal = (props: IProps) => {
  const { values, apptType, onCancel } = props;
  const { state, myDispatch } = useAppContext();
  const { ports, containerOwners, containerSizes } = state.configStates;
  const { loggedUser } = state.appStates;
  const {
    timeSlots,
    tLoading,
    bLoading,
    apptError,
    timeSlotError,
    pickApptData,
    emptyApptData,
    searchParams,
    searchPost,
  } = state.containerStates;

  // states management
  const [date, onChange] = useState<any | null>(new Date());
  const [selectedTimeSlot, setSelectedTimeSlot] = useState<any | null>(null);
  const [confirmAppt, setConfirmAppt] = useState<any | null>(null);

  //   Get Port Name of Container
  const getPortTerminalName = (val: any, vtype: "port" | "terminal") => {
    if (typeof val === "string") {
      return getDetailedValue(val, vtype === "port" ? ports : loggedUser?.terminal);
    } else {
      return val?.name;
    }
  };
  const port = getPortTerminalName(values.portId, "port");

  // select Time
  const onSelectTime = (slotData: any) => {
    setSelectedTimeSlot(slotData);
  };

  // get dynamic port credentials for APM
  const portCred = ports.find(
    (pp: any) => pp?.userId === loggedUser?._id && pp?.name?.includes(port)
  );

  // GET- Import Slots params
  let requiredParams = {
    username: portCred?.portUsername,
    password: portCred?.portPassword,
    terminal: portCred?.code === "APM" ? "APM TERMINAL" : portCred?.code,
    containerNo: values.containerNo,
    date: date.toLocaleDateString(),
    portId: portCred?.portId,
  };

  // GET- Import Slots for a container API call
  const getImportSlots = () => {
    if (apptType === "empty") {
      let emptyParams = {
        ...requiredParams,
        lineId: getDetailedValue(values.containerOwner, containerOwners),
        size: getDetailedValue(values.containerSize, containerSizes),
      };
      myDispatch(getTimeSlots(emptyParams, apptType));
    } else {
      myDispatch(getTimeSlots(requiredParams, apptType));
    }
  };

  // Confirm Booking
  const onConfirmAppointment = (slot: any) => {
    // Post- schedule appointment
    let requiredPostData = {
      ...requiredParams,
      containerId: values?._id,
      slot_id: slot?.key,
      timeSlots: slot,
    };
    let apptResponse;
    if (apptType === "empty") {
      let emptyPayload = {
        ...requiredPostData,
        lineId: getDetailedValue(values.containerOwner, containerOwners),
        size: getDetailedValue(values.containerSize, containerSizes),
      };

      apptResponse = bookAppointment(emptyPayload, apptType);
    } else {
      apptResponse = bookAppointment(requiredPostData, apptType);
    }
    myDispatch(apptResponse);
    apptResponse.payload.then(() => {
      setConfirmAppt(slot);
    });
  };

  // set today date and slots on default
  useEffect(() => {
    getImportSlots();
  }, [date]);

  // get container lists for pickup data updates
  useEffect(() => {
    if (pickApptData || emptyApptData) {
      myDispatch(getContainerLists(searchPost, searchParams));
    }
  }, [pickApptData, emptyApptData]);

  return (
    <>
      {timeSlotError && <Toastr show={true} status="error" message={timeSlotError} />}
      {apptError && <Toastr show={true} status="error" message={apptError} />}
      <Modal
        show={values && values.containerNo}
        dialogClassName={confirmAppt ? "modal-sm" : "modal-md"}
      >
        {confirmAppt ? (
          <ConfirmAppointment
            selectedDate={date.toDateString()}
            selectedSlot={confirmAppt}
            onClose={() => {
              setConfirmAppt(null);
              onCancel();
            }}
          />
        ) : (
          <>
            <Modal.Header>
              <Modal.Title className="w-100 d-flex align-items-center justify-content-between">
                Book {apptType === "pickup" ? "a Pickup" : "an Empty"} Appointment
                <div className="img-wrapper h-60px">
                  <img
                    src={PortImages[port?.toUpperCase().replace(/\s/g, "_")]}
                    alt={port}
                    className="h-100 w-100 img-contain"
                  />
                </div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-md-7">
                  <Calendar
                    onChange={onChange}
                    value={date}
                    className="custom-calendar"
                    tileClassName="calendar-tile"
                  />
                </div>
                <div className="col-md-5 bg-gray-50 p-20 font-14 text-gray-900">
                  {tLoading && <Loader />}
                  {timeSlots && timeSlots.length ? (
                    <div className="timeslot-grid">
                      {timeSlots.map((timeSlot: any) => (
                        <div
                          key={timeSlot.key}
                          className={selectedTimeSlot?.text === timeSlot?.text ? "active" : ""}
                          onClick={() => onSelectTime(timeSlot)}
                        >
                          {timeSlot.text}
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="d-flex align-items-center justify-content-center h-100">
                      <h5 className="text-gray-500 mb-0">No Appointments Available</h5>
                    </div>
                  )}
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="d-flex">
                <button className="btn mr-40" onClick={onCancel}>
                  Cancel
                </button>
                <button
                  className="btn btn-primary"
                  onClick={() => onConfirmAppointment(selectedTimeSlot)}
                >
                  {bLoading && (
                    <span
                      className="spinner-border spinner-border-sm mr-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                  Confirm
                </button>
              </div>
            </Modal.Footer>
          </>
        )}
      </Modal>
    </>
  );
};

export default AppointmentModal;
