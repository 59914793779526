import { SetStateAction, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { IconCheck } from "Common/Icons/index";
import { useAppContext } from "contexts/AppContext";
import { IPortTypes, ITerminalTypes } from "../onboarding";
import _ from "lodash";
import { assignTerminal, getCredPortList } from "reducers/action/appAction";

export interface ModalPropsI {
  showModal: any;
  hideModal: any;
  showNextModal: any;
}

const SelectTerminals = (props: any) => {
  const { activeStep, handleActive, showModal, handlePrevious, setShowModal } = props;
  const { state, myDispatch } = useAppContext();
  const { ports, loading, terminals } = state.configStates;
  const { loggedUser, assignPortErMessage, ploading } = state.appStates;
  const [tempTerminals, setTempTerminals] = useState<ITerminalTypes[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [myTerminals, setmyTerminals] = useState<ITerminalTypes | null>(null);

  // const [tempTerminals, setTempTerminals] = useState<ITerminalTypes | null>(null);
  // const [selectAll, setSelectAll] = useState(false);

  // const [selectedTerminal, setSelectedTerminal] = useState<ITerminalTypes | null>(null);

  //select terminal
  const handleSelectedTerminal = (port: ITerminalTypes) => {
    // console.log(port, "port data");
    // const temp = tempTerminals;
    // const findIndex = tempTerminals.findIndex((tt: any) => tt.name === port);
    // console.log("index of temp terminals", findIndex);
    // temp[findIndex].checked = !tempTerminals[findIndex].checked;
    setmyTerminals(port);
    // setTempTerminals([...temp]);
  };

  // useEffect(() => {
  //   const temp: any = [];
  //   if (terminals.length) {
  //     terminals.map((item: any) => {
  //       return temp.push({
  //         ...item,
  //         // checked: loggedUser?.terminal?.find((tt: any) => tt._id === item._id),
  //         checked: true,
  //       });
  //     });
  //     setmyTerminals([...temp]);
  //     setTempTerminals([...temp]);
  //   }
  // }, [terminals]);

  //handle next
  const handleNext = async () => {
    // const selectedValue: any[] = [];
    // myTerminals.map((term) => {
    //   return selectedValue.push(term.checked ? term._id : "");
    // });
    if (myTerminals?._id) {
      const selectedValue = [myTerminals?._id];
      let data = { terminalId: selectedValue };
      if (data.terminalId.length) {
        let res = await assignTerminal(data);
        myDispatch(res);
        res.payload.then((resp) => {
          // myDispatch(getCredPortList(loggedUser._id));
          handleActive(activeStep);
        });
      }
    }
  };

  //search
  // const handleSearchTerminal = (e: any) => {
  //   const text = e?.target.value;
  //   const temp = tempTerminals;
  //   const searchData = tempTerminals.filter((data: ITerminalTypes) => {
  //     return data.name.toUpperCase().includes(text.toUpperCase());
  //   });
  //   if (text) {
  //     setmyTerminals(searchData);
  //   } else {
  //     setmyTerminals(temp);
  //   }
  // };

  //select All
  // const handleSelectAll = (select: boolean) => {
  //   let selectedTerminals = terminals.map((pp: any) => {
  //     return {
  //       ...pp,
  //       checked: select,
  //     };
  //   });
  //   setmyTerminals(selectedTerminals);
  //   setTempTerminals(selectedTerminals);
  //   setSelectAll(select);
  // };

  return (
    <Modal show={showModal && activeStep === 1} centered animation={false} size="xl">
      <Modal.Header className="justify-content-center">
        <Modal.Title>Port Market</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex align-items-center justify-content-center">
          <div className="app-search bg-gray-50 w-50 rounded-lg mr-20">
            <input
              type="text"
              className="input-search-left form-control py-2"
              placeholder="Search Terminal..."
              // onChange={(e: any) => handleSearchTerminal(e)}
            />
            <span className="search-icon ml-1"></span>
          </div>
          {/* <button
            className={`btn btn-outline-${selectAll ? "warning" : "light"}`}
            onClick={() => handleSelectAll(Boolean(!selectAll))}
          >
            {selectAll ? "Unselect All" : "Select All"}
          </button> */}
        </div>
        <div className="c-list__grid d-flex justify-content-center mt-40">
          {terminals?.length > 0 &&
            terminals.map((terminal: ITerminalTypes, index: number) => (
              <div
                className={`clist__card clist__card--outline text-center py-15 px-15 ${
                  terminal._id === myTerminals?._id ? "active" : ""
                }`}
                key={index}
                onClick={() => setmyTerminals(terminal)}
              >
                {terminal.name}
                <div className={terminal._id === myTerminals?._id ? "checked" : "unchecked"}>
                  <IconCheck className="text-white" />
                </div>
              </div>
            ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        {/* <button className="btn" onClick={() => setShowModal(false)}>
          Do it Later
        </button> */}
        <button className="btn btn-outline-light mr-10 " onClick={() => handlePrevious(activeStep)}>
          Back
        </button>
        <button className="btn btn-primary" onClick={() => handleNext()}>
          {state.appStates.loading && (
            <span
              className="spinner-border spinner-border-sm  mr-1"
              role="status"
              aria-hidden="true"
            ></span>
          )}
          Next
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default SelectTerminals;
