import { useAppContext } from "contexts/AppContext";
import React, { useState } from "react";
import { Button, Form, Col, Row } from "react-bootstrap";

// interface IProps {
//   getAllTokens: any;
//   addNewToken: any;
// }

const EditInputForm = (props: any) => {
  const { saveClickHandler, inputChangeHandler, inputValue } = props;

  return (
    <>
      <Form onSubmit={(event) => saveClickHandler(event)}>
        <div className="filter-row p-4">
          <Row className="align-items-center w-50">
            <Col xs="auto">
              <Form.Label>Update Blank Index</Form.Label>
            </Col>
            <Col>
              <Form.Control
                type="text"
                value={inputValue?.blankIndex}
                onChange={(event) => inputChangeHandler(event, "blankIndex")}
                required
              />
            </Col>

            <Col xs="auto">
              <Form.Label>Update SSL</Form.Label>
            </Col>
            <Col>
              <Form.Control
                type="text"
                value={inputValue?.sslValue}
                onChange={(event) => inputChangeHandler(event, "sslValue")}
                required
              />
            </Col>
            <div className="ml-4">
              <Button className="ml-2 rounded" type="submit">
                Save
              </Button>
            </div>
            <div className="ml-4">
              <Button
                className="ml-2 rounded"
                type="button"
                onClick={(event) => saveClickHandler(event)}
              >
                Cancel
              </Button>
            </div>
          </Row>
        </div>
      </Form>
    </>
  );
};

export default EditInputForm;
