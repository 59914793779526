import React from "react";

interface IProps {
  item?: any;
}
const UpdateTokenLabel = (props: IProps) => {
  const { item } = props;
  if (item.token) {
    return <div className={`font-12, font-weight-normal text-brand-500`}>Token Added</div>;
  } else {
    return <div className={`font-12, font-weight-normal text-gray-500`}>Token Not Required</div>;
  }
};
export default UpdateTokenLabel;
