import { headers } from "../../config/api";
import _ from "lodash";
import { objToQueryParams, removeNullEmpty } from "utils/commonFunctions";
import axios from "axios";
import { api } from "config";

// const prodApi = axios.create({
//   baseURL: `https://trackos-devapi.axle.network/`,
//   headers,
// });

export const getEmptyData = (data: string, isTomorrow: boolean, weekDate?: string) => {
  return {
    type: "GETEMPTYDATA",
    payload: api.post("api/empty/all", {
      terminal: data,
      isTomorrow,
      weekDate: weekDate,
      isGenerateSignUrl: true,
    }),
  };
};

export const setEmptiesData = (data: any) => {
  return {
    type: "SET_EMPTYDATA",
    payload: data,
  };
};

export const getFilterEmptiesData = (data: any) => {
  let payload = { ...data, isGenerateSignUrl: true };
  return {
    type: "FILTER_EMPTYDATA",
    payload: api.post("api/empty/all", payload),
  };
};

export const fetchGateHours = (data?: any) => {
  let url = "api/gateHours/gate";
  if (data?.port) {
    url = `api/gateHours/gate?port=${data.port}`;
  }
  return {
    type: "FETCH_GATEHOURS",
    payload: api.get(url),
  };
};
export const getEmptyCorrections = (data: any) => {
  return {
    type: "EMPTY_CORRECTION",
    payload: api.post("api/empty/corrections", data),
  };
};
