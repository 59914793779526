import { useAppContext } from "contexts/AppContext";
import { getDuplicateArrayofObjects } from "pages/EmptyReturn/Common/utils";
import React, { useEffect, useState } from "react";
import { getVesselLists, setVesselParams } from "VesselSchedule/action";
import { VesselPropsI } from "../vessel";

const VesselTabs = (props: any) => {
  const { state, myDispatch } = useAppContext();
  const { noCredentialPorts } = state.configStates;
  const { userSelectedTerminals } = state.appStates;
  const userSelectedPorts = getDuplicateArrayofObjects(
    noCredentialPorts,
    userSelectedTerminals,
    "terminalCode",
    "code"
  );

  const [isTabActiveId, setIsTabActiveId] = useState("");
  const handleTabChange = (id: string, terminalCode: number) => {
    setIsTabActiveId(id);
    getVesselSchedule(id, terminalCode);
    const searchPost = {
      port: [id],
      terminalId: terminalCode,
    };
    myDispatch(setVesselParams(searchPost, null));
  };

  useEffect(() => {
    if (userSelectedPorts.length) {
      setIsTabActiveId(userSelectedPorts[0]?._id);
      getVesselSchedule(userSelectedPorts[0]?._id, userSelectedTerminals[0]?.code);
      const searchPost = {
        port: [userSelectedPorts[0]?._id],
        terminalId: userSelectedTerminals[0]?.code,
      };
      myDispatch(setVesselParams(searchPost, null));
    }
  }, [userSelectedTerminals]);

  //getVessel Schedule list API CALL
  const getVesselSchedule = async (id: string, code: number) => {
    const response = await getVesselLists({ port: [id], terminalId: code });
    myDispatch(response);
  };
  return (
    <div className="d-flex flex-row align-items-center justify-content-between w-100">
      <ul className="nav nav-compact border-0 p-0" role="tablist">
        {userSelectedPorts.map((tabItem: any) => (
          <li className="nav-item" key={tabItem._id}>
            <div
              className={`nav-link ml-1 cursor-pointer ${
                isTabActiveId === tabItem._id ? " active" : "rounded-3 bg-light"
              }`}
              onClick={() => handleTabChange(tabItem._id, tabItem.terminalCode)}
            >
              {tabItem.name}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default VesselTabs;
