import { api } from "config";
import { objToQueryParams, removeNullEmpty } from "utils/commonFunctions";

export const getEmptiesSlot = (data: any, params?: any) => {
  let postData = removeNullEmpty(data);
  let url = "/api/emptyslots";
  url = params ? url + "?" + objToQueryParams(params) : url;
  return {
    type: "DRAYOS_EMPTIESSLOT",
    payload: api.post(url, postData),
  };
};

export const runEmptiesSlotCron = () => {
  let url = "api/scheduler/saveEmptyAppointmentSlots";
  console.log("url", url);
  return {
    type: "RUN_EMPTIESSLOT",
    //   payload: api.get(url),
  };
};

export const setSearchParams = (searchParams?: any, searchPost?: any) => {
  return {
    type: "SET_EMPTIESSLOT_PARAMS",
    payload: { searchPost, searchParams },
  };
};

export const getFilteredEmptiesSlotData = (data: any, params?: any) => {
  let postData = removeNullEmpty(data);
  let url = "api/emptyslots/getFilteredData";
  url = params ? url + "?" + objToQueryParams(params) : url;
  // console.log("postData",postData);
  return {
    type: "DRAYOS_FILTERED_EMPTIESSLOTDATA",
    payload: api.post(url, postData),
  };
};
