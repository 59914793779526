import { Fragment, useEffect, useState } from "react";
import moment from "moment";
import { useAppContext } from "contexts/AppContext";
import { handleSort } from "pages/EmptyReturn/Common/utils";
import { cookieTableHeaders } from "../constant";
import { addNewSession, setContainerCredentialsParams, updateLBCTCookies } from "../action";
import Toastr from "Common/Components/Toastr";
// import CookieUpdateModal from "./CookieUpdateModal";
import Loader from "Common/Components/Loader";
import CookieUpdateModal from "./CookieUpdateModal";

const CookieList = (props: any) => {
  const { state, myDispatch } = useAppContext();
  const { isCookies } = props;
  const {
    cookieList,
    searchParams,
    searchPost,
    stLoading,
    sLoading,
    errorMessage,
    lbctCookies,
    cloading,
  } = state.supportStates;

  const [more, setMore] = useState<any | null>();
  const [selectedCredential, setSelectedCredential] = useState<any | null>();
  const [showToast, setShowToast] = useState<boolean>(false);
  const [filteredCookieList, setFilteredCookieList] = useState<any>(cookieList);

  const onSort = (obj: any) => {
    const searchParamsD = handleSort(obj, cookieTableHeaders, searchParams);
    myDispatch(setContainerCredentialsParams(searchPost, searchParamsD));
  };

  const closeModalAfterSuccess = () => {
    setSelectedCredential(null);
  };

  setTimeout(() => {
    setShowToast(false);
  }, 4000);

  const toastHandler = () => {
    setShowToast(true);
  };

  let countNo = 0;

  const defaultNotification = {
    id: "",
    isLoading: false,
    success: false,
    message: "",
    status: "",
    type: "",
  };
  const [notification, setNotification] = useState<any | null>(defaultNotification);
  const resetNotification = () => {
    setNotification(defaultNotification);
  };

  const handleAutoFix = async (credential: any) => {
    setNotification({ ...notification, isLoading: true, type: "auto", id: credential?._id });
    const payload = {
      port: credential?.portId?.code,
      portUsername: credential?.portUsername,
      portPassword: credential?.portPassword,
    };
    const response = updateLBCTCookies(payload);
    myDispatch(response);
    const { data } = await response.payload;
    if (data?.data?.message) {
      const message = data?.data?.message;
      console.log("here is message", message);
      setNotification({ ...notification, success: true, message, status: "error" });
      return;
    }
    const lbctResponse = data.data[0]?.response;
    const message = lbctResponse?.message;
    if (lbctResponse?.caution) {
      setNotification({ ...notification, success: true, message, status: "error" });
    } else {
      setNotification({ ...notification, success: true, message, status: "success" });
    }
  };

  useEffect(() => {
    let filteredList = cookieList;
    if (isCookies) {
      filteredList = cookieList.filter((mm: any) => mm.cookies);
    }
    setFilteredCookieList(filteredList);
  }, [isCookies, cookieList]);

  return (
    <Fragment>
      {errorMessage === "" && showToast && (
        <Toastr show={true} status="success" message={"Cookies Updated Successfully"} />
      )}
      {errorMessage !== "" && showToast && (
        <Toastr show={true} status="error" message={errorMessage} />
      )}
      <div className="table-responsive" style={{ height: "calc(100vh - 255px)" }}>
        {stLoading && <Loader />}
        <table className="table table-card">
          <thead>
            <tr>
              <th>S No.</th>
              {cookieTableHeaders.map((item: any, i: number) => {
                let sortIcon = "table-sort table-sort--default";
                if (item.sort === "-" && item.active) {
                  sortIcon = "table-sort table-sort--ascending";
                }
                if (item.sort === "+" && item.active) {
                  sortIcon = "table-sort table-sort--descending";
                }
                return (
                  <th
                    key={i}
                    onClick={() => onSort(item)}
                    className={
                      item.sort
                        ? item.label === "Cookies Update"
                          ? `text-center ${sortIcon}`
                          : sortIcon
                        : ""
                    }
                  >
                    {item.label}
                  </th>
                );
              })}
            </tr>
          </thead>

          <tbody>
            {Array.isArray(filteredCookieList) && filteredCookieList.length > 0 ? (
              filteredCookieList?.map((credential: any, i: number) => {
                const cookiess = JSON.stringify(credential?.cookies);
                countNo += 1;
                return (
                  <tr key={i}>
                    <td>{countNo}</td>
                    <td>{credential.userId._id}</td>
                    <td>{credential.portId.code}</td>
                    <td>{credential.portUsername}</td>
                    <td>{credential.portPassword}</td>
                    <td>
                      <div
                        style={{
                          maxWidth: 200,
                          whiteSpace: "break-spaces",
                          lineBreak: "anywhere",
                        }}
                      >
                        <span onClick={() => setMore(i === more ? null : i)}>
                          {cookiess && more === i && cookiess}
                          {cookiess && more !== i && cookiess.substring(0, 50) + "..."}
                        </span>
                      </div>
                    </td>
                    <td>{credential?.auth_failed?.toString()}</td>
                    <td>{credential?.session_failed?.toString()}</td>
                    <td>
                      {credential.updatedAt ? moment(credential.updatedAt).format("lll") : ""}
                    </td>
                    <td className="text-center">
                      <button
                        className="btn btn-primary mr-10"
                        onClick={() => handleAutoFix(credential)}
                      >
                        {notification.isLoading &&
                          notification.id === credential._id &&
                          notification.type === "auto" && (
                            <span
                              className="spinner-border spinner-border-sm mr-2"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          )}
                        Verify
                      </button>
                      <button
                        className={"btn btn-primary"}
                        onClick={() => {
                          setSelectedCredential(credential);
                        }}
                      >
                        Manual
                      </button>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={100}>Data Not Found</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {/* Container Details Modal */}
      {selectedCredential && (
        <CookieUpdateModal
          credentials={selectedCredential}
          closeModalAfterSuccess={closeModalAfterSuccess}
          onCancel={() => setSelectedCredential(null)}
          sLoading={sLoading}
          toastHandler={toastHandler}
        />
      )}
      {notification.success && (
        <Toastr
          key={notification.id}
          show={notification.success}
          status={notification.status}
          message={notification.message}
          reset={resetNotification}
        />
      )}
    </Fragment>
  );
};

export default CookieList;
