import React from "react";
import { Modal } from "react-bootstrap";

export interface ModalPropsI {
  showAddModal: any;
  hideAddModal: any;
}

const AddNewBooking = (props: ModalPropsI) => {
  const { showAddModal, hideAddModal } = props;
  return (
    <Modal show={showAddModal} centered animation={false} size="sm">
      <Modal.Header>
        <Modal.Title>Add New Booking(s)</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-wrapper">
          <div className="form-group">
            <label>Enter up to 100 bookings</label>
            <textarea
              className="form-control"
              placeholder="e.g. Booking 1, Booking 2&#10;or&#10;Booking 1&#10;Booking 2&#10;Booking 3"
            ></textarea>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-close" onClick={hideAddModal}>
          Cancel
        </button>
        <button className="btn btn-primary">Add Bookings</button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddNewBooking;
