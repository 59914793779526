import Loader from "Common/Components/Loader";
import { useAppContext } from "contexts/AppContext";
import moment from "moment";
import React, { Fragment, useState } from "react";
import { getVesselLists, setVesselParams } from "VesselSchedule/action";
import { tableHeaders } from "VesselSchedule/constant";

const VesselScheduleTable = () => {
  const { state, myDispatch } = useAppContext();
  const [headers, setTableHeader] = useState<any | null>(tableHeaders);

  const { vLoading, vessels, vErrorMessage, searchParams, searchPost } = state.vesselStates;
  const { sort } = searchParams;

  const onsort = (obj: any) => {
    let sortString = ``;
    headers.map((D: any) => {
      if (D.value === obj.value) {
        D.sort = D.sort === "-" || D.sort === "" ? "+" : "-";
        sortString = `${D.sort === "+" ? "" : D.sort}${D.value}`;
        D.active = true;
      } else {
        D.active = false;
      }
      return D;
    });
    if (sortString !== "") {
      const searchPostD = {
        ...searchPost,
      };
      const searchParamsD = {
        ...searchParams,
        sort: sortString,
      };
      myDispatch(getVesselLists(searchPostD, searchParamsD));
    }
  };

  const dateFormatConverter = (value: any) => {
    return value;
    // NOTE: issues formating FENIX raw date
    // sample date creating issue:
    // 23-Mar-29 1400
    // 23-Apr-05 1400
    // 23-May-01 0400
    //
    // const hasDate = new Date(value);
    // if (hasDate.toString() !== "Invalid Date") {
    //   const format = moment(value).format("MM/DD/YYYY HH:mm");
    //   return format;
    // }
    // return value;
  };

  return (
    <React.Fragment>
      <div className="table-responsive" id="VesselTable" style={{ height: "calc(100vh - 260px)" }}>
        {vLoading && <Loader />}
        <table className="table table-card" id="myTable">
          <thead>
            <tr>
              <th scope="col" className="w-10px">
                Sno
              </th>
              {headers.map((item: any, i: number) => {
                let sortIcon = "table-sort table-sort--default";
                if (item.sort === "-" && item.active) {
                  sortIcon = "table-sort table-sort--ascending";
                }
                if (item.sort === "+" && item.active) {
                  sortIcon = "table-sort table-sort--descending";
                }
                return (
                  <th key={i} onClick={() => onsort(item)} className={item.sort ? sortIcon : ""}>
                    {item.label}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {vessels && vessels.length ? (
              vessels.map((vessel: any, i: number) => {
                return (
                  <Fragment key={i}>
                    <tr key={i}>
                      <td className="text-center"> {i + 1}</td>
                      <td>{vessel.vesselName}</td>
                      <td>{vessel.voyageName}</td>
                      <td>{vessel.vesselCode || vessel.vesselExtraTracerData?.VesselCode}</td>
                      <td>{formatDate(vessel?.etaOnSite)}</td>
                      <td>{formatDate(vessel?.ERD)}</td>
                      <td>{formatDate(vessel?.ERDRF)}</td>
                      <td>{formatDate(vessel?.cutOffDate)}</td>
                      <td>{formatDate(vessel?.cutOffDateRf || vessel?.reeferCutOff)}</td>
                      <td>
                        {formatDate(
                          vessel?.hazardousCutOff ||
                            vessel?.vesselExtraTracerData?.hazardousCutOff ||
                            vessel?.vesselExtraTracerData?.hazCutOff
                        )}
                      </td>
                      <td>
                        {vessel.vesselExtraTracerData &&
                          Object.entries(vessel.vesselExtraTracerData).map(([key, value], j) => {
                            if (!value) return;
                            const formatted = dateFormatConverter(value);
                            return (
                              <div key={j} className="d-flex">
                                <div className="font-weight-500">{key}:</div>
                                <div>{JSON.stringify(formatted)}</div>
                              </div>
                            );
                          })}
                      </td>
                    </tr>
                  </Fragment>
                );
              })
            ) : (
              <tr>
                <td colSpan={100}>No Data Found</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};

export default VesselScheduleTable;
const formatDate = (dateString: string) => {
  if (!dateString || !moment(dateString).isValid()) return "";
  return moment(dateString).format("YYYY-MM-DD HH:mm:ss");
};
