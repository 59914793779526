import React, { Fragment, useState } from "react";
import Loader from "Common/Components/Loader";
import { useAppContext } from "contexts/AppContext";
import moment from "moment";
import { generateLFDColor } from "utils/commonFunctions";
import Toastr from "Common/Components/Toastr";

import { tableHeaders } from "../constant";
import { config } from "config";

const DetailTable = (props: any): React.ReactElement => {
  //API CALL
  const { state, myDispatch } = useAppContext();

  const { missingDatas, mloading, mErrloading } = state.reportDRStates;
  //STATE MANAGEMENT
  const [showToatr, setshowToatr] = useState<any>(null);
  const timeZone = localStorage.getItem("timeZone");

  const page = props.detail?.page ? props.detail?.page : 1;
  const limit = props.detail?.page ? props.detail?.limit : 50;

  // const hitSingle=()=>{

  // }

  const onCopy = (nn: string) => {
    if (nn.length > 0) {
      var tempInput = document.createElement("input");
      tempInput.value = nn;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand("copy");
      document.body.removeChild(tempInput);
      setshowToatr(nn + " Copied");
      setTimeout(() => {
        setshowToatr(null);
      }, 2000);
    }
  };

  return (
    <Fragment>
      {showToatr && <Toastr show={true} status="success" message={showToatr} />}
      <div
        className="table-responsive"
        id="dispatchTable"
        style={{ height: "calc(100vh - 536px)" }}
      >
        {mloading && <Loader />}
        <table className="table table-card" id="myTable">
          <thead>
            <tr>
              <th scope="col" className="">
                <div className="d-flex align-items-center justify-content-between">
                  <span className="mr-1">#</span>
                </div>
              </th>
              {tableHeaders.map((item: any, i: number) => {
                let sortIcon = "table-sort table-sort--default";
                if (item.sort === "-" && item.active) {
                  sortIcon = "table-sort table-sort--ascending";
                }
                if (item.sort === "+" && item.active) {
                  sortIcon = "table-sort table-sort--descending";
                }
                return (
                  <th key={i}>
                    <span
                      className={
                        item.label === "Discharge Date" ? "text-center text-break-spaces" : ""
                      }
                    >
                      {item.label}
                    </span>
                  </th>
                );
              })}
            </tr>
          </thead>

          <tbody>
            {missingDatas.length === 0 && (
              <tr>
                <td colSpan={100}>No Containers found with Missing data </td>
              </tr>
            )}
            {missingDatas.map((container: any, i: number) => {
              container.documents.forEach((element: any) => {
                if (
                  element &&
                  element.type == "TIR OUT" &&
                  element.userId &&
                  element.userId !== config.systemID
                ) {
                  container.outgateDoc = element.url;
                }
                if (
                  element &&
                  element.type == "TIR OUT" &&
                  element.userId &&
                  element.userId == config.systemID
                ) {
                  container.outGateTrackos = element.url;
                }
                if (
                  element &&
                  element.type == "TIR IN" &&
                  element.userId &&
                  element.userId !== config.systemID
                ) {
                  container.ingateDoc = element.url;
                }

                if (
                  element &&
                  element.type == "TIR IN" &&
                  element.userId &&
                  element.userId == config.systemID
                ) {
                  container.inGateTrackos = element.url;
                }
              });

              return (
                <Fragment key={i}>
                  <tr key={i}>
                    <td>
                      <div className="d-flex align-items-center justify-content-between">
                        <span className="mr-1">{(page - 1) * limit + i + 1}</span>
                        <div className="form-check"></div>
                      </div>
                    </td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-light text-primary"
                        onClick={() => onCopy(container.reference_number)}
                      >
                        {container.reference_number}
                        {mErrloading && (
                          <span
                            className="spinner-border spinner-border-sm m-2"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        )}
                      </button>
                    </td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-light text-primary"
                        onClick={() => onCopy(container.containerNo)}
                      >
                        {container.containerNo}
                      </button>
                    </td>
                    <td>{container.shipperName}</td>
                    <td>
                      {container.systemGeneratedOwnerName}
                      <br />
                      <span
                        className={
                          container.containerOwnerName && container.isUserEnteredcontainerOwner
                            ? "text-danger"
                            : "text-success"
                        }
                      >
                        {container.containerOwnerName}
                      </span>
                    </td>
                    <td>
                      {container.systemGeneratedContainerSizeName}
                      <br />
                      <span
                        className={
                          container.containerSizeName && container.isUserEnteredcontainerSize
                            ? "text-danger"
                            : "text-success"
                        }
                      >
                        {container.containerSizeName}
                      </span>
                    </td>
                    <td>
                      {container.systemGeneratedTypeName}
                      <br />
                      <span
                        className={
                          container.containerTypeName && container.isUserEnteredcontainerType
                            ? "text-danger"
                            : "text-success"
                        }
                      >
                        {container.containerTypeName}
                      </span>
                    </td>
                    <td>{container.status}</td>
                    {/* <td>{container.myApp_tracking_status}</td> */}
                    <td>
                      {container.custom && <p>{"Custom: " + container.custom}</p>}
                      {container.freight && <p>{"Freight: " + container.freight}</p>}
                      {container.brokerHold && <p>{"Broker: " + container.brokerHold}</p>}
                    </td>
                    <td>
                      {container.systemGeneratedEstimatedToArrive &&
                        timeZone &&
                        moment(container.systemGeneratedEstimatedToArrive)
                          .tz(timeZone)
                          .format("MM/DD HH:mm")}
                    </td>
                    <td>
                      <span
                        className={
                          container.isUserEnteredvessel && timeZone && container.vessel?.eta
                            ? "text-danger"
                            : "text-success"
                        }
                      >
                        {timeZone &&
                          container.vessel?.eta &&
                          moment(container.vessel?.eta).tz(timeZone).format("MM/DD HH:mm")}
                      </span>
                    </td>
                    <td>
                      {container.systemGeneratedDischarge &&
                        timeZone &&
                        moment(container.systemGeneratedDischarge)
                          .tz(timeZone)
                          .format("MM/DD HH:mm")}
                    </td>
                    <td>
                      <span
                        className={
                          container.isUserEnterdischargedDate &&
                          timeZone &&
                          container.dischargedDate
                            ? "text-danger"
                            : "text-success"
                        }
                      >
                        {timeZone &&
                          container.dischargedDate &&
                          moment(container.dischargedDate).tz(timeZone).format("MM/DD HH:mm")}
                      </span>
                    </td>

                    <td>
                      {timeZone &&
                        container.systemGeneratedLastFreeDay &&
                        moment(container.systemGeneratedLastFreeDay).tz(timeZone).format("MM/DD")}
                    </td>
                    <td>
                      <span
                        className={
                          container.isUserEnteredlastFreeDay && timeZone && container.lastFreeDay
                            ? "text-danger"
                            : "text-success"
                        }
                      >
                        {timeZone &&
                          container.lastFreeDay &&
                          moment(container.lastFreeDay).tz(timeZone).format("MM/DD")}
                      </span>
                    </td>
                    <td>
                      {container.systemGeneratedOutGateDate &&
                        timeZone &&
                        moment(container.systemGeneratedOutGateDate)
                          .tz(timeZone)
                          .format("MM/DD HH:mm")}

                      <br />
                      <span
                        className={
                          timeZone && container.outgateDate && container.isUserEnteredoutgateDate
                            ? "text-danger"
                            : "text-success"
                        }
                      >
                        {timeZone &&
                          container.outgateDate &&
                          moment(container.outgateDate).tz(timeZone).format("MM/DD")}
                      </span>
                    </td>
                    <td>
                      <a href={container.outGateTrackos}>{container.outGateTrackos}</a> <br />
                      <a
                        href={container.outgateDoc}
                        className={`text-${container.outGateTrackos ? "success" : "danger"}`}
                      >
                        {container.outgateDoc}
                      </a>
                    </td>
                    <td>
                      {container.systemGeneratedInGateDate &&
                        timeZone &&
                        moment(container.systemGeneratedInGateDate)
                          .tz(timeZone)
                          .format("MM/DD HH:mm")}

                      <br />
                      <span
                        className={
                          timeZone && container.ingateDate && container.isUserEnteredingateDate
                            ? "text-danger"
                            : "text-success"
                        }
                      >
                        {timeZone &&
                          container.ingateDate &&
                          moment(container.ingateDate).tz(timeZone).format("MM/DD")}
                      </span>
                    </td>
                    <td>
                      <a href={container.inGateTrackos}>{container.inGateTrackos}</a> <br />
                      <a
                        href={container.ingateDoc}
                        className={`text-${container.inGateTrackos ? "success" : "danger"}`}
                      >
                        {container.ingateDoc}
                      </a>
                    </td>

                    <td>
                      {container.systemGeneratedVesselName}
                      <br />
                      <span
                        className={
                          container.deliveryOrderNo && container.isUserEntereddeliveryOrderNo
                            ? "text-danger"
                            : "text-success"
                        }
                      >
                        {container.deliveryOrderNo}
                      </span>
                    </td>
                    <td>
                      {container.systemGeneratedVoyageName}
                      <br />
                      <span
                        className={
                          container.releaseNo && container.isUserEnteredreleaseNo
                            ? "text-danger"
                            : "text-success"
                        }
                      >
                        {container.releaseNo}
                      </span>
                    </td>

                    <td>
                      {container.systemGeneratedcallerbillLandingNo}
                      <br />
                      <span
                        className={
                          container.callerbillLandingNo &&
                          container.isUserEnteredcallerbillLandingNo
                            ? "text-danger"
                            : "text-success"
                        }
                      >
                        {container.callerbillLandingNo}
                      </span>
                    </td>
                    <td>
                      {container.systemGeneratedsealNo}
                      <br />
                      <span
                        className={
                          container.seal && container.isUserEnteredsealNo
                            ? "text-danger"
                            : "text-success"
                        }
                      >
                        {container.seal}
                      </span>
                    </td>
                  </tr>
                  {container.error?.TirInDocumentError || container.error?.TirOutDocumentError ? (
                    <tr>
                      <td colSpan={100}>
                        {container.error?.TirInDocumentError && (
                          <ContainerError container={container} errorType={"TirInDocumentError"} />
                        )}
                        {container.error?.TirOutDocumentError && (
                          <ContainerError container={container} errorType={"TirOutDocumentError"} />
                        )}
                        {container.error?.EtaError && (
                          <ContainerError container={container} errorType={"EtaError"} />
                        )}
                        {container.error?.LfdErrors && (
                          <ContainerError container={container} errorType={"LfdErrors"} />
                        )}
                        {container.error?.DischargeError && (
                          <ContainerError container={container} errorType={"DischargeError"} />
                        )}
                      </td>
                    </tr>
                  ) : container.error?.message ? (
                    <tr>
                      <td colSpan={100}>
                        <ul>
                          <li>{container.error.message}</li>
                        </ul>
                      </td>
                    </tr>
                  ) : null}
                  <td colSpan={100}>
                    <ul>
                      <li>Tracking Status: {container?.error?.status}</li>
                    </ul>
                    <ul>
                      <li>Last Traced: {container?.error?.updatedAt}</li>
                    </ul>
                    <ul>
                      <li>Caution Count: {container?.error?.cautionCount}</li>
                    </ul>
                  </td>
                </Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
    </Fragment>
  );
};
export default DetailTable;

const ContainerError = (props: any) => {
  const { errorType, container } = props;
  const label = {
    EtaError: "eta",
    LfdErrors: "lastFreeDay",
    DischargeError: "dischargeDate",
    TirInDocumentError: "TIR IN",
    TirOutDocumentError: "TIR OUT",
  };
  const tirErrorMessage =
    typeof container.error?.[errorType]?.secondaryMessage === "string"
      ? container.error?.[errorType]?.secondaryMessage
      : container.error?.[errorType]?.secondaryMessage?.message;

  return (
    <ul className="p-2">
      <span>{errorType ? errorType : null}</span>
      <br />
      {container.error?.[errorType]?.message && (
        <Fragment>
          <span>
            <strong>Port : </strong>
            {container.error?.[errorType]?.port}
          </span>
          <li> {container.error?.[errorType]?.message}</li>
          <li> {tirErrorMessage}</li>
        </Fragment>
      )}
    </ul>
  );
};
