interface PropsI {
  error?: boolean;
  helper?: string;
  required?: boolean;
  label?: string;
  name: string;
  rows?: number;
  placeholder?: string;
  register?: any;
  rules?: any;
  onChange?: (val: string) => void;
  autoComplete?: string;
  className?: string;
  onBlur?: (val: string) => void;
  dense: any;
  defaultValue?: string | number;
  value?: string | number;
}

const CustomTextAreaField = (props: PropsI) => {
  const {
    error,
    helper,
    placeholder,
    required,
    label,
    name,
    rows,
    register,
    rules,
    dense,
    autoComplete,
    defaultValue,
    value,
    onChange,
    onBlur,
    ...rest
  } = props;

  let fieldError = false;

  if (error) {
    fieldError = true;
  }

  return (
    <div className={`${!dense ? "form-group mb-15" : "mb-0"} ${error && "error"}`}>
      {/* <label className="mb-10">{label}</label> */}
      <div className="input-wrapper">
        <textarea
          placeholder={placeholder}
          autoComplete={autoComplete || "off"}
          autoCorrect="off"
          defaultValue={defaultValue}
          rows={rows || 3}
          value={value}
          onChange={
            onChange
              ? (event: any) => {
                  return onChange(event.target.value);
                }
              : null
          }
          onBlur={(event: any) => {
            return onBlur ? event.target.value : null;
          }}
          {...(register && {
            ...register(
              name,
              rules
                ? {
                    ...rules,
                  }
                : {
                    required,
                  }
            ),
          })}
          {...rest}
        />
      </div>
      {error && (
        <div className="mt-2 error" style={{ color: "red" }}>
          {helper}
        </div>
      )}
    </div>
  );
};

export default CustomTextAreaField;
