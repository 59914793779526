import { useAppContext } from "contexts/AppContext";
import React from "react";
import { getLFDDetail, getTimeScaleReport } from "../action";

interface Iprops {
  heading: string;
  tracked: string;
  untracked: string;
}
const Card = ({ heading, untracked, tracked }: Iprops) => {
  const { state, myDispatch } = useAppContext();
  return (
    <div style={{ cursor: "pointer" }} className="card shadow-sm">
      <div className="card-body">
        <div className="row align-items-center">
          <div className="col">
            <h4>{heading.toUpperCase()}</h4>
          </div>
        </div>
        <div className="row mt-3">
          <div onClick={() => myDispatch(getLFDDetail(true))} className="col">
            <p className="text-muted mb-0 border px-2 py-3 text-center">Tracked: {tracked}</p>
          </div>
          <div onClick={() => myDispatch(getLFDDetail(true))} className="col">
            <p className="text-muted mb-0 border px-2 py-3 text-center">Untracked: {untracked}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
