import { SelectFieldWithoutControl } from "Common/Components/Inputs/CustomSelectField";
import CustomTextAreaField from "Common/Components/Inputs/CustomTextAreaField";
import Loader from "Common/Components/Loader";

const VesselScheduleUpload = (props: any) => {
  const {
    PORTS_TO_UPLOAD,
    uploadData,
    lastUpdated,
    handleHTML,
    running,
    isLoading,
    handlePortChange,
    handleUploadClick,
  } = props;

  return (
    <div className="page-fluid">
      {isLoading && <Loader />}

      <div className="top-page">
        <div className="py-15 d-flex align-items-center">
          <h4>Vessel Schedules</h4>
        </div>
      </div>
      <div className="d-flex align-items-center">
        <SelectFieldWithoutControl
          className="w-200 mr-2"
          classNamePrefix="select"
          isLoading={false}
          isSearchable={true}
          name="port"
          options={PORTS_TO_UPLOAD}
          onChange={handlePortChange}
          size="small"
          placeholder="Select port"
          value={
            uploadData.port &&
            PORTS_TO_UPLOAD.find((gt: { value: string }) => gt.value === uploadData.port)
          }
        />

        <input
          type="text"
          className="form-control w-10 mr-2"
          placeholder="Last Uploaded"
          name="date"
          value={lastUpdated}
        />
      </div>
      <div className="form-row-md mb-10">
        <div className="form-group mb-0 mt-10 col-md">
          <CustomTextAreaField
            placeholder="Paste HTML Body Here"
            name="vesselHtml"
            className="form-control"
            rows={25}
            dense={false}
            value={uploadData.data}
            onChange={handleHTML}
          />
        </div>
      </div>
      <div className="mt-10">
        <button className="btn btn-primary" onClick={handleUploadClick}>
          {running && (
            <span
              className="spinner-border spinner-border-sm mr-2"
              role="status"
              aria-hidden="true"
            ></span>
          )}
          Upload
        </button>
      </div>
    </div>
  );
};

export default VesselScheduleUpload;
