import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { LogoPortPro, LogoPortProSmall } from "../Icons/CustomIcons";
import { useAppContext } from "contexts/AppContext";
import { logoutUser } from "reducers/action/appAction";
import { sidebarLists } from "Common/constants/permissionList";
const Sidebar = () => {
  const { state, myDispatch } = useAppContext();
  const location = useLocation();

  const { loggedUser, onboardingTab } = state.appStates;
  const [isMenuHovered, setisMenuHovered] = useState(false);
  const [activeMenu, setActiveMenu] = useState("");
  const [selectedMenu, setSelectedMenu] = useState("");

  const handleMenuClick = (title: string) => {
    setActiveMenu(activeMenu === title ? "" : title);
  };

  let timer: any = null;

  const onTimeout = () => {
    setisMenuHovered(true);
  };

  const clearTimer = () => {
    clearTimeout(timer);
  };

  const handleMenuEnter = () => {
    timer = setTimeout(onTimeout, 300);
  };

  const handleMenuLeave = () => {
    if (timer) {
      clearTimer();
    }
    setisMenuHovered(false);
    setActiveMenu("");
  };

  const onSignOut = () => {
    myDispatch(logoutUser());
  };
  useEffect(() => {
    setSelectedMenu(location.pathname);
  }, [location.pathname]);

  return (
    <aside
      className={`left-sidebar  ${isMenuHovered ? "ishovered shadow-5" : ""}`}
      onMouseEnter={handleMenuEnter}
      onMouseLeave={handleMenuLeave}
    >
      {/* Sidebar scroll*/}
      {/* Sidebar navigation*/}
      <nav className="sidebar-nav">
        <div className="d-flex align-items-center justify-content-between">
          <span className="navbar-brand">
            {/* Logo Expand */}
            <LogoPortPro className="navbar-brand__logo-big" />
            {/* Logo Small */}
            <LogoPortProSmall className="navbar-brand__logo-small" />
          </span>
        </div>
        <div id="sidebarnav" className="sidebar-content">
          {sidebarLists(loggedUser).map((sidebar: any, index: number) => {
            if (sidebar.disable) {
              return (
                <div className="sidebar-item" key={index}>
                  <div className="sidebar-link">
                    {sidebar.icon}
                    <span className="hide-menu">{sidebar.title}</span>
                  </div>
                </div>
              );
            }
            const isActive = activeMenu === sidebar.title;
            // const isSelected = selectedMenu ===sidebar.link;
            const isSelected =
              selectedMenu === sidebar.link ||
              (sidebar.subLinks &&
                sidebar.subLinks.some((sublink: { link: string }) =>
                  selectedMenu.startsWith(sublink.link)
                ));
            return (
              <React.Fragment key={index}>
                {sidebar.link && !sidebar.subLinks ? (
                  <Link
                    key={index}
                    className={`sidebar-item ${isSelected ? "selected" : ""}`}
                    to={sidebar.link}
                  >
                    <div className={`sidebar-link`}>
                      {sidebar.icon}
                      <span className="hide-menu">{sidebar.title}</span>
                    </div>
                  </Link>
                ) : (
                  <div
                    key={index}
                    className={`sidebar-item ${isSelected ? "isclicked" : ""} ${
                      isActive ? "isclicked" : ""
                    }`}
                    onClick={() => handleMenuClick(sidebar.title)}
                  >
                    <div className={`sidebar-link ${sidebar.subLinks ? "has-arrow" : ""}`}>
                      {sidebar.icon}
                      <span className="hide-menu">{sidebar.title}</span>
                    </div>
                    {sidebar.subLinks && (
                      <div className="collapse first-level">
                        {sidebar.subLinks.map((sublink: any, subIndex: number) => {
                          const sublinkPath = `${sublink.link}`; // Concatenate the parent link and sublink link
                          const isSublinkSelected = selectedMenu.startsWith(sublinkPath); // Check if the selectedMenu starts with the concatenated link

                          return (
                            <Link
                              key={subIndex}
                              className={`first-level__link ${isSublinkSelected ? "active" : ""}`}
                              to={sublinkPath}
                            >
                              {sublink.title}
                            </Link>
                          );
                        })}
                      </div>
                    )}
                  </div>
                )}
              </React.Fragment>
            );
          })}
          <span className="sidebar-item">
            <div className="sidebar-link" onClick={() => onSignOut()}>
              <i className="uil uil-sign-out-alt"></i>
              <span className="hide-menu">Sign Out</span>
            </div>
          </span>
        </div>
      </nav>
    </aside>
  );
};

export default Sidebar;
