import Loader from "Common/Components/Loader";
import { useAppContext } from "contexts/AppContext";
import React, { Fragment, useState } from "react";
import { appointmentReportTableHeaders } from "../constant";

function AppointmentReportTable() {
  const { state } = useAppContext();
  const { apptReport, apptReportLoading } = state.reportDRStates;
  const [more, setMore] = useState<any | null>();
  return (
    <Fragment>
      <div className="table-responsive" style={{ height: "calc(100vh - 255px)" }}>
        {apptReportLoading && <Loader />}
        <table className="table table-card">
          <thead>
            <tr>
              <th scope="col" className="">
                SNo.
              </th>
              {appointmentReportTableHeaders.map((item: any, i: number) => {
                return <th key={i}>{item.label}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {Array.isArray(apptReport) && apptReport.length <= 0 && (
              <tr>
                <td colSpan={100}>Please Update Filter to get data</td>
              </tr>
            )}
            {Array.isArray(apptReport) &&
              apptReport?.length > 0 &&
              apptReport?.map((element: any, i: number) => {
                // let requestDateTimesOpts = element["Requested Time/DateTime"].map((val: any) => ({
                //   label: val,
                //   value: val,
                // }));
                let requestDateTimesString = [];
                if (element.REQUESTEDDATETIMESLOSTS && element.REQUESTEDTIMESLOSTS) {
                  if (
                    Array.isArray(element.REQUESTEDDATETIMESLOSTS) &&
                    element.REQUESTEDDATETIMESLOSTS.length > 0
                  ) {
                    requestDateTimesString = element?.REQUESTEDDATETIMESLOSTS?.map(
                      (val: any) =>
                        `${val.appointmentDateTimeStart} - ${val.appointmentDateTimeEnd}`
                    );
                  } else if (
                    Array.isArray(element.REQUESTEDTIMESLOSTS) &&
                    element.REQUESTEDTIMESLOSTS.length > 0
                  ) {
                    requestDateTimesString = element?.REQUESTEDTIMESLOSTS?.map(
                      (val: any) => `${val.appointmentTimeStart} - ${val.appointmentTimeEnd}`
                    );
                  }
                  // const requestDateTimes =
                  //   Array.isArray(element.REQUESTEDDATETIMESLOSTS) &&
                  //   element.REQUESTEDDATETIMESLOSTS.length > 0
                  //     ? element?.REQUESTEDDATETIMESLOSTS?.map(
                  //         (val: any) =>
                  //           `${val.appointmentDateTimeStart}-${val.appointmentDateTimeEnd}`
                  //       )
                  //     : element?.REQUESTEDTIMESLOSTS?.map((val: any) =>
                  //         `${val.appointmentTimeStart}-${val.appointmentTimeEnd}`;
                  //       );
                  // requestDateTimesString = requestDateTimes.join("\n");
                }
                return (
                  <Fragment key={i}>
                    <tr key={i}>
                      <td>
                        <div className="d-flex align-items-center justify-content-between">
                          <span className="mr-1">{i + 1}</span>
                          <div className="form-check"></div>
                        </div>
                      </td>
                      <td>{element.LOAD}</td>
                      <td>{element.CONTAINER}</td>
                      <td>{element.PORT}</td>
                      <td>{element.CARRIER}</td>
                      <td>{element.PORT_USERNAME}</td>
                      <td>{element.PORT_PASSWORD}</td>
                      <td>{element.APPT_ID}</td>
                      <td>{element.LOAD_STATUS}</td>
                      <td>{element.APPT_STATUS}</td>
                      <td>{element.LOAD_TYPE}</td>
                      <td>{element.SYSTEM_REMARKS}</td>
                      <td>{element.FULL_EMPTY}</td>
                      <td>{element.MOVE_TYPE}</td>
                      <td>
                        <div
                          style={{
                            maxWidth: 200,
                            whiteSpace: "break-spaces",
                            lineBreak: "anywhere",
                          }}
                        >
                          <span onClick={() => setMore(i === more ? null : i)}>
                            {more !== i && (
                              <span>
                                {requestDateTimesString[0]}
                                {requestDateTimesString.length > 1 && <span>...</span>}
                              </span>
                            )}
                            {more === i && (
                              <span>
                                {requestDateTimesString.map((val: any) => {
                                  return <p>{val}</p>;
                                })}
                              </span>
                            )}
                          </span>
                        </div>
                      </td>
                      <td>
                        {element?.ACCEPTEDDATETIMESLOTS
                          ? `${element?.ACCEPTEDDATETIMESLOTS.appointmentDateTimeStart} - ${element?.ACCEPTEDDATETIMESLOTS.appointmentDateTimeStart}`
                          : null}
                      </td>
                      {/* <td>
                        {element["Requested Time/DateTime"].length < 0 ? (
                          element["Requested Time/DateTime"].length
                        ) : (
                          <SelectFieldWithoutControl
                            isLoading={false}
                            isClearable={true}
                            isSearchable={true}
                            name="CARRIER"
                            options={requestDateTimesOpts}
                            // onChange={(e: any) => {
                            //   handleOptValChange(e, "CARRIER");
                            // }}
                            size="small"
                            className="min-200 mr-2"
                            placeholder="Select CARRIER"
                            value={
                              selectedOptions.CARRIER &&
                              requestDateTimesOpts.find(
                                (opt: any) => opt.value === selectedOptions.CARRIER
                              )
                            }
                          />
                        )}
                      </td> */}
                      <td>{element.REMARKS}</td>
                    </tr>
                  </Fragment>
                );
              })}
          </tbody>
        </table>
      </div>
    </Fragment>
  );
}

export default AppointmentReportTable;
